import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import * as $ from "jquery";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import CheckIcon from "@mui/icons-material/Check";

import dayjs, { Dayjs } from "dayjs";
import { MdDelete } from "react-icons/md";
import GridTableClientPlans from "./GridTableClientPlans";
import moment from "moment";
import { FaPlusSquare } from "react-icons/fa";
import ClientPlansPopUp from "./ClientPlansPopUp";
import Popupmodel from "../Leads/Popupmodel";
import "./ClientPlans.css";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import { method, take } from "lodash";
import ClientWorkoutPlanListing from "./ClientWorkoutPlanListing copy";
import ClientDietPlans from "./ClientDietPlans";
import { Button } from "react-bootstrap";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import PopupDietPlanView from "./PopupDietPlanView";
import { BiRefresh } from "react-icons/bi";
import { useLocation } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublicIcon from "@mui/icons-material/Public";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { toast } from "react-toastify";

import PreferredFoodTime from "./clientPlans/PreferredFoodTime";
import MealPlate from "./clientPlans/MealPlate";
import CloseIcon from "@mui/icons-material/Close";

import DownloadIcon from "@mui/icons-material/Download";
import { axiosFetch } from "../../Utlis/AxiosFetch";

import ClientWorkoutPlan from "./clientPlans/WorkoutPlan/ClientWorkoutPlan.jsx";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { openPlugin } from "@react-pdf-viewer/open";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ClientNotes(props) {
  const current_pathname = window.location.pathname.includes("lead");

  const [showDetailPage, setShowDetailPage] = useState(false);
  const [dietPlanId, setDietPlanId] = useState(null);

  const [dietPlanView, setDietPlanView] = useState(false);
  const [dietPlanViewPlanId, setDietPlanViewPlanId] = useState("");

  const contextState = useContext(stateContext);

  const { updateEditDetails, updateList } = contextState;
  const { configToken, operator, currentUser } = contextState.state;

  const [dietPlanList, setDietPlanList] = useState([]);
  const [selectedIds, setSelectedIds] = useState(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [remarks, setRemarks] = useState("");
  const toolbarPluginInstance = toolbarPlugin();
  const openPluginInstance = openPlugin();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [remarksPopup, setRemarksPopup] = React.useState(false);
  const open = Boolean(anchorEl);
  const [planId, setPlanId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [viewPDF, setViewPDF] = useState();

  const isAccess = JSON.parse(localStorage.getItem("currentRow"))
    ?.programFeature?.diet_plan;

  let history = useHistory();

  const openRemarks = () => {
    setRemarksPopup(true);
  };

  const closeRemarks = () => {
    setRemarksPopup(false);
    setRemarks("");
  };

  const handleClick = (event, row, index) => {
    setAnchorEl(event.currentTarget);
    setPlanId(row.id);
    setActiveId(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDietPlanViewModal = (dietPlanId) => {
    setDietPlanView(true);
    setDietPlanViewPlanId(dietPlanId);
  };

  const dietPlanPermission =
    contextState.state.featureAccessible.find(
      (feature) => feature.sub_feature_id === 23
    ).is_view && "DIET PLAN";

  const workoutPlanPermission = contextState.state.featureAccessible.find(
    (feature) => feature.sub_feature_id === 24
  ).is_view;

  const onSelectionChanged = (event) => {
    setSelectedIds(
      event.api.getSelectedRows().map((selected) => {
        return selected.id;
      })
    );
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const checkbox = (params) => {
    return params.node.group === true;
  };

  const autoGroupColumnDef = useMemo((params) => {
    return {
      headerName: "Athlete",
      field: "athlete",
      minWidth: 250,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox,
      },
    };
  }, []);

  function addNew() {
    setDietPlanId(null);
    setShowDetailPage(true);
    setUpdate(false);
  }
  const [update, setUpdate] = useState(false);
  function editDietPlan(dietPlanId) {
    setDietPlanId(dietPlanId);
    setShowDetailPage(true);
    setUpdate(true);
  }

  // const [currentTab, setCurrentTab] = useState("");

  // const initialPlan =
  //   currentTab === "Diet"
  //     ? { tabShown: "DIET PLAN" }
  //     : { tabShown: "Workout Plan" };

  const [dietTabShow, setDietTabShow] = useState({ tabShown: "DIET PLAN" });

  function setTabShown(e) {
    $(".profileInfo li").removeClass("active");
    $(e.target).addClass("active");
    const tabShown = $(e.target).html();

    setDietTabShow((prevState) => ({ ...prevState, tabShown: tabShown }));
    setShowDetailPage(false);
  }

  const [genearteIds, setGenearteIds] = useState(1);

  const [activeId, setActiveId] = useState();

  const LeadId = useParams();
  useEffect(() => {
    axios
      .get(`${ATLAS_URI}/getDietPlansByLeadId/${LeadId?.id}`, configToken)
      .then((res) => setDietPlanList((prevState) => res.data))
      .catch((err) => console.error(err));
  }, [!showDetailPage]);

  const getPlanName = async () => {
    await axios
      .get(
        `${ATLAS_URI}/getDietPlansByLeadId/${props.clientsData.Lead}`,
        configToken
      )
      .then((res) => setDietPlanList((prevState) => res.data))
      .catch((err) => console.error(err));
  };

  const viewDietPlan = () => {
    showDietPlanViewModal(planId);
    handleClose();
  };

  const handleChangeRemark = (e) => {
    setRemarks(e.target.value);
  };

  // const publishStageAndStatus = () => {
  //   const status = {
  //     stage: "Published",
  //     status: true,
  //     leadId: LeadId?.id,
  //   };
  //   axios
  //     .post(
  //       `${ATLAS_URI}/updateDietPlanStageAndStateByPlanId/${planId}`,
  //       status,
  //       configToken
  //     )
  //     .then(
  //       (res) => setAnchorEl(null),
  //       axios
  //         .get(`${ATLAS_URI}/getDietPlansByLeadId/${LeadId.id}`, configToken)
  //         .then((res) => setDietPlanList(res.data))
  //     )
  //     .catch((err) => console.error(err));
  // };

  const publishStageAndStatus = async () => {
    const status = {
      stage: "Published",
      status: true,
      leadId: LeadId?.id,
    };

    try {
      const updateResponse = await axios.post(
        `${ATLAS_URI}/updateDietPlanStageAndStateByPlanId/${planId}`,
        status,
        configToken
      );

      if (updateResponse.status === 200) {
        setAnchorEl(null);
        const dietPlanResponse = await axios.get(
          `${ATLAS_URI}/getDietPlansByLeadId/${LeadId.id}`,
          configToken
        );

        setDietPlanList(dietPlanResponse.data);
      }
    } catch (err) {
      console.error("Error occurred:", err);
    }
  };

  // const unpublishStageAndStatus = () => {
  //   const status = {
  //     status: false,
  //     leadId: LeadId?.id,
  //   };
  //   axios
  //     .post(
  //       `${ATLAS_URI}/updateDietPlanStageAndStateByPlanId/${planId}`,
  //       status,
  //       configToken
  //     )
  //     .then(
  //       (res) => setAnchorEl(null),
  //       axios
  //         .get(`${ATLAS_URI}/getDietPlansByLeadId/${LeadId?.id}`, configToken)
  //         .then((res) => setDietPlanList(res.data))
  //     )
  //     .catch((err) => console.error(err));
  // };

  const unpublishStageAndStatus = async () => {
    const status = {
      status: false,
      leadId: LeadId?.id,
    };

    try {
      const updateResponse = await axios.post(
        `${ATLAS_URI}/updateDietPlanStageAndStateByPlanId/${planId}`,
        status,
        configToken
      );

      if (updateResponse.status === 200) {
        setAnchorEl(null);

        const dietPlanResponse = await axios.get(
          `${ATLAS_URI}/getDietPlansByLeadId/${LeadId?.id}`,
          configToken
        );

        setDietPlanList(dietPlanResponse.data);
      }
    } catch (err) {
      console.error("Error occurred:", err);
    }
  };

  const ViewPDF = () => {
    localStorage.setItem("currentPlanTab", "DIET PLAN");

    console.log(planId, "checkkk");

    axiosFetch({
      url: `getDietPlanById/${planId}`,
      method: "get",
    }).then((res) => {
      console.log(res.data, "checkk");

      if (res.status === 200) {
        history.push({
          pathname: "/Clients/progressReport",
          state: { pdfData: res.data, view: "DIET PLAN" },
        });
      }
    });

    /*     if (!window.location.href.match("/lead/")) {
      axiosFetch({
        url: `/v3/planListingCRM/${LeadId?.id}/${startDate}`,
        method: "get",
      })
        .then((res) => {
          if (res.status === 200) {
            history.push({
              pathname: "/Clients/progressReport",
              state: { pdfData: res?.data?.body[0]?.fileURL },
            });
          }
        })
        .catch((err) => console.error(err));
    } else {
      axiosFetch({
        url: `/getDietPlansByLeadId/${LeadId?.id}`,
        method: "get",
      })
        .then((res) => {
          if (res.status === 200) {
            history.push({
              pathname: "/Clients/progressReport",
              state: { pdfData: res?.data[0]?.pdfLink, view: "DIET PLAN" },
            });
          }
        })
        .catch((err) => console.error(err));
    } */
  };

  const downloadPdfReport = () => {
    axiosFetch({
      url: `/v3/planListingCRM/${LeadId?.id}/${startDate}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          window.open(res?.data?.body[0]?.fileURL);
        }
      })
      .catch((err) => {
        alert("No PDF found or plan not created");
        console.error(err);
      });
  };

  const dietPlanStartDate = () => {
    axiosFetch({
      url: `/getDietPlanByPlanId/${planId}`,
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        setStartDate(res.data.dietPlanDetails[0].start_date);
      }
    });
  };
  useEffect(() => {
    dietPlanStartDate();
  }, [planId]);

  useEffect(() => {
    const currentTab = localStorage.getItem("currentPlanTab");
    if (currentTab) setDietTabShow({ tabShown: currentTab });
  }, []);

  const inltitalPdf = {
    pdfName: "",
    file: "",
  };

  const fileInputRef = useRef(null);
  const [uploadPdf, setUploadPdf] = useState(inltitalPdf);
  let { id } = useParams();

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const pdf = {
        pdfName: file.name,
        file: file,
      };

      setUploadPdf(pdf);
    }
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePdfSubmit = async () => {
    const formData = new FormData();

    console.log(uploadPdf.file);

    formData.append("dietPlan", uploadPdf.file);

    let request = await axiosFetch({
      url: `/uploadDietplanDocument/${id}`,
      method: "post",
      requestConfig: formData,
    });

    if (request.status === 200) {
      toast.success(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setUploadPdf(inltitalPdf);
    }
  };

  useEffect(() => {
    setUploadPdf(inltitalPdf);
  }, []);

  return (
    <section className="profileInfo">
      <nav className="tabNavigation">
        <ul>
          {dietPlanPermission &&
            (dietTabShow.tabShown === "DIET PLAN" ? (
              <li className="active" onClick={setTabShown}>
                DIET PLAN
              </li>
            ) : (
              <li onClick={setTabShown}>DIET PLAN</li>
            ))}
          {workoutPlanPermission &&
            (dietTabShow.tabShown === "Workout Plan" ? (
              <li className="active" onClick={setTabShown}>
                Workout Plan
              </li>
            ) : (
              <li onClick={setTabShown}>Workout Plan</li>
            ))}
          <li onClick={setTabShown}>Preferred Food Timing</li>
          {/* <li onClick={setTabShown}>Meal Plate</li> */}
        </ul>
      </nav>

      {dietTabShow.tabShown === "DIET PLAN" && (
        <div className="d-flex gap-5 w-100 mb-4">
          <div
            style={{
              // position: "relative",
              marginLeft: "10px",
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
          >
            {/* <Button onClick={handleContainerClick}>Upload Pdf</Button> */}
            <span>{uploadPdf.pdfName}</span>
            <input
              type="file"
              accept="application/pdf"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              // style={{
              //   width: "115px",
              //   position: "absolute",
              //   opacity: 0,
              //   left: "0",
              //   cursor: "pointer",
              // }}
            />
          </div>
          {/* <Button onClick={handlePdfSubmit}>Save</Button> */}
        </div>
      )}

      {dietTabShow.tabShown === "DIET PLAN" &&
        dietPlanPermission &&
        (showDetailPage ? (
          <ClientDietPlans
            showDetailPage={showDetailPage}
            setShowDetailPage={setShowDetailPage}
            clientsData={props.clientsData}
            dietPlanId={dietPlanId}
            setGenearteIds={setGenearteIds}
            update={update}
            dietPlanList={dietPlanList}
            setDietPlanList={setDietPlanList}
            Lead={props.clientsData.Lead}
            setAnchorEl={setAnchorEl}
            downloadPdfReport={downloadPdfReport}
          />
        ) : (
          <div style={{ width: "100%", height: "1000px" }}>
            <div
              id="myGrid"
              style={{
                height: "100%",
                width: "100%",
              }}
              className="ag-theme-alpine"
            >
              {!current_pathname && (
                <>
                  {/* uncomment this Add new Plan button will work fine */}
                  {contextState.state.featureAccessible.find(
                    (feature) => feature.sub_feature_id === 23
                  ).is_edit &&
                    isAccess && (
                      <Button style={{ margin: "0.5rem" }} onClick={addNew}>
                        Add New Plan
                      </Button>
                    )}
                </>
              )}

              <br />

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>S No</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Plan Name</strong>
                      </TableCell>

                      <TableCell>
                        <strong>Stage</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Created By</strong>
                      </TableCell>

                      <TableCell>
                        <strong>Created Date</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Updated By</strong>
                      </TableCell>

                      <TableCell>
                        <strong>Updated Date</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Remarks</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dietPlanList.length !== 0
                      ? dietPlanList.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {!!row.plan_name ? row.plan_name : "-"}
                            </TableCell>

                            <TableCell>
                              {!!row.stage ? row.stage : "-"}
                            </TableCell>
                            <TableCell>
                              {!!row
                                ? !row.status
                                  ? "InActive"
                                  : "Active"
                                : "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {!!row.User
                                ? (!!row.User.firstName
                                    ? row.User.firstName
                                    : "-") +
                                  (!!row.User.lastName ? row.User.lastName : "")
                                : "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {!!row.createdAt
                                ? row.createdAt?.slice(0, 10)
                                : "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {!!row.updated_by
                                ? (!!row.updated_by.firstName
                                    ? row.updated_by.firstName
                                    : "-") +
                                  (!!row.updated_by.lastName
                                    ? row.updated_by.lastName
                                    : "")
                                : "-"}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {!!row.updatedAt
                                ? row.updatedAt?.slice(0, 10)
                                : "-"}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleClick(e, row, index);
                                }}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      : "No Results Found"}
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal
                open={remarksPopup}
                onClose={closeRemarks}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Remarks
                  </Typography>
                  <hr />
                  <TextField
                    id="outlined-basic"
                    label="Remarks"
                    variant="outlined"
                    value={remarks}
                    onChange={handleChangeRemark}
                  />
                  <br />
                  <br />
                  <Button variant="contained" onClick={closeRemarks}>
                    Cancel
                  </Button>
                  <Button variant="contained">Submit</Button>
                </Box>
              </Modal>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                transformOrigin={{ horizontal: "center", vertical: "center" }}
              >
                <MenuItem onClick={ViewPDF}>
                  <VisibilityIcon />
                  &nbsp;View
                </MenuItem>
                {!current_pathname && <hr />}
                {!current_pathname && (
                  <>
                    <MenuItem onClick={() => editDietPlan(planId)}>
                      <EditIcon />
                      &nbsp;Edit
                    </MenuItem>

                    <hr />
                    <MenuItem
                      onClick={
                        dietPlanList[activeId]?.stage === "Draft"
                          ? publishStageAndStatus
                          : dietPlanList[activeId]?.stage === "Published" &&
                            dietPlanList[activeId]?.status == true
                          ? unpublishStageAndStatus
                          : dietPlanList[activeId]?.stage === "Published" &&
                            dietPlanList[activeId]?.status === false
                          ? publishStageAndStatus
                          : unpublishStageAndStatus
                      }
                    >
                      {dietPlanList[activeId]?.stage === "Published" &&
                        dietPlanList[activeId]?.status === false && (
                          <CheckIcon />
                        )}
                      {dietPlanList[activeId]?.stage === "Draft" && (
                        <PublicIcon />
                      )}
                      {dietPlanList[activeId]?.stage === "Published" &&
                        dietPlanList[activeId]?.status == true && <CloseIcon />}
                      &nbsp;
                      {dietPlanList[activeId]?.stage === "Draft"
                        ? "Publish"
                        : dietPlanList[activeId]?.stage === "Published" &&
                          dietPlanList[activeId]?.status == true
                        ? "InActivate"
                        : dietPlanList[activeId]?.stage === "Published" &&
                          dietPlanList[activeId]?.status === false
                        ? "Activate"
                        : "InActivate"}
                    </MenuItem>
                    <hr />
                    <MenuItem
                      onClick={() => {
                        axios
                          .delete(
                            `${ATLAS_URI}/deleteDietPlanByPlanId/${planId}`,
                            configToken
                          )
                          .then((res) => {
                            if (res.status == 200) {
                              toast.success("Plan Deleted", {
                                position: toast.POSITION.TOP_CENTER,
                              });

                              axios
                                .get(
                                  `${ATLAS_URI}/getDietPlansByLeadId/${LeadId?.id}`,
                                  configToken
                                )
                                .then(
                                  (res) => (
                                    setAnchorEl(null),
                                    setDietPlanList((prevState) => res.data)
                                  )
                                )
                                .catch((err) => console.error(err));
                            }
                          })
                          .catch((err) => {
                            toast.error(err.response.data.message, {
                              position: toast.POSITION.TOP_CENTER,
                            });
                          });
                      }}
                    >
                      <DeleteIcon />
                      &nbsp;Delete
                    </MenuItem>
                    {dietPlanList[activeId]?.stage === "Published" && (
                      <>
                        <hr />

                        <MenuItem onClick={downloadPdfReport}>
                          <DownloadIcon />
                          &nbsp;Download
                        </MenuItem>
                      </>
                    )}
                  </>
                )}
              </Menu>
            </div>

            {dietPlanView && (
              <PopupDietPlanView
                show1={dietPlanView}
                setShow1={setDietPlanView}
                planId={dietPlanViewPlanId}
              />
            )}
          </div>
        ))}

      {dietTabShow.tabShown === "Workout Plan" && workoutPlanPermission && (
        <ClientWorkoutPlanListing
          clientDetail={props.clientsData}
          setShowDetailPage={setShowDetailPage}
        />
      )}
      {dietTabShow.tabShown === "Preferred Food Timing" && (
        <PreferredFoodTime />
      )}
      {dietTabShow.tabShown === "Meal Plate" && <MealPlate />}
    </section>
  );
}

export default ClientNotes;
