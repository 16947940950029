import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Controller } from "react-hook-form";

const SelectBox = ({
  control,
  name,
  isRequired,
  modalProps,
  menuList,
  defaultValue,
  inputLabel,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      rules={{ required: isRequired ? "required" : false }}
      control={control}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth size="small" disabled={disabled}>
          {inputLabel && (
            <InputLabel id="demo-multiple-chip-label">{inputLabel}</InputLabel>
          )}
          <Select
            sx={{ fontSize: "16px" }}
            {...field}
            error={!!error}
            MenuProps={{
              PaperProps: {
                ...modalProps,
              },
            }}
            input={
              inputLabel && (
                <OutlinedInput id="select-multiple-chip" label={inputLabel} />
              )
            }
          >
            <MenuItem value="select" disabled>
              {defaultValue}
            </MenuItem>
            {menuList?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={true}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SelectBox;
