import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import DataViewer from "../../../components/DataViewer/DataViewer";
import ControlledTextField from "../../../components/ControlledTextField";
import { FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../../components/ControlledAutocomplete";
import ControlledRadioGroup from "../../../components/ControlledRadioGroup";
import ControlledTimepicker from "../../../components/ControlledTimepicker";
import ControllerDatetimepicker from "../../../components/ControlledDatetimepicker";
import FormRenderer from "../../../components/FormRenderer/FormRenderer";
import formdata from "./diet_preference";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const FemaleInformation = ({ displaydata, update }) => {
  const [isedit, setIsedit] = useState(false);
  const [masters, setMasters] = useState({});
  const [infoview, setInfoview] = useState({});

  const [isDisable, setIsDisable] = useState(false);

  const [deliveryType, setDeliveryType] = useState(null);

  const defaultValues = {
    createDate: new Date(),
  };
  const methods = useForm({
    defaultValues,
    profession_details: "",
  });
  const {
    getValues,

    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    register,
    formState: { errors, isValid },
  } = methods;

  const handleClick = (event) => {
    setIsedit(!isedit);
  };

  useEffect(() => {
    axiosFetch({
      method: "get",
      url: `/getFemaleInfoMaster`,
    })
      .then((response) => {
        setMasters({
          ...masters,
          trimster_master: [...response.data.body.trimster_master],
          deliverytype_master: [...response.data.body.deliverytype_master],
        });
      })
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    if (displaydata) {
      let femaleInfo = displaydata?.femaleInformation;

      setDeliveryType(femaleInfo?.delivery_type);

      setValue("current_trimester", femaleInfo?.current_trimester);
      setValue("is_postpartum", femaleInfo?.is_postpartum ? "Yes" : "No");
      setValue("postpartum_info", femaleInfo?.postpartum_info);
      setValue("delivery_type", femaleInfo?.delivery_type); //femaleInfo?.delivery_type
      setValue("is_diastasis", femaleInfo?.is_diastasis ? "Yes" : "No");
      setValue("fingergap", femaleInfo?.fingergap);

      setInfoview({
        "Which Trimester are you in currently?": femaleInfo?.current_trimester,
        "Are you in Postpartum phase": femaleInfo?.is_postpartum ? "Yes" : "No",
        "Postpartum Details": femaleInfo?.postpartum_info,
        "Delivery Type": femaleInfo?.delivery_type,
        "Are ou dealing with diastasis recti?": femaleInfo?.is_diastasis
          ? "Yes"
          : "No",
        fingergap: femaleInfo?.fingergap,
      });

      // setValue("gender",basicinfo?.gender)
      // setValue("dob",basicinfo?.dob)
    }
  }, [displaydata]);
  const handleCancel = (event) => {
    setIsedit(false);
  };
  const handledietpreference = async (data) => {
    const isNotValid = () => {
      toast.error("Please fill all the Yes mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    };

    const { postpartum_info, fingergap, is_postpartum, is_diastasis } = data;

    if (
      (is_postpartum === "Yes" && !postpartum_info) ||
      (is_diastasis === "Yes" && !fingergap)
    ) {
      isNotValid();
      return;
    }

    setIsedit(false);

    update({
      ...data,
      formtype: 6,
    });
    //alert(JSON.stringify(data))
  };

  // const current_trimester = watch("current_trimester");
  // useEffect(() => {
  //   if (current_trimester?.toLowerCase() != "none") {

  //     setValue("postpartum_info", "");
  //     setValue("is_postpartum", false);
  //     setValue("delivery_type", "");
  //     setValue("is_diastasis", false);
  //     setValue("fingergap", "");
  //   } else {
  //   }
  // }, [current_trimester]);

  // useEffect(() => {
  //   // if(is_postpartum?.toLowerCase() == 'no')
  //   // {
  //   //   setValue('postpartum_info',"")
  //   //   setValue('delivery_type',"")
  //   //   setValue('is_diastasis',false)
  //   //   setValue('fingergap',"")
  //   // }else{
  //   // }
  // }, [is_postpartum]);

  const is_diastasis = watch("is_diastasis");
  const is_postpartum = watch("is_postpartum");
  const current_trimester = watch("current_trimester");
  const delivery_type = watch("delivery_type");

  useEffect(() => {
    if (is_postpartum === "No") {
      setValue("postpartum_info", "");
      setValue("fingergap", "");
      setValue("is_diastasis", "No");
      setDeliveryType(null);
      setValue("delivery_type", null);
    }

    if (is_diastasis === "No") {
      setValue("fingergap", "");
    }

    if (current_trimester !== "None") {
      setValue("is_postpartum", "No");
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [is_postpartum, is_diastasis, current_trimester]);

  useEffect(() => {
    if (delivery_type) {
      setDeliveryType(delivery_type);
    }
  }, [delivery_type]);

  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit((data) => {
          handledietpreference(data);
        })}
      >
        {isedit ? (
          <>
            {masters.trimster_master && (
              <Grid container xs={12} spacing={2}>
                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Which Trimester are you in currently?"}
                    name={"current_trimester"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={
                      displaydata?.femaleInformation?.current_trimester
                    }
                    options={
                      masters?.trimster_master ? masters?.trimster_master : []
                    }
                  />
                </Grid>
                <Grid container item xs={3} alignItems={"center"}>
                  <Stack
                    style={{ width: "100%" }}
                    fullWidth
                    direction={"row"}
                    spacing={2}
                  >
                    <ControlledRadioGroup
                      label={"Are you in your postpartum phase?"}
                      name={"is_postpartum"}
                      control={control}
                      row={true}
                      rules={{}}
                      fullWidth={true}
                      variant="outlined"
                      options={[
                        {
                          value: "Yes",
                          label: "Yes",
                        },
                        {
                          value: "No",
                          label: "No",
                        },
                      ]}
                      isDisableColumn={isDisable}
                    />

                    <ControlledTextField
                      label={"Postpartum Details"}
                      name={"postpartum_info"}
                      control={control}
                      rules={{}}
                      fullWidth={true}
                      isDisableColumn={
                        isDisable === true
                          ? true
                          : getValues("is_postpartum") === "Yes"
                          ? false
                          : true
                      }
                      //
                      variant="outlined"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={3}>
                  {!deliveryType && (
                    <ControlledAutocomplete
                      label={"Type of Delivery"}
                      name={"delivery_type"}
                      control={control}
                      rules={{}}
                      fullWidth={true}
                      variant="outlined"
                      defaultValue={null}
                      options={
                        masters?.deliverytype_master
                          ? masters?.deliverytype_master
                          : []
                      }
                      isDisableColumn={
                        isDisable === true
                          ? true
                          : getValues("is_postpartum") === "Yes"
                          ? false
                          : true
                      }
                    />
                  )}

                  {deliveryType && (
                    <ControlledAutocomplete
                      label={"Type of Delivery"}
                      name={"delivery_type"}
                      control={control}
                      rules={{}}
                      fullWidth={true}
                      variant="outlined"
                      defaultValue={deliveryType}
                      options={
                        masters?.deliverytype_master
                          ? masters?.deliverytype_master
                          : []
                      }
                      isDisableColumn={
                        isDisable === true
                          ? true
                          : getValues("is_postpartum") === "Yes"
                          ? false
                          : true
                      }
                    />
                  )}
                </Grid>
                <Grid container item xs={3} alignItems={"center"}>
                  <Stack
                    style={{ width: "100%" }}
                    fullWidth
                    direction={"row"}
                    spacing={2}
                  >
                    <ControlledRadioGroup
                      label={"Are you dealing with Diastases recti?"}
                      name={"is_diastasis"}
                      control={control}
                      row={true}
                      rules={{}}
                      fullWidth={true}
                      variant="outlined"
                      options={[
                        {
                          value: "Yes",
                          label: "Yes",
                        },
                        {
                          value: "No",
                          label: "No",
                        },
                      ]}
                      isDisableColumn={
                        isDisable === true
                          ? true
                          : getValues("is_postpartum") === "Yes"
                          ? false
                          : true
                      }
                    />

                    {/* <ControlledTextField
                    label={"Diastasis Information"}
                    name={"diastasis_info"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                  /> */}
                  </Stack>
                </Grid>

                <Grid item xs={3}>
                  <ControlledTextField
                    label={"Finger Gap"}
                    name={"fingergap"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    isDisableColumn={
                      isDisable === true
                        ? true
                        : getValues("is_postpartum") === "Yes"
                        ? getValues("is_diastasis") === "Yes"
                          ? false
                          : true
                        : true
                    }
                  />
                </Grid>
              </Grid>
            )}{" "}
          </>
        ) : (
          <Grid container xs={12} spacing={2}>
            <DataViewer data={infoview} />
            {/* <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Diet Preference"}
            </Typography>
            <Typography variant="h6">
              {displaydata?.dietPreference ? displaydata?.dietPreference  : "--"}
            </Typography>
            </Stack>
             
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Any Allergies"}
            </Typography>
            <Typography variant="h6">
              {displaydata?.dietAnyAllergies ? displaydata?.dietAnyAllergies  : "--"}
            </Typography>
            </Stack>

              
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Please Specify Allergies"}
            </Typography>
            <Typography variant="h6">
              {displaydata?.otherAllergies ? displaydata?.otherAllergies  : "--"}
            </Typography>
            </Stack>
              
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Diet Preferred Cuisine"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.dietPreferredCuisine ? displaydata?.dietPreferredCuisine  : "--"}
              </Typography>
              </Stack>

            
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Food Dislikes"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.food_dislike ? displaydata?.food_dislike  : "--"}
              </Typography>
              </Stack>
            
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Number of Meals"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.number_of_meals ? displaydata?.number_of_meals  : "--"}
              </Typography>
              </Stack>
            
              
            </Grid> */}
          </Grid>
        )}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          {isedit && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="outlined" onClick={handleCancel}>
                {" "}
                Cancel{" "}
              </Button>
              <Button variant="contained" type="submit">
                {" "}
                Save{" "}
              </Button>
            </Stack>
          )}
        </Grid>
      </form>
      {!isedit && (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button variant="contained" onClick={handleClick}>
              {" "}
              Edit{" "}
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default FemaleInformation;

//btn-disabled
