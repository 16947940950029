import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CallIcon from "@mui/icons-material/Call";
import "./styles.css";

const CallStatusBtn = ({
  currentCallStatus,
  statusColor,
  duration,
  interactionForm,
  setInteractionForm,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (currentCallStatus === "ended" || currentCallStatus === "Busy") {
      setInteractionForm({
        ...interactionForm,
        details: {
          ...interactionForm?.details,
          open: true,
        },
      });
    }
  }, [currentCallStatus]);

  const endCall = () => {
    setInteractionForm({
      ...interactionForm,
      details: {
        ...interactionForm?.details,
        open: true,
      },
    });
  };

  // useEffect(() => {
  //   endCall();
  // }, []);

  return (
    <div
      // className="movable-div"

      style={{
        position: "absolute",
        padding: "12px",
        zIndex: 1000,
        borderRadius: "50px",
        textAlign: "center",
        right: "20px",
        top: "80px",
        display: "flex",
        background: "#fff",
        border: "1px solid rgba(0, 0, 0, 0.26)",
        alignItems: "center",
        transform: `translate(${position.x}px, ${position.y}px)`,
      }}
    >
      {currentCallStatus === "answered" && (
        <Typography
          sx={{
            color: "#2196f3",
            fontSize: "16px",
            paddingRight: "14px",
          }}
        >
          {duration}
        </Typography>
      )}

      <Typography
        onClick={() => endCall()}
        sx={{
          fontSize: "14px",
          margin: "0px",
          color: "#fff",
          background: statusColor(),
          textAlign: "center",
          padding: "6px",
          borderRadius: "50%",
        }}
      >
        <CallIcon />{" "}
      </Typography>

      <div
        style={{
          cursor: "grab",
          padding: "4px 4px 4px 14px",
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Typography>
          <DragIndicatorIcon />
        </Typography>
      </div>
    </div>
  );
};
export default CallStatusBtn;
