import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

export const getSourceList = createAsyncThunk(
  "source/getSourceList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosFetch({
        method: "get",
        url: "/getSources",
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
