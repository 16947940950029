import React, { useContext, useEffect, useState } from "react";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import vegicon from "../../assets/icons8-vegetarian-48.png";
import nonVegIcon from "../../assets/icons8-non-vegetarian-food-symbol-48.png";
import vmaxIcon from "../../assets/2.png";
import "./DietTemplateView.css";
// import latest_sample from "./latest_sample.json";
// import ClientDietPlanView from './ClientDietPlanView';

function DietTemplateView(props) {
  const [show, setShow] = useState(props.show1);
 
  const [dietPlanInfo, setDietPlanInfo] = useState({});
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const handleClose = () => {
    props.setShow1(false);
  };

  useEffect(() => {
    props.show1 && setShow(true);

    axios
      .get(
        `${ATLAS_URI}/getDietPlanTemplateBylatestID/${props.planId}`,
        configToken
      )
      .then((resp) => {
        if (resp.status == 200) {
          setDietPlanInfo(resp.data);
        }
      });
  }, [props.show1]);

  const handleShow = () => setShow(props.show1);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  //   const headingStyle = {
  //     fontFamily: "Lato",
  //     fontWeight: "400",
  //     fontSize: "14px",
  //     display: "flex"
  // };

  // const valueStyle = {
  //     fontFamily: "Lato",
  //     fontWeight: "700",
  //     fontSize: "14px"
  // };

  // const nutritionStyle = {
  //     boxSizing: "border-box",
  //     position: "absolute",
  //     width: 79,
  //     height: "50px",
  //     background: "#FAFAFA",
  //     borderRadius: 4
  // }

  // const nutritionTextStyle = {
  //     textAlign: 'center'
  // }

  // const mealTableFonts = {
  //     height: '30px',
  //     fontSize: '14px',
  //     fontWeight: '700',
  //     border: '1px solid #dddd',
  //     backgroundColor:'#e30022',
  //     color:'#fff',
  //     textAlign:'center'
  // }

  // const tableStyle = {
  //     border: '1px solid #dddd',

  // }

  const mealNames = [];
  const food_menu = dietPlanInfo?.food_menu?.map((foodMenu) => {
    let isRepeat = false;
    if (mealNames.indexOf(foodMenu.name) == -1) {
      mealNames.push(foodMenu.name);
    } else {
      isRepeat = true;
    }
    return { ...foodMenu, isRepeat };
  });

  const totalNutrition = food_menu
    ?.filter((foodMenu) => !foodMenu.isRepeat)
    .map((foodMenu) => foodMenu.total)
    .reduce(
      (acc, foodDetail) => {
        acc.carbs += parseFloat(foodDetail.carbs);
        acc.fats += parseFloat(foodDetail.fats);
        acc.protein += parseFloat(foodDetail.protein);
        acc.calories += parseFloat(foodDetail.calories);
        acc.fibre += parseFloat(foodDetail.fibre);

        return acc;
      },
      { carbs: 0, fats: 0, protein: 0, calories: 0, fibre: 0 }
    );

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Diet Template Details</Modal.Title>
          <GrClose
            style={{
              backgroundColor: isHovering ? "#FF0000" : "",
              color: isHovering ? "#fff" : "",
              border: isHovering ? "1px solid" : "",
              height: isHovering ? "30px" : "",
              width: isHovering ? "30px" : "",
              opacity: isHovering ? "0.7" : "",
            }}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="header" style={{ backgroundColor: "#000000e6" }}>
              <div className="first-header">
                <div>
                  <div className="logo">
                    <img
                      src={`data:image/svg+xml;utf8,${dietPlanInfo.logo}`}
                      width="400px"
                      height="80px"
                      alt="logo"
                    />
                  </div>
                  <div className="title-card">
                    <div className="title-light">1st Month</div>
                    <div className="title-heading">Diet Template</div>
                  </div>
                </div>
                <div className="food-image-container">
                  <div
                    className="food-image"
                    style={{
                      backgroundImage: `url(data:image/png;base64,${dietPlanInfo.foodImage})`,
                    }}
                  ></div>
                  <div className="absolute-image"></div>
                </div>
              </div>
              <div className="header-table">
                <div className="absolute-table"></div>
                <div className="left-table-header">
                  <div>
                    <span>Client Name</span>
                    <br />
                    <span>Lead ID</span>
                    <br />
                    <span>Gender</span>
                    <br />
                    <span>Age</span>
                  </div>
                  <div>
                    <span>:</span>
                    <br />
                    <span>:</span>
                    <br />
                    <span>:</span>
                    <br />
                    <span>:</span>
                  </div>
                  <div>
                    <span>{dietPlanInfo?.ClientName || "--"}</span>
                    <br />
                    <span>{dietPlanInfo?.leadId || "--"}</span>
                    <br />
                    <span>{dietPlanInfo?.Gender || "--"}</span>
                    <br />
                    <span>{dietPlanInfo?.Age || "--"}</span>
                  </div>
                </div>
                <div className="left-table-header">
                  <div>
                    <span>Height</span>
                    <br />
                    <span>Weight</span>
                    <br />
                    <span>Dietician</span>
                    <br />
                    <span>Date</span>
                  </div>
                  <div>
                    <span>:</span>
                    <br />
                    <span>:</span>
                    <br />
                    <span>:</span>
                    <br />
                    <span>:</span>
                  </div>
                  <div>
                    <span>
                      {dietPlanInfo?.Height}&nbsp;
                      {dietPlanInfo?.height_unit || "--"}{" "}
                    </span>
                    <br />
                    <span>
                      {dietPlanInfo?.Weight}&nbsp;
                      {dietPlanInfo?.weight_unit || "--"}
                    </span>
                    <br />
                    <span>{dietPlanInfo?.Dietician || "--"}</span>
                    <br />
                    <span>{dietPlanInfo?.Date || "--"}</span>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className="primary-details">
              <div className="absolute-table-primary-details"></div>
              <div className="left-table-header">
                <div>
                  <span className="weighter">Fitness Goals</span>
                  <br />
                  <span className="weighter">Diet Type</span>
                </div>
                <div>
                  <span>: </span>
                  <br />
                  <span>: </span>
                </div>
                <div>
                  <span className="result">
                    {dietPlanInfo?.primary_fitness_goals || "--"}
                  </span>
                  <br />
                  <span className="result">
                    {dietPlanInfo?.dietType || "--"}
                  </span>
                </div>
              </div>
              <div className="left-table-header">
                <div>
                  <span className="weighter">Diet Restrictions</span>
                  <br />
                  <span className="weighter">Health Issues</span>
                </div>
                <div>
                  <span>: </span>
                  <br />
                  <span>: </span>
                </div>
                <div>
                  <span className="result">
                    {dietPlanInfo?.dietRestriction || "--"}
                  </span>
                  <br />
                  <span className="result">
                    {dietPlanInfo?.health_issues || "--"}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <h3>Nutritional Information</h3>
            <br />
            <div className="nutritional-information">
              <div>
                <span className="weighter">🔥 Calories</span>:&nbsp;&nbsp;
                <span className="result">
                  {dietPlanInfo?.dietPlanRelationshipData?.totalValue?.calories}
                  &nbsp;cal
                </span>
              </div>
              <div>
                <span className="weighter">🧬 Proteins</span>:&nbsp;&nbsp;
                <span className="result">
                  {dietPlanInfo?.dietPlanRelationshipData?.totalValue?.protein}
                  &nbsp;g
                </span>
              </div>
              <div>
                <span className="weighter">💧 Water Intake</span>:&nbsp;&nbsp;
                <span className="result">
                  {dietPlanInfo?.dietPlanRelationshipData?.waterGoal}&nbsp;L
                </span>
              </div>
            </div>
            {dietPlanInfo?.dietPlanRelationshipData?.food_menu.map(
              (menus, menuIndex) => {
                return (
                  <>
                    <table key={menuIndex} className="dietTable">
                      <tr>
                        <th colspan="3">
                          {menus.mealName}&nbsp;&nbsp;({menus.time})
                        </th>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }} className="special-row">
                          S.No.
                        </td>
                        <td style={{ width: "40%" }} className="special-row">
                          Meal
                        </td>
                        <td style={{ width: "40%" }} className="special-row">
                          Specification
                        </td>
                      </tr>
                      {menus?.foodMenues?.map((menus, menuIndex) => {
                        return (
                          <tr key={menuIndex}>
                            <td>{menus.id}</td>
                            <td>
                              {menus.food.map((diet) => {
                                return diet.map((eachMenu, eachMenuIndex) => {
                                  return (
                                    <div key={eachMenuIndex}>
                                      <span className="weighter">
                                        {eachMenu.food_item}
                                        &nbsp;
                                        <span>
                                          {eachMenu.quantity}
                                          {eachMenu.Units_gms_Ml_Piece}
                                        </span>
                                      </span>
                                      <span className="and-condition">
                                        &nbsp;
                                        {eachMenu.isNotDietLast ? "or" : ""}
                                      </span>
                                      &nbsp;
                                      {!eachMenu.isNotDietLast &&
                                      eachMenu.isMenuLast
                                        ? "and"
                                        : ""}
                                    </div>
                                  );
                                });
                              })}
                            </td>

                            <td>
                              {menus?.food?.map((diet) => {
                                return diet.map((eachMenu, eachMenuIndex) => {
                                  return (
                                    <div key={eachMenuIndex}>
                                      <span className="result">
                                        {eachMenu.food_instructions}
                                      </span>
                                    </div>
                                  );
                                });
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                    <br />
                    <br />
                  </>
                );
              }
            )}

            <br />
            <br />
            <div className="vegetables-container">
              <div className="vegetables">Vegetables To Be Included :</div>
              <p>
                Lettuce, Carrot, Beetroot, Mushroom, Peas, Capsicum (all color),
                Cucumber, Brinjal, Lady’s finger, Zucchini, Turnip, Green leafy
                vegetable, Beans (all variety), Corn, Gourd (all variety),
                Asparagus, Kovai Pumpkin, Tinda, Radish, Yam, Beetroot, Pumpkin,
                Plantain.
              </p>
            </div>
            <div className="vegetables-container">
              <div className="vegetables">Fruits To Be Included :</div>
              <p>
                Apple, Papaya, Guava, Orange, Avocado, Pears, Papaya, Apricot,
                Berries (all variety), Plump, Green grapes, Kiwi, Peach, Fig,
                Dragon fruit, Lemon, Tomato, Passion fruit, Grapefruit, Apricot,
                Mango, Jackfruit.
              </p>
            </div>
            <br />
            <br />
            <div className="general-instructions">
              <div className="Instructions">General Instructions&nbsp;:</div>
              <ul>
                <li>
                  🍎 Keep yourself hydrated by consuming at least 10-12 glasses
                  of water every day. Avoid consuming water before and after
                  meal consumption.
                </li>
                <li>
                  🍎 Ensure that you do not skip the mid meals and make a point
                  to consume mid-meal snacks for boosting energy needs which
                  will help reduce fatigue and also provide energy.
                </li>
                <li>
                  🍎 Avoid lying down immediately after meals to reduce gastric
                  issues.
                </li>
                <li>
                  🍎 Avoid consuming too much salt in your diet. Limit salt
                  intake to 5gms/day.
                </li>
                <li className="bolder">
                  🍎 Weigh the food before serving it on the plate (do buy a
                  food weighing scale and measuring cups).
                </li>
                <li>
                  🍎 Eat vegetables followed by protein and carbs on the plate.
                </li>
                <li>
                  🍎 Do not add coconut to your gravies, sprinkle coconut once
                  the poriyal/sabji is cooked.
                </li>
                <li className="bolder">
                  🍎 Update your meal plates regularly, this will help us to
                  guide you further.
                </li>
                <li>🍎 Go on less salt for dinner.</li>
                <li>
                  🍎 Do not use sugars in your diets; they add extra calories.
                </li>
                <li>
                  🍎 Do not use butter,dalda, or margarine. If the ghee is
                  organic and pure then 1tsp of ghee can be added to your
                  rice/roti.
                </li>
              </ul>
            </div>
            <div className="conclusion_header">Measurement Help Desk:</div>
            <div className="conclusion">
              <div>
                <span className="conclusion_title">Measuring Spoons</span>
                <img
                  src={`data:image/png;base64,${dietPlanInfo.image1}`}
                  className="bordered-image rotated"
                  alt="..."
                />
              </div>
              <div>
                <span className="conclusion_title">Measuring Cups</span>
                <img
                  src={`data:image/png;base64,${dietPlanInfo.image2}`}
                  className="bordered-image"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DietTemplateView;
