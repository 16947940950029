import {
  DialogContent,
  DialogContentText,
  Stack,
  DialogTitle,
  Dialog,
  Typography,
  Select,
  FormControl,
  Menu,
  MenuItem,
  FormHelperText,
  Button,
  Grid,
} from "@mui/material";
import { useContext, useRef, useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Call from "../../assets/Call.png";
import { MuiTelInput } from "mui-tel-input";
import { PlivoContext } from "../../context/PlivoContext";
import { CALLER_ID, CALL_METHOD, INDIA_CALLER_ID } from "../../Constants";
import stateContext from "../../context/StateContext";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { updateId, updateCallPlatForm } from "../store/Call/CallItem";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const leadCategory = [
  { id: 1, name: "First Call" },
  { id: 3, name: "Follow up call" },
];

const CallPopup = (props) => {
  const location = useLocation();
  const pathName = location.pathname === "/Clients/listClients" ? "Client" : "";
  const dispatch = useDispatch();
  const id = useSelector((state) => state.leadId);
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);

  const [selectedMicroPhone, setSelectedMicroPhone] = useState("");
  const [selectedSpeaker, setSelectedSpeaker] = useState("");
  const [altPhoneNumber, setAltPhoneNumber] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [disableSpeaker, setDisableSpeaker] = useState(true);
  const [disableMicroPhone, setDisableMicroPhone] = useState(true);
  const [disableLeadCall, setDisableLeadCall] = useState(true);
  const [disableAltCall, setDisableAltCall] = useState(true);

  const [isErrCategory, setIsErrCategory] = useState(false);
  const [isErrSpeaker, setIsErrSpeaker] = useState(false);
  const [isErrMicrophone, setIsErrMicrophone] = useState(false);

  const [categoryList, setCategoryList] = useState(leadCategory);
  const [typeList, setTypeList] = useState([]);

  const callObjectInfo = useRef(null);
  const contextState = useContext(stateContext);

  const [current_pathname, setCurrent_pathname] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname.includes("Leads") ||
      window.location.pathname.includes("lead")
    ) {
      // setCurrent_pathname(true);

      setDisableLeadCall(false);
    } else {
      setCurrent_pathname(false);
      setDisableLeadCall(true);
    }
  }, []);

  useEffect(() => {
    if (props.role === "other") {
      setCurrent_pathname(false);
    } else if (props.role === "sale") {
      setCurrent_pathname(true);
      setSelectedCategory(5);
      dispatch(updateId(5));
      setDisableLeadCall(false);
    }
  }, [props.role]);

  // const current_pathname = window.location.pathname.includes("Leads");

  const {
    plivo,
    microphoneDevices,
    speakerDevices,
    setStatePlivo,
    currentCallStatus,
  } = useContext(PlivoContext);
  const { operator } = contextState.state;

  const handleClose = () => {
    setOpen(false);

    if (props.close !== undefined) {
      props.close(true);
    }
  };

  const handleChangeCategory = (event) => {
    dispatch(updateId(event.target.value));

    setSelectedCategory(event.target.value);
    if (event.target.value === "") {
      setIsErrCategory(true);
      setDisableLeadCall(true);
      setDisableAltCall(true);
    } else {
      setDisableLeadCall(false);
      setDisableAltCall(false);
      setIsErrCategory(false);
    }
  };

  const handleChangeAltPhNo = (value) => {
    setAltPhoneNumber(value);
    setDisableAltCall(false);
    callObjectInfo.current.alternativeNumber = value;
  };

  const handleChangeSpeaker = (e) => {
    setSelectedSpeaker(e.target.value);
    plivo.client.audio.speakerDevices.set(e.target.value);
  };

  const handleChangeMicroPhone = (e) => {
    setSelectedMicroPhone(e.target.value);
    plivo.client.audio.microphoneDevices.set(e.target.value);
  };

  function sanitizeNumber(input) {
    let sanitized = input.replace("-", "");
    sanitized = sanitized.replace(" -", "");
    sanitized = sanitized.replace("- ", "");
    // sanitized     = sanitized.replace('+','');
    sanitized = sanitized.replace(/\s+/g, "");
    return sanitized;
  }

  const getCategoryList = () => {
    if (
      window.location.pathname.includes("Leads") ||
      window.location.pathname.includes("lead")
    ) {
      return;
    }

    axiosFetch({
      url: "/interactionCategories",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          let response = res.data;

          if (window.location.pathname.includes("Clients")) {
            response = response.filter((data) => data.id !== 5);
          }

          setCategoryList(response);
        } else if (res.status === 400) {
        } else if (res.status === 401) {
        } else if (res.status === 500) {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTypeList = () => {
    axiosFetch({
      url: "/interactionTypes",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setTypeList(res.data);
        } else if (res.status === 400) {
          toast.success();
        } else if (res.status === 401) {
        } else if (res.status === 500) {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const callAction = (callNumber) => {
    const current_pathname = window.location.pathname;

    const pathName = window.location.pathname.split("/")[1];

    let leadId = props?.selectedRow?.id;

    const isclientDetail = window.location.pathname.split("/")[2];

    if (pathName === "Clients") {
      leadId =
        isclientDetail === "clientDetail"
          ? props?.selectedRow?.id
          : props?.selectedRow?.Lead;
    } else {
      leadId = props?.selectedRow?.id;
    }

    // const clientId = props?.selectedRow?.id;
    const clientId = id.value;

    // check already call is connected or ringing
    if (
      currentCallStatus === "ended" ||
      currentCallStatus === "Canceled" ||
      currentCallStatus === "Busy" ||
      currentCallStatus === ""
    ) {
    } else {
      // alert(`already call connected : ${currentCallStatus}`)
      toast.warn("During a call, you can not make another call !", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (props.callType === "plivo") {
      dispatch(updateCallPlatForm("plivo"));
    }

    if (selectedCategory === "") {
      setIsErrCategory(true);
      return false;
    } else {
      props.close();
      const callObj = {
        ...callObjectInfo.current,
        leadPhoneNumber: callNumber,
      };

      if (CALL_METHOD == "WebRTC") {
        const dest = sanitizeNumber(callObj.leadPhoneNumber);

        let customCallerId = CALLER_ID;
        if (dest.startsWith("+91")) {
          customCallerId = INDIA_CALLER_ID;
        }

        /* setState(prevState => ({ ...prevState, currentCallUUID: plivoBrowserSdk.current.client.getCallUUID() }))
      setNewTableRow({ ...paramVal.current.data, id: paramVal.current.data.lead_id })
      openFollowUpModal(callObjectInfo.current.leadId, state, setState)
      setCurrentCallStatus('Ringing') */

        const extraHeaders = {
          "X-PH-Test1": "test1",
          "X-PH-header1": customCallerId,
          "X-PH-leadId": window.location.href.match("/Clients/listClients")
            ? props?.leadId
            : callObjectInfo.current.leadId,
          "X-PH-userId": operator.id,
          "X-PH-page": 2,
          // "X-PH-catgoryId":,
          // "X-PH-setState": setState,
          // "X-PH-state": { ...state },
          // "X-PH-setNewTableRow": setNewTableRow,
          "X-PH-Obj": { kk: 1 },
          "X-PH-Arr": [1, 2],
          ooo: 123,
          ppp: { kk: 0 },
        };

        plivo.client.call(dest, extraHeaders);

        if (props?.selectedRow?.type) {
          history.push({
            pathname: `/${props.selectedRow.type}/${leadId}`,
            state: { tableValue: 1 },
          });
          return;
        }

        if (current_pathname.toLowerCase().includes("lead")) {
          history.push({
            pathname: `/lead/${leadId}`,
            state: { tableValue: 1 },
          });
        } else {
          history.push({
            pathname: `/Clients/clientDetail/${leadId}`,
            state: { tableValue: 1 },
          });
        }
      } else {
        axiosFetch({
          method: "post",
          url: "/makeOutboundCall",
          requestConfig: callObj,
        })
          // axios
          //   .post(`${ATLAS_URI}/makeOutboundCall/`, callObj, configToken)
          .then((callRes) => {
            if (callRes.status == 200) {
              // setState((prevState) => ({
              //   ...prevState,
              //   currentCallUUID: callRes.data.requestUuid,
              // }));
              // setNewTableRow({
              //   ...paramVal.current.data,
              //   id: paramVal.current.data.lead_id,
              // });
              // openFollowUpModal(callObjectInfo.current.leadId, state, setState);
              // if (current_pathname.includes("leads")) {
              //   history.push({
              //     pathname: `/lead/${leadId}`,
              //     state: { tableValue: 1 },
              //   });
              // } else {
              //   history.push({
              //     pathname: `/Clients/clientDetail/${clientId}`,
              //     state: { tableValue: 1 },
              //   });
              // }
            }
          });
      }
    }
  };

  useEffect(() => {
    if (microphoneDevices.length > 0) {
      setSelectedMicroPhone(microphoneDevices[0].deviceId);
      setSelectedSpeaker(speakerDevices[0].deviceId);
    }

    // if (current_pathname) {
    //   setCategoryList([{ id: 5, name: "Sales Call" }]);
    // } else {
    //   getCategoryList();
    // }
    getCategoryList();
    getTypeList();

    callObjectInfo.current = {
      agentPhoneNumber: operator.Mobile_number,
      leadPhoneNumber: props?.selectedRow?.LeadID?.contact_number,
      leadId: props?.selectedRow?.id,
      userId: operator.id,
      alternativeNumber: "",
    };
  }, []);

  useEffect(() => {
    if (current_pathname) {
      setSelectedCategory(5);
      dispatch(updateId(5));
    }
  }, [current_pathname]);

  function getCalNo(props) {
    //props?.selectedRow?.LeadID?.contact_number   props.selectedRow?.contact_number
    // order is imp

    if (pathName === "Client") {
      return props.selectedRow.Phone;
    }

    if (props && props.selectedRow && props.selectedRow.LeadID) {
      return props?.selectedRow?.LeadID?.contact_number;
    } else if (props && props.selectedRow && props.selectedRow.contact_number) {
      return props.selectedRow.contact_number;
    } else {
      return "-";
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      fullScreen={fullScreen}
      open={open}
      sx={{ width: "100% !important", height: "100% !important" }}
    >
      <span style={{ marginTop: "10px", marginLeft: "83%", color: "#757575" }}>
        <CloseIcon onClick={handleClose} />
      </span>

      <DialogTitle sx={{ fontWeight: "600", size: "18px", lineHeight: "27px" }}>
        Call Option
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          // sx={{ gap: "40px", width: "379px", height: "509px" }}
          sx={{
            gap: "40px",
            width: {
              xl: "379px",
              md: "100%",
              sm: "100%",
            },
            marginBottom: "20px",
          }}
        >
          <Grid container spacing={3} sx={{ width: "100%" }}>
            <Grid item xl={12} md={12} sm={6}>
              <Typography
                sx={{
                  color: "black",
                  width: "76px",
                  height: "24px",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
              >
                Category<span style={{ color: "red" }}>*</span>
              </Typography>

              <FormControl sx={{ top: "7px", marginBottom: "15px" }} fullWidth>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  disabled={current_pathname}
                  value={selectedCategory}
                  onChange={handleChangeCategory}
                >
                  <MenuItem value="">
                    <em style={{ color: "#9da5b1" }}>Please Select Category</em>
                  </MenuItem>
                  {categoryList.map((category) => (
                    <MenuItem value={category.id}>{category.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color: "red" }}>
                  {isErrCategory ? "Please Select Category" : ""}
                </FormHelperText>
              </FormControl>
              <Stack direction="row" spacing={2}>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "24px",
                    fontFamily: "Poppins",
                  }}
                >
                  Mobile:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400px",
                    fontSize: "16px",
                    marginLeft: "4px !important",
                  }}
                >
                  {getCalNo(props)}
                </Typography>

                <Button
                  onClick={() => callAction(getCalNo(props))}
                  disabled={disableLeadCall}
                >
                  <div
                    style={{
                      width: "37px",
                      height: "38px",
                      borderRadius: "6px",
                      backgroundColor: disableLeadCall ? "#D0D0D0" : "green",
                      marginTop: "-10px",
                    }}
                  >
                    <img
                      src={Call}
                      style={{
                        width: "18px",
                        height: "18px",
                        margin: "9px",
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                  </div>
                </Button>
              </Stack>
            </Grid>

            <Grid item xl={12} md={12} sm={6}>
              <Typography
                sx={{
                  color: "black",
                  width: "76px",
                  height: "24px",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  marginBottom: "7px",
                }}
              >
                Speaker
              </Typography>

              <FormControl sx={{ minWidth: 120 }} fullWidth>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={selectedSpeaker}
                  onChange={handleChangeSpeaker}
                  // disabled={disableSpeaker}
                >
                  {speakerDevices.map((item) => (
                    <MenuItem value={item.deviceId}>{item.label} </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Without label</FormHelperText> */}
              </FormControl>
            </Grid>
            <Grid item xl={12} md={12} sm={12}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Poppins",
                }}
              >
                Micro Phone
              </Typography>
              <FormControl sx={{ minWidth: 120, top: "7px" }} fullWidth>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  // sx={{
                  //   width: "378px",
                  //   height: "46px",
                  //   borderRadius: "6px",
                  //   border: "2px",
                  // }}
                  value={selectedMicroPhone}
                  onChange={handleChangeMicroPhone}
                  // disabled={disableMicroPhone}
                >
                  {microphoneDevices.map((item) => (
                    <MenuItem value={item.deviceId}>{item.label}</MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Without label</FormHelperText> */}
              </FormControl>
            </Grid>
            <Grid item xl={12} md={12} sm={12}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Poppins",
                  marginBottom: "7px",
                }}
              >
                Alternative Number
              </Typography>

              <Stack
                direction="row"
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <MuiTelInput
                  fullWidth
                  // sx={{
                  //   width: "378px",
                  //   height: "46px",
                  //   borderRadius: "6px",
                  //   border: "2px",
                  //   top: "7px",
                  // }}
                  value={altPhoneNumber}
                  onChange={handleChangeAltPhNo}
                  defaultCountry="IN"
                />
                <Button
                  disabled={disableAltCall}
                  onClick={() =>
                    callAction(callObjectInfo?.current?.alternativeNumber)
                  }
                >
                  <div
                    style={{
                      width: "37px",
                      height: "38px",
                      borderRadius: "6px",
                      backgroundColor: disableAltCall ? "#D0D0D0" : "green",
                    }}
                  >
                    <img
                      src={Call}
                      style={{
                        width: "18px",
                        height: "18px",
                        margin: "9px",
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                  </div>
                </Button>
              </Stack>
            </Grid>

            <Grid item xl={12} md={12} sm={6}></Grid>
          </Grid>

          <br />
          <br />

          <br />

          <br />
          <br />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default CallPopup;
