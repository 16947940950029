import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CallDetailsForm from "./CallDetailsForm";
import InteractionList from "./InteractionList";
import AddIcon from "@mui/icons-material/Add";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

const Interaction = ({ callFlag, setCallFlag, onSetIsBackDrop }) => {
  let params = useParams();
  let history = useHistory();

  let operator = JSON.parse(window.localStorage.getItem("operator"));

  let [endCall, setEndCall] = useState(false);

  let [isShowForm, setIsShowForm] = useState(false);
  let [interactionList, setInteractionList] = useState([]);

  let [endCallId, setEndCallId] = useState("");

  useEffect(() => {
    // setIsShowForm(callFlag);
    setEndCall(false);
  }, [callFlag]);

  const showForm = () => {
    setIsShowForm(true);
  };

  const handleMakeFalse = () => {
    setIsShowForm(false);
    setCallFlag(false);
    setEndCallId("");
  };

  const handleEndCall = () => {
    axiosFetch({
      method: "get",
      url: `/getInteractionByRoleIdAndLeadId?leadId=${params.id}&roleId=${operator.Role}&userId=${operator.id}&page=1&perPage=10&isCallUUID=true`,
    })
      .then((res) => {
        if (res.status === 200) {
          setEndCall(true);
          setEndCallId(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getInteractionList = () => {
    axiosFetch({
      method: "get",
      url: `/getInteractionByRoleIdAndLeadId?leadId=${params.id}&roleId=${operator.Role}&userId=${operator.id}&page=1&perPage=10`,
    })
      .then((res) => {
        if (res.status === 200) {
          setInteractionList(res.data.interactionData);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getInteractionList();
  }, []);
  return (
    <div>
      {!isShowForm ? (
        <Button
          onClick={showForm}
          variant="outlined"
          sx={{
            margin: "7px",
            color: "white",
            backgroundColor: "black",
            textTransform: "capitalize",

            "&:hover": {
              color: "white",
              backgroundColor: "black",
            },
          }}
        >
          <AddIcon />
          Create Interaction
        </Button>
      ) : (
        ""
      )}
      {isShowForm ? (
        <CallDetailsForm
          isShowForm={isShowForm}
          callFlag={callFlag}
          getInteractionList={getInteractionList}
          handleEndCall={handleEndCall}
          endCall={endCall}
          endCallId={endCallId}
          onMakeFalse={handleMakeFalse}
          onSetIsBackDrop={onSetIsBackDrop}
        />
      ) : (
        ""
        )}

      <InteractionList interactionList={interactionList} />
    </div>
  );
};
export default Interaction;
