import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import ProgramList from "./ProgramList";
import ProgramListNew from "./ProgramListNew.jsx";
import { Button, Grid, Typography } from "@mui/material";
import FeatureList from "./FeatureList";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";

import ProgramListTable from "./components/ProgramListTable.jsx";

export default function ProgramsNew() {
  const history = useHistory();
  const [programList, setProgramList] = useState([]);
  const [featureList, setFeatureList] = useState([]);

  const getProgramList = (params) => {
    let url;
    if (params !== undefined) {
      url = `/getProducts/${params}`;
    } else {
      url = `/getProducts/`;
    }
    axiosFetch({
      url: url,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setProgramList(res.data);
          if (res.data.length !== 0) {
            getFeatureList(res.data[0].id);
          }
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getFeatureList = (id) => {
    axiosFetch({
      method: "get",
      url: `/getProductLinesByProdId/${id}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setFeatureList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getProgramList();
    // getFeatureList();
  }, []);

  const addRow = () => {
    history.push("/programs/createNewProgramNew");
  };

  return (
    <div className="new-client">
      <div className="new-cient-container">
        <div className="d-flex justify-content-between mb-4">
          <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
            {"Programs List"}
          </Typography>

          <Button
            variant="contained"
            onClick={() => addRow()}
            sx={{
              backgroundColor: "#2a2a2a",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
          >
            <AddIcon /> Add New Program
          </Button>
        </div>
        <ProgramListTable />
      </div>
    </div>
  );
}
