import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import * as $ from "jquery";
import ChangePassword from "./ChangePassword";
import NotifyMe from "./NotifyMe";
import { ATLAS_URI } from "../Constants";
import stateContext from "../context/StateContext";

import VmaxLogo from "../assets/VmaxLogo.png";
import { axiosFetch } from "../Utlis/AxiosFetch";

function TopHeader() {
  const contextState = useContext(stateContext);

  const [data, setData] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [businessLogo, setBusinessLogo] = useState("base-logo.png");
  const [isChangePasswordOpened, setChangePasswordOpened] = useState(false);
  const configToken = JSON.parse(window.localStorage.getItem("operator")) && {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(window.localStorage.getItem("operator")).token
      }`,
    },
  };
  useEffect(() => {
    //get Configurations
    axios
      .get(`${ATLAS_URI}/getConfiguration/`, configToken)
      .then((response) => {
        if (typeof response.data[0] !== "undefined") {
          setBusinessName(response.data[0].BusinessName);
          setBusinessLogo(response.data[0].BusinessLogo);
        }
      })
      .catch((err) => console.log(err));
  }, [ATLAS_URI]);

  const logoutOperator = async (e) => {
    const loginActivityId = JSON.parse(localStorage.getItem("loginActivityId"));

    const response = await axiosFetch({
      url: `/updateLogoutActivity`,
      method: "post",
      requestConfig: {
        loginActivityID: loginActivityId,
      },
    });

    if (response.status === 200) {
      window.localStorage.removeItem("states");
      window.localStorage.removeItem("operator");
      window.localStorage.removeItem("LEAD_LC_STORAGE_GRID");
      window.localStorage.removeItem("LEAD_LC_STORAGE_PANEL");
      window.localStorage.removeItem("RESTORE_LEAD_LISTING_SCREEN_GRID");
      window.localStorage.removeItem("RESTORE_LEAD_LISTING_SCREEN_PANEL");
      window.localStorage.removeItem("goto_clients_workoutplan_page");
      window.localStorage.removeItem("CLIENT_LC_STORAGE");
      window.localStorage.removeItem("RESTORE_CLIENT_LISTING_SCREEN");
      window.location.replace("/");
    }
  };

  const openProfileDropdown = (e) => {
    if ($(".profile-dropDown").hasClass("visible")) {
      $(".showProfileOption > i")
        .removeClass("fa-chevron-up")
        .addClass("fa-chevron-down");
      $(".profile-dropDown").removeClass("visible");
    } else {
      $(".showProfileOption > i")
        .removeClass("fa-chevron-down")
        .addClass("fa-chevron-up");
      $(".profile-dropDown").addClass("visible");
    }
  };

  useEffect(() => {
    /*axios.get(`${ATLAS_URI}/getClients/`, configToken)
            .then(res1 => {
                let clientsData = res1.data;
                axios.get(`${ATLAS_URI}/getAgreements/`, configToken)
                    .then(response => {
                        let data = []
                        let agreementData = response.data;

                        agreementData.forEach(element => {

                            if (typeof element.ToDate !== 'undefined' && element.ToDate !== '') {
                                if (returnTime(element.ToDate) <= getTodayTime()) {
                                    data.push({
                                        id: element.id,
                                        type: "Agreement Renewable",
                                        update: `Agreement: ${element.Name} has expired on ${element.ToDate}`,
                                        Date: getDateFormat(returnDate(element.ToDate)),
                                        timestamp: returnDate(element.ToDate).getTime(),
                                        alertType: "alert-warning",
                                        icon: "fa-exclamation-triangle"
                                    })
                                }
                            }
                        });
                        clientsData.forEach(element => {
                            let today = new Date();
                            if (typeof element.Anniversary !== 'undefined' && element.Anniversary !== '') {
                                let date = returnDate(element.Anniversary);
                                date.setFullYear(today.getFullYear())
                                if (date.getTime() <= getTodayTime()) {

                                    data.push({
                                        id: element.id,
                                        type: "Anniversary",
                                        update: `Client: ${element.Name} Anniversary is on ${element.Anniversary}`,
                                        Date: getDateFormat(date),
                                        timestamp: date.getTime(),
                                        alertType: "alert-info",
                                        icon: "fa-info-circle"
                                    })
                                }
                            }
                            if (typeof element.DOB !== 'undefined' && element.DOB !== '') {
                                let date = returnDate(element.DOB);
                                date.setFullYear(today.getFullYear())
                                if (date.getTime() <= getTodayTime()) {

                                    data.push({
                                        id: element.id,
                                        type: "Birthday",
                                        update: `Client: ${element.Name} Birthday is on ${element.Anniversary}`,
                                        Date: getDateFormat(date),
                                        timestamp: date.getTime(),
                                        alertType: "alert-info",
                                        icon: "fa-info-circle"
                                    })

                                }
                            }
                        })
                        setData(data.sort((a, b) => b.timestamp - a.timestamp))
                    }).catch(err => console.log(err))
            }).catch(err => console.log(err))*/
  }, []);

  function getDateFormat(today) {
    const date = today.getDate();
    const month = String(parseInt(today.getMonth()) + 1);
    const year = today.getFullYear();
    return (
      (date < 10 && "0") + date + "-" + (month < 10 && "0") + month + "-" + year
    );
  }

  return (
    <React.Fragment>
      <section id="topHeader">
        {/* <img alt="logo" style={{ margin: "0 5px", objectFit: "cover" }} src={`${ATLAS_URI}/static/${businessLogo}`} height={35}></img> */}
        {/* <span className="brandTitle">{businessName}</span> */}

        <img
          src={VmaxLogo}
          alt="vmax-logo"
          style={{ width: "150px", marginTop: "-10px" }}
        />

        <span className="profileArea pull-right">
          <div className="row">
            <div className="col-sm-2 col-5">
              <i className="fas fa-user-tie userProfile" />
            </div>
            <div className="col-sm-6 d-none d-sm-block userInfo">
              <div className="fw700 fs16">
                {contextState.state.operator.Name}
              </div>
              <div className="fs14 color-g">
                {contextState.state.operator.Role}
              </div>
            </div>
            <div className="col-sm-2 col-4">
              <button
                className="headIcons showProfileOption"
                onClick={openProfileDropdown}
              >
                <i className="fas fa-chevron-down" />
              </button>
            </div>
            <div className="col-sm-2 col-3">
              <NotifyMe
                data={data}
                storageKey="notific_key"
                notific_key="timestamp"
                notific_value="update"
                heading="Notification Alerts"
              />
            </div>
          </div>

          <span className="profile-dropDown dropDown">
            <div className="option">
              <button
                onClick={() => {
                  setChangePasswordOpened(true);
                }}
              >
                <i className="fas fa-cog" /> Change Password
              </button>
            </div>
            <div className="option">
              <button onClick={logoutOperator}>
                <i className="fas fa-sign-out" /> Log Out
              </button>
            </div>
            <div className="option d-flex ms-3 align-items-center">
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  marginRight: "1px",
                  marginLeft: "-3px",
                }}
              >
                V
              </span>
              <span className="ms-3">6.1.0</span>
            </div>
          </span>
        </span>
        <span className="lastLogin pull-right d-none d-md-block">
          <div className="fw700 fs16">Last Login</div>
          <div className="fs14 color-g">
            {contextState.state.operator.LastLogin}
          </div>
        </span>
      </section>

      <ChangePassword
        isOpened={isChangePasswordOpened}
        closeModal={() => {
          setChangePasswordOpened(false);
        }}
        username={contextState.state.operator.Username}
        BACKEND_URI={ATLAS_URI}
        setChangePasswordOpened={setChangePasswordOpened}
      />
    </React.Fragment>
  );
}

export default React.memo(TopHeader);
