import { React, useState, useEffect } from "react";
import DataViewer from "../../../components/DataViewer/DataViewer";
import {
  Grid,
  Typography,
  OutlinedInput,
  FormHelperText,
  Stack,
  Container,
  Button,
} from "@mui/material";

import { TextareaAutosize } from "@mui/base";
import ControlledTextField from "../../../components/ControlledTextField";
import { FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../../components/ControlledAutocomplete";
import ControlledRadioGroup from "../../../components/ControlledRadioGroup";
import ControlledTimepicker from "../../../components/ControlledTimepicker";
import ControllerDatetimepicker from "../../../components/ControlledDatetimepicker";
import FormRenderer from "../../../components/FormRenderer/FormRenderer";
import formdata from "./diet_preference";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { date } from "yup";
import { toast } from "react-toastify";
import { styled } from "@mui/system";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};
const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
 

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

/* 
What is your Most Challenging Pain Point which is stopping you from achieving your fitness goals?

* No Time to Exercise
* Lack of Proper Knowledge about Planned and Balanced Nutrition
* No Time to prepare Healthy meals at home
* Healthy food is not Tasty
* No Motivation & Consistency
* Physical Injuries
* Fitness is Expensive and Inaccessible
* Fitness is not Fun


*/

const challenges = [
  "No Time to Exercise",
  "Lack of Proper Knowledge about Planned and Balanced Nutrition",
  "No Time to prepare Healthy meals at home",
  "Healthy food is not Tasty",
  "No Motivation & Consistency",
  "Physical Injuries",
  "Fitness is Expensive and Inaccessible",
  "Fitness is not Fun",
];

const OtherInformation = ({ displaydata, update }) => {
  const [isedit, setIsedit] = useState(false);
  const [masters, setMasters] = useState({});
  const [isothermedication, setIsothermedication] = useState(false);
  const [infoview, setInfoview] = useState({});

  const fitnessGoal = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [selectedFitnessGoal, setSelectedFitenessGoal] = useState("");

  const defaultValues = {
    createDate: new Date(),
  };
  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors, isValid },
  } = methods;
  let is_tablet_recommented = watch("tablets_recommended_if_yes");
  useEffect(() => {
    if (is_tablet_recommented == "Yes") {
      setIsothermedication(true);
    } else {
      setIsothermedication(false);
    }
  }, [is_tablet_recommented]);
  const handleClick = (event) => {
    setIsedit(!isedit);
  };

  const handleCancel = (event) => {
    setIsedit(false);
  };

  const [editChallenge, setEditChallenge] = useState("");
  useEffect(() => {
    if (displaydata) {
      let dietInfo = displaydata?.others;
      setEditChallenge(dietInfo?.lead_pain_point?.join(","));
      setValue("know_us", dietInfo?.know_us);
      setValue("profession_details", dietInfo?.profession_details);
      setValue("vmax_fit_goal", dietInfo?.vmax_fit_goal?.join(","));
      setValue("lead_pain_point", dietInfo?.lead_pain_point?.join(","));
      setValue("lead_body_pain", dietInfo?.lead_body_pain?.join(","));
      setValue("vmax_fitness_scale", dietInfo?.vmax_fitness_scale);

      setValue("vmax_fit_scale_reason", dietInfo?.vmax_fit_scale_reason);

      //  setValue("mealtimings",dietInfo?.mealtimings)

      // setValue("gender",basicinfo?.gender)
      // setValue("dob",basicinfo?.dob)
    }
  }, [displaydata]);

  const handlemedicalassestment = async (data) => {
    update({
      ...data,
      vmax_fitness_scale: selectedFitnessGoal,
      formtype: 7,
    });
  };

  const current_medical_condition_arr = watch("current_medical_condition");
  useEffect(() => {
    if (current_medical_condition_arr?.indexOf("Others") == -1) {
      setValue("other_medical_condition", "");
    } else {
    }
  }, [current_medical_condition_arr]);

  const any_deficiency = watch("any_deficiency");
  useEffect(() => {
    if (any_deficiency?.indexOf("Others") == -1) {
      setValue("other_deficiency", "");
    } else {
    }
  }, [any_deficiency]);

  const lead_body_pain = watch("lead_body_pain");
  useEffect(() => {
    if (lead_body_pain?.indexOf("Others") == -1) {
      setValue("other_bodyache", "");
    } else {
    }
  }, [lead_body_pain]);
  const tablets_recommended_if_yes = watch("tablets_recommended_if_yes");
  useEffect(() => {
    if (tablets_recommended_if_yes?.toLowerCase() == "no") {
      setValue("tablets_recommended", "");
    } else {
    }
  }, [tablets_recommended_if_yes]);

  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit((data) => {
          setIsedit(false);
          handlemedicalassestment(data);
        })}
      >
        <Grid container xs={12} spacing={2}>
          <Grid item xs={4}>
            <Stack spacing={2}>
              <Typography>How did you get to know about us?</Typography>
              {isedit ? (
                <ControlledTextField
                  label={"Please specify"}
                  name={"know_us"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  //   isDisableColumn={getValues("current_medical_condition")?.indexOf('Others') == -1}
                />
              ) : (
                <Typography variant="h6">
                  {displaydata?.others?.know_us ?? "--"}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={2}>
              <Typography>More details about your Profession</Typography>
              {isedit ? (
                <ControlledTextField
                  label={"Please specify"}
                  name={"profession_details"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  //   isDisableColumn={getValues("current_medical_condition")?.indexOf('Others') == -1}
                />
              ) : (
                <Typography variant="h6">
                  {displaydata?.others?.profession_details ?? "--"}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={2}>
              <Typography>
                What is your Most Primary Fitness Goal from the list below
              </Typography>
              {isedit ? (
                <ControlledTextField
                  label={"Please specify"}
                  name={"vmax_fit_goal"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  //   isDisableColumn={getValues("current_medical_condition")?.indexOf('Others') == -1}
                />
              ) : (
                <Typography variant="h6">
                  {displaydata?.others?.vmax_fit_goal ?? "--"}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={2}>
              <Typography>
                What is your Most Challenging Pain Point which is stopping you
                from achieving your fitness goals?
              </Typography>
              {isedit ? (
                <ControlledAutocomplete
                  label={"Please specify"}
                  name={"lead_pain_point"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={editChallenge}
                  options={challenges}
                  //   isDisableColumn={getValues("current_medical_condition")?.indexOf('Others') == -1}
                />
              ) : (
                <Typography variant="h6">
                  {displaydata?.others?.lead_pain_point ?? "--"}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={5}>
              <Typography>Any existing Body Pain?</Typography>
              {isedit ? (
                <ControlledTextField
                  label={"Please specify"}
                  name={"lead_body_pain"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  //   isDisableColumn={getValues("current_medical_condition")?.indexOf('Others') == -1}
                />
              ) : (
                <Typography variant="h6">
                  {displaydata?.others?.lead_body_pain ?? "--"}
                </Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={2}>
              <Typography>
                On a scale of 1-10, how committed are you to achieving your
                Fitness Goals?
              </Typography>
              {isedit ? (
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Height
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setSelectedFitenessGoal(e.target.value)}
                      value={selectedFitnessGoal}
                      label="Height"
                    >
                      {fitnessGoal.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          <div className="filter-date">
                            <span>{value}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Typography variant="h6">
                  {displaydata?.others?.vmax_fitness_scale ?? "--"}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={2}>
              <Typography>
                {" "}
                If your answer to the above question was below 8, then Please
                specify why?
              </Typography>
              {isedit ? (
                <ControlledTextField
                  label={"Please specify"}
                  name={"vmax_fit_scale_reason"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  //   isDisableColumn={getValues("current_medical_condition")?.indexOf('Others') == -1}
                />
              ) : (
                <Typography variant="h6">
                  {displaydata?.others?.vmax_fit_scale_reason ?? "--"}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          {isedit && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="outlined" onClick={handleCancel}>
                {" "}
                Cancel{" "}
              </Button>
              <Button variant="contained" type="submit">
                {" "}
                Save{" "}
              </Button>
            </Stack>
          )}
        </Grid>
      </form>
      {!isedit && (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button variant="contained" onClick={handleClick}>
              {" "}
              Edit{" "}
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default OtherInformation;

//btn-disabled
