import * as React from "react";
import { useEffect, useState, useRef } from "react";
import {
  DataGrid,
  GridCellModes,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { ATLAS_URI } from "../../Constants";
import { useContext } from "react";
import stateContext from "../../context/StateContext";
import { dateConverter } from "../../Utlis/dateConversion";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const ProgramList = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const endDateRef = useRef();
  const startDateRef = useRef();
  const history = useHistory();
  const open = Boolean(anchorEl);
  const contextState = useContext(stateContext);
  let { configToken } = contextState.state;

  const [inputList, setInputList] = useState([
    {
      name: "",
    },
  ]);

  const [isDisabled, setIsDisabled] = useState(true);

  const [featurePopover, setFeaturePopover] = React.useState(null);

  const openFeature = Boolean(featurePopover);
  const id = openFeature ? "simple-popover" : undefined;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rowId, setRowId] = useState("");

  const [popover, setPopover] = React.useState(null);
  const [featureName, setFeatureName] = useState("");

  const handleChangeFeatureName = (e) => {
    setFeatureName(e.target.value);
  };

  const handleClickPopover = (event) => {
    setPopover(event.currentTarget);
    handleClose();
    handleDelete();
  };

  const handleClickFeaturePopup = (event) => {
    handleClose();
    setFeaturePopover(event.currentTarget);
  };

  const handleEdit = (event) => {
    handleClose();
    history.push(`/programs/edit/${rowId}`);
  };

  const viewClient = () => {
    handleClose();
    history.push("/programs/client");
  };

  const handleViewFeature = () => {
    handleClose();
    props.getFeatureList(rowId);
  };
  const handleCloseFeaturePopup = () => {
    setFeaturePopover(null);
  };

  const handleClosePopover = () => {
    setPopover(null);
  };

  const handleListAdd = () => {
    setIsDisabled(true);
    setInputList([
      ...inputList,
      {
        name: "",
      },
    ]);
  };

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    const newInputList = [...inputList];

    if (event.target.value !== "") {
      newInputList[index].name = value;
      // newInputList[index].input_rank = index + 1;
      setInputList(newInputList);
    }

    if (event.target.value !== "") {
      inputList[inputList.length - 1].name === ""
        ? setIsDisabled(true)
        : setIsDisabled(false);
    }
  };

  const handleRemoveItem = (index) => {
    setIsDisabled(false);
    const newList = [...inputList];
    newList.splice(index, 1);
    setInputList(newList);
  };

  const openPopover = Boolean(popover);

  const columns = [
    {
      field: "name",
      headerName: "Program Name",
      type: "string",
      width: 200,
      editable: true,
      valueGetter: (params) => (!!params.row.name ? params.row.name : "-"),
    },
    {
      field: "parent_prod_num",
      headerName: "Parent Program  ID",
      type: "number",
      width: 150,
      editable: true,
      valueGetter: (params) =>
        !!params.row.parent_prod_id ? params.row.parent_prod_id : "-",
    },
    {
      field: "id",
      headerName: "Program ID",
      type: "number",
      width: 150,
      editable: false,
      valueGetter: (params) => (!!params.row.id ? params.row.id : "-"),
    },

    {
      field: "category",
      headerName: "Program Category",
      type: "singleSelect",
      valueOptions: ["Service", "Product"],
      width: 150,
      editable: true,
      valueGetter: (params) =>
        !!params.row.category ? params.row.category : "-",
    },
    {
      field: "duration",
      headerName: "Program Duration",
      type: "number",
      width: 150,
      editable: true,
      renderCell: (params) => {
        const duration = params.row.duration;

        if (duration && duration.length) {
          return (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                width: "120px",
              }}
            >
              {duration.map((item, index) => (
                <Chip label={item.duration} key={index} />
              ))}
            </Stack>
          );
        }
      },
    },
    {
      field: "duration_unit",
      headerName: "Program Duration Units",
      type: "singleSelect",
      valueOptions: ["days", "week", "month", "year"],
      width: 200,
      editable: true,
      valueGetter: (params) =>
        !!params.row.duration_unit ? params.row.duration_unit : "-",
    },
    {
      field: "comments",
      headerName: "Description",
      width: 250,
      editable: true,
      valueGetter: (params) =>
        !!params.row.comments ? params.row.comments : "-",
    },
    {
      field: "eff_start_date",
      headerName: "Start Date",
      // type: "date",
      width: 150,
      editable: true,
      valueGetter: (params) => {
        if (params.row.eff_start_date !== null) {
          //return params.row.eff_start_date.split("T")[0];
          return dateConverter(params.row.eff_start_date);
        } else {
          return "-";
        }
      },
    },
    {
      field: "eff_end_date",
      headerName: "End Date",
      // type: "date",
      width: 150,
      editable: true,
      valueGetter: (params) => {
        if (params.row.eff_end_date !== null) {
          //return params.row.eff_end_date.split("T")[0];
          return dateConverter(params.row.eff_end_date);
        } else {
          return "-";
        }
      },
    },
    {
      field: "version",
      headerName: "Program Version",
      editable: false,
      width: 200,
      valueGetter: (params) =>
        !!params.row.version ? params.row.version : "-",
    },
    {
      field: "type",
      headerName: "Program  Type",
      type: "singleSelect",
      valueOptions: ["Live Coaching", "Live Webinars", "Transformation"],
      width: 150,
      editable: true,
      valueGetter: (params) => (!!params.row.type ? params.row.type : "-"),
    },
    {
      field: "sub_type",
      headerName: "Program Sub Type",
      type: "singleSelect",
      valueOptions: [
        "Dance",
        "Yoga",
        "Strength & conditioning",
        "Sports drill",
        "Special movements",
        "HIIT",
        "Cardio",
      ],

      width: 150,
      editable: true,
      valueGetter: (params) =>
        !!params.row.sub_type ? params.row.sub_type : "-",
    },
    {
      field: "active_flg",
      headerName: "Active Program",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "approval_flg",
      headerName: "Program Approval",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "auto_allocate_flg",
      headerName: "Program Auto Allocation",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "billabe_flg",
      headerName: "Program Billable",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "add_to_quote_flg",
      headerName: "Program Quotation",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "mobile_app_ad_visible_flg",
      headerName: "Mobile UI Banner Visibility",
      type: "boolean",
      width: 150,
      editable: true,
    },

    {
      field: "orderable_flg",
      headerName: "Program Items Ordered",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "parent_prod_id",
      headerName: "Program ID",
      width: 150,
      editable: false,
      valueGetter: (params) =>
        !!params.row.parent_prod_id ? params.row.parent_prod_id : "-",
    },

    {
      field: "mobile_ui_banner_text",
      headerName: "Mobile UI Banner",
      width: 150,
      editable: true,
      valueGetter: (params) =>
        !!params.row.mobile_ui_banner_text
          ? params.row.mobile_ui_banner_text
          : "-",
    },
    // {
    //   field: "s3KeyName",
    //   headerName: "s3KeyName",
    //   // type: "boolean",
    //   width: 150,
    //   editable: true,
    //   valueGetter: (params) =>
    //     !!params.row.s3KeyName ? params.row.s3KeyName : "-",
    // },
    {
      field: "product_num",
      headerName: "Program NO",
      type: "number",
      width: 150,
      editable: false,
      valueGetter: (params) =>
        !!params.row.product_num ? params.row.product_num : "-",
    },
    {
      field: "service_flg",
      headerName: "Program Service",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "ship_flg",
      headerName: "Shipping",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "vendor_part_num",
      headerName: "Vendor Product NO",
      // type: "singleSelect",
      width: 150,
      editable: true,
      valueGetter: (params) =>
        !!params.row.vendor_part_num ? params.row.vendor_part_num : "-",
    },
    {
      field: "vendor_part_id",
      headerName: "Vendor Product ID",
      // type: "singleSelect",
      width: 150,
      editable: true,
      valueGetter: (params) =>
        !!params.row.vendor_part_num ? params.row.vendor_part_num : "-",
    },

    {
      field: "x_gst_exempt_flag",
      headerName: "Tax Exemption",
      type: "boolean",
      width: 150,
      editable: true,
    },
    {
      field: "x_ship_to_address_req",
      headerName: "Shipment Address",
      width: 150,
      editable: true,
      valueGetter: (params) =>
        !!params.row.x_ship_to_address_req
          ? params.row.x_ship_to_address_req
          : "-",
    },
    {
      field: "x_tax_status",
      headerName: "Tax Status",
      // type: "singleSelect",
      width: 150,
      valueGetter: (params) =>
        !!params.row.x_tax_status ? params.row.x_tax_status : "-",
      // editable: true,
    },
    {
      field: "x_tax_status_desc",
      headerName: "Tax Status Description",

      width: 150,
      editable: true,
      valueGetter: (params) =>
        !!params.row.x_tax_status_desc ? params.row.x_tax_status_desc : "-",
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        if (params.row.createdAt !== null) {
          // return params.row.createdAt.split("T")[0];
          return dateConverter(params.row.createdAt);
        } else {
          return "-";
        }
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated Date",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        if (params.row.updatedAt !== null) {
          //return params.row.updatedAt.split("T")[0];
          return dateConverter(params.row.updatedAt);
        } else {
          return "-";
        }
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 150,
      editable: false,
      valueGetter: (params) =>
        !!params.row.created_by ? params.row.created_by : "-",
    },
    {
      field: "updated_by",
      headerName: "Last Updated By",
      width: 150,
      editable: false,
      valueGetter: (params) =>
        !!params.row.created_by ? params.row.created_by : "-",
    },
    {
      field: "Actions",
      headerName: "Actions",
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="delete"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => handleClick(e, params)}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    name: true,
    parent_prod_num: false,
    id: true,
    eff_start_date: true,
    eff_end_date: true,
    duration: true,
    duration_unit: true,
    orderable_flg: true,
    active_flg: true,
    approval_flg: true,
    auto_allocate_flg: false,
    billabe_flg: false,
    add_to_quote_flg: false,
    mobile_app_ad_visible_flg: false,
    category: true,
    comments: false,
    orderable_flg: false,
    parent_prod_id: false,
    mobile_ui_banner_text: false,
    s3KeyName: false,
    product_num: false,
    service_flg: false,
    ship_flg: false,
    vendor_part_id: false,
    vendor_part_num: false,
    version: true,
    type: false,
    sub_type: false,
    x_gst_exempt_flag: false,
    x_ship_to_address_req: false,
    x_tax_status: false,
    x_tax_status_desc: false,
    createdAt: true,
    updatedAt: false,
    created_by: true,
    updated_by: false,
  });
  const [cellModesModel, setCellModesModel] = React.useState({});
  const [keyword, setKeyword] = useState("");
  const [rowSelection, setRowSelection] = useState([
    props?.programList.length !== 0 && props?.programList[0].id,
  ]);

  const handleChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleClick = (event, params) => {
    setRowId(params.id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    setInputList([
      {
        name: "",
      },
    ]);
  };

  const handleDelete = () => {
    setPopover(null);
    axiosFetch({
      url: `/deleteProduct/${rowId}`,
      method: "delete",
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Program deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.getProgramList();
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleCellClick = React.useCallback((params) => {
    setRowId(params.id);
    if (
      params.field !== undefined &&
      params.field !== "Actions" &&
      params.row !== undefined
    ) {
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    }
  }, []);
  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow) => {
    let formData = new FormData();

    const updatedRow = { ...newRow, isNew: false };

    for (const i in updatedRow) {
      if (
        updatedRow[i] !== undefined &&
        updatedRow[i] !== null &&
        updatedRow[i] !== "null"
      ) {
        formData.append(i, updatedRow[i]);
      }
    }
    //handle send data to api

    axios
      .put(`${ATLAS_URI}/updateProduct/${updatedRow.id}`, formData, configToken)
      // axiosFetch({
      //   method: "put",
      //   url: `/updateProduct/${updatedRow.id}`,
      //   requestConfig: formData,
      // })
      .then((res) => {
        if (res.status === 200) {
          // toast.success("Program Updated Successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return updatedRow;
  };

  const handleChangeRowSelection = (id) => {
    setRowSelection(id);
  };

  const handleSave = () => {
    handleCloseFeaturePopup();

    axiosFetch({
      method: "post",
      url: `/postProductLines/${rowId}`,
      requestConfig: {
        product_line: inputList.filter((item) => item.name !== ""),
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Feature Added Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.getFeatureList(rowId);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onChangeColumn = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  const handleClear = () => {
    setKeyword("");
    setStartDate("");
    setEndDate("");
    props.getProgramList();
  };

  const handleSearch = () => {
    let string = "";
    if (!!keyword) {
      string = `?search=${keyword}`;
    }
    if (!!startDate && string !== "") {
      string = string + `&startDate=${startDate}`;
    } else if (!!startDate && string === "") {
      string = `startDate=${startDate}`;
    }
    if (!!endDate && string !== "") {
      string = string + `&endDate=${endDate}`;
    } else if (!!endDate && string === "") {
      string = `endDate=${endDate}`;
    }
    props.getProgramList(string);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 700, width: "100%" }}>
      <Typography sx={{ margin: "31px", fontSize: "25px", fontWeight: "600" }}>
        Program List
      </Typography>

      <Card sx={{ minWidth: 275, margin: "32px" }}>
        <CardContent>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            Filters
          </Typography>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Search "
                variant="outlined"
                placeholder="Search"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <input
                placeholder="Start Date"
                class="textbox-n"
                type="text"
                onFocus={() => (startDateRef.current.type = "date")}
                onBlur={() => (startDateRef.current.type = "text")}
                id="date"
                ref={startDateRef}
                value={startDate}
                onChange={handleChangeStartDate}
              />
            </Grid>
            <Grid item xs={4}>
              <input
                placeholder="End Date"
                class="textbox-n"
                type="text"
                onFocus={() => (endDateRef.current.type = "date")}
                onBlur={() => (endDateRef.current.type = "text")}
                id="date"
                ref={endDateRef}
                value={endDate}
                onChange={handleChangeEndDate}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ marginLeft: "38%" }}>
          <Button variant="contained" onClick={() => handleSearch()}>
            Search
          </Button>
          <Button variant="contained" onClick={() => handleClear()}>
            Clear
          </Button>
        </CardActions>
      </Card>
      <DataGrid
        editMode="row"
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => onChangeColumn(newModel)}
        rows={props.programList}
        columns={columns}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(err) => console.log(err)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pagination
        // autoPageSize
        slots={{ toolbar: CustomToolbar }}
        pageSizeOptions={[10]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }
        sx={{
          ".MuiDataGrid-row.Mui-even": {
            backgroundColor: "#DCD6D0",
          },
          ".MuiDataGrid-row.Mui-odd": {
            backgroundColor: "#fff",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            overflow: "visible !important",
          },
        }}
      />

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        // style={{ transform: "translateY(-300px)" }}
      >
        {/* <MenuItem onClick={(e) => viewClient(e)}>
          <VisibilityIcon />
          &nbsp;&nbsp; View Clients
        </MenuItem>
        <hr /> */}
        <MenuItem onClick={(e) => handleViewFeature(e)}>
          <VisibilityIcon />
          &nbsp;&nbsp; View Feature
        </MenuItem>
        <hr />
        <MenuItem onClick={(e) => handleClickFeaturePopup(e)}>
          <AddIcon />
          &nbsp;&nbsp; Add Feature
        </MenuItem>
        <hr />
        <MenuItem onClick={(e) => handleEdit(e)}>
          <EditIcon />
          &nbsp;&nbsp; Edit
        </MenuItem>
        <hr />
        <MenuItem onClick={(e) => handleClickPopover(e)}>
          <DeleteIcon />
          &nbsp;&nbsp; Delete
        </MenuItem>
      </Menu>
      <Popover
        open={openPopover}
        popover={popover}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ horizontal: "center" }}
      >
        <Typography sx={{ p: 2 }}>
          Are you sure, you want to delete the Program?
        </Typography>
        <Button onClick={() => handleDelete()}>Yes</Button>
        <Button onClick={() => handleClosePopover()}>No</Button>
      </Popover>

      <Popover
        open={openFeature}
        featurePopover={featurePopover}
        onClose={handleCloseFeaturePopup}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{
          padding: "4% !important",
        }}
      >
        <Typography variant="h5">Add Feature</Typography>
        {inputList.length > 0
          ? inputList.map((input, index) => (
              <div key={index}>
                <br />
                <TextField
                  id="outlined-basic"
                  label={`Feature ${index + 1}`}
                  variant="outlined"
                  placeholder="Feature Name"
                  onChange={(event) => handleInputChange(event, index)}
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveItem(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))
          : "No item in the list "}
        <Button
          variant="outlined"
          onClick={handleListAdd}
          disabled={isDisabled}
        >
          Add Feature
        </Button>
        <br />
        <br />
        <br />

        <Button variant="contained" onClick={() => handleSave()}>
          Save
        </Button>
        <Button variant="contained" onClick={handleCloseFeaturePopup}>
          Close
        </Button>
      </Popover>
    </div>
  );
};

export default ProgramList;
