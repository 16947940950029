import React, { useState, useEffect, useContext, useRef } from "react";
import stateContext from "./StateContext";
import { AssignObject } from "../Helpers/AssignObject";
import { ATLAS_URI } from "../Constants";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router";

const State = (props) => {
  const [state, setState] = useState({
    CSRF_TOKEN: "",
    operator: {
      Name: "",
      Username: "",
      Feature: "",
      Role: "",
      Mobile_number: "",
      plivoEndPointUserName: "",
    },
    configToken: {},
    pageAccessible: [],
    featureAccessible: [],
    sessionID: "",
    EditDetailsData: {},
    currentPage: "Dashboard",
    leadsList: [],
    sourcesList: [],
    propertyTypeList: [],
    usersList: [],
    statusList: [],
    currentUser: {},
  });

  const [currentCallStatus, setCurrentCallStatus] = useState("");

  const [callDurationInfo, setCallDurationInfo] = useState({
    duration: "",
    timerID: "",
  });

  const [duration, setDuration] = useState("");
  const [isTimerOn, setIsTimerOn] = useState(false);

  const plivoBrowserSdk = useRef(null);

  let history = useHistory();

  async function getBulkLeads(leadsList, config) {
    return await axios
      .get(`${ATLAS_URI}/getBulkLeads`, config)
      .then((response) => {
        // console.log(response,'bulkleads123');
        const data = response.data.ads.data;
        for (let i = 0; i < data.length; i++) {
          try {
            const leads = data[i].leads.data;
            for (let j = 0; j < leads.length; j++) {
              try {
                let date = leads[j].created_time;
                var x = {
                  id: leads[j].id,
                  AdName: leads[j].ad_name,
                  ClientEmail: leads[j].field_data.filter(
                    (x) => x.name === "email"
                  )[0].values[0],
                  ClientName: leads[j].field_data.filter(
                    (x) => x.name === "full_name"
                  )[0].values[0],
                  ClientPhone: leads[j].field_data.filter(
                    (x) => x.name === "phone_number"
                  )[0].values[0],
                  ClientPhone2: leads[j].field_data.filter(
                    (x) =>
                      x.name === "confirm_phone_number" ||
                      x.name === "whatsapp_number" ||
                      x.name === "whatsapp_contact_information"
                  )[0].values[0],
                  Occupation: leads[j].field_data.filter(
                    (x) => x.name === "job_title"
                  )[0].values[0],
                  CampaignName: leads[j].campaign_name,
                  PropertyType: "",
                  Source: "1",
                  Sources: "Facebook",
                  AssignedTo: "",
                  LeadDate:
                    date.substr(8, 2) +
                    "-" +
                    date.substr(5, 2) +
                    "-" +
                    date.substr(0, 4),
                  lead_payments: [],
                  Remarks: [],
                  VisitInfos: [],
                  FollowUpList: [],
                  FollowUps: [],
                };
                leadsList.push(x);
              } catch (e) {
                console.log(e);
              }
            }
          } catch (error) {}
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(async () => {
    let operator = JSON.parse(window.localStorage.getItem("operator"));
    if (operator) {
      state.operator = operator;
    }

    if (window.location.pathname === "/payment-status") return;
    if (window.location.pathname === "/forms") return;

    if (state.operator.Username === "") {
      if (window.location.pathname !== "/") {
        window.location.replace("/");
      }
    } else {
      //Get All Roles
      const token = JSON.parse(window.localStorage.getItem("operator")).token;
      const { exp } = jwtDecode(token);
      const expirationTime = exp * 1000 - 60000;
      if (Date.now() >= expirationTime) {
        window.localStorage.removeItem("states");
        window.localStorage.removeItem("operator");
        window.location.replace("/");
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .get(`${ATLAS_URI}/getRoleByID/${state.operator.Role}`)
        .then((response) => {
          const roleData = response.data;
          if (typeof roleData !== "undefined" && roleData !== null) {
            setState((prevState) => ({
              ...prevState,
              pageAccessible: roleData.Pages,
              operator: {
                ...prevState.operator,
                Role: roleData.Role,
              },
              configToken: config,
            }));
          }
        })
        .catch((err) => console.log(err));

      axios
        .get(`${ATLAS_URI}/getByIdWithFeature/${state.operator.Role}`)
        .then((response) => {
          const roleFeatureData = response.data;

          if (
            typeof roleFeatureData !== "undefined" &&
            roleFeatureData !== null
          ) {
            setState((prevState) => ({
              ...prevState,
              featureAccessible: roleFeatureData.role_feature_access,
              operator: {
                ...prevState.operator,
                Feature: roleFeatureData.role_feature_access,
              },
              configToken: config,
            }));
          }
        })
        .catch((err) => console.log(err));

      let newLeadsList = [];
      let dbLeadList = [];

      // try { await getBulkLeads(newLeadsList, config).then(() => { }).catch(error => console.log(error)); } catch (e) { console.log(e) }
      axios
        .all([
          axios.get(`${ATLAS_URI}/getSources/`, config),
          // axios.get(`${ATLAS_URI}/getPropertyTypes/`, config),
          axios.get(`${ATLAS_URI}/getUsers/`, config),
          // axios.get(`${ATLAS_URI}/getLeads/`, config),
          axios.get(`${ATLAS_URI}/getStatuses/`, config),
          axios.get(`${ATLAS_URI}/current`, config),
        ])
        .then(
          axios.spread(
            (sources, /* propertyType, */ user, status, currentUser) => {
              // dbLeadList = lead.data;
              // // console.log('lead = ', lead)
              // console.log(lead.data,'dbLeadList');
              // newLeadsList.map(leadIn => {
              //     let oldLead = 'undefined';

              //     for (let i = 0; i < dbLeadList.length; i++) {
              //         let y = dbLeadList[i]
              //         if (y.id.toString() === leadIn.id.toString()) {
              //             oldLead = { ...y }
              //             dbLeadList = dbLeadList.filter(x => x.id.toString() !== y.id.toString())
              //             break;
              //         }
              //     }

              //     if (oldLead !== 'undefined') {
              //         try {
              //             Object.assign(leadIn, AssignObject(oldLead, leadIn))

              //             leadIn._AssignedTo = typeof (oldLead.AssignedTo) !== 'undefined' && (oldLead.AssignedTo) !== null && user.data.filter(y => y.id === oldLead.AssignedTo)[0].Username

              //         } catch (Exception) {
              //             console.log(Exception)
              //             console.log(leadIn, oldLead)
              //         }
              //     }
              //     leadIn.Payments = leadIn.lead_payments

              //     leadIn.LastRemarks = leadIn.Remarks && (leadIn.Remarks.length !== 0 ? leadIn.Remarks[leadIn.Remarks.length - 1].Remarks : "");
              //     leadIn.Payments && leadIn.Payments.length > 0 ? (leadIn.Payment = "Paid") : (leadIn.Payment = "Un-Paid");

              //     if (leadIn.VisitInfos && leadIn.VisitInfos.length !== 0) {
              //         const latestVisit = leadIn.VisitInfos[leadIn.VisitInfos.length - 1];
              //         leadIn.VisitProperty = latestVisit.Property + " - " + latestVisit.Project;
              //         leadIn.VisitDate = latestVisit.VisitDate;
              //         leadIn.VisitStatus = latestVisit.VisitStatus;

              //     } else {
              //         leadIn.VisitStatus = "Not Planned";
              //     }
              //     return leadIn
              // })
              // dbLeadList.map(leadIn => {

              //     leadIn.Payments = leadIn.lead_payments

              //     leadIn.LastRemarks = leadIn.Remarks && (leadIn.Remarks.length !== 0 ? leadIn.Remarks[leadIn.Remarks.length - 1].Remarks : "");
              //     leadIn.Payments && leadIn.Payments.length > 0 ? (leadIn.Payment = "Paid") : (leadIn.Payment = "Un-Paid");

              //     if (leadIn.VisitInfos && leadIn.VisitInfos.length !== 0) {
              //         const latestVisit = leadIn.VisitInfos[leadIn.VisitInfos.length - 1];
              //         leadIn.VisitProperty = latestVisit.Property + " - " + latestVisit.Project;
              //         leadIn.VisitDate = latestVisit.VisitDate;
              //         leadIn.VisitStatus = latestVisit.VisitStatus;

              //     } else {
              //         leadIn.VisitStatus = "Not Planned";
              //     }
              //     return leadIn
              // })
              // console.log("Here Bulk Leads")

              setState((prevState) => ({
                ...prevState,
                // leadsList: newLeadsList.concat(dbLeadList),
                sourcesList: sources.data,
                propertyTypeList: [], // propertyType.data,
                usersList: user.data,
                statusList: status.data,
                currentUser: currentUser?.data,
                configToken: config,
              }));
            }
          )
        )
        .catch((error) => console.log(error));
    }
  }, []);

  function onCallRemoteRinging(
    currentCallUUID,
    currentCallStatus,
    callObjectInfo
  ) {
    setCurrentCallStatus(currentCallStatus);

    /*  setCallObjectInfo(prevState => {
            console.log('prevState in setCallObjectInfo:::: ', prevState)
            return {...prevState}
        }) */

    setState((prevState) => ({
      ...prevState,
      currentCallUUID: currentCallUUID,
    }));
    if (callObjectInfo && callObjectInfo?.leadId) {
      history.push({
        pathname: "/Leads/leadView/" + callObjectInfo?.leadId,
        state: {
          currentCallUUID: currentCallUUID,
          isCallHistoryView: true,
          // currentCallStatus: currentCallStatus
        },
      });
    }
  }

  function updateEditDetails(newEditDetailsData) {
    setState((prevState) => ({
      ...prevState,
      EditDetailsData: newEditDetailsData,
    }));
  }

  function redirectFromEditDetails(page) {
    setState((prevState) => ({ ...prevState, EditDetailsData: {} }));
    if (page !== null) {
      window.location.replace(page);
    }
  }
  function updateList(list, newList) {
    setState((prevState) => ({
      ...prevState,
      [list]: newList,
    }));
  }
  function resetEditID(page) {
    if (
      typeof state.EditDetailsData.redirectTo !== "undefined" &&
      state.EditDetailsData.redirectTo !== null &&
      !(state.EditDetailsData.redirectTo === page)
    ) {
      setState((prevState) => ({ ...prevState, EditDetailsData: {} }));
      return true;
    } else return false;
  }
  function updateOperatorInfo(operator) {
    const start = parseInt(Math.random() * (operator.Username.length - 3));
    const sessionCode =
      operator.Username.substr(start, 3).toUpperCase() +
      new Date().getTime() +
      String(parseInt(Math.random() * 10));

    const newOperator = {
      id: operator.id,
      Name: operator.Name
        ? operator.Name
        : `${operator.firstName ? operator.firstName : ""} ${
            operator.lastName ? operator.lastName : ""
          }`,
      Username: operator.Username,
      Role: operator.Role,
      Feature: operator.Feature,
      token: operator.token,
      LastLogin: operator.LastLogin,
      Mobile_number: operator.Mobile_number,
      plivoEndPointUserName: operator.plivoEndPointUserName,
      LeadAddress: operator.telecmi_userId,
      LeadName: operator.telecmi_psd,
    };
    setState((prevState) => ({
      ...prevState,
      operator: newOperator,
      sessionID: sessionCode,
    }));

    window.localStorage.setItem("operator", JSON.stringify(newOperator));
  }

  async function updateLeadsList() {
    const token = JSON.parse(window.localStorage.getItem("operator")).token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let newLeadsList = [];
    let dbLeadList = [];

    // try { await getBulkLeads(newLeadsList, config).then(() => { }).catch(error => console.log(error)); } catch (e) { console.log(e) }
    axios
      .all([
        axios.get(`${ATLAS_URI}/getSources/`, config),
        // axios.get(`${ATLAS_URI}/getPropertyTypes/`, config),
        axios.get(`${ATLAS_URI}/getUsers/`, config),
        // axios.get(`${ATLAS_URI}/getLeads/`, config),
        axios.get(`${ATLAS_URI}/getStatuses/`, config),
        axios.get(`${ATLAS_URI}/current`, config),
      ])
      .then(
        axios.spread(
          (sources, /* propertyType, */ user, lead, status, currentUser) => {
            // dbLeadList = lead.data;
            // // console.log('lead = ', lead)
            // console.log(lead.data,'dbLeadList');
            // newLeadsList.map(leadIn => {
            //     let oldLead = 'undefined';

            //     for (let i = 0; i < dbLeadList.length; i++) {
            //         let y = dbLeadList[i]
            //         if (y.id.toString() === leadIn.id.toString()) {
            //             oldLead = { ...y }
            //             dbLeadList = dbLeadList.filter(x => x.id.toString() !== y.id.toString())
            //             break;
            //         }
            //     }

            //     if (oldLead !== 'undefined') {
            //         try {
            //             Object.assign(leadIn, AssignObject(oldLead, leadIn))

            //             leadIn._AssignedTo = typeof (oldLead.AssignedTo) !== 'undefined' && (oldLead.AssignedTo) !== null && user.data.filter(y => y.id === oldLead.AssignedTo)[0].Username

            //         } catch (Exception) {
            //             console.log(Exception)
            //             console.log(leadIn, oldLead)
            //         }
            //     }
            //     leadIn.Payments = leadIn.lead_payments

            //     leadIn.LastRemarks = leadIn.Remarks && (leadIn.Remarks.length !== 0 ? leadIn.Remarks[leadIn.Remarks.length - 1].Remarks : "");
            //     leadIn.Payments && leadIn.Payments.length > 0 ? (leadIn.Payment = "Paid") : (leadIn.Payment = "Un-Paid");

            //     if (leadIn.VisitInfos && leadIn.VisitInfos.length !== 0) {
            //         const latestVisit = leadIn.VisitInfos[leadIn.VisitInfos.length - 1];
            //         leadIn.VisitProperty = latestVisit.Property + " - " + latestVisit.Project;
            //         leadIn.VisitDate = latestVisit.VisitDate;
            //         leadIn.VisitStatus = latestVisit.VisitStatus;

            //     } else {
            //         leadIn.VisitStatus = "Not Planned";
            //     }
            //     return leadIn
            // })
            // dbLeadList.map(leadIn => {

            //     leadIn.Payments = leadIn.lead_payments

            //     leadIn.LastRemarks = leadIn.Remarks && (leadIn.Remarks.length !== 0 ? leadIn.Remarks[leadIn.Remarks.length - 1].Remarks : "");
            //     leadIn.Payments && leadIn.Payments.length > 0 ? (leadIn.Payment = "Paid") : (leadIn.Payment = "Un-Paid");

            //     if (leadIn.VisitInfos && leadIn.VisitInfos.length !== 0) {
            //         const latestVisit = leadIn.VisitInfos[leadIn.VisitInfos.length - 1];
            //         leadIn.VisitProperty = latestVisit.Property + " - " + latestVisit.Project;
            //         leadIn.VisitDate = latestVisit.VisitDate;
            //         leadIn.VisitStatus = latestVisit.VisitStatus;

            //     } else {
            //         leadIn.VisitStatus = "Not Planned";
            //     }
            //     return leadIn
            // })
            // console.log("Here Bulk Leads")

            setState((prevState) => ({
              ...prevState,
              // leadsList: newLeadsList.concat(dbLeadList),
              sourcesList: sources.data,
              propertyTypeList: [], // propertyType.data,
              usersList: user.data,
              statusList: status.data,
              currentUser: currentUser?.data,
              configToken: config,
            }));
          }
        )
      )
      .catch((error) => console.log(error));
  }

  return (
    <stateContext.Provider
      value={{
        state,
        setState,
        updateEditDetails,
        redirectFromEditDetails,
        resetEditID,
        updateList,
        updateOperatorInfo,
        updateLeadsList,
        onCallRemoteRinging,
        currentCallStatus,
        setCurrentCallStatus,
        setCallDurationInfo,
        callDurationInfo,
        plivoBrowserSdk,
        setIsTimerOn,
        setDuration,
        duration,
        isTimerOn,
      }}
    >
      {props.children}
    </stateContext.Provider>
  );
};
export default State;
