import { Grid, Stack, Typography, Chip, Box } from "@mui/material";
import React from "react";

const DataViewer = ({
  data,
  inline,
  outline,
  className,
  type,
  onClick,
  disabled = false,
}) => {
  return (
    <>
      {Object.keys(data).map((viewitem) => (
        <Grid item xs={3}>
          <Box sx={{ width: "300px" }}>
            <Typography variant="body2" className="mb-2">
              {viewitem}
            </Typography>
            {viewitem === "Emotional Health" || viewitem === "Social Habits" ? (
              <>
                {data[viewitem]?.map((itm) => (
                  <Chip label={itm} sx={{ margin: "2px" }} />
                ))}
              </>
            ) : (
              <Typography variant="h6">
                {data[viewitem] ? data[viewitem] : "--"}
              </Typography>
            )}
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default DataViewer;

//btn-disabled

//  {viewitem === "Current Medical Condition"
// ? data[viewitem].join()
// : data[viewitem]}
