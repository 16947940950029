import React, { useEffect, useState } from "react";

import "./Comments.css";

import { Menu, MenuItem } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { axiosFetch } from "../../Utlis/AxiosFetch";

import { toast } from "react-toastify";

import EditIcon from "@mui/icons-material/Edit";

const Comments = ({ anchorEl, handleClose, editRow, taskData, currentTab }) => {
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;
  const [comment, setComment] = useState("");
  const [updateComment, setUpdateComment] = useState("");
  const [editComment, setEditComment] = useState(null);

  const [updateId, setUpdateId] = useState("");

  const convertUtcToLocalTime = (utc) => {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleString();
    const inputDate = localTime.split(",");
    const formattedDate = inputDate[0].replace(
      /^(\d{2})\/(\d{2})\/(\d{4})$/,
      "$3-$1-$2"
    );

    return [formattedDate, inputDate];
  };

  const handleAddComment = async () => {
    const response = await axiosFetch({
      url: `/addTaskComment`,
      method: "post",
      requestConfig: {
        task_id: editRow.id,
        user_id: userId,
        comment_text: comment,
      },
    });

    if (response.status === 200) {
      setComment("");
      toast.success(response.data, {
        position: toast.POSITION.TOP_CENTER,
      });
      taskData(currentTab);
      handleClose();
    }
  };

  const handleUpdate = (e, id) => {
    setEditComment(e);
    setUpdateId(id.id);
    setUpdateComment(id.comment_text);
  };

  const handleUpdateComment = async () => {
    const response = await axiosFetch({
      url: `/editTaskComment`,
      method: "put",
      requestConfig: {
        task_id: editRow.id,
        user_id: userId,
        comment_text: updateComment,
        is_edited: true,
        id: updateId,
      },
    });

    if (response.status === 200) {
      setComment("");
      setEditComment(null);
      toast.success(response.data, {
        position: toast.POSITION.TOP_CENTER,
      });
      taskData(currentTab);
      handleClose();
    }
  };

  const handleEditClose = () => {
    setEditComment(null);
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          width: "500px",
          top: "-100px",
          left: "-100px",
        }}
      >
        <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          style={{
            width: "450px",
            backgroundColor: "transparent",
          }}
        >
          <h4 className="coment-title">Comments</h4>
        </MenuItem>
        <MenuItem
          style={{ backgroundColor: "transparent" }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <ul className="comment-list">
            {editRow?.task_comments &&
              editRow?.task_comments.map((item) => (
                <li className="comment-item-list">
                  <div className="comment-list-container">
                    <span>
                      <PersonIcon
                        style={{ fontSize: "22px", color: "#6B6B6B" }}
                      />
                    </span>
                    <span>{item.commented_by.firstName}</span>
                    <span>{convertUtcToLocalTime(item.createdAt)[0]}</span>
                    <span>{convertUtcToLocalTime(item.createdAt)[1][1]}</span>
                    <span>{item.is_edited ? "Edited" : ""}</span>
                    <span onClick={(e) => handleUpdate(e.target, item)}>
                      <EditIcon
                        style={{ fontSize: "16px", color: "#6B6B6B" }}
                      />
                    </span>
                  </div>
                  <span className="comment-text">{item.comment_text}</span>
                </li>
              ))}

            <li className="comment-list-item">
              <span className="comment-item-icon">
                <PersonIcon style={{ fontSize: "22px", color: "#6B6B6B" }} />
              </span>
              <input
                type="text"
                className="comment-input"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
              <span className="comment-sent" onClick={handleAddComment}>
                <ArrowUpwardIcon />
              </span>
            </li>
          </ul>
        </MenuItem>
      </Menu>

      <Menu
        id="simple-menu"
        anchorEl={editComment}
        open={Boolean(editComment)}
        onClose={handleEditClose}
        style={{
          top: "-100px",
          left: "-100px",
        }}
      >
        <MenuItem
          style={{
            width: "400px",
            backgroundColor: "transparent",
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <h4 className="coment-title">Edit Comment</h4>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <div className="comment-list-item">
            <span className="comment-item-icon">
              <PersonIcon style={{ fontSize: "22px", color: "#6B6B6B" }} />
            </span>
            <input
              type="text"
              className="comment-input"
              value={updateComment}
              onChange={(e) => {
                setUpdateComment(e.target.value);
              }}
            />

            <span className="comment-sent" onClick={handleUpdateComment}>
              <ArrowUpwardIcon />
            </span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Comments;
