import React, { useState, useEffect } from "react";
import * as $ from "jquery";
import { useHistory } from "react-router";

function Header(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const [breadcrumbs, setBreadCrumbs] = useState(
    getCrumbs(window.location.pathname)
  );
  useEffect(() => {
    if (!menuOpen) {
      //When Sidebar is hovered
      $("#menu_section").on("mouseenter", () => {
        // $("#menu_section").css({ "min-width": "calc(225px*(1/var(--ratio)))", "width": "calc(225px*(1/var(--ratio)))", "padding-left": "0" });
        // $("#menu_section").css({ "min-width": "calc(225px*(1/var(--ratio)))", "width": "calc(225px*(1/var(--ratio)))", "padding-left": "5px",'z-index':'1','position':'fixed','left':'0px','transform':'translateY(-100px)','height':'960px' });
        $("#menu_section").css({
          "min-width": "calc(225px*(1/var(--ratio)))",
          width: "calc(225px*(1/var(--ratio)))",
          "padding-left": "5px",
          "z-index": "1",
          position: "fixed",
          left: "0px",
        });
        // $('#pageContainer').css({   'width': 'calc(100% - 225px)' })

        $(".navTitle").css({
          width: "calc(175px*(1/var(--ratio)))",
          "padding-left": "20px",
        });
        // $('body').css({'float':'right','position':'relative','right':'6px','width':'1310px'})
      });

      $("#menu_section").on("mouseleave", () => {
        // $("#menu_section").css({ "width": "60px", "min-width": "60px", "padding-left": "5px" });
        $("#menu_section").css({
          width: "60px",
          "min-width": "60px",
          "padding-left": "5px",
          "z-index": "1",
          position: "fixed",
          left: "0px",
        });

        $(".navTitle").css({ width: "0px", "padding-left": "0px" });
        $(".sideBarItem .accordion-button").addClass("collapsed");
        $(".sideBarItem .accordion-collapse").removeClass("show");
        $("#pageContainer").css({
          float: "right",
          position: "fixed",
          left: "40px",
        });

        // $('body').css({ 'float': 'left', 'width': '1550px' })
        if ($(window).width() == 1920) {
          $("#pageContainer").css({
            float: "right",
            position: "fixed",
            left: "40px",
            width: "1890px",
          });
        }
        // else {
        //     $('#pageContainer').css({  'width': '100%' })

        // }
      });
    } else {
      $("#menu_section").off("mouseenter").off("mouseleave");
    }
  }, [menuOpen]);

  history.listen((location) => {
    setBreadCrumbs(getCrumbs(location.pathname));
  });

  function getCrumbs(location) {
    let newPath = "";
    let loc = location.split("/");
    loc.forEach((path, index) => {
      var greaterThan = path !== "" && loc.length - 1 !== index ? " > " : "";
      var result = path.replace(/([A-Z])/g, " $1") + greaterThan;
      newPath += result.charAt(0).toUpperCase() + result.slice(1);
    });
    return newPath;
  }
  const hideFullMenu = () => {
    $("#menu_section").css({
      width: "60px",
      "min-width": "60px",
      "padding-left": "5px",
      position: "relative",
      left: "0",
    });
    $(".navTitle").css({ width: "0px", "padding-left": "0px" });
    $(".sideBarItem .accordion-button").addClass("collapsed");
    $(".sideBarItem .accordion-collapse").removeClass("show");
    $("#pageContainer").css({ float: "left", width: "100%" });
    if ($(window).width() == 1920) {
      $("#pageContainer").css({
        float: "left",
        position: "relative",
        right: "6px",
        width: "1920px",
      });
    }

    // $('#header').css({'position':'relative','right':'6px','left':'6px'})
    // $('#topHeader').css({'position':'relative','right':'6px','left':'6px'})
  };

  const showFullMenu = () => {
    $("#menu_section").css({
      "min-width": "calc(225px*(1/var(--ratio)))",
      width: "calc(225px*(1/var(--ratio)))",
      "padding-left": "0",
      position: "fixed",
      left: "0",
      "z-index": "1",
    });
    $(".navTitle").css({
      width: "calc(175px*(1/var(--ratio)))",
      "padding-left": "20px",
    });
    $("#pageContainer").css({
      float: "right",
      position: "relative",
      right: "6px",
      width: "calc(100% - 225px)",
      left: "0",
    });
    if (window.innerWidth == 1920) {
      $("#pageContainer").css({
        float: "right",
        position: "relative",
        right: "20px",
        width: "1690px",
        left: "0",
      });
    }
    // $('#pageContainer').css({'float':'right','position':'relative','right':'50px'})

    // $('body').css({'float':'right','width':'1310px'})
    // $('#header').css({'position':'relative','right':'300px'})

    // $('#topHeader').css({'position':'relative','right':'280px','width':'1920px'})
  };

  const onMenuClick = () => {
    if (!menuOpen) {
      document.querySelector(".menu_btn").classList.add("menuOpen");
      showFullMenu();
      setMenuOpen(true);
    } else {
      document.querySelector(".menu_btn").classList.remove("menuOpen");
      hideFullMenu();
      setMenuOpen(false);
    }
  };

  return (
    <header id="header">
      {/* <div className="navTitle">Navigation</div> */}

      <button className="menuArea" onClick={onMenuClick}>
        <span id="sideMenu" className="menu_btn">
          <span className="menu_btn_burger"></span>
        </span>
      </button>

      <span className="pageName">{breadcrumbs}</span>
    </header>
  );
}

export default React.memo(Header);
