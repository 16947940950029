import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const CallLog = ()=>{
    return(
        <div>
 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><strong>Date</strong></TableCell>
            <TableCell><strong>Call Type</strong></TableCell>
            <TableCell><strong>Call Subtype</strong></TableCell>
            <TableCell><strong>Role/UserName</strong></TableCell>
            <TableCell><strong>Call Disposition</strong></TableCell>
            <TableCell><strong>Call Sub Disposition</strong></TableCell>
            <TableCell><strong>Next Follow up(Date and Time)</strong></TableCell>
            <TableCell><strong>Program Name</strong></TableCell>
            <TableCell><strong>Deal Size</strong></TableCell>
            <TableCell><strong>Notes</strong></TableCell>
            <TableCell><strong>Recording</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
        </TableBody>
      </Table>
    </TableContainer>
        </div>
    )
}
export default CallLog;