import { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import axios from "axios";
// import { ATLAS_URI } from '../../Constants';
import stateContext from "../../../context/StateContext";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { ATLAS_URI } from "../../../Constants";
import { useHistory } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const CreateAllocation = ({
  open,
  togglePopup,
  closePopup,
  allocationId,
  typeId,
}) => {
  const contextState = useContext(stateContext);
  const history = useHistory();
  const sourceList = contextState?.state?.sourcesList;
  const { configToken } = contextState.state;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [time, setTime] = useState("");
  const [selectedSalesRep, setSelectedSalesRep] = useState([]);
  const [GenderCndt, setGenderCndt] = useState("");
  const [selectedSource, setSelectedSource] = useState([]);
  const [sourceCndt, setSourceCndt] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [languageCndt, setLanguageCndt] = useState("");
  const [selectedMedicalCntd, setSelectedMedicalCntd] = useState([]);
  const [cntd_medicalCntd, setCntd_medicalCntd] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countryCntd, setCountryCntd] = useState("");
  const [selectedAgeGroup, setSelectedAgeGroup] = useState([]);
  const [ageCntd, setAgeCntd] = useState("");
  const [selectedProfession, setSelectedProfession] = useState([]);
  const [professionCntd, setProfessionCntd] = useState("");
  const [gender, setGender] = useState("");

  const [genderCntdErr, setGenderCntdErr] = useState("");
  const [sourceCntdErr, setSourceCntdErr] = useState("");
  const [medicalCntdErr, setMedicalCntdErr] = useState("");
  const [languageCntdErr, setLanguageCntdErr] = useState("");
  const [CountryCntdErr, setCountryCntdErr] = useState("");
  const [AgeCntdErr, setAgeCntdErr] = useState("");
  const [ProfessionCntdErr, setProfessionCntdErr] = useState("");
  const [SalesErr, setSalesErr] = useState("");

  const [genderId, setGenderId] = useState("");
  const [sourceId, setSourceId] = useState("");
  const [medicalId, setMedicalId] = useState("");
  const [languageId, setLanguageId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [ageId, setAgeId] = useState("");
  const [professionId, setProfessionId] = useState("");

  const [sourceErr, setSourceErr] = useState("");
  const [medicalErr, setMedicalErr] = useState("");
  const [languageErr, setLanguagesetErr] = useState("");
  const [CountryErr, setCountryErr] = useState("");
  const [AgeErr, setAgeErr] = useState("");
  const [ProfessionErr, setProfessionErr] = useState("");

  const [StartDateErr, setStartDateErr] = useState("");
  const [EndDateErr, setEndDateErr] = useState("");
  const [GenderErr, setGenderErr] = useState("");

  const [salesPplList, setSalesPplList] = useState([]);

  const [isHovering, setIsHovering] = useState(false);
  const [dropDownData, setDropDownData] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [sequenceNumber, setSequenceNumber] = useState("");
  const [isSequenceErr, setIsSequenceErr] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleChangeSequenceNo = (e) => {
    const re = /^[0-9\b]+$/;
    let val = e.target.value;
    if (val === "") {
      setIsSequenceErr(true);
      setSequenceNumber(val);
    }

    if (val !== "" && re.test(val)) {
      setSequenceNumber(val);
      setIsSequenceErr(false);
    }
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleChangeStartDate = (e) => {
    if (e.target.value === "") {
      setStartDateErr(true);
    } else {
      setStartDateErr(false);
      let date = e.target.value;
      let time =
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds();

      setTime(time);

      // let dateTime = date + " " + time;

      setStartDate(date);
    }
  };

  const handleChangeEndDate = (e) => {
    if (e.target.value === "") {
      setEndDateErr(true);
      return false;
    } else {
      setEndDateErr(false);
      let date = e.target.value;
      let time =
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds();
      setTime(time);
      // let dateTime = date + " " + time;
      setEndDate(date);
    }
  };

  {
    /* ------- styles for multi-select -------*/
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeSalesPpl = (event) => {
    const {
      target: { value },
    } = event;
    let arr, arrUniq, obj, duplicateId;

    // taking id
    var valueArr = value.map(function (item) {
      return item.id;
    });

    // checks if exist
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });

    if (isDuplicate) {
      obj = value[value.length - 1];

      duplicateId = value.filter((item) => item.id === obj.id);

      if (duplicateId) {
        arr = value.filter((item) => item.id !== obj.id);
      } else {
        arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
        arr = arrUniq;
      }
    } else {
      arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
      arr = arrUniq;
    }

    if (arr.length === 0) {
      setSalesErr(true);
    } else {
      setSalesErr(false);
    }
    // const selectedId = arrUniq.map((item) => item.id.toString());
    setSelectedSalesRep(arr);
  };

  const handleChangeMedicalCondt = (event) => {
    if (event.target.value !== "") {
      setMedicalErr(false);
    }
    if (cntd_medicalCntd === "") {
      setMedicalCntdErr(true);
    } else {
      setMedicalCntdErr(false);
      const {
        target: { value },
      } = event;
      let arr, arrUniq, obj, duplicateId;

      // taking id
      var valueArr = value.map(function (item) {
        return item.id;
      });

      // checks if exist
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      obj = value[value.length - 1];
      if (isDuplicate) {
        duplicateId = value.filter((item) => item.id === obj.id);

        if (
          duplicateId &&
          cntd_medicalCntd !== "in" &&
          cntd_medicalCntd !== "nin"
        ) {
          arr = [obj];
        } else if (cntd_medicalCntd === "in" || cntd_medicalCntd === "nin") {
          arr = value.filter((item) => item.id !== obj.id);
        }
      } else if (
        value.length > 1 &&
        (cntd_medicalCntd === "in" || cntd_medicalCntd === "nin")
      ) {
        arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
        arr = arrUniq;
      } else if (value.length === 0) {
        arr = [];
      } else {
        arr = [obj];
      }
      // const selectedId = arrUniq.map((item) => item.id.toString());
      setSelectedMedicalCntd(arr);
    }
  };

  const handleChangeCountry = (event) => {
    if (event.target.value !== "") {
      setCountryErr(false);
    }
    if (countryCntd === "") {
      setCountryCntdErr(true);
    } else {
      setCountryCntdErr(false);
      const {
        target: { value },
      } = event;
      let arr, arrUniq, obj, duplicateId;

      // taking id
      var valueArr = value.map(function (item) {
        return item.id;
      });

      // checks if exist
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      obj = value[value.length - 1];
      if (isDuplicate) {
        duplicateId = value.filter((item) => item.id === obj.id);

        if (duplicateId && countryCntd !== "in" && countryCntd !== "nin") {
          arr = [obj];
        } else if (countryCntd === "in" || countryCntd === "nin") {
          arr = value.filter((item) => item.id !== obj.id);
        }
      } else if (
        value.length > 1 &&
        (countryCntd === "in" || countryCntd === "nin")
      ) {
        arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
        arr = arrUniq;
      } else if (value.length === 0) {
        arr = [];
      } else {
        arr = [obj];
      }
      // const selectedId = arrUniq.map((item) => item.id.toString());
      setSelectedCountry(arr);
    }
  };

  const handleChangeLanguage = (event) => {
    if (event.target.value !== "") {
      setLanguagesetErr(false);
    }
    if (languageCndt === "") {
      setLanguageCntdErr(true);
      return false;
    } else {
      setLanguageCntdErr(false);
      const {
        target: { value },
      } = event;
      let arr, arrUniq, obj, duplicateId;

      // taking id
      var valueArr = value.map(function (item) {
        return item.id;
      });

      // checks if exist
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      obj = value[value.length - 1];
      if (isDuplicate) {
        duplicateId = value.filter((item) => item.id === obj.id);

        if (duplicateId && languageCndt !== "in" && languageCndt !== "nin") {
          arr = [obj];
        } else if (languageCndt === "in" || languageCndt === "nin") {
          arr = value.filter((item) => item.id !== obj.id);
        }
      } else if (
        value.length > 1 &&
        (languageCndt === "in" || languageCndt === "nin")
      ) {
        arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
        arr = arrUniq;
      } else if (value.length === 0) {
        arr = [];
      } else {
        arr = [obj];
      }
      // const selectedId = arrUniq.map((item) => item.id.toString());
      setSelectedLanguage(arr);
    }
  };

  const handleChangeAge = (event) => {
    if (event.target.value !== "") {
      setAgeErr(false);
    }
    if (ageCntd === "") {
      setAgeCntdErr(true);
    } else {
      setAgeCntdErr(false);
      const {
        target: { value },
      } = event;
      let arr, arrUniq, obj, duplicateId;

      // taking id
      var valueArr = value.map(function (item) {
        return item.id;
      });

      // checks if exist
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      obj = value[value.length - 1];
      if (isDuplicate) {
        duplicateId = value.filter((item) => item.id === obj.id);

        if (duplicateId && ageCntd !== "in" && ageCntd !== "nin") {
          arr = [obj];
        } else if (ageCntd === "in" || ageCntd === "nin") {
          arr = value.filter((item) => item.id !== obj.id);
        }
      } else if (value.length > 1 && (ageCntd === "in" || ageCntd === "nin")) {
        arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
        arr = arrUniq;
      } else if (value.length === 0) {
        arr = [];
      } else {
        arr = [obj];
      }
      // const selectedId = arrUniq.map((item) => item.id.toString());
      setSelectedAgeGroup(arr);
    }
  };

  const handleChangeProfession = (event) => {
    if (event.target.value !== "") {
      setProfessionErr(false);
    }
    if (professionCntd === "") {
      setProfessionCntdErr(true);
    } else {
      setProfessionCntdErr(false);
      const {
        target: { value },
      } = event;
      let arr, arrUniq, obj, duplicateId;

      // taking id
      var valueArr = value.map(function (item) {
        return item.id;
      });

      // checks if exist
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      obj = value[value.length - 1];
      if (isDuplicate) {
        duplicateId = value.filter((item) => item.id === obj.id);

        if (
          duplicateId &&
          professionCntd !== "in" &&
          professionCntd !== "nin"
        ) {
          arr = obj;
        } else if (professionCntd === "in" || professionCntd === "nin") {
          arr = value.filter((item) => item.id !== obj.id);
        }
      } else if (
        value.length > 1 &&
        (professionCntd === "in" || professionCntd === "nin")
      ) {
        arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
        arr = arrUniq;
      } else if (value.length === 0) {
        arr = [];
      } else {
        arr = [obj];
      }

      // const selectedId = arrUniq.map((item) => item.id.toString());
      setSelectedProfession(arr);
    }
  };
  const handleChangeCountryCntd = (e) => {
    setCountryCntd(e.target.value);
    setSelectedCountry([]);
  };

  const handleChangeCntd_Medical = (e) => {
    setCntd_medicalCntd(e.target.value);
    setSelectedMedicalCntd([]);
  };

  const handleChangeLanguageCntd = (e) => {
    setLanguageCndt(e.target.value);

    if (e.target.value === "") {
      setSelectedLanguage([]);
    } else {
      setLanguageCntdErr(false);
      if (selectedLanguage.length !== 0) {
        setSelectedLanguage([]);
      }
    }
  };

  const handleChangeAgeCntd = (e) => {
    setAgeCntd(e.target.value);

    if (e.target.value === "") {
      setSelectedAgeGroup([]);
    } else {
      setAgeCntdErr(false);
      if (selectedAgeGroup.length !== 0) {
        setSelectedAgeGroup([]);
      }
    }
  };

  const handleSourceCndt = (e) => {
    setSourceCndt(e.target.value);

    if (e.target.value === "") {
      setSelectedSource([]);
    } else {
      setSourceCntdErr(false);
      if (selectedSource.length !== 0) {
        setSelectedSource([]);
      }
    }
  };

  const handleProfessionCntd = (e) => {
    setProfessionCntd(e.target.value);
    if (e.target.value === "") {
      setSelectedProfession([]);
    } else {
      setProfessionCntdErr(false);
      if (selectedProfession.length !== 0) {
        setSelectedProfession([]);
      }
    }
  };

  const handleChangeGenderCntd = (e) => {
    setGenderCndt(e.target.value);
    if (e.target.value === "") {
      setGender("");
      // setGenderCntdErr(true);
    } else {
      setGenderCntdErr(false);
    }
  };

  const handleChangeSource = (event) => {
    if (event.target.value === "") {
      // setSourceErr(true);
      return false;
    } else {
      setSourceErr(false);
    }
    if (sourceCndt === "") {
      setSourceCntdErr(true);
    } else {
      setSourceCntdErr(false);
      const {
        target: { value },
      } = event;
      let arr, arrUniq, obj, duplicateId;

      // taking id
      var valueArr = value.map(function (item) {
        return item.id;
      });

      // checks if exist
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      obj = value[value.length - 1];
      if (isDuplicate) {
        duplicateId = value.filter((item) => item.id === obj.id);

        if (duplicateId && sourceCndt !== "in" && sourceCndt !== "nin") {
          // remove from the org arr
          arr = [obj];
        } else if (sourceCndt === "in" || sourceCndt === "nin") {
          arr = value.filter((item) => item.id !== obj.id);
          // // appending in the arr
          // console.log("here3");
          // arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
          // arr = arrUniq;
        }
      } else if (
        value.length > 1 &&
        (sourceCndt === "in" || sourceCndt === "nin")
      ) {
        arrUniq = [...new Map(value.map((v) => [v.id, v])).values()];
        arr = arrUniq;
      } else if (value.length === 0) {
        arr = [];
      } else {
        arr = [obj];
      }
      // const selectedId = arrUniq.map((item) => item.id.toString());
      setSelectedSource(arr);
    }
  };

  // gt, gte, lt, lte, eq, ne, in, nin
  const age_ruleList = [
    { id: "gt", name: "Greater than" },
    { id: "gte", name: "Greater than equal to" },
    { id: "lt", name: "Less than" },
    { id: "lte", name: "Lesser than equal to" },
    { id: "eq", name: "Equal to" },
    { id: "ne", name: "Not Equal to" },
    { id: "in", name: "In" },
    { id: "nin", name: "Not in" },
  ];

  const ruleList = [
    { id: "eq", name: "Equal to" },
    { id: "ne", name: "Not Equal to" },
    { id: "in", name: "In" },
    { id: "nin", name: "Not in" },
  ];
  const handleChangeGender = (e) => {
    if (GenderCndt === "") {
      setGenderCntdErr(true);
      return false;
    } else {
      setGender(e.target.value);
    }
  };
  const createAllocation = (e) => {
    e.preventDefault();

    var conditions = [];
    var obj = null;
    if (sequenceNumber === "") {
      setIsSequenceErr(true);
      return false;
    }
    if (GenderCndt === "" && gender !== "") {
      setGenderCntdErr(true);
      return false;
    }

    if (sourceCndt === "" && selectedSource.length !== 0) {
      setSourceCntdErr(true);
      return false;
    }

    if (selectedSource.length === 0 && sourceCndt !== "") {
      setSourceErr(true);
      return false;
    }

    if (selectedMedicalCntd.length === 0 && cntd_medicalCntd !== "") {
      setMedicalErr(true);
      return false;
    }

    if (cntd_medicalCntd === "" && selectedMedicalCntd.length !== 0) {
      setMedicalCntdErr(true);
      return false;
    }

    if (languageCndt === "" && selectedLanguage.length !== 0) {
      setLanguageCntdErr(true);
      return false;
    }

    if (selectedLanguage.length === 0 && languageCndt !== "") {
      setLanguagesetErr(true);
      return false;
    }
    if (countryCntd === "" && selectedCountry.length !== 0) {
      setCountryCntdErr(true);
      return false;
    }
    if (selectedCountry.length === 0 && countryCntd !== "") {
      setCountryErr(true);
      return false;
    }

    if (ageCntd === "" && selectedAgeGroup.length !== 0) {
      setAgeCntdErr(true);
      return false;
    }

    if (selectedAgeGroup.length === 0 && ageCntd !== "") {
      setAgeErr(true);
      return false;
    }

    if (professionCntd === "" && selectedProfession.length !== 0) {
      setProfessionCntdErr(true);
      return false;
    }

    if (selectedProfession.length === 0 && professionCntd !== "") {
      setProfessionErr(true);
      return false;
    }

    if (selectedSalesRep.length === 0 && sourceCndt !== "") {
      setSalesErr(true);
      return false;
    }

    if (startDate === "") {
      setStartDateErr(true);
      return false;
    }
    if (endDate === "") {
      setEndDateErr(true);
      return false;
    }

    if (GenderCndt === "" && gender !== "") {
      setGenderCntdErr(true);
      return false;
    }
    if (gender === "" && GenderCndt !== "") {
      setGenderErr(true);
      return false;
    }

    if (selectedSource.length !== 0 && sourceCndt !== "") {
      obj = {
        id: typeId === 1 && sourceId !== "" ? sourceId : undefined,
        field_name: "source",
        check_condition: sourceCndt,
        value:
          selectedSource.length !== 0
            ? selectedSource.map((item) => item.id.toString())
            : null,
      };

      conditions.push(obj);
    }
    if (selectedLanguage.length !== 0 && languageCndt !== "") {
      obj = {
        id: typeId === 1 && languageId !== "" ? languageId : undefined,
        field_name: "language",
        check_condition: languageCndt,
        value:
          selectedLanguage.length !== 0
            ? selectedLanguage.map((item) =>
                item.languageDisplayValue.toString()
              )
            : null,
      };
      conditions.push(obj);
    }
    if (selectedMedicalCntd.length !== 0 && cntd_medicalCntd !== "") {
      obj = {
        id: typeId === 1 && medicalId !== "" ? medicalId : undefined,
        field_name: "medical_condition",
        check_condition: cntd_medicalCntd,
        value:
          selectedMedicalCntd.length !== 0
            ? selectedMedicalCntd
                .map((item) => item.medical_condition.toString())
                .sort()
            : null,
      };
      conditions.push(obj);
    }
    if (selectedProfession.length !== 0 && professionCntd !== "") {
      obj = {
        id: typeId === 1 && professionId !== "" ? professionId : undefined,
        field_name: "profession",
        check_condition: professionCntd,
        value:
          selectedProfession.length !== 0
            ? selectedProfession.map((item) => item.profession.toString())
            : null,
      };
      conditions.push(obj);
    }
    if (selectedAgeGroup.length !== 0 && ageCntd !== "") {
      obj = {
        id: typeId === 1 && ageId !== "" ? ageId : undefined,
        field_name: "age",
        check_condition: ageCntd,
        value:
          selectedAgeGroup.length !== 0
            ? selectedAgeGroup.map((item) => item.id.toString())
            : null,
      };
      conditions.push(obj);
    }
    if (selectedCountry.length !== 0 && countryCntd !== "") {
      obj = {
        id: typeId === 1 && countryId !== "" ? countryId : undefined,
        field_name: "country",
        check_condition: countryCntd,
        value:
          selectedCountry.length !== 0
            ? selectedCountry.map((item) => item.country.toString())
            : null,
      };
      conditions.push(obj);
    }

    if (gender !== "" && GenderCndt !== "") {
      obj = {
        id: typeId === 1 && genderId !== "" ? genderId : undefined,
        field_name: "gender",
        check_condition: GenderCndt,
        value: gender !== "" ? [gender] : null,
      };
      conditions.push(obj);
    }
    if (selectedSalesRep.length === 0) {
      toast.error("Please select any Sales Executives", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }

    if (conditions.length !== 0 && selectedSalesRep.length !== 0) {
      setDisableSubmit(true);
      var obj = {
        id: allocationId !== "" ? allocationId : undefined,
        sequence: sequenceNumber !== "" ? sequenceNumber : undefined,
        start_date: startDate !== "" ? startDate + " " + time : undefined,
        end_date: endDate !== "" ? endDate + " " + time : undefined,
        sales_executive:
          selectedSalesRep.length !== 0
            ? selectedSalesRep.map((item) => item.id.toString())
            : undefined,
        conditions: conditions.length !== 0 ? conditions : undefined,
      };
    } else {
      toast.error("Please select any condition", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    if (typeId === 1) {
      axios
        .put(`${ATLAS_URI}/updateAutoAllocationRule`, obj, configToken)
        .then((res) => {
          if (res.status === 200) {
            closePopup();
            toast.success("Allocation Updated Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            setDisableSubmit(false);
          } else if (res.status === 401) {
            history.push("/");
          } else {
            setDisableSubmit(false);
          }
        })
        .catch((err) => console.error(err));
    } else {
      axios
        .post(`${ATLAS_URI}/postAutoAllocationRule`, obj, configToken)
        .then((res) => {
          if (res.status === 200) {
            closePopup();
            toast.success("Allocation Created Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            setDisableSubmit(false);
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => {
          setDisableSubmit(false);
          toast.error(`${err?.response?.data?.message}`, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const getDropDownData = () => {
    axios
      .get(`${ATLAS_URI}/getSelectValuesAutoallocation`, configToken)
      .then((res) => {
        if (res.status === 200) {
          setDropDownData(res.data);
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => console.error(err));
  };

  const getSalesExecutivesList = () => {
    
    axios
      .get(`${ATLAS_URI}/getSalesExecutivesToAutoAllocation`, configToken)
      .then((res) => {
        if (res.status === 200) {
          setSalesPplList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => console.error(err));
  };

  const isPrefillZone = () => {
    axios
      .get(
        `${ATLAS_URI}/getAutoAllocationRuleByRuleId/${allocationId}`,
        configToken
      )
      .then((res) => {
        if (res.status === 200) {
          let selectedObj = res.data[0];

          setStartDate(selectedObj.start_date.split("T")[0]);
          setEndDate(selectedObj.end_date.split("T")[0]);

          if (selectedObj.sequence !== null) {
            setSequenceNumber(selectedObj.sequence);
          }

          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "gender"
            ).length !== 0
          ) {
            setGenderId(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "gender"
              )[0].id
            );
          }
          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "gender"
            )[0].value.length !== 0
          ) {
            setGender(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "gender"
              )[0].value[0]
            );

            setGenderCndt(
              ruleList.filter(
                (rule) =>
                  rule.id ===
                  selectedObj.rule_details.filter(
                    (item) => item.field_name === "gender"
                  )[0].check_condition
              )[0].id
            );
          }

          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "source"
            ).length !== 0
          ) {
            setSourceId(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "source"
              )[0].id
            );
          }
          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "source"
            )[0].value.length !== 0
          ) {
            setSelectedSource(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "source"
              )[0].value
            );

            setSourceCndt(
              ruleList.filter(
                (rule) =>
                  rule.id ===
                  selectedObj.rule_details.filter(
                    (item) => item.field_name === "source"
                  )[0].check_condition
              )[0].id
            );
          }

          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "medical_condition"
            ).length !== 0
          ) {
            setMedicalId(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "medical_condition"
              )[0].id
            );
          }
          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "medical_condition"
            )[0].value.length !== 0
          ) {
            setSelectedMedicalCntd(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "medical_condition"
              )[0].value
            );

            setCntd_medicalCntd(
              ruleList.filter(
                (rule) =>
                  rule.id ===
                  selectedObj.rule_details.filter(
                    (item) => item.field_name === "medical_condition"
                  )[0].check_condition
              )[0].id
            );
          }

          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "gender"
            ).length !== 0
          ) {
            setLanguageId(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "language"
              )[0].id
            );
          }

          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "language"
            ).length !== 0
          ) {
            setLanguageId(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "language"
              )[0].id
            );
          }
          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "language"
            )[0].value.length !== 0
          ) {
            setSelectedLanguage(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "language"
              )[0].value
            );

            setLanguageCndt(
              ruleList.filter(
                (rule) =>
                  rule.id ===
                  selectedObj.rule_details.filter(
                    (item) => item.field_name === "language"
                  )[0].check_condition
              )[0].id
            );
          }

          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "country"
            ).length !== 0
          ) {
            setCountryId(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "country"
              )[0].id
            );
          }
          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "country"
            )[0].value.length !== 0
          ) {
            setSelectedCountry(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "country"
              )[0].value
            );

            setCountryCntd(
              ruleList.filter(
                (rule) =>
                  rule.id ===
                  selectedObj.rule_details.filter(
                    (item) => item.field_name === "country"
                  )[0].check_condition
              )[0].id
            );
          }

          if (
            selectedObj.rule_details.filter((item) => item.field_name === "age")
              .length !== 0
          ) {
            setAgeId(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "age"
              )[0].id
            );
          }
          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "age"
            )[0].value.length !== 0
          ) {
            setSelectedAgeGroup(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "age"
              )[0].value
            );

            setAgeCntd(
              age_ruleList.filter(
                (rule) =>
                  rule.id ===
                  selectedObj.rule_details.filter(
                    (item) => item.field_name === "age"
                  )[0].check_condition
              )[0].id
            );
          }

          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "profession"
            ).length !== 0
          ) {
            setProfessionId(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "profession"
              )[0].id
            );
          }
          if (
            selectedObj.rule_details.filter(
              (item) => item.field_name === "profession"
            )[0].value.length
          ) {
            setSelectedProfession(
              selectedObj.rule_details.filter(
                (item) => item.field_name === "profession"
              )[0].value
            );

            setProfessionCntd(
              ruleList.filter(
                (rule) =>
                  rule.id ===
                  selectedObj.rule_details.filter(
                    (item) => item.field_name === "profession"
                  )[0].check_condition
              )[0].id
            );
          }

          if (res.data[0].sales_executive.length !== 0) {
            setSelectedSalesRep(res.data[0].sales_executive);
          }
        } else if (res.status === 401) {
          history.push("/");
        }
      });
  };

  const clearAll = () => {
    setSelectedSource([]);
    setSourceCndt("");

    setSelectedLanguage([]);
    setLanguageCndt("");

    setSelectedCountry([]);
    setCountryCntd("");

    setSelectedProfession([]);
    setProfessionCntd("");

    setSelectedMedicalCntd([]);
    setCntd_medicalCntd("");
    setSelectedSalesRep([]);

    setSelectedAgeGroup([]);
    setAgeCntd("");

    setGender("");
    setGenderCndt("");

    setStartDate("");
    setEndDate("");
    setSelectedSalesRep([]);
  };
  useEffect(() => {
    if (allocationId !== "") {
      isPrefillZone();
    }
    getSalesExecutivesList();
    getDropDownData();
  }, []);
  return (
    <div>
      <Modal show={open} onHide={togglePopup}>
        <Modal.Header closeButton>
          <h4>Create Allocation</h4>
          <GrClose
            style={{
              backgroundColor: isHovering ? "#FF0000" : "",
              color: isHovering ? "#fff" : "",
              border: isHovering ? "1px solid" : "",
              height: isHovering ? "30px" : "",
              width: isHovering ? "30px" : "",
              opacity: isHovering ? "0.7" : "",
            }}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={togglePopup}
          />
        </Modal.Header>

        <Modal.Body>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-md">
                  <h5>
                    Sequence Number <span style={{ color: "red" }}>*</span>
                  </h5>
                </div>
                <div className="col-md">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <TextField
                      id="outlined-basic"
                      label="Sequence Number"
                      variant="outlined"
                      value={sequenceNumber}
                      onChange={handleChangeSequenceNo}
                      disabled={typeId === 2 ? true : false}
                    />
                    <FormHelperText style={{ color: "red" }}>
                      {isSequenceErr ? "Please Enter Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md"></div>
              </div>
              <div className="row">
                <div className="col-md">
                  <h5>Source</h5>
                </div>
                <div className="col-md">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong>Condition</strong> */}
                    <Select
                      displayEmpty
                      value={sourceCndt}
                      onChange={handleSourceCndt}
                      disabled={typeId === 2 ? true : false}
                    >
                      <MenuItem value="">
                        <em>Select Condition</em>
                      </MenuItem>
                      {ruleList?.map((source) => (
                        <MenuItem value={source.id}>{source.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {sourceCntdErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                {/* in /nin --- muli-select */}
                <div className="col-md">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong>Source</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      displayEmpty
                      multiple
                      value={selectedSource}
                      onChange={handleChangeSource}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Source</em>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value.id} label={value.Source} />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      name="source"
                    >
                      {sourceList?.map((source) => (
                        <MenuItem value={source}>{source.Source}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {sourceErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5>Medical Condition</h5>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong>Condition</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      displayEmpty
                      value={cntd_medicalCntd}
                      onChange={handleChangeCntd_Medical}
                    >
                      <MenuItem value="">
                        <em>Select Condition</em>
                      </MenuItem>
                      {ruleList?.map((source) => (
                        <MenuItem value={source.id}>{source.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {medicalCntdErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong id="demo-multiple-chip-label">
                      Medical Condition
                    </strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      displayEmpty
                      multiple
                      value={selectedMedicalCntd}
                      onChange={handleChangeMedicalCondt}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Medical Condition</em>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value.id}
                                  label={value.medical_condition}
                                />
                              ))}
                            </Box>
                          );
                        }
                      }}
                    >
                      {dropDownData &&
                        dropDownData.medical_condition.map((mCntd) => (
                          <MenuItem
                            key={mCntd.id}
                            value={mCntd}
                            // style={getStyles(name.name, personName, theme)}
                          >
                            {mCntd.medical_condition}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {medicalErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5>Language</h5>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong>Condition</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      displayEmpty
                      value={languageCndt}
                      onChange={handleChangeLanguageCntd}
                    >
                      <MenuItem value="">
                        <em>Select Condition</em>
                      </MenuItem>
                      {ruleList?.map((source) => (
                        <MenuItem value={source.id}>{source.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {languageCntdErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong id="demo-multiple-chip-label">Language</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      placeholder="Medical Condition"
                      multiple
                      displayEmpty
                      value={selectedLanguage}
                      onChange={handleChangeLanguage}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Language</em>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value.id}
                                  label={value.languageDisplayValue}
                                />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Select Language</em>
                      </MenuItem>
                      {dropDownData &&
                        dropDownData.languages.map((lng) => (
                          <MenuItem
                            key={lng.id}
                            value={lng}
                            // style={getStyles(name.name, personName, theme)}
                          >
                            {lng.languageDisplayValue}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {languageErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5>Country</h5>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong>Condition</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      displayEmpty
                      value={countryCntd}
                      onChange={handleChangeCountryCntd}
                    >
                      <MenuItem value="">
                        <em>Select Condition</em>
                      </MenuItem>
                      {ruleList?.map((source) => (
                        <MenuItem value={source.id}>{source.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {CountryCntdErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong id="demo-multiple-chip-label">Country</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      multiple
                      displayEmpty
                      value={selectedCountry}
                      onChange={handleChangeCountry}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Country</em>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value.id} label={value.country} />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Select Country</em>
                      </MenuItem>
                      {dropDownData &&
                        dropDownData.country.map((country) => (
                          <MenuItem
                            key={country.id}
                            value={country}
                            // style={getStyles(country.name, personName, theme)}
                          >
                            {country.country}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {CountryErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col">
                  <h5>Age Group</h5>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong>Condition</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      displayEmpty
                      value={ageCntd}
                      onChange={handleChangeAgeCntd}
                    >
                      <MenuItem value="">
                        <em>Select Condition</em>
                      </MenuItem>
                      {age_ruleList?.map((source) => (
                        <MenuItem value={source.id}>{source.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {AgeCntdErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong id="demo-multiple-chip-label">Age Group</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      multiple
                      displayEmpty
                      value={selectedAgeGroup}
                      onChange={handleChangeAge}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Age Group</em>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value.id}
                                  label={value.display_value}
                                />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Select Condition</em>
                      </MenuItem>
                      {dropDownData &&
                        dropDownData.AgeRange.map((age) => (
                          <MenuItem
                            key={age.id}
                            value={age}
                            // style={getStyles(name.name, personName, theme)}
                          >
                            {age.display_value}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {AgeErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5>Profession</h5>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong>Condition</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      displayEmpty
                      value={professionCntd}
                      onChange={handleProfessionCntd}
                    >
                      <MenuItem value="">
                        <em>Select Condition</em>
                      </MenuItem>
                      {ruleList?.map((source) => (
                        <MenuItem value={source.id}>{source.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {ProfessionCntdErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong id="demo-multiple-chip-label">Profession</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      placeholder="Medical Condition"
                      multiple
                      displayEmpty
                      value={selectedProfession}
                      onChange={handleChangeProfession}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Profession</em>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value.id} label={value.profession} />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Select Profession</em>
                      </MenuItem>
                      {dropDownData &&
                        dropDownData.profession.map((name) => (
                          <MenuItem
                            key={name.id}
                            value={name}
                            // style={getStyles(name.name, personName, theme)}
                          >
                            {name.profession}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {ProfessionErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5>Sales Executive</h5>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Select
                      disabled={typeId === 2 ? true : false}
                      placeholder="Sales Executive"
                      multiple
                      displayEmpty
                      value={selectedSalesRep}
                      onChange={handleChangeSalesPpl}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Sales Executive</em>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value?.id} label={value?.firstName} />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Select Sales Executive</em>
                      </MenuItem>
                      {salesPplList?.map((salesEx) => (
                        <MenuItem
                          key={salesEx?.id}
                          value={salesEx}
                          // style={getStyles(name.name, selectedSalesRep, theme)}
                        >
                          {salesEx?.firstName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {SalesErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col"></div>
                {/* <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <strong>Condition</strong>
                    <Select value={salesCndt} onChange={handleSalesCndt}>
                      {ruleList?.map((rule) => (
                        <MenuItem value={rule.id}>{rule.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div> */}
              </div>
              <div className="row">
                <div className="col">
                  <br />
                  <h5>
                    Start Date / End Date<span style={{ color: "red" }}>*</span>
                  </h5>
                </div>
                <div className="col">
                  <br />
                  <input
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    disabled={typeId === 2 ? true : false}
                    value={startDate}
                    onChange={handleChangeStartDate}
                    style={{
                      padding: "12px",
                      borderRadius: "6px",
                      width: "75%",
                    }}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {StartDateErr ? "Please Select Something" : ""}
                  </FormHelperText>
                </div>
                <div className="col">
                  <br />
                  <input
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    disabled={typeId === 2 ? true : false}
                    value={endDate}
                    onChange={handleChangeEndDate}
                    style={{
                      padding: "12px",
                      borderRadius: "6px",
                      width: "75%",
                    }}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {EndDateErr ? "Please Select Something" : ""}
                  </FormHelperText>
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col">
                  <br />
                  <h5>Gender</h5>
                </div>
                <div className="col">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    {/* <strong>Condition</strong> */}
                    <Select
                      disabled={typeId === 2 ? true : false}
                      displayEmpty
                      value={GenderCndt}
                      onChange={handleChangeGenderCntd}
                    >
                      <MenuItem value="">
                        <em>Select Condition</em>
                      </MenuItem>
                      {ruleList?.map((source) => (
                        <MenuItem value={source.id}>{source.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {genderCntdErr ? "Please Select Something" : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col">
                  {" "}
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={gender !== "" ? gender : ""}
                    onChange={handleChangeGender}
                    disabled={typeId === 2 ? true : false}
                  >
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      label="Female"
                      disabled={typeId === 2 ? true : false}
                    />

                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="Male"
                      disabled={typeId === 2 ? true : false}
                    />
                  </RadioGroup>
                  <FormHelperText style={{ color: "red" }}>
                    {GenderErr ? "Please Select Something" : ""}
                  </FormHelperText>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col"></div>
              <div className="col">
                {" "}
                {/* <button
                  class="btn btn-primary"
                  style={{ display: typeId === 2 ? "none" : "" }}
                >
                  Clear
                </button> */}
              </div>
              <div
                className="col"
                style={{
                  display: typeId === 2 ? "none" : "",
                  transform: "translate(-83%, 10px)",
                }}
              >
                {typeId === 1 ? (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={(e) => createAllocation(e)}
                    disabled={disableSubmit}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={(e) => createAllocation(e)}
                    disableElevation
                    disabled={disableSubmit}
                  >
                    Create New Allocation
                  </Button>
                )}

                <Button
                  variant="contained"
                  style={{
                    display: typeId === 2 ? "none" : "",
                    marginLeft: "21px",
                  }}
                  onClick={clearAll}
                >
                  Clear All
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CreateAllocation;
