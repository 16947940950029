import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/CustomButton/Button";
import { dateConverter } from "../../../hooks/helperFunction";

const Header = ({ title, dispatch }) => {
  const groupList = ["All", "Red", "Yellow", "Green"];
  const MUIDateConverter = dateConverter.MUIDateConverter;

  const [selectedGroup, setSelectedGroup] = useState(" ");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!startDate || !endDate) {
      toast.error(`Date is missing`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    dispatch({ type: "filterDate", payload: { startDate, endDate } });

    if (selectedGroup === "All") {
      dispatch({ type: "color", payload: "" });
    } else {
      dispatch({ type: "color", payload: selectedGroup.toLowerCase() });
    }
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedGroup(" ");
    dispatch({ type: "clear" });
  };

  return (
    <form className="report-header" onSubmit={handleSubmit}>
      <h3>{title}</h3>

      <div className="report-date-container">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["DatePicker"]}
            style={{ padding: "10px" }}
          >
            <DatePicker
              value={startDate}
              onChange={(e) => setStartDate(MUIDateConverter(e.$d)[0])}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>

      <div className="report-date-container">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["DatePicker"]}
            style={{ padding: "10px" }}
          >
            <DatePicker
              value={endDate}
              onChange={(e) => setEndDate(MUIDateConverter(e.$d)[0])}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>

      {title !== "Progress Report" && (
        <Box sx={{ minWidth: 120 }}>
          <FormControl style={{ width: "210px" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => setSelectedGroup(e.target.value)}
              value={selectedGroup}
            >
              <MenuItem value=" " disabled>
                Select
              </MenuItem>
              {groupList.map((value, index) => (
                <MenuItem value={value} key={index}>
                  <div className="filter-date">
                    <span>{value}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Button inline type={"submit"}>
        Save
      </Button>
      <Button outline type={"button"} onClick={handleClear}>
        Clear
      </Button>
    </form>
  );
};

export default Header;
