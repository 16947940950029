import * as React from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, Grid, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const StyledMenu = styled((props) => (
	<Menu
	  elevation={0}
	  anchorOrigin={{
		vertical: 'bottom',
		horizontal: 'right',
	  }}
	  transformOrigin={{
		vertical: 'top',
		horizontal: 'right',
	  }}
	  {...props}
	/>
  ))(({ theme }) => ({
	'& .MuiPaper-root': {
	  borderRadius: 6,
	  marginTop: theme.spacing(1),
	  minWidth: 124,
	  color:
		theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
	  boxShadow:
		'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
	  '& .MuiMenu-list': {
		padding: '4px 0',
	  },
	  '& .MuiMenuItem-root': {
		'& .MuiSvgIcon-root': {
		  fontSize: 18,
		  color: theme.palette.text.secondary,
		  marginRight: theme.spacing(1.5),
		},
		'&:active': {
		  backgroundColor: alpha(
			theme.palette.primary.main,
			theme.palette.action.selectedOpacity,
		  ),
		},
	  },
	},
  }));
  	
  function createData(no, name, code, population, size) {
	const density = population / size;
	return { no, name, code, population, size, density };
  }
  
  const rows = [
	createData('1', 'India', 'IN', 1324171354, 3287263),
	createData('2', 'China', 'CN', 1403500365, 9596961),
	createData('3','Italy', 'IT', 60483973, 301340),
	createData('4','United States', 'US', 327167434, 9833520),
	createData('5','Canada', 'CA', 37602103, 9984670),
	createData('6','Australia', 'AU', 25475400, 7692024),
	createData('7','Germany', 'DE', 83019200, 357578),
	createData('8','Ireland', 'IE', 4857000, 70273),
	createData('9','Mexico', 'MX', 126577691, 1972550),
	createData('10','Japan', 'JP', 126317000, 377973),
	createData('11','France', 'FR', 67022000, 640679),
	createData('12','United Kingdom', 'GB', 67545757, 242495),
	createData('13','Russia', 'RU', 146793744, 17098246),
	createData('14','Nigeria', 'NG', 200962417, 923768),
	createData('15','Brazil', 'BR', 210147125, 8515767),
  ];

const ProgramList = (props) => {

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
	const handleChangePage = (event, newPage) => {
	  setPage(newPage);
	};
  
	const handleChangeRowsPerPage = (event) => {
	  setRowsPerPage(+event.target.value);
	  setPage(0);
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMoreMenu = () => {
		props.addRow();	
	}

	
	const columns = [
		{ 
			id: 'no', 
			label: 'S.NO' 
		},
		{ id: 'name', label: 'ID' },
		{
		  id: 'name',
		  label: 'Name',
		  format: (value) => value.toLocaleString('en-US'),
		},
		{
		  id: 'name',
		  label: 'Category',
		  format: (value) => value.toLocaleString('en-US'),
		},
		{
		  id: 'name',
		  label: 'Sub Category',
		  format: (value) => value.toFixed(2),
		  minWidth: 124
		},
		{
			id: 'name',
			label: 'Start Date',
			format: (value) => value.toFixed(2),
			minWidth: 124
		},
		{
			id: 'name',
			label: 'Status',
			format: (value) => value.toFixed(2),
		},
		{
			id: 'name',
			label: 'Version',
			format: (value) => value.toFixed(2),
		},
		{
			id: 'name',
			label: 'Approval',
			icon: <AddIcon sx={{ color: "#6B6B6B" }}/>
		},
		{
			id: 'name',
			label: 'Created Date',
			format: (value) => value.toFixed(2),
			minWidth: 124
		},
		{
			id: 'name',
			label: 'Created by',
			format: (value) => value.toFixed(2),
			minWidth: 124
		},
		{
			id: 'name',
			label: 'Updated Date',
			format: (value) => value.toFixed(2),
			minWidth: 124
		},
		{
			id: 'name',
			label: 'Updated by',
			format: (value) => value.toFixed(2),
			minWidth: 124
		},
		{
			id: 'name',
			label: 'Actions',
			icon: <MoreHorizIcon onClick={handleClick} sx={{ color: "#6B6B6B", cursor: "pointer" }} />,
		},
	  ];

	return(<>
		<Paper sx={{ padding: "28px 24px", marginTop: "20px"}} >
			<TableContainer  sx={{ maxHeight: "calc(100vh - 160px)", border: "1px solid #babfc7", borderRadius: "7px" }}>
				<Table stickyHeader aria-label="sticky table">
				<TableHead>
					<TableRow>
					{columns.map((column) => (
						<TableCell
							key={column.id}
							align={column.align}
							style={{ minWidth: column.minWidth, backgroundColor: "#F4F4F4", border: "1px solid #D0D0D0", padding: "10px 14px" }}
						>
						{column.label}
						</TableCell>
					))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.map((row) => {
						return (
						<TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
							{columns.map((column) => {
							const value = row[column.id];
							return (
								<TableCell key={column.id} align={column.align} style={{border: "1px solid #D0D0D0", padding: "10px 14px"}}>
									{column.format && typeof value === 'number'
										? column.format(value)
										: column.icon ?? value}
								</TableCell>
							);
							})}
						</TableRow>
						);
					})}
				</TableBody>
				</Table>
			</TableContainer>
		</Paper>
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx={{ marginTop: "12px" }}
		>
			<Grid item xs={6} sx={{paddingLeft: "20px"}} >
				<Button variant={"text"} 
				 sx={{
    				textTransform: "capitalize",
    				color: "#000"
				 }}
				><DownloadIcon sx={{fontSize: "18px", marginRight: "4px", fontWeight: 600}} />{"Export"}</Button>
			</Grid>
			<Grid item xs={6}>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					sx={{
						"& .MuiTablePagination-selectLabel":{
							marginBottom: "2px",
							fontWeight: 600
						},
						"& .MuiTablePagination-displayedRows": {
							marginBottom: "2px",
							fontWeight: 500
						}
					}}
				/>
			</Grid>
		</Grid>

		{/* more menu */}
		<StyledMenu
			id="demo-customized-menu"
			MenuListProps={{
			'aria-labelledby': 'demo-customized-button',
			}}
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
		>
			<MenuItem onClick={()=>handleMoreMenu("View")} disableRipple>
				<VisibilityOutlinedIcon /><Typography sx={{ fontSize: "12px" }} >{"View"}</Typography>
			</MenuItem>
			<MenuItem onClick={()=>handleMoreMenu("View Clients")} disableRipple>
				<SupervisorAccountOutlinedIcon /><Typography sx={{ fontSize: "12px" }} >{"View Clients"}</Typography>
			</MenuItem>
			<MenuItem onClick={()=>handleMoreMenu("Edit")} disableRipple>
				<EditIcon />
				<Typography sx={{ fontSize: "12px" }} >{"Edit"}</Typography>
			</MenuItem>
			<MenuItem onClick={()=>handleMoreMenu("Inactive")} disableRipple>
				<LockOutlinedIcon /> <Typography sx={{ fontSize: "12px" }} >{"Inactive"}</Typography>
			</MenuItem>
		</StyledMenu>
		</>
	)
};

export default ProgramList;