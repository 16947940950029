import { useReducer, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { dateConverter } from "../../../hooks/helperFunction";
import { useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";

import "../Report.css";

const initialState = {
  commentList: [],
  addComment: "",
  updateComment: "",
  showEditComment: null,
  editCommentId: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "receiveComment":
      let modifyComment =
        action.payload === "No comments" ? [] : action.payload;

      return { ...state, commentList: modifyComment };
    case "addComment":
      return { ...state, addComment: action.payload };
    case "editComment":
      return {
        ...state,
        showEditComment: action.payload.showEditComment,
        editCommentId: action.payload.commentObj.id,
        updateComment: action.payload.commentObj.comment_text,
      };
    case "closeEditComment": {
      return { ...state, showEditComment: null };
    }
    case "updateComment": {
      return { ...state, updateComment: action.payload };
    }
    default:
      throw new Error("Unknown action");
  }
};

const Comments = ({
  anchorEl,
  closeDispatch,
  currentRow,
  currentDate,
  title,
}) => {
  const utcFormater = dateConverter.utcFormater;
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;

  const [
    { commentList, showEditComment, addComment, updateComment, editCommentId },
    dispatch,
  ] = useReducer(reducer, initialState);

  const getActivityComment = async () => {
    let response;

    if (title === "progressReport") {
      response = await axiosFetch({
        url: `getProgressReportComment/${currentRow.leadId}`,
        method: "get",
      });
    } else {
      response = await axiosFetch({
        url: `/getActivityComment?lead_id=${currentRow.leadId}&activity_date=${currentDate}`,
        method: "get",
      });
    }

    if (response.status === 200) {
      dispatch({ type: "receiveComment", payload: response.data });
    }
  };

  const handleAddComment = async (e) => {
    const commentObj = {
      lead_id: currentRow.leadId,
      activity_date: currentDate,
      user_id: userId,
      comment_text: addComment,
    };

    if (e === "edit") {
      commentObj.is_edited = true;
      commentObj.comment_id = editCommentId;
      commentObj.comment_text = updateComment;
    }

    if (title === "progressReport") {
      delete commentObj.lead_id;
      delete commentObj.activity_date;
      delete commentObj.comment_id;
      commentObj.progress_id = currentRow.leadId;
      if (e === "edit") {
        commentObj.id = editCommentId;
      }
    }

    let response;

    if (title === "progressReport") {
      response = await axiosFetch({
        url: `postProgressReportComment`,
        method: "post",
        requestConfig: commentObj,
      });
    } else {
      response = await axiosFetch({
        url: `/addActivityReportComment`,
        method: "post",
        requestConfig: commentObj,
      });
    }

    if (response.status === 200) {
      toast.success(response.data, {
        position: toast.POSITION.TOP_CENTER,
      });
      closeDispatch({ type: "commentProgress", payload: { isComment: false } });
    }
  };

  const handleUpdate = (e, id) => {
    dispatch({
      type: "editComment",
      payload: { showEditComment: e, commentObj: id },
    });
  };

  const handleEditClose = () => {
    dispatch({ type: "closeEditComment" });
  };

  useEffect(() => {
    getActivityComment();
  }, []);

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() =>
          closeDispatch({
            type: "commentProgress",
            payload: { isComment: false },
          })
        }
        style={{
          width: "500px",
          top: "-10px",
          left: "-100px",
        }}
      >
        <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          style={{
            width: "450px",
            backgroundColor: "transparent",
          }}
        >
          <div className="comment__header">
            <h4 className="coment-title">Comments</h4>
            <CloseIcon
              style={{ fontSize: "21px", color: "#6B6B6B" }}
              onClick={() =>
                closeDispatch({
                  type: "commentProgress",
                  payload: { isComment: false },
                })
              }
            />
          </div>
        </MenuItem>
        <MenuItem
          style={{ backgroundColor: "transparent" }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <ul className="comment-list">
            {commentList.length > 0 &&
              commentList.map((item, index) => (
                <li className="comment-item-list" key={index}>
                  <div className="report-comment-con">
                    <div className="report-title">
                      <span>
                        <PersonIcon
                          style={{ fontSize: "22px", color: "#6B6B6B" }}
                        />
                      </span>
                      <span>{item.commented_by}</span>
                      <div>
                        <span className="report-sub-title">
                          {utcFormater(item.createdAt)[0]}
                        </span>
                        <span className="report-sub-title">
                          {utcFormater(item.createdAt)[1][1]}
                        </span>
                      </div>
                      <span className="report-title-edit">
                        {item.is_edited ? "Edited" : ""}
                      </span>
                    </div>
                    <div className="report-edit-icon">
                      <span onClick={(e) => handleUpdate(e.target, item)}>
                        <EditIcon
                          style={{ fontSize: "16px", color: "#6B6B6B" }}
                        />
                      </span>
                    </div>
                  </div>
                  <span className="comment-text">{item.comment_text}</span>
                </li>
              ))}

            <li className="comment-list-item">
              <span className="comment-item-icon">
                <PersonIcon style={{ fontSize: "22px", color: "#6B6B6B" }} />
              </span>
              <input
                type="text"
                className="comment-input"
                value={addComment}
                onChange={(e) => {
                  dispatch({ type: "addComment", payload: e.target.value });
                }}
              />
              <span className="comment-sent" onClick={handleAddComment}>
                <ArrowUpwardIcon />
              </span>
            </li>
          </ul>
        </MenuItem>
      </Menu>
      <Menu
        id="simple-menu"
        anchorEl={showEditComment}
        open={Boolean(showEditComment)}
        onClose={handleEditClose}
        style={{
          top: "-100px",
          left: "-100px",
        }}
      >
        <MenuItem
          style={{
            width: "400px",
            backgroundColor: "transparent",
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <h4 className="coment-title">Edit Comment</h4>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <div className="comment-list-item">
            <span className="comment-item-icon">
              <PersonIcon style={{ fontSize: "22px", color: "#6B6B6B" }} />
            </span>
            <input
              type="text"
              className="comment-input"
              value={updateComment}
              onChange={(e) => {
                dispatch({ type: "updateComment", payload: e.target.value });
              }}
            />

            <span
              className="comment-sent"
              onClick={() => handleAddComment("edit")}
            >
              <ArrowUpwardIcon />
            </span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Comments;
