import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { SalesAutomation } from "./SalesAutomation/SalesAutomation";
import Card from "../../components/Card/Card";
import ClientAutomation from "./Client Automation/ClientAutomation";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Automation = () => {
  const location = useLocation();
  const state = location.state;

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (state?.tab === 1) {
      setTabValue(1);
    }
  }, [state]);

  return (
    <Card>
      <Box
        sx={{
          minHeight: "100vh",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#000" } }}
            TabIndicatorProps={{
              style: {
                left: `${tabValue === 0 ? 15 : 185}px`, // Adjust the left value dynamically based on tab index
              },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: 600 }}
                >
                  {"Sales Automation"}
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: 600 }}
                >
                  {"Client Automation"}
                </Typography>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={tabValue} index={0}>
          <SalesAutomation />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <ClientAutomation />
        </CustomTabPanel>
      </Box>
    </Card>
  );
};

/* 



*/
