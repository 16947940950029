import React, { useContext, useEffect, useState } from "react";
import BoxHeader from "../../components/BoxHeader";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { openPlugin } from "@react-pdf-viewer/open";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { ToolbarSlot, TransformToolbarSlot } from "@react-pdf-viewer/toolbar";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import axios from "axios";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import { toast } from "react-toastify";

const HandFReportViewer = (props) => {
  const searchPluginInstance = searchPlugin({ ShowSearchPopoverButton: false });
  const toolbarPluginInstance = toolbarPlugin();
  const openPluginInstance = openPlugin();
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    // Download: () => <></>,
    // EnterFullScreen: () => <></>,
    // SwitchTheme: () => <></>,
    // GoToPreviousPage:()=><></>,
    // GoToNextPage:()=><></>,
    SearchTheme: () => <></>,
    Open: () => <></>,
  });
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  /* GET API - H&F Report  */
  const [viewPDF, setViewPDF] = useState();

  useEffect(() => {
    axios
      .get(`${ATLAS_URI}/getHFreportpdfByReportId/${props.id}`, configToken)
      .then((res) => setViewPDF(res.data))
      .catch((err) =>
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      );
  }, []);

  return viewPDF ? (
    <div>
      <Worker workerUrl={ATLAS_URI + "/static/pdf.worker.min.js"}>
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        <Viewer fileUrl={viewPDF} plugins={[toolbarPluginInstance]} />
      </Worker>
    </div>
  ) : (
    <></>
  );
};

export default HandFReportViewer;
