import { Menu, MenuItem } from "@mui/material";

import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const ActionMenu = ({ anchorEl, onClose, currentRow, getPaymentHistory }) => {
  const handlePaymentStatus = async (status) => {
    let request = await axiosFetch({
      url: `/verifyClientPayment`,
      method: "post",
      requestConfig: {
        paymentId: currentRow.id,
        status: status,
      },
    });

    if (request.status === 200) {
      toast.success(request.data, {
        position: toast.POSITION.TOP_CENTER,
      });
      onClose();
      getPaymentHistory();
    }
  };

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{
          top: "-100px",
          left: "-100px",
        }}
      >
        {/* onClick={(e) => setOpen(e.target)} */}
        <MenuItem onClick={() => handlePaymentStatus(2)}>
          <span className="ms-1">Pending</span>
        </MenuItem>
        <MenuItem onClick={() => handlePaymentStatus(3)}>
          <span className="ms-1">Payment Verified</span>
        </MenuItem>
        <MenuItem onClick={() => handlePaymentStatus(4)}>
          <span className="ms-1">Partial Payment</span>
        </MenuItem>
        <MenuItem onClick={() => handlePaymentStatus(5)}>
          <span className="ms-1">Excess Amount Received</span>
        </MenuItem>
        <MenuItem onClick={() => handlePaymentStatus(6)}>
          <span className="ms-1">Less Amount received</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionMenu;
