import React, { useState, useEffect } from "react";
import * as $ from "jquery";
import { Modal } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import Dialog from "./Dialog";

function EmailModal(props) {
  const [state, setState] = useState({
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
  });

  useEffect(() => {
    var emailInfo = props.state.Email;
    if (emailInfo.VisitInfo && emailInfo.VisitInfo.length !== 0) {
      var visit =
        props.state.Email.VisitInfo[props.state.Email.VisitInfo.length - 1];
      var x =
        "Planned Visit Date: " +
        visit.VisitDate +
        "\n" +
        "Project: " +
        visit.Project +
        "\n" +
        "Property: " +
        visit.Property +
        "\n" +
        "Description: " +
        visit.Description;
      $("#Message").text(x);
    }
  }, []);
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        props.state.EmailInfo.ServiceID,
        props.state.EmailInfo.TemplateID,
        e.target,
        props.state.EmailInfo.UserID
      )
      .then(
        (result) => {
          $("#Message").text("");
          $("#Subject").text("");
          const newDialogInfo = {
            isOpened: true,
            text: "Email Sent",
            type: "Success",
          };
          setState((prevState) => ({
            ...prevState,
            dialogInfo: newDialogInfo,
          }));
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              dialogInfo: { isOpened: false, text: "", type: "" },
            }));
            props.closeEmailModal();
          }, 2500);
        },
        (error) => {}
      );
    e.target.reset();
  }
  return (
    <Modal show={props.state.isEmailModalOpen} onHide={props.closeEmailModal}>
      <Dialog
        onClose={(e) =>
          setState((prevState) => ({
            ...prevState,
            dialogInfo: { isOpened: false, text: "" },
          }))
        }
        dialogInfo={state.dialogInfo}
      />
      <Modal.Header closeButton>
        <Modal.Title>Send Email</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="">
            <div className="taskFieldArea">
              <form onSubmit={sendEmail}>
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Email</label>
                      <small className="req"> *</small>
                      <input
                        type="email"
                        name="Email"
                        id="ModalEmail"
                        className="form-control"
                        required
                        value={props.state.Email.email_id}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Subject</label>
                      <small className="req"> *</small>
                      <input
                        type="text"
                        id="Subject"
                        className="form-control"
                        required
                        name="Subject"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Message</label>
                      <small className="req"> *</small>
                      <textarea
                        id="Message"
                        name="Message"
                        className="form-control"
                        required
                        rows={5}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="box-footer pr0">
                  <button className="btn btn-info pull-right">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EmailModal;
