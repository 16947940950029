import React, { useContext, useEffect, useState } from "react";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FreshLeads from "./FreshLeads";
import LeadDetails from "./LeadDetails";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";

function PopupPDFUpload(props) {
  const [show, setShow] = useState(props.show1);

  const [file, setFile] = useState();

  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const handleClose = () => {
    props.setShow1(false);
  };

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleSubmit = () => {
    // event.preventDefault()
    const formData = new FormData();
    formData.append("H_F_report", file);
    formData.append("fileName", file.name);

    if (file.type != "application/pdf") {
      alert("upload pdf file");
      return;
    }

    axios
      .put(`${ATLAS_URI}/upload/${props.leadId}`, formData, configToken)
      .then((resp) => {
        if (resp.status == 200) {
          props.setShow1(false);
          alert("uploaded report");
        }
      });
  };

  useEffect(() => {
    props.show1 && setShow(true);
  }, [props.show1]);

  const handleShow = () => setShow(props.show1);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload H&F</Modal.Title>
          <GrClose
            style={{
              backgroundColor: isHovering ? "#FF0000" : "",
              color: isHovering ? "#fff" : "",
              border: isHovering ? "1px solid" : "",
              height: isHovering ? "30px" : "",
              width: isHovering ? "30px" : "",
              opacity: isHovering ? "0.7" : "",
            }}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          {/* <form onSubmit={handleSubmit}> */}
          <input
            type="file"
            name="file"
            onChange={handleChange}
            accept="application/pdf"
          />
          {file ? (
            <div>
              <p>Filename: {file.name}</p>
              <p>Filetype: {file.type}</p>
              <p>Size in bytes: {file.size}</p>
            </div>
          ) : (
            <p></p>
          )}
          {/* <button type="submit">Upload</button>
        </form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleSubmit()}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={()=>handleClose()}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopupPDFUpload;
