import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import BoxHeader from "../../../components/BoxHeader";
import axios from "axios";
import { ATLAS_URI } from "../../../Constants";
import stateContext from "../../../context/StateContext";
import { useHistory } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import ViewTemplate from "./ViewTemplate";
// import ClientWorkoutPlan from "../../Clients/ClientWorkoutPlan";
import { dateConverter } from "../../../Utlis/dateConversion";
import { useDispatch } from "react-redux";

import "./WorkoutTemplate.css";
import ClientWorkoutPlan from "../../Clients/clientPlans/WorkoutPlan/ClientWorkoutPlan";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

import {
  updateEditWorkout,
  updateView,
} from "../../store/WorkoutPlan/WorkoutPlan";
import TemplateTable from "./TemplateTable";

function WorkoutTemplate() {
  const dispatch = useDispatch();
  let history = useHistory();
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "1000px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const [templateFlag, setTemplateFlag] = useState(false);
  const [openNewTemplate, setOpenNewTemplate] = useState(false);
  const [editTemplateFlag, setEditTemplateFlag] = useState(false);

  const [templateList, setTemplateList] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false);

  const [selectedTemplateId, setSelectedTemplateId] = useState([]);

  const [state, setState] = useState();

  const restFunction = () => {
    setEditTemplateFlag(false);
    setTemplateFlag(false);
  };

  const openWorkoutPlanModal = (data) => {
    // setSelectedTemplateId(id);
    // fetchData(id);

    dispatch(updateView(data));
    dispatch(
      updateEditWorkout({
        id: data.id,
        name: "template",
      })
    );
    setOpenNewTemplate(true);
    setTemplateFlag(true);
    setEditTemplateFlag(data);
  };

  const closeWorkoutPlanModal = () => {
    setShowTemplate(false);
  };
  const editTemplate = async (data) => {
    dispatch(
      updateEditWorkout({
        id: data.id,
        name: "template",
      })
    );
    setOpenNewTemplate(true);
    setTemplateFlag(true);
    setEditTemplateFlag(data.id);

    // setOpenNewTemplate(false);
    // history.push({
    //   pathname: `/Templates/workoutTemplate/${data.id}`,
    //   state: {
    //     from: 9,
    //     id: data.id,
    //   },
    // });
  };

  const deleteTemplate = (id) => {
    axios
      .delete(`${ATLAS_URI}/deleteWorkoutTemplateById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Template Deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          getTemplateList();
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => console.error(err));
  };

  const [columnDefs] = useState([
    { field: "sno", headerName: "S.No", minWidth: 400 },
    { field: "template_name", headerName: "Template name", minWidth: 400 },
    { field: "createdBy", headerName: "Created By", minWidth: 400 },
    { field: "createdAt", headerName: "Created At", minWidth: 400 },
    {
      field: "Action",
      cellRenderer: (params) => {
        return (
          <>
            <button
              className="btn btn-default btn-xs no-focus"
              title="Edit"
              onClick={() => editTemplate(params.data)}
            >
              <i className="fas fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              className="btn btn-default btn-xs no-focus"
              title="View"
              onClick={() => openWorkoutPlanModal(params.data.id)}
            >
              <i className="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button
              className="btn btn-default btn-xs no-focus"
              title="Delete"
              onClick={() => deleteTemplate(params.data.id)}
            >
              <i className="fas fa-trash" aria-hidden="true"></i>
            </button>
          </>
        );
      },
    },
  ]);

  const getTemplateList = async () => {
    const response = await axiosFetch({
      url: `/getWorkoutTemplateDetails`,
      method: "get",
    });

    if (response.status === 200) {
      setTemplateList(response.data.reverse());
    }

    if (response.status === 401) {
      history.push("/");
    }
  };

  const handleTemplate = () => {
    setOpenNewTemplate(false);
    getTemplateList();
  };

  const createNewTemplate = () => {
    setOpenNewTemplate(true);
    setTemplateFlag(true);
  };

  const closeTemplate = (params) => {
    setOpenNewTemplate(params);
  };

  const handleBack = () => {
    setOpenNewTemplate(false);
  };

  useEffect(() => {
    getTemplateList();
  }, []);
  return (
    <section className=" profileInfo workoutTemplate">
      <div className="row">
        <div className="profileTab">
          <div>
            <div className="col-md-12 ">
              <div>
                {openNewTemplate && (
                  <button className="back-btn" onClick={handleBack}>
                    Back
                  </button>
                )}
              </div>
              <div className="box-body bozero">
                <BoxHeader title="Workout Template" />
                <div style={containerStyle}>
                  <div style={gridStyle} className="ag-theme-alpine">
                    {!openNewTemplate ? (
                      <>
                        <Button
                          onClick={createNewTemplate}
                          style={{ float: "right" }}
                        >
                          Create New Temaplate
                        </Button>
                        <br />
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {openNewTemplate ? (
                      <ClientWorkoutPlan
                        className="workoutTemplate"
                        templateFlag={templateFlag}
                        handleTemplate={handleTemplate}
                        editTemplateFlag={editTemplateFlag}
                        restFunction={restFunction}
                      />
                    ) : (
                      // <AgGridReact
                      //   rowData={templateList}
                      //   columnDefs={columnDefs}
                      // />
                      <TemplateTable
                        editTemplate={editTemplate}
                        openWorkoutPlanModal={openWorkoutPlanModal}
                        deleteTemplate={deleteTemplate}
                        templateList={templateList}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showTemplate && state.length !== 0 && (
        <ViewTemplate
          showTemplate={showTemplate}
          closeWorkoutPlanModal={closeWorkoutPlanModal}
          id={selectedTemplateId}
          data={state}
        />
      )}
    </section>
  );
}

export default WorkoutTemplate;
