import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Modal, CloseButton } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ATLAS_URI, CALL_METHOD } from "../../Constants";
import { PlivoContext } from "../../context/PlivoContext";
import stateContext from "../../context/StateContext";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import {
  DataGridPro,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { CALLER_ID, INDIA_CALLER_ID } from "../../Constants";
function LeadViewCallHistory(props) {
  const dispatch = useDispatch();

  const contextState = useContext(stateContext);
  const [programList, setProgramList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState("");
  const [isDisplayFollowUp, setIsDisplayFollowUp] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  // const { /* currentCallStatus, */ callDurationInfo, plivoBrowserSdk/* , duration */ } = contextState
  const { configToken, operator } = contextState.state;

  const { currentCallStatus, duration, plivo } = useContext(PlivoContext);
  const startDateRef = useRef();
  const [startDate, setStartDate] = useState("");
  let loggedInUser = JSON.parse(window.localStorage.getItem("operator"));

  const [leadData, setLeadData] = useState();

  const [callHistory, setCallHistory] = useState([]);
  const [openNotePopUp, setOpenNotePopup] = useState(false);
  const [noteUUID, setNoteUUID] = useState("");
  const [editNote, setEditNote] = useState("");

  const [nextFollowupDateTime, setNextFollowupDateTime] = useState("");
  const [nextFollowupInfo, setNextFollowupInfo] = useState("");
  const [lastFollowupInfo, setLastFollowupInfo] = useState("");
  const [isCallHistory, setIsCallHistory] = useState(false);
  const [leadInfo, setLeadInfo] = useState({});

  const [leadStatusList, setLeadStatusList] = useState([]);
  const [leadStatus, setLeadStatus] = useState("");

  const [leadStageList, setLeadStageList] = useState([]);
  const [leadDispositionList, setLeadDispositionList] = useState([]);
  const [leadSubDispositionList, setLeadSubDispositionList] = useState([]);
  const [leadSubNotInterestedList, setLeadSubNotInterestedList] = useState([]);

  const [expectedCloseDate, setExpectedCloseDate] = useState("");
  const [expectedDealSize, setExpectedDealSize] = useState(0);
  const routeParams = useParams();

  const [selectedLeadStage, setSelectedLeadStage] = useState("");
  const [selectedLeadDisposition, setSelectedLeadDisposition] = useState("");
  const [selectedLeadSubDisposition, setSelectedLeadSubDisposition] =
    useState("");
  const [selectedLeadSubNotInterested, setSelectedLeadSubNotInterested] =
    useState("");

  const [updatedleadStage, setUpdatedLeadStage] = useState("");
  const [programName, setProgramName] = useState("");
  const callObjectInfo = useRef(null);

  const [isCallModalShow, setIsCallModalShow] = useState(false);
  const [updatedleadStatus, setUpdatedLeadStatus] = useState("");
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "1000px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  let history = useHistory();
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);
  const onGridReady = useCallback((params) => {
    /* fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
          .then((resp) => resp.json())
          .then((data) => {}); */
  }, []);

  const [state, setState] = useState({
    resetNewRow: {
      id: "",
      Client: "",
      name: "",
      ClientContact: "",
      email_id: "",
      ClientLocation: "",
      LeadStatus: "",
      Remarks: [],
      PropertyType: "Select",
      RequiredLocation: "",
      MinArea: "",
      MaxArea: "",
      MinBudget: "",
      MaxBudget: "",
      RequiredLoan: "",
      source: "Select",
      call_status: "",
      LeadDate: "",
      LastFollowUpDate: "",
      next_followup_date: "",
      FollowUpList: [],
      CreatedBy: "",
      User: "Select",
      Role: "Select",
    },
    newTableRow: {
      Source: "Select",
      User: "Select",
      Role: "Select",
      CreatedAt: "",
    },
    //From Data
    tableBodyList: [],
    sourceList: [],
    userList: [],
    roleList: [],
    tableBodyDisplayList: [],
    editingActivated: false,
    editingID: "",
    isFollowUpModalOpen: false,
    currentCallUUID: "",
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
    Email: "",
    EmailInfo: {},
    isModalOpen: false,
    SelectedLeads: [],
    isEmailModalOpen: false,
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => {
        return !!params?.row?.type ? params?.row?.type : "-";
      },
    },
    {
      field: "fromName",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => {
        return !!params?.row?.fromName ? params?.row?.fromName : "-";
      },
    },
    {
      field: "fromNumber",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "From Number",
      valueGetter: (params) => {
        return !!params?.row?.from ? params?.row?.from : "-";
      },
    },
    {
      field: "firstName",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "First Name",
      valueGetter: (params) => {
        return !!params?.row?.firstName ? params?.row?.firstName : "-";
      },
    },
    {
      field: "lastName",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Last Name",
      valueGetter: (params) => {
        return !!params?.row?.lastName ? params?.row?.lastName : "-";
      },
    },
    {
      field: "contactNumber",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Contact Number",
      valueGetter: (params) => {
        return !!params?.row?.to ? params?.row?.to : "-";
      },
    },
    {
      field: "leadStatus",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Lead Status",
      valueGetter: (params) => {
        let lead = params.row.Lead;
        if (!!lead) {
          if (!!lead.lead_status) {
            return !!lead.lead_status ? lead.lead_status : "-";
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "callDateTime",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Call Date Time",
      valueGetter: (params) => {
        return params.row.callDateTime;
        // return date.split('T')[0]
      },
    },
    {
      field: "callDurationinSeconds",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Duration(seconds)",
      valueGetter: (params) => {
        // let duration = params.row.callDuration;
        // let seconds = Math.round(duration/60);
        return !!params.row.callDurationinSeconds
          ? params.row.callDurationinSeconds
          : "-";
      },
    },
    {
      field: "notes",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Notes",
      renderCell: (params) => {
        return !!params.row.notes ? params.row.notes : "-";
      },
    },
    {
      field: "recordingURL",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Recording URL",
      renderCell: (params, id) => {
        return (
          <audio controls>
            <source src={params.row.recordingURL} type="audio/mp3" />
          </audio>
        );
      },
    },
    {
      field: "callStatus",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Call Status",
      valueGetter: (params) => {
        return !!params.row.callStatus ? params.row.callStatus : "-";
      },
    },
  ]);

  const handleChangeStartDate = (e) => {
    setStartDate(e.target.value);
    // if (e.target.value === "") {
    //   setStartDateErrMsg(true);
    //   return false;
    // } else {
    //   setStartDateErrMsg(false);
    // }
  };

  function getCallHistory() {
    setIsLoading(true);

    axios
      .get(`${ATLAS_URI}/getCallsByLeadId/${props.leadId}`, configToken)
      .then((res) => {
        if (res.status == 200) {
          // setCallHistory(res.data)
          setIsLoading(false);
          setTotalRecords(res.data.count);
          props.setCurrentNote(res?.data[0]?.Lead?.notes);
          setCallHistory(
            res.data.map((c) => {
              c.fromName = c?.User?.Username;
              c.firstName = c?.Lead?.name;
              c.lastName = "";
              c.fromNumber = c?.callerId;
              c.contactNumber = c?.to;
              c.leadStatus = c?.Lead?.LeadStatusID?.Status;
              c.callDateTime =
                c?.createdAt &&
                new Date(c?.createdAt).toDateString() +
                  ", " +
                  new Date(c?.createdAt).toLocaleTimeString();
              c.callDurationinSeconds = c?.callDuration;
              c.type = c.type == 1 ? "Outbound" : "Inbound";
              return c;
            })
          );

          return;
        }
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getNextFollowupInfo() {
    axios
      .get(
        `${ATLAS_URI}/getLatestFollowupByLeadIdAndUserId/${props.leadId}/${operator.id}`,
        configToken
      )
      .then((res) => {
        if (res.status == 200) {
          setNextFollowupInfo(res.data?.NextFollowUpDate);
          return;
        }
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLastFollowupInfo() {
    axios
      .get(
        `${ATLAS_URI}/getLastFollowupDateByLeadIdAndUserId/${props.leadId}/${operator.id}`,
        configToken
      )
      .then((res) => {
        if (res.status == 200) {
          setLastFollowupInfo(res.data?.createdAt);
          return;
        }
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLeadStatusList() {
    axios
      .get(`${ATLAS_URI}/getStatuses`, configToken)
      .then((res) => {
        setLeadStatusList(res.data);
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLeadInfo(leadId) {
    axios
      .get(`${ATLAS_URI}/getLeadByID/${leadId}`, configToken)
      .then((res) => {
        if (!!res.data.leadStageList) {
          let dispositionInfo;
          if (res.data.leadStageList.leadStageInfo !== null) {
            let stageInfo = res.data.leadStageList.leadStageInfo;
            setSelectedLeadStage(res.data.leadStageList.vmax_lead_stage_id);
          }
          if (res.data.leadStageList.leadDispositionInfo !== null) {
            dispositionInfo = res.data.leadStageList.leadDispositionInfo;
            setSelectedLeadDisposition(
              res.data.leadStageList.vmax_lead_disposition_id
            );
            getLeadSubDispositionListByDispositionId(
              res.data.leadStageList.vmax_lead_disposition_id,
              res.data.leadStageList.vmax_lead_stage_id
            );
          }
          if (res.data.leadStageList.leadSubDispositionInfo !== null) {
            let subdispositionInfo =
              res.data.leadStageList.leadSubDispositionInfo;
            setSelectedLeadSubDisposition(
              res.data.leadStageList.vmax_lead_sub_disposition_id
            );
            if (dispositionInfo !== undefined) {
              getLeadSubNotInterestedListByDispositionIdAndSubDispositionId(
                dispositionInfo.id,
                subdispositionInfo.id
              );
            }
          }
          if (res.data.leadStageList.leadSubNotInterestedInfo !== null) {
            let subNotInterestedInfo =
              res.data.leadStageList.leadSubNotInterestedInfo;
            setSelectedLeadSubNotInterested(subNotInterestedInfo.id);
          }
        }
        setLeadInfo(res.data);
        setLeadStatus(res.data?.lead_status);
        setUpdatedLeadStatus(res.data?.lead_status);
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLatestLeadStageRelationByLeadId(leadId) {
    axios
      .get(
        `${ATLAS_URI}/getLatestLeadStageRelationByLeadId/${leadId}`,
        configToken
      )
      .then((res) => {
        setUpdatedLeadStage(res.data?.vmax_lead_stage_id);
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLeadStageList() {
    axios
      .get(`${ATLAS_URI}/getLeadStages`, configToken)
      .then((res) => {
        setLeadStageList(res.data);
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLeadDispositionList(stageId) {
    axios
      .get(`${ATLAS_URI}/getLeadDispositions`, configToken)
      .then((res) => {
        // setLeadDispositionList(res.data)
        if (res.status === 200) {
          if (parseInt(stageId) === 7 || parseInt(stageId) === 5) {
            let disposition_id = leadStageList.filter(
              (list) => list.id == stageId
            )[0].vmax_lead_disposition_id;

            setLeadDispositionList(
              res.data.filter((list) => list.id == disposition_id)
            );
          } else {
            setLeadDispositionList(res.data);
          }
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLeadSubDispositionListByDispositionId(dispositionId, stageId) {
    let url;

    // url = `/v3/getClientSubDisposition?clientStatus=0&dispositionId=${dispositionId}`;
    if (dispositionId == 2) {
      url = `getLeadSubDispositionsByDispositionId/${dispositionId}`;
    } else {
      url = `getLeadSubDispositionsByDispositionIdAndStageId/${dispositionId}/${stageId}`;
    }
    // axios.get(`${ATLAS_URI}/getLeadSubDispositionsByDispositionId/${dispositionId}`, configToken)
    axiosFetch({
      url,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          // if( parseInt(selectedLeadStage)=== 1 && parseInt(dispositionId) === 1 || (parseInt(selectedLeadStage)=== 4 && parseInt(dispositionId) === 1 ))
          // {
          //   let disposition_id = leadStageList.filter(list => list.id == dispositionId)[0].vmax_lead_disposition_id;

          setLeadSubDispositionList(res.data);

          // }
          //     else if(parseInt(selectedLeadStage)=== 2 && parseInt(dispositionId) === 1){
          //         setLeadSubDispositionList(res.data.slice(2,3))
          //     }
          //     else{
          //         setLeadSubDispositionList(res.data)
          //     }
          // }
        }
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLeadSubNotInterestedListByDispositionIdAndSubDispositionId(
    dispositionId,
    subDispositionId
  ) {
    axios
      .get(
        `${ATLAS_URI}/getLeadSubNotInterestedListByDispositionIdAndSubDispositionId/${dispositionId}/${subDispositionId}`,
        configToken
      )
      .then((res) => {
        setLeadSubNotInterestedList(res.data);
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  useEffect(() => {
    getCallHistory();
    getNextFollowupInfo();
    getLastFollowupInfo();
    getLeadStatusList();

    getLeadStageList();
    if (selectedLeadDisposition !== 7) {
      getLeadDispositionList();
    }

    getLeadInfo(props.leadId);

    getLatestLeadStageRelationByLeadId(props.leadId);

    // for call history props.currentCallUUID will be undefined
    if (!props.currentCallUUID) {
      setIsCallHistory(true);
    }
  }, []);

  function onLeadStageChange(e) {
    setSelectedLeadStage(e.target.value);
    let isDisplayFollowUp = leadStageList.filter(
      (stage) => stage.id === parseInt(e.target.value)
    )[0].isFollowupDisplay;
    setIsDisplayFollowUp(isDisplayFollowUp);

    // getLeadSubDispositionListByDispositionId(e.target.value)
    getLeadDispositionList(e.target.value);
    setSelectedLeadDisposition("");
    setSelectedLeadSubDisposition("");
  }

  function onLeadDispositionChange(e) {
    setSelectedLeadDisposition(e.target.value);
    getLeadSubDispositionListByDispositionId(e.target.value, selectedLeadStage);

    // might change disposition after selected sub not ineterested, so set empty
    setLeadSubNotInterestedList([]);
  }

  function onLeadSubDispositionChange(e) {
    setSelectedLeadSubDisposition(e.target.value);

    let isDisplayFollowUp = leadSubDispositionList.filter(
      (subDisposition) => subDisposition.id === parseInt(e.target.value)
    )[0].isFollowupDisplay;
    setIsDisplayFollowUp(isDisplayFollowUp);

    if (selectedLeadDisposition == 1 && e.target.value == 4) {
      getLeadSubNotInterestedListByDispositionIdAndSubDispositionId(
        selectedLeadDisposition,
        e.target.value
      );
    } else {
      setLeadSubNotInterestedList([]);
    }
  }

  function onLeadSubNotInterestedChange(e) {
    setSelectedLeadSubNotInterested(e.target.value);
  }

  function saveNote(callUUID, notes) {
    const postNote = {
      // callUUID: callUUID,
      id: props.leadId,
      notes: notes,
    };
    axios
      .post(`${ATLAS_URI}/updateLead/${props.leadId}`, postNote, configToken)
      .then((noteRes) => {
        if (noteRes.status == 200) {
          // props.setCurrentNote('')
          // getCallHistory()
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function saveFollowupInfo() {
    const postFollowupData = {
      next_followup_date: nextFollowupDateTime,
      userId: operator.id,
    };
    axios
      .post(
        `${ATLAS_URI}/addFollowUp/${props.leadId}`,
        postFollowupData,
        configToken
      )
      .then((followupRes) => {
        if (followupRes.status == 200) {
          setNextFollowupDateTime("");
          getNextFollowupInfo();
        }
      })
      .catch((err) => {});
  }

  function saveLeadStageRelation() {
    const postLeadStageRelationData = {
      leadId: props.leadId,
    };
    if (selectedLeadStage != "") {
      postLeadStageRelationData.vmax_lead_stage_id = selectedLeadStage;
    }
    if (selectedLeadSubDisposition != "") {
      postLeadStageRelationData.vmax_lead_sub_disposition_id =
        selectedLeadSubDisposition;
    }
    if (selectedLeadSubNotInterested != "") {
      postLeadStageRelationData.vmax_lead_sub_not_interested_id =
        selectedLeadSubNotInterested;
    }
    if (expectedCloseDate != "") {
      postLeadStageRelationData.expected_close_date = expectedCloseDate;
    }
    if (selectedLeadDisposition !== "") {
      postLeadStageRelationData.vmax_lead_disposition_id =
        selectedLeadDisposition;
    }
    // if (expectedDealSize) {
    //     postLeadStageRelationData.expected_deal_size = expectedDealSize
    // }

    axios
      .post(
        `${ATLAS_URI}/postLeadStageRelation`,
        postLeadStageRelationData,
        configToken
      )
      .then((postStageRelation) => {
        if (postStageRelation.status == 200) {
          if (selectedLeadStage != "") {
            setUpdatedLeadStage(selectedLeadStage);
          }
        }
      })
      .catch((err) => {});
  }

  function updateLeadInfo() {
    const leadData = {
      id: props.leadId,
      lead_status: leadStatus,
    };
    axios
      .post(`${ATLAS_URI}/updateLead/${props.leadId}`, leadData, configToken)
      .then((leadInfo) => {
        if (leadInfo.status == 200) {
          setUpdatedLeadStatus(leadInfo.data.lead_status);
        }
      })
      .catch((err) => {});
  }

  // Hours calculation
  const hours = Math.floor(duration / 360000);

  // Minutes calculation
  const minutes = Math.floor((duration % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((duration % 6000) / 100);

  const milliseconds = duration % 100;

  const getProgramList = (params) => {
    if (params === undefined) {
      params = "";
    }

    axiosFetch({
      method: "get",
      url: `/getProducts/${params}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setProgramList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onProgramChange = (e) => {
    setProgramName(e.target.value);
  };
  useEffect(() => {
    getProgramList();
  }, []);

  //   const getLeadProgram = () => {
  //     axiosFetch({
  //       method: "get",
  //       url: `/getProductsByLeadId/${routeParams.id}`,
  //     })
  //       .then((res) => {
  //         if (res.status === 200) {

  //           setProgramList(res.data);
  //         } else if (res.status === 400) {
  //           toast.error("Bad Request", {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         } else if (res.status === 401) {
  //           history.push("/");
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   };
  //   useEffect(() => {
  //     getLeadProgram();
  //   }, []);

  const handleSelect = () => {
    // if (startDate === "") {
    //   setStartDateErrMsg(true);
    //   return false;
    // }
    if (programName !== "" && startDate !== "") {
      axiosFetch({
        method: "post",
        url: "/addProductToLead",
        requestConfig: {
          lead_id: routeParams.id,
          prod_id: programName,
          start_date: startDate,
          deal_size: !!expectedDealSize ? expectedDealSize : undefined,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Program Added Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            // handleClose();
            // getLeadProgram();
            // getProgramList()
          } else if (res.status === 400) {
            toast.error("Bad Request", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const handleChangePaginationModel = (model) => {
    setPaginationModel(model);
  };

  const viewCallModal = () => {
    if (!operator.Mobile_number) {
      toast.error("Agent mobile number is not provided", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    callObjectInfo.current = {
      agentPhoneNumber: operator.Mobile_number,
      leadPhoneNumber: leadData.filter((data) => data.id === props.leadId)[0]
        ?.contact_number,
      leadId: props.leadId,
      userId: operator.id,
      alternativeNumber: "",
    };

    // setCallObjectInfo(callObject)

    setIsCallModalShow(true);
  };

  function callAction(callNumber) {
    setIsCallModalShow(false);

    const callObj = { ...callObjectInfo.current, leadPhoneNumber: callNumber };

    if (CALL_METHOD == "WebRTC") {
      const dest = sanitizeNumber(callObj.leadPhoneNumber);

      let customCallerId = CALLER_ID;
      if (dest.startsWith("+91")) {
        customCallerId = INDIA_CALLER_ID;
      }

      const extraHeaders = {
        "X-PH-Test1": "test1",
        "X-PH-header1": customCallerId,
        "X-PH-leadId": callObjectInfo.current.leadId,
        "X-PH-userId": operator.id,
        "X-PH-page": 1,
      };

      plivo.client.call(dest, extraHeaders);
    } else {
      axios
        .post(`${ATLAS_URI}/makeOutboundCall/`, callObj, configToken)
        .then((callRes) => {
          if (callRes.status == 200) {
            setState((prevState) => ({
              ...prevState,
              currentCallUUID: callRes.data.requestUuid,
            }));
            // openFollowUpModal(callObjectInfo.leadId, state, setState)
            history.push({
              pathname: "/Leads/leadView/" + callObjectInfo.current.leadId,
              state: {
                currentCallUUID: callRes.data.requestUuid,
                isCallHistoryView: true,
              },
            });
          }
        });
    }
  }

  function sanitizeNumber(input) {
    let sanitized = input.replace("-", "");
    sanitized = sanitized.replace(" -", "");
    sanitized = sanitized.replace("- ", "");
    // sanitized     = sanitized.replace('+','');
    sanitized = sanitized.replace(/\s+/g, "");
    return sanitized;
  }

  return (
    <div className="row">
      <div className="col-lg-8 col-md-8 col-sm-8">
        <div className="taskFieldArea">
          {!isCallHistory && (
            <form onSubmit={() => {}}>
              <div>
                <div className="container">
                  <div>
                    {CALL_METHOD == "WebRTC" && (
                      <>
                        <div className="row">
                          <div className="col-md">
                            <strong> Call Status </strong>
                          </div>
                          <div className="col-md">{currentCallStatus}</div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md">
                            <strong>Duration</strong>
                          </div>
                          <div className="col-md">
                            {duration !== 0 ? duration : "-"}
                          </div>
                        </div>
                        <br />
                      </>
                    )}

                    {isDisplayFollowUp !== null && isDisplayFollowUp ? (
                      <div className="row">
                        <div className="col-md">
                          <label>
                            <strong>Next Followup Date</strong>
                          </label>
                        </div>
                        <div className="col-md">
                          <input
                            name="call_next_followup_date"
                            type="datetime-local"
                            min={new Date().toISOString().slice(0, 16)}
                            onChange={(e) =>
                              setNextFollowupDateTime(e.target.value)
                            }
                            value={nextFollowupDateTime}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <br />

                    <div className="row">
                      <div className="col-md">
                        <strong>Stage</strong>
                      </div>
                      <div className="col-md">
                        <select
                          name="call_lead_stage"
                          value={selectedLeadStage}
                          onChange={onLeadStageChange}
                        >
                          <option value="">{"Select"}</option>
                          {leadStageList.map((status) => (
                            <option key={status.id} value={status.id}>
                              {status.leadStage}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <br />

                    {selectedLeadStage === "5" || selectedLeadStage === "7" ? (
                      <></>
                    ) : (
                      <div className="row">
                        <div className="col-md">
                          <strong>Lead Disposition</strong>
                        </div>
                        <div className="col-md">
                          <select
                            name="call_lead_disposition"
                            value={selectedLeadDisposition}
                            onChange={onLeadDispositionChange}
                          >
                            <option value="">{"Select"}</option>
                            {leadDispositionList.map((status) => (
                              <option key={status.id} value={status.id}>
                                {status.leadDisposition}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <br />
                    {selectedLeadStage == "5" ||
                    selectedLeadStage == "7" ||
                    (selectedLeadStage == "8") &
                      (selectedLeadDisposition == "1") ? (
                      <></>
                    ) : (
                      <div className="row">
                        <div className="col-md">
                          <strong>Lead Sub Disposition</strong>
                        </div>
                        <div className="col-md">
                          <select
                            name="call_lead_sub_disposition"
                            value={selectedLeadSubDisposition}
                            onChange={onLeadSubDispositionChange}
                          >
                            <option value="">{"Select"}</option>
                            {leadSubDispositionList.map((status) => (
                              <option key={status.id} value={status.id}>
                                {status.leadSubDisposition}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <br />

                    {leadSubNotInterestedList.length > 0 && (
                      <div className="row">
                        <div className="col-md">
                          <strong>Lead Sub Not Interested</strong>
                        </div>
                        <div className="col-md">
                          <select
                            name="call_lead_sub_not_interested"
                            value={selectedLeadSubNotInterested}
                            onChange={onLeadSubNotInterestedChange}
                          >
                            <option value="">{"Select"}</option>
                            {leadSubNotInterestedList.map((status) => (
                              <option key={status.id} value={status.id}>
                                {status.leadSubNotInterested}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <br />
                    <div className="row">
                      <div className="col-md">
                        <strong>Call Note</strong>
                      </div>
                      <div className="col-md">
                        <textarea
                          id="callNote"
                          onChange={(e) => props.setCurrentNote(e.target.value)}
                          value={props.currentNote}
                        ></textarea>
                      </div>
                    </div>
                    <br />

                    {(selectedLeadStage === "1" &&
                      selectedLeadDisposition == "1") ||
                    (selectedLeadStage === "4" &&
                      selectedLeadDisposition == "1") ? (
                      <div className="row">
                        <div className="col-md">
                          <strong>Program Name</strong>
                        </div>
                        <div className="col-md">
                          <select onChange={onProgramChange}>
                            <option value="">{"Select"}</option>
                            {programList.map((program) => (
                              <option key={program.id} value={program.id}>
                                {program.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <br />
                    {(selectedLeadStage === "1" &&
                      selectedLeadDisposition == "1") ||
                    (selectedLeadStage === "4" &&
                      selectedLeadDisposition == "1") ? (
                      <div className="row">
                        <div className="col-md">
                          <strong>Start Date</strong>
                        </div>
                        <div className="col-md">
                          <input
                            placeholder="Start Date"
                            class="textbox-n"
                            type="text"
                            onFocus={() => (startDateRef.current.type = "date")}
                            onBlur={() => (startDateRef.current.type = "text")}
                            ref={startDateRef}
                            value={startDate}
                            min={new Date().toISOString().split("T")[0]}
                            onChange={handleChangeStartDate}
                            onKeyDown={(e) =>
                              new Date().toISOString().split("T")[0] &&
                              e.preventDefault()
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <br />
                    {selectedLeadDisposition == 1 && (
                      <>
                        <div className="row">
                          <div className="col-md">
                            <strong>Expected Close Date</strong>
                          </div>
                          <div className="col-md">
                            <input
                              name="expected_close_date"
                              type="date"
                              min={new Date()}
                              onChange={(e) =>
                                setExpectedCloseDate(e.target.value)
                              }
                              value={expectedCloseDate}
                            />
                          </div>
                        </div>
                        <br />
                        {((selectedLeadStage == "1" &&
                          selectedLeadDisposition == "1") ||
                          (selectedLeadStage == "4" &&
                            selectedLeadDisposition == "1")) && (
                          <div className="row">
                            <div className="col-md">
                              <strong>Expected Deal Size</strong>
                            </div>
                            <div className="col-md">
                              <input
                                type="number"
                                min={0}
                                onChange={(e) => {
                                  setExpectedDealSize(e.target.value);
                                }}
                                value={expectedDealSize}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="box-footer pr0">
                <Button onClick={() => plivo.client.hangup()}>End Call</Button>
                <Button
                  className="btn btn-info pull-right"
                  onClick={() => {
                    if (props.currentNote != "") {
                      saveNote(props.currentCallUUID, props.currentNote);
                    }

                    if (nextFollowupDateTime != "") {
                      saveFollowupInfo();
                    }
                    if (leadStatus != "") {
                      updateLeadInfo();
                    }

                    if (
                      selectedLeadStage != "" ||
                      selectedLeadDisposition != "" ||
                      expectedCloseDate ||
                      expectedDealSize
                    ) {
                      saveLeadStageRelation();
                    }
                    if (programName !== "" && startDate !== "") {
                      handleSelect();
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
          <div className="ptbnull">
            <h4 className="box-title titlefix pb5">Call History</h4>
            <div className="box-tools pull-right"></div>
          </div>

          <div className="pt20">
            <div className="tab-pane active" id="timeline">
              {/* <AggridFollowUpModal callHistory={callHistory} /> */}

              {/* <div style={containerStyle}>
                                <div style={gridStyle} className="ag-theme-alpine">

                                    <AgGridReact
                                        rowData={callHistory}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        onGridReady={onGridReady}

                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: 'filters',
                                                    labelDefault: 'Filters',
                                                    labelKey: 'filters',
                                                    iconKey: 'filters',
                                                    toolPanel: 'agColumnsToolPanel',
                                                    toolPanelParams: {
                                                        suppressRowGroups: true,
                                                        suppressValues: true,
                                                        suppressPivots: true,
                                                        suppressPivotMode: true,
                                                        suppressSideButtons: true,
                                                        suppressColumnFilter: true,
                                                        suppressColumnSelectAll: true,
                                                        suppressColumnExpandAll: true,
                                                    },
                                                },
                                            ],
                                            position: 'bottom',
                                            // defaultToolPanel:'filters',
                                            defaultToolPanel: 'columns',
                                        }}

                                    ></AgGridReact>
                                </div>
                            </div> */}

              <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                  <Paper
                    sx={{
                      height: 700,
                      width: "100%",
                    }}
                  >
                    <DataGridPro
                      loading={isLoading}
                      rowCount={totalRecords}
                      rows={callHistory}
                      columns={columnDefs}
                      // slots={{ toolbar: parseInt(loggedInUser.id) === 1 || parseInt(loggedInUser.id) === 15 || parseInt(loggedInUser.id) === 28 || parseInt(loggedInUser.id) === 30 ? CustomToolbar : ""}}
                      slots={{ toolbar: CustomToolbar }}
                      paginationMode="server"
                      pagination
                      page={paginationModel.page}
                      pageSize={paginationModel.pageSize}
                      paginationModel={paginationModel}
                      onPaginationModelChange={handleChangePaginationModel}
                      sx={{
                        ".MuiDataGrid-row.Mui-even": {
                          backgroundColor: "#DCD6D0",
                        },
                        ".MuiDataGrid-row.Mui-odd": {
                          backgroundColor: "#fff",
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold !important",
                          overflow: "visible !important",
                        },
                      }}
                    />
                  </Paper>
                </div>
              </div>

              {/* <table className="table table-bordered " >
                                        <tbody className="thead-light" >
                                            <tr style={{ 'width': '50px' }}  >
                                                <th scope="col">From</th>
                                                <th scope="col" >Called At</th>
                                                <th scope="col">Call Duration (seconds)</th>
                                                
                                                <th scope="col">Note</th>
                                                <th scope="col">Recording</th>
                                                <th scope="col">Call Status</th>
                                            </tr>
                                            {
                                                callHistory?.map(callInfo => {
                                                    return (
                                                        <tr>
                                                            <td>{callInfo?.User?.Username}</td>
                                                            <td>{callInfo?.createdAt && (new Date(callInfo?.createdAt).toDateString() + ', ' + new Date(callInfo?.createdAt).toLocaleTimeString())}</td>
                                                            <td>{callInfo?.callDuration}</td>
                                                          
                                                            <td><p>{callInfo?.notes}</p> <GrNotes onClick={(e) => {
                                                                
                                                             
                                                                setNoteUUID(callInfo?.callUUID)
                                                                setOpenNotePopup(true)
                                                                setEditNote(callInfo?.notes)
                                                            }} fontSize={10} /></td>
                                                            <td>
                                                                <audio controls>
                                                                    <source src={callInfo?.recordingURL} type="audio/mp3" />
                                                                </audio>
                                                            </td>
                                                            <td>{callInfo?.callStatus}</td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table> */}
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-md-4 col-sm-4 col-eq">
        <div className="taskside">
          <hr className="taskseparator" />

          <div className="taskInfo">
            <div>
              <i className="far fa-calendar-plus"></i> Lead Created Date:{" "}
              {leadInfo?.createdAt}
            </div>
            <div>
              <i className="far fa-calendar-plus"></i> Last Follow Up Date:{" "}
              {lastFollowupInfo &&
                new Date(lastFollowupInfo).toDateString() +
                  ", " +
                  new Date(lastFollowupInfo).toLocaleTimeString()}
            </div>
            <div>
              <i className="far fa-calendar-plus"></i> Next Follow Up Date:{" "}
              {nextFollowupInfo &&
                new Date(nextFollowupInfo).toDateString() +
                  ", " +
                  new Date(nextFollowupInfo).toLocaleTimeString()}
            </div>
            {/* <div><i></i> Status: {leadStatusList.find(e => e?.id == updatedleadStatus)?.Status}</div> */}
            <div>
              <i></i> Stage:{" "}
              {leadStageList.find((e) => e?.id == updatedleadStage)?.leadStage}
            </div>
          </div>

          <div className="taskInfo">
            <div>
              <span>Lead Name: </span>
              {leadInfo?.name}
            </div>
            <div>
              <span>Lead Contact: </span>
              {leadInfo?.contact_number}
            </div>
            <div>
              <span>Lead Email: </span>
              {leadInfo?.email_id}
            </div>
            {/* <div><span>Source: </span>{leadInfo?.SourceID?.Source}</div> */}
            <div>
              <span>Account Name: </span>
              {leadInfo?.SourceID?.Source}
            </div>
          </div>

          <div className="taskInfo">
            <div>
              <i className="far fa-calendar-plus"></i> Created Date:{" "}
              {leadInfo.createdAt?.slice(0, 10)}
            </div>
            {/* <div><i className="far fa-calendar-plus"></i> Last Follow Up Date: {lastFollowupInfo && (new Date(lastFollowupInfo).toDateString() + ', ' + new Date(lastFollowupInfo).toLocaleTimeString())}</div> */}
            <div>
              <i className="far fa-calendar-plus"></i> Next Follow Up Date:{" "}
              {nextFollowupInfo &&
                new Date(nextFollowupInfo).toDateString() +
                  ", " +
                  new Date(nextFollowupInfo).toLocaleTimeString()}
            </div>
            {/* <div><i></i> Status: {leadStatusList.find(e => e?.id == updatedleadStatus)?.Status}</div> */}

            <div>
              <i></i> Stage:{" "}
              {leadStageList.find((e) => e?.id == updatedleadStage)?.leadStage}
            </div>
            <div>
              Call Disposition :{" "}
              {
                leadStageList.find((e) => e?.id === updatedleadStage)
                  ?.vmax_stage_detail?.display_value
              }
            </div>
            {/* <div>Call Subdisposition: {leadSubDispositionList.find(e=>e?.id===selectedLeadSubDisposition)?.}</div> */}
          </div>
        </div>
        {isCallModalShow && (
          <Modal show={isCallModalShow}>
            <Modal.Header closeButton>
              <Modal.Title>Call Option</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: "flex" }}>
                <label>Lead Mobile Number: </label>{" "}
                <p>{callObjectInfo.current.leadPhoneNumber}</p>
                <button
                  className="btn btn-default btn-xs no-focus"
                  title="Call"
                  onClick={() =>
                    callAction(callObjectInfo.current.leadPhoneNumber)
                  }
                >
                  <i
                    className="fas fa-phone-alt text-green"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>

              <div>
                <label>Alternative Number: </label>
                <label /* className='col-md-8 floatclass' */>
                  <PhoneInput
                    value={callObjectInfo.current.alternativeNumber}
                    onChange={(e) => {
                      // setCallObjectInfo(prevState => ({ ...prevState, alternativeNumber: "+" + e }))
                      callObjectInfo.current.alternativeNumber = "+" + e;
                    }}
                    country={"in"}
                    enableSearch={true}
                    inputStyle={{
                      paddingLeft: "15%",
                      backgroundColor: "#fce4e4",
                      borderColor: "#ff01416a",
                    }}
                  />
                </label>
                <button
                  className="btn btn-default btn-xs no-focus"
                  title="Call"
                  onClick={() => {
                    // setCallObjectInfo(prevState => ({...prevState, leadPhoneNumber: callObjectInfo.alternativeNumber }))
                    callAction(callObjectInfo.current.alternativeNumber);
                  }}
                >
                  <i
                    className="fas fa-phone-alt text-green"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsCallModalShow(false);
                }}
              >
                Close
              </Button>
              {/* <Button variant="primary" onClick={()=>handleClose()}>
                    Save Changes
                  </Button> */}
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* {openNotePopUp &&
                            <Modal show={openNotePopUp} onHide={() => setOpenNotePopup(false)} style={{ 'width': '50%', 'justifyContent': 'center', 'position': 'relative', 'left': '50%', 'transform': 'translateY(-1000px)' }}>   Note
                                <Modal.Body >

                                  
                                    <textarea style={{ width: '100%' }} onChange={(e) => { setEditNote(e.target.value) }} defaultValue={editNote} />

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => {
                                        saveNote(noteUUID, editNote)
                                        setOpenNotePopup(false)
                                    }} >save</Button>
                                    <Button variant="secondary" onClick={() => setOpenNotePopup(false)}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                        } */}
    </div>
  );
}

export default LeadViewCallHistory;
