import DataTable from "../../../components/DataTable/DataTable";

const ClientTable = ({
  apiRef,
  rows,
  count,
  columns,
  handlePagination,
  onFilterChange,
  slots,
  columnVisibilityModel,
  visibileColumns,
  handleChangeRowSelection,
  onColumnVisibilityModelChange,
  pinList,
}) => {
  return (
    <DataTable
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      count={count}
      checkbox={true}
      handlePagination={handlePagination}
      onFilterChange={onFilterChange}
      slots={slots}
      columnVisibilityModel={columnVisibilityModel}
      handleChangeRowSelection={handleChangeRowSelection}
      visibileColumns={visibileColumns}
      pinList={pinList}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
    />
  );
};

export default ClientTable;
