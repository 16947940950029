import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Popover,
  Stack,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const FeatureList = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowId, setRowId] = useState("");
  const [programId, setProgramId] = useState("");

  const [cellModesModel, setCellModesModel] = React.useState({});
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    name: true,
    createdAt: true,
    created_by: true,
    updatedAt: true,
    updated_by: true,
  });

  const open = Boolean(anchorEl);

  const [popover, setPopover] = React.useState(null);
  const openPopover = Boolean(popover);
  const history = useHistory();

  const handleClickPopover = (event) => {
    handleClose();
    handleDelete();
    setPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopover(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Feature Name",
      width: 150,
      editable: true,
      valueGetter: (params) => {
        return !!params.row.name ? params.row.name : "-";
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      valueGetter: (params) => {
        return !!params.row.createdAt.split("T")[0]
          ? params.row.createdAt.split("T")[0]
          : "-";
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 150,
      valueGetter: (params) => {
        return !!params.row.created_by ? params.row.created_by : "-";
      },
    },
    {
      field: "updatedAt",
      headerName: "Last updated At",
      width: 200,
      valueGetter: (params) => {
        return !!params.row.updatedAt.split("T")[0]
          ? params.row.updatedAt.split("T")[0]
          : "-";
      },
    },
    {
      field: "updated_by",
      headerName: "Last updated By",
      width: 150,
      valueGetter: (params) => {
        return !!params.row.updated_by ? params.row.updated_by : "-";
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="delete"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => handleClick(e, params)}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  const handleCellClick = React.useCallback((params) => {
    setRowId(params.id);
    setProgramId(params.row.prod_id);
    if (params.field !== "Actions") {
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    }
  }, []);

  const handleClick = (event, row) => {
    setRowId(row.id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    axiosFetch({
      method: "put",
      url: `/updateProductLines/`,
      requestConfig: {
        id: updatedRow.id,
        name: updatedRow.name,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.info(res.data);
          // toast.success("Program Updated Successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return updatedRow;
  };

  const handleChangeRowSelection = (id) => {};

  const handleDelete = () => {
    handleClosePopover();
    axiosFetch({
      url: `/deleteProductLines/${rowId}`,
      method: "delete",
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Feature deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.getFeatureList(programId);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Typography sx={{ margin: "31px", fontSize: "25px", fontWeight: "600" }}>
        Feature List
      </Typography>

      <DataGrid
        editMode="row"
        columnVisibilityModel={columnVisibilityModel}
        rows={props.featureList}
        columns={columns}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pagination
        // autoPageSize
        pageSizeOptions={[10]}
        onRowSelectionModelChange={(id) => {
          handleChangeRowSelection(id);
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }
        sx={{
          ".MuiDataGrid-row.Mui-even": {
            backgroundColor: "#DCD6D0",
          },
          ".MuiDataGrid-row.Mui-odd": {
            backgroundColor: "#fff",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            overflow: "visible !important",
          },
        }}
      />

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ transform: "translateX(-300px)" }}
      >
        <MenuItem onClick={(e) => handleClickPopover(e)}>
          <DeleteIcon />
          &nbsp;&nbsp; Delete
        </MenuItem>
        {/* <MenuItem>
          <VisibilityIcon />
          &nbsp;&nbsp; Add Feature
        </MenuItem> */}
      </Menu>

      <Popover
        open={openPopover}
        popover={popover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Are you sure, you want to delete the Feature?
        </Typography>
        <Button onClick={() => handleDelete()}>Yes</Button>
        <Button onClick={() => handleClosePopover()}>No</Button>
      </Popover>
    </div>
  );
};

export default FeatureList;
