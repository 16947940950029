import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import DataViewer from "../../../components/DataViewer/DataViewer";
import ControlledTextField from "../../../components/ControlledTextField";
import { FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../../components/ControlledAutocomplete";
import ControlledRadioGroup from "../../../components/ControlledRadioGroup";
import ControlledTimepicker from "../../../components/ControlledTimepicker";
import ControllerDatetimepicker from "../../../components/ControlledDatetimepicker";
import FormRenderer from "../../../components/FormRenderer/FormRenderer";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

const StressManagement = ({ displaydata, update }) => {
  const [isedit, setIsedit] = useState(false);
  const [masters, setMasters] = useState({});
  const [infoview, setInfoview] = useState({});

  const defaultValues = {
    createDate: new Date(),
  };
  const methods = useForm({
    defaultValues,
    profession_details: "",
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    register,
    formState: { errors, isValid },
  } = methods;

  const handleClick = (event) => {
    setIsedit(!isedit);
  };

  const stressLevel = Array.from({ length: 3 }, (e, i) => i + 1);
  const [selectedStressLevel, setSelectedStressLevel] = useState("");

  useEffect(() => {
    axiosFetch({
      method: "get",
      url: `/getStressmaster`,
    })
      .then((response) => {
        setMasters({
          ...masters,
          emotionalhealth: [...response.data.body.emotional_health_data],
          sleepHours: [...response.data.body.sleep_hours],
          sleepType: [...response.data.body.sleeptype_master],
          stressReason: [...response.data.body.stress_reason],
          socialHabits: [...response.data.body.social_habits],
          energyLevel: [...response.data.body.energy_level],
        });
      })
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    if (displaydata) {
      let stressInfo = displaydata?.stressAssessment;

      setSelectedStressLevel(stressInfo?.stress_level);

      setValue("emotional_health", stressInfo?.emotional_health);
      setValue("sleep_hours", stressInfo?.sleep_hours);
      setValue("sleep_type", stressInfo?.sleep_type);
      setValue("stress_level", stressInfo?.stress_level);
      setValue("stress_reason", stressInfo?.stress_reason);
      setValue("social_habits", stressInfo?.social_habits);
      setValue("energy_level", stressInfo?.energy_level);

      setInfoview({
        "Emotional Health": stressInfo?.emotional_health,
        "Sleep Hours": stressInfo?.sleep_hours,
        "Sleep Type": stressInfo?.sleep_type,
        "Stress Level": stressInfo?.stress_level,
        "Stress Reason": stressInfo?.stress_reason,
        "Social Habits": stressInfo?.social_habits,
        "Energy Level": stressInfo?.energy_level,
      });

      // setValue("gender",basicinfo?.gender)
      // setValue("dob",basicinfo?.dob)
    }
  }, [displaydata]);
  const handleCancel = (event) => {
    setIsedit(false);
  };
  const handledietpreference = async (data) => {
    update({
      ...data,
      formtype: 5,
      stress_level: selectedStressLevel,
    });
    //alert(JSON.stringify(data))
  };
  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit((data) => {
          handledietpreference(data);
          setIsedit(false);
        })}
      >
        {isedit ? (
          <>
            {masters.emotionalhealth && (
              <Grid container xs={12} spacing={2}>
                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Emotional Health"}
                    name={"emotional_health"}
                    multiple={true}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={
                      displaydata?.stressAssessment?.emotional_health === null
                        ? []
                        : displaydata?.stressAssessment?.emotional_health
                    }
                    options={
                      masters?.emotionalhealth ? masters?.emotionalhealth : []
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Sleep Hours"}
                    name={"sleep_hours"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={displaydata?.stressAssessment?.sleep_hours}
                    options={masters?.sleepHours ? masters?.sleepHours : []}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Sleep Type"}
                    name={"sleep_type"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={displaydata?.stressAssessment?.sleep_type}
                    options={masters?.sleepType ? masters?.sleepType : []}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Box>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Stress Level on a scale of 1-3
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => setSelectedStressLevel(e.target.value)}
                        value={selectedStressLevel}
                        label="Stress Level on a scale of 1-3"
                      >
                        {stressLevel.map((value, index) => (
                          <MenuItem value={value} key={index}>
                            <div className="filter-date">
                              <span>{value}</span>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Stress Reason"}
                    name={"stress_reason"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={displaydata?.stressAssessment?.stress_reason}
                    options={masters?.stressReason ? masters?.stressReason : []}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Social Habits"}
                    name={"social_habits"}
                    control={control}
                    multiple={true}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={
                      displaydata?.stressAssessment?.social_habits == null
                        ? []
                        : displaydata?.stressAssessment?.social_habits
                    }
                    options={masters?.socialHabits ? masters?.socialHabits : []}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Energy Level"}
                    name={"energy_level"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={displaydata?.stressAssessment?.energy_level}
                    options={masters?.energyLevel ? masters?.energyLevel : []}
                  />
                </Grid>
              </Grid>
            )}{" "}
          </>
        ) : (
          <Grid container xs={12} spacing={2}>
            <DataViewer data={infoview} />
            {/* <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Diet Preference"}
            </Typography>
            <Typography variant="h6">
              {displaydata?.dietPreference ? displaydata?.dietPreference  : "--"}
            </Typography>
            </Stack>
             
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Any Allergies"}
            </Typography>
            <Typography variant="h6">
              {displaydata?.dietAnyAllergies ? displaydata?.dietAnyAllergies  : "--"}
            </Typography>
            </Stack>

              
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Please Specify Allergies"}
            </Typography>
            <Typography variant="h6">
              {displaydata?.otherAllergies ? displaydata?.otherAllergies  : "--"}
            </Typography>
            </Stack>
              
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Diet Preferred Cuisine"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.dietPreferredCuisine ? displaydata?.dietPreferredCuisine  : "--"}
              </Typography>
              </Stack>

            
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Food Dislikes"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.food_dislike ? displaydata?.food_dislike  : "--"}
              </Typography>
              </Stack>
            
            </Grid>
            <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Number of Meals"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.number_of_meals ? displaydata?.number_of_meals  : "--"}
              </Typography>
              </Stack>
            
              
            </Grid> */}
          </Grid>
        )}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          {isedit && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="outlined" onClick={handleCancel}>
                {" "}
                Cancel{" "}
              </Button>
              <Button variant="contained" type="submit">
                {" "}
                Save{" "}
              </Button>
            </Stack>
          )}
        </Grid>
      </form>
      {!isedit && (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button variant="contained" onClick={handleClick}>
              {" "}
              Edit{" "}
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default StressManagement;

//btn-disabled
