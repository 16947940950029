import React from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";

const Modal = ({ isOpen, onClose, children, style }) => {
  if (!isOpen) return null;

  const paymentModal = document.getElementById("open__modal-root");

  const handleModalClick = (event) => {
    // Stop event propagation to prevent onClose from being triggered
    event.stopPropagation();
  };

  return (
    <>
      {ReactDOM.createPortal(
        <div>
          <div
            className={`${styles["modal-overlay"]} `}
            onClick={onClose}
          ></div>
          <div className={`${styles["modal"]}`} style={style}>
            {children}
          </div>
        </div>,
        paymentModal
      )}
    </>
  );
};

export default Modal;
