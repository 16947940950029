import React, { Component } from 'react'
import * as $ from 'jquery'
import emptyTableImg from '../img/addnewitem.svg'
import { Link, Route } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Popupmodel from '../Pages/Leads/Popupmodel';
import './DataTable.css'
import { ModalBody } from 'react-bootstrap';
import SelectBox from './SelectBox';
import ManageUsers from '../Pages/Settings/ManageUsers';
import ListClients from '../Pages/Clients/ListClients';
import { ATLAS_URI } from '../Constants';
import GridTable from './GridTable';
// import GridTable from './GridTable';


class DataTable extends Component {

    state = {
        currentPage: 1,
        maxPages: 1,
        maxRecordsPerPage: 10,
        tableBody: [],
        tableBodyActive: [],
        AllBoxes: [],
        // editMode: false,
        show:false,
        editMode: true,
        userList:[],
        pageAccessible:[],
        featureAccessible: [],
        assignedValue:''
    }


    constructor() {
        super();
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleMaxRecordChange = this.handleMaxRecordChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.selectAllBoxes = this.selectAllBoxes.bind(this);

        this.setEditable = this.setEditable.bind(this)
        this.deleteAll = this.deleteAll.bind(this)
        this.sortID = this.sortID.bind(this)
    }

// const {operator}=contextState.state
    changeHandler(e) {
        let newValue;

        if (e.target.type === "checkbox") {
            newValue = [...this.state.AllBoxes]
            if (e.target.checked) {
                newValue.push(e.target.value)
            } else {
                newValue = newValue.filter(data => data !== e.target.value)
            }
        }
        this.setState({
            AllBoxes: newValue
        })
     
    // let newValue1=e.target.value


    
    
    // this.props.handleAssignedTo(e.target.value)
}



    selectAllBoxes(e) {
        const mainBox = $("#MainBox").is(':checked')
        let boxValue = []
        if (mainBox) {
            this.state.tableBodyActive.slice(this.getBodyStartIndex(), this.getBodyEndIndex()).map(x => { boxValue.push(x.id.toString()) })
            this.setState({
                AllBoxes: boxValue
            })
        } else {
            this.setState({
                AllBoxes: []
            })
        }
    }

    componentDidMount() {
        this.setState({
            maxPages: parseInt(this.props.tableBody?.length / this.state.maxRecordsPerPage) + 1,
            tableBody: this.props.tableBody,
            tableBodyActive: this.props.tableBody
        });
    
        this.state.maxPages > this.state.currentPage ? $("#dtNextBtn").removeClass("disabled") : $("#dtNextBtn").addClass("disabled")

    }

    shouldComponentUpdate(prevProps, prevState) {
        return (prevProps.tableBody !== this.state.tableBody ||
            prevState.tableBodyActive !== this.state.tableBodyActive) ||
            prevState.currentPage !== this.state.currentPage ||
            prevState.maxRecordsPerPage !== this.state.maxRecordsPerPage ||
            prevState.AllBoxes !== this.state.AllBoxes ||
            prevState.editMode !== this.state.editMode ||
            prevProps.searchField !== this.state.searchField
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (prevProps.tableBody !== this.props.tableBody) {
            this.setState({
                maxPages: parseInt(this.props.tableBody.length / (this.state.maxRecordsPerPage + 1)) + 1,
                tableBody: this.props.tableBody,
                tableBodyActive: this.props.tableBody
            });

            setTimeout(() => {
                $("#dtSearch").val("");
                this.state.maxPages > this.state.currentPage ? $("#dtNextBtn").removeClass("disabled") : $("#dtNextBtn").addClass("disabled")
            }, 50)
        }

        if (prevState.tableBodyActive !== this.state.tableBodyActive || prevState.maxRecordsPerPage !== this.state.maxRecordsPerPage) {
            const temp = parseInt(this.state.tableBodyActive?.length / this.state.maxRecordsPerPage);
            this.setState({
                maxPages: this.state.tableBodyActive?.length % this.state.maxRecordsPerPage === 0 ? temp : temp + 1,
                tableBodyActive: this.state.tableBodyActive,
                currentPage: 1
            });
            $("#dtPrevBtn").addClass("disabled")

            setTimeout(() => {
                this.state.maxPages > this.state.currentPage ? $("#dtNextBtn").removeClass("disabled") : $("#dtNextBtn").addClass("disabled")
            }, 50)
        }

    }

    getPageRecord() {
        if (typeof this.props.tableBody === 'undefined' || this.state.tableBodyActive.length === 0) {
            return "0 to 0 of 0";

        } else {
            const startPage = (this.state.currentPage - 1) * this.state.maxRecordsPerPage + 1;
            const totalRecords = this.state.tableBodyActive.length;
            const endPage = this.state.tableBodyActive.length < this.state.currentPage * this.state.maxRecordsPerPage ? this.state.tableBodyActive.length : this.state.currentPage * this.state.maxRecordsPerPage

            return startPage + " to " + endPage + " of " + totalRecords;

        }

    }

    getBodyStartIndex() {
        return (this.state.currentPage - 1) * this.state.maxRecordsPerPage;
    }

    getBodyEndIndex() {
        return this.state.tableBodyActive.length < this.state.currentPage * this.state.maxRecordsPerPage ? this.state.tableBodyActive.length : this.state.currentPage * this.state.maxRecordsPerPage
    }

    handleChangePage(e) {

        let currPage = e.target.name === "next" ? this.state.currentPage + 1 : this.state.currentPage - 1;
        currPage < this.state.maxPages ? $("#dtNextBtn").removeClass("disabled") : $("#dtNextBtn").addClass("disabled")
        currPage > 1 ? $("#dtPrevBtn").removeClass("disabled") : $("#dtPrevBtn").addClass("disabled")

        this.setState({ currentPage: currPage })
    }

    onSearchChange(e) {
        this.setState({
            tableBodyActive: this.state.tableBody.filter(data => data[this.props.searchField] !== null && e.target.value.toLowerCase() === data[this.props.searchField].substr(0, e.target.value.length).toLowerCase())

        })
    }

    handleMaxRecordChange(e) {
        this.setState({ maxRecordsPerPage: parseInt(e.target.value) })
      
    }

    setEditable(e) {
        let edit = this.state.editMode

        if (edit) {
            $('#editBtn').text("Edit")
            this.props.onEdit && this.props.onEdit(this.state.AllBoxes)
            this.setState({ editMode: !edit })
        } else {
            $('#editBtn').text("Update")
            this.setState({ editMode: !edit, AllBoxes: [] })

        }
    }
    deleteAll(e) {
        const allLeads = { leads: this.state.AllBoxes }
        const configToken = {
            headers: {
                Authorization: `Bearer ${JSON.parse(window.localStorage.getItem('operator')).token}`,
            },
        }
        axios.delete(this.props.DeleteAPI + this.state.AllBoxes, configToken)
            .then(() => {
                window.location.reload();
            })
            .catch(err => console.error(err))
    }
    sortID(e) {
        e.preventDefault()
        let x = [...this.state.tableBody].reverse()
        $("#sort").toggleClass("fa-angle-down fa-angle-up");
        this.setState({
            tableBody: x,
            tableBodyActive: x
        })
    }

//     handleClose ()
//     { 
//     this.setState(prevState=>({
//    show:prevState.show
//     }))

//     }


    handleShow (indd)
    {
        
            this.setState(prevState => ({
                show: !prevState.show
              }));

             
          
        
    }

   
    

    render() {
       
        return (
            <>
           
            <div className="table-responsive mailbox-messages ">
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer ">

                    <div className="dataTables_maxRecords form-group ">
                        <label>
                            <select className="form-control" value={String(this.state.maxRecordsPerPage)} onChange={this.handleMaxRecordChange} >
                                <option value="5">5</option>
                                <option value="10" defaultValue={"10"}>10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select> Records per page
                        </label>
                     &nbsp;&nbsp;&nbsp;

                     <div className='submit'> 

                      {this.props.show && <Button onClick={()=>this.handleAssignedTo()}>Submit</Button>}
                     </div>
                        {/* {
                                    <SelectBox
                                        label="AssignedTo"
                                        name="AssignedTo"
                                        options={this.state.userList}
                                        attributeShown="AssignedTo"
                                        changeHandler={changeHandler}
                                        // value={this.state.newTableRow.AssignedTo}
                                        resetValue={() => this.setState(prevState => ({ newTableRow: { ...prevState.newTableRow, AssignedTo: "Select" } }))}
                                    />
                                } */}
                                
                               
                                    {/* {this.props.tableHeader.map((bodyField)=>{
                                    return  (bodyField.replace(/\s/g, '')==='Name'&&
                                     <select className='form-control'>
                                        <option>{ this.props.userName.map((bodyData)=>{
                                           return (
                                            <ul>

                                              <li>{  bodyData.Name}</li>
                                            </ul>
                                           )
                                        })}</option>
                                     </select>) })} */}
                                     {/* &nbsp; &nbsp; &nbsp; &nbsp; */}
                              
                    {/* <label>

                                    <select id='drop-down' name='AssignedTo'>
                                    <option selected disabled>Assigned To</option>
                                        {this.props.userName.map((user)=>{
                                            return (
                                                <>
                                                <option>{user.Name}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                    </label> */}
                    </div>
                   

                    
                    
                                
                     
                    

                    <div className="dataTables_filter">
                        <input type="search" placeholder={`Search by ${this.props.searchField}`} id='dtSearch' name="searchValue" onChange={this.onSearchChange} />
                        {this.props.isEditable && <button onClick={this.setEditable} id="editBtn" className='btn btn-primary ml-5'>Edit</button>}
                        {this.props.isEditable && this.state.editMode && <button onClick={this.deleteAll} className='btn btn-primary ml-5'>Delete All</button>}
                    </div>

                    <table className="table table-bordered table-hover dataTable no-footer  " cellSpacing="1" cellPadding='1' width="100%" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" style={{ "width": "100%"}}>
                        <thead >
                            <tr>
                                {this.props.isEditable && this.state.editMode &&
                                    <th key={"EditableBox"} tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1">
                                        <input type='checkbox' id='MainBox' name='MainBox' value={"MainBox"} onChange={this.selectAllBoxes} />
                                    </th>
                                }
                                {/* <th><input type='checkbox' onChange={this.selectAllBoxes}/></th> */}
                                

                                {this.props.tableHeader.map(tableHeader =>
                                    <th key={tableHeader} tabIndex="0"  aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" >
                                        {tableHeader === "id" || tableHeader === "_id" ? <a onClick={this.sortID}>ID <i className="fas fa-angle-down" id='sort'></i></a> : tableHeader}
                                    </th>
                                )}

                                {typeof this.props.noActions === 'undefined' &&
                                    <th className="text-right" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Action: activate to sort column ascending">
                                        Action
                                    </th>
                                }

                            </tr>
                        </thead>

                        <tbody>
                            {typeof this.props.tableBody === 'undefined' || this.props.tableBody.length === 0 ?
                                <tr className='odd'>
                                    <td valign="top" colSpan="12" className="dataTables_empty">
                                        <div align="center">
                                            No data available in table
                                            <br /><br />
                                            <img alt='Empty Table' src={emptyTableImg} width="150" />
                                            <br /><br />
                                            <span className="text-success bolds">
                                                <i className="fa fa-arrow-left"></i>
                                                &nbsp;Add new record or search with different criteria.
                                            </span>
                                            <div></div>
                                        </div>
                                    </td>
                                </tr>
                                :
                                this.state.tableBodyActive
                                    .slice(this.getBodyStartIndex(), this.getBodyEndIndex()).map((bodyData,indd) =>
                                        <tr className='dtFieldRows ' id={bodyData?.id} key={bodyData?.id}>
                                          
                                            {this.props.isEditable && this.state.editMode &&
                                                <td className="bodyDataFields" >
                                                    <input
                                                        type='checkbox'
                                                        name='AllBoxes'
                                                        onChange={this.changeHandler}
                                                        // value={bodyData.id}
                                                        value={bodyData?.id?.toString()}

                                                        checked={this.state.AllBoxes.includes(bodyData?.id?.toString())}
                                                    />
                                                   
                                                </td>
                                            }

                                            {this.props.tableHeader.map((bodyField,ind2) =>
                                            
                                                {
                                                  
                                                    
                                                    return (<td className="bodyDataFields " key={bodyField}>
                                                    {/* bodyField.replace(/\s/g, '')==='ClientName'?<span className='sub' onClick={()=>this.handleShow(indd)}>{bodyData[bodyField.replace(/\s/g, '')]}</span>: */}
                                          
                                            {
                                            typeof bodyData[bodyField.replace(/\s/g, '')] !== 'undefined' &&
                                                   
                
                                                (typeof bodyData[bodyField.replace(/\s/g, '')] !== 'object'  ?
                                                    <>
                                                    {/* {bodyField.replace(/\s/g, '')=='id'&&<input type='checkbox' value={bodyData[bodyField.replace(/\s/g, '')]}/> } &nbsp; */}
                                                        {/* <td>{<input type='checkbox'/>}</td> */}
                                                      
                                                        
                                                        {/* {Object.keys(bodyData).includes('firstName')&&bodyField.replace(/\s/g, '')==='FirstName'&bodyData[bodyField.replace(/\s/g, '')]} */}
                                                         { bodyData[bodyField.replace(/\s/g, '')]}
                                                    
                                                    </>
                                                  
                                                    :
                                                    Array.isArray(bodyData[bodyField.replace(/\s/g, '')]) &&
                                                    bodyData[bodyField.replace(/\s/g, '')].map(data => <div key={data} className="dtMultiData">{data}</div>)
                                                )
                                            }
                                           
                                        </td>)
                                                }
                                                
                                               
                                                
                                            )}

                                            {typeof this.props.noActions === 'undefined' &&
                                            
                                                <><td className="bodyDataActions">

                                                    {(typeof this.props.customAction === 'undefined') ?
                                                        <React.Fragment>
                                                            <button className="btn btn-default btn-xs no-focus" title="Edit" onClick={this.props.editTableRow}>
                                                                <i className="fas fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                            {bodyData.id!==1?<button className="btn btn-default btn-xs no-focus" title="Delete" onClick={this.props.deleteFromTable}>
                                                                <i className="fas fa-times text-red" aria-hidden="true"></i>
                                                            </button>:''}
                                                        </React.Fragment>
                                                        :

                                                        <React.Fragment>
                                                            {this.props.customAction.map(action => (action.title === "Attempt" || action.title === "Update" || action.title==='View' ) ?
                                                                <Link key={action.title} className="btn btn-default btn-xs no-focus" onClick={action.onClickEvent} to={action.redirectTo}><i className={`${action.icon}`} aria-hidden="true"></i></Link>
                                                                :
                                                                ((!action.fieldCondition || bodyData[Object.keys(action.fieldCondition)[0]] === Object.values(action.fieldCondition)[0]) &&
                                                                    <button key={action.title} className="btn btn-default btn-xs no-focus" title={action.title} onClick={action.onClickEvent}><i className={`${action.icon}`} aria-hidden="true"></i></button>
                                                                )
                                                            )}
                                                        </React.Fragment>}

                                                </td>
                                                {/*  */}
                                                    </>
                                               
                                            }

                                        </tr>
                                    )

                            }

                        </tbody>

                    </table>
                    {/* <button onClick={this.props.showColumns}>Hide Columns</button> */}


                    <section className="dtBottomSection row">

                        <div className="dtPageDetails col-md-6">{`Record: ${this.getPageRecord()}`}</div>

                        <div className="dtPaginationArea col-md-6">

                            <button className="dtPageBtn disabled" id='dtPrevBtn' name="previous" onClick={this.handleChangePage}>
                                <i className="fa fa-angle-left"></i>
                            </button>

                            <div id="dtCurrentPage">{this.state.currentPage}</div>

                            <button className="dtPageBtn disabled" id='dtNextBtn' name="next" onClick={this.handleChangePage}>
                                <i className="fa fa-angle-right"></i>
                            </button>

                    
                        </div>
                    </section>

                </div>


            </div>
            {/* <Popupmodel handleShow1={this.state.show}/> */}
           
 </>
        )
    }


}

export default DataTable