import { Typography, Grid, Button, Box } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Card from "../../../components/Card/Card";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

import TextBox from "../../../components/TextFields/TextBox";
import MultiSelectBox from "../../../components/TextFields/MultiSelectBox";
import DatePickerBox from "../../../components/TextFields/DatePickerBox";
import SelectBox from "../../../components/TextFields/SelectBox";
import RadioButtonGroup from "../../../components/TextFields/RadioButtonGroup";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const options = [
  { label: "Dietitian", value: "dietitian" },
  { label: "Trainer", value: "trainer" },
];

const initialState = {
  assignedTo: "",
  ruleName: "",
  description: "",
  sequenceNo: "",
  startDate: "",
  endDate: "",
  groupOne: [],
  groupTwo: [],
  groupThree: [],

  conditionList: [
    {
      selectCondition: "",
      multiSelectType: [],
    },
  ],
};

const AddRule = () => {
  const history = useHistory();
  const { state } = useLocation();
  const currentRow = state && state.currentRow;
  const isEdit = state && state.isEdit;
  const isView = state && state.isView;

  const [isLoading, setIsLoading] = useState(false);

  const [dropDownValues, setDropDownValues] = useState({});
  const [groupList, setGroupList] = useState([]);

  const form = useForm({
    defaultValues: isEdit || isView ? currentRow : initialState,
  });

  const { handleSubmit, control, watch, reset } = form;
  const currentSelectedList = watch("conditionList");
  const currentAssignedTo = watch("assignedTo");
  const selectedGroupOne = watch("groupOne");
  const selectedGroupTwo = watch("groupTwo");
  const selectedGroupThree = watch("groupThree");

  const {
    fields: fieldCondition,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({ name: "conditionList", control });

  const handleAddCondition = () => {
    if (fieldCondition.length < dropDownValues.conditionList.length) {
      appendCondition({
        selectCondition: "",
        multiSelectType: [],
        selectType: "",
      });
    }
  };

  const handleRemoveAddCondition = (index) => {
    if (isView) return;
    removeCondition(index);
  };

  const getAvailableConditionOptions = (index) => {
    const selectedConditions = currentSelectedList
      .map((item) => item?.selectCondition)
      .filter((_, i) => i !== index);

    return dropDownValues.conditionList.filter(
      (option) => !selectedConditions.includes(option.id)
    );
  };

  const getSelectTypeOptions = (selectedList) => {
    let selectedTypeOption;
    let isMultiSelect = true;

    switch (selectedList) {
      case "shiftTiming": {
        selectedTypeOption = dropDownValues.shiftTimingList;
        break;
      }
      case "program": {
        selectedTypeOption = dropDownValues.metals;
        isMultiSelect = false;
        break;
      }
      case "language": {
        selectedTypeOption = dropDownValues.languagesList;
        break;
      }
      case "medicalCondition": {
        selectedTypeOption = dropDownValues.medicalConditions;
        break;
      }
      case "preferedCuisine": {
        selectedTypeOption = dropDownValues.preferedCuisine;
        break;
      }
    }

    return [selectedTypeOption, isMultiSelect];
  };

  const getFilteredGroupList = (groupName) => {
    let selectedValues = [];

    switch (groupName) {
      case "groupOne":
        selectedValues = [...selectedGroupTwo, ...selectedGroupThree];
        break;
      case "groupTwo":
        selectedValues = [...selectedGroupOne, ...selectedGroupThree];
        break;
      case "groupThree":
        selectedValues = [...selectedGroupOne, ...selectedGroupTwo];
        break;
      default:
        selectedValues = [];
    }

    const filterList = groupList.filter(
      (item) => !selectedValues.includes(item?.id)
    );

    return filterList;
  };

  const onSubmit = async (formdata) => {
    let request;
    setIsLoading(true);

    try {
      if (!isEdit) {
        request = await axiosFetch({
          url: `/api/v1/clientAutomation`,
          method: "post",
          requestConfig: formdata,
        });
      } else {
        request = await axiosFetch({
          url: `/api/v1/clientAutomation`,
          method: "put",
          requestConfig: formdata,
        });
      }

      if (request.status === 201) {
        setIsLoading(false);
        toast.success(request.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        history.push({
          pathname: "/Settings/automation",
          state: {
            tab: 1,
          },
        });
      } else {
        setIsLoading(false);
        toast.error(request.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = () => {
    history.push({
      pathname: "/Settings/automation",
      state: {
        tab: 1,
      },
    });
  };

  useEffect(() => {
    if (currentAssignedTo === "trainer") {
      setGroupList(dropDownValues.trainerList);
    } else if (currentAssignedTo === "dietitian") {
      setGroupList(dropDownValues.dietitianList);
    }
  }, [currentAssignedTo]);

  // useEffect(() => {
  //   if (selectedGroupOne) {
  //     console.log("groupOne", selectedGroupOne);
  //   } else if (selectedGroupTwo) {
  //     console.log("groupTwo", selectedGroupTwo);
  //   } else if (selectedGroupThree) {
  //     console.log("groupThre", selectedGroupTwo);
  //   }
  // }, [selectedGroupOne, selectedGroupTwo, selectedGroupThree]);

  useEffect(async () => {
    const request = await axiosFetch({
      url: `api/v1/dropdownList`,
      method: "get",
    });

    if (request.status === 200) {
      setDropDownValues(request.data?.dropDownList);
    }
  }, []);

  return (
    <Card>
      <Typography
        sx={{
          display: "inline-flex",
          alignItems: "center",
          width: "cont",
          gap: "5px",
          fontSize: "18px",
          fontWeight: "600",
          cursor: "pointer",
        }}
        onClick={handleBack}
      >
        <KeyboardBackspaceIcon />
        Add Rule
      </Typography>
      <form className="dateForm" onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(dropDownValues).length > 0 && (
          <Grid container spacing={4}>
            <Grid item md={12} sx={{ mt: "20px" }}>
              <RadioButtonGroup
                control={control}
                name="assignedTo"
                options={options}
                isRequired={true}
                disabled={isView}
              />
            </Grid>
            <Grid item md={6}>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                    Rule Name<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <TextBox
                    control={control}
                    name={"ruleName"}
                    isRequired={{
                      required: "required",
                      maxLength: {
                        value: 100,
                        message: "Maximum 100 characters allowed",
                      },
                    }}
                    disabled={isView}
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                    Sequence No<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextBox
                    control={control}
                    name={"sequenceNo"}
                    type="number"
                    isRequired
                    disabled={isView}
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                    Start Date<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <DatePickerBox
                    control={control}
                    name={"startDate"}
                    isRequired
                    disabled={isView}
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                    End Date<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <DatePickerBox
                    control={control}
                    name={"endDate"}
                    isRequired
                    disabled={isView}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                Description<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextBox
                control={control}
                name={"description"}
                isRequired={{
                  required: "required",
                  maxLength: {
                    value: 100,
                    message: "Maximum 100 characters allowed",
                  },
                }}
                disabled={isView}
              />
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="button"
                variant="contained"
                sx={{
                  backgroundColor: "#2a2a2a",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                }}
                onClick={handleAddCondition}
              >
                Add Conditions
              </Button>
            </Grid>

            {fieldCondition.map((field, index) => {
              const selectedList =
                currentSelectedList?.[index]?.selectCondition;
              const [selectedTypeOption, isMultiSelect] =
                getSelectTypeOptions(selectedList);

              const filteredConditionList = getAvailableConditionOptions(index);
              return (
                <Grid item md={12} key={field.id}>
                  <Grid container spacing={4}>
                    <Grid item md={4}>
                      <SelectBox
                        name={`conditionList.${index}.selectCondition`}
                        control={control}
                        menuList={filteredConditionList}
                        isRequired
                        inputLabel={"Condition"}
                        disabled={isView}
                      />
                    </Grid>
                    {selectedTypeOption && isMultiSelect && (
                      <Grid item md={4}>
                        <MultiSelectBox
                          name={`conditionList.${index}.multiSelectType`}
                          control={control}
                          menuList={selectedTypeOption}
                          isRequired
                          inputLabel={"Select"}
                          disabled={isView}
                        />
                      </Grid>
                    )}
                    {selectedTypeOption && !isMultiSelect && (
                      <Grid item md={4}>
                        <SelectBox
                          name={`conditionList.${index}.selectType`}
                          control={control}
                          menuList={selectedTypeOption}
                          isRequired
                          inputLabel={"Condition"}
                          disabled={isView}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      md={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "70px",
                      }}
                    >
                      <CloseIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleRemoveAddCondition(index)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <Grid item md={12}>
              <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                Assign To<span style={{ color: "red" }}>*</span>
              </Typography>
              <MultiSelectBox
                name={`groupOne`}
                control={control}
                menuList={getFilteredGroupList("groupOne")}
                isRequired
                inputLabel={"Group 1"}
                arrangeModal={{
                  marginTop: "-200px",
                  width: "600px",
                  marginLeft: "100px",
                }}
                disabled={isView}
              />
            </Grid>
            <Grid item md={12}>
              <MultiSelectBox
                name={`groupTwo`}
                control={control}
                menuList={getFilteredGroupList("groupTwo")}
                inputLabel={"Group 2"}
                arrangeModal={{
                  marginTop: "-200px",
                  width: "600px",
                  marginLeft: "100px",
                }}
                disabled={isView}
              />
            </Grid>
            <Grid item md={12}>
              <MultiSelectBox
                name={`groupThree`}
                control={control}
                menuList={getFilteredGroupList("groupThree")}
                inputLabel={"Group 3"}
                arrangeModal={{
                  marginTop: "-200px",
                  width: "600px",
                  marginLeft: "100px",
                }}
                disabled={isView}
              />
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: "flex", gap: "20px", justifyContent: "center" }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#000",
                  },
                  borderColor: "#2a2a2a",
                  color: "#000",
                  marginBottom: "20px",
                  px: "25px",
                }}
                onClick={handleBack}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#2a2a2a",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                  marginBottom: "20px",
                  px: "25px",
                }}
                disabled={isView || isLoading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </Card>
  );
};

export default AddRule;
