const Form = () => {
  return (
    <iframe
      src="http://164.52.200.111:8086"
      style={{ width: "100%", height: "100vh", border: "none" }}
      title="Embedded Page"
    />
  );
};

export default Form;
