import React, { useContext } from "react";
import { useState } from "react";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

import { IconButton, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ApiDataContext from "../../Pages/Leads/MasterDataContextProvider";
import { useEffect } from "react";

import { axiosFetch } from "../../Utlis/AxiosFetch";

const useListLead = (handleClick, open) => {
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;
  const [visibileLeadColumns, setVisibileLeadColumns] = useState({});
  const [medicalCondition, setMedicalCondition] = useState({
    medicalConditon: [],
    saleRole: [],
  });

  const masterData = useContext(ApiDataContext);
  const convertUtcToLocalTime = (utc) => {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleString();
    const inputDate = localTime.split(",");

    const [month, day, year] = inputDate[0].split("/");
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    return [formattedDate, inputDate];
  };

  const interestedValues = [
    { value: 2, label: "High" },
    { value: 1, label: "Moderate" },
  ];

  const pendingInbound = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const gender = [
    { value: "M", label: "M" },
    { value: "F", label: "F" },
    { value: "O", label: "O" },
  ];

  const tag = [
    { value: "FMC", label: "FMC" },
    { value: "FMCEXPRESS", label: "FMC Express" },
  ];

  const colorMap = {
    Hot: "#ff8787",
    Won: "#69db7c",
    Warm: "#ffa94d",
    Cold: "#4dabf7",
  };

  // -------- Add new operator "not contains" --------
  // Get the base operators
  const stringOperators = getGridStringOperators();
  // Find the "contains" operator
  const containsOperator = stringOperators.find(
    (operator) => operator.value === "contains"
  );

  // Create a "not contains" operator by cloning the "contains" operator and customizing it
  const notContainsOperator = { ...containsOperator };
  notContainsOperator.value = "notContains";
  notContainsOperator.label = "not contains";
  stringOperators.splice(1, 0, notContainsOperator);

  // Add new operator to Call count

  const callCountOperators = getGridStringOperators();

  const containOperator = stringOperators.find(
    (operator) => operator.value === "contains"
  );

  let operations = [
    { value: "lt", label: "Less than" },
    { value: "gt", label: "Greater than" },
    { value: "lte", label: "Less than or equal to" },
    { value: "gte", label: "Greater than or equal to" },
  ];

  for (let operation of operations) {
    let newObj = {
      ...containOperator,
      value: operation.value,
      label: operation.label,
    };
    callCountOperators.push(newObj);
  }

  function getDisplayValueById(arr, id, fn) {
    try {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return fn(arr[i]);
          // arr[i][attrName];
        }
      }
    } catch (error) {
      let err = error;
    }
    return "-";
  }

  const leadColumnsList = [
    {
      field: "id",
      headerName: "Lead Id",
      headerClassName: "super-app-theme--header",
      width: 150,
    },

    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => {
        return !!params.row.name ? params.row.name : "-";
      },
      filterOperators: stringOperators,
    },

    {
      field: "account_name",
      headerName: "Account Name",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",

      // options for select dropdown
      valueOptions: masterData.sources.map((e) => {
        return { value: e.id, label: e.Source };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.source) {
          let val = getDisplayValueById(
            masterData.sources,
            obj.row.source,
            (e) => e.Source
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "tag",
      headerName: "Tag",
      headerClassName: "super-app-theme--header",
      type: "singleSelect",
      width: 150,
      valueOptions: tag.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      // valueGetter is old method don't use this
      renderCell: (params) => {
        return !!params.row.isConsultation ||
          !!params.row.isExpressConsultation ? (
          <Typography
            sx={{
              color: "#495057",
              backgroundColor: "#f3d9fa",
              padding: "10px 20px",
              borderRadius: "30px",
            }}
          >
            {!!params.row.isConsultation ? "FMC" : "FMC Express"}
          </Typography>
        ) : (
          <Typography
            sx={{
              padding: "10px 20px",
              borderRadius: "30px",
            }}
          >
            -
          </Typography>
        );
      },
    },
    {
      field: "age",
      headerName: "Age",
      headerClassName: "super-app-theme--header",
      width: 50,
      valueGetter: (params) => (!!params.row.age ? params.row.age : "-"),
    },
    {
      field: "gender",
      headerName: "Gender",
      type: "singleSelect",
      headerClassName: "super-app-theme--header",
      width: 100,
      valueOptions: gender.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
    },

    {
      field: "recommended_program_name",
      headerName: "Recommendation",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.recommended_program_name
          ? params.row.recommended_program_name
          : "-",
    },

    {
      field: `leadStageInfo`,
      headerName: "Stage",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",

      // options for select dropdown
      valueOptions: masterData.leadStages.map((e) => {
        return { value: e.id, label: e.display_value };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.lead_stage_id) {
          let val = getDisplayValueById(
            masterData.leadStages,
            obj.row.lead_stage_id,
            (e) => e.display_value
          );
          return (
            <Typography
              component={"span"}
              sx={{
                backgroundColor: colorMap[val] || "",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {val}
            </Typography>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: `leadDispositionInfo`,
      headerName: "Disposition",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      // options for select dropdown
      valueOptions: masterData.leadDisposition.map((e) => {
        return { value: e.id, label: e.display_value };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.lead_disposition_id) {
          let val = getDisplayValueById(
            masterData.leadDisposition,
            obj.row.lead_disposition_id,
            (e) => e.display_value
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: `leadSubDispositionInfo`,
      headerName: "Sub Disposition",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      // options for select dropdown
      valueOptions: masterData.leadSubDisposition.map((e) => {
        return { value: e.id, label: e.display_value };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.lead_sub_disposition_id) {
          let val = getDisplayValueById(
            masterData.leadSubDisposition,
            obj.row.lead_sub_disposition_id,
            (e) => e.display_value
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: `leadSubNotInterestedInfo`,
      headerName: "Grand Disposition",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      // options for select dropdown
      valueOptions: masterData.leadNotInterested.map((e) => {
        return { value: e.id, label: e.display_value };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.lead_sub_not_interested_id) {
          let val = getDisplayValueById(
            masterData.leadNotInterested,
            obj.row.lead_sub_not_interested_id,
            (e) => e.display_value
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
      ////////////////////////////////////////////////////////////////////////////////////////
    },
    {
      field: "email_id",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 150,

      valueGetter: (params) =>
        !!params.row.email_id ? params.row.email_id : "-",
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      headerClassName: "super-app-theme--header",
      width: 150,

      renderCell: (params) => {
        const incoming = params.row?.pendingInbound;

        return (
          <span
            style={{
              backgroundColor: incoming ? "#ff6b6b" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              fontWeight: "600",
            }}
          >
            {!!params.row.contact_number ? params.row.contact_number : "-"}
          </span>
        );
      },
    },
    {
      field: "height",
      headerName: "Height",
      headerClassName: "super-app-theme--header",
      width: 100,
      valueGetter: (params) => (!!params.row.height ? params.row.height : "-"),
    },
    {
      field: "current_weight",
      headerName: "Weight",
      headerClassName: "super-app-theme--header",
      width: 100,
      valueGetter: (params) =>
        !!params.row.current_weight ? params.row.current_weight : "-",
    },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   valueGetter: (params) =>
    //     params.row.address !== null ? params.row.address : "-",
    // },
    {
      field: "pendingInbound",
      headerName: "Inbound",
      headerClassName: "super-app-theme--header",
      width: 100,
      type: "singleSelect",
      valueOptions: pendingInbound.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      renderCell: (params) => {
        return params.row?.pendingInbound ? "Yes" : "No";
      },
    },
    {
      field: "city",
      headerName: "City",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (!!params.row.city ? params.row.city : "-"),
    },

    {
      field: "country",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      width: 150,

      filterOperators: stringOperators,
      valueGetter: (params) =>
        !!params.row.country ? params.row.country : "-",
    },
    {
      field: "form_filled_date",
      headerName: "Form Fill up date",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "date",

      valueFormatter: (params) => {
        return params.row?.form_filled_date;
      },
      renderCell: (params) =>
        !!params.row.form_filled_date
          ? params.row.form_filled_date.split("T")[0]
          : "-",
    },
    {
      field: "form_submission_count",
      headerName: "Form Submissions",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.form_submission_count
          ? params.row.form_submission_count
          : "-",
    },
    {
      field: "call_count",
      headerName: "Call Count",
      width: "200",
      filterOperators: callCountOperators,
      valueGetter: (params) => {
        return params.row?.call_count ? params.row?.call_count : "-";
      },
    },

    {
      field: "notes",
      headerName: "Call Notes",
      width: "200",
      valueGetter: (params) => {
        return params.row.reviewNote ? params.row.reviewNote : "-";
      },
    },

    {
      field: "preferred_language",
      headerName: "Preferred Language",
      headerClassName: "super-app-theme--header",
      width: 150,

      valueGetter: (params) =>
        !!params.row.preferred_language ? params.row.preferred_language : "-",
    },
    {
      field: "social_media_handle_name",
      headerName: "Social Media Handler",
      width: "200",
      valueGetter: (params) => {
        return params.row?.social_media_handle_name
          ? params.row?.social_media_handle_name
          : "-";
      },
    },
    {
      field: "assigned_to_user",
      headerName: "Sales Person",
      headerClassName: "super-app-theme--header",

      width: 150,
      type: "singleSelect",
      // options for select dropdown
      valueOptions: medicalCondition.saleRole.map((e) => {
        return {
          value: e.id,
          label:
            (e.firstName ? e.firstName : "") +
            " " +
            (e.lastName ? e.lastName : ""),
        };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.assigned_to) {
          let val = getDisplayValueById(
            masterData.users,
            obj.row.assigned_to,
            (e) =>
              (e.firstName ? e.firstName : "") +
              " " +
              (e.lastName ? e.lastName : "")
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "occupation",
      headerName: "Profession",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.occupation ? params.row.occupation : "-",
    },
    {
      field: "next_followup_date",
      headerName: "Next Follow Up",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "date",
      renderCell: (params) => {
        if (params.row.next_followup_on) {
          return convertUtcToLocalTime(params.row.next_followup_on)[0];
        } else {
          return "-";
        }
      },
    },
    {
      field: "referral_code",
      headerName: "Referral",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.referral_code ? params.row.referral_code : "-",
    },

    {
      field: "current_medical_condition",
      headerName: "Current Medical Condition",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      valueOptions: medicalCondition?.medicalConditon.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      renderCell: (params) => {
        if (params.row?.current_medical_condition) {
          return params.row?.current_medical_condition.join();
        } else {
          return "-";
        }
      },
    },

    {
      field: "createdAt",
      headerName: "Created Date",
      width: 180,
      type: "date",

      valueFormatter: (params) => {
        return params.row?.createdAt;
      },
      renderCell: (params) => {
        if (params.row.createdAt) {
          return (
            <span>
              {convertUtcToLocalTime(params.row.createdAt)[0]}
              {convertUtcToLocalTime(params.row.createdAt)[1][1]}
            </span>
          );
        } else {
          return "-";
        }
      },
      //  filterable: false,
    },
    {
      field: "created_by_details",
      headerName: "Created By",
      width: 150,
      type: "singleSelect",
      valueOptions: masterData.users.map((e) => {
        return {
          value: e.id,
          label: `${e.firstName ? e.firstName : ""} ${
            e.lastName ? e.lastName : ""
          }`,
        };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.created_by) {
          let val = getDisplayValueById(
            masterData.users,
            obj.row.created_by,
            (e) =>
              (e.firstName ? e.firstName : "") +
              " " +
              (e.lastName ? e.lastName : "")
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      width: 180,
      type: "date",
      valueFormatter: (params) => {
        return params.row?.updatedAt;
      },
      renderCell: (obj) => {
        if (obj.row.updatedAt) {
          return (
            <span>
              {convertUtcToLocalTime(obj.row.updatedAt)[0]}
              {convertUtcToLocalTime(obj.row.updatedAt)[1][1]}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "updated_by_details",
      headerName: "Updated By",
      width: 150,
      type: "singleSelect",
      valueOptions: masterData.users.map((e) => {
        return {
          value: e.id,
          label: `${e.firstName ? e.firstName : ""} ${
            e.lastName ? e.lastName : ""
          }`,
        };
      }),
      renderCell: (obj) => {
        if (obj.row.updated_by) {
          let val = getDisplayValueById(
            masterData.users,
            obj.row.updated_by,
            (e) =>
              (e.firstName ? e.firstName : "") +
              " " +
              (e.lastName ? e.lastName : "")
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
    },

    {
      field: "Interested",
      headerName: "Interested",
      headerClassName: "super-app-theme--header",
      type: "singleSelect",

      // valueOptions: masterData.leadSubDisposition.map((e) => {
      //   return { value: e.id, label: e.display_value };
      // }),

      valueOptions: interestedValues.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      renderCell: (params) => {
        if (params.row?.lead_interested) {
          return (
            <sapn
              style={{
                backgroundColor:
                  params.row?.lead_interested === "High" ? "#ffc9c9" : "",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                fontWeight: "600",
              }}
            >
              {params.row?.lead_interested}{" "}
            </sapn>
          );
        } else {
          return "-";
        }
      },

      // cellClassName: (params) => {
      //   if (params.row?.lead_interested === "High") {
      //     return clsx("super-app", {
      //       positive: true,
      //     });
      //   } else if (params.row?.lead_interested === "Moderate") {
      //     return clsx("super-app", {
      //       negative: true,
      //     });
      //   }
      // },
      // cellClassName: (params) =>
      // clsx("super-app", {
      //   negative: !params.row.next_followup_on,
      //   positive:
      //     params.row.next_followup_on &&
      //     moment(params.row.next_followup_on).isValid(),
      // }),
    },
    {
      field: "action",
      hideable: false,
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 150,

      renderCell: (params) => {
        // if (params.row.vmax_lead_hf_report !== null) {
        //   setIsReportExist(true);
        // } else {
        //   setIsReportExist(false);
        // }

        return (
          <IconButton
            aria-label="delete"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => handleClick(e, params)}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  const getDropdown = async () => {
    const filterList = await axiosFetch({
      url: `/getTrainersNutritionMedicalForFilterDropdown`,
      method: "get",
    });

    const assignList = await axiosFetch({
      url: "/getSalesAssignUsers",
      method: "get",
    }).then((res) => res.data);

    const getColumnList = await axiosFetch({
      url: `getListViewByUserIdAndScreen/${userId}/1`,
      method: "get",
    }).then((res) => res.data);

    if (getColumnList?.fieldList) {
      setVisibileLeadColumns(getColumnList?.fieldList);
    }

    setMedicalCondition({
      medicalConditon: filterList.data.medicalConditon,
      saleRole: assignList,
    });
  };

  useEffect(() => {
    getDropdown();
  }, []);

  return { visibileLeadColumns, leadColumnsList, medicalCondition };
};

export default useListLead;

// const visibileLeadColumns = {
//   id: true,
//   name: true,
//   contact_number: true,
//   email_id: true,
//   gender: true,
//   account_name: true,
//   country: true,
//   preferred_language: true,
//   leadStageInfo: true,
//   assigned_to_user: true,
//   Interested: true,
//   form_filled_date: true,
//   createdAt: true,
//   updatedAt: true,

//   age: false,
//   leadDispositionInfo: false,
//   leadSubDispositionInfo: false,
//   leadSubNotInterestedInfo: false,
//   height: false,
//   current_weight: false,
//   city: false,
//   form_submission_count: false,
//   notes: false,
//   preferred_language: true,
//   social_media_handle_name: false,
//   assigned_to_user: true,
//   occupation: false,
//   next_followup_date: false,
//   referral_code: false,
//   current_medical_condition: false,
//   created_by_details: false,
//   updated_by_details: false,
// };
