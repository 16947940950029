import ReactDOM from "react-dom";

import "../Report.css";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Backdrop = ({ dispatch }) => {
  return (
    <div
      className={"session-backdrop"}
      onClick={() =>
        dispatch({
          type: "picture",
          payload: false,
        })
      }
    ></div>
  );
};

const table = {
  border: "2px solid #D0D0D0",
  color: "#6B6B6B",
};

const TableHeader = [
  { front_facing: "Facing Sides" },
  { right_facing_after_image: "Right Facing" },
  { left_facing_after_image: "Left Facing" },
  { front_facing_after_image: "Front Facing" },
  { back_facing_after_image: "Back Facing" },
];

const Picutre = ({ dispatch, currentRow }) => {
  // const images = [
  //   {
  //     right_facing_after_image:
  //       "https://img.freepik.com/premium-photo/sporty-fit-yogini-woman-practices-yoga-pranayama-breath-control_163782-4818.jpg?w=360",
  //   },
  //   {
  //     left_facing_after_image:
  //       "https://img.freepik.com/premium-photo/indian-woman-girl-performing-yoga-asana-meditation-dhyana-sitting-isolated-white-background_466689-12012.jpg?w=360",
  //   },
  //   {
  //     front_facing_after_image:
  //       "https://img.freepik.com/premium-photo/sporty-fit-yogini-woman-practices-yoga-pranayama-breath-control_163782-4818.jpg?w=360",
  //   },
  //   {
  //     back_facing_after_image:
  //       "https://img.freepik.com/premium-photo/indian-woman-girl-performing-yoga-asana-meditation-dhyana-sitting-isolated-white-background_466689-12012.jpg?w=360",
  //   },
  // ];

  const [picture, setPicture] = useState([]);

  const getPicture = async () => {
    const request = await axiosFetch({
      url: `getProgressImagesToProgressReportByLeadId/${currentRow.leadId}`,
      method: "get",
    });

    if (request.status === 200) {
      setPicture(request.data);
    }
  };

  useEffect(() => {
    getPicture();
  }, [currentRow]);

  return (
    <div className="progress_picture">
      <div className="report__modal-header">
        <h3>Progress Pictures</h3>
        <CloseIcon
          style={{ fontSize: "21px", color: "#6B6B6B", cursor: "pointer" }}
          onClick={() =>
            dispatch({
              type: "picture",
              payload: false,
            })
          }
        />
      </div>

      {picture.length === 0 && (
        <span className="no_data">No Picture Available</span>
      )}

      {picture.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TableHeader.map((headerItem, index) => {
                  const value = Object.values(headerItem)[0];
                  return index === 0 ? (
                    <TableCell
                      key={index}
                      style={{ backgroundColor: "#F4F4F4", color: "black" }}
                      sx={table}
                    >
                      {value}
                    </TableCell>
                  ) : (
                    <TableCell key={index} sx={table}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {TableHeader.map((headerItem, index) => {
                  const key = Object.keys(headerItem)[0];
                  const matchingRow = picture.find(
                    (rowItem) => Object.keys(rowItem)[0] === key
                  );
                  return (
                    <>
                      {matchingRow ? (
                        <TableCell key={index} sx={table}>
                          <img
                            src={matchingRow[key]}
                            alt="progress Pictures"
                            style={{ width: "100px", height: "150px" }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={index}
                          style={{ backgroundColor: "#F4F4F4", color: "black" }}
                          sx={table}
                        >
                          "Pictures"
                        </TableCell>
                      )}
                    </>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

const ProgressPicture = ({ dispatch, currentRow }) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(<Backdrop dispatch={dispatch} />, backdropRoot)}
      {ReactDOM.createPortal(
        <Picutre dispatch={dispatch} currentRow={currentRow} />,
        editTableModal
      )}
    </>
  );
};

export default ProgressPicture;
