import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Snackbar, Alert } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import {
  updateEvents,
  updateGetEvents,
  updateRest,
  updateCustomArray,
  updateCustomList,
} from "../../../store/WorkoutPlan/WorkoutPlan";
import "./Calendar.css";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";

import {
  updateVmax,
  updateLive,
  updateCustom,
  updateEditEvent,
  updateEditWorkout,
  updateClickEvent,
  updateStartDate,
  updateDeleteUpdateEvent,
  updateView,
} from "../../../store/WorkoutPlan/WorkoutPlan";
import { useParams } from "react-router-dom";

const Calendar = (props) => {
  const { id } = useParams();
  const { setTarget_calorie, setMainPlanNotes, setIndexdb,setEdit,setWorkoutPlanName } = props;

  const clientId = useSelector((state) => state.leadId);
  const dispatch = useDispatch();

  const [snackBarColor, setSnackBarColor] = useState("error");
  const [snackMessage, setsnackMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [workoutType, setWorkoutType] = useState([]);
  const workoutPlan = useSelector((state) => state.workoutPlan.vmax);
  const workout = useSelector((state) => state.workoutPlan);
  let customListArray = useSelector((state) => state.workoutPlan.customList);
  const plan = useSelector((state) => state.workoutPlan.startDate);
  const editWorkout = useSelector((state) => state.workoutPlan.editWorkout);

  const viewTemplate = useSelector((state) => state.workoutPlan.viewTemplate);
  const [hasdate, setHasdate] = useState("");

  const [oldIdList, setOldIdList] = useState("");

  const [cloneEvents, setCloneEvents] = useState([]);

  const [clickEvent, setClickEvent] = useState("");

  const [events, setEvents] = useState([]);
  // const [createdEvents, setCreatedEvents] = useState("");

  const handleDateClick = (args) => {
    // To Block dates before Plan Start Date

    if (viewTemplate) {
      return;
    }
    if (!plan.planStart && !plan.date) {
      setsnackMessage("Please provide Dates ");
      setSnackBarColor("warning");
      setOpen(true);
      return;
    }
    if (props) {
      let selectedDate = new Date(args["date"]);
      selectedDate.setDate(selectedDate.getDate() + 1);

      let StartDate = new Date(plan.planStart);
      let endDate = new Date(plan.date);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      endDate.setMilliseconds(999);

      if (selectedDate < StartDate || selectedDate > endDate)
        props.modelOpen(false);
      else props.modelOpen(true);
    }
    if (!plan.targetCalorie && !props.templateFlag) {
      setsnackMessage("Please provide Target Calories");
      setSnackBarColor("warning");
      setOpen(true);
      return;
    }
    if (plan.planName === "" && !props.templateFlag) {
      setsnackMessage("Please provide Workout Plan Name");
      setSnackBarColor("warning");
      setOpen(true);
      return;
    }
    if (props.templateFlag) {
      // if (!editWorkout) {
      if (plan.startDate || plan.templateName) {
        props.modelOpen(true);
        dispatch(updateEditEvent(""));
      } else {
        setsnackMessage("Please enter Template name and start date");
        setSnackBarColor("warning");
        setOpen(true);
        return;
      }
      // }
    } else {
      // if (!editWorkout) {
      if (!props.templateListId) {
        // if (plan.planName) {
        //   setsnackMessage("Please provide Workout Plan Name");
        //   setSnackBarColor("warning");
        //   setOpen(true);
        // }
        if (plan.planStart) {
          props.modelOpen(true);
          dispatch(updateEditEvent(""));
        } else {
          setsnackMessage("Please provide dates");
          setSnackBarColor("warning");
          setOpen(true);
        }
      }
      // }
    }
  };

  const handleEventClick = async (event) => {

    if (viewTemplate) {
      return;
    }

    const dateObject = new Date(event.start);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    const eventObj = {
      start: formattedDate,
      id: event.extendedProps.ids,
      title: event.title,
    };

    dispatch(updateClickEvent(eventObj));

    setClickEvent(formattedDate);
    setHasdate(formattedDate);

    if (event.title === "rest") {
      setsnackMessage("You can't able to edit the rest you can delete that");
      setSnackBarColor("warning");
      setOpen(true);
      // alert(`You can't able to edit the rest you can delete that`);
      return;
    }

    if (editWorkout) {
      let response;
      let filteredArray;
      if (editWorkout.name === "template") {
        response = await axiosFetch({
          url: `/getTemplateWorkoutByWorkoutId/${event.extendedProps.ids}`,
          method: "get",
        });
      } else {
        if(event.extendedProps.ids){
        response = await axiosFetch({
          url: `/getWorkoutsByWorkoutId/${event.extendedProps.ids}`,
          method: "get",
        });
      }
      else {
        filteredArray = customListArray.filter(item => item.date === formattedDate);
        props.modelOpen(true);
        dispatch(updateEditEvent(filteredArray[0]));
        }
      }

      if (response?.status === 200) {
        props.modelOpen(true);
        dispatch(updateEditEvent(response.data));
      }
    }
  };

  const handleDeleteClick = async (event) => {

    const confirmed = window.confirm(`Are you sure you want to delete this`);
    if (confirmed) {
      let response;
    
      if (props.editTemplateFlag) {
        response = await axiosFetch({
          url: `/postWorkoutTemplate`,
          requestConfig: {
            action: "delete",
            workoutTypeId: event.extendedProps.ids,
            exerciseId: event.extendedProps.exerciseID,
          },
          method: "post",
        });
        if (response.status === 200) {
          fetchData();
        }
      } else if (confirmed && !event.extendedProps.ids) {
        let event_s = [...workout.events];
        // let customListArray = [];
        for (let i = 0; i < event_s.length; i++) {
          if (
            event.startStr === event_s[i].start &&
            event.title === event_s[i].title
          ) {
            event_s.splice(i, 1);

            setEvents(event_s);
          }
        }
        let customArray = [...customListArray];

        for (let i = 0; i < customArray.length; i++) {
          const currentPlan = customArray[i];

          if (currentPlan.date === event.startStr) {
            let removePlan = false; // Flag to determine plan removal

            const updatedWorkoutDetails = currentPlan.workout_details
              .map((workout) => {
                const exercises = workout[Object.keys(workout)[0]].items;
                const filteredExercises = exercises.filter(
                  (exercise) => exercise.exerciseName !== event.title
                );

                if (filteredExercises.length < 1) {
                  removePlan = true; // Set flag if no exercises are left
                  return null; // Return null for exercises to be filtered out
                }

                return {
                  [Object.keys(workout)[0]]: {
                    items: filteredExercises,
                    categoryType: workout[Object.keys(workout)[0]].categoryType,
                  },
                };
              })
              .filter(Boolean); // Filter out null values

            if (removePlan) {
              customArray.splice(i, 1); // Remove the plan from customArray
              i--; // Decrement i to adjust for the removed element
            } else {
              customArray[i] = {
                ...currentPlan,
                workout_details: updatedWorkoutDetails,
              };
            }
          }
        }

        dispatch(updateCustomArray(customArray));
        // response = await axiosFetch({
        //   url: `/postWorkoutTemplate`,
        //   requestConfig: {
        //     action: "delete",
        //     workoutTypeId: event.extendedProps.ids,
        //     exerciseId: event.extendedProps.exerciseID
        //   },
        //   method: "post",
        // });
        // if (response.status === 200) {
        //   fetchData();
        // }
      } else {
        response = await axiosFetch({
          url: `/postWorkoutPlans`,
          requestConfig: {
            action: "delete",
            workoutTypeId: event.extendedProps.ids,
            exerciseId: event.extendedProps.exerciseID,
          },
          method: "post",
        });
        if (response.status === 200) {
          fetchData();
        }
      }
    }
  };

  let createdEvents = [];

  useEffect(() => {
    fetchData();
    props.handleChildComponent();
  }, [props.childComponent]);

  // const [modifyeventList, setModifyeventList] = useState([]);

  const updateCalendarEvents = (modifyevents, params) => {
    setEvents((prevEvents) => {
      let eventEdit = false;

      function hasCommonDate(events1, events2) {
        return events1.some((event1) =>
          events2.some((event2) => {
            if (event1.start === event2.start && event1.start === hasdate) {
              eventEdit = true;
              return false;
            }
            return event1.start === event2.start;
          })
        );
      }

      function hasSameDate(events1, events2) {
        return events1.filter((event1) =>
          events2.some((event2) => {
            if (event1.start === hasdate) {
              // eventEdit = true;
              return false;
            }
            return event1.start !== event2.start;
          })
        );
      }
      function checkForRestDay(oldArray, newArray) {
        const firstRestDays = {};
        let checkedDays = [];
        let state = false;
        // Check oldArray for the first occurrence of a rest day for each particular day
        for (const entry of oldArray) {
          if (
            (entry.plan_type === "rest" || entry.planType === "rest") &&
            !(entry.start in firstRestDays) &&
            !checkedDays.includes(entry.start)
          ) {
            firstRestDays[entry.start] = true;
          } else {
            checkedDays.push(entry.start);
          }
        }

        // Check newArray for any exercise on the first rest day for each particular day in oldArray
        for (const entry of newArray) {
          const date = entry.start;

          if (date in firstRestDays) {
            alert(`Error: Rest day doesn't allow any exercise on ${date}`);
            setsnackMessage(
              `Error: Rest day doesn't allow any exercise on ${date}`
            );
            setSnackBarColor("warning");
            setOpen(true);
            state = true;
          }
        }
        return state;
      }

      let result;
      let editResult;

      if (editWorkout) {
        result = hasCommonDate(cloneEvents, modifyevents);
        editResult = hasSameDate(prevEvents, modifyevents);

        if (result) {
          if (checkForRestDay(cloneEvents, modifyevents))
            // alert("Event has duplicate data");
            // setsnackMessage("Event has duplicate data");
            // setSnackBarColor("warning");
            // setOpen(true);
            return [...prevEvents];
          // setCloneEvents((pre) => {
          //   return [...pre, ...modifyevents];
          // });
          // return [...editResult, ...modifyevents];
        } else {
          setCloneEvents((pre) => {
            return [...pre, ...modifyevents];
          });
          return [...editResult, ...modifyevents];
        }
      } else {
        result = hasCommonDate(prevEvents, modifyevents);
      }

      if (result) {
        if (checkForRestDay(prevEvents, modifyevents)) return [...prevEvents];
        // setsnackMessage("Event has duplicate data");
        // setSnackBarColor("warning");
        // setOpen(true);
        // alert("Event has duplicate data");
        // return [...prevEvents];
        else return [...prevEvents, ...modifyevents];
      } else {
        if (params === "vmax") {
          dispatch(updateVmax(""));
        }
        if (params === "live") {
          dispatch(updateLive(""));
        }
        if (params === "custom") {
          dispatch(updateCustom(""));
        }
        if (params === "rest") {
          dispatch(updateRest(""));
        }
        return [...prevEvents, ...modifyevents];
      }
    });
  };

  useEffect(() => {
    let modifyevents = [];

    if (workout.vmax) {
      modifyevents = workout.vmax.map((vmax) => {
        let newEvents = {
          start: vmax.date,
          title: vmax.plan_name,
          planType: "vmax",
          color: "green",
        };
        return newEvents;
      });
    }

    updateCalendarEvents(modifyevents, "vmax");
  }, [workout.vmax, editWorkout]);

  useEffect(() => {
    let modifyevents = [];

    if (workout.rest) {
      modifyevents = workout.rest.map((rest) => {
        let newEvents = {
          start: rest.date,
          title: rest.plan_type,
          planType: "rest",
          color: "red",
        };

        return newEvents;
      });
    }

    updateCalendarEvents(modifyevents, "rest");
  }, [workout.rest]);

  useEffect(() => {
    let modifyevents = [];

    if (workout.live) {
      modifyevents = workout.live.map((live) => {
        let newEvents = {
          start: live.date,
          title: live.plan_name,
          planType: "live",
          color: "blue",
        };
        return newEvents;
      });
    }

    updateCalendarEvents(modifyevents, "live");
  }, [workout.live]);

  useEffect(() => {
    let modifyevents = [];

    if (workout.custom) {
      workout.custom.forEach((custom) => {
        custom.workout_details.forEach((workout) => {
          for (const item in workout) {
            workout[item]["items"].forEach((s) => {
              modifyevents.push({
                start: custom.date,
                title: s["exerciseName"],
                planType: "custom",
                color: "#e8590c",
              });
            });
          }
        });
      });

      // modifyevents = workout.custom.map((custom) => {

      //   // NOTE: Modify here to add event stack
      //   // map over workout_details

      //   let workout_name = workoutType.filter(
      //     (type) => type.id === custom.workout_type
      //     );

      //     const exerciseNames = custom.workout_details.flatMap((details) =>
      //     Object.values(details).map((item) => item.items[0].exerciseName)
      //   );

      //   let newEvents = {
      //     start: custom.date,
      //     // title: custom.workout_type_name ? custom.workout_type_name: workout_name[0]?.name,
      //     title: exerciseNames[0],
      //     planType: "custom",
      //     color: "#e8590c",
      //   };

      //   return newEvents;
      // });
    }

    updateCalendarEvents(modifyevents, "custom");
  }, [workout.custom]);

  useEffect(() => {
    if (events.length > 0) {
      dispatch(updateEvents(events));
    }
  }, [events]);

  const fetchData = async (plate) => {
    //editWorkout
    let response;

    if (!plate) {
      if (typeof props.editTemplateFlag !== "undefined") {
        if (!props.editTemplateFlag) {
          return;
        } else {
          const templateResponse = await axiosFetch({
            url: `/getWorkoutTemplateById/${props.editTemplateFlag}`,
            method: "get",
          });
          response = [templateResponse.data];
        }
      }

      if (typeof props.editTemplateFlag === "undefined") {
        if (editWorkout) {
          const list = await axiosFetch({
            url: `/getWorkoutPlansByPlanId/${editWorkout}`,
            method: "get",
          });

          response = [list.data];
          setEdit(true)
          let planNote = list.data?.planNote;
          async function getHTML(link) {
            const response = await fetch(link);
            const htmlString = await response.text();
            return htmlString;
          }

          if (!!planNote) {
            getHTML(planNote)
              .then((res) => {
                setTarget_calorie(res);
                setMainPlanNotes(res);
                setIndexdb(res);
              })
              .catch((err) => console.log(err));
          }
        } else {
          if (!props.editTemplateFlag) {
            let clientId = id;
            let responseList = await axiosFetch({
              url: `/getAllWorkoutPlansByleadId/${clientId}`,
              method: "get",
            });
            response = responseList.data;
          }
        }
      }
    } else {
      // let updateTemplate = {
      //   ...plate,
      // };

      const inputDate = plan.planStart;
      const parts = inputDate.split("-");
      const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

      let updateTemplate = {
        ...plate,
        template: plate?.template?.map((item) => {
          let diffInDays = Math.floor(
            (new Date(item.date) - new Date(plate.template[0].date)) /
              (24 * 60 * 60 * 1000)
          );

          return {
            ...item,
            date: new Date(
              new Date(formattedDate).getTime() +
                diffInDays * 24 * 60 * 60 * 1000
            )
              .toISOString()
              .split("T")[0],
          };
        }),
      };

      response = [updateTemplate];
    }

    let dataList = [];
    let data;

    if (response) {
      dataList = response;
    }

    const workoutType = await axiosFetch({
      url: `/getWorkoutNames`,
      method: "get",
    });
    setWorkoutType(workoutType.data);

    if (dataList) {
      data = dataList.flatMap((item) => {
        if (editWorkout) {
          const [year, month, day] = item.start_date.split("-");
          const formattedDate = `${month}-${day}-${year}`;

          function handleNextMonthDate() {
            const startDateStr = formattedDate;
            const daysToAdd = 31;

            const startDate = new Date(startDateStr);

            startDate.setDate(startDate.getDate() + daysToAdd);

            const nextDateStr = `${
              startDate.getMonth() + 1
            }-${startDate.getDate()}-${startDate.getFullYear()}`;

            return nextDateStr;
          }
          setWorkoutPlanName(item.plan_name)
          dispatch(
            updateStartDate({
              date: handleNextMonthDate(),
              planStart: formattedDate,
              planName: item.plan_name,
              templateName: item.template_name,
              targetCalorie: item.target_calorie,
            })
          );
        }

        let itemArray = [];

        if (item) {
          itemArray = item.planDetails ? item.planDetails : item.template;
        }

        // return itemArray?.map((detail) => {
        //   let rest =
        //     detail?.plan_type === "rest"
        //       ? "rest"
        //       : detail?.workout_details?.flatMap((details) =>
        //       Object.values(details)?.map(
        //         (item) => item?.items[0]?.exerciseName
        //       )
        //     );
        //   let color = detail?.plan_type === "rest" ? "red" : "";

        //   // workoutType.data.filter((value) => {
        //   //   if (value.id === detail.workout_type) {
        //   //     rest = value.name;
        //   //   }
        //   // });

        //   // return {
        //   //   title: rest,
        //   //   start: detail.date,
        //   //   id: detail.id,
        //   //   plan_type: detail.plan_type,
        //   //   color: color,
        //   // };

        // });

        let events = [];
     
        itemArray.forEach((arrItem) => {
          if (arrItem.plan_type === "rest") {
            events.push({
              start: arrItem.date,
              title: "rest",
              planType: arrItem.plan_type,
              color: "red",
            });
          } else
            arrItem.workout_details.forEach((workout) => {
              for (const item in workout) {
                workout[item]["items"].forEach((s) => {
                  events.push({
                    start: arrItem.date,
                    title: s["exerciseName"],
                    planType: arrItem.plan_type,
                    exerciseID: s["id"],
                    ids: arrItem["id"],
                    // color: "#e8590c",
                  });
                });
              }
            });
        });
        return events;
      });
    }

    let responseAll = [];
    let responseAllList = [];
    if (typeof props.editTemplateFlag === "undefined") {
      let clientId = id;
      responseAll = await axiosFetch({
        url: `/getAllWorkoutPlansByleadId/${clientId}`,
        method: "get",
      });

      responseAllList = responseAll.data.flatMap((item) =>
        item.planDetails.map((detail) => ({
          title: item.plan_name,
          start: detail.date,
          id: detail.id,
          plan_type: detail.plan_type,
        }))
      );
    } else {
      responseAllList = data;
    }

    if (plate) {
      let idList = [];

      function isDateInNewTemplate(date) {
        return response.some((item) => {
          return item?.template?.some((tpl) => tpl.date === date);
        });
      }

      let idSet = new Set(idList);

      responseAll.data.forEach((item) => {
        item.planDetails.forEach((plan) => {
          if (isDateInNewTemplate(plan.date)) {
            idSet.add(plan.id);
          }
        });
      });

      idList = Array.from(idSet);

      const transformedTemplate = response.map((item) => {
        const { id, template, template_name, ...rest } = item;
        const relationship_data = template?.reduce((acc, curr) => {
          const { id, ...rest } = curr;
          if (!acc[curr.plan_type]) {
            acc[curr.plan_type] = [];
          }
          acc[curr.plan_type].push(rest);
          return acc;
        }, {});
        return { ...rest, relationship_data, plan_name: template_name };
      });

      setOldIdList(idList);

      dispatch(
        updateDeleteUpdateEvent({
          idList: idList,
          updateTemplateList: transformedTemplate,
        })
      );

      // if (idList.length > 0) {
      //   const deleteOldEvent = async (id) => {
      //     response = await axiosFetch({
      //       url: `/postWorkoutPlans`,
      //       requestConfig: {
      //         action: "delete",
      //         workoutTypeId: id,
      //       },
      //       method: "post",
      //     });
      //   };

      //   idList.map((id) => {
      //     deleteOldEvent(id);
      //   });
      // }
    }

    setEvents(data);
    dispatch(updateGetEvents(responseAllList));
    setCloneEvents(responseAllList);
  };

  useEffect(() => {
    fetchData();
  }, [props.editTemplateFlag]);

  useEffect(() => {
    if (props.templateListId) {
      const fetchTemplateList = async () => {
        const response = await axiosFetch({
          url: `/getWorkoutTemplateById/${props.templateListId}`,
          method: "get",
        });

        if (response.status === 200) {
          fetchData(response.data);
        }
      };
      fetchTemplateList();
    }
  }, [props.templateListId]);

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      dispatch(updateView(""));
    };
  }, []);

  const eventContent = (eventInfo) => {
    return (
      <div className="event-container">
        <div className="event-time">{eventInfo.timeText}</div>
        <div
          className="event-title"
          onClick={() => handleEventClick(eventInfo.event)}
          onKeyDown={() => handleEventClick(eventInfo.event)}
        >
          {eventInfo.event.title}
        </div>

        {!viewTemplate && (
          <button
            className="delete-btn"
            onClick={() => handleDeleteClick(eventInfo.event)}
          >
            Delete
          </button>
        )}
      </div>
    );
  };

  const calendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: "dayGridMonth",
    dateClick: handleDateClick,
    eventOrder: false,
    events: events,

    headerToolbar: {
      start: "today prev,next",
      center: "title",
      end: "",
    },
    buttonText: {
      today: "Today",
    },

    eventContent: eventContent,
    eventOrderStrict: true,
  };

  return (
    <div className="workout-calendar-con">
      <div className={`centered-calendar ${props.templateFlag && `template`}`}>
        {/* <div className="centered-calendar"> */}
        <FullCalendar {...calendarOptions} />
        <Snackbar
          className="snackbar-container"
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            style={{ fontWeight: "bold", fontSize: "19px" }}
            severity={snackBarColor}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default Calendar;
