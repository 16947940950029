import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MyCalendar from "./Calendar";
import AppointmentsTable from "./AppointmentTable";

const Appointments = () => {
  return (
    <div style={{ margin: "28px" }}>
      <Box>
        <Paper elevation={3}>
          <MyCalendar />
          <br />
          <br />
        </Paper>
      </Box>
      <br />
      <Box>
        <Paper elevation={3}>
          <AppointmentsTable />
          <br />
          <br />
        </Paper>
      </Box>
    </div>
  );
};

export default Appointments;
