import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";

const ActionMenu = ({ anchorEl, onClose, currentRow }) => {
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{
          top: "-100px",
          left: "-50px",
        }}
      >
        <MenuItem>
          <span className="mr-5"></span>
          <span className="ms-1">Active</span>
        </MenuItem>

        <MenuItem>
          <span className="mr-5">
            {/* <DeleteIcon style={{ fontSize: "20px", color: "#6B6B6B" }} /> */}
          </span>
          <span className="ms-1">InActive</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionMenu;
