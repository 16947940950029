import React, { useEffect, useState } from "react";

import { Menu, MenuItem } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import PageviewIcon from "@mui/icons-material/Pageview";
import PersonIcon from "@mui/icons-material/Person";
import { toast } from "react-toastify";

const LiveSessionAction = ({
  anchorEl,
  onClose,
  activeTab,
  onEditSession,
  onEditOccurence,
  selectedRow,
  onView,
  onCancel,
  onPlay,
  onViewList,
}) => {
  const [open, setOpen] = useState(null);
  const [isSingle, setIsSingle] = useState(null);
  const [isFlag, setFlag] = useState(true);

  const handleCloseMenu = (edit) => {
    setOpen(null);
    setIsSingle(null);
    onClose(edit);
    setFlag(true);
  };

  const handleOccurrence = () => {
    if (selectedRow?.meeting?.is_recurring) {
      onEditOccurence(true);
      handleCloseMenu("edit");
    } else {
      toast.error("Single session not alowed to open occurence", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleEdit = () => {
    onEditSession(true);
    handleCloseMenu("edit");
  };

  const handleSeries = (value) => {
    onCancel(value);
    handleCloseMenu("edit");
  };

  const convertUtcToLocalTime = (utc) => {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleString();
    const inputDate = localTime.split(",");

    const [month, day, year] = inputDate[0].split("/");
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    return [formattedDate, inputDate];
  };

  const convertUtcToDate = (utc) => {
    const dateInUTC = new Date(utc);

    // Extract year, month, and day components
    const year = dateInUTC.getFullYear();
    const month = String(dateInUTC.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(dateInUTC.getDate()).padStart(2, "0");

    // Return the formatted date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
  };

  const handleStart = () => {
    const startTime = convertUtcToLocalTime(selectedRow.start_time)[1][1];
    const endTime = convertUtcToLocalTime(selectedRow.end_time)[1][1];
    const sessionDate = convertUtcToDate(selectedRow.start_time);

    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const formattedToday = `${year}-${month}-${day}`;

    if (sessionDate === formattedToday) {
      const startDateTime = new Date(`${formattedToday} ${startTime}`);
      const endDateTime = new Date(`${formattedToday} ${endTime}`);

      const adjustedStartTime = new Date(startDateTime.getTime() - 15 * 60000);

      const currentTime = new Date();

      if (currentTime >= adjustedStartTime && currentTime <= endDateTime) {
        window.open(selectedRow.start_url, "_blank");
      } else {
        toast.warning("Current time is outside the allowed session time.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.warning("Today's date does not match the session date.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (selectedRow?.meeting?.is_recurring === false) {
      setFlag(false);
    }
  }, [selectedRow]);

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{
          top: "-100px",
          left: "-100px",
        }}
      >
        {activeTab === 0 && (
          <div>
            <MenuItem onClick={(e) => setOpen(e.target)}>
              <span className="mr-5">
                <EditIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
              </span>
              <span className="ms-1">Edit</span>
            </MenuItem>
            <MenuItem onClick={onView}>
              <span className="mr-5">
                <PageviewIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
              </span>
              <span className="ms-1">View</span>
            </MenuItem>
            <MenuItem onClick={handleStart}>
              <span className="mr-5">
                <PlayArrowIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
              </span>
              <span className="ms-1">Start</span>
            </MenuItem>

            <MenuItem onClick={() => onViewList("Booking")}>
              <span className="mr-5">
                <RemoveRedEyeIcon
                  style={{ fontSize: "20px", color: "#6B6B6B" }}
                />
              </span>
              <span className="ms-1">View Booking</span>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                setIsSingle(e.target);
              }}
            >
              <span className="mr-5">
                <CloseIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
              </span>
              <span className="ms-1">Cancel</span>
            </MenuItem>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <MenuItem onClick={() => onViewList("Booked")}>
              <span className="mr-5">
                <RemoveRedEyeIcon
                  style={{ fontSize: "20px", color: "#6B6B6B" }}
                />
              </span>
              <span className="ms-1">View Booked</span>
            </MenuItem>

            <MenuItem onClick={() => onViewList("Attendance")}>
              <span className="mr-5">
                <PersonIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
              </span>
              <span className="ms-1">View Attendance</span>
            </MenuItem>
            <MenuItem onClick={onView}>
              <span className="mr-5">
                <PageviewIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
              </span>
              <span className="ms-1">View</span>
            </MenuItem>
            {selectedRow?.status === "Completed" && (
              <MenuItem onClick={onPlay}>
                <span className="mr-5">
                  <PlayArrowIcon
                    style={{ fontSize: "20px", color: "#6B6B6B" }}
                  />
                </span>
                <span className="ms-1">Play</span>
              </MenuItem>
            )}
          </div>
        )}
      </Menu>
      <Menu
        id="simple-menu"
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleCloseMenu}
        style={{
          top: "-35px",
          left: "0px",
        }}
      >
        {isFlag && (
          <MenuItem onClick={handleOccurrence}>
            <span className="mr-5">
              <EditIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
            <span className="ms-1">Occurrence</span>
          </MenuItem>
        )}
        {!isFlag && (
          <MenuItem onClick={handleEdit}>
            <span className="mr-5">
              <EditIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
            <span className="ms-1">Series</span>
          </MenuItem>
        )}
      </Menu>
      <Menu
        id="simple-menu"
        anchorEl={isSingle}
        open={Boolean(isSingle)}
        onClose={handleCloseMenu}
        style={{
          top: "-35px",
          left: "0px",
        }}
      >
        <MenuItem
          onClick={() => {
            handleSeries(1);
          }}
        >
          <span className="mr-5">
            <CloseIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Single series</span>
        </MenuItem>

        {isFlag && (
          <MenuItem
            onClick={() => {
              handleSeries(2);
            }}
          >
            <span className="mr-5">
              <CloseIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
            <span className="ms-1">All series</span>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default LiveSessionAction;
