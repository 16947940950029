import React from 'react'
import TextEditor from './TextEditor'

const Index = ({setTarget_calorie,target_calorie,showplanNotes}) => {
  return (
    <div>
      <TextEditor setTarget_calorie={setTarget_calorie} target_calorie={target_calorie} showplanNotes={showplanNotes}/>
    </div>
  )
}

export default Index
