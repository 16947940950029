import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

import "./MealPlate.css";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const MealPlate = () => {
  // const clientId = useSelector((state) => state.leadId);
  const { id } = useParams();

  const [foodTaken, setFoodTaken] = useState([]);

  let currentDate = new Date().toISOString().slice(0, 10);

  const headers = [
    "Date",
    "Early Morning",
    "Breakfast",
    "Mid Morning",
    "Lunch",
    "Evening Snacks",
    "Dinner",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosFetch({
          url: `/v3/getNutritionActivityByLeadIdAndDateCRM?leadId=${id}&activity_date=${currentDate}`,
          method: "get",
        });

        setFoodTaken(response.data.body.food_taken);
      } catch (error) {
        // Handle any errors here
      }
    };

    fetchData();
  }, []);

  // const today = new Date();
  // const nextMonth = new Date(
  //   today.getFullYear(),
  //   today.getMonth() + 1,
  //   today.getDate()
  // );

  // const startDate = today.toISOString().slice(0, 10);
  // const endDate = nextMonth.toISOString().slice(0, 10);

  // const [selectDate, setSelectDate] = useState([]);

  // useEffect(() => {
  //   if (selectDate.length > 0) {
  //     const selectedDate = selectDate.map((data) => {
  //       if (data) {
  //         const date = new Date(data.$d);
  //         const formattedDate = date
  //           .toLocaleDateString("en-US", {
  //             year: "numeric",
  //             month: "2-digit",
  //             day: "2-digit",
  //           })
  //           .replace(/\//g, "-");
  //         return formattedDate;
  //       }
  //     });

  //     console.log(selectedDate);
  //   }
  // }, [selectDate]);

  return (
    <div>
      {/* <div className="select-date">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={[
              "DateRangePicker",
              "DateRangePicker",
              "DateRangePicker",
            ]}
          >
            
            <DemoItem label="2 calendars" component="DateRangePicker">
              <DateRangePicker
                calendars={2}
                onChange={(value) => {
                  setSelectDate(value);
                }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </div> */}
      <TableContainer
        style={{
          width: "1500px",
          margin: "0 auto",
          marginRight: "50px",
          border: "1px solid #ccc",
          borderRadius: "6px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  style={{
                    fontWeight: "600",
                    border: "1px solid #ccc",
                    width: "300px",
                  }}
                >
                  <div style={{ width: index === 0 ? "150px" : "300px" }}>
                    {header}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {foodTaken.length === 0 ? (
                <TableCell colSpan={headers.length}>
                  <p style={{ margin: "0 550px", width: "fit-content" }}>
                    No Records Found ...
                  </p>
                </TableCell>
              ) : (
                <>
                  <TableCell
                    style={{ border: "1px solid #ccc", width: "300px" }}
                  >
                    {currentDate}
                  </TableCell>
                  {headers.slice(1).map((header, index) => {
                    const matchingFoodTaken = foodTaken.find(
                      (item) => item.mealName === header
                    );

                    return (
                      <TableCell
                        key={index}
                        style={{ border: "1px solid #ccc", width: "300px" }}
                      >
                        {matchingFoodTaken ? (
                          <>
                            {matchingFoodTaken.foodList.map(
                              (item, itemIndex) => (
                                <p key={itemIndex}>{item.food_item_name}</p>
                              )
                            )}
                            <div className="img-con">
                              {matchingFoodTaken.images &&
                                matchingFoodTaken.images.map(
                                  (image, imageIndex) => (
                                    <img
                                      key={imageIndex}
                                      src={image.url}
                                      alt={`Meal ${index + 1}`}
                                    />
                                  )
                                )}
                            </div>
                          </>
                        ) : null}
                      </TableCell>
                    );
                  })}
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MealPlate;
