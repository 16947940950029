import { useEffect, useState } from "react";
import "./TodayTask.css";
import { axiosFetch } from "../../Utlis/AxiosFetch";

const TodayTask = () => {
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;
  const [taskdata, setTaskData] = useState([]);

  const getTaskData = async () => {
    const response = await axiosFetch({
      url: `/getAllTasksForDashboard/${userId}`,
      method: "get",
    }).then((res) => res.data);

    if (response.status === 200) {
      setTaskData(response);
    }
  };

  useEffect(() => {
    getTaskData();
  }, []);

  return (
    <div className="todayTask">
      <h4>Today's Task</h4>

      <table className="task-table">
        <thead className="task-table_header">
          <tr>
            <th> Name</th>
            <th> Overdue</th>
            <th> Open</th>
            <th> Completed</th>
          </tr>
        </thead>
        <tbody className="task-table_body">
          {taskdata?.map((row, index) => (
            <tr key={index}>
              <td> {row.type}</td>
              <td> {row.dueTask}</td>
              <td> {row.openTask}</td>
              <td> {row.completedTask}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TodayTask;
