import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import { ATLAS_URI } from '../../Constants'
import stateContext from '../../context/StateContext'
function ListAgents() {

    const contextState = useContext(stateContext)
    const updateEditDetails = contextState.updateEditDetails
    const { configToken } = contextState.state

    const [state, setState] = useState({
        tableBodyList: [],
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        },
    })

    const mounted = React.useRef(true);
    useEffect(() => () => { mounted.current = false; }, []);

    useEffect(() => {
        //Get All Agents
        axios.get(`${ATLAS_URI}/getAgents`, configToken)
            .then(response => {
                const responseData = response.data;
                if (typeof responseData !== 'undefined' && mounted.current) {
                    setState({ ...state, tableBodyList: responseData })
                }
            }).catch(err => console.log(err))
    }, [])



    function editRecord(e) {
        const temp = e.target.parentElement.parentElement.id;
        updateEditDetails({
            id: temp, editingActivated: true, redirectFrom: "/Agents/listAgents", redirectTo: "/Agents/addAgent"
        })

    }

    function openDialog(e) {
        const newDialogInfo = {
            isOpened: true,
            delID: e.target.parentElement.parentElement.id,
            text: "Are you sure you want to delete this Agent?",
            type: "Confirm"
        }
        setState({ ...state, dialogInfo: newDialogInfo })
    }

    function deleteFromTable(e) {
        const delID = state.dialogInfo.delID;

        axios.delete(`${ATLAS_URI}/deleteAgent/` + delID, configToken)
            .then(() => {
                const newTableBodyList = state.tableBodyList.filter(data => data.id.toString() !== delID);
                setState({
                    ...state,
                    tableBodyList: newTableBodyList,
                    dialogInfo: { isOpened: false, text: "", delID: "" }
                })
            })
            .catch(err => alert(err))

    }

    return (
        <div className="content">
            <div className="row">

                {state.dialogInfo.isOpened && <Dialog
                    onFalse={(e) => setState({ ...state, dialogInfo: { isOpened: false, text: "" } })}
                    onTrue={(e) => deleteFromTable(e)}
                    dialogInfo={state.dialogInfo}
                />}

                <div className="col-md-12">
                    <div className="box box-primary">
                        <BoxHeader title="Agents List" />
                        <div className="box-body">
                            <DataTable
                                tableHeader={["id", "Name", "Phone", "Email", "Deals In"]}
                                tableBody={state.tableBodyList}
                                searchField="Name"
                                customAction={[
                                    { title: "Update", icon: "fas fa-edit", redirectTo: "/Agents/addAgent", onClickEvent: editRecord },
                                    { title: "Delete", icon: "fas fa-times text-red", onClickEvent: openDialog }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default ListAgents