import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Box,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export const RulesList = ({
  index,
  Controller,
  control,
  dropDownlists,
  handleConditionTwoChange,
  form,
  handleRemoveTask,
  allocatedTo,
}) => {
  return (
    <>
      <Grid item md={2}>
        <Typography
          sx={{
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Task Name
        </Typography>
        <Controller
          name={`taskList.${allocatedTo}.${index}.taskName`}
          rules={{ required: "required" }}
          control={control}
          defaultValue={""}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small">
              <Select
                sx={{ fontSize: "16px" }}
                {...field}
                error={!!error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "-50px",
                      marginLeft: "-50px",
                      width: "auto",
                    },
                  },
                }}
              >
                <MenuItem value="select" disabled>
                  Select
                </MenuItem>

                {dropDownlists.taskName.map((task) => (
                  <MenuItem
                    value={task.value}
                    key={task.id}
                    sx={{ fontSize: "16px" }}
                  >
                    {task.value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item md={2}>
        <Typography
          sx={{
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Allocated to
        </Typography>
        <Controller
          name={`taskList.${allocatedTo}.${index}.allocatedTo`}
          rules={{ required: "required" }}
          control={control}
          defaultValue={""}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small">
              <Select
                sx={{ fontSize: "16px" }}
                {...field}
                error={!!error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "-50px",
                      marginLeft: "-100px",
                      width: "auto",
                    },
                  },
                }}
                disabled
              >
                <MenuItem value={"Fitness"} sx={{ fontSize: "16px" }}>
                  Fitness
                </MenuItem>
                <MenuItem value={"Dietician"} sx={{ fontSize: "16px" }}>
                  Dietician
                </MenuItem>
              </Select>
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item md={2}>
        <Typography
          sx={{
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Condition 1
        </Typography>
        <Controller
          name={`taskList.${allocatedTo}.${index}.conditionOne`}
          rules={{ required: "required" }}
          control={control}
          defaultValue={""}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small">
              <Select
                sx={{ fontSize: "16px" }}
                {...field}
                error={!!error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "-50px",
                      marginLeft: "-150px",
                      width: "auto",
                    },
                  },
                }}
              >
                <MenuItem value="select" disabled>
                  Select
                </MenuItem>

                {dropDownlists.conditionOne.map((task) => (
                  <MenuItem
                    value={task.value}
                    key={task.id}
                    sx={{ fontSize: "16px" }}
                  >
                    {task.value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item md={1}>
        <Typography
          sx={{
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Condition 2
        </Typography>
        <Controller
          name={`taskList.${allocatedTo}.${index}.conditionTwo`}
          rules={{ required: "required" }}
          control={control}
          defaultValue={""}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small">
              <Select
                sx={{ fontSize: "16px" }}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleConditionTwoChange(
                    e.target.value,
                    index,
                    form.getValues(),
                    allocatedTo
                  );
                }}
                error={!!error}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "-50px",
                      marginLeft: "-200px",
                      width: "auto",
                    },
                  },
                }}
              >
                <MenuItem value="select" disabled>
                  Select
                </MenuItem>

                {dropDownlists.conditionTwo.map((task) => (
                  <MenuItem
                    value={task.value}
                    key={task.id}
                    sx={{ fontSize: "16px" }}
                  >
                    {task.value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item md={1}>
        <Typography
          sx={{
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Day
        </Typography>
        <Controller
          name={`taskList.${allocatedTo}.${index}.day`}
          rules={{ required: "required" }}
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                type="number"
                size="small"
                disabled
                error={!!error}
                // Add any other TextField props here
              />
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            </>
          )}
        />
      </Grid>
      <Grid item md={1}>
        <Typography
          sx={{
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Month
        </Typography>
        <Controller
          name={`taskList.${allocatedTo}.${index}.month`}
          rules={{ required: "required" }}
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                {...field}
                type="number"
                variant="outlined"
                fullWidth
                size="small"
                disabled
                error={!!error}

                // Add any other TextField props here
              />
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            </>
          )}
        />
      </Grid>
      <Grid item md={3}>
        <Typography
          sx={{
            fontWeight: 600,
            marginBottom: "10px",
          }}
        >
          Description
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <div style={{ width: "100%" }}>
            <Controller
              name={`taskList.${allocatedTo}.${index}.description`}
              rules={{ required: "required" }}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    {...field}
                    variant="outlined"
                    size="small"
                    error={!!error}
                    sx={{ width: "90%" }}
                    // Add any other TextField props here
                  />
                  <FormHelperText error={true}>{error?.message}</FormHelperText>
                </>
              )}
            />
          </div>
          <Typography
            sx={{
              marginTop: "7px",
              fontWeight: 600,
              marginBottom: "10px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CloseIcon onClick={() => handleRemoveTask(index)} />
          </Typography>
        </Box>
      </Grid>
    </>
  );
};
