import { useState, useEffect, useContext, useRef } from "react";
import {
  Card,
  Collapse,
  IconButton,
  Typography,
  CardContent,
  Grid,
  Select,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Stack,
  Button,
  MenuItem,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextareaAutosize } from "@mui/base";
import dayjs from "dayjs";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useHistory, useParams } from "react-router-dom";
import { PlivoContext } from "../../../context/PlivoContext";
import stateContext from "../../../context/StateContext";
import { toast } from "react-toastify";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useSelector, useDispatch } from "react-redux";
import { resetid, updateTriggerEndCall } from "../../store/Call/CallItem";

import "./CallDetailsForm.css";

const leadCategory = [
  { id: 1, name: "First Call" },
  { id: 3, name: "Follow up call" },
];

const InitalDispositionList = [
  { id: 1, name: "Connected" },
  { id: 2, name: "Not Connected" },
];

const CallDetailsForm = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  let routerParams = useParams();
  const callId = useSelector((state) => state.call.callId);
  const telecmiStatus = useSelector((state) => state.call.callType);
  const callPlatForm = useSelector((state) => state.call.callPlatForm);
  const triggerEndCall = useSelector((state) => state.call.triggerEndCall);
  const callType = useSelector((state) => state.call.id);
  const contextState = useContext(stateContext);

  const [endCall, setEndCall] = useState(false);

  const isClient =
    window.location.pathname.includes("Clients/listClients") ||
    window.location.pathname.includes("Clients/clientDetail");

  const id = JSON.parse(window.localStorage.getItem("operator")).id;
  const { plivo, duration, currentCallStatus, handleReset } =
    useContext(PlivoContext);

  const [expand, setExpand] = useState(true);

  const [current_pathname, setCurrent_pathname] = useState(false);

  let [durationFlag, setDurationFlag] = useState(false);

  let [categoryList, setCategoryList] = useState(leadCategory);
  let [typeList, setTypeList] = useState([]);
  let [stageList, setStageList] = useState([]);
  let [dispositionList, setDispositionList] = useState(InitalDispositionList);
  let [subDispositionList, setSubDispositionList] = useState([]);
  let [grandDispositionList, setGrandDispositionList] = useState([]);

  let [dateTime, setDateTime] = useState(dayjs(new Date()));

  //dayjs(new Date()) this is help to add default date
  let [nextFollowUpDate, setNextFollowUpDate] = useState();
  let [callStatus, setCallStatus] = useState(currentCallStatus);
  let [call_duration, setCall_Duration] = useState(duration);
  let [notes, setNotes] = useState("");

  let [category, setCategory] = useState("");
  let [type, setType] = useState("");
  let [stage, setStage] = useState("");
  let [disposition, setDisposition] = useState("");
  let [subDisposition, setSubDisposition] = useState("");
  let [grandDisposition, setGrandDisposition] = useState("");

  let [isErrDateTime, setIsErrDateTime] = useState(false);
  let [isErrCategory, setIsErrCategory] = useState(false);
  let [isErrType, setIsErrType] = useState(false);
  let [isErrCallStatus, setIsErrCallStatus] = useState(false);
  let [isErrDuration, setIsErrDuration] = useState(false);

  let [isErrStage, setIsErrStage] = useState(false);
  let [isErrDis, setIsErrDis] = useState(false);
  let [isErrSubDis, setIsErrSubDis] = useState(false);
  let [isErrGrandDis, setIsErrGrandDis] = useState(false);

  let [isErrNotes, setIsErrNotes] = useState(false);

  const [isEndCall, setIsEndCall] = useState(true);
  const [someStage, setSomeStage] = useState(false);
  const [isLost, setLost] = useState(false);

  const [categoryDisabled, setCategoryDisabled] = useState(false);
  const [client_path, setClientPath] = useState(false);

  const notallow = useRef("");

  useEffect(() => {
    if (callStatus === "answered" || callStatus === "ringing") {
      props.onSetIsBackDrop(true);
    } else {
      props.onSetIsBackDrop(false);
    }
  }, [callStatus]);

  useEffect(() => {
    setCallStatus(telecmiStatus);

    if (telecmiStatus === "ended") {
      setEndCall(true);
      setIsEndCall(true);
    }
  }, [telecmiStatus]);

  useEffect(() => {
    if (current_pathname) {
      // setCategory(5);
      // setCategoryDisabled(true);
    } else {
      setCategoryDisabled(false);
    }
  }, [current_pathname]);

  useEffect(() => {
    if (
      window.location.pathname.includes("Leads") ||
      window.location.pathname.includes("lead")
    ) {
      setCurrent_pathname(true);
      setClientPath(true);
      setSomeStage(false);
    } else {
      setCurrent_pathname(false);
      setClientPath(false);
    }
  }, []);

  useEffect(() => {
    if (callType) {
      setCategory(callType);
      setDurationFlag(true);
      setCategoryDisabled(true);

      if (callPlatForm === "telecmi") {
        setSomeStage(false);
      }

      // if (
      //   !window.location.pathname.includes("Leads") ||
      //   !window.location.pathname.includes("lead")
      // ) {
      //   setSomeStage(false);
      // }
    }
  }, [callType]);

  useEffect(() => {
    return () => {
      setDurationFlag(false);
      dispatch(resetid());
    };
  }, []);

  useEffect(() => {
    if (props.callFlag) {
      setIsEndCall(false);
      setEndCall(false);
    }

    if (props.endCall) {
      setIsEndCall(true);
      setEndCall(true);
    }
  }, [props.endCall, props.callFlag]);

  const handleEndCall = (falg) => {
    if (callPlatForm === "plivo") {
      plivo.client.hangup();
      props.handleEndCall();
      if (falg !== "flag") {
        toastMessage();
      }
      return;
    } else if (callPlatForm === "telecmi") {
      triggerEndCall.terminate();
      toastMessage();
    }
  };

  function toastMessage() {
    toast.warn("Please fill and save the form otherwise you lost your data", {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  const dateTimeOnChange = (newValue) => {
    setDateTime(newValue);
    if (newValue === null) {
      setIsErrDateTime(true);
    } else {
      setIsErrDateTime(false);
    }
  };

  const categoryOnChange = (e) => {
    setCategory(e.target.value);
    setIsErrCategory(false);
    setSomeStage(false);
    setSubDisposition("");
    setDisposition("");
  };

  const typeOnChange = (e) => {
    setType(e.target.value);
    setIsErrType(false);
  };

  const stageOnChange = (e) => {
    setStage(e.target.value);
    if (e.target.value === 7 || e.target.value === 5) {
      setSomeStage(true);
    } else if (e.target.value === 3) {
      setLost(true);
      setSomeStage(false);
    }
    // else if (e.target.value === 10) {
    //   setSomeStage(true);
    // }
    else {
      setSomeStage(false);
      setLost(false);
    }
    setSubDisposition("");
    setDisposition("");
    setGrandDisposition("");

    setIsErrStage(false);
    if (e.target.value != 5 || e.target.value != 7 || e.target.value != 10) {
      setIsErrDis(false);
      setIsErrSubDis(false);
      // setIsErrGrandDis(false);
    }

    if (e.target.value === 10 || e.target.value === 11) {
      const filterDisposition = dispositionList.filter((item) => item.id === 2);
      setDispositionList(filterDisposition);
    } else {
      setDispositionList(InitalDispositionList);
    }
  };

  const dispositionOnChange = async (e) => {
    setDisposition(e.target.value);
    // getLeadSubDisposition(e.target.value);

    if (isClient) {
      setIsErrDis(false);
      if (e.target.value === 1) setIsErrSubDis(false);
    }

    const value = e.target.value;

    let categoryValue = category;
    if (value === 2) {
      categoryValue = "";
    }

    if (!client_path) {
      let response = await axiosFetch({
        url: `/v3/getClientSubDisposition?clientStatus=0&dispositionId=${value}&interactionCategoryId=${categoryValue}`,
        method: "get",
      });

      setSubDispositionList(response.data);
    } else {
      getLeadSubDisposition(e.target.value);
    }
  };

  const subDispositionOnChange = (e) => {
    setSubDisposition(e.target.value);
    getGrandDisposition(disposition, e.target.value);
  };

  const grandDispositionOnChange = (e) => {
    setGrandDisposition(e.target.value);
  };

  const nextFollowupOnChange = (newValue) => {
    setNextFollowUpDate(newValue);
  };

  const callStatusOnChange = (e) => {
    setCallStatus(e.target.value);
    setIsErrCallStatus(false);
  };

  const durationOnChange = (newValue) => {
    setCall_Duration(newValue.target.value);
    setIsErrDuration(false);
  };

  const notesOnChange = (e) => {
    setNotes(e.target.value);
    // if (e.target.value.length >= 100) {
    //   setIsErrNotes(false);
    // }
  };

  const toggleCollapse = () => {
    setExpand(!expand);
  };

  const getCategoryList = () => {
    if (
      window.location.pathname.includes("Leads") ||
      window.location.pathname.includes("lead")
    ) {
      return;
    }

    axiosFetch({
      url: "/interactionCategories",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          let response = res.data;

          // if (!window.location.pathname.includes("lead")) {
          //   response = response.filter((data) => data.id !== 5);
          // }

          setCategoryList(response);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Interal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTypesList = () => {
    axiosFetch({
      url: "/interactionTypes",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setTypeList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Interal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getStageList = () => {
    axiosFetch({
      url: "/getLeadStages",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setStageList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Interal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const getDisposition = () => {
  //   axiosFetch({
  //     url: "/getLeadDispositions",
  //     method: "get",
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         if (parseInt(stage) === 7 || parseInt(stage) === 5) {
  //           let disposition_id = stageList.filter((list) => list.id == stage)[0]
  //             .vmax_lead_disposition_id;
  //           setDispositionList(
  //             res.data.filter((list) => list.id == disposition_id)
  //           );
  //         } else {
  //           setDispositionList(res.data);
  //         }
  //       } else if (res.status === 401) {
  //         history.push("/");
  //       } else if (res.status === 400) {
  //         toast.error("Bad Request", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       } else if (res.status === 500) {
  //         toast.error("Interal Server Error", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const getLeadSubDisposition = (id) => {
    let url;
    if (id == 2) {
      url = `getLeadSubDispositionsByDispositionId/${id}`;
    } else {
      url = `getLeadSubDispositionsByDispositionIdAndStageId/${id}/${stage}`;
    }

    axiosFetch({
      url,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setSubDispositionList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Interal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const getGrandDisposition = (disId, subDisId) => {
    axiosFetch({
      url: `/getLeadSubNotInterestedListByDispositionIdAndSubDispositionId/${disId}/${subDisId}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setGrandDispositionList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const clearAll = () => {
    // setCategory("");
    // setType("");
    setStage("");
    setDisposition("");
    setSubDisposition("");
    setGrandDisposition("");
    // setCallStatus("");
    setCall_Duration("");
    setNotes("");
  };

  let currentProfileContactNumber = JSON.parse(
    localStorage.getItem("currentProfile")
  );

  const enterLog = () => {
    if (call_duration === null) {
      setIsErrDuration(true);
    }
    if (category === "") {
      setIsErrCategory(true);
    }

    let requestObj;
    let isValid = true;
    function validateField(field, setter, name) {
      if (field === "") {
        setter(true);
        isValid = false;
      } else {
        setter(false);
      }

      if (isClient) {
        if (name === "notes" && field.length <= 100) {
          setter(true);
          isValid = false;
        }
      }

      // if (
      //   window.location.pathname.includes("Clients/listClients") ||
      //   window.location.pathname.includes("Clients/clientDetail")
      // ) {
      //   if (name === "notes" && field.length <= 100) {
      //     setter(true);
      //     isValid = false;
      //   }
      // }

      // if (name === "notes" && field.length <= 100) {
      //   setter(true);
      //   isValid = false;
      // }
    }

    const fieldsToValidate = [
      { name: "DateTime", field: dateTime, setter: setIsErrDateTime },
      { name: "Category", field: category, setter: setIsErrCategory },
      { name: "Stage", field: stage, setter: setIsErrStage },
      { name: "Disposition", field: disposition, setter: setIsErrDis },
      { name: "SubDisposition", field: subDisposition, setter: setIsErrSubDis },
      { name: "Type", field: type, setter: setIsErrType },
      { name: "notes", field: notes, setter: setIsErrNotes },
      {
        name: "GrandDisposition",
        field: grandDisposition,
        setter: setIsErrGrandDis,
      },
    ];
    for (const { field, setter, name } of fieldsToValidate) {
      if (isClient) {
        if (name === "Stage") continue;
        if (disposition === 1) {
          setIsErrSubDis(false);
          setIsErrDis(false);
        }
        if (disposition === 1 && name === "SubDisposition") continue;
      }

      if (stage === 5 || stage === 7) {
        if (name === "Disposition" || name === "SubDisposition") {
          continue;
        }
      }

      if (disposition === 2) {
        if (name === "notes") {
          setIsErrNotes(false);
          continue;
        }
      }

      if (!isClient && subDisposition === 4) {
        if (name === "notes") {
          setIsErrNotes(false);
          continue;
        }
      }

      if (stage === 2 && disposition === 1) {
        if (name === "SubDisposition") {
          setIsErrSubDis(false);
          continue;
        }
      }

      if (stage === 3 && disposition === 2) {
        if (name === "GrandDisposition") {
          setIsErrGrandDis(false);
          continue;
        }
      }

      if (stage !== 3 && name === "GrandDisposition") {
        continue;
      }

      validateField(field, setter, name);
    }

    if (!isValid) {
      toast.error("Select all the Mandatory Field", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    // if (
    //   window.location.pathname.includes("Clients/listClients") ||
    //   window.location.pathname.includes("Clients/clientDetail")
    // ) {
    //   if (disposition === 2) {
    //     setIsErrNotes(false);
    //   } else if (disposition === 1 && subDisposition === 4) {
    //     setIsErrNotes(false);
    //   } else if ((!disposition || disposition === 1) && notes?.length <= 100) {
    //     setIsErrNotes(true);
    //     return;
    //   }
    // } else {
    //   if ((!disposition || disposition === 1) && !notes) {
    //     setIsErrNotes(true);
    //     return;
    //   }
    // }

    // if ((!disposition || disposition === 1) && notes?.length <= 100) {
    //   setIsErrNotes(true);
    //   return;
    // }

    if (
      dateTime !== null &&
      category !== "" &&
      type !== "" &&
      // callStatus !== "" &&
      call_duration !== null
    ) {
      function formatDate(inputDate) {
        const inputString = String(inputDate);

        const dateRegex = /^([A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4})/;
        const timeRegex = /(\d{2}:\d{2}:\d{2})/;
        const dateMatch = inputString.match(dateRegex);
        const timeMatch = inputString.match(timeRegex);

        if (dateMatch && timeMatch) {
          const rawDate = dateMatch[1]; // Mon Aug 28 2023
          const time = timeMatch[1]; // 11:15:44

          // Convert raw date string to Date object
          const formattedDate = new Date(rawDate);

          // Extract individual date components
          const month = formattedDate.getMonth() + 1; // Months are zero-based
          const day = formattedDate.getDate();
          const year = formattedDate.getFullYear();

          // Create formatted date string
          const formattedDateString = `${month}-${day}-${year}`;

          // Format the time
          const [hours, minutes, seconds] = time.split(":");
          const parsedHours = parseInt(hours, 10);
          const amOrPm = parsedHours >= 12 ? "PM" : "AM";
          const hours12 = parsedHours % 12 === 0 ? 12 : parsedHours % 12;
          const formattedTime = `${hours12}:${minutes} ${amOrPm}`;

          return `${formattedDateString} ${formattedTime}`;
        }
      }

      requestObj = {
        userId: id,
        leadId: parseInt(routerParams?.id),
        callUUID: props.endCallId,
        interaction_category: !!category ? category : null,

        interaction_type: !!type ? type : null,

        interaction_on: !!dateTime ? dateTime.$d : null,

        lead_stage: !!stage ? stage : null,

        lead_disposition: !!disposition ? disposition : null,

        grand_disposition: !!grandDisposition ? grandDisposition : null,

        lead_sub_disposition: !!subDisposition ? subDisposition : null,

        next_followup_on: !!nextFollowUpDate ? nextFollowUpDate.$d : null,
        reviewNote: notes !== "" ? notes : null,
        callStatus: callStatus !== "" ? callStatus : null,
        to: currentProfileContactNumber.contact_number,
        telephony: "Manual",

        // callDuration:
        //   call_duration !== ""
        //     ? new Date(call_duration).toTimeString().split(" ")[0]
        //     : null,
      };

      if (!props.callFlag) {
        requestObj.callDuration = call_duration !== "" ? call_duration : null;
      }

      if (callPlatForm === "telecmi") {
        requestObj.callUUID = callId;
        requestObj.screen = "lead";
      }

      // if (
      //   window.location.pathname.includes("Leads") ||
      //   window.location.pathname.includes("lead")
      // ) {
      //   requestObj.callUUID = callId;
      //   requestObj.screen = "lead";
      // }

      if (!client_path) {
        delete requestObj.lead_stage;
        delete requestObj.grand_disposition;
        delete requestObj.lead_sub_disposition;
        requestObj.client_sub_disposition = !!subDisposition
          ? subDisposition
          : null;
      }

      axiosFetch({
        url: "/createInteraction",
        method: "post",
        requestConfig: requestObj,
      })
        .then((res) => {
          if (res.status === 200) {
            clearAll();
            setCallStatus("");
            setCall_Duration("");
            handleReset();

            toast.success("Interaction Saved!", {
              position: toast.POSITION.TOP_CENTER,
            });
            props.onMakeFalse();
            props.getInteractionList();
          } else if (res.status === 401) {
            history.push("/");
          } else if (res.status === 400) {
            toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
          } else if (res.status === 500) {
            toast.error("Internal Server Error", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    setEndCall(false);
    dispatch(resetid());
  };

  useEffect(() => {
    getCategoryList();
    getTypesList();
    // getDisposition();
    getStageList();
    getLeadSubDisposition();
  }, []);

  useEffect(() => {
    if (props.callFlag) {
      setCallStatus(currentCallStatus);
      setCall_Duration(duration);

      if (currentCallStatus === "ended") {
        handleEndCall("flag");
      }
    } else {
      setCallStatus("");
      setCall_Duration("");
    }
  }, [currentCallStatus, duration]);

  useEffect(() => {
    if (typeList) {
      setType(2);
    }
  }, [typeList]);

  return (
    <>
      <Card
        sx={{ width: "-webkit-fill-available !important" }}
        ref={notallow}
        className="test"
      >
        <CardContent
          sx={{ fontSize: "16px", fontWeight: "600", fontFamily: "Poppins" }}
        >
          Select Criteria
          <IconButton
            disableRipple
            onClick={toggleCollapse}
            aria-expanded={expand}
            aria-label="Show more"
            sx={{ marginLeft: "84pc" }}
          >
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </CardContent>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container xs={12}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Date & Time<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Category<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Type<span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    sx={{
                      width: "370px !important",
                      height: "46px",
                      borderRadius: "6px",
                      border: "2px",
                    }}
                    value={dateTime}
                    onChange={dateTimeOnChange}
                  />
                </LocalizationProvider>
                <br />
                <FormHelperText style={{ color: "red", marginTop: "9px" }}>
                  {isErrDateTime ? "(Required)" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <Select
                    sx={{
                      width: "370px",
                      height: "46px",
                      borderRadius: "6px",
                      border: "2px",
                    }}
                    placeholder="Select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "center",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "center",
                        horizontal: "center",
                      },
                    }}
                    // disabled={categoryDisabled}
                    value={category}
                    onChange={categoryOnChange}
                  >
                    {categoryList.map((category) => (
                      <MenuItem value={category.id} key={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red", marginTop: "12px" }}>
                  {isErrCategory ? "(Required)" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <Select
                    sx={{
                      width: "370px",
                      height: "46px",
                      borderRadius: "6px",
                      border: "2px",
                    }}
                    placeholder="Select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "center",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "center",
                        horizontal: "center",
                      },
                    }}
                    value={type}
                    onChange={typeOnChange}
                  >
                    {typeList.map((type) => (
                      <MenuItem value={type.id} key={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>
                  {isErrType ? "(Required)" : ""}
                </FormHelperText>
              </Grid>
              <br />
              <br />
              <br />
              {client_path && (
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Stage
                  </Typography>
                  <FormControl>
                    <Select
                      sx={{
                        width: "370px",
                        height: "46px",
                        borderRadius: "6px",
                        border: "2px",
                      }}
                      placeholder="Select"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "center",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "center",
                          horizontal: "center",
                        },
                      }}
                      value={stage}
                      onChange={stageOnChange}
                    >
                      {stageList.map((stage) => (
                        <MenuItem value={stage.id} key={stage.id}>
                          {stage.leadStage}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText style={{ color: "red" }}>
                    {isErrStage ? "(Required)" : ""}
                  </FormHelperText>
                </Grid>
              )}
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Disposition
                </Typography>
                <FormControl>
                  <Select
                    sx={{
                      width: "370px",
                      height: "46px",
                      borderRadius: "6px",
                      border: "2px",
                    }}
                    placeholder="Select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "center",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "center",
                        horizontal: "center",
                      },
                    }}
                    disabled={someStage}
                    value={disposition}
                    onChange={dispositionOnChange}
                  >
                    {dispositionList.map((disposition) => (
                      <MenuItem key={disposition.id} value={disposition.id}>
                        {disposition.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>
                  {isErrDis ? "(Required)" : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Sub Disposition
                </Typography>
                <FormControl>
                  <Select
                    sx={{
                      width: "370px",
                      height: "46px",
                      borderRadius: "6px",
                      border: "2px",
                    }}
                    placeholder="Select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "center",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "center",
                        horizontal: "center",
                      },
                    }}
                    disabled={someStage}
                    value={subDisposition}
                    onChange={subDispositionOnChange}
                  >
                    {client_path
                      ? subDispositionList.map((subDisposition) => (
                          <MenuItem
                            value={subDisposition.id}
                            key={subDisposition.id}
                          >
                            {subDisposition.leadSubDisposition}
                          </MenuItem>
                        ))
                      : subDispositionList.map((subDisposition) => (
                          <MenuItem
                            value={subDisposition.id}
                            key={subDisposition.id}
                          >
                            {subDisposition.display_value}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>
                  {isErrSubDis ? "(Required)" : ""}
                </FormHelperText>
              </Grid>

              <br />
              <br />
              <br />
              <br />

              {client_path && (
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Grand Disposition
                  </Typography>
                  <FormControl>
                    <Select
                      sx={{
                        width: "370px",
                        height: "46px",
                        borderRadius: "6px",
                        border: "2px",
                      }}
                      placeholder="Select"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "center",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "center",
                          horizontal: "center",
                        },
                      }}
                      disabled={!isLost}
                      value={grandDisposition}
                      onChange={grandDispositionOnChange}
                    >
                      {grandDispositionList.map((grandDisposition) => {
                        return (
                          <MenuItem
                            value={grandDisposition.id}
                            key={grandDisposition.id}
                          >
                            {grandDisposition.leadSubNotInterested}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormHelperText style={{ color: "red" }}>
                    {isErrGrandDis ? "(Required)" : ""}
                  </FormHelperText>
                </Grid>
              )}
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Next Follow Up
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    sx={{
                      width: "370px",
                      height: "46px",
                    }}
                    value={nextFollowUpDate}
                    onChange={nextFollowupOnChange}
                    minDate={dayjs().startOf("day")}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Call Status<span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl sx={{ width: "370px" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      width: "370px",
                      height: "46px",
                      borderRadius: "6px",
                      border: "2px",
                    }}
                    disabled
                    value={callStatus}
                    onChange={callStatusOnChange}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {isErrCallStatus ? "(Required)" : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <br />
              <br />
              <br />
              <br />
              <Grid item xs={5}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Duration<span style={{ color: "red" }}>*</span>
                </Typography>
                {durationFlag ? (
                  <input
                    style={{
                      width: "370px",
                      height: "46px",
                      borderRadius: "6px",
                      padding: "10px",
                      border: "1px solid #C4C4C4",
                    }}
                    type="time"
                    step="1"
                    disabled
                    placeholder="duration"
                    onChange={durationOnChange}
                    value={call_duration}
                  />
                ) : (
                  <input
                    style={{
                      width: "370px",
                      height: "46px",
                      borderRadius: "6px",
                      padding: "10px",
                      border: "1px solid #C4C4C4",
                    }}
                    type="time"
                    step="1"
                    placeholder="duration"
                    onChange={durationOnChange}
                    value={call_duration}
                  />
                )}

                <FormHelperText style={{ color: "red" }}>
                  {isErrDuration ? "(Required)" : ""}
                </FormHelperText>
              </Grid>
              <br />
              <br />
              <br />
              <br />

              <Grid item xs={12}>
                <Typography
                  sx={{
                    width: "370px",
                    height: "46px",
                    borderRadius: "6px",
                    border: "2px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Notes{isClient && "(100 characters )"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  style={{ width: "80%", height: "88px" }}
                  aria-label="empty textarea"
                  placeholder="Enter Here"
                  value={notes}
                  onChange={notesOnChange}
                />
              </Grid>
              <FormHelperText style={{ color: "red", marginTop: "9px" }}>
                {isErrNotes ? "(Required)" : ""}
              </FormHelperText>
            </Grid>
            <br />
            <Stack
              direction="row"
              spacing={2}
              sx={{ float: "right", marginRight: "37px", marginBottom: "30px" }}
            >
              {props.callFlag ? (
                <Button
                  disabled={endCall}
                  variant="outlined"
                  sx={{
                    color: "black",
                    backgroundColor: "white",
                    textTransform: "capitalize",
                    borderRadius: "6px",
                    width: "118px",
                    height: "46px",
                    borderColor: "black",
                    cursor: "pointer",
                    "&:hover": {
                      color: "black",
                      borderColor: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onClick={handleEndCall}
                >
                  End Call
                </Button>
              ) : (
                ""
              )}
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "black",
                  textTransform: "capitalize",
                  width: "118px",
                  height: "46px",
                  borderRadius: "6px",
                  cursor: "pointer",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "black",
                  },
                }}
                onClick={clearAll}
              >
                Clear
              </Button>
              {isEndCall && (
                <Button
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "black",
                    textTransform: "capitalize",
                    width: "118px",
                    height: "46px",
                    cursor: "pointer",
                    borderRadius: "6px",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "black",
                    },
                  }}
                  onClick={enterLog}
                >
                  Save
                </Button>
              )}
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default CallDetailsForm;

// function useOnClickOutside(ref, handler, enabled) {
//   useEffect(() => {
//     if (!enabled) return;
//     const listener = (event) => {
//       if (!ref.current || ref.current.contains(event.target)) {
//         return;
//       }
//       handler(event);
//     };
//     document.addEventListener("mousedown", listener);
//     document.addEventListener("touchstart", listener);
//     return () => {
//       document.removeEventListener("mousedown", listener);
//       document.removeEventListener("touchstart", listener);
//     };
//   }, [ref, handler]);
// }
