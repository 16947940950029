import { useEffect, useReducer } from "react";
import "../Report.css";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import useReportActivity from "../../../hooks/tableColumns/useReportActivity";
import useReportProgress from "../../../hooks/tableColumns/useReportProgress";
import DataTable from "../../../components/DataTable/DataTable";
import ProgressPicture from "../modals/ProgressPicture";
import ActiviyMeals from "../modals/ActiviyMeals";
import Comments from "../modals/Comments";
import LiveSesionModal from "../modals/LiveSesionModal";
import { Box } from "@mui/material";
import MultiSelectBox from "../../../components/TextFields/MultiSelectBox";
import { useForm } from "react-hook-form";

const initialState = {
  columns: [],
  rows: [],
  isPicture: false,
  isMeal: false,
  isComment: false,
  showComment: null,
  currentRow: null,
  isOpenLiveSessionModal: false,
  liveSessionDetails: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "progress":
      return {
        ...state,
        columns: action.payload.progressColumn,
        rows: action.payload.report,
      };
    case "activity":
      return {
        ...state,
        columns: action.payload.actvityColumn,
        rows: action.payload.report,
      };
    case "picture":
      return {
        ...state,
        isPicture: action.payload,
        currentRow: action.payload,
      };
    case "meals":
      return {
        ...state,
        isMeal: action.payload,
        currentRow: action.payload,
      };
    case "commentProgress":
      return {
        ...state,
        isComment: action.payload.isComment,
        showComment: action.payload.showComment,
        currentRow: action.payload.currentRow,
      };
    case "commentActivity":
      return {
        ...state,
        isComment: action.payload.isComment,
        showComment: action.payload.showComment,
        currentRow: action.payload.currentRow,
      };
    case "isLiveSession":
      return {
        ...state,
        isOpenLiveSessionModal: action.payload.isOpen,
        liveSessionDetails: action.payload.liveSessionDetails,
      };
    default:
      throw new Error("Unknown action");
  }
};

const Table = ({ title, report, count, onTableDispatch, currentDate }) => {
  const form = useForm({
    defaultValues: {
      filterList: ["premium"],
    },
  });

  const { control, watch } = form;
  const currentFilterList = watch("filterList");

  let { progressColumn } = useReportProgress(handleClick, handleComment);
  let { actvityColumn } = useReportActivity(
    handleClick,
    handleComment,
    handleLiveSessionModal
  );

  useEffect(() => {
    onTableDispatch({ type: "filterList", payload: currentFilterList });
  }, [currentFilterList]);

  const [
    {
      columns,
      rows,
      isPicture,
      isMeal,
      isComment,
      showComment,
      currentRow,
      isOpenLiveSessionModal,
      liveSessionDetails,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  function handleClick(_, event) {
    if (title === "progressReport") {
      dispatch({
        type: "picture",
        payload: event.row,
      });
    } else {
      dispatch({
        type: "meals",
        payload: event.row,
      });
    }
  }

  function handleLiveSessionModal(row) {
    dispatch({
      type: "isLiveSession",
      payload: {
        isOpen: true,
        liveSessionDetails: row?.completedSession,
      },
    });
  }

  function onCloseLiveModal() {
    dispatch({
      type: "isLiveSession",
      payload: {
        isOpen: false,
        liveSessionDetails: "",
      },
    });
  }

  function handleComment(target, event) {
    if (title === "progressReport") {
      dispatch({
        type: "commentProgress",
        payload: {
          isComment: true,
          showComment: target.currentTarget,
          currentRow: event.row,
        },
      });
    } else {
      dispatch({
        type: "commentActivity",
        payload: {
          isComment: true,
          showComment: target.currentTarget,
          currentRow: event.row,
        },
      });
    }
  }

  const handlePagination = async (model) => {
    if (model.pageSize === -1) {
      onTableDispatch({
        type: "changePage",
        payload: { ...model, pageSize: "All" },
      });
    } else {
      onTableDispatch({ type: "changePage", payload: model });
    }
  };

  useEffect(() => {
    if (title === "progressReport") {
      dispatch({
        type: "progress",
        payload: { progressColumn, report },
      });
    } else {
      dispatch({
        type: "activity",
        payload: { actvityColumn, report },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, report]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="report-table-container">
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "50px" }}>
        <Box sx={{ width: "300px" }}>
          <MultiSelectBox
            control={control}
            name={"filterList"}
            isRequired
            disableCloseOnSelect={false}
            selectAllOption={true}
            menuList={[
              { id: "premium", value: "Premium" },
              { id: "free", value: "Free" },
            ]}
          />
        </Box>
      </Box>

      <DataTable
        columns={columns}
        rows={rows}
        count={count}
        handlePagination={handlePagination}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      {isPicture && (
        <ProgressPicture dispatch={dispatch} currentRow={currentRow} />
      )}
      {isMeal && <ActiviyMeals dispatch={dispatch} currentRow={currentRow} />}
      {isComment && (
        <Comments
          anchorEl={showComment}
          closeDispatch={dispatch}
          currentRow={currentRow}
          currentDate={currentDate}
          title={title}
        />
      )}
      <LiveSesionModal
        isOpen={isOpenLiveSessionModal}
        liveSessionDetails={liveSessionDetails}
        onCloseLiveModal={onCloseLiveModal}
      />
    </div>
  );
};

export default Table;
