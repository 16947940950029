import { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import TextBox from "../../../../components/TextFields/TextBox";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography, Button } from "@mui/material";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const UpdateThresold = ({ isOpen, onClose, handleThreshold, isLoading }) => {
  const form = useForm({
    defaultValues: { thresold: "" },
  });

  const { control, handleSubmit, reset } = form;

  const onSubmit = async (formdata) => {
    handleThreshold(formdata, reset);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: "300px" }}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: 600, marginBottom: "10px" }}>
                Update Thresold
              </Typography>
              <TextBox
                control={control}
                name={"thresold"}
                type="number"
                isRequired={{
                  required: "required",
                }}
              />
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#2a2a2a",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                  marginBottom: "20px",
                  px: "25px",
                }}
                disabled={isLoading}
              >
                save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
};

export default UpdateThresold;
