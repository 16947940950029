import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, FormHelperText } from "@mui/material";

const ControlledAutocomplete = ({
  multiple = false,
  options,
  name,
  control,
  label,
  placeholder,
  getOptionLabel,
  defaultValue,
  error,
  fullWidth,
  rules,
  helperText,
  isDisableColumn = false,
  freeSolo = false,
  isReadOnly = false,
  onChangeCallback,
}) => {
  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <Controller
        control={control}
        id={name}
        key={name}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple={multiple}
            options={options}
            freeSolo={freeSolo}
            disabled={isDisableColumn}
            fullWidth={fullWidth}
            getOptionLabel={getOptionLabel}
            defaultValue={defaultValue}
            value={value || (multiple ? [] : "")}
            onChange={(event, newValue) => {
              onChange(newValue);
              onChangeCallback && onChangeCallback(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                error={error}
                disabled={isDisableColumn}
                onChange={(event) => {
                  if (freeSolo) {
                    onChange(event.target.value);
                  }
                }}
              />
            )}
          />
        )}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ControlledAutocomplete;

// import React from "react";
// import { Controller } from "react-hook-form";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import { FormControl, FormHelperText } from "@mui/material";

// const ControlledAutocomplete = ({
//   multiple = false,
//   options,
//   name,
//   control,
//   label,
//   placeholder,
//   getOptionLabel,
//   defaultValue,
//   error,
//   fullWidth,
//   rules,
//   helperText,
//   isDisableColumn = false,
//   freeSolo = false,
//   isReadOnly = false,
//   onChangeCallback,
// }) => {
//   return (
//     <FormControl error={error} fullWidth={fullWidth}>
//       <Controller
//         control={control}
//         id={name}
//         key={name}
//         name={name}
//         rules={rules}
//         render={({ field: { onChange } }) => (
//           <Autocomplete
//             multiple={multiple}
//             options={options}
//             // size="small"
//             disabled={isDisableColumn}
//             fullWidth={fullWidth}
//             getOptionLabel={getOptionLabel}
//             defaultValue={defaultValue}
//             onChange={(event, item) => {
//               onChange(item);
//               onChangeCallback && onChangeCallback(item);
//             }}
//             freeSolo={freeSolo}
//             renderInput={(params) => (
//               <TextField
//                 disabled={isDisableColumn}
//                 {...params}
//                 error={error}
//                 //size="small"
//                 onChange={(event, newValue) => {
//                   onChange(event);
//                 }}
//                 // helperText={helperText}
//                 label={label}
//                 placeholder={placeholder}
//               />
//             )}
//           />
//         )}
//       />
//       {error && <FormHelperText>{error ? helperText : " "}</FormHelperText>}
//     </FormControl>
//   );
// };
// export default ControlledAutocomplete;
