import Modal from "../../components/Modal/Modal";
import {
  Box,
  FormControl,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  Card,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useForm, Controller } from "react-hook-form";
import CallIcon from "@mui/icons-material/Call";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { useHistory } from "react-router-dom";

import { useCallerPopup } from "../store/Call/CallerPopupContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
const categoryLeadList = [
  { id: 1, name: "First Call" },
  { id: 3, name: "Follow up call" },
];

const categoryClientList = [
  { id: 1, name: "First Call" },
  { id: 2, name: "Post plan call" },
  { id: 3, name: "Follow up call" },
  { id: 4, name: "Renewal call" },
  { id: 5, name: "Sales Call" },
];

const Servetel = ({ isOpen, onClose, selectedRow, leadId }) => {
  const location = useLocation();
  const pathName = location.pathname.toLowerCase().includes("lead")
    ? "Lead"
    : "Client";
  const history = useHistory();
  const { setSelectedItems, setIsVisible, setCallid } = useCallerPopup();
  const userId = JSON.parse(window.localStorage.getItem("operator"))?.id;
  const [categoryList, setCategoryList] = useState([]);

  const clientNumber =
    pathName === "Lead"
      ? selectedRow?.contact_number
      : location.pathname === "/Clients/listClients"
      ? selectedRow?.Phone
      : selectedRow?.contact_number;

  const agentNumber = JSON.parse(
    window.localStorage.getItem("operator")
  )?.Mobile_number;

  const form = useForm({
    defaultValues: {
      category: "",
      alternativeNumber: "",
    },
  });

  const { control, reset, handleSubmit, watch, trigger, getValues } = form;

  const category = watch("category");

  const handleClose = () => {
    reset();
    onClose();
  };

  const triggerCall = async (number) => {
    setSelectedItems({
      leadId: leadId,
      category: getValues("category"),
    });

    if (pathName === "Lead") {
      history.push({
        pathname: `/lead/${leadId}`,
      });
    } else {
      history.push({
        pathname: `/Clients/clientDetail/${leadId}`,
      });
    }

    setIsVisible(true);
    onClose();
    try {
      const response = await axiosFetch({
        url: `/servetel/makeOutboundCall`,
        method: "post",
        requestConfig: {
          agentPhoneNumber: agentNumber,
          leadPhoneNumber: number,
          leadId: leadId,
          userId: userId,
        },
      });

      if (response.status === 200) {
        setCallid(response.data?.body?.call_id);
      }
    } catch (error) {
      console.error("Error initiating call:", error);
    }
  };

  const handleCallWithDefaultNumber = async () => {
    const isValid = await trigger("category");
    if (isValid) triggerCall(clientNumber);
  };

  const onSubmit = (formData) => {
    triggerCall(formData.alternativeNumber);
  };

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("loginDetails"));
    if (item?.Role === "Sales Manager" || item?.Role === "Sales") {
      setCategoryList(categoryLeadList);
    } else {
      setCategoryList(categoryClientList);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} style={{ boxShadow: "none" }}>
      <Card
        elevation={0}
        sx={{
          width: {
            xl: "379px",
            md: "100%",
            sm: "100%",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xl={12} md={12} sm={12}>
              <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                Servetel
              </Typography>
            </Grid>
            <Grid item xl={12} md={12} sm={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Category<span style={{ color: "red" }}>*</span>
              </Typography>
              <Controller
                name="category"
                rules={{ required: "required" }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <Select
                      displayEmpty
                      sx={{
                        fontSize: "16px",
                        color: "black",
                        backgroundColor: "white",
                      }}
                      {...field}
                      error={!!error}
                    >
                      <MenuItem value="">
                        <span style={{ color: "#9da5b1" }}>
                          Please Select Category
                        </span>
                      </MenuItem>

                      {categoryList.map((task) => (
                        <MenuItem
                          value={task.id}
                          key={task.id}
                          sx={{ fontSize: "16px" }}
                        >
                          {task.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />

              <Typography
                sx={{
                  fontSize: "16px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                Mobile:{clientNumber}
                <button
                  style={{
                    all: "unset",
                    border: category ? "1px solid green" : "",
                    padding: "6px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={handleCallWithDefaultNumber}
                >
                  <CallIcon
                    sx={{
                      fontSize: "18px",
                      color: category ? "green" : "",
                    }}
                  />
                </button>
              </Typography>
            </Grid>
            <Grid item xl={12} md={12} sm={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Alternative Number
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Controller
                  name={"alternativeNumber"}
                  control={control}
                  rules={{
                    required: "required",
                    validate: (value) => {
                      const phoneNumberWithoutCountryCode = value
                        .replace(/^\+91\s*/, "")
                        .replace(/\s+/g, "");
                      if (
                        phoneNumberWithoutCountryCode &&
                        phoneNumberWithoutCountryCode.length !== 10
                      ) {
                        return "Phone number must be exactly 10 digits";
                      }
                      return true;
                    },
                  }}
                  defaultValue={undefined}
                  render={({ field, fieldState: { error } }) => (
                    <MuiTelInput
                      fullWidth
                      size="small"
                      defaultCountry="IN"
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <button
                  style={{
                    all: "unset",
                    border: category ? "1px solid green" : "",
                    padding: "6px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  <CallIcon
                    sx={{
                      fontSize: "18px",
                      color: category ? "green" : "",
                    }}
                  />
                </button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Modal>
  );
};

export default Servetel;
