import React from "react";
import moment from "moment";
import BodyIndex from "../../img/BodyIndex.jpeg";
function HFReport(props) {
  const leadDetails = props.leadDetails;

  const s1style = {
    color: "#FFF",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "14pt",
  };
  //className s2
  const s2 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "14pt",
  };

  //className 23

  const s3 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "12pt",
  };

  const s4 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "15pt",
  };

  const s5 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12pt",
  };

  const s6 = {
    color: "#FFF",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "13pt",
  };

  const s7 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "13pt",
  };

  const s8 = {
    color: "#212121",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12pt",
  };

  const p = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "12pt",
    margin: "0pt",
  };

  const s9 = {
    color: "#212121",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "10pt",
  };

  const s10 = {
    color: "#212121",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "10pt",
  };

  const s11 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "10pt",
  };

  const s12 = {
    color: "#FFF",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12pt",
  };

  const s13 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "10pt",
  };

  const s14 = {
    color: "#444",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "12pt",
  };

  const s15 = {
    color: "#424242",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "12pt",
  };

  const h1 = {
    color: "#F00",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "22pt",
    backgroundColor: "#772e53",
    textAlign: "center",
    border: "solid 1px black",
  };

  const h4 = {
    color: "#F00",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: "16pt",
  };

  const s16 = {
    color: "#F00",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "underline",
    fontSize: "16pt",
  };

  const h2 = {
    color: "#F00",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "20.5pt",
  };

  const s17 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    // fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12pt",
  };

  const h3 = {
    color: "#F00",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    // textDecoration: "underline",
    fontSize: "20pt",
  };

  const s18 = {
    color: "#F00",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "20pt",
  };

  const s19 = {
    color: "#37751C",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12pt",
  };

  const s21 = {
    color: "#F00",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "20pt",
  };

  const s22 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12pt",
    verticalAlign: "-8pt",
  };

  const s24 = {
    color: "#F00",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "underline",
    fontSize: "12pt",
  };

  const s25 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "13pt",
  };

  const s26 = {
    color: "#FFF",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "26pt",
  };

  const s27 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "14pt",
  };

  const s28 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "12pt",
  };

  const s29 = {
    color: "black",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    fontSize: "14pt",
  };

  const s30 = {
    color: "#FF1300",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: "14pt",
  };

  const li = {
    display: "block",
  };

  //   const idl1 = {
  //     paddingLeft: "0pt",
  //   }

  //  const l1lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "12pt",
  //   }

  //   const idl2 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l2lifirst_child = {
  //     content: "- ",
  //     color: "#F00",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "bold",
  //     textDecoration: "none",
  //     fontSize: "16pt",
  //   }

  //   const idl3 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l3lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "12pt",
  //   }

  //   const idl4 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l4lifirst_child = {
  //     content: "❖ ",
  //     color: "black",
  //     fontFamily: "MS PGothic",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "12pt",
  //   }

  //   const idl5 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l5lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "12pt",
  //   }

  //   const idl6 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l6lifirst_child = {
  //     content: "• ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "bold",
  //     textDecoration: "none",
  //     fontSize: "12pt",
  //     verticalAlign: "-8pt",
  //   }

  //   const idl7 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l7lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //   }

  //   const idl8 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l8lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "12pt",
  //   }

  //   const idl9 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l9lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "12pt",
  //   }

  //   const idl10 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l10lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "12pt",
  //   }

  //   const idl11 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l11lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //   }

  //   const idl12 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l12lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //   }

  //   const idl13 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l13lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "14pt",
  //   }

  //   const idl14 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l14lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "14pt",
  //   }

  //   const idl15 = {
  //     paddingLeft: "0pt",
  //   }

  //   const l15lifirst_child = {
  //     content: "● ",
  //     color: "black",
  //     fontFamily: "Arial",
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //     textDecoration: "none",
  //     fontSize: "14pt",
  //   }

  const table = {
    verticalAlign: "top",
    overflow: "visible",
  };
  const tbody = {
    verticalAlign: "top",
    overflow: "visible",
  };

  return (

      <div style={{ width: "700pt" }}>
        {" "}
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <table
          className="table"
          style={{ ...table, borderCollapse: "collapse", marginLeft: "23.5pt" }}
          S={0}
        >
          <tbody className="tbody">
            <tr style={{ ...tbody, height: "39pt" }}>
              <td
                style={{
                  width: "509pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#772E53",
                }}
                colSpan="2"
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s1"
                  style={{
                    ...p,
                    ...s1style,
                    paddingLeft: "182pt",
                    paddingRight: "182pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  PERSONAL DETAILS
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Date
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {moment().format("MM/DD/YYYY")}
                </p>
              </td>
            </tr>
            <tr style={{ height: "32pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Name
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.name || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Email Address
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.email_id || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Phone Number
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.contact_number || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "32pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Whatsapp Number
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.contact_number || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "32pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s4"
                  style={{
                    ...s4,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Location
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.city !== null &&
                  leadDetails?.state !== null &&
                  leadDetails?.country !== null
                    ? `${leadDetails?.city !== null ? leadDetails?.city : ""} ${
                        leadDetails?.state !== null ? leadDetails?.state : ""
                      } ${
                        leadDetails?.country !== null
                          ? leadDetails?.country
                          : ""
                      }`
                    : "-"}
                </p>
              </td>
            </tr>
            {/* <tr style={{ height: "28pt" }}>
              <td
                style={{
                  width: "509pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={2}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-i...p,ndent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </td>
            </tr> */}
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Gender
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.gender == "M" ? "Male" : "Female" || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "33pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s4"
                  style={{
                    ...s4,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Age
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.age || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Height
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.height !== null &&
                  leadDetails?.height_unit !== null
                    ? `${leadDetails?.height} ${
                        leadDetails?.height_unit == 1 ? "ft'in" : "cms"
                      }`
                    : "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Weight
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FFD9D9",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.current_weight !== null &&
                  leadDetails?.weight_unit !== null
                    ? `${leadDetails?.current_weight} ${
                        leadDetails?.weight_unit == 1 ? "lbs" : "Kg"
                      }`
                    : "-"}
                </p>
              </td>
            </tr>
            {/* <tr style={{ height: "27pt" }}>
              <td
                style={{
                  width: "509pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={2}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-i...p,ndent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </td>
            </tr> */}
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Prefered Language
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.preferred_language || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Profession
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.occupation || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "32pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  More details about your Profession
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.profession_details || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  How Active is your Lifestyle ?
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FFD9D9",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.lead_lifestyle || "-"}
                </p>
              </td>
            </tr>
            <br />
            <br />
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "246pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Work Hours
                </p>
              </td>
              <td
                style={{
                  width: "263pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.work_hours || "-"}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            ...p,
            "-webkit-text-i,ndent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <table
          className="table"
          style={{ ...table, borderCollapse: "collapse", marginLeft: "22.5pt" }}
          S={0}
        >
          <tbody className="tbody">
            <tr style={{ ...tbody, height: "64pt" }}>
              <td
                style={{
                  width: "253pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-i,ndent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  What is your Most Primary Fitness Goal ?
                </p>
              </td>
              <td
                style={{
                  width: "254pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.fitness_goals || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "69pt" }}>
              <td
                style={{
                  width: "253pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  What is your Most Challenging Pain Point which is stopping you
                  from achieving your Fitness Goals?
                </p>
              </td>
              <td
                style={{
                  width: "254pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.lead_pain_point || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "36pt" }}>
              <td
                style={{
                  width: "253pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Any existing Body Pain ?
                </p>
              </td>
              <td
                style={{
                  width: "254pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.lead_body_pain || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "37pt" }}>
              <td
                style={{
                  width: "253pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Any existing Health Issues ?
                </p>
              </td>
              <td
                style={{
                  width: "254pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.current_medical_condition
                    ? leadDetails?.current_medical_condition.toString()
                    : leadDetails?.current_medical_condition_string || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "68pt" }}>
              <td
                style={{
                  width: "253pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  On a scale of 1-10, how committed are you to achieve your
                  Fitness Goals ?
                </p>
              </td>
              <td
                style={{
                  width: "254pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingLeft: "5pt",
                    paddingRight: "3pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.vmax_fitness_scale || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "37pt" }}>
              <td
                style={{
                  width: "253pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    ...s2,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Reason for lack of Commitment ?
                </p>
              </td>
              <td
                style={{
                  width: "254pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.vmax_fit_scale_reason || "-"}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <table
          className="table"
          style={{ ...table, borderCollapse: "collapse", marginLeft: "26.5pt" }}
          S={0}
        >
          <tbody className="tbody">
            <tr style={{ ...tbody, height: "27pt" }}>
              <td
                style={{
                  width: "501pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#772E53",
                }}
                colSpan="2"
              >
                <p
                  className="s1"
                  style={{
                    ...s1style,
                    paddingTop: "5pt",
                    paddingLeft: "105pt",
                    paddingRight: "104pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  Your Health and Fitness Report Starts Here
                </p>
              </td>
            </tr>
            <tr style={{ height: "22pt" }}>
              <td
                style={{
                  width: "501pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={2}
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "25pt" }}>
              <td
                style={{
                  width: "243pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Age
                </p>
              </td>
              <td
                style={{
                  width: "258pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "6pt",
                    paddingLeft: "93pt",
                    paddingRight: "91pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  {leadDetails?.age || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "243pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Height
                </p>
              </td>
              <td
                style={{
                  width: "258pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "5pt",
                    paddingLeft: "93pt",
                    paddingRight: "91pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  {leadDetails?.height !== null &&
                  leadDetails?.height_unit !== null
                    ? `${leadDetails?.height} ${
                        leadDetails?.height_unit == 1 ? "ft'in" : "cms"
                      }`
                    : "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "24pt" }}>
              <td
                style={{
                  width: "243pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Weight
                </p>
              </td>
              <td
                style={{
                  width: "258pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FFD9D9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "5pt",
                    paddingLeft: "93pt",
                    paddingRight: "91pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  {leadDetails?.current_weight !== null &&
                  leadDetails?.weight_unit !== null
                    ? `${leadDetails?.current_weight} ${
                        leadDetails?.weight_unit == 1 ? "lbs" : "Kg"
                      }`
                    : "-"}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          className="table"
          style={{ ...table, borderCollapse: "collapse", marginLeft: "26.5pt" }}
          S={0}
        >
          <tbody className="tbody">
            <tr style={{ ...tbody, height: "24pt" }}>
              <td
                style={{
                  width: "243pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  BMI (Body Mass Index)
                </p>
              </td>
              <td
                style={{
                  width: "257pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FFD9D9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "5pt",
                    paddingLeft: "49pt",
                    paddingRight: "48pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  {leadDetails?.bmi || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "38pt" }}>
              <td
                style={{
                  width: "243pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  BMR (Basal Metabolic Rate) for your Present Weight
                </p>
              </td>
              <td
                style={{
                  width: "257pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "5pt",
                    paddingLeft: "49pt",
                    paddingRight: "48pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  {leadDetails?.bmr || "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "42pt" }}>
              <td
                style={{
                  width: "243pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    lineHeight: "114%",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  BMR for your Goal Weight of Your Most Efficient Weight Range
                </p>
              </td>
              <td
                style={{
                  width: "257pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s3"
                  style={{
                    ...s3,
                    paddingTop: "5pt",
                    paddingLeft: "49pt",
                    paddingRight: "48pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  {leadDetails?.goal_weight_bmr || "-"}
                </p>
              </td>
            </tr>
            <br /> <br />   <br /> <br />
            <tr style={{ height: "27pt" }}>
              <td
                style={{
                  width: "500pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#772E53",
                }}
                colSpan="2"
              >
                <p
                  className="s6"
                  style={{
                    ...s6,
                    paddingTop: "5pt",
                    paddingLeft: "187pt",
                    paddingRight: "187pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  Category
                </p>
              </td>
            </tr>
            <tr style={{ height: "46pt" }}>
              <td
                style={{
                  width: "500pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FFD9D9",
                }}
                colSpan="2"
              >
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s7"
                  style={{
                    ...s7,
                    paddingLeft: "187pt",
                    paddingRight: "187pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                You fall under {props.category} category

                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <table
          className="table"
          style={{ ...table, borderCollapse: "collapse", marginLeft: "27.5pt" }}
          S={0}
        >
          <tbody className="tbody">
            <tr style={{ ...tbody, height: "22pt" }}>
              <td
                style={{
                  width: "499pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#772E53",
                }}
                colSpan="2"
              >
                <p
                  className="s1"
                  style={{
                    ...s1style,
                    paddingTop: "2pt",
                    paddingLeft: "191pt",
                    paddingRight: "190pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  Your Ideal Range
                </p>
              </td>
            </tr>
            <tr style={{ height: "21pt" }}>
              <td
                style={{
                  width: "244pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "2pt",
                    paddingLeft: "2pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Your Ideal BMI Range
                </p>
              </td>
              <td
                style={{
                  width: "255pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s8"
                  style={{
                    ...s8,
                    paddingTop: "2pt",
                    paddingLeft: "1pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  18.5 - 23
                </p>
              </td>
            </tr>
            <tr style={{ height: "20pt" }}>
              <td
                style={{
                  width: "244pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "2pt",
                    paddingLeft: "2pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Your Ideal Weight Range
                </p>
              </td>
              <td
                style={{
                  width: "255pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "2pt",
                    paddingLeft: "1pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.ideal_weight_1 !== null &&
                  leadDetails?.ideal_weight_2 !== null
                    ? `${leadDetails?.ideal_weight_1} - ${leadDetails?.ideal_weight_2}`
                    : "-"}
                </p>
              </td>
            </tr>
            <tr style={{ height: "36pt" }}>
              <td
                style={{
                  width: "244pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  backgroundColor: "#FEF4E9",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "2pt",
                    paddingLeft: "2pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    lineHeight: "114%",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Your Most Efficient Weight Range (VMax Recommended)
                </p>
              </td>
              <td
                style={{
                  width: "255pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    ...s5,
                    paddingTop: "10pt",
                    paddingLeft: "1pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  {leadDetails?.efficient_weight_1 !== null &&
                  leadDetails?.efficient_weight_2 !== null
                    ? `${leadDetails?.efficient_weight_1} - ${leadDetails?.efficient_weight_2}`
                    : "-"}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <ul style={{ listStyleType: "disc" }}>
          <li style={{ paddingLeft: "34px", color: "black" }}>
            At your Most Efficient Weight, your Body will have the most Energy
            Efficient Operation throughout the day, because you won't strain on
            dragging the unwanted weight.
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "5pt",
                paddingLeft: "44pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                lineHeight: "114%",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Which also means, your Joints and Bones will not strain on the
              extra load and will be healthy and mobile for life long.
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "5pt",
                paddingLeft: "44pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                lineHeight: "114%",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Your Muscles will have easy operations, and will use less Energy
              for the day to day activities.
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "4pt",
                paddingLeft: "44pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                lineHeight: "114%",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              At this Efficient Weight, you will be Energetic throughout the day
              with the Best Metabolism and High Immunity.
            </p>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <table
              className="table"
              style={{
                ...table,
                borderCollapse: "collapse",
                marginLeft: "6.5pt",
              }}
              S={0}
            >
              <tbody className="tbody">
                <tr style={{ ...tbody, height: "23pt" }}>
                  <td
                    style={{
                      width: "531pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt ",
                      backgroundColor: "#772E53",
                    }}
                    colSpan="2"
                  >
                    <p
                      className="s1"
                      style={{
                        ...s1style,
                        paddingTop: "2pt",
                        paddingLeft: "105pt",
                        paddingRight: "104pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      BMI Range (For your Reference)
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "17pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s9"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "98pt",
                        paddingRight: "98pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Category
                    </p>
                  </td>
                  <td
                    style={{
                      width: "266pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s9"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "106pt",
                        paddingRight: "106pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      BMI
                    </p>
                  </td>
                </tr>
                {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                <tr style={{ height: "17pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s10"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Underweight
                    </p>
                  </td>
                  <td
                    style={{
                      width: "266pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s11"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "106pt",
                        paddingRight: "106pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Below 18.5
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "18pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s10"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Normal
                    </p>
                  </td>
                  <td
                    style={{
                      width: "266pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s10"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "106pt",
                        paddingRight: "106pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      18.5 - 25
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "17pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s10"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Overweight
                    </p>
                  </td>
                  <td
                    style={{
                      width: "266pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s11"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "106pt",
                        paddingRight: "106pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      25 - 29
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "18pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s10"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Obese Class I
                    </p>
                  </td>
                  <td
                    style={{
                      width: "266pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s11"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "106pt",
                        paddingRight: "106pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      30 - 34
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "17pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s11"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Obese Class II
                    </p>
                  </td>
                  <td
                    style={{
                      width: "266pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s11"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "106pt",
                        paddingRight: "106pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      35 - 39
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "18pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s11"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Obese Class III
                    </p>
                  </td>
                  <td
                    style={{
                      width: "266pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s11"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "106pt",
                        paddingRight: "106pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Over 40
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "45pt" }}>
                  <td
                    style={{
                      width: "531pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt ",
                      backgroundColor: "#FFD9D9",
                    }}
                    colSpan="2"
                  >
                    <p
                      className="s3"
                      style={{
                        ...s3,
                        paddingTop: "6pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        lineHeight: "114%",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      The Ideal BMI Weight Range is a Basic and Approximate
                      Range as per Standard BMI Charts. It says that, being
                      inside the Approximate BMI recommended range is a Good
                      Practice for Everyone.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <br />

            <table
              className="table"
              style={{
                ...table,
                borderCollapse: "collapse",
                marginLeft: "6.5pt",
              }}
              S={0}
            >
              <tbody className="tbody">
                <tr style={{ ...tbody, height: "21pt" }}>
                  <td
                    style={{
                      width: "532pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt       ",
                      backgroundColor: "#772E53",
                    }}
                    colSpan="3"
                  >
                    <p
                      className="s12"
                      style={{
                        ...s12,
                        paddingTop: "1pt",
                        paddingLeft: "107pt",
                        paddingRight: "107pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      INDIAN BODY FAT STANDARDS  ( For Your &nbsp; Reference )
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s13"
                      style={{
                        ...s13,
                        paddingTop: "5pt",
                        paddingLeft: "98pt",
                        paddingRight: "98pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      CATEGORIES
                    </p>
                  </td>
                  <td
                    style={{
                      width: "120pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s13"
                      style={{
                        ...s13,
                        paddingTop: "5pt",
                        paddingLeft: "24pt",
                        paddingRight: "23pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      MEN
                    </p>
                  </td>
                  <td
                    style={{
                      width: "147pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s13"
                      style={{
                        ...s13,
                        paddingTop: "5pt",
                        paddingLeft: "37pt",
                        paddingRight: "36pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      WOMEN
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "21pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Atmost Actual Essential Fat needed
                    </p>
                  </td>
                  <td
                    style={{
                      width: "120pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "24pt",
                        paddingRight: "23pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      3-5%
                    </p>
                  </td>
                  <td
                    style={{
                      width: "147pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "37pt",
                        paddingRight: "36pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      10-12%
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        ...s14,
                        paddingTop: "7pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Athletic Standards
                    </p>
                  </td>
                  <td
                    style={{
                      width: "120pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        ...s14,
                        paddingTop: "7pt",
                        paddingLeft: "24pt",
                        paddingRight: "23pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      6-13%
                    </p>
                  </td>
                  <td
                    style={{
                      width: "147pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        ...s14,
                        paddingTop: "7pt",
                        paddingLeft: "37pt",
                        paddingRight: "36pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      14-20%
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "21pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Fitness Standards (VMax Recommended)
                    </p>
                  </td>
                  <td
                    style={{
                      width: "120pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "24pt",
                        paddingRight: "23pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      14-17%
                    </p>
                  </td>
                  <td
                    style={{
                      width: "147pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "37pt",
                        paddingRight: "36pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      21-24%
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "7pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Below Average Physique (Not Recommended)
                    </p>
                  </td>
                  <td
                    style={{
                      width: "120pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "7pt",
                        paddingLeft: "24pt",
                        paddingRight: "23pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      18-24%
                    </p>
                  </td>
                  <td
                    style={{
                      width: "147pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "7pt",
                        paddingLeft: "37pt",
                        paddingRight: "36pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      25-29%
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "21pt" }}>
                  <td
                    style={{
                      width: "265pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "2pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Obese Category
                    </p>
                  </td>
                  <td
                    style={{
                      width: "120pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "24pt",
                        paddingRight: "23pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      25% and above
                    </p>
                  </td>
                  <td
                    style={{
                      width: "147pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s15"
                      style={{
                        ...s14,
                        paddingTop: "6pt",
                        paddingLeft: "37pt",
                        paddingRight: "36pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      30% and above
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p style={{ breakAfter: "always" }}></p>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>

            <p
              style={{
                ...p,
                paddingLeft: "10pt",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            ></p>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <p
                  style={{
                    ...p,
                    paddingTop: "4pt",
                    paddingLeft: "10pt",
                    "-webkit-text-indent": "-9pt",
                    textIndent: "-9pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  {/* <h1
            className="h1"
            style={{
              ...h1,
              paddingTop: "5pt",
              paddingLeft: "137pt",
              "-webkit-text-indent": "0pt",
              textIndent: "0pt",
              "-webkit-text-align": "center",
              textAlign: "center",
            }}
          >
            Notes from your Coach
          </h1> */}

                  <p
                    className="s12"
                    style={{
                      ...h1,
                      paddingTop: "1pt",
                      paddingLeft: "107pt",
                      paddingRight: "107pt",
                      "-webkit-text-indent": "0pt",
                      textIndent: "0pt",
                      "-webkit-text-align": "center",
                      textAlign: "center",
                      color:'white'
                    }}
                  >
                    Notes from your Coach
                  </p>
                  <center
                    className="h4"
                    style={{ ...h4, backgroundColor: "#FBE4CC" }}
                  >
                    {props.coachName}
                  </center>
                </p>
                <br />
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
            <ul style={{ paddingLeft: "0pt", listStyleType: "none" }}>
              <li>
              
                <p
                  style={{
                    ...p,
                    paddingTop: "6pt",
                    paddingLeft: "1pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    lineHeight: "114%",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Like Diabetes, Blood Pressure Disorders, Cardiovascular
                  Diseases, Reduced Immunity, Thyroid disorders, PCOD, Imbalance
                  of Hormones Production and Some kinds of Cancer as well.
                </p>
                <p
                  className="s17"
                  style={{
                    ...s17,
                    paddingTop: "5pt",
                    paddingLeft: "1pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    lineHeight: "114%",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Imbalanced Nutrition and Inactive Lifestyle, is the Major
                  Cause for all kinds of Lifestyle Disorders
                </p>
                <p
                  style={{
                    ...p,
                    paddingLeft: "11pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                ></p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                    breakAfter: "always",
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    ...p,
                    paddingLeft: "102pt",
                    "-webkit-text-indent": "-86pt",
                    textIndent: "-86pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Arial,",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      webkitTextDecoration: "none",
                      textDecoration: "none",
                      fontSize: "10pt",
                      breakAfter: "always",
                    }}
                  >
                    Based on Your Fitness Goal, Age and Height, Your Most
                    Efficient Weight Range (VMax Recommended) is Your Most
                    Efficient Weight Range
                  </span>
                </p>
                    <br/>
                <p
                  style={{
                    ...p,
                    paddingLeft: "9pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <span>
                    <table cellSpacing="0" cellPadding="0">
                      <tr>
                        <td>
                          <img width="500" height="480" src={BodyIndex} alt="" />
                        </td>
                      </tr>
                     </table>
                  </span>
                </p>
                <br />
                <br />
                <br />
              {props.categoryType === 2
              ?  <>
                
                <h2
                  className="h2"
                  style={{
                    ...h2,
                    paddingTop: "1pt",
                    paddingLeft: "6pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  Risks of being Underweight
                </h2>
                <p
                  style={{
                    ...p,
                    paddingTop: "13pt",
                    paddingLeft: "6pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  Being underweight can cause health problems, just as being
                  overweight can.
                </p>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <p
                      className="s17"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Not all people who are underweight experience adverse side
                      effects or symptoms from being underweight. However, some
                      people, experience the following symptoms related to being
                      underweight:
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Osteoporosis.
                    </p>
                  </li>
                  <li>
                    <p
                      className="s17"
                      style={{
                        ...s17,
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Skin, Hair, or Teeth problems.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "left",
                      }}
                    >
                      Getting Sick frequently.
                    </p>
                  </li>
                  <li>
                    <p
                      className="s17"
                      style={{
                        ...s17,
                      
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "left",
                      }}
                    >
                      Very Low Immunity against Cancer
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                      
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Feeling tired all the time.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                      
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Anemia.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                       
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Slow or Impaired Growth.
                    </p>
                  </li>
                  <li>
                    <p
                      className="s17"
                      style={{
                        ...s17,
                      
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Physical illness or chronic disease.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                       
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Regular nausea, Vomiting, Diarrhea
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Thyroid disorders
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-21pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Bad Digestive Conditions (Crohn’s disease or Ulcerative
                      Colitis).
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                       
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-21pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Other conditions may decrease a person’s appetite
                    </p>
                  </li>
                  <br />
                  <li   style={{
                        ...p,
                      
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-21pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}>
                    
                      According to a study published in the journal BMC Public
                      Health, being underweight is associated with an increased
                      risk for mortality when compared to people with an average
                      BMI. The researchers suggested that being underweight may
                      impair a person’s healing processes following an accident
                      or trauma compared to a person with an average BMI.
                    
                  </li>
                </ul>
                <br/>
                <div>
            <h3 style={{"text-align": "left","color": "red"}}>
            Reasons &nbsp;&nbsp;for being Underweight</h3>
            <p>There are a variety of reasons why a person may be Underweight. Sometimes, multiple underlying causes may be related.</p>
         </div>
         <h3 style={{"color": "red"}}>Causes &nbsp;&nbsp;of being underweight include:</h3>
         <br/>
         <ul style={{ listStyleType: "disc", color:"black" }}>
            <li>Family history.</li>
            <li>A High Metabolism</li>
            <li>Frequent Physical activity</li>
            <li>Mental illness</li>
            <li>Physical Illness or chronic disease.</li>
            <li>Regular nausea</li>
            <li>Vomiting</li>
            <li>Diarrhea</li>
            <li>Other conditions may decrease a person’s appetite</li>
            <li>Very Low Immunity against Cancer</li>
            <li>Thyroid disorders</li>
            <li>Bad Digestive Conditions (Crohn's disease or Ulcerative Colitis).</li>
         </ul>
         <br/>
         <h3 style={{"color": "red"}}>Tips for a Healthy Weight Gain
            Some key components of a diet for Weight Gain includes:
         </h3>
         <br/>
         <ul style={{ listStyleType: "disc", color:"black" }}>
            <li>Adding snacks.</li>
            <li>High-Protein and Whole-grain Carbohydrate Snacks can help a person gain weight.</li>
            <li>Eating Several small meals a day.</li>
            <li>Incorporating additional foods.</li>
            <li>Avoiding empty calories.</li>
         </ul>
         <br/>
         <br/>
         <h3 style={{"color": "red"}}>Healthy Ways to Gain Weight</h3><br/>
         <p style={{color:'black'}}>Weight Training - Weight training at least three times a week is essential to healthy weight gain. This will aid in gaining and maintaining lean muscle mass.</p>
         <br/>
         <p style={{color:'black'}}>According to a study published in the journal BMC Public Health, being underweight is associated with an increased risk for mortality when compared to people with an average BMI. The researchers suggested that being underweight may impair a person’s healing processes following an accident or trauma compared to a person with an average BMI.</p>
         <br/>
         <p style={{color:'black'}}>Eat enough Protein - A diet with the right amount of protein will support muscle growth. In combination with regular weight training will increase muscle mass.This is essential for healthy weight gain.</p>
         <br/>
        
            <p style={{color:'black'}}>Eat Meals with Fibrous Carbohydrates or Complex Carbohydrates and Healthy fats</p>
            <br/>
            <p style={{color:'black'}}>Including foods rich in fibrous carbohydrates and healthful fats in every meal will help to increase the number of both calories and nutrients in the diet.</p>
            <br/>
            <p style={{color:'black'}}>Drink high-calorie Liquids - People with a small appetite may find a high-calorie liquids appealing than a large meal. These provide nutrient-dense calories better.</p>
        
         <br/>
         <div style={{'width': '100%','padding': '10px','border': '2px solid red'}}>Your Everyday Food Intake must have a Balanced Quantity of Macro Nutrients like Protein, Fat, Carbohydrates and Fiber. Which is one of the main reasons why you are Unable to Reach your Fitness Goals.</div>
                
                </>
                :<>
                <h2
                  className="h2"
                  style={{
                    ...h2,
                    paddingTop: "1pt",
                    paddingLeft: "6pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  Consequences of Having Excess Body Fat
                </h2>
                <p
                  style={{
                    ...p,
                    paddingTop: "13pt",
                    paddingLeft: "6pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  People who are Overweight, compared to those with a Normal or
                  Healthy Weight, are at increased risk for many Serious
                  Diseases and Health Issues. Having too much Fat in your body,
                  mainly around the Midsection will result in
                </p>
                <ul style={{ listStyleType: "disc" }}>
                  <li className="li">
                    <p
                      className="s17"
                      style={{
                        ...s17,
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      High Chances of developing Health Disorders such as
                    </p>
                    <ul style={{ listStyleType: "disc" }}>
                      <li className="li">
                        <p
                          style={{
                            ...p,
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            "-webkit-text-indent": "-18pt",
                            textIndent: "-18pt",
                            "-webkit-text-align": "left",
                            textAlign: "left",
                          }}
                        >
                          Type 2 Diabetes
                        </p>
                      </li>
                      <li className="li">
                        <p
                          style={{
                            ...p,
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            "-webkit-text-indent": "-18pt",
                            textIndent: "-18pt",
                            "-webkit-text-align": "left",
                            textAlign: "left",
                          }}
                        >
                          High Blood Pressure (Hypertension)
                        </p>
                      </li>
                      <li className="li">
                        <p
                          style={{
                            ...p,
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            "-webkit-text-indent": "-18pt",
                            textIndent: "-18pt",
                            "-webkit-text-align": "left",
                            textAlign: "left",
                          }}
                        >
                          Cardiovascular Diseases and Stroke
                        </p>
                      </li>
                      <li className="li">
                        <p
                          style={{
                            ...p,
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            "-webkit-text-indent": "-18pt",
                            textIndent: "-18pt",
                            "-webkit-text-align": "left",
                            textAlign: "left",
                          }}
                        >
                          Gallbladder Disease
                        </p>
                      </li>
                      <li className="li">
                        <p
                          className="s17"
                          style={{
                            ...s17,
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            "-webkit-text-indent": "-18pt",
                            textIndent: "-18pt",
                            "-webkit-text-align": "justify",
                            textAlign: "justify",
                          }}
                        >
                          Note : These Health Disorders depends on a lot of
                          other affecting factors too, but it is good to be
                          informed and precautious from Right now
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p
                      className="s17"
                      style={{
                        ...s17,
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      High LDL (Bad)Cholesterol, Low HDL (Good)Cholesterol,
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      High levels of Triglycerides (Dyslipidemia).
                    </p>
                  </li>
                  <li>
                    <p
                      className="s17"
                      style={{
                        ...s17,
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Osteoarthritis as you age (a breakdown of cartilage and
                      bone within a joint)
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        fontWeight: "bold",
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Many types of Cancer.
                    </p>
                  </li>
                  <li>
                    <p
                      className="s17"
                      style={{
                        ...s17,
                        fontWeight: "bold",
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Mental Illness such as Clinical Depression and Anxiety.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        fontWeight: "bold",
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Reduced Metabolism and Immunity Levels.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        fontWeight: "bold",
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Reduced Testosterone Levels.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        fontWeight: "bold",
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Low Quality of Life
                    </p>
                  </li>
                  <li>
                    <p
                      className="s17"
                      style={{
                        ...s17,
                        fontWeight: "bold",
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Sleep Apnea and Breathing problems
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        fontWeight: "bold",
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Low Self Confidence and Low Self Esteem
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Body Pain and Difficulty with Physical Activities- Low
                      Energy and Tiredness always.
                    </p>
                  </li>
                  <li>
                    <p
                      style={{
                        ...p,
                        fontWeight: "bold",
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        "-webkit-text-indent": "-21pt",
                        textIndent: "-21pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      And mostly all other causes of Death (Mortality)
                    </p>
                  </li>
                </ul>
                </>}
              
              </li>
            </ul>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <h3
              className="h3"
              style={{
                ...h3,
                paddingTop: "4pt",
                paddingLeft: "6pt",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
                textDecoration:"none"
              }}
            >
              Why is Balanced Nutrition and Adequate
              <span className="s18" style={s18}>
                {" "}
              </span>
              Macro-Nutrients Important for you everyday ?
            </h3>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <p
              style={{
                ...p,
                paddingLeft: "12pt",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              (Quality Protein, Quality Carbs, Dietary Fat from good Source and
              Natural Fibre)
            </p>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  Please find the Suggested New Macronutrients calculation
                  (Page.no 5) for you to achieve your Fitness Goals.
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  (Valid only for 2-3 weeks starting from now, after that based
                  on your performance and progress, I need to give you new
                  calculations to achieve your Fitness goals).
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  Right now you are in the Normal Category of the BMI chart But
                  You are Very close to Overweight category, if you follow an
                  Unhealthy Lifestyle with Imbalanced Nutrition, it will soon
                  lead you into Overweight Category.
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  Without good nutrition, your body is more prone to disease,
                  infection, fatigue, and poor performance.
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  A Well Balanced intake of Macronutrients are essential for
                  attaining Optimal Physical Health, Muscle Growth and to
                  increasing your Immunity levels.
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Manage A Healthy Weight
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Gives You Adequate Energy and Increases Focus
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Reduces The Risk of Chronic Disease.
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Your Organs and tissues need proper nutrition to work
                  effectively.
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  Without good nutrition, your body is more prone to disease,
                  infection, fatigue, and poor performance.
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "justify",
                    textAlign: "justify",
                  }}
                >
                  A Balanced Diet includes foods that are High in Vitamins,
                  Minerals, and other Nutrients and Low in Unhealthy Fats and
                  Sugars.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <p
          style={{
            ...p,
            paddingTop: "4pt",
            paddingLeft: "29pt",
            "-webkit-text-indent": "36pt",
            textIndent: "36pt",
            "-webkit-text-align": "justify",
            textAlign: "justify",
          }}
        >
         
            Your Everyday Food Intake must have a Balanced Quantity of{" "}
            Macro Nutrients like{" "}
         
          
            Protein, Fat, Carbohydrates and Fibre
  . 
          
            Which is one of the main reasons why you are Unable to Reach your
            Fitness Goals.
         
        </p>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
          <br />
        </p>
        <p
         
         className="s21"
          style={{
            ...s21,
            paddingTop: "1pt",
           paddingLeft: "48px",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",

            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          Importance of Protein ?
        </p>
        <ul style={{ paddingLeft:"68px", lineHeight:"30px"}}>
          <li className="li">
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "46px",
                "-webkit-text-indent": "-7pt",
                textIndent: "-7pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Build Protein is an important building block of bones, muscles,
              cartilage and skin. In fact,your hair and nails are mostly
              protein.
            </p>
          </li>
          <li className="li">
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "46px",
                "-webkit-text-indent": "-7pt",
                textIndent: "-7pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Repair Your body uses it to build and repair tissue.
            </p>
          </li>
          <li className="li">
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "46px",
                "-webkit-text-indent": "-7pt",
                textIndent: "-7pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Oxygenate Red blood cells contain a protein compound that carries
              oxygen throughout the body. This helps supply your entire body
              with the nutrients it needs.
            </p>
          </li>
          <li className="li">
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "46px",
                "-webkit-text-indent": "-7pt",
                textIndent: "-7pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Digest About half the dietary protein that you consume each day
              goes into making enzymes, which aids in digesting food, and making
              new cells and body chemicals
            </p>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <br />
          </li>
        </ul>
        {/* <p
          className="s21"
          style={{
            ...s21,
            paddingTop: "1pt",
            paddingLeft: "7pt",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          Hazards of NOT consuming enough Dietary Fibre everyday.
        </p>
        <ul>
          <li>
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Constipation
            </p>
          </li>
          <li>
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              May mess up your Blood sugar levels
            </p>
          </li>
          <li>
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Risk of developing Colon Cancer
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              May disrupt the Microbiome, leading to Chronic Health Issues.
            </p>
          </li>
          <li>
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Weight Fluctuations
            </p>
          </li>
          <li>
            <p
              className="s17"
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Can negatively affect your Cholesterol Levels
            </p>
          </li>
        </ul> */}
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <p
          className="s21"
          style={{
            ...s21,
            paddingLeft: "48px",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          What happens if you eat too many bad fats?
        </p>
        <p
          style={{
            ...p,
            paddingTop: "8pt",
            paddingBottom: "2pt",
            paddingLeft: "39pt",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            lineHeight: "114%",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          Too much fat in your diet, especially saturated fats, can raise your
          cholesterol level, which will increase the risk of heart disease.
        </p>
        <ul style={{ listStyleType: "disc" ,paddingLeft:'81pt'}}>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "2pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Weight gain and obesity
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "6pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Risk of Heart disease and related issues, like high blood pressure
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "6pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              High blood cholesterol levels and triglyceride levels
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Metabolic syndrome
            </p>
            
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Risk of developing Prediabetes, Insulin resistance and Type 2
              diabetes
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Fatty liver disease
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Stroke
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Risk of Neurological problems
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                lineHeight: "114%",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Gastrointestinal problems, which can also cause issues with the
              immune system and inflammation
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Increased risk of age-related vision loss
            </p>
          </li>
        </ul>
       
        
        
      
        <br /> <br />
        
        <p
          className="s21"
          style={{
            ...s21,
            paddingTop: "1pt",
           paddingLeft: "48px",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",

            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          Why do you need to Consume Adequate amount of Protein everyday?
        </p>
        <br/>
        <ul style={{color:'black', paddingLeft:"53pt", lineHeight:"28px"}}>
          <li  
        >
            Numerous studies suggest that a High-Protein Diet accompanied by
            Moderate Exercising will majorly assist you in achieving Healthy Fat
            Loss.
          </li>
          <li style={{color:'black'}}>
            Quality Protein will satiate your Hunger Levelsand keep you full for
            longer.
          </li>
          <li style={{color:'black'}}>
            Adequate Protein will help in Increasing your Muscle Strength and
            Protect your joints.
          </li>
          <li style={{color:'black'}}>
            Adequate Protein will help in Increasing your Metabolism and keep
            you satiated for a longer time.
          </li>
          <li style={{color:'black'}}>
            Consuming Quality Protein will Lower your Blood Pressure, compared
            to consuming Calorie dense Oily food or High Sugary food.
          </li>
          <li>Gives You Adequate Energy and Increases Focus</li>
          <li>Reduces The Risk of Chronic Disease</li>
        </ul>
        <h3 style={{"color": "red", paddingLeft:"48px"}}>Hazards of NOT consuming enough Dietary Fibre everyday.</h3>
            <ul style={{paddingLeft:"53pt", lineHeight:"28px", color : "black"}}>
                <li>Constipation</li>
                <li>May mess up your Blood sugar levels</li>
                <li>Risk of developing Colon Cancer</li>
                <li>May disrupt the Microbiome, leading to Chronic Health Issues.</li>
                <li>Weight Fluctuations</li>
                <li>Can negatively affect your Cholesterol Levels</li>
            </ul>

        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <br />
        <h3 style={{"color": "red",paddingLeft:"48px"}}>Hazards of Excess &nbsp;&nbsp;Sugar Intake:</h3>
            <ul style={{ paddingLeft:"53pt", lineHeight:"28px", color:"black"}}>
                <li>Science based evidence proves that consuming Dietary Fat won't make you Fat, but consuming excess
                    Sugar or excess Simple Carbs will make you Fat quicker and Increase your chances of developing
                    Lifestyle Disorders.</li>
                <li>If you feel like having something sweet in your mouth, eat a lot of Fresh Fruits and munch on some
                    Dried Fruits as well.</li>
            </ul>
            <p style={{paddingLeft:"53pt",color: 'black'}}>Everyday, You Must consume Micro Nutrients like Calcium,
                    Magnesium, Zinc, Iron and all the other Vitamins
                    and Minerals. Which is very essential for Optimal Health and should be consumed very
                essentially
               EVERYDAY for Optimal Performance of
                    your Joints, Muscles, Brain
                    and other Organs. And for your Optimal
                Healthy Well-Being.
            </p>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <p
          className="s18"
          style={{
            ...s18,
            paddingLeft: "48px",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          Micro-Nutrients that you must include everyday in your Diet:
        </p>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <p
          className="s21"
          style={{
            ...s21,
            paddingLeft: "48px",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          Calcium:
        </p>
        <ul style={{ listStyleType: "disc", paddingLeft:"53pt", lineHeight:"31px" }}>
          <li>
            {/* <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                lineHeight: "114%",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            > */}
            Your body needs calcium to build and maintain{" "}
           strong bones.Your
            heart, muscles and nerves
          
              also need calcium to function properly. Some studies suggest that
              calcium, along with vitamin D, may have benefits beyond bone
              health, perhap
            protecting against cancer, diabetes and high blood pressure.
            <br />
            <br />
            {/* </p> */}
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <table
              className="table"
              style={{
                ...table,
                borderCollapse: "collapse",
                marginLeft: "6.5pt",
              }}
              S={0}
            >
              <tbody className="tbody">
                <tr style={{ ...tbody, height: "31pt" }}>
                  <td
                    style={{
                      width: "538pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#772E53",
                    }}
                    colSpan="2"
                  >
                    <p
                      className="s1"
                      style={{
                        ...s1style,
                        paddingTop: "6pt",
                        paddingLeft: "121pt",
                        paddingRight: "119pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Recommended Daily Requirement - Calcium
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "20pt" }}>
                  <td
                    style={{
                      width: "235pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "95pt",
                        paddingRight: "94pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Women
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...p,
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "126pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      1,000 mg
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "20pt" }}>
                  <td
                    style={{
                      width: "235pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "95pt",
                        paddingRight: "94pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Men
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "126pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      1,000 mg
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
          
          </li>
         
         
         
         
        </ul>
        <p
              className="s21"
              style={{
                ...s21,
                paddingTop: "4pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Iron:
            </p>
        <ul style={{paddingLeft:"53pt"}}>
        <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Iron is an important component of Hemoglobin, 
              the substance in red blood cells that carries
              Oxygen 
              
                from your lungs to transport it throughout your body.
              
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                lineHeight: "114%",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
             
                If you don’t have enough iron, your body can’t make enough
                healthy oxygen-carrying red blood cells. A lack of red blood
                cells is called Iron Deficiency or{" "}
              Anaemia.
            </p>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <br />

            <table
              className="table"
              style={{
                ...table,
                borderCollapse: "collapse",
                marginLeft: "6.5pt",
              }}
              S={0}
            >
              <tbody className="tbody">
                <tr style={{ ...tbody, height: "30pt" }}>
                  <td
                    style={{
                      width: "538pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt ",
                      backgroundColor: "#772E53",
                    }}
                    colSpan="2"
                  >
                    <p
                      className="s1"
                      style={{
                        ...s1style,
                        paddingTop: "5pt",
                        paddingLeft: "121pt",
                        paddingRight: "119pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Recommended Daily Requirement - Iron
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "20pt" }}>
                  <td
                    style={{
                      width: "235pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...p,
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "95pt",
                        paddingRight: "94pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Women
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "130pt",
                        paddingRight: "128pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      18 mg
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "20pt" }}>
                  <td
                    style={{
                      width: "235pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "95pt",
                        paddingRight: "94pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Men
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "130pt",
                        paddingRight: "128pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      8 mg
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>

            <br />

            <br />
          
          </li>
        </ul>
        <p
              className="s21"
              style={{
                ...s21,
                paddingTop: "4pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Zinc:
            </p>
            <ul style={{paddingLeft:"53pt"}}>
            <li>
            <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Zinc is used for treatment and prevention of zinc deficiency and
              its consequences, including Stunted growth &nbsp;
              and acute diarrhea in children, and Slow wound healing.
            </p>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
          </li>
          <li>
            <p
              style={{
                ...p,
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",

                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
             It is also used for 
              Boosting the Immune System, 
                treating the common cold and recurrent ear infections, and{" "}
            Preventing Respiratory Infections.
            </p>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <br />
            <br />
            <table
              className="table"
              style={{
                ...table,
                borderCollapse: "collapse",
                marginLeft: "6.5pt",
              }}
              S={0}
            >
              <tbody className="tbody">
                <tr style={{ ...tbody, height: "30pt" }}>
                  <td
                    style={{
                      width: "538pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt ",
                      backgroundColor: "#772E53",
                    }}
                    colSpan="2"
                  >
                    <p
                      className="s1"
                      style={{
                        ...s1style,
                        paddingTop: "6pt",
                        paddingLeft: "121pt",
                        paddingRight: "119pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Recommended Daily Requirement - Zinc
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "21pt" }}>
                  <td
                    style={{
                      width: "235pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "95pt",
                        paddingRight: "94pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Women
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "130pt",
                        paddingRight: "128pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      13 mg
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "20pt" }}>
                  <td
                    style={{
                      width: "235pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "95pt",
                        paddingRight: "94pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Men
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "130pt",
                        paddingRight: "128pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      17 mg
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
           
          </li>
            </ul>
            <p
              className="s21"
              style={{
                ...s21,
                paddingTop: "4pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Magnesium:
            </p>
            <ul style={{paddingLeft:"53pt"}}>
            <li>
            {/* <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",

                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            > */}
            The kidneys primarily control levels of magnesium within the body
            and excrete magnesium into the urine each day, which is one reason
            why Urinary Excretion is reduced when magnesium intake is low.
            {/* </p> */}
          </li>
          <li>
            {/* <p
              style={{
                ...p,
                paddingTop: "1pt",
                paddingLeft: "30pt",
                "-webkit-text-indent": "-18pt",
                textIndent: "-18pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            > */}
            Magnesium is actually the least abundant serum electrolyte in the
            body, but it’s still Extremely important for your Metabolism, Enzyme
            Function, Energy Production and for Balancing Nitric Oxide in the
            body.
            {/* </p> */}
            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <table
              className="table"
              style={{
                ...table,
                borderCollapse: "collapse",
                marginLeft: "13.5pt",
              }}
              S={0}
            >
              <tbody className="tbody">
                <tr style={{ ...tbody, height: "31pt" }}>
                  <td
                    style={{
                      width: "531pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt ",
                      backgroundColor: "#772E53",
                    }}
                    colSpan="2"
                  >
                    <p
                      className="s1"
                      style={{
                        ...s1style,
                        paddingTop: "6pt",
                        paddingLeft: "106pt",
                        paddingRight: "104pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Recommended Daily Requirement - Magnesium
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "20pt" }}>
                  <td
                    style={{
                      width: "228pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "40pt",
                        paddingRight: "38pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Women (Age 19-30 years)
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "130pt",
                        paddingRight: "128pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      310 mg
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "20pt" }}>
                  <td
                    style={{
                      width: "228pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "40pt",
                        paddingRight: "38pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Women (Age 31 &amp; above)
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "130pt",
                        paddingRight: "128pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      320 mg
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "20pt" }}>
                  <td
                    style={{
                      width: "228pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      backgroundColor: "#FEF4E9",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "40pt",
                        paddingRight: "38pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      Men
                    </p>
                  </td>
                  <td
                    style={{
                      width: "303pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                    }}
                  >
                    <p
                      className="s5"
                      style={{
                        ...s5,
                        paddingTop: "2pt",
                        paddingLeft: "130pt",
                        paddingRight: "128pt",
                        "-webkit-text-indent": "0pt",
                        textIndent: "0pt",
                        "-webkit-text-align": "center",
                        textAlign: "center",
                      }}
                    >
                      400 mg
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
           
           
          </li>
            </ul>

            <p
              style={{
                ...p,
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              <br />
            </p>
            <p
              className="s21"
              style={{
                ...s21,
                paddingTop: "4pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Why is a Balanced Diet Important ?
            </p>
            <ul style={{ listStyleType: "disc", paddingLeft:"53pt" }}>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Your Organs and tissues need proper nutrition to work effectively.
                </p>
              </li>
              <li>
                <p
                  className="s17"
                  style={{
                    ...s17,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    lineHeight: "114%",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                 Without good nutrition your body is more prone to 
                    Disease, Infection, Fatigue, and Poor Performance.
                 
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    lineHeight: "114%",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  A Balanced diet includes foods that are High in Vitamins, Minerals, and other Nutrients and Low in Unhealthy Fats and Sugars.
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
            </ul>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <p
              className="s21"
              style={{
                ...s21,
                paddingLeft: "48px",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Tips for a Good Nutritious Diet.
            </p>
            <ul style={{ listStyleType: "disc",paddingLeft:"53pt" }}>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    lineHeight: "114%",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Good Nutrition intake means your body gets all the Essential
                  Nutrients, Vitamins, and Minerals it needs to work at its
                  best.
                </p>
              </li>
              <li>
                <p
                  className="s17"
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    lineHeight: "114%",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Plan your meals and snacks to include Nutrient-Dense foods
                  that are also Low in Calories.
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Eat plenty of Fruit.
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Eat plenty of Vegetables.
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Eat Whole grains instead of refined or processed grains.
                </p>
                <p
                  style={{
                    ...p,
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
              </li>
            </ul>
            <p
              className="s21"
              style={{
                ...s21,
                paddingTop: "4pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Benefits of Exercising Regularly
            </p>
            <ul style={{ listStyleType: "disc",paddingLeft:"53pt" }}>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Improve Bone Density
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Improve Metabolism
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Improve Heart Health
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Balance Hormone production
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Improve Testosterone production for men
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Improve Confidence and Mood
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Improve Sleep
                </p>
              </li>
              <li>
                <p
                  style={{
                    ...p,
                    paddingTop: "1pt",
                    paddingLeft: "30pt",
                    "-webkit-text-indent": "-18pt",
                    textIndent: "-18pt",
                    "-webkit-text-align": "left",
                    textAlign: "left",
                  }}
                >
                  Improve Brain Function
                </p>
              </li>
            </ul>
            <p
              className="s21"
              style={{
                ...s21,
                paddingTop: "4pt",
                paddingLeft: "48px",
                "-webkit-text-indent": "0pt",
                textIndent: "0pt",
                "-webkit-text-align": "left",
                textAlign: "left",
              }}
            >
              Some Tips to attain Optimal Physical Health and Well-Being.
            </p>
          <ul style={{ listStyleType: "disc",paddingLeft:"53pt" }}>  <li>
              <p
                style={{
                  ...p,
                  paddingTop: "1pt",
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Reduce your Calorie Intake, but DO NOT Crash Diet.
              </p>
            </li>
            <li>
              <p
                style={{
                  ...p,
                  paddingTop: "1pt",
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Crash dieting will reduce your Metabolism and make you more
                Lazy.
              </p>
            </li>
            <li>
              <p
                style={{
                  ...p,
                  paddingTop: "1pt",
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Prolonged crash dieting will result in major Hormonal Imbalance.
              </p>
              <p
                style={{
                  ...p,
                  "-webkit-text-indent": "0pt",
                  textIndent: "0pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li>
              <p
                style={{
                  ...p,
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                So be in a Healthy Calorie Deficit until you reach your
                Efficient Weight range.
              </p>
              <p
                style={{
                  ...p,
                  "-webkit-text-indent": "0pt",
                  textIndent: "0pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li>
              <p
                style={{
                  ...p,
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Increase your everyday Protein intake.
              </p>
            </li>
            <li>
              <p
                style={{
                  ...p,
                  paddingTop: "1pt",
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Get 7-8 hours of Quality Sleep every day.
              </p>
            </li>
            <li>
              <p
                style={{
                  ...p,
                  paddingTop: "1pt",
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Manage and Balance your Stress levels.
              </p>
            </li>
            <li>
              <p
                style={{
                  ...p,
                  paddingTop: "1pt",
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Increase your everyday Physical activities.
              </p>
            </li>
            <li>
              <p
                style={{
                  ...p,
                  paddingTop: "1pt",
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  lineHeight: "114%",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Eat a variety of Fresh Vegetables and Fruits, without adding
                high-calorie dressing or excess Salt and Sugars.
              </p>
            </li>
            <li>
              <p
                className="s24"
                style={{
                  ...s24,
                  paddingTop: "1pt",
                  paddingLeft: "30pt",
                  "-webkit-text-indent": "-18pt",
                  textIndent: "-18pt",
                  "-webkit-text-align": "left",
                  textAlign: "left",
                }}
              >
                Drink at least 3.8 Litres of water Per Day
              </p>
            </li></ul>
        <p
          className="s25"
          style={{
            ...s25,
            paddingTop: "5pt",
            paddingLeft: "22pt",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          (It’s recommended that you to consume 1 Litre of water for every 20 Kg
          per day)
        </p>
        <p
          style={{
            ...p,
            paddingLeft: "14pt",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        ></p>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <table
          className="table"
          style={{
            ...table,
            borderCollapse: "collapse",
            marginLeft: "6.125pt",
          }}
          S={0}
        >
          <tbody className="tbody">
            <tr style={{ ...tbody, height: "45pt" }}>
              <td
                style={{
                  width: "538pt",
                  borderTopStyle: "solid",

                  borderLeftStyle: "solid",

                  borderBottomStyle: "solid",

                  borderRightStyle: "solid",

                  backgroundColor: "#772E53",
                }}
                colSpan="2"
              >
                <p
                  className="s26"
                  style={{
                    ...s26,
                    paddingTop: "5pt",
                    paddingLeft: "197pt",
                    paddingRight: "195pt",
                    "-webkit-text-indent": "0pt",
                    textIndent: "0pt",
                    "-webkit-text-align": "center",
                    textAlign: "center",
                  }}
                >
                  Conclusion
                </p>
              </td>
            </tr>
            <tr style={{ height: "78pt" }}>
              <td
                style={{
                  width: "538pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",

                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",

                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",

                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <ul style={{ listStyleType: "disc" }}>
                  {leadDetails.occupation !== null &&
                  leadDetails.occupation !== undefined &&
                  leadDetails.occupation !== "" ? (
                    <li>
                      <p
                        className="s27"
                        style={{
                          ...s27,
                          paddingTop: "4pt",
                          paddingLeft: "41pt",
                          "-webkit-text-indent": "-18pt",
                          textIndent: "-18pt",
                          "-webkit-text-align": "left",
                          textAlign: "left",
                        }}
                      >
                        Since you are a &nbsp;
                        <b className="s28" style={s28}>
                          {leadDetails.occupation}
                        </b>
                        , you need to always perform at your best.
                      </p>
                    </li>
                  ) : (
                    ""
                  )}

                  <li>
                    <p
                      className="s27"
                      style={{
                        ...s27,
                        paddingTop: "2pt",
                        paddingLeft: "41pt",
                        paddingRight: "2pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        lineHeight: "114%",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      Hope you got a Clarity on your current Health status
                      through this extensive Report.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr style={{ height: "60pt" }}>
              <td
                style={{
                  width: "538pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",

                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",

                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",

                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <p
                      className="s27"
                      style={{
                        ...s27,
                        paddingTop: "4pt",
                        paddingLeft: "41pt",
                        paddingRight: "3pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        lineHeight: "114%",
                        "-webkit-text-align": "left",
                        textAlign: "left",
                      }}
                    >
                      I would advise you to take Action on your Health
                      Immediately, for the sake of you and your family’s
                      Well-being.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr style={{ height: "78pt" }}>
              <td
                style={{
                  width: "538pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",

                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",

                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",

                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",

                  backgroundColor: "FFD9D9",
                }}
                colSpan="2"
              >
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <p
                      className="s27"
                      style={{
                        ...s27,
                        paddingTop: "4pt",
                        paddingLeft: "41pt",
                        paddingRight: "3pt",
                        "-webkit-text-indent": "-18pt",
                        textIndent: "-18pt",
                        lineHeight: "114%",
                        "-webkit-text-align": "justify",
                        textAlign: "justify",
                      }}
                    >
                      Many people don’t have anybody who could say this to them,
                      but you have me to advise you and guide you to attaining
                      Complete Health and Well-being. So please use our team’s
                      expertise and gain back your Health and Youth.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <p
          className="s29"
          style={{
            ...s29,
            paddingTop: "4pt",
            paddingLeft: "6pt",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            lineHeight: "114%",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          If You have any other doubts, please feel free to book a Call with our
          Health Consultant or your Nutritionist/Trainer. We will be happy to
          Assist you.
        </p>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <p
          style={{
            ...p,
            paddingLeft: "96pt",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "center",
            textAlign: "center",
          }}
        >
          <span className="h4" style={{ ...h4, backgroundColor: "#FBE4CC" }}>
            {props.coachName}
          </span>
        </p>
        <p
          style={{
            ...p,
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          <br />
        </p>
        <p
          className="s29"
          style={{
            ...s29,
            paddingLeft: "6pt",
            "-webkit-text-indent": "0pt",
            textIndent: "0pt",
            lineHeight: "114%",
            "-webkit-text-align": "left",
            textAlign: "left",
          }}
        >
          For Regular Tips &amp; Motivation about Health and Wellness, Please
          Follow us on Instagram @
          <span className="s30" style={s30}>
            VMax.Fit
          </span>
        </p>
      </div>
    
  );
}

export default HFReport;
