import React, { useState, useEffect, useContext } from "react";
import { ATLAS_URI } from "../../Constants";
import axios from "axios";
import stateContext from "../../context/StateContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AndOr } from "./ClientDietPlansMenuTable";

export const ClientDietPlansFoodRow = ({
  state,
  setState,
  orFoodIdx,
  andFoodIdx,
  mealIdx,
  menueIdx,
  orFoodArr,
  andFoodArr,
  orFood,
  andFood,
}) => {
  const [inputName, setInputName] = useState("");
 
  const [inpuQuantity, setInputQuantity] = useState("");
  const [inputUnit, setInputUnit] = useState("");
  const [inputInstruction, setInputInstruction] = useState("");

  const [selectFood, setSelectFood] = useState("");
  const [searchFood, setSearchFood] = useState("");
  const [foodArray, setFoodArray] = useState([]);

  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  let objectUnitMap = {
    "Solid Food": {
      gms: 1,
      "Small Cup": 100,
      "Medium Cup": 200,
      "Large Cup": 350,
    },
    "Piece Food Item": {
      gms: 1,
      "Small Piece": 35,
      "Medium Piece": 70,
      "Large Piece": 100,
    },

    "Unit Food Item": {
      gms: 1,
      "Small Piece": 35,
      "Medium Piece": 70,
      "Large Piece": 100,
    },
    "Liquid Food": {
      ml: 1,
      "Small Glass": 100,
      "Medium Glass": 250,
      "Large Glass": 350,
    },
  };

  const foodDetails = async () => {
    await axios
      .get(
        `${ATLAS_URI}/getFoodNutrientDataWOparams?search=${searchFood}`,

        configToken
      )
      .then((res) => {
        setFoodArray(res.data.body.foodDetails);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (searchFood && searchFood.length >= 1) {
      foodDetails();
    }
  }, [searchFood]);

  const calculateMenueCalories = () => {
    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));
      for (let mealIdx = 0; mealIdx < newState.food_meals.length; mealIdx++) {
        for (
          let menuIdx = 0;
          menuIdx < newState.food_meals[mealIdx].foodMenues.length;
          menuIdx++
        ) {
          let calories = newState.food_meals[mealIdx].foodMenues[
            menuIdx
          ].food.reduce((sum, item) => {
            let tmp = 0;
            for (let j of item) {
              let calories = j.macronutrients?.calories;
              let obj = objectUnitMap[j.Category] ?? {};
              let q = j.quantity ? j.quantity : 0;
              let Q = j.Quantity ? j.Quantity : 0;

              let u = j.unit ? j.unit : Object.keys(obj)[0];
              let calcCalorie = (
                (calories
                  ? (parseFloat(calories) / parseInt(Q)) * parseInt(q)
                  : 0.0) * obj[u]
              ).toFixed(0);
              if (tmp < parseFloat(calcCalorie)) {
                tmp = parseFloat(calcCalorie);
              }
            }

            sum += parseInt(tmp);
            return sum;
          }, 0);
          newState.food_meals[mealIdx].foodMenues[menuIdx].calories = calories;
        }
      }

      return { ...newState };
    });
  };

  useEffect(() => {
    if (
      state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][orFoodIdx]
        .food_item
    ) {
      setInputName(
        state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
          orFoodIdx
        ].food_item
      );
    }

    if (
      state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][orFoodIdx]
        .Typ
    ) {
      setSelectFood(
        state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
          orFoodIdx
        ].Typ === "Veg"
          ? "Veg"
          : "Non-Veg"
      );
    }
    //quantity set
    if (
      state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][orFoodIdx]
        .quantity
    ) {
      setInputQuantity(
        state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
          orFoodIdx
        ].quantity
      );
    }

    //select dropdown set
    if (
      state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][orFoodIdx]
        .unit !== ""
    ) {
      setInputUnit(
        state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
          orFoodIdx
        ].unit
      );
    }
    //instuction set
    if (
      state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][orFoodIdx]
        .food_instructions !== ""
    ) {
      setInputInstruction(
        state.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
          orFoodIdx
        ].food_instructions
      );
    }

    calculateMenueCalories();
  }, []);

  function handleNumberChange(event, mealIdx, menueIdx, andFoodIdx, orFoodIdx) {
    let quantitys = event.target.value;
    let quantityOfFood;
    const inputValues = quantitys.replace(/^0+/, "");
    const numericValue =parseInt(inputValues, 10)|| 0
    if (numericValue >= 1 && numericValue <= 9999) {
      setInputQuantity(event.target.value);
      quantityOfFood=numericValue;
    } else {
      setInputQuantity("");
    }
   
    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));
      newState.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
        orFoodIdx
      ].quantity = quantityOfFood;
      return newState;
    });
    calculateMenueCalories();
  }

  const handleSelectChange = (
    event,
    mealIdx,
    menueIdx,
    andFoodIdx,
    orFoodIdx
  ) => {
    setInputUnit(event.target.value);
    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));
      newState.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
        orFoodIdx
      ].unit = event.target.value;
      return newState;
    });
    calculateMenueCalories();
  };

  const handleNameClick = ( mealIdx, menueIdx, andIdx, orIdx )=>{
      let Initialfood = [
        [
          {
            macronutrients: {
              calories: null,
              protein: 0,
              carbs: 0,
              fibre: 0,
              fats: 0,
            },
            micronutrients: {
              IRON_mg: 0,
              ZINC_mg: 0,
              NIACIN_mg: 0,
              CALCIUM_mg: 0,
              SELENIUM_mg: 0,
              THIAMINE_mg: 0,
              MAGNESIUM_mg: 0,
              MANGANESE_mg: 0,
              POTASSIUM_mg: 0,
              VITAMIN_A_mg: 0,
              VITAMIN_C_mg: 0,
              VITAMIN_D_mg: 0,
              VITAMIN_E_mg: 0,
              VITAMIN_K_mg: 0,
              FOLIC_ACID_mg: 0,
              PHOSPHORUS_mg: 0,
              RIBOFLAVIN_mg: 0,
            },
            id: "",
            Typ: "",
            unit: "",
            Category: "",
            Quantity: 0,
            quantity: 0,
            food_item: "",
            defaultValue: 0,
            Units_gms_Ml_Piece: "",
            food_instructions: "",
          },
        ],
      ];
   
      setState((state) => {
        let newState = JSON.parse(JSON.stringify(state));
        newState.food_meals[mealIdx].foodMenues[menueIdx].food[andIdx][orIdx] = Initialfood
        return { ...newState }
      });
      
    }

  function deleteFood(mealIdx, menueIdx, andIdx, orIdx, orLength) {
    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));

      let food = newState.food_meals[mealIdx].foodMenues[menueIdx].food;
      if (orLength === 1)
        newState.food_meals[mealIdx].foodMenues[menueIdx].food = food.filter(
          (item, idx) => idx !== andIdx
        );
      else
        newState.food_meals[mealIdx].foodMenues[menueIdx].food[andIdx] = food[
          andIdx
        ].filter((item, idx) => idx !== orIdx);

      const foods = newState.food_meals[mealIdx].foodMenues[menueIdx].food;

      // return previous state if menu items length is 1
      if (newState.food_meals[mealIdx].foodMenues.length === 1) {
        return newState;
      }

      // delete menu from state if menu length becomes 0
      if (foods.length === 0) {
        const menues = newState.food_meals[mealIdx].foodMenues.filter(
          (_, id) => id !== menueIdx
        );
        newState.food_meals[mealIdx].foodMenues = menues;
      }

      return { ...newState };
    });
  }

  function switchAndOr(mealIdx, menuIdx, andIdx, orIdx, currState) {
    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));
      let XswithIdx, YSwitchIdx;
      let food = newState.food_meals[mealIdx].foodMenues[menuIdx].food;
      if (food[andIdx].length - 1 == orIdx) {
        XswithIdx = andIdx + 1;
        YSwitchIdx = orIdx - 1;
      } else {
        YSwitchIdx = orIdx + 1;
        XswithIdx = andIdx;
      }
      if (currState == "or") {
        let tempArr = food[XswithIdx].slice(YSwitchIdx);
        food[XswithIdx] = food[XswithIdx].filter((_, idx) => idx < YSwitchIdx);
        food = [
          ...food.slice(0, XswithIdx + 1),
          tempArr,
          ...food.slice(XswithIdx + 1),
        ];
      } else {
        let tempArr = food[XswithIdx];
        food = food.filter((_, idx) => idx !== XswithIdx);
        food[XswithIdx - 1] = [...food[XswithIdx - 1], ...tempArr];
      }

      newState.food_meals[mealIdx].foodMenues[menuIdx].food = food;

      return { ...newState };
    });
  }

  const handleInstruction = (
    event,
    mealIdx,
    menueIdx,
    andFoodIdx,
    orFoodIdx
  ) => {
    setInputInstruction(event.target.value);

    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));
      newState.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
        orFoodIdx
      ].food_instructions = event.target.value;
      return newState;
    });
  };

  const handleOptionClick = (
    option,
    mealIdx,
    menueIdx,
    andFoodIdx,
    orFoodIdx
  ) => {
    calculateMenueCalories();
    setInputName(option.food_item_name);
    setSelectFood(option.Type === "Veg" ? "Veg" : "Non-Veg");

    setFoodArray([]);
    let foodVal = {
      macronutrients: {
        calories: parseInt(option.calories),
        protein: option.protein,
        carbs: option.carbs,
        fibre: option.fibre,
        fats: option.fats,
      },
      micronutrients: {
        IRON_mg: option.IRON_mg,
        ZINC_mg: option.ZINC_mg,
        NIACIN_mg: option.NIACIN_mg,
        CALCIUM_mg: option.CALCIUM_mg,
        SELENIUM_mg: option.SELENIUM_mg,
        THIAMINE_mg: option.THIAMINE_mg,
        MAGNESIUM_mg: option.MAGNESIUM_mg,
        MANGANESE_mg: option.MANGANESE_mg,
        POTASSIUM_mg: option.POTASSIUM_mg,
        VITAMIN_A_mg: option.VITAMIN_A_mg,
        VITAMIN_C_mg: option.VITAMIN_C_mg,
        VITAMIN_D_mg: option.VITAMIN_D_mg,
        VITAMIN_E_mg: option.VITAMIN_E_mg,
        VITAMIN_K_mg: option.VITAMIN_K_mg,
        FOLIC_ACID_mg: option.FOLIC_ACID_mg,
        PHOSPHORUS_mg: option.PHOSPHORUS_mg,
        RIBOFLAVIN_mg: option.RIBOFLAVIN_mg,
      },
      id: String(andFoodIdx + orFoodIdx),
      foodid : option.id,
      Typ: option.Type,
      unit: "",
      Category: option.Category,
      Quantity: option.Quantity,
      quantity: "",
      food_item: option.food_item_name,
      defaultValue: "",
      Units_gms_Ml_Piece: option.Units_gms_Ml_Piece,
      food_instructions: "",
    };

    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));
      newState.food_meals[mealIdx].foodMenues[menueIdx].food[andFoodIdx][
        orFoodIdx
      ] = foodVal;
      return newState;
    });
  };

  let obj = {
    "Small Cup": 100,
    "Medium Cup": 200,
    "Large Cup": 350,
    "Small Piece": 35,
    "Medium Piece": 70,
    "Large Piece": 100,
    "Small Glass": 100,
    "Medium Glass": 250,
    "Large Glass": 350,
    gms: 1,
    ml: 1,
  };
  const calculateItem = (foodItem) => {
    let q = foodItem.quantity;
    if (foodItem.quantity == "") {
      q = 0;
    }
    let Q = foodItem.Quantity;

    let u = foodItem.unit;

    let obj = objectUnitMap[foodItem.Category] ?? {};
    if (foodItem.unit == "" || !foodItem.unit)
      foodItem.unit = Object.keys(obj)[0];

    return {
      macronutrients: {
        calories: (calories) =>
          Number(
            (
              (calories
                ? (parseFloat(calories) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(0)
          ),
        carbs: (carbs) =>
          Number(
            (
              (carbs ? (parseFloat(carbs) / parseInt(Q)) * parseInt(q) : 0.0) *
              obj[u]
            ).toFixed(0)
          ),
        protein: (protein) =>
          Number(
            (
              (protein
                ? (parseFloat(protein) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(0)
          ),
        fats: (fats) =>
          Number(
            (
              (fats ? (parseFloat(fats) / parseInt(Q)) * parseInt(q) : 0.0) *
              obj[u]
            ).toFixed(0)
          ),
        fibre: (fibre) =>
          Number(
            (
              (fibre ? (parseFloat(fibre) / parseInt(Q)) * parseInt(q) : 0.0) *
              obj[u]
            ).toFixed(0)
          ),
      },
      micronutrients: {
        VITAMIN_A_mg: (VITAMIN_A_mg) =>
          Number(
            (
              (VITAMIN_A_mg
                ? (parseFloat(VITAMIN_A_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        VITAMIN_D_mg: (VITAMIN_D_mg) =>
          Number(
            (
              (VITAMIN_D_mg
                ? (parseFloat(VITAMIN_D_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        VITAMIN_E_mg: (VITAMIN_E_mg) =>
          Number(
            (
              (VITAMIN_E_mg
                ? (parseFloat(VITAMIN_E_mg) / parseInt(Q)) *
                  parseInt(foodItem.quantity)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        VITAMIN_K_mg: (VITAMIN_K_mg) =>
          Number(
            (
              (VITAMIN_K_mg
                ? (parseFloat(VITAMIN_K_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        THIAMINE_mg: (THIAMINE_mg) =>
          Number(
            (
              (foodItem.micronutrients.THIAMINE_mg
                ? (parseFloat(THIAMINE_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        RIBOFLAVIN_mg: (RIBOFLAVIN_mg) =>
          Number(
            (
              (RIBOFLAVIN_mg
                ? (parseFloat(RIBOFLAVIN_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        NIACIN_mg: (NIACIN_mg) =>
          Number(
            (
              (NIACIN_mg
                ? (parseFloat(NIACIN_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        FOLIC_ACID_mg: (FOLIC_ACID_mg) =>
          Number(
            (
              (FOLIC_ACID_mg
                ? (parseFloat(FOLIC_ACID_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        VITAMIN_C_mg: (VITAMIN_C_mg) =>
          Number(
            (
              (VITAMIN_C_mg
                ? (parseFloat(VITAMIN_C_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        IRON_mg: (IRON_mg) =>
          Number(
            (
              (IRON_mg
                ? (parseFloat(IRON_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        CALCIUM_mg: (CALCIUM_mg) =>
          Number(
            (
              (CALCIUM_mg
                ? (parseFloat(CALCIUM_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        PHOSPHORUS_mg: (PHOSPHORUS_mg) =>
          Number(
            (
              (PHOSPHORUS_mg
                ? (parseFloat(PHOSPHORUS_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        POTASSIUM_mg: (POTASSIUM_mg) =>
          Number(
            (
              (POTASSIUM_mg
                ? (parseFloat(POTASSIUM_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        SELENIUM_mg: (SELENIUM_mg) =>
          Number(
            (
              (SELENIUM_mg
                ? (parseFloat(SELENIUM_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        ZINC_mg: (ZINC_mg) =>
          Number(
            (
              (ZINC_mg
                ? (parseFloat(ZINC_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        MAGNESIUM_mg: (MAGNESIUM_mg) =>
          Number(
            (
              (MAGNESIUM_mg
                ? (parseFloat(MAGNESIUM_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
        MANGANESE_mg: (MANGANESE_mg) =>
          Number(
            (
              (MANGANESE_mg
                ? (parseFloat(MANGANESE_mg) / parseInt(Q)) * parseInt(q)
                : 0.0) * obj[u]
            ).toFixed(3)
          ),
      },
    };
  };
  let calc = calculateItem(orFood);

  return (
    <td style={{ border: "none", padding: "0px" }} colSpan={9}>
      <table
        width="100%"
        style={{ border: "none" }}
        key={orFoodIdx + andFoodIdx}
      >
        <tr
          style={{
            border: "1px solid #dddddd",
          }}
        >
          <td
            style={{
              width: "169px",
              minWidth: "169px",
              maxWidth: "169px",
              overflow: "hidden",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {selectFood === "Veg" ? (
                <img
                  src={require("../../img/icons8-vegetarian-48.png")}
                  alt="Vegatarian"
                  style={{ height: "20px" }}
                />
              ) : (
                ""
              )}
              {selectFood === "Non-Veg" ? (
                <img
                  src={require("../../img/icons8-non-vegetarian-food-symbol-48.png")}
                  alt="non-Veg"
                  style={{ height: "20px" }}
                />
              ) : (
                ""
              )}

              <input
                style={{ width: "100%", border: "none", outline: "none" }}
                type="text"
                placeholder="Search the food"
                value={inputName}
                maxLength={50}
                onClick={ () => {
                  handleNameClick( 
                    mealIdx,
                    menueIdx,
                    andFoodIdx,
                    orFoodIdx,
                  )
                }}
                onChange={(e) => {
                  setSearchFood(e.target.value);
                  setInputName(e.target.value);
                }}
              />
            </div>
            {foodArray.length > 0 ? (
              <ul
                style={{
                  height: "100px",
                  overflowY: "scroll",
                  cursor: "pointer",
                  position: "absolute",
                  backgroundColor: "white",
                }}
              >
                {foodArray.map((option, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() =>
                        handleOptionClick(
                          option,
                          mealIdx,
                          menueIdx,
                          andFoodIdx,
                          orFoodIdx
                        )
                      }
                      onKeyDown={() => {
                        handleOptionClick(
                          option,
                          mealIdx,
                          menueIdx,
                          andFoodIdx,
                          orFoodIdx
                        );
                      }}
                    >
                      {option.Type === "Veg" ? (
                        <img
                          src={require("../../img/icons8-vegetarian-48.png")}
                          alt="Vegatarian"
                          style={{ height: "20px" }}
                        />
                      ) : (
                        <img
                          src={require("../../img/icons8-non-vegetarian-food-symbol-48.png")}
                          alt="non-Veg"
                          style={{ height: "20px" }}
                        />
                      )}
                      {option.food_item_name}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <></>
            )}
          </td>

          <td
            style={{
              width: "120px",
              minWidth: "120px",
              maxWidth: "120px",
              overflow: "hidden",
              boxSizing: "border-box",
              padding: "10px 10px",
            }}
          >
            <input
              style={{
                width: "30%",
                height: "28px",
                border: "1px solid #dddddd",
                textAlign: "center",
                paddingTop: "5px",
                paddingBottom: "4px",
              }}
              type="number"
              placeholder="Quantity"
              value={inpuQuantity}
              min="0"
              max="9999"
              onKeyDown={(e) => {
               if (e.key === "." || e.key === "-" || e.key === "+" || e.key === "e") {
                  e.preventDefault();
                }
              }}
              onChange={(event) =>
                handleNumberChange(
                  event,
                  mealIdx,
                  menueIdx,
                  andFoodIdx,
                  orFoodIdx
                )
              }
            />
            <select
              style={{
                width: "70%",
                height: "28px",
                border: ".5px solid #dddddd",
              }}
              value={inputUnit}
              onChange={(event) =>
                handleSelectChange(
                  event,
                  mealIdx,
                  menueIdx,
                  andFoodIdx,
                  orFoodIdx
                )
              }
            >
              <option value="" disabled hidden>
                Select an option
              </option>

              {Object.keys(objectUnitMap[orFood.Category] ?? {}).map((key) => (
                <option value={key}>{key}</option>
              ))}
            </select>
          </td>

          <td
            style={{
              width: "40px",
              minWidth: "40px",
              maxWidth: "40px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              padding: "10px 10px",
            }}
          >
            {calc.macronutrients?.calories(orFood.macronutrients?.calories) 
              ? calc.macronutrients?.calories(orFood.macronutrients?.calories)
              : 0}
            cal
          </td>
          <td
            style={{
              width: "40px",
              minWidth: "40px",
              maxWidth: "40px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              padding: "10px 10px",
            }}
          >
            {calc.macronutrients?.protein(orFood.macronutrients?.protein) 
              ? calc.macronutrients?.protein(orFood.macronutrients?.protein)
              : 0}
            g
          </td>
          <td
            style={{
              width: "39px",
              minWidth: "39px",
              maxWidth: "39px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              padding: "10px 10px",
            }}
          >
            {calc.macronutrients?.carbs(orFood.macronutrients?.carbs)
              ? calc.macronutrients?.carbs(orFood.macronutrients?.carbs)
              : 0}
            g
          </td>
          <td
            style={{
              width: "39px",
              minWidth: "39px",
              maxWidth: "39px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              padding: "10px 10px",
            }}
          >
            {calc.macronutrients?.fibre(orFood.macronutrients?.fibre) 
              ? calc.macronutrients?.fibre(orFood.macronutrients?.fibre)
              : 0}
            g
          </td>
          <td
            style={{
              width: "39px",
              minWidth: "39px",
              maxWidth: "39px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              padding: "10px 10px",
            }}
          >
            {calc.macronutrients?.fats(orFood.macronutrients?.fats)
              ? calc.macronutrients?.fats(orFood.macronutrients?.fats)
              : 0}
            g
          </td>

          <td
            style={{
              textAlign: "center",

              width: "58px",
              minWidth: "58px",
              maxWidth: "58px",
              overflow: "hidden",
              boxSizing: "border-box",
              padding: "10px 10px",
            }}
          >
            <input
              placeholder="Enter Instruction"
              value={inputInstruction}
              style={{ border: "none" }}
              onChange={(event) =>
                handleInstruction(
                  event,
                  mealIdx,
                  menueIdx,
                  andFoodIdx,
                  orFoodIdx
                )
              }
            />
          </td>

          <td
            style={{
              textAlign: "center",
              width: "30px",
              minWidth: "30px",
              maxWidth: "30px",
              overflow: "hidden",
              padding: "10px 0px",
            }}
            onClick={() => {
              deleteFood(
                mealIdx,
                menueIdx,
                andFoodIdx,
                orFoodIdx,
                orFoodArr.length
              );
            }}
          >
            {" "}
            <DeleteOutlineIcon
              style={{
                width: "20px",
              }}
            />
          </td>
        </tr>
        {andFoodIdx !== andFoodArr.length - 1 ||
        orFoodIdx < orFoodArr.length - 1 ? (
          orFoodIdx < orFoodArr.length - 1 ? (
            <AndOr
              onClick={() =>
                switchAndOr(mealIdx, menueIdx, andFoodIdx, orFoodIdx, "or")
              }
              style={{ width: "100%" }}
              state="or"
            />
          ) : (
            <AndOr
              onClick={() =>
                switchAndOr(mealIdx, menueIdx, andFoodIdx, orFoodIdx, "and")
              }
              style={{ width: "100%" }}
              state="and"
            />
          )
        ) : (
          <></>
        )}
      </table>
    </td>
  );
};
