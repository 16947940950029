import React, { useState, useEffect, useContext, useMemo } from 'react'
import { editTableRow, openDialog } from '../../Helpers/TableHelpers'
import axios from 'axios'
import SelectBox from '../../components/SelectBox'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { ATLAS_URI } from '../../Constants'
import stateContext from '../../context/StateContext'
import { Button } from 'react-bootstrap'
import ClientWorkoutPlan from './ClientWorkoutPlan'
import { BiRefresh } from 'react-icons/bi'
import WorkoutPlanView from '../Plans/WorkoutPlanView'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'



function ClientWorkoutPlanListing(props) {


  const [showDetailPage, setShowDetailPage] = useState(false)
  const [workoutPlanId, setWorkoutPlanId] = useState(null)
  const [selectedTemplateId, setSelectedTemplateId] = useState([]);
  const contextState = useContext(stateContext)
const [showPlan, setShowPlan] = useState(false)
  const { updateEditDetails, updateList } = contextState
  const { configToken, operator, currentUser } = contextState.state
  const [state, setState] = useState();
  const [workoutPlanList, setWorkoutPlanList] = useState([])
  const [selectedIds, setSelectedIds] = useState(null)
const [selectDate, setSelectDate] = useState('')
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const editPermission = contextState.state.featureAccessible.find(
    (feature) => feature.sub_feature_id === 24
  ).is_edit
  let monthList = [],
  monthData = [];
  const onSelectionChanged = (event) => {
   
    setSelectedIds(event.api.getSelectedRows().map((selected) => { return selected.id }))
  };

  const onGridReady = (params) => {
 
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const updateData = (data) => {
      setRowData(data);
    };

   
  }

  const checkbox = (params) => {
    
    return params.node.group === true;
  };

  const autoGroupColumnDef = useMemo((params) => {
   



    /* get Workout Plan */

    const getWorkoutPlan = () => {

    }
    return {
      headerName: 'Athlete',
      field: 'athlete',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox,
      },
    };
  }, []);

  useEffect(() => {
   
    axios.get(`${ATLAS_URI}/getWorkoutPlansByLeadId/${props.clientDetail.Lead}`, configToken).then(res => {
  
      if (res.status == 200) {
        setWorkoutPlanList(res.data)
     
      }
    })

  }, [])


  function addNew() {
    setWorkoutPlanId(null)
    setShowDetailPage(true)
  }

  function editWorkoutPlan(workoutPlanId) {

  
    setWorkoutPlanId(workoutPlanId)
    setShowDetailPage(true)
  }


  /* Get Workout plan Refresh */

  const getWorkoutPlan = () => {
    axios.get(`${ATLAS_URI}/getWorkoutPlansByLeadId/${props.clientDetail.Lead}`, configToken).then(res => {
   
      if (res.status == 200) {
        setWorkoutPlanList(res.data)
      
      }
    })
  }


  const customizeData = (data) => {
       

    if (data && data.length !== 0) {
        let index = 0
        for (let i in data.workoutPlanDetails
          ) {
            // insert date
            let org_Date = new Date();
            let date = new Date(org_Date).getDate() + parseInt(i);
            let month;

            let mnth = new Date(org_Date).getMonth() + 1;
            if (mnth <= 9) {
                month = "0" + mnth;
            }
            if (date <= 9) {
                date = "0" + date;
            }
            let year = new Date(org_Date).getFullYear();

            // if (date == 32) {
            //     date = "01";
            // }
            let NumberOfdaysInAMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
            if (date > NumberOfdaysInAMonth) {
                // if (date == 32) {
                //     index = 0
                // }
                org_Date.setMonth(org_Date.getMonth() + 1, index + 1);
                let nextMonth = new Date(org_Date);
                date = nextMonth.getDate();
                index++;
                if (date <= 9) {
                    date = '0' + date
                }


                if (nextMonth.getMonth() + 1 <= 9) {

                    month = '0' + (nextMonth.getMonth() + 1);
                } else {

                    month = (nextMonth.getMonth() + 1)
                }

            }
            let formatedDate = year + "-" + month + "-" + date;
           
            data.workoutPlanDetails
            [i].date = formatedDate;
        }

        generateTableJSON(data);
    }
};



const generateTableJSON = (data) => {
  data.workoutPlanDetails
  .sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
  });

 

  const monthList = [];

  const monthData = [];
  data.workoutPlanDetails
  ?.forEach((workoutInfo) => {
   
      let month = new Date(workoutInfo.date).getMonth();
      // if(workoutInfo.date.getDate()>31){

      // }
      if (monthList.indexOf(month) == -1) {
          monthList.push(month);

          monthData.push({
              week: [
                  {
                      day: [
                          {
                              details: workoutInfo.workout_details
                              .map((c) => {
                                  return {
                                      link: c.youtube_link,
                                      name: c.name,
                                      title: c.title,
                                      date: workoutInfo.date,
                                  };
                              }),
                          },
                      ],
                  },
              ],
          });
      } else {
        

          if (
              monthData[monthList.indexOf(month)].week[
                  monthData[monthList.indexOf(month)].week.length - 1
              ].day.length <= 6
          ) {
              monthData[monthList.indexOf(month)].week[
                  monthData[monthList.indexOf(month)].week.length - 1
              ].day.push({
                  details: workoutInfo.workout_details
                  .map((c) => {
                      return {
                          link: c.youtube_link,
                          name: c.name,
                          title: c.title,
                          date: workoutInfo.date,
                      };
                  }),
              });
          } else {
              monthData[monthList.indexOf(month)].week.push({
                  day: [
                      {
                          details: workoutInfo.workout_details
                          .map((c) => {
                              return {
                                  link: c.youtube_link,
                                  name: c.name,
                                  title: c.title,
                                  date: workoutInfo.date,
                              };
                          }),
                      },
                  ],
              });
          }
      }
  });



  const workoutInfo = monthData.map((c) => {
      c.week.map((a) => {
          
          if (a.day.length < 6) {
              for (let index = a.day.length; index < 7; index++) {
                

                  a.day[index] = null;
              }
            
                    const lll = a.day.fill({link: '', name: '', title: ''}, a.day.length, 7)
                   
             
              return a;
          }
      });
      return c;
  });



  setState(workoutInfo);
  setShowPlan(true);
};

let history=useHistory()
  const fetchData = (id) => {
    monthData = [];
    monthList = [];

    axios.get(`${ATLAS_URI}/getWorkoutPlanByPlanId/${id}`, configToken)
        .then((res) => {
            if (res.status === 200) {
      
        setSelectDate(res.data.workoutPlanDetails[0].date)
                let org_data = res.data;
             
                customizeData(org_data);
            
            } else if (res.status === 401) {
                history.push("/");
            }
        })
        .catch((err) => console.error(err));
};

  const openWorkoutPlanModal = (id) => {

    setSelectedTemplateId(id);
    fetchData(id);
};

const closeWorkoutPlanModal = () => {
  setShowPlan(false);
};



const getWorkoutGoal=()=>{
  axios.get(`${ATLAS_URI}/getWorkoutPlansByLeadId/${props.clientDetail.Lead}`, configToken).then(res => {
  
    if (res.status == 200) {
      setWorkoutPlanList(res.data)
    
    }
  })


}





  return (
    showDetailPage ? <ClientWorkoutPlan showDetailPage={showDetailPage} setShowDetailPage={setShowDetailPage} clientsData={props.clientDetail} workoutPlanId={workoutPlanId} editPermission={editPermission} /> : (<div style={{ width: '100%', height: '1000px' }}>

      <div
        id="myGrid"
        style={{
          height: '100%',
          width: '100%',
        }}
        className="ag-theme-alpine"
      >
        {editPermission && <Button style={{ margin: '0.5rem' }} onClick={addNew}>+Add New Plan</Button>}
        <Button style={{ margin: "0 0 0 75%", 'backgroundColor': 'transparent', 'borderColor': '#999' }} onClick={getWorkoutPlan} ><BiRefresh className='text-dark' fontSize='25px' title='Refresh the table' /></Button>




        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            sortable: true,
            filter: true,

          }}
          rowSelection={'multiple'}

          onSelectionChanged={onSelectionChanged}

          /*  sideBar={{
             toolPanels: [
               {
                 id: 'columns',
                 labelDefault: 'Columns',
                 labelKey: 'columns',
                 iconKey: 'columns',
                 toolPanel: 'agColumnsToolPanel',
                 toolPanelParams: {
                   suppressRowGroups: true,
                   suppressValues: true,
                   suppressPivots: true,
                   suppressPivotMode: true,
                   suppressSideButtons: true,
                   suppressColumnFilter: true,
                   suppressColumnSelectAll: true,
                   suppressColumnExpandAll: true,
                 },
               },
             ],
             position:'bottom',
             defaultToolPanel: 'columns',
           }} */

          onGridReady={onGridReady}
          rowData={workoutPlanList}
          suppressDragLeaveHidesColumns={true}

        >
          {/* <AgGridColumn headerName="Select All"  > */}
          <AgGridColumn
            // field="id"
            filter="agTextColumnFilter"
            // minWidth={100}
            suppressColumnsToolPanel={true}
            rowSelection={'multiple'}
            autoGroupColumnDef={autoGroupColumnDef}
            headerCheckboxSelection={true}
            checkboxSelection={true}
          />
          <AgGridColumn enableRowGroup={true} suppressColumnsToolPanel={true} minWidth={100} rowDrag={false} field="plan_name" />


          <AgGridColumn enableRowGroup={true} suppressColumnsToolPanel={true} field="Action" cellRenderer={(params, id) =>


            <React.Fragment key={params.data.id}>
              <button className="btn btn-default btn-xs no-focus" title="Edit" onClick={() => editWorkoutPlan(params.data.id)} >
                <i className="fas fa-pencil" aria-hidden="true"></i>
              </button>

              <button
                        className="btn btn-default btn-xs no-focus"
                        title="View"
                        onClick={() => openWorkoutPlanModal(params.data.id)}
                      >
                        <i className="fas fa-eye" aria-hidden="true"></i>
                      </button>


                      <button className="btn btn-default btn-xs no-focus"
                                title="Delete Plan"
                                onClick={() => {
                                  axios.delete(`${ATLAS_URI}/deleteWorkoutPlanByPlanId/${params.data.id}`, configToken).then(res => {
                                    if (res.status == 200) {
                                      // toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                                      toast.success('Plan Deleted', { position: toast.POSITION.TOP_CENTER })
                                      // axios.get(`${ATLAS_URI}/getWorkoutPlansByLeadId/${props.clientDetail.Lead}`, configToken)
                                      getWorkoutGoal()

                                    }
                                  }).catch(err => {
                                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                                  })
                                }}>
                                <i className="fas fa-trash" aria-hidden="true"></i>
                              </button>

            </React.Fragment>


          }
          />

          {/* <DataTable  
                customAction={[
                                                        // { title: "Update", icon: "fas fa-edit", redirectTo: "/Clients/addClient", onClickEvent: (e) => editRecord(e, "/Clients/addClient") },
              { title: "Update", icon: "fas fa-eye", redirectTo: "/Clients/clientDetail", onClickEvent: (e) => editRecord(e, "/Clients/clientDetail") },
             { title: "Delete", icon: "fas fa-times text-red", onClickEvent: openDialog }
                                                    ]}
                /> */}


          {/* <button>Submit</button> */}

          {/* </AgGridColumn></AgGridColumn> */}


          {/* <AgGridColumn headerName="Competition">
            <AgGridColumn field="year" />
            <AgGridColumn field="date" minWidth={180} />
          </AgGridColumn>
          <AgGridColumn field="sport" minWidth={100} />
          <AgGridColumn headerName="Medals">
            <AgGridColumn field="gold" />
            <AgGridColumn field="silver" />
            <AgGridColumn field="bronze" />
            <AgGridColumn field="total" />
          </AgGridColumn> */}

        </AgGridReact>
      </div>

      {showPlan && state.length !== 0 && (
                <WorkoutPlanView
                   showPlan={showPlan}
                    closeWorkoutPlanModal={closeWorkoutPlanModal}
                    id={selectedTemplateId}
                    data={state}
                    clientsData={props.clientDetail}
                    selectDate={selectDate}
                />
            )}
    </div>)
  )
}

export default ClientWorkoutPlanListing;