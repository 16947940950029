import React from "react";

import "./Status.css";

import success from "../../assets/success.png";
import failure from "../../assets/failure.png";

const Success = () => {
  const url = window.location.href;
  const urlParams = new URLSearchParams(url);
  const status = urlParams.get("razorpay_payment_link_status");

  return (
    <div className="payment-container">
      <img
        className={status !== "failure" ? "img-success" : "img"}
        alt="payment-failure"
        src={status !== "failure" ? success : failure}
      />
      <span>
        Your Payment is {status !== "failure" ? "Successfull" : "Failure"}
      </span>
    </div>
  );
};

export default Success;
//Your Payment is Successfull
