import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import { ATLAS_URI } from '../../Constants'
import stateContext from '../../context/StateContext'

function UsersListAndLastLogin() {

    const contextState = useContext(stateContext)
    const { configToken } = contextState.state

    const [state, setState] = useState({
        resetNewRow: {
            Sno: "",
            Name: "",
            Username: "",
            UserType: "",
            LastLogin: ""
        },
        newTableRow: {},
        tableBodyList: [

        ],
        newRowID: "1001",
        editingActivated: false,
        editingID: ""
    })

    const mounted = React.useRef(true);
    useEffect(() => () => { mounted.current = false; }, []);

    useEffect(() => {

        //Get All Login Details
        axios.get(`${ATLAS_URI}/getLoginDetails/`, configToken)
            .then(res => {
                const loginDetailsData = res.data;
                if (typeof loginDetailsData !== 'undefined' && mounted.current) {

                    setState({ tableBodyList: loginDetailsData })

                }
            }).catch(err => console.log(err))
    }, []);



    return (
        <section className="content">
            <div className="row">

                <div className="col-md-12">
                    <div className="box box-primary">

                        <BoxHeader title="Users Last Login List" />

                        <div className="box-body">
                            <DataTable
                                tableHeader={["id", "Name", "Username", "Role", "Login Time"]}
                                tableBody={state.tableBodyList}
                                searchField="Name"
                                noActions={""}
                            />
                        </div>

                    </div>
                </div>


            </div>
        </section>
    )



}

export default UsersListAndLastLogin