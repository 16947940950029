import { FormHelperText, TextField } from "@mui/material";

import { Controller } from "react-hook-form";

const TextBox = ({
  control,
  name,
  isRequired = { required: false },
  disabled,
  type = "string",
}) => {
  return (
    <Controller
      name={name}
      rules={isRequired}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            variant="outlined"
            fullWidth
            size="small"
            error={!!error}
            disabled={disabled}
            type={type}
          />
          <FormHelperText error={true}>{error?.message}</FormHelperText>
        </>
      )}
    />
  );
};

export default TextBox;
