// CallerPopup.js
import React, { useEffect, useRef, useState } from "react";
import styles from "./CallerPopup.module.css";
import ReactDOM from "react-dom";
import CallIcon from "@mui/icons-material/Call";
import ServertelForm from "./ServertelForm";
import { useCallerPopup } from "../store/Call/CallerPopupContext";

const CallerPopup = () => {
  const { isVisible } = useCallerPopup();
  const [showModal, setShowModal] = useState(isVisible);
  const callerModal = document.getElementById("open__modal-root");

  const [position, setPosition] = useState({ x: 1400, y: 200 });
  const [dragging, setDragging] = useState(false);
  const rel = useRef({ x: 0, y: 0 });

  const onMouseDown = (e) => {
    if (e.button !== 0) return;
    const pos = e.target.getBoundingClientRect();
    setDragging(true);
    rel.current = {
      x: e.pageX - pos.left,
      y: e.pageY - pos.top,
    };
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  const onMouseMove = (e) => {
    if (!dragging) return;

    let newX = e.pageX - rel.current.x;
    let newY = e.pageY - rel.current.y;

    const popupWidth = 50;
    const popupHeight = 50;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    newX = Math.max(0, Math.min(newX, windowWidth - popupWidth));
    newY = Math.max(0, Math.min(newY, windowHeight - popupHeight));

    setPosition({ x: newX, y: newY });
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    } else {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragging]);

  useEffect(() => {
    if (isVisible) {
      setShowModal(true);
    }
  }, [isVisible]);

  return ReactDOM.createPortal(
    <>
      {isVisible && (
        <div
          className={styles.popup}
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
          }}
          onMouseDown={onMouseDown}
          onClick={() => setShowModal(true)}
        >
          <div className={styles.popup_header}>
            <CallIcon sx={{ fontSize: "28px", color: "red" }} />
          </div>
        </div>
      )}
      <ServertelForm isOpen={showModal} onClose={() => setShowModal(false)} />
    </>,
    callerModal
  );
};

export default CallerPopup;
