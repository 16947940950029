import React, { useContext, useEffect, useState } from 'react';
import  CloseButton  from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {GrClose} from 'react-icons/gr'
import axios from 'axios'
import { ATLAS_URI } from '../../Constants';
import stateContext from '../../context/StateContext';
import ClientDietPlanView from './ClientDietPlanView';
import ClientWorkoutPlanView from './ClientWorkoutPlanView';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { Worker, Viewer } from "@react-pdf-viewer/core";

function PopupWorkoutPlanView(props) {

  const [show, setShow] = useState(props.show1);

//   const [workoutPlanInfo, setWorkoutPlanInfo] = useState({})

const [viewPDF, setViewPDF] = useState()

const toolbarPluginInstance = toolbarPlugin();
const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    // Download: () => <></>,
    // EnterFullScreen: () => <></>,
    // SwitchTheme: () => <></>,
    // GoToPreviousPage:()=><></>,
    // GoToNextPage:()=><></>,
    SearchTheme: () => <></>,
    Open: () => <></>
  });


  const contextState = useContext(stateContext)
    const { configToken } = contextState.state
   



  const handleClose = () => {
    props.setShow1(false)
  }

  useEffect(() => {
    axios.get(`${ATLAS_URI}/generatePreSignedURLPlanPDF/${props.planId}`, configToken).then(resp => {
  
        setViewPDF(resp.data)
    })
  }, props.show1)


//   useEffect(() => {
//     // props.show1 && setShow(true)

//     axios.get(`${ATLAS_URI}/getWorkoutPlanByPlanId/${props.planId}`, configToken)
//       .then(resp => {
//     
//         if (resp.status == 200) {
          
//             setWorkoutPlanInfo(resp.data)
          
//         }
//       })
//   }, [props.show1])
  
  
  const handleShow = () => setShow(props.show1);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Diet Plan Details</Modal.Title>
          <GrClose 
          style={{
            backgroundColor: isHovering ? '#FF0000' : '',
            color: isHovering ? '#fff' : '',
            border:isHovering?'1px solid':'',
            height:isHovering?'30px':'',
            width:isHovering?'30px':'',
            opacity:isHovering?'0.7':''
          }}
          onMouseOver={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClose}  />
        </Modal.Header>
        <Modal.Body>

                  {viewPDF && <div>

                      <Worker workerUrl={ATLAS_URI + "/static/pdf.worker.min.js"}>
                          <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                          <Viewer
                              fileUrl={viewPDF}
                              plugins={[toolbarPluginInstance]}
                          />
                      </Worker>
                  </div>}
            
          </Modal.Body>
        <Modal.Footer> 
          <Button variant="secondary" onClick={()=>handleClose()}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={()=>handleClose()}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopupWorkoutPlanView