import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import * as $ from 'jquery'
import BoxHeader from '../../components/BoxHeader'
import SelectBox from '../../components/SelectBox'
import { getNormalDate } from '../../Helpers/DateHelpers'
import Dialog from '../../components/Dialog'
import { changeHandler as oldChangeHandler } from '../../Helpers/ChangeHandler'
import stateContext from '../../context/StateContext'
import { ATLAS_URI } from '../../Constants'

function AddNewAgreement() {

    const contextState = useContext(stateContext)
    let { EditDetailsData, configToken } = contextState.state

    const [state, setState] = useState({
        resetNewRow: {
            Name: "",
            Type: "",
            Buyer: "Select",
            Seller: "Select",
            Project: "Select",
            Property: "Select",
            FromDate: "",
            ToDate: "",
            TotalCost: "",
            GovtID: "",
            GovtIDImages: [],
            AddressProof: "",
            AddressProofImages: []
        },
        newTableRow: {
            Buyer: "Select",
            Seller: "Select",
            Project: "Select",
            Property: "Select",
        },
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        },
        editingActivated: false,
        editingID: "",
        tableBodyList: [

        ],
        agreementTypeList: [],
        clientsList: [],
        projectsList: [],
        propertiesList: []

    })

    const mounted = React.useRef(true);
    useEffect(() => () => { mounted.current = false; }, []);
    useEffect(() => {

        axios.all(
            [
                axios.get(`${ATLAS_URI}/getProjects/`, configToken),
                axios.get(`${ATLAS_URI}/getClients/`, configToken),
                axios.get(`${ATLAS_URI}/getProperties/`, configToken),
                axios.get(`${ATLAS_URI}/getAgreementTypes/`, configToken)

            ]
        )
            .then(axios.spread((projects, clients, properties, agreementTypes) => {
                mounted.current && setState(prevState => ({
                    ...prevState,
                    agreementTypeList: agreementTypes.data,
                    clientsList: clients.data,
                    projectsList: projects.data,
                    propertiesList: properties.data

                }));

            }))
            .catch(error => console.log(error));
    }, []);



    function fillClientInfo(clientType, client) {
        if (clientType === "Buyer") {
            $("#buyerName").val(client.Name);
            $("#buyerPhone").val(client.Phone);
            $("#buyerEmail").val(client.Email);
        } else {
            $("#sellerName").val(client.Name);
            $("#sellerPhone").val(client.Phone);
            $("#sellerEmail").val(client.Email);
        }
    }

    function validateThenAddAgreement(e) {
        e.preventDefault();
        if (state.newTableRow.Buyer === "Select") {
            const newDialogInfo = { isOpened: true, text: "Kindly Select a Buyer", type: "Error" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)

        } else if (state.newTableRow.Seller === "Select") {
            const newDialogInfo = { isOpened: true, text: "Kindly Select a Seller", type: "Error" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)

        } else if (state.newTableRow.Project === "Select") {
            const newDialogInfo = { isOpened: true, text: "Kindly Select a Project", type: "Error" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)

        } else if (state.newTableRow.Property === "Select") {
            const newDialogInfo = { isOpened: true, text: "Kindly Select a Property", type: "Error" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)

        }
        else {
            addNewAgreement(e);
        }
    }

    function addNewAgreement(e) {
        const formData = new FormData();

        if (state.newTableRow.GovtIDSelected) {
            state.newTableRow.GovtIDSelected.forEach(image => {
                formData.append('SelectedGovtID', image);
            })
        }
        if (state.newTableRow.AddressProofSelected) {
            state.newTableRow.AddressProofSelected.forEach(image => {
                formData.append('SelectedAddressProof', image);
            })
        }
        let ToDate = state.newTableRow.ToDate;
        let FromDate = state.newTableRow.FromDate;
        if (typeof ToDate !== 'undefined' && ToDate !== '') {
            ToDate = getNormalDate(ToDate);
        }
        if (typeof FromDate !== 'undefined' && FromDate !== '') {
            FromDate = getNormalDate(FromDate);
        }

        formData.append('Name', state.newTableRow.Name);
        formData.append('Type', state.newTableRow.Type);
        formData.append('Buyer', state.newTableRow.Buyer);
        formData.append('Seller', state.newTableRow.Seller);
        formData.append('Project', state.newTableRow.Project);
        formData.append('Property', state.newTableRow.Property);
        formData.append('FromDate', FromDate);
        formData.append('ToDate', ToDate);
        formData.append('TotalCost', state.newTableRow.TotalCost);
        formData.append('GovtID', state.newTableRow.GovtID);
        formData.append('AddressProof', state.newTableRow.AddressProof);

        axios.post(`${ATLAS_URI}/addAgreement/`, formData, configToken)
            .then(response => {
                if (response.status === 200) {
                    const newDialogInfo = { isOpened: true, text: "Agreement Added Successfully", type: "Success" }
                    document.getElementsByName("AddressProofSelected")[0].value = "";
                    document.getElementsByName("GovtIDSelected")[0].value = "";
                    setState(prevState => ({
                        ...prevState,
                        newTableRow: state.resetNewRow,
                        dialogInfo: newDialogInfo
                    }))

                    setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)

                    $("#buyerName").val("");
                    $("#buyerPhone").val("");
                    $("#buyerEmail").val("");
                    $("#sellerName").val("");
                    $("#sellerPhone").val("");
                    $("#sellerEmail").val("");
                }
            })
            .catch(err => alert(err))
    }

    function openDialog(e) {
        const newDialogInfo = {
            isOpened: true,
            delSrc: $(e.target.parentElement).find("img").attr("src"),
            delIndex: $(e.target.parentElement).attr("index"),
            delName: e.target.parentElement.parentElement.name,
            text: "Are you sure you want to delete this Image?",
            type: "Confirm"
        }
        setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))

        setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
    }

    function deleteImage(e) {
        e.preventDefault();

        axios.delete(state.dialogInfo.delSrc, configToken)
            .then(() => {
                const newData = state.newTableRow;

                newData[state.dialogInfo.delName].splice(state.dialogInfo.delIndex, 1)
                axios.post(`${ATLAS_URI}/updateAgreement/` + EditDetailsData.id, newData, configToken)
                    .then(() => {
                        setState(prevState => ({
                            ...prevState,
                            newTableRow: newData,
                            dialogInfo: { isOpened: false, text: "" }
                        }))
                    }).catch(err => alert(err))
            })
            .catch(err => alert(err))
    }

    function changeHandler(e) {
        oldChangeHandler(e, state, setState);
    }

    return (
        <section className="content">
            <div className="row">
                <Dialog
                    onClose={(e) => setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "" } }))}
                    dialogInfo={state.dialogInfo}
                />
                <div className="col-md-12">
                 
                    <div className="box box-primary">

                        <BoxHeader title="Add Agreement" />

                        <form onSubmit={validateThenAddAgreement}>
                            <div className="box-body bozero mx5p">

                                <input type="hidden" name="ci_csrf_token" value="" />

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Agreement Name</label>
                                            <input name="Name" type="text" className="form-control" value={state.newTableRow.Name} onChange={changeHandler} />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Agreement Type</label> <small className="req"> *</small>
                                            <select required className="form-control" name="Type" value={state.newTableRow.Type} onChange={changeHandler} >
                                                <option value="">Select</option>
                                                {typeof state.agreementTypeList !== 'undefined' && state.agreementTypeList.map(classData => <option key={classData.AgreementType} value={classData.id}>{classData.AgreementType}</option>)}
                                            </select>
                                        </div>

                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <SelectBox
                                            label="Buyer"
                                            name="Buyer"
                                            options={state.clientsList.filter(client => client.Type === "Buyer")}
                                            attributeShown="Name"
                                            changeHandler={changeHandler}
                                            value={state.newTableRow.Buyer}
                                            callBackFn={fillClientInfo}
                                            resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, Buyer: "Select" } }))}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Buyer Name</label>
                                            <input id='buyerName' type="text" className="form-control" readOnly="readOnly" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Buyer Phone</label>
                                            <input id='buyerPhone' type="text" className="form-control" readOnly="readOnly" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Buyer Email</label>
                                            <input id="buyerEmail" type="email" className="form-control" readOnly="readOnly" />
                                        </div>
                                    </div>

                                </div>

                                <br />

                                <div className="row">

                                    <div className="col-md-6">
                                        <SelectBox
                                            label="Seller"
                                            name="Seller"
                                            options={state.clientsList.filter(client => client.Type === "Seller")}
                                            attributeShown="Name"
                                            changeHandler={changeHandler}
                                            value={state.newTableRow.Seller}
                                            callBackFn={fillClientInfo}
                                            resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, Seller: "Select" } }))}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Seller Name</label>
                                            <input id="sellerName" type="text" className="form-control" readOnly="readOnly" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Seller Phone</label>
                                            <input id="sellerPhone" type="text" className="form-control" readOnly="readOnly" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Seller Email</label>
                                            <input id="sellerEmail" type="email" className="form-control" readOnly="readOnly" />
                                        </div>
                                    </div>

                                </div>

                                <br />

                                <div className="row">

                                    <div className="col-md-6">
                                        <SelectBox
                                            label="Project"
                                            name="Project"
                                            options={state.projectsList}
                                            attributeShown="Name"
                                            changeHandler={changeHandler}
                                            value={state.newTableRow.Project}
                                            resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, Project: "Select" } }))}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <SelectBox
                                            label="Property"
                                            name="Property"
                                            options={state.propertiesList.filter(property => property.Project.toString() === state.newTableRow.Project)}
                                            attributeShown="PropertyTitle"
                                            changeHandler={changeHandler}
                                            value={state.newTableRow.Property}
                                            resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, Property: "Select" } }))}
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>From Date</label>
                                            <input name="FromDate" type="date" className="form-control" value={state.newTableRow.FromDate} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <input name="ToDate" type="date" className="form-control" value={state.newTableRow.ToDate} onChange={changeHandler} />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Total Cost</label>
                                            <input name="TotalCost" type="number" className="form-control" value={state.newTableRow.TotalCost} onChange={changeHandler} />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="form-group">
                                        <label>Govt ID</label>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="GovtID" type="text" className='form-control' value={state.newTableRow.GovtID} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="GovtIDSelected" type="file" accept=".png, .jpg, .jpeg" multiple className='form-control' onChange={changeHandler} />

                                            {typeof state.newTableRow.GovtIDImages !== 'undefined' && state.newTableRow.GovtIDImages.map((image, key) =>
                                                <span key={key} index={key} className='databaseImgArea'>
                                                    <img alt="database images" style={{ margin: "5px 2px", objectFit: "cover" }} src={`${ATLAS_URI}/file/${image}`} width={60} height={60}></img>
                                                    <button type='button' onClick={openDialog}>x</button>
                                                </span>
                                            )}

                                            {typeof state.newTableRow.GovtIDSelected !== 'undefined' && state.newTableRow.GovtIDSelected.map(image =>
                                                <img alt="selected images" style={{ margin: "5px 2px", objectFit: "cover" }} src={URL.createObjectURL(image)} width={60} height={60}></img>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="form-group">
                                        <label>Address Proof</label>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="AddressProof" type="text" className='form-control' value={state.newTableRow.AddressProof} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="AddressProofSelected" type="file" accept=".png, .jpg, .jpeg" multiple className='form-control' onChange={changeHandler} />

                                            {typeof state.newTableRow.AddressProofImages !== 'undefined' && state.newTableRow.AddressProofImages.map((image, key) =>
                                                <span key={key} index={key} className='databaseImgArea'>
                                                    <img alt="database images" style={{ margin: "5px 2px", objectFit: "cover" }} src={`${ATLAS_URI}/file/${image}`} width={60} height={60}></img>
                                                    <button type='button' onClick={openDialog}>x</button>
                                                </span>
                                            )}

                                            {typeof state.newTableRow.AddressProofSelected !== 'undefined' && state.newTableRow.AddressProofSelected.map(image =>
                                                <img alt="selected images" style={{ margin: "5px 2px", objectFit: "cover" }} src={URL.createObjectURL(image)} width={60} height={60}></img>
                                            )}
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right">Save</button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    )



}

export default AddNewAgreement