import {
  Box,
  Card,
  Switch,
  Typography,
  Grid,
  Tabs,
  Tab,
  Checkbox,
  Paper,
  Stack,
  FormGroup,
  FormControlLabel,
  Button,
  FormHelperText,
  TextField,
  FormControl,
  Autocomplete,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  RadioGroup,
  Radio,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHistory, useLocation } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BlackSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#2D2D2D",
    "&:hover": {
      backgroundColor: alpha("#2D2D2D", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#2D2D2D",
  },
}));

const styles = {
  switch: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  text: {
    width: "120px",
  },
};

const initialState = {
  trainer: false,
  nutrition: false,
  workout_plan: false,
  diet_plan: false,
  programList: [],
  ruleName: "",
  description: "",
  generalWorkout: [],
  specialWorkout: [],
  is_consultation: false,
};

const AddAccess = () => {
  const history = useHistory();
  const { state } = useLocation();
  const editTaskRow = state && state.taskDetails;
  const isEdit = state && state.isEdit;
  const [value, setTabValue] = useState(0);
  const [dropDownlists, setDropDownLists] = useState({
    programList: [],
    general_work: [],
    special_work: [],
  });

  const [activeItem, setActiveItem] = useState(0);

  const handleSelectAllStudio = (event, ids) => {
    const idList = ids.map((item) => item.program_id);
    const preValue = getValues("generalWorkout");
    let updatedValue;

    if (event) {
      updatedValue = [...preValue, ...idList];
    } else {
      updatedValue = preValue.filter((value) => !idList.includes(value));
    }

    setValue("generalWorkout", updatedValue);
  };

  const form = useForm({
    defaultValues: isEdit ? editTaskRow : initialState,
  });
  const { handleSubmit, control, reset, setValue, getValues } = form;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleListItemClick = (index) => {
    setActiveItem(index);
  };

  const goBackToPreviousPage = () => {
    history.push("/Programs/accessAutomation");
  };

  const onSubmit = async (formData) => {
    const updateData = {
      ...formData,
      is_consultation: formData.is_consultation === "true" ? true : false,
    };

    let url = "/addFeatureRule";
    let method = "post";

    if (isEdit) {
      url = `/updateFeatureRule`;
      method = "put";
    }

    try {
      const response = await axiosFetch({
        method: method,
        url: url,
        requestConfig: updateData,
      });

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        history.push("/Programs/accessAutomation");
      }
    } catch {}
  };

  const getProgramDetails = async () => {
    try {
      const liveListResponse = await axiosFetch({
        method: "get",
        url: `/getStudioProgramList`,
      });

      const programListResponse = await axiosFetch({
        method: "get",
        url: `/getProgramListForFeatureAutomation`,
      });

      if (
        liveListResponse.status === 200 &&
        programListResponse.status === 200
      ) {
        const data = liveListResponse.data.body;
        const programList = programListResponse.data.body;
        setDropDownLists((pev) => {
          return {
            ...pev,
            general_work: data.general_work,
            special_work: data.special_work,
            programList: programList,
          };
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getProgramDetails();
  }, []);

  return (
    <div className="new-client">
      <div className="new-cient-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid container spacing={3} sx={{ marginBottom: "40px" }}>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Button
                  variant={"text"}
                  sx={{ borderRadius: "50px", minWidth: "14px", color: "#000" }}
                  onClick={() => goBackToPreviousPage()}
                >
                  <KeyboardBackspaceIcon />
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      marginLeft: "5px",
                    }}
                  >
                    Access Automation
                  </Typography>
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#2a2a2a",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid item md={3}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Rule Name
                </Typography>
                <Controller
                  name={`ruleName`}
                  rules={{ required: "required" }}
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={!!error}
                      />
                      <FormHelperText error={true}>
                        {error?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Programs
                </Typography>

                <Controller
                  control={control}
                  name={"programList"}
                  rules={{ required: "required" }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl size="small" fullWidth>
                      <Autocomplete
                        multiple
                        options={dropDownlists.programList}
                        size="small"
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          field.onChange(value);
                        }}
                        value={field.value}
                        freeSolo
                        disableCloseOnSelect
                        // getOptionSelected={(option, selectedValue) =>
                        //   option.id === selectedValue.id
                        // }
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            onClick={(event) => {
                              const newValue = field.value.some(
                                (item) => item.id === option.id
                              )
                                ? field.value.filter(
                                    (item) => item.id !== option.id
                                  )
                                : [...field.value, option];
                              field.onChange(newValue);
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={field.value.some(
                                (item) => item.id === option.id
                              )}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} error={!!error} />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            sx={{
                              width: "450px",
                              position: "absolute",
                              marginTop: "-50px",
                              marginLeft: "-100px",
                            }}
                          />
                        )}
                      />
                      <FormHelperText error={true}>
                        {error?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Rule Description
                </Typography>
                <Controller
                  name={`description`}
                  rules={{ required: "required" }}
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={!!error}
                      />
                      <FormHelperText error={true}>
                        {error?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </Grid>

              <Grid item md={3} sx={{ padding: 0, margin: 0 }}>
                <Card sx={{ margin: "0px !important" }}>
                  <nav aria-label="secondary mailbox folders">
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={activeItem === 0}
                          onClick={() => handleListItemClick(0)}
                        >
                          <ListItemText primary="Plans and Coaches" />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={activeItem === 1}
                          onClick={() => handleListItemClick(1)}
                        >
                          <ListItemText primary="Studio" />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={activeItem === 2}
                          onClick={() => handleListItemClick(2)}
                        >
                          <ListItemText primary="Consultation" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </nav>
                </Card>
              </Grid>

              {activeItem === 0 && (
                <Grid item md={4}>
                  <Card sx={{ padding: "20px", margin: "0px !important" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sx={styles.switch}>
                        <Typography sx={styles.text}>Workout Plan </Typography>
                        <Controller
                          name="workout_plan"
                          control={control}
                          render={({ field }) => (
                            <BlackSwitch {...field} checked={field.value} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} sx={styles.switch}>
                        <Typography sx={styles.text}>Diet Plan </Typography>
                        <Controller
                          name="diet_plan"
                          control={control}
                          render={({ field }) => (
                            <BlackSwitch {...field} checked={field.value} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} sx={styles.switch}>
                        <Typography sx={styles.text}>Workout Coach</Typography>
                        <Controller
                          name="trainer"
                          control={control}
                          render={({ field }) => (
                            <BlackSwitch {...field} checked={field.value} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} sx={styles.switch}>
                        <Typography sx={styles.text}>Diet Coach</Typography>
                        <Controller
                          name="nutrition"
                          control={control}
                          render={({ field }) => (
                            <BlackSwitch {...field} checked={field.value} />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}
              {activeItem === 1 && (
                <Grid item md={9}>
                  <Card sx={{ padding: "20px", margin: "0px !important" }}>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          sx={{
                            "& .MuiTabs-indicator": { backgroundColor: "#000" },
                          }}
                        >
                          <Tab
                            label={
                              <div style={{ display: "flex" }}>
                                <Checkbox
                                  checked={value === 0 ? true : false}
                                  sx={{ "&.Mui-checked": { color: "#000" } }}
                                  label="General Workout"
                                />
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {"General Workout"}
                                </Typography>
                              </div>
                            }
                            {...a11yProps(1)}
                          />
                          <Tab
                            label={
                              <div style={{ display: "flex" }}>
                                <Checkbox
                                  checked={value === 1 ? true : false}
                                  sx={{ "&.Mui-checked": { color: "#000" } }}
                                  label="Special Workout"
                                />
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {"Special Workout"}
                                </Typography>
                              </div>
                            }
                            {...a11yProps(0)}
                          />
                        </Tabs>
                      </Box>

                      <CustomTabPanel value={value} index={0}>
                        <Grid container spacing={3}>
                          {dropDownlists.general_work?.type?.map(
                            (general, index) => (
                              <Grid item xs={3} key={index}>
                                <Paper sx={{ height: "100%" }}>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                      padding: "10px 10px !important",
                                      backgroundColor: "#F4F4F4",
                                    }}
                                  >
                                    <Typography sx={{ width: "190px" }}>
                                      {general?.type}
                                    </Typography>

                                    <BlackSwitch
                                      // checked={"testt"}
                                      onChange={
                                        (e) => {
                                          handleSelectAllStudio(
                                            e.target.checked,
                                            general?.programs
                                          );
                                        }
                                        //  field.onChange()
                                      }
                                    />
                                  </Stack>
                                  <Controller
                                    name="generalWorkout"
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <FormGroup>
                                        {general?.programs.map(
                                          (program, index) => (
                                            <FormControlLabel
                                              key={index}
                                              sx={{
                                                width: "100%",
                                                margin: "0px",
                                              }}
                                              control={
                                                <Checkbox
                                                  sx={{
                                                    "&.Mui-checked": {
                                                      color: "#000",
                                                    },
                                                  }}
                                                  checked={value?.includes(
                                                    program?.program_id
                                                  )}
                                                  onChange={(e) => {
                                                    const updatedValue =
                                                      value?.includes(
                                                        program?.program_id
                                                      )
                                                        ? value.filter(
                                                            (item) =>
                                                              item !==
                                                              program?.program_id
                                                          )
                                                        : [
                                                            ...value,
                                                            program?.program_id,
                                                          ];
                                                    onChange(updatedValue);
                                                  }}
                                                />
                                              }
                                              label={program?.program_name}
                                            />
                                          )
                                        )}
                                      </FormGroup>
                                    )}
                                  />
                                </Paper>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                        <Grid container spacing={3}>
                          {dropDownlists.special_work?.type?.map(
                            (general, index) => (
                              <Grid item xs={3} key={index}>
                                <Paper sx={{ height: "100%" }}>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                      padding: "10px 10px !important",
                                      backgroundColor: "#F4F4F4",
                                    }}
                                  >
                                    <Typography sx={{ width: "190px" }}>
                                      {general?.type}
                                    </Typography>
                                  </Stack>
                                  <Controller
                                    name="specialWorkout"
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <FormGroup>
                                        {general?.programs.map(
                                          (program, index) => (
                                            <FormControlLabel
                                              key={index}
                                              sx={{
                                                width: "100%",
                                                margin: "0px",
                                              }}
                                              control={
                                                <Checkbox
                                                  sx={{
                                                    "&.Mui-checked": {
                                                      color: "#000",
                                                    },
                                                  }}
                                                  checked={value?.includes(
                                                    program?.program_id
                                                  )}
                                                  onChange={(e) => {
                                                    const updatedValue =
                                                      value?.includes(
                                                        program?.program_id
                                                      )
                                                        ? value.filter(
                                                            (item) =>
                                                              item !==
                                                              program?.program_id
                                                          )
                                                        : [
                                                            ...value,
                                                            program?.program_id,
                                                          ];
                                                    onChange(updatedValue);
                                                  }}
                                                />
                                              }
                                              label={program?.program_name}
                                            />
                                          )
                                        )}
                                      </FormGroup>
                                    )}
                                  />
                                </Paper>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </CustomTabPanel>
                    </Box>
                  </Card>
                </Grid>
              )}

              {activeItem === 2 && (
                <Grid item xs={4}>
                  <Card
                    sx={{
                      padding: "20px",
                      margin: "0px !important",
                      height: "100px",
                    }}
                  >
                    <Controller
                      name="is_consultation"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          {...field}
                          value={field.value}
                        >
                          <FormControlLabel
                            value={true}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#000",
                                  },
                                }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontFamily: "Roboto Condensed",
                                }}
                              >
                                Yes
                              </span>
                            }
                          />
                          <FormControlLabel
                            value={false}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#000",
                                  },
                                }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontFamily: "Roboto Condensed",
                                }}
                              >
                                No
                              </span>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddAccess;
