import React, { useState, useEffect, useContext } from "react";
import * as $ from "jquery";
import BoxHeader from "../../components/BoxHeader";
import axios from "axios";
import DataTable from "../../components/DataTable";
import BulkUpdateModal from "../../components/BulkUpdateModal";
import SelectBox from "../../components/SelectBox";
import Dialog from "../../components/Dialog";
import { getFormattedDate, getNormalDate } from "../../Helpers/DateHelpers";
import { importData } from "../../Helpers/ExcelHelper";
import { editTableRow } from "../../Helpers/TableHelpers";
import { changeHandler } from "../../Helpers/ChangeHandler";
import {
  closeInputModal,
  openInputModalLeads,
} from "../../Helpers/ModalHandler";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import "./FreshLeads.css";
import { Button } from "react-bootstrap";

import { toast } from "react-toastify";

import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import { ValueService } from "ag-grid-community";
import { values } from "lodash";
import { addLeadSchema } from "../../ValidationSchema";
import { axiosFetch } from "../../Utlis/AxiosFetch";

// import "react-phone-input-2/lib/bootstrap.css"

function FreshLeads(props) {
  const history = useHistory();
  const contextState = useContext(stateContext);
  const { updateEditDetails, updateList, redirectFromEditDetails } =
    contextState;
  const {
    configToken,
    leadsList,
    usersList,
    sourcesList,
    propertyTypeList,
    statusList,
    operator,
    EditDetailsData,
    featureAccessible,
  } = contextState.state;

  //checking to Defaultely assign if user is sales person(if permission only for "My Leads")
  const defaultAssign =
    featureAccessible.find((fe) => fe.sub_feature_id == 3 && fe.is_view) &&
    featureAccessible.find(
      (fe) => fe.sub_feature_id == 2 && fe.is_view == false
    ) &&
    featureAccessible.find(
      (fe) =>
        fe.sub_feature_id == 4 && fe.is_view == false && fe.is_edit == false
    );

  const [leadStageList, setLeadStageList] = useState([]);
  const [leadDisposition, setLeadDisposition] = useState([]);
  const [leadSubDisposition, setLeadSubDisposition] = useState([]);
  const [grandDisposition, setGrandDisposition] = useState([]);
  const [disableProgram, setDisableProgram] = useState(true);
  const [disableDealSize, setDisableDealSize] = useState(true);
  const [disableFollowUp, setDisableFollowUp] = useState(true);
  const [disableSavebtn, setDisableSavebtn] = useState(false);
  const [isDisplayFollowUp, setIsDisplayFollowUp] = useState(false);
  const [state, setState] = useState({
    resetNewRow: {
      // ID: "",
      // Client: "Select",
      // PropertyType: "Select",
      // Source: "Select",
      // AssignedTo: "Select",
      // CreatedBy: ""
      id: "",
      Client: "",
      name: "",
      account_name: "",
      pincode: "",
      height: "",
      current_weight: "",
      dob: "",
      gender: "",
      contact_number: "",
      email_id: "",
      PropertyType: "",
      source: "",
      LeadStatus: "",
      RequiredLocation: "",
      MinArea: "",
      MaxArea: "",
      MinBudget: "",
      MaxBudget: "",
      RequiredLoan: "No",
      next_followup_date: null,
      InterestedIn: "",
      // lead_status:"",
      vmax_lead_stage_id: "",
      vmax_lead_disposition_id: "",
      vmax_lead_sub_disposition_id: "",
      vmax_lead_sub_not_interested_id: null,
      // call_status: "",
      Remarks: [],
      VisitInfos: [],
      Payments: [],
      current_medical_condition: [],
    },
    newTableRow: {
      // ID: "",
      // Client: "Select",
      // PropertyType: "Select",
      // Source: "Select",
      // AssignedTo: "Select",
      // CreatedBy: ""
      id: "0001",
      Client: "",
      name: "",
      address: "",
      contact_number: "",
      account_name: "",
      pincode: "",
      height: "",
      current_weight: "",
      dob: "",
      gender: "",
      email_id: "",
      PropertyType: "",
      source: null,
      LeadStatus: "",
      RequiredLocation: "",
      MinArea: "",
      MaxArea: "",
      MinBudget: "",
      MaxBudget: "",
      RequiredLoan: "No",
      next_followup_date: null,
      InterestedIn: "",
      // lead_status:"",
      vmax_lead_stage_id: "",
      vmax_lead_disposition_id: "",
      vmax_lead_sub_disposition_id: "",
      vmax_lead_sub_not_interested_id: null,
      // call_status: "",
      Remarks: [],
      VisitInfos: [],
      Payments: [],

      occupation: "",
      current_medical_condition: [],
      preferred_language: "",
      refferal:""
    },
    tableBodyList: [],
    editingActivated: false,
    editingID: "",

    dialogInfo: {
      isOpened: false,
      text: "",
      iconClass: "fas fa-exclamation-triangle",
      onTrueFn: () => { },
      wrongData: [],
    },
    clientsList: [],
    propertyTypeList: [],
    sourceList: [],
    userList: [],
    APIs: {
      AddData: "addLead",
      UpdateData: "updateLead",
      DeleteData: "deleteLead",
    },
    isModalOpen: false,
    SelectedLeads: [],
  });

  const [leadStage, setLeadStage] = useState("7");
  const [leadDispositionId, setLeadDispositionId] = useState("");
  const [leadSubDispositionId, setLeadSubDispositionId] = useState("");
  const [grandDispositionId, setGrandDispositionId] = useState(null);

  const [leadStageErr, setLeadStageErr] = useState(false);
  const [dispositionErr, setDispositionErr] = useState(false);
  const [subDispositionErr, setSubDispositionErr] = useState(false);

  const mounted = React.useRef(true);

  const getLeadStage = () => {
    axiosFetch({
      url: "/getLeadStages",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setLeadStageList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getLeadDisposition = (stageId) => {
    axiosFetch({
      url: "/getLeadDispositions",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          if (parseInt(stageId) === 7 || parseInt(stageId) === 5) {
            let disposition_id = leadStageList.filter(
              (list) => list.id == stageId
            )[0].vmax_lead_disposition_id;

            setLeadDisposition(
              res.data.filter((list) => list.id == disposition_id)
            );
          } else if (parseInt(stageId) === 6) {
            let disposition_id = leadStageList.filter(
              (list) => list.id == stageId
            )[0].vmax_lead_disposition_id;

            setLeadDisposition(
              res.data.filter((list) => list.id == disposition_id)
            );
          } else {
            setLeadDisposition(res.data);
          }
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getLeadSubDisposition = (id) => {
    let url;
    if (id == 2) {
      url = `getLeadSubDispositionsByDispositionId/${id}`;
    } else {
      url = `getLeadSubDispositionsByDispositionIdAndStageId/${id}/${leadStage}`;
    }
    axiosFetch({
      url,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setLeadSubDisposition(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getGrandDisposition = (disId, subDisId) => {
    axiosFetch({
      url: `/getLeadSubNotInterestedListByDispositionIdAndSubDispositionId/${disId}/${subDisId}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setGrandDisposition(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleChangeLeadStage = (e) => {
    setLeadStage(e.target.value);
    let isDisplayFollowUp = leadStageList.filter(
      (stage) => stage.id === parseInt(e.target.value)
    )[0].isFollowupDisplay;
    setIsDisplayFollowUp(isDisplayFollowUp);

    getLeadDisposition(e.target.value);
    setLeadDispositionId("");
    setLeadSubDispositionId("");
    setGrandDispositionId("");
    if (e.target.value === "") {
      setLeadStageErr(true);
      return false;
    } else {
      setLeadStageErr(false);
    }
  };

  const handleChangeDisposition = (e) => {
    setLeadDispositionId(e.target.value);
    getLeadSubDisposition(e.target.value);

    // if(e.target.value === ""){
    //   setDispositionErr(true);
    //   return false;
    // }

    // else {
    //   setDispositionErr(false);

    // }
    // if(leadStage === 7){
    //     setDispositionErr(false);
    // }
  };

  const handleChangeSubDisposition = (e) => {
    setLeadSubDispositionId(e.target.value);

    let isDisplayFollowUp = leadSubDisposition.filter(
      (subDisposition) => subDisposition.id === parseInt(e.target.value)
    )[0].isFollowupDisplay;
    setIsDisplayFollowUp(isDisplayFollowUp);

    getGrandDisposition(leadDispositionId, e.target.value);
    // if(e.target.value === ""){
    //   setSubDispositionErr(true);
    //   return false;
    // }
    // else{
    //   setSubDispositionErr(false);
    //
    // }
  };

  const handleChangeGrandDisposition = (e) => {
    setGrandDispositionId(e.target.value);
  };

  useEffect(() => {
    getLeadStage();
    if (leadStage !== 7) {
      getLeadDisposition();
    }
  }, []);
  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  useEffect(() => {
    let leadsData = leadsList;
    let userData = usersList;
    let sourcesData = sourcesList;
    let propertyTypesData = propertyTypeList;

    defaultAssign &&
      formik.setFieldValue(
        "assigned_to",
        contextState.state.currentUser.Username
      );

    axios
      .all([
        axios.get(`${ATLAS_URI}/getRoles/`, configToken),
        axios.get(`${ATLAS_URI}/getSalesRoles/`, configToken),
      ])
      .then(
        axios.spread((roles, salesRoles) => {
          const rolesData = roles.data;
          if (typeof rolesData !== "undefined" && mounted.current) {
            if (
              leadsData.length >= 0 &&
              sourcesData.length >= 0 &&
              propertyTypesData.length >= 0 &&
              userData.length >= 0
            ) {
              const newLeadList = leadsData.map((lead) => {
                const filteredPropertyType =
                  lead.PropertyType &&
                  propertyTypesData.filter(
                    (propertyType) =>
                      propertyType.id.toString() ===
                      lead.PropertyType.toString()
                  )[0];
                lead.PropertyTypes = filteredPropertyType
                  ? filteredPropertyType.PropertyType
                  : "";
                const filteredSource =
                  lead.source &&
                  sourcesData.filter(
                    (source) => source.id.toString() === lead.source.toString()
                  )[0];
                lead.Sources = filteredSource ? filteredSource.source : "";
                const filteredAssignedTo =
                  lead.AssignedTo &&
                  userData.filter(
                    (user) => user.id.toString() === lead.AssignedTo.toString()
                  )[0];
                lead._AssignedTo = filteredAssignedTo
                  ? filteredAssignedTo.Username
                  : "";
                return lead;
              });

              const newUserList = salesRoles.data.map((user) => {
                user.AssignedTo = user.Name
                  ? user.Name
                  : user.firstName + " " + (user.lastName ? user.lastName : "");
                // user.Name
                return user;
              });

              setTimeout(() => {
                setState((prevState) => ({
                  ...prevState,
                  tableBodyList: newLeadList,
                  sourceList: sourcesData,
                  propertyTypeList: propertyTypesData,
                  userList: newUserList,
                }));
              }, 200);
            }
          }
        })
      )
      .catch((err) => console.error(err));

    // }, []);
  }, [contextState]);
  // useEffect(()=>{
  //     axios.get(`${ATLAS_URI}/getSalesRoles/`, configToken).then(response=>{

  //         const newUserList = response.data.map(user => {

  //             user.AssignedTo = user.Name
  //             return user;
  //         })
  //         setState(prevState => ({
  //             ...prevState,

  //             userList:newUserList

  //         }))

  //     }).catch(err => console.log(err))
  // },[])

  const [isLeadStatusChange, setIsLeadStatusChange] = useState(false);

  function addNewLead(e) {
    e.preventDefault();
    var newLead = { ...state.newTableRow };
    newLead.LeadDate = getFormattedDate();
    newLead.InterestedIn = "";
    newLead.next_followup_date = "";
    newLead.Client = null;
    newLead.ClientName = $("#clientName").val();
    newLead.ClientPhone = $("#clientPhone").val();
    newLead.ClientEmail = $("#clientEmail").val();
    newLead.Remarks = [];

    axios
      .post(`${ATLAS_URI}/addLead/`, newLead, configToken)
      .then((res) => {
        if (res.status === 200) {
          const newDialogInfo = {
            isOpened: true,
            text: "Lead Added Successfully",
            type: "Success",
          };
          const x = {
            AssignedTo: newLead.AssignedTo,
            ClientEmail: newLead.ClientEmail,
            ClientName: newLead.ClientName,
            ClientPhone: newLead.ClientPhone,
            FollowUpList: [],
            FollowUps: [],
            LeadDate: newLead.LeadDate,
            PropertyType: newLead.PropertyType,
            PropertyTypes: state.propertyTypeList.filter(
              (property) => newLead.PropertyType === property.id.toString()
            )[0].PropertyType,
            Remarks: [],
            source: newLead.source,
            Sources: state.sourceList.filter(
              (source) => newLead.source === source.id.toString()
            )[0].source,
            VisitInfos: [],
            id: res.data.id,
            _AssignedTo: state.userList.filter(
              (assigned) => newLead.AssignedTo === assigned.id.toString()
            )[0].Name,
          };
          let newTableBodyList = [...state.tableBodyList];
          newTableBodyList.unshift(x);

          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              tableBodyList: newTableBodyList,
              newTableRow: state.resetNewRow,
              dialogInfo: newDialogInfo,
            }));
          }, 50);

          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              dialogInfo: { isOpened: false, text: "", type: "" },
            }));
            updateList("leadsList", newTableBodyList);
            $("#clientName").val("");
            $("#clientPhone").val("");
            $("#clientEmail").val("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  function onAttemptClick(e) {
    const temp = $(e.target).parents(".dtFieldRows").attr("id");

    updateEditDetails({
      ...state,
      id: parseInt(temp),
      editingActivated: false,
      redirectTo: "/Leads/leadDetails",
      redirectFrom: "/Leads/freshLeads",
    });
  }

  function openDialog(e) {
    e.preventDefault();
    const delID = e.target.parentElement.parentElement.id;
    const newDialogInfo = {
      isOpened: true,
      delID: e.target.parentElement.parentElement.id,
      text: "Are you sure you want to delete this Lead?",
      type: "Confirm",
      onTrueFn: (e) => deleteFromTable(e, delID),
    };
    setState((prevState) => ({ ...prevState, dialogInfo: newDialogInfo }));
  }

  function deleteFromTable(e, delID2) {
    axios
      .delete(`${ATLAS_URI}/deleteLead/` + delID2, configToken)
      .then(() => {
        const newTableBodyList = state.tableBodyList.filter(
          (data) => data.id.toString() !== delID2
        );
        updateList("leadsList", newTableBodyList);
        setState((prevState) => ({
          ...prevState,
          tableBodyList: newTableBodyList,
          dialogInfo: {
            isOpened: false,
            text: "",
          },
        }));
      })
      .catch((err) => console.error(err));
  }

  function validateThenAddLead(e) {
    if (state.newTableRow.PropertyType === "Select") {
      e.preventDefault();
      const newDialogInfo = {
        isOpened: true,
        text: "Property Type is left Empty",
        type: "Error",
      };
      setState((prevState) => ({ ...prevState, dialogInfo: newDialogInfo }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          dialogInfo: { isOpened: false, text: "", type: "" },
        }));
      }, 3000);
    } else if (state.newTableRow.source === "Select") {
      e.preventDefault();
      const newDialogInfo = {
        isOpened: true,
        text: "Source is left Empty",
        type: "Error",
      };
      setState((prevState) => ({ ...prevState, dialogInfo: newDialogInfo }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          dialogInfo: { isOpened: false, text: "", type: "" },
        }));
      }, 3000);
    } else if (state.newTableRow.AssignedTo === "Select") {
      e.preventDefault();
      const newDialogInfo = {
        isOpened: true,
        text: "User is left Empty",
        type: "Error",
      };
      setState((prevState) => ({ ...prevState, dialogInfo: newDialogInfo }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          dialogInfo: { isOpened: false, text: "", type: "" },
        }));
      }, 3000);
    } else {
      addNewLead(e);
    }
  }

  // function editRecord(e) {
  //     const temp = e.target.parentElement.parentElement.id;
  //     const cId = state.tableBodyList.filter((item) => item.id === temp)[0].Client
  //     props.updateEditDetails({ id: cId, editingActivated: true, redirectFrom: "/Clients/listClients", pageFlag: "Clients > Add Client" });
  // }

  function openInputModal(leads) {
    openInputModalLeads(setState, leads);
  }

  function onSelectChange(e) {
    let newValue;
    newValue = e.target.value;
    setState((prevState) => ({
      ...prevState,
      newTableRow: {
        ...prevState.newTableRow,
        [e.target.name]: newValue,
      },
    }));
    let selectedOption = e.target.value;
  }
  var currentMedicalCondition = [];
  $("input:checkbox[name=current_medical_condition]:checked").each(function () {
    currentMedicalCondition.push($(this).val());
  });

  const onSubmit = async () => {
    setDisableSavebtn(true);
    if (!leadStage) {
      setLeadStageErr(true);
      return false;
    }
    //   if(!leadDispositionId && leadStage !== 7){
    //     setDispositionErr(true);
    //     return false;
    //   }
    //   if(!leadSubDispositionId && leadStage !== 7){
    //     setSubDispositionErr(true);
    //     return false;
    //   }

    let newLead = formik.values;
    newLead.vmax_lead_stage_id = leadStage;
    newLead.vmax_lead_disposition_id = !!leadDispositionId
      ? leadDispositionId
      : undefined;
    newLead.vmax_lead_sub_disposition_id = !!leadSubDispositionId
      ? leadSubDispositionId
      : undefined;
    newLead.vmax_lead_sub_not_interested_id = !!grandDispositionId
      ? grandDispositionId
      : undefined;
    newLead.created_by = operator.id;
    newLead.occupation = !!newLead.occupation ? newLead.occupation : undefined;
    newLead.source = !!newLead.source ? newLead.source : undefined;
    //assigning default  user id if the user is sales person
    newLead.gender = !!newLead.gender ? newLead.gender : undefined;
    if (defaultAssign) newLead.assigned_to = contextState.state.currentUser.id;

    let lead_id;
    if (!formik.values.assigned_to) {
      axios
        .post(`${ATLAS_URI}/addLeadFromCRM/`, newLead, configToken)
        .then((res, error) => {
          props.closePopupSetter(false);
          setDisableSavebtn(false);
          lead_id = res.data.lead_id;
          newLead.id = res.data.lead_id;

          axios
            .post(`${ATLAS_URI}/autoAllocateLead`, newLead, configToken)
            .then((userData) => {
              newLead.assigned_to =
                userData.data == "" || !userData.data
                  ? undefined
                  : userData.data;

              axios
                .post(
                  `${ATLAS_URI}/updateLead/${lead_id}`,
                  newLead,
                  configToken
                )
                .then((res) => {

                })
                .catch((err) => {
                  console.error(err);
                });
            })
            .catch((err) => {
              setDisableSavebtn(false);
              toast.error(err.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              console.error(err.response.data.message);
            });
          if (res.status === 400) {

          }
          let list = [...leadsList];
          var foundIndex = list.findIndex((x) => x.id == EditDetailsData.id);
          list[foundIndex] = newLead;
          updateList("leadsList", list);

          setState((prevState) => ({
            ...prevState,
            editingActivated: false,
            newTableRow: state.resetNewRow,
          }));

          window.location.reload();

          // redirectFromEditDetails(EditDetailsData.redirectFrom)
        })
        .catch((err) => {
          setDisableSavebtn(false);
          console.error("err", err.message);
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      axios
        .post(`${ATLAS_URI}/addLeadFromCRM/`, newLead, configToken)
        .then((res, error) => {
          if (res.status === 400) {
            setDisableSavebtn(false);
            console.error(JSON.stringify(res.data));
          }
          let list = [...leadsList];
          var foundIndex = list.findIndex((x) => x.id == EditDetailsData.id);
          list[foundIndex] = newLead;
          updateList("leadsList", list);

          setState((prevState) => ({
            ...prevState,
            editingActivated: false,
            newTableRow: state.resetNewRow,
          }));

          window.location.reload();

          // redirectFromEditDetails(EditDetailsData.redirectFrom)
        })
        .catch((err) => {
          setDisableSavebtn(false);
          console.error("err", err.message);
          //   if (err.message == "Request failed with status code 400") {
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          //   }
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...state.newTableRow,
    },
    validationSchema: addLeadSchema,
    onSubmit,
  });


  // changes temporary
  // useEffect(()=>{
  //     insertIntoTable()
  // })

  function changeHandler(e) {
    oldChangeHandler(e, state, setState);
  }

  const handleChange = (e) => {
    let data = state.newTableRow.current_medical_condition;
    data.push(e.target.value);
    setState((prevState) => ({
      ...prevState.newTableRow,
      current_medical_condition: data,
    }));
  };


  console.log(formik.errors, 'formik.errors')

  return (
    <section className="content">
      <div className="row">
        {state.isModalOpen && (
          <BulkUpdateModal
            state={state}
            closeModal={closeInputModal}
            SelectedLeads={state.SelectedLeads}
            userList={state.userList}
            ATLAS_URI={ATLAS_URI}
          />
        )}
        <Dialog
          onFalse={(e) => {
            setState((prevState) => ({
              ...prevState,
              dialogInfo: { isOpened: false, text: "", wrongData: [] },
            }));
          }}
          onTrue={state.dialogInfo.onTrueFn}
          dialogInfo={state.dialogInfo}
        />
        <div className="col-md-12">
          <div className="box box-primary">
            <BoxHeader
              title={`${state.editingActivated ? "Edit" : "Add New"} Lead`}
            />

            <form onSubmit={formik.handleSubmit}>
              <div className="box-body">
                <React.Fragment>
                  <div className="form-group">
                    <label>Name</label>
                    <small className="req"> *</small>
                    <label className="col-md-8 floatclass">
                      <input
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.name && formik.touched.name
                            ? "form-control input-error"
                            : "form-control"
                        }
                      />
                    </label>
                  </div>
                  {formik.errors.name && formik.touched.name && (
                    <p className="error-message">{formik.errors.name}</p>
                  )}

                  <div className="form-group">
                    <label>Phone Number</label>
                    <small className="req"> *</small>
                    <label className="col-md-8 floatclass">
                      {/* <input name='contact_number' value={state.newTableRow.contact_number} onChange={changeHandler} type="dfstext" id='clientPhone'  className="form-control" /> */}
                      <PhoneInput
                        value={formik.values.contact_number}
                        onChange={(e) => {
                          formik.setFieldValue("contact_number", "+" + e);
                        }}
                        country={"in"}
                        enableSearch={true}
                        inputStyle={
                          formik.errors.contact_number &&
                            formik.touched.contact_number
                            ? {
                              paddingLeft: "5%",
                              backgroundColor: "#fce4e4",
                              borderColor: "#ff01416a",
                            }
                            : { paddingLeft: "5%" }
                        }
                      />
                    </label>
                  </div>
                  {formik.errors.contact_number &&
                    formik.touched.contact_number && (
                      <p className="error-message">
                        {formik.errors.contact_number}
                      </p>
                    )}

                  <div className="form-group">
                    <label>Refferal</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="refferal"
                        onBlur={formik.handleBlur}
                        value={formik.values.refferal}
                        type="text"
                        id="clientEmail"
                        className="form-control"
                        onChange={formik.handleChange}
                      />
                    </label>
                  </div>
                  {formik.errors.refferal && formik.touched.refferal && (
                    <p className="error-message">{formik.errors.refferal}</p>
                  )}
                  <div className="form-group">
                    <label>Email Id</label>
                    <small className="req"> *</small>
                    <label className="col-md-8 floatclass">
                      <input
                        name="email_id"
                        value={formik.values.email_id}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        id="clientEmail"
                        className={
                          formik.errors.email_id && formik.touched.email_id
                            ? "form-control input-error"
                            : "form-control"
                        }
                      />
                    </label>
                  </div>
                  {formik.errors.email_id && formik.touched.email_id && (
                    <p className="error-message">{formik.errors.email_id}</p>
                  )}

                  {/* START- Changes are did on 15/10/2022 */}
                  {/* <div className="form-group">
                                        <label>Source</label>
                                        <label className='col-md-8 floatclass'><input name='account_name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.account_name} type="text" id='account_name' className='form-control' /></label>
                                    </div> */}
                  {/* {formik.errors.account_name && formik.touched.account_name && <p className="error-message">{formik.errors.account_name}</p>} */}

                  <div className="form-group">
                    <label>Age</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="age"
                        className="form-control"
                        onBlur={formik.handleBlur}
                        value={formik.values.age}
                        onChange={formik.handleChange}
                      />
                    </label>
                    {formik.errors.age && formik.touched.age && (
                      <p className="error-message">{formik.errors.age}</p>
                    )}
                  </div>
                  {/* <div className="form-group">
                                        <label>Date Of Birth</label>
                                        <label className='col-md-8 floatclass'><input name="dob" type="date" className='form-control' onBlur={formik.handleBlur} value={formik.values.dob} onChange={formik.handleChange} /></label>
                                    </div>
                                    {formik.errors.dob && formik.touched.dob && <p className="error-message">{formik.errors.dob}</p>} */}

                  <div className="form-group ">
                    <label
                      className={
                        formik.errors.gender && formik.touched.gender
                          ? "gender-error"
                          : ""
                      }
                    >
                      Gender :
                    </label>{" "}
                    &nbsp;&nbsp;
                    <span
                      className="leftside_move"
                      style={{ position: "relative", left: "300px" }}
                    >
                      <input
                        name="gender"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value="M"
                        type="radio"
                        id="clientEmail"
                      />
                      &nbsp;&nbsp;<label>Male</label>&nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        name="gender"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value="F"
                        type="radio"
                        id="clientEmail"
                      />
                      &nbsp;&nbsp;<label>Female</label>
                      <br />
                    </span>
                  </div>
                  {formik.errors.gender && formik.touched.gender && (
                    <p className="error-message">{formik.errors.gender}</p>
                  )}

                  <div className="form-group">
                    <label>Height (in cms)</label>
                    <small className="req"> *</small>
                    <label className="col-md-8 floatclass">
                      <input
                        name="height"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.height}
                        type="height"
                        id="clientEmail"
                        className={
                          formik.errors.height && formik.touched.height
                            ? "form-control input-error"
                            : "form-control"
                        }
                      />
                    </label>
                  </div>
                  {formik.errors.height && formik.touched.height && (
                    <p className="error-message">{formik.errors.height}</p>
                  )}

                  <div className="form-group">
                    <label>Current Weight (in Kg)</label>
                    <small className="req"> *</small>
                    <label className="col-md-8 floatclass">
                      <input
                        name="current_weight"
                        value={formik.values.current_weight}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="current_weight"
                        id="clientEmail"
                        className={
                          formik.errors.current_weight &&
                            formik.touched.current_weight
                            ? "form-control input-error"
                            : "form-control"
                        }
                      />
                    </label>
                  </div>
                  {formik.errors.current_weight &&
                    formik.touched.current_weight && (
                      <p className="error-message">
                        {formik.errors.current_weight}
                      </p>
                    )}

                  <div className="form-group">
                    <label>City</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        id="clientEmail"
                        className={
                          formik.errors.city && formik.touched.city
                            ? "form-control input-error"
                            : "form-control"
                        }
                      />
                    </label>
                    {formik.errors.city && formik.touched.city && (
                      <p className="error-message">{formik.errors.city}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Country</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="country"
                        value={formik.values.country}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        id="clientEmail"
                        className={
                          formik.errors.country && formik.touched.country
                            ? "form-control input-error"
                            : "form-control"
                        }
                      />
                    </label>
                  </div>
                  {formik.errors.country && formik.touched.country && (
                    <p className="error-message">{formik.errors.country}</p>
                  )}

                  {/* <div className="form-group">
                                        <label>Location</label>
                                        <label className='col-md-8 floatclass'><input name='address' value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" id='clientEmail' className="form-control" /></label>
                                    </div> */}

                  {/* <div className="form-group">
                                        <label>State</label>
                                        <label className='col-md-8 floatclass'><input onChange={formik.handleChange} value={formik.values.state} onBlur={formik.handleBlur} name='state' type="state" id='clientEmail' className={formik.errors.state && formik.touched.state?'form-control input-error':'form-control'} /></label>
                                    </div>
                                    {formik.errors.state && formik.touched.state && <p className="error-message">{formik.errors.state}</p>}

                                  

                                    <div className="form-group">
                                        <label>Pincode</label>
                                        <label className='col-md-8 floatclass'><input name='pincode'  value={formik.values.pincode} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" id='clientEmail' className={formik.errors.pincode && formik.touched.pincode?'form-control input-error':'form-control'} /></label>
                                    </div>
                                    {formik.errors.pincode && formik.touched.pincode && <p className="error-message">{formik.errors.pincode}</p>} */}

                  {/* <div className="form-group">
                                        <label>Mother Tongue</label>
                                        <label className='col-md-8 floatclass'><select className="form-control" name="mother_tongue" onBlur={formik.handleBlur} value={formik.values.mother_tongue} onChange={formik.handleChange} >
                                        <option value="">Select</option>
                                        <option value="English">
                                        English
                                        </option>
                                        val<option value="Tamil">
                                            Tamil
                                        </option>
                                        <option value="Telugu">
                                            Telugu
                                        </option>
                                        <option value="Hindi">
                                            Hindi
                                        </option>
                                        <option value="Bengali">
                                            Bengali
                                        </option>
                                        <option value="Kannada">
                                            Kannada
                                        </option>
                                        <option value="Urdu">
                                            Urdu
                                        </option>
                                        <option value="Malayalam">
                                            Malayalam
                                        </option>
                                        

                                    </select></label>
                                    </div> */}

                  <div className="form-group">
                    <label>
                      Preferred Language<small className="req">*</small>
                    </label>
                    {/* <input name='preferred_language' value={formik.values.preferred_language} type="preferred_language" id='clientEmail' className="form-control" onChange={formik.handleChange} /> */}
                    <div className="row">
                      <div className="col-md-3 px-5 ">
                        <div className="form-group">
                          <input
                            type="radio"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value="English"
                            name="preferred_language"
                          />
                          &nbsp;&nbsp;<label>English</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group">
                          <input
                            type="radio"
                            value="Tamil"
                            onChange={formik.handleChange}
                            name="preferred_language"
                          />
                          &nbsp;&nbsp;<label>Tamil</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group">
                          <input
                            type="radio"
                            value="Telugu"
                            onChange={formik.handleChange}
                            name="preferred_language"
                          />
                          &nbsp;&nbsp;<label>Telugu</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group">
                          <input
                            type="radio"
                            value="Hindi"
                            onChange={formik.handleChange}
                            name="preferred_language"
                          />
                          &nbsp;&nbsp;<label>Hindi</label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 px-5">
                        <div className="form-group">
                          <input
                            type="radio"
                            value="Bengali"
                            onChange={formik.handleChange}
                            name="preferred_language"
                          />
                          &nbsp;&nbsp;<label>Bengali</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group">
                          <input
                            type="radio"
                            value="Kannada"
                            onChange={formik.handleChange}
                            name="preferred_language"
                          />
                          &nbsp;&nbsp;<label>Kannada</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group">
                          <input
                            type="radio"
                            value="Urdu"
                            onChange={formik.handleChange}
                            name="preferred_language"
                          />
                          &nbsp;&nbsp;<label>Urdu</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group">
                          <input
                            type="radio"
                            value="Malayalam"
                            onChange={formik.handleChange}
                            name="preferred_language"
                          />
                          &nbsp;&nbsp;<label>Malayalam</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {formik.errors.preferred_language &&
                    formik.touched.preferred_language && (
                      <p className="error-message">
                        {formik.errors.preferred_language}
                      </p>
                    )}

                  <div className="form-group">
                    <label>Profession</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="occupation"
                        onBlur={formik.handleBlur}
                        value={formik.values.occupation}
                        type="text"
                        id="clientEmail"
                        className="form-control"
                        onChange={formik.handleChange}
                      />
                    </label>
                  </div>
                  {formik.errors.occupation && formik.touched.occupation && (
                    <p className="error-message">{formik.errors.occupation}</p>
                  )}

                  {/* <div className="form-group">
                                        <label>Work Hours</label>
                                        <label className='col-md-8 floatclass'><input placeholder='Eg : 9 - 5' name='work_hours' onBlur={formik.handleBlur} value={formik.values.work_hours} onChange={formik.handleChange} type="text" id='clientEmail'  className={formik.errors.work_hours && formik.touched.work_hours?'form-control input-error':'form-control'} /></label>
                                    </div> */}

                  {/* <div className="form-group">
                                        <label>Family Details</label>
                                        <label className='col-md-8 floatclass'><input value={formik.values.family_details} onBlur={formik.handleBlur} name='family_details' type="text" id='clientEmail' onChange={formik.handleChange} className="form-control" /></label>
                                    </div> */}

                  {/* <div className="form-group">
                                        <label>Emergency Contact</label>
                                        <label className='col-md-8 floatclass'><input onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.emergency_contact} name='emergency_contact' type="emergency_contact" id='clientEmail' className={formik.errors.emergency_contact && formik.touched.emergency_contact?'form-control input-error':'form-control'} /></label>
                                    </div>
                                    {formik.errors.emergency_contact && formik.touched.emergency_contact && <p className="error-message">{formik.errors.emergency_contact}</p>} */}

                  {/* <div className="form-group">
                                        <label>Emergency Contact</label>
                                        <label className='col-md-8 floatclass'>
                                            <PhoneInput value={formik.values.emergency_contact} onChange={(e) => { formik.setFieldValue('emergency_contact', "+" + e) }} country={"in"} enableSearch={true} inputStyle={formik.errors.emergency_contact && formik.touched.emergency_contact ? { paddingLeft: '5%', backgroundColor: '#fce4e4', borderColor: '#ff01416a' } : { paddingLeft: '5%' }} />
                                        </label>
                                    </div>
                                    {formik.errors.emergency_contact && formik.touched.emergency_contact && <p className="error-message">{formik.errors.emergency_contact}</p>} */}

                  <div className="form-group">
                    <label
                      className={
                        formik.errors.current_medical_condition &&
                          formik.touched.current_medical_condition
                          ? "checkbox-error"
                          : ""
                      }
                    >
                      Current Medical Condition
                    </label>

                    {/* <input onChange={formik.handleChange} value={formik.values.current_medical_condition} name='current_medical_condition' type="current_medical_condition" id='clientEmail' className="form-control" /> */}
                    <div className="row">
                      <div className="col-md-3 px-5">
                        <div className="form-group "></div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group ">
                          <input
                            type="checkbox"
                            value="Diabetes"
                            onChange={formik.handleChange}
                            disabled={currentMedicalCondition.some(
                              (e) => e == "None"
                            )}
                            name="current_medical_condition"
                          />
                          &nbsp;&nbsp;<label>Diabetes</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group ">
                          <input
                            type="checkbox"
                            value="Cholesterol"
                            onChange={formik.handleChange}
                            disabled={currentMedicalCondition.some(
                              (e) => e == "None"
                            )}
                            name="current_medical_condition"
                          />
                          &nbsp;&nbsp;<label>Cholesterol</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5 ">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            value="Hypertension"
                            onChange={formik.handleChange}
                            disabled={currentMedicalCondition.some(
                              (e) => e == "None"
                            )}
                            name="current_medical_condition"
                          />
                          &nbsp;&nbsp;<label>Hypertension</label>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-3 px-5">
                        <div className="form-group ">
                          <input
                            type="checkbox"
                            value="PCOS"
                            onChange={formik.handleChange}
                            disabled={currentMedicalCondition.some(
                              (e) => e == "None"
                            )}
                            name="current_medical_condition"
                          />
                          &nbsp;&nbsp;<label>PCOS</label>
                        </div>
                      </div>
                      <div className="col-md-3   px-5">
                        <div className="form-group ">
                          <input
                            type="checkbox"
                            value="Thyroid"
                            onChange={formik.handleChange}
                            disabled={currentMedicalCondition.some(
                              (e) => e == "None"
                            )}
                            name="current_medical_condition"
                          />
                          &nbsp;&nbsp;<label>Thyroid</label>
                        </div>
                      </div>
                      <div className="col-md-3  px-5">
                        <div className="form-group ">
                          <input
                            type="checkbox"
                            value="Physical Injury"
                            onChange={formik.handleChange}
                            disabled={currentMedicalCondition.some(
                              (e) => e == "None"
                            )}
                            name="current_medical_condition"
                          />
                          &nbsp;<label>Physical Injury</label>
                        </div>
                      </div>
                      <div className="col-md-3 px-5">
                        <div className="form-group ">
                          <input
                            type="checkbox"
                            value="None"
                            onChange={formik.handleChange}
                            disabled={
                              currentMedicalCondition.some(
                                (e) => e !== "None"
                              ) && currentMedicalCondition.length > 0
                            }
                            name="current_medical_condition"
                          />
                          &nbsp;&nbsp;<label>None</label>
                        </div>
                      </div>
                    </div>

                    {formik.errors.current_medical_condition &&
                      formik.touched.current_medical_condition && (
                        <p className="error-message">
                          {formik.errors.current_medical_condition}
                        </p>
                      )}
                  </div>

                  {/* <div className="form-group">
                                        <label>Interested In</label>
                                        <label className='col-md-8 floatclass'><input onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.interestedin} name='interested in' type="interested_in" id='clientEmail' className="form-control" /></label>
                                    </div> */}

                  {/* <div className="form-group ">
                                    <label>Lead Status</label> <small className="req"> *</small>

                                        <label className='col-md-8 floatclass'  ><select name="lead_status" value={formik.values.lead_status} onChange={(e) => {
                                            setIsLeadStatusChange(true)
                                            formik.handleChange(e)
                                        }} className={formik.errors.lead_status && formik.touched.lead_status?'form-control input-error':'form-control'}>
                                        <option value="" >{"Select"}</option>
                                        {
                                            statusList.map((status) =>
                                                <option key={status.id} value={status.id}>{status.Status}</option>
                                            )
                                        }

                                    </select></label>
                                    {formik.errors.lead_status && formik.touched.lead_status && <p className="error-message">{formik.errors.lead_status}</p>}
                                </div> */}

                  <div className="form-group">
                    <label>
                      Lead Stage<small className="req">*</small>
                    </label>

                    <label className="col-md-8 floatclass">
                      <select
                        name="vmax_lead_stage_id"
                        value={leadStage}
                        onChange={handleChangeLeadStage}
                        className={
                          formik.errors.vmax_lead_stage_id &&
                            formik.touched.vmax_lead_stage_id
                            ? "form-control input-error"
                            : "form-control"
                        }
                      >
                        {/* <option value="">Select</option> */}
                        {/* <option value={leadStageList[7]?.leadStage}>{leadStageList[7].id}</option> */}

                        {leadStageList.map((stage, id) => (
                          <option value={stage.id} key={stage.leadStage}>
                            {stage.leadStage}
                          </option>
                        ))}
                      </select>
                    </label>

                    {leadStageErr ? (
                      <p className="error-message">Please Select Somethig</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group">
                    {leadStage === "5" || leadStage === "7" ? (
                      <></>
                    ) : (
                      <>
                        <label> Disposition</label>

                        <label className="col-md-8 floatclass">
                          <select
                            value={leadDispositionId}
                            onChange={handleChangeDisposition}
                            className="form-control"
                          >
                            <option value="">Select</option>
                            {leadDisposition.map((stage) => (
                              <option value={stage.id}>
                                {stage.leadDisposition}
                              </option>
                            ))}
                          </select>
                        </label>
                        {dispositionErr ? (
                          <p className="error-message">
                            Please Select Something
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>

                  {
                    //   leadSubDisposition.length !== 0?
                    leadStage === "5" ||
                      leadStage === "7" ||
                      (leadStage == "8" && leadDispositionId == "1") ? (
                      <></>
                    ) : (
                      <div className="form-group">
                        <label> Sub Disposition</label>

                        <label className="col-md-8 floatclass">
                          <select
                            value={leadSubDispositionId}
                            onChange={handleChangeSubDisposition}
                            className="form-control"
                          >
                            <option value="">Select</option>
                            {leadSubDisposition.map((stage) => (
                              <option value={stage.id}>
                                {stage.leadSubDisposition}
                              </option>
                            ))}
                          </select>
                        </label>
                        {subDispositionErr ? (
                          <p className="error-message">
                            Please Select Something
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                    //  :""
                  }
                  {grandDisposition.length !== 0 ? (
                    <div className="form-group">
                      <label>
                        {" "}
                        Grand Disposition<small className="req">*</small>
                      </label>

                      <label className="col-md-8 floatclass">
                        <select
                          value={grandDispositionId}
                          onChange={handleChangeGrandDisposition}
                          className="form-control"
                        >
                          <option value="">Select</option>
                          {grandDisposition.map((stage) => (
                            <option value={stage.id}>
                              {stage.leadSubNotInterested}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <div className="form-group col-md-12">
                                    <label>Call Status</label>  

                                    <label  className='col-md-8 floatclass'   ><select className="form-control" name="call_status" value={formik.values.call_status} onChange={formik.handleChange} >
                                        <option value="">Select</option>
                                        <option value="Not Contacted">
                                            Not Contacted
                                        </option>
                                        {formik.values.lead_status !== "Un-Attempted" && <option value="Contacted">
                                            Contacted
                                        </option>}

                                    </select></label>
                                </div> */}
                  {/* <div className='form-group'>
                                    <label>Notes</label>
                                    <label className='col-md-8 floatclass'><input name='notes' value={formik.values.notes} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text"  className="form-control" /></label>
                                {formik.errors.notes && formik.touched.notes && <p className='error-message'>{formik.errors.notes}</p>}
                                </div> */}

                  {isDisplayFollowUp !== null && isDisplayFollowUp && (
                    <div className="form-group">
                      <label>
                        Next
                        <br /> Followup
                        <br /> Date
                      </label>
                      <label
                        className="col-md-8 floatclass"
                        style={{ float: "right", marginRight: "20px" }}
                      >
                        <input
                          name="next_followup_date"
                          type="datetime-local"
                          className={
                            formik.errors.next_followup_date &&
                              formik.touched.next_followup_date
                              ? "form-control input-error"
                              : "form-control"
                          }
                          value={formik.values.next_followup_date}
                          onChange={formik.handleChange}
                        />
                      </label>
                    </div>
                  )}
                  {formik.errors.next_followup_date &&
                    formik.touched.next_followup_date && (
                      <p className="error-message">
                        {formik.errors.next_followup_date}
                      </p>
                    )}

                  <div className="form-group">
                    {defaultAssign ? (
                      <div className="form-group">
                        <label>Assigned To</label>
                        <label className="col-md-8 floatclass">
                          <input
                            disabled={true}
                            name="assigned_to"
                            value={formik.values.assigned_to}
                            onChange={formik.handleChange}
                            type="text"
                            id="clientEmail"
                            className={
                              formik.errors.work_hours &&
                                formik.touched.work_hours
                                ? "form-control input-error"
                                : "form-control"
                            }
                          />
                        </label>
                      </div>
                    ) : (
                      <SelectBox
                        label="Assigned To"
                        name="assigned_to"
                        isEdit={false}
                        options={state.userList}
                        attributeShown="AssignedTo"
                        changeHandler={formik.handleChange}
                        value={formik.values.assigned_to}
                        resetValue={() =>
                          setState((prevState) => ({
                            ...prevState,
                            newTableRow: {
                              ...prevState.newTableRow,
                              AssignedTo: "Select",
                            },
                          }))
                        }
                        onAssignedTo={formik}
                        isLeadStatusChange={isLeadStatusChange}
                      // styleAdjust={true}
                      />
                    )}
                  </div>

                  {/* <SelectBox
                                    label="Property Type"
                                    name="PropertyType"
                                    options={state.propertyTypeList}
                                    attributeShown="PropertyType"
                                    changeHandler={(e) => changeHandler(e, state, setState)}
                                    value={state.newTableRow.PropertyType}
                                    resetValue={() => setState(prevState => ({ ...prevState, newTableRow: { ...prevState.newTableRow, PropertyType: "Select" } }))}
                                /> */}
                  <div className="form-group">
                    <SelectBox
                      label="Account Name"
                      name="source"
                      isEdit={false}
                      options={state.sourceList}
                      attributeShown="Source"
                      changeHandler={formik.handleChange}
                      value={formik.values.source}
                      resetValue={() =>
                        setState((prevState) => ({
                          ...prevState,
                          newTableRow: {
                            ...prevState.newTableRow,
                            source: "Select",
                          },
                        }))
                      }
                    //    styleAdjust={true}
                    />
                  </div>
                </React.Fragment>
              </div>

              <br />
              <br />
              <div className="box-footer">
                <Button
                  type="submit"
                  disabled={disableSavebtn}
                  className="btn btn-info pull-right"
                >
                  Save
                </Button>
              </div>
            </form>
          </div>

          {/* <div className="box box-primary"> */}
          {/* <BoxHeader title="Import Data" /> */}
          {/* <form onSubmit={(e) => importData(e, props, state, setState)}> */}
          {/* <div className="box-body">
                                <div className="form-group">
                                    <input type="file" id="importFile"  className='form-control' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </div>
                                <a href={`${ATLAS_URI}/file/import-sample.xlsx`} download rel="noopener noreferrer" target="_blank" className="downloadLink">Download Sample Excel File</a>

                            </div> */}

          {/* <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right">Import</button>
                            </div> */}
          {/* </form> */}

          {/* </div> */}
        </div>

        {/* <div className="col-md-6">

                    <div className="box box-primary">
                        <BoxHeader title="Recently Leads Created" />

                        <div className="box-body">

                            //  Data Table is saved in Notepad

                        </div>

                    </div> */}

        {/* </div> */}
      </div>
    </section>
  );
}

export default FreshLeads;
