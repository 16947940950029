import { Button, Grid, Typography, Box, Paper } from "@mui/material";
import { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import AccessItem from "./AccessItem";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const initialObject = {
  accessDetails: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "getAccesss": {
      return {
        ...state,
        accessDetails: action.payload,
      };
    }
    default: {
      throw new Error("Unkown Action");
    }
  }
};

const AccessDashborad = () => {
  const history = useHistory();
  const [{ accessDetails }, dispatch] = useReducer(reducer, initialObject);

  const handleAddTask = () => {
    history.push({
      pathname: "/Programs/accessAutomation/addAccess",
      //   state: { programDetails: programDetails },
    });
  };

  const handleSearch = async (event) => {
    try {
      const response = await axiosFetch({
        method: "get",
        url: `/getFeatureRuleSearch?search=${event.target.value}`,
      });

      if (response.status === 200) {
        const data = response.data.body;
        dispatch({ type: "getAccesss", payload: data });
      }
    } catch (error) {}
  };

  const getAccessDetails = async () => {
    try {
      const response = await axiosFetch({
        method: "get",
        url: `/getProgramFeatureRules`,
      });

      if (response.status === 200) {
        const data = response.data.body;
        dispatch({ type: "getAccesss", payload: data });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAccessDetails();
  }, []);

  return (
    <div className="new-client">
      <div className="new-cient-container">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              textTransform: "capitalize",
              marginLeft: "5px",
            }}
          >
            Access Automation
          </Typography>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#000",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#000",
              },
              border: "20px slid #2a2a2a",
            }}
            onClick={handleAddTask}
          >
            Add Access
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
              marginBottom: "20px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearch}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
        {accessDetails?.map((access, index) => (
          <AccessItem
            access={access}
            id={index}
            key={access.id}
            getAccessDetails={getAccessDetails}
          />
        ))}
      </div>
    </div>
  );
};

export default AccessDashborad;
