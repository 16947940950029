import "./DropdownMenu.css";

import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ArticleIcon from "@mui/icons-material/Article";
import { useEffect } from "react";

const DropdownMenu = ({ notification, triggerTime, markNotification }) => {
  useEffect(() => {}, [notification]);

  const handleMark = () => {
    markNotification(notification.map((item) => item.id));
  };

  return (
    <div className="drop-down-menu">
      <div className="drop-down-title">
        <span>All Notifications</span>
        <span
          onClick={handleMark}
          className={`${notification.length === 0 ? "mark-read" : ""}`}
        >
          Mark all as read
        </span>
      </div>
      <ul>
        {notification.length === 0 && (
          <li className="not-found">
            <span>Not Found</span>
          </li>
        )}
        {notification.length > 0 &&
          notification.map((item, index) => (
            <li
              key={index}
              className="read"
              onClick={() => markNotification([item.id])}
            >
              <div className="icon-container">
                <ArticleIcon style={{ fontSize: "50px", color: "#6B6B6B" }} />
                <div className="notification-message">
                  <span>{item.notification_text}</span>
                  <span>{item.due_duration} minutes</span>
                </div>
              </div>
              <span> {triggerTime}</span>
            </li>
          ))}
        {/*  <li>
          <div className="icon-container">
            <EventAvailableIcon
              style={{ fontSize: "50px", color: "#6B6B6B" }}
            />
            <div className="notification-message">
              <span>Meeting Reminder</span>
              <span>Renewal call due in 5 mins.</span>
            </div>
          </div>
          <span> a few seconds ago</span>
        </li>
        <li>
          <div className="icon-container">
            <ArticleIcon style={{ fontSize: "50px", color: "#6B6B6B" }} />
            <div className="notification-message">
              <span>Task Reminder</span>
              <span>Follow Up with James due in 5 mins.</span>
            </div>
          </div>
          <span> 5 mins ago</span>
        </li> */}
      </ul>
    </div>
  );
};

export default DropdownMenu;
