import {
  Typography,
  Button,
  CardContent,
  CardActions,
  Card,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  Stack,
  FormHelperText,
} from "@mui/material";
import { useRef, useState, useEffect } from "react";
import "./styles/AddProgram.css";
import { TextareaAutosize } from "@mui/base";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { ATLAS_URI } from "../../Constants";
import { useContext } from "react";
import stateContext from "../../context/StateContext";
import axios from "axios";
import Image from "react-bootstrap/Image";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

const AddProgram = () => {
  const { id: routeProgramId } = useParams();

  const startDateRef = useRef();
  const endDateRef = useRef();

  const history = useHistory();

  const contextState = useContext(stateContext);
  let { configToken } = contextState.state;

  const [programId, setProgramId] = useState(
    typeof routeProgramId !== "undefined" ? routeProgramId : 0
  );
  const [crudType, setCrudType] = useState(
    typeof routeProgramId !== "undefined" ? "update" : "create"
  );

  const [name, setName] = useState("");
  const [isNameErr, setIsNameErr] = useState(false);
  const [Category, setCategory] = useState("");
  const [CategoryList, setCategoryList] = useState(["Service", "Product"]);
  const [duration, setDuration] = useState("");
  const [durationUnit, setDurationUnit] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [pdescription, setPDescription] = useState("");

  const [isP_Active, setIsP_Active] = useState(false);
  const [isP_Quotation, setIsP_Quotation] = useState(false);
  const [isP_Approval, setIsP_Approval] = useState(false);
  const [isP_AutoAllocate, setIsP_AutoAllocate] = useState(false);
  const [isP_Bill, setIsP_Bill] = useState(false);
  const [isConsulation, setIsConsulation] = useState(false);
  const [isP_order, setIsP_order] = useState(false);
  const [isP_service, setIsP_service] = useState(false);
  const [isP_Ship, setIsP_Ship] = useState(false);

  const [vendorNum, setVendorNum] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [owner, setOwner] = useState("");
  const [tax_address, setTax_Address] = useState("");
  const [tax_status, setTax_Status] = useState("");
  const [tax_description, setTax_Description] = useState("");
  const [isTaxable, setIsTaxable] = useState(false);

  const [isStartDateErr, setIsStartDateErr] = useState(false);
  const [isEndDateErr, setIsEndDateErr] = useState(false);
  const [isDurationErr, setIsDurationErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [isDurationUnitErr, setIsDurationUnitErr] = useState(false);
  const [file, setFile] = useState("");
  const [thumbnailImg, setThumbnailImg] = useState("");
  const [userList, setUserList] = useState([]);
  const [type, setType] = useState("");
  const [subType, setSubType] = useState("");

  const [typeList, setTypeList] = useState([]);
  const [subTypeList, setSubTypeList] = useState([]);
  const [programDetails, setProgramDetails] = useState(null);

  // to create img url
  const [productImgUrl, setProductImgUrl] = useState("");
  const [thumbImgUrl, setThumbImgUrl] = useState("");
  const [isProgramOwnderErr, setIsProgramOwnderErr] = useState(false);
  const [isProgramTypeErr, setIsProgramTypeErr] = useState(false);
  const [isProgramSubTypeErr, setIsProgramSubTypeErr] = useState(false);

  const [isImageErr, setIsImageErr] = useState(false);
  const [isImageThumbErr, setIsImageThumbErr] = useState(false);
  const [isDateRangeErr, setIsDateRangeErr] = useState(false);

  async function getProgramTypes() {
    const res = await axios.get(`${ATLAS_URI}/getProgramTypes/`, configToken);
    setTypeList(res.data.body);
  }
  async function getProgramSubTypes(typeId) {
    const res = await axios.get(
      `${ATLAS_URI}/getProgramSubTypes/${typeId}`,
      configToken
    );
    setSubTypeList(res.data.body);
  }

  async function getProgramDetails(progId) {
    const res = await axios.get(
      `${ATLAS_URI}/getProductByProdId/${progId}`,
      configToken
    );
    await getProgramSubTypes(res.data.type_id);

    setProgramDetails(res.data);
    setName(res.data.name);
    setCategory(res.data.category);
    setDuration(res.data.duration);
    setDurationUnit(res.data.duration_unit);
    setStartDate(res.data.eff_start_date);
    setEndDate(res.data.eff_end_date);
    setPDescription(res.data.comments);
    setOwner(res.data.program_owner);

    setType(res.data.type_id);
    setSubType(res.data.sub_type_id);

    setIsP_Active(res.data.active_flg);
    setIsP_Quotation(res.data.add_to_quote_flg);
    setIsP_Approval(res.data.approval_flg);
    setIsP_AutoAllocate(res.data.auto_allocate_flg);
    setIsP_Bill(res.data.billabe_flg);
    setIsConsulation(res.data.is_consultation);
    setVendorNum(res.data.vendor_part_num);
    setVendorId(res.data.vendor_id);
    setIsP_order(res.data.orderable_flg);
    setIsP_service(res.data.service_flg);
    setIsP_Ship(res.data.ship_flg);
    setTax_Address(res.data.x_ship_to_address_req);
    setTax_Status(res.data.x_tax_status);
    setTax_Description(res.data.x_tax_status_desc);
    setIsTaxable(res.data.x_gst_exempt_flag);

    //img url
    setProductImgUrl(res.data.imageURL);
    setThumbImgUrl(res.data.thumbnailImageURL);
  }

  const handleChangeTaxDescription = (e) => {
    setTax_Description(e.target.value);
  };

  const token = JSON.parse(window.localStorage.getItem("operator")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const toggleTaxable = () => {
    setIsTaxable(!isTaxable);
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
    if (!e.target.value) {
      setIsNameErr(true);
    } else {
      setIsNameErr(false);
    }
  };

  const handleChangeStartDate = (value) => {
    if (value) {
      setStartDate(value.format("YYYY-MM-DD"));

      setIsStartDateErr(false);
    } else {
      setStartDate("");
      setIsStartDateErr(true);
    }
  };

  const handleChangeEndDate = (value) => {
    if (value) {
      setEndDate(value.format("YYYY-MM-DD"));
      setIsEndDateErr(false);
    } else {
      setEndDate("");
      setIsEndDateErr(true);
    }
  };

  useEffect(() => {
    /*
    let d = new Date(startDate);

    if (durationUnit === "month") {
      d.setMonth(d.getMonth() + parseInt(duration));

      setEndDate([d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-'));
    }

    if (durationUnit === "days") {
      d.setDate(d.getDate() + parseInt(duration));

      setEndDate([d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-'));
    }

    if (durationUnit === "year") {
      d.setFullYear(d.getFullYear() + parseInt(duration));

      setEndDate([d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-'));
    }

    if (durationUnit === "week") {
      d.setDate(d.getDate() + duration * 7);

      setEndDate([d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-'));
    }
    */
  }, [duration, startDate, durationUnit]);

  const handleChangeDescription = (e) => {
    setPDescription(e.target.value);
  };

  const toggleActive = () => {
    setIsP_Active(!isP_Active);
  };

  const toggleQuotation = () => {
    setIsP_Quotation(!isP_Quotation);
  };

  const toggleApproval = () => {
    setIsP_Approval(!isP_Approval);
  };

  const toggleBillable = () => {
    setIsP_Bill(!isP_Bill);
  };

  const toggleConsulation = () => {
    setIsConsulation(!isConsulation);
  };
  const handleChangeVendorNum = (e) => {
    setVendorNum(e.target.value);
  };

  const handleChangeVendorId = (e) => {
    setVendorId(e.target.value);
  };

  const handleChangeVendorName = (e) => {
    setVendorName(e.target.value);
  };

  const handleChangeProgramOwner = (e) => {
    setOwner(e.target.value);
    if (e.target.value) {
      setIsProgramOwnderErr(false);
    } else {
      setIsProgramOwnderErr(true);
    }
  };
  const handleChangeProgramType = (e) => {
    setType(e.target.value);
    if (e.target.value) {
      setIsProgramTypeErr(false);
      getProgramSubTypes(e.target.value);
    } else {
      setIsProgramTypeErr(true);
    }
  };
  const handleChangeProgramSubType = (e) => {
    setSubType(e.target.value);
    // if(e.target.value) {
    //   setIsProgramSubTypeErr(false)
    // }else{
    //   setIsProgramSubTypeErr(true)
    // }
  };
  const toggleOrder = () => {
    setIsP_order(!isP_order);
  };

  const toggleService = () => {
    setIsP_service(!isP_service);
  };

  const toggleShip = () => {
    setIsP_Ship(!isP_Ship);
  };

  const handleChangeAddress = (e) => {
    setTax_Address(e.target.value);
  };

  const handleChangeStatus = (e) => {
    setTax_Status(e.target.value);
  };

  const toggleAllocate = () => {
    setIsP_AutoAllocate(!isP_AutoAllocate);
  };
  const handleChangeCategory = (e) => {
    setCategory(e.target.value);
    if (e.target.value !== "") {
      setCategoryErr(false);
    } else {
      setCategoryErr(true);
    }
  };

  const handleChangeDuration = (e) => {
    // const regex = /^[+\b]+$/;
    // if (e.target.value === "" || regex.test(e.target.value)) {

    setDuration(e.target.value);
    // }
    if (e.target.value !== "") {
      setIsDurationErr(false);
    } else {
      setIsDurationErr(true);
    }
  };

  const handleChangeDurationUnit = (e) => {
    setDurationUnit(e.target.value);
    if (e.target.value !== "") {
      setIsDurationUnitErr(false);
    } else {
      setIsDurationUnitErr(true);
    }
  };

  const handleReset = () => {};

  const handleSubmit = () => {
    let formData = new FormData();

    formData.append("prodImage", file);
    formData.append("thumbnail", thumbnailImg);

    let allow = true;

    if (name === "") {
      setIsNameErr(true);
      allow = false;
    }
    if (Category === "") {
      setCategoryErr(true);
      allow = false;
    }
    if (duration === "") {
      setIsDurationErr(true);
      allow = false;
    }
    if (durationUnit === "") {
      setIsDurationUnitErr(true);
      allow = false;
    }

    if (startDate === "") {
      setIsStartDateErr(true);
      allow = false;
    }
    if (endDate === "") {
      setIsEndDateErr(true);
      allow = false;
    }

    if (owner === "") {
      setIsProgramOwnderErr(true);
      allow = false;
    }

    if (type === "") {
      setIsProgramTypeErr(true);
      allow = false;
    }

    // if (subType === "") {
    //   setIsProgramSubTypeErr(true);
    //   allow = false;
    // }

    if (crudType === "create" && file === "") {
      setIsImageErr(true);
      allow = false;
    }

    if (crudType === "create" && thumbnailImg === "") {
      setIsImageThumbErr(true);
      allow = false;
    }

    // validate date ranges
    if (allow) {
      const endDtCal = moment(startDate).add(duration, durationUnit);
      const endDt = moment(endDate);

      if (endDt.unix() < endDtCal.unix()) {
        setIsDateRangeErr(true);
        allow = false;
      } else {
        setIsDateRangeErr(false);
      }
    }

    if (!allow) {
      return;
    }

    let product = {
      active_flg: isP_Active ? isP_Active : false,
      add_to_quote_flg: isP_Quotation ? isP_Quotation : false,
      approval_flg: isP_Approval ? isP_Approval : false,
      auto_allocate_flg: isP_AutoAllocate ? isP_AutoAllocate : false,
      billabe_flg: isP_Bill ? isP_Bill : false,
      is_consultation: isConsulation ? isConsulation : false,
      mobile_app_ad_visible_flg: false,
      category: !!Category ? Category : undefined,
      comments: !!pdescription ? pdescription : undefined,
      eff_start_date: !!startDate ? startDate : undefined,
      eff_end_date: !!endDate ? endDate : undefined,
      name: !!name ? name : undefined,
      duration: !!duration ? duration : undefined,
      duration_unit: !!durationUnit ? durationUnit : undefined,
      orderable_flg: !!isP_order ? isP_order : false,
      program_owner: !!owner ? owner : undefined,
      vendor_id: !!vendorName ? vendorName : undefined,
      // parent_prod_id: "parent_prod_id1234",
      // product_num: "10020",
      // mobile_ui_banner_text: "vmax weightloss loss programs",
      // pr_target_mkt_seg_id: "qwer1234",
      service_flg: !!isP_service ? isP_service : false,
      ship_flg: !!isP_Ship ? isP_Ship : false,
      // sub_type: subType,
      // type: type,
      type_id: type,
      sub_type_id: subType ? subType : undefined,

      // version: "1",
      x_gst_exempt_flag: !!isTaxable ? isTaxable : false,
      x_ship_to_address_req: !!tax_address ? tax_address : undefined,
      vendor_part_num: !!vendorNum ? vendorNum : undefined,
      x_tax_status: !!tax_status ? tax_status : undefined,
      x_tax_status_desc: !!tax_description ? tax_description : undefined,
      // vendor_prod_id: "prodid",
      deal_size: 2,
    };

    for (const i in product) {
      if (product[i] !== undefined) {
        formData.append(i, product[i]);
      }
    }

    let URL =
      crudType === "create"
        ? `${ATLAS_URI}/postProduct/`
        : `${ATLAS_URI}/updateProduct/${programId}`;

    if (crudType === "create") {
      axios
        .post(URL, formData, configToken)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Program Created Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });

            history.push("/programs");
          } else if (res.status === 400) {
            toast.error("Bad Request", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => console.error(err));
    } else {
      axios
        .put(URL, formData, configToken)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Program Updated Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });

            history.push("/programs");
          } else if (res.status === 400) {
            toast.error("Bad Request", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setIsImageErr(false);
    // formData.append("file", e.target.files[0]);
  };

  const handleThumbnailImageChange = (e) => {
    setThumbnailImg(e.target.files[0]);
    setIsImageThumbErr(false);
    // formData.append("file", e.target.files[0]);
  };

  const getUserList = async () => {
    const res = await axiosFetch({
      url: "/getUsers",
      method: "get",
    });

    if (res.status === 200) {
      setUserList(res.data);
    } else if (res.status === 400) {
      toast.error("Bad Request", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (res.status === 401) {
      history.push("/");
    }
  };

  useEffect(async () => {
    await getUserList();
    await getProgramTypes();
    if (programId) {
      await getProgramDetails(programId);
    }
  }, []);

  function getStartDate() {
    if (crudType === "create") {
      return "";
    } else {
      return dayjs(startDate);
    }
  }

  function getEndDate() {
    if (crudType === "create") {
      return "";
    } else {
      return dayjs(endDate);
    }
  }

  const goBackToPreviousPage = () => {
    history.push("/programs");
  };

  return (
    <div className="container">
      <Button
        onClick={goBackToPreviousPage}
        variant="outlined"
        sx={{ margin: "8px 12px" }}
      >
        <ArrowBackOutlinedIcon /> Back
      </Button>

      <Card sx={{ minWidth: 275, margin: "18px" }}>
        <CardContent>
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
            className="header-txt"
          >
            {programId === 0 ? "Add New" : "Update"} Program
          </Typography>
          <hr />

          <div className="prog-dts">
            <Typography className="sub-header">Program Details</Typography>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  fullWidth
                  id="outlined-basic"
                  label="Program Name*"
                  variant="outlined"
                  value={name}
                  onChange={(e) => handleChangeName(e)}
                />

                <FormHelperText sx={{ color: "red" }}>
                  {isNameErr ? "Please Enter Program Name" : ""}
                </FormHelperText>
              </Grid>
              <br /> <br />
              <Grid container spacing={2} sx={{ margin: "0px" }}>
                <Grid item xs={3}>
                  <FormControl style={{ minWidth: 220 }}>
                    <InputLabel id="demo-simple-select-label">
                      Category*
                    </InputLabel>
                    <Select
                      autoWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Category}
                      onChange={(e) => handleChangeCategory(e)}
                      label="Category*"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {CategoryList.map((category) => (
                        <MenuItem value={category}>{category}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ color: "red" }}>
                    {categoryErr ? "Please Select Something" : ""}
                  </FormHelperText>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    type="number"
                    placeholder="Duration"
                    value={duration}
                    label="Duration*"
                    onChange={(e) => handleChangeDuration(e)}
                    InputProps={{
                      inputProps: { min: 1 },
                    }}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", ".", "0"].includes(e.key) &&
                      e.preventDefault()
                    }
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {isDurationErr ? "Please Enter Duration" : ""}
                  </FormHelperText>
                </Grid>

                <Grid item xs={3}>
                  <FormControl style={{ minWidth: 220 }}>
                    <InputLabel id="demo-simple-select-label">
                      Duration Unit*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={durationUnit}
                      label="Duration Unit"
                      onChange={(e) => handleChangeDurationUnit(e)}
                    >
                      <MenuItem value="days">Days</MenuItem>
                      <MenuItem value="week">Week</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                      <MenuItem value="year">Year</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ color: "red" }}>
                    {isDurationUnitErr ? "Please Select Something" : ""}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ margin: "0px" }}>
                {/* <Grid item xs={3}>
                  <FormControl style={{ minWidth: 220 }}>
                    <InputLabel id="demo-simple-select-label">
                      Sub Program
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={age}
                      label="Sub Program"
                    // onChange={handleChange}
                    >
                      <MenuItem value="services">Services</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={3}>
                  Start Date*
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl style={{ minWidth: 220 }}>
                      <DatePicker
                        ref={startDateRef}
                        value={getStartDate()}
                        onChange={handleChangeStartDate}
                      />
                    </FormControl>
                  </LocalizationProvider>
                  <FormHelperText sx={{ color: "red" }}>
                    {isStartDateErr ? "Please Select Start Date" : ""}
                  </FormHelperText>
                </Grid>

                <Grid item xs={3}>
                  End Date*
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl style={{ minWidth: 220 }}>
                      <DatePicker
                        ref={endDateRef}
                        value={getEndDate()}
                        onChange={handleChangeEndDate}
                      />
                    </FormControl>
                  </LocalizationProvider>
                  <FormHelperText sx={{ color: "red" }}>
                    {isEndDateErr ? "Please Select End Date" : ""}
                  </FormHelperText>
                  <FormHelperText sx={{ color: "red" }}>
                    {isDateRangeErr
                      ? "The End Date does not satisfy the duration criteria"
                      : ""}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ margin: "0px" }}>
                <Grid item xs={3}>
                  <TextareaAutosize
                    fullWidth
                    aria-label="minimum height"
                    minRows={2}
                    placeholder="Program Description"
                    style={{ width: 200 }}
                    value={pdescription}
                    onChange={handleChangeDescription}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl style={{ minWidth: 220 }}>
                    <InputLabel id="demo-simple-select-label">
                      Program Owner*
                    </InputLabel>
                    <Select
                      autoWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={owner}
                      onChange={(e) => handleChangeProgramOwner(e)}
                      label="Program Owner"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {userList.map((user) => (
                        <MenuItem value={user.id}>{user.Username}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ color: "red" }}>
                    {isProgramOwnderErr ? "Please Select Program Owner" : ""}
                  </FormHelperText>
                </Grid>

                <Grid item xs={3}>
                  <FormControl style={{ minWidth: 220 }}>
                    <InputLabel>Program Type*</InputLabel>
                    <Select
                      autoWidth
                      value={type}
                      onChange={(e) => handleChangeProgramType(e)}
                      label="Program Type*"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {typeList.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ color: "red" }}>
                    {isProgramTypeErr ? "Please Select Program Type" : ""}
                  </FormHelperText>
                </Grid>

                <Grid item xs={3}>
                  <FormControl style={{ minWidth: 300 }}>
                    <InputLabel>Program Sub Type*</InputLabel>
                    <Select
                      autoWidth
                      value={subType}
                      onChange={(e) => handleChangeProgramSubType(e)}
                      label="Program Sub Type*"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {subTypeList.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ color: "red" }}>
                    {isProgramSubTypeErr
                      ? "Please Select Program Sub Type"
                      : ""}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Stack direction="row" spacing={2} sx={{ margin: "43px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={isP_Active} onChange={toggleActive} />
                    }
                    label="Active Program"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isP_Quotation}
                        onChange={toggleQuotation}
                      />
                    }
                    label="Program Quotation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isP_Approval}
                        onChange={toggleApproval}
                      />
                    }
                    label="Program Approval"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isP_AutoAllocate}
                        onChange={toggleAllocate}
                      />
                    }
                    label="Program Auto Allocation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={isP_Bill} onChange={toggleBillable} />
                    }
                    label="Program Billable"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isConsulation}
                        onChange={toggleConsulation}
                      />
                    }
                    label="Consulation Program"
                  />
                </Stack>
              </Grid>
              <Grid container spacing={2} sx={{ margin: "17px" }}>
                <Grid item xs={4}>
                  <label>Upload Product Image</label>
                  <input
                    type="file"
                    name="ProfilePic"
                    onChange={handleChange}
                    accept="image/png, image/jpeg"
                  />
                  <Image src={productImgUrl} thumbnail width={150} />
                  {/* <img src={productImgUrl} width='150'/> */}
                  <FormHelperText sx={{ color: "red" }}>
                    {isImageErr ? "Please Provide Product Image" : ""}
                  </FormHelperText>
                </Grid>

                <Grid item xs={4}>
                  <label>Upload Product Thumbnail Image</label>
                  <input
                    type="file"
                    name="ProfilePic"
                    onChange={handleThumbnailImageChange}
                    accept="image/png, image/jpeg"
                  />
                  <Image src={thumbImgUrl} thumbnail width={150} />
                  {/* <img src={thumbImgUrl} width='150'/> */}
                  <FormHelperText sx={{ color: "red" }}>
                    {isImageThumbErr
                      ? "Please Provide Product Thumbnail Image"
                      : ""}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div>
            <Typography className="sub-header">Vendor Details</Typography>
            <hr />
            <br />
            <Grid container xs={12}>
              <Grid item xs={3}>
                <TextField
                  placeholder="Vendor Number"
                  value={vendorNum}
                  onChange={(e) => handleChangeVendorNum(e)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  placeholder="Vendor ID"
                  value={vendorId}
                  onChange={(e) => handleChangeVendorId(e)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ minWidth: 220 }}>
                  <InputLabel id="demo-simple-select-label">
                    Vendor Name
                  </InputLabel>
                  <Select
                    autoWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vendorName}
                    onChange={(e) => handleChangeVendorName(e)}
                    label="Vendor Name"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {userList.map((user) => (
                      <MenuItem value={user.id}>{user.Username}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <br />
          </div>
          <div>
            <Typography className="sub-header">Shipment Details</Typography>
            <hr />
            <br />
            <Grid container spacing={2} sx={{ margin: "0px" }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isP_order}
                      onChange={() => toggleOrder()}
                    />
                  }
                  label="Program Items Ordered "
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isP_service}
                      onChange={() => toggleService()}
                    />
                  }
                  label="Program Service"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isP_Ship}
                      onChange={() => toggleShip()}
                    />
                  }
                  label="Shipping"
                />
              </Stack>
            </Grid>
          </div>

          <div>
            <Typography className="sub-header">Tax Details</Typography>
            <hr />
            <br />
            <Grid container xs={12} sx={{ margin: "0px" }}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Tax Address"
                  variant="outlined"
                  value={tax_address}
                  onChange={(e) => handleChangeAddress(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ minWidth: 220 }}>
                  <InputLabel id="demo-simple-select-label">
                    Tax Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tax_status}
                    label="Tax Status"
                    onChange={(e) => handleChangeStatus(e)}
                  >
                    <MenuItem value="services">Services</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Tax Status Description"
                  variant="outlined"
                  value={tax_description}
                  onChange={(e) => handleChangeTaxDescription(e)}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ margin: "0px" }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox checked={isTaxable} onChange={toggleTaxable} />
                  }
                  label="Tax Exemption"
                />
              </Stack>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" onClick={() => handleSubmit()}>
            {programId === 0 ? "Create" : "Update"} Program
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default AddProgram;
