import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { returnTime, getTodayTime } from "../../Helpers/DateHelpers";
// import Scheduler from '../../components/Scheduler'
import stateContext from "../../context/StateContext";
import { ADMIN, ATLAS_URI } from "../../Constants";
import BoxHeader from "../../components/BoxHeader";
import DataTable from "../../components/DataTable";
import ClipLoader from "react-spinners/ClipLoader";
import TodayTask from "./TodayTask";
import Collapsible from "react-collapsible";

import { ExpandMore } from "@material-ui/icons";
import SelectPaymentMode from "../../components/FeedBackModals/SelectPaymentMode";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
//
// import db from '../../../../_helpers/db';
import { IoIosArrowDown } from "react-icons/io";
import { Button, Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";

import { AiOutlineDoubleRight } from "react-icons/ai";

function Dashboard() {
  const contextState = useContext(stateContext);
  const { configToken, operator, leadsList } = contextState.state;

  const [loading, setLoading] = useState(true);

  const [showLeadAccountWise, setShowLeadAccountWise] = useState(false);
  const [leadAccountWise, setLeadAccountWise] = useState([]);

  const [showClientAccountWise, setShowClientAccountWise] = useState(false);
  const [clientAccountWise, setClientAccountWise] = useState([]);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const [state, setState] = useState({
    series: [1, 0, 0, 0, 0, 0, 0, 0],
    //   series:[{
    //     name:size,
    //     data:[1, 0, 0, 0, 0, 0,0,0]
    //   }],
    options: {
      chart: {
        type: "pie",
      },
      legend: {
        position: "right",
        fontSize: "14pt",
      },
      // labels: ["Attempted Leads", "Un-Attempted Leads", "Total Agreements", "Today's Follow-ups", "Upcoming Follow-ups", "Old Pending Follow-ups"],
      labels: [
        "No Of Leads Account wise-Source",
        "No of Leads Account wise",
        "Account wise No of Tenure end clients",
        "No of New Clients Account wise",
        "No of New Clients Program wise",
        "In Active Client",
        "No of Clients Program Wise",
        "No of payment(Account wise program)",
      ],
    },

    allLeadsData: [],
    allAgreementsData: [""],
    tableBodyList: [],
  });
  const [state2, setstate2] = useState({
    options: {
      chart: {
        type: "pie",
      },
      legend: {
        position: "right",
        fontSize: "14pt",
      },
    },
  });
  const [data, setData] = useState([]);
  const mounted = React.useRef(true);
  useEffect(() => {
    mounted.current = true;
  }, []);

  useEffect(() => {
    let leadsData = leadsList;
    const userid = JSON.parse(window.localStorage.getItem("operator")).Role;
    const userID = operator._id;

    if (operator.Role !== ADMIN)
      leadsData = leadsData.filter((lead) => lead.AssignedTo === userID);

    if (configToken?.headers?.Authorization) {
      axios
        .get(`${ATLAS_URI}/getDashboardData/` + userid, configToken)
        .then((res) => {
          //Get All Agreements
          // const response=res.json()

          mounted.current &&
            setState((prevState) => ({
              ...prevState,
              allLeadsData: res.data,
              // allAgreementsData: agreementData,
              // series:state.series.map(item=>{return item.data.push(size)}),
              // series: newSeries,
              tableBodyList: res.data,
              userRole: operator.Role,
            }));
          setLoading(false);
          // setData(Object.values(state.allLeadsData.leadSource))
          // axios.get(`${ATLAS_URI}/getAgreements/`, configToken)
          //     .then(res2 => {
          //         const agreementData = typeof res2.data !== 'undefined' ? res2.data : [];
          //         const newSeries = state.series.map((value, index) => {
          // switch (index) {
          //     case 0:
          //         return leadsData.filter(lead => lead.LeadStatus === "Attempted").length;
          //     case 1:
          //         return leadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length;
          //     case 2:
          //         return agreementData.length;
          //     case 3:
          //         return leadsData.filter(lead => lead.NextFollowUpDate && returnTime(lead.NextFollowUpDate) === getTodayTime()).length;
          //     case 4:
          //         return leadsData.filter(lead => lead.NextFollowUpDate && returnTime(lead.NextFollowUpDate) > getTodayTime()).length;
          //     case 5:
          //         return leadsData.filter(lead => lead.NextFollowUpDate && returnTime(lead.NextFollowUpDate) < getTodayTime()).length;
          //     default:
          //         return value;
          // }
        })
        .catch((err) => {
          console.error("err::: ", err.message);
          console.error(err);
        });
    }

    // mounted.current && setState(prevState => ({
    //     ...prevState,
    //     allLeadsData: res.data,
    //     // allAgreementsData: agreementData,
    //     // series: newSeries,
    //     // tableBodyList: res.data,
    //     userRole: operator.Role
    // }));
    // }).catch(err => console.log(err))
    // })
  }, []);

  Object.size = function (obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  // Get the size of an object
  const myObj = state.allLeadsData.leadSource;
  var size = Object.size(myObj);

  function approveUser(e) {
    e.preventDefault();
    const edit = e.target.parentElement.parentElement.id;
    axios
      .post(
        `${ATLAS_URI}/updateUser/` + edit,
        { State: "Approved" },
        configToken
      )
      .then((x) => {
        const newTableBodyList = state.tableBodyList.filter(
          (data) => data?.id?.toString() !== edit
        );
        setState((prevState) => ({
          ...prevState,
          tableBodyList: newTableBodyList,
        }));
      })
      .catch((err) => console.error(err));
  }

  function deleteFromTable(e) {
    const delID = e.target.parentElement.parentElement.id;
    axios
      .delete(`${ATLAS_URI}/deleteUser/` + delID, configToken)
      .then(() => {
        const newTableBodyList = state.tableBodyList.filter(
          (data) => data.id?.toString() !== delID?.toString()
        );
        setState((prevState) => ({
          ...prevState,
          tableBodyList: newTableBodyList,
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const [show, setshow] = useState(false);

  const [expandedPanel, setExpandedPanel] = useState("panel1");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  function getAllLeadSourceAccountWise() {
    axios
      .get(`${ATLAS_URI}/getLeadsGroupByAllKnowUs/`, configToken)
      .then((res) => {
        if (res.status == 200) {
          setLeadAccountWise(res.data);
          setShowLeadAccountWise(true);
        }
      });
  }

  function getAllClientSourceAccountWise() {
    axios
      .get(`${ATLAS_URI}/getClientsGroupByAllKnowUs/`, configToken)
      .then((res) => {
        if (res.status == 200) {
          setClientAccountWise(res.data);
          setShowClientAccountWise(true);
        }
      });
  }

  return (
    <section className="content">
      {state.userRole === ADMIN && state.tableBodyList.length > 0 && (
        <div className="box box-primary w-90">
          <BoxHeader title="Pending Users" />
          <div className="box-body">
            <DataTable
              tableHeader={["id", "Name", "Username", "Password", "Roles"]}
              searchField="Name"
              tableBody={state.tableBodyList}
              customAction={[
                {
                  title: "Approve",
                  icon: "fas fa-check text-green",
                  onClickEvent: approveUser,
                },
                {
                  title: "Delete",
                  icon: "fas fa-times text-red",
                  onClickEvent: deleteFromTable,
                },
              ]}
            />
          </div>
        </div>
      )}
      {/* Today Task */}

      <div className="new-task-container d-flex">
        <div className="leads-client-container">
          {loading ? (
            <div className="loader" />
          ) : (
            (state.allLeadsData.newLeads ||
              state.allLeadsData.unallocatedLeads ||
              state.allLeadsData.allocatedLeads ||
              state.allLeadsData.noOfLeads ||
              (state?.allLeadsData?.leadSource &&
                state?.allLeadsData?.leadSource?.length > 0)) && (
              <Accordion
                expanded={expandedPanel === "panel1"}
                onChange={handleAccordionChange("panel1")}
              >
                {/* Leads */}
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <div className=" col-md-6 col-lg-4  d-flex justify-content-center  w-100">
                    <div className="row">
                      <div className="col-md-9">
                        <h3>Leads</h3>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "block", padding: "8px 16px 16px" }}
                >
                  <div className="row m-2">
                    {state.allLeadsData.newLeads != 0 && (
                      <div className=" col-md-5 col-lg-6 ">
                        <div className="box-left box-left-blue">
                          <div className="box-header">
                            <div className="row">
                              <div className="col-md-3 iconArea">
                                <i className="far fa-tasks"></i>
                              </div>
                              <div className="col-md-7">
                                {/* <h3 className="box-title">Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Attempted").length} </h3> */}
                                <h3 className="box-title">New Leads</h3>
                                <h3>{state.allLeadsData.newLeads} </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {state.allLeadsData.unallocatedLeads != 0 && (
                      <div className=" col-md-5 col-lg-6">
                        <div className="box-left box-left-red">
                          <div className="box-header">
                            <div className="row">
                              <div className="col-md-3 iconArea">
                                <i className="fas fa-thumbs-up"></i>
                              </div>
                              <div className="col-md-7">
                                {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                                <h3 className="box-title">Unallocated Leads</h3>
                                <h3 className="box-info">
                                  {state.allLeadsData.unallocatedLeads}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Unwanted datas are hiding now */}
                    {/* <div className="col-md-4 my10">
                    <div className="box box-primary pieChartArea">

                        <div className="box-header">
                            <h3>No. of Leads Account wise</h3>
                        </div>
                        <div className='pieChartBox'>
                            <ReactApexChart options={state.options} series={state.series} type="pie" width={500} />
                        </div>
                    </div>
                </div> */}

                    {state.allLeadsData?.leadSource?.length != 0 && (
                      <div className="col-md-6 my10">
                        <div className="box box-primary pieChartArea">
                          <div className="box-header">
                            <h3>No. of Leads Account wise-Know Us </h3>{" "}
                            <button
                              style={{ border: "none" }}
                              onClick={getAllLeadSourceAccountWise}
                            >
                              <AiOutlineDoubleRight
                                fontSize="25px"
                                title="Show more"
                              />
                            </button>
                          </div>
                          {/* <div className="pieChartBox">
                            <ReactApexChart
                              options={{
                                chart: {
                                  type: "pie",
                                },
                                legend: {
                                  position: "right",
                                  fontSize: "14pt",
                                },
                                // labels: ["Attempted Leads", "Un-Attempted Leads", "Total Agreements", "Today's Follow-ups", "Upcoming Follow-ups", "Old Pending Follow-ups"],
                                labels: state.allLeadsData?.leadSource?.map(
                                  (data) => data.know_us
                                ),
                              }}
                              series={state.allLeadsData?.leadSource?.map(
                                (data) => data.count
                              )}
                              type="pie"
                              width={500}
                            />
                          </div> */}
                        </div>
                      </div>
                    )}

                    <div className="col-md-6 my10">
                      {state.allLeadsData.allocatedLeads != 0 && (
                        <div className="col-lg-12">
                          <div className="box-left box-left-green">
                            <div className="box-header">
                              <div className="row">
                                <div className="col-md-3 iconArea">
                                  <i className="far fa-check"></i>
                                </div>
                                <div className="col-md-9">
                                  {/* <h3 className="box-title">Total Agreements</h3>
                                    <h3 className="box-info">{state.allAgreementsData.length}</h3> */}
                                  <h3 className="box-title">Allocated Leads</h3>
                                  <h3 className="box-info">
                                    {state.allLeadsData.allocatedLeads}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {state.allLeadsData.followUpLeads && (
                        <div className=" col-md-12 col-lg-4">
                          <div className="box-left box-left-red">
                            <div className="box-header">
                              <div className="row">
                                {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                                <div className="col-md-9">
                                  {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                                  <h3 className="box-title">Follow up-Leads</h3>
                                  {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {state.allLeadsData.noOfLeads != 0 && (
                        <div className=" col-md-6 col-lg-12 mt-4">
                          <div className="box-left box-left-purple">
                            <div className="box-header">
                              <div className="row">
                                <div className="col-md-2 col-lg-2 iconArea">
                                  <i className="fas fa-user"></i>
                                </div>
                                <div className="col-md-9">
                                  <h3 className="box-title">Total Leads</h3>
                                  <h3 className="box-info">
                                    {state.allLeadsData.noOfLeads}{" "}
                                    <span>(Total Leads in CRM)</span>
                                  </h3>
                                  {/* <div className='button-corner'><a href={state.userRole === ADMIN ? 'Leads/allLeads' : "assignedLeadsCalls"} className="btn btn-default">(VIEW ALL LEADS)</a></div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          )}
          <br />
          {/* SALES PART  */}
          {false && (
            <Accordion
              expanded={expandedPanel === "panel2"}
              onChange={handleAccordionChange("panel2")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                {/* Sales */}
                <div className=" col-md-6 col-lg-4  d-flex justify-content-center  w-100">
                  <div className="row">
                    <div className="col-md-9">
                      <h3>Sales</h3>
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "block", padding: "8px 16px 16px" }}
              >
                <div className="row col-md-12">
                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Sales Team 1</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="  col-md-6 col-lg-4 ">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Sales Team2</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          <div className="col-md-9">
                            <h3 className="box-title">Sales Team3</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Sales Team4</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Team Conversion</h3>
                            {/* <h3 className="box-info">{state.allLeadsData}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">My Conversion</h3>
                            {/* <h3 className="box-info">{state.allLeadsData}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}{" "}
          <br />
          {/* Clients Part... */}
          {(state.allLeadsData.activeClient ||
            state.allLeadsData.dietitianUnallocated ||
            state.allLeadsData.inActiveClient ||
            state.allLeadsData.newClients ||
            state.allLeadsData.totalClients ||
            state.allLeadsData.trainerUnallocated ||
            (state?.allLeadsData?.clientsAccountWise &&
              state?.allLeadsData?.clientsAccountWise?.length > 0)) && (
            <Accordion
              expanded={expandedPanel === "panel3"}
              onChange={handleAccordionChange("panel3")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                {/* Clients */}
                <div className=" col-md-6 col-lg-4  d-flex justify-content-center  w-100">
                  <div className="row">
                    <div className="col-md-9">
                      <h3>Clients</h3>
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "block", padding: "8px 16px 16px" }}
              >
                <div className="row m-2">
                  {state.allLeadsData.totalClients != 0 && (
                    <div className=" col-md-6 col-lg-6">
                      <div className="box-left box-left-red">
                        <div className="box-header">
                          <div className="row">
                            {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                            <div className="col-md-9">
                              {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                              <h3 className="box-title">Total clients</h3>
                              <h3 className="box-info">
                                {state.allLeadsData.totalClients}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {state.allLeadsData.dietitianUnallocated != 0 &&
                    state.allLeadsData.trainerUnallocated != 0 && (
                      <div className=" col-md-6 col-lg-6">
                        <div className="box-left box-left-red">
                          <div className="box-header">
                            <div className="row">
                              {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                              <div className="col-md-9">
                                {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}

                                <h3 className="box-title">
                                  UnAllocated-Clients
                                </h3>
                                <h3 className="box-info">
                                  {state.allLeadsData.dietitianUnallocated &&
                                  state.allLeadsData.trainerUnallocated
                                    ? state.allLeadsData.dietitianUnallocated +
                                      state.allLeadsData.trainerUnallocated
                                    : undefined}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {state.allLeadsData?.clientsAccountWise?.length != 0 && (
                    <div className="col-md-6 my10">
                      <div className="box box-primary pieChartArea">
                        <div className="box-header">
                          <h3>Clients Account wise</h3>

                          <button
                            style={{ border: "none" }}
                            onClick={getAllClientSourceAccountWise}
                          >
                            <AiOutlineDoubleRight
                              fontSize="25px"
                              title="Show more"
                            />
                          </button>
                        </div>
                        <div className="pieChartBox">
                          {/* <ReactApexChart
                            options={{
                              chart: {
                                type: "pie",
                              },
                              legend: {
                                position: "right",
                                fontSize: "14pt",
                              },
                              // labels: ["Attempted Leads", "Un-Attempted Leads", "Total Agreements", "Today's Follow-ups", "Upcoming Follow-ups", "Old Pending Follow-ups"],
                              labels:
                                state.allLeadsData?.clientsAccountWise?.map(
                                  (d) => d?.LeadID?.know_us
                                ),
                            }}
                            series={state.allLeadsData?.clientsAccountWise?.map(
                              (d) => d.count
                            )}
                            type="pie"
                            width={"500"}
                          /> */}
                        </div>
                      </div>
                    </div>
                  )}

                  {state.allLeadsData.inActiveClient != 0 && (
                    <div className=" col-md-6 col-lg-6">
                      <div className="box-left box-left-red">
                        <div className="box-header">
                          <div className="row">
                            <div className="col-md-3 iconArea">
                              <i className="fas fa-thumbs-up"></i>
                            </div>
                            <div className="col-md-9">
                              {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                              <h3 className="box-title">Inactive Clients</h3>
                              <h3 className="box-info">
                                {state.allLeadsData.inActiveClient}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {state.allLeadsData.activeClient != 0 && (
                    <div className=" col-md-6 col-lg-6">
                      <div className="box-left box-left-red">
                        <div className="box-header">
                          <div className="row">
                            {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                            <div className="col-md-9">
                              {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                              <h3 className="box-title">Active Clients</h3>
                              <h3 className="box-info">
                                {state.allLeadsData.activeClient}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {state.allLeadsData.newClients != 0 && (
                    <div className=" col-md-6 col-lg-6">
                      <div className="box-left box-left-green">
                        <div className="box-header">
                          <div className="row">
                            <div className="col-md-2 col-lg-2 iconArea">
                              <i className="far fa-phone-alt"></i>
                            </div>
                            <div className="col-md-9">
                              <h3 className="box-title">New Clients</h3>
                              <h3 className="box-info">
                                {state.allLeadsData.newClients}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {state.allLeadsData.totalClients && <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                        <div className="box-header">

                            <div className="row">
                            <div className="col-md-2 col-lg-2 iconArea">
                                            <i className="far fa-phone-alt"></i>
                                        </div>
                                <div className="col-md-9">
                                <h3 className="box-title">Number of Clients</h3>
                                <h3>{ state.allLeadsData.totalClients.length}</h3>
                                   
                                </div>

                            </div>
                        </div>
                    </div>
                </div>} */}

                  {/* <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                        <div className="box-header">

                            <div className="row">
                            <div className="col-md-2 col-lg-2 iconArea">
                                    <i className="fas fa-bookmark"></i>
                                </div>
                                <div className="col-md-9">
                                <h3 className="box-title">Number of Tenure End Clients</h3>
                                
                                   
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}

                  {/* <div className="col-md-4 my10">
                    <div className="box box-primary pieChartArea">

                        <div className="box-header">
                            <h3>Account wise No. of Tenure End Clients</h3>
                        </div>
                        <div className='pieChartBox'>
                            <ReactApexChart options={state.options} series={state.series} type="pie" width={500} />
                        </div>
                    </div>
                </div> */}

                  {/* <div className="col-md-4 my10">
                    <div className="box box-primary pieChartArea">

                        <div className="box-header">
                            <h3>No. New Clients Account wise</h3>
                        </div>
                        <div className='pieChartBox'>
                            <ReactApexChart options={state.options} series={state.series} type="pie" width={500} />
                        </div>
                    </div>
                </div> */}

                  {/* <div className="col-md-4 my10">
                    <div className="box box-primary pieChartArea">

                        <div className="box-header">
                            <h3>No. New Clients Program wise </h3>
                        </div>
                        <div className='pieChartBox'>
                            <ReactApexChart options={state.options} series={state.series} type="pie" width={500} />
                        </div>
                    </div>
                </div> */}

                  {/* <div className="col-md-4 my10">
                    <div className="box box-primary pieChartArea">

                        <div className="box-header">
                            <h3>No. of Clients program wise</h3>
                        </div>
                        <div className='pieChartBox'>
                            <ReactApexChart options={state.options} series={state.series} type="pie" width={500} />
                        </div>
                    </div>
                </div> */}

                  {/* <div className="col-md-4 my10">
                    <div className="box box-primary pieChartArea">

                        <div className="box-header">
                            <h3>In Active Clients</h3>
                        </div>
                        <div className='pieChartBox'>
                            <ReactApexChart options={state.options} series={state.series} type="pie" width={500} />
                        </div>
                    </div>
                </div> */}
                </div>
              </AccordionDetails>
            </Accordion>
          )}{" "}
          <br />
          {/*  Calender */}
          {false && (
            <Accordion
              expanded={expandedPanel === "panel4"}
              onChange={handleAccordionChange("panel4")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                {/* Calendar */}
                <div className=" col-md-6 col-lg-4 ">
                  <div className="row">
                    <div className="col-md-9">
                      <h3 style={{ transform: "translateX(700px)" }}>
                        Calendar
                      </h3>
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "block", padding: "8px 16px 16px" }}
              >
                <div className="row m-2">
                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">
                              Pending Induction Call
                            </h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">
                              Pending Induction Call Clients
                            </h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Messages To Reply</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Pending Diet Chart</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">
                              Pending Diet Chart Approval
                            </h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Pending Workout Chart</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">
                              Pending Workout Chart Approval
                            </h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">
                              Pending Diet workout Approval
                            </h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">My Monthly target</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">My Today target</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Team Monthly target</h3>
                            {/* <h3 className="box-info">{state.allLeadsData}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Team Today target</h3>
                            {/* <h3 className="box-info">{state.allLeadsData}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-6 col-lg-4">
                    <div className="box-left box-left-red">
                      <div className="box-header">
                        <div className="row">
                          {/* <div className="col-md-3 iconArea">
                                    <i className="fas fa-thumbs-up"></i>
                                </div> */}
                          <div className="col-md-9">
                            {/* <h3 className="box-title">Un-Attempted Leads</h3>
                                    <h3 className="box-info">{state.allLeadsData.filter(lead => lead.LeadStatus === "Un-Attempted").length}</h3> */}
                            <h3 className="box-title">Freeze</h3>
                            {/* <h3 className="box-info">{state.allLeadsData.length}</h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          <br />
          {/*  Payments */}
          {false && (
            <Accordion
              expanded={expandedPanel === "panel5"}
              onChange={handleAccordionChange("panel5")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                {/* Payments */}
                <div className=" col-md-6 col-lg-4 ">
                  <div className="row">
                    <div className="col-md-9">
                      <h3 style={{ transform: "translateX(700px)" }}>
                        Payments
                      </h3>
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "block", padding: "8px 16px 16px" }}
              >
                <div className="row m-2">
                  <div className="col-md-12 my10">
                    <div className="box box-primary pieChartArea">
                      <div className="box-header">
                        <h3>No. of Payments Account wise</h3>
                      </div>
                      {/* <div className="pieChartBox">
                        <ReactApexChart
                          options={state.options}
                          series={state.series}
                          type="pie"
                          width={500}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}{" "}
          ``
        </div>

        <div className="w-50 me-5 ms-5 d-flex flex-column align-items-center">
          <TodayTask />
        </div>
      </div>
      {/* <div className="row m-2">

                <div className="col-lg-6 my10">
                    <div className="box box-primary pieChartArea">

                        <div className="box-header">
                            <h3>Summary</h3>
                        </div>
                        <div className='pieChartBox'>
                            <ReactApexChart options={state.options} series={state.series} type="pie" width={560} />
                        </div>
                    </div>
                </div>

                
                                            </div> */}
      {/* <Scheduler /> */}
      {(showLeadAccountWise || showClientAccountWise) && (
        <Modal
          show={showLeadAccountWise || showClientAccountWise}
          onHide={() => {
            setShowLeadAccountWise(false);
            setShowClientAccountWise(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showLeadAccountWise
                ? "Lead Account wise"
                : "Client Account wise"}
            </Modal.Title>
            <GrClose
              style={{
                backgroundColor: isHovering ? "#FF0000" : "",
                color: isHovering ? "#fff" : "",
                border: isHovering ? "1px solid" : "",
                height: isHovering ? "30px" : "",
                width: isHovering ? "30px" : "",
                opacity: isHovering ? "0.7" : "",
              }}
              onMouseOver={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => {
                setShowLeadAccountWise(false);
                setShowClientAccountWise(false);
              }}
            />
          </Modal.Header>

          <Modal.Body>
            <table id="tab_customers" className="table table-striped">
              <colgroup>
                <col span="1" />
                <col span="1" />
              </colgroup>
              <thead>
                <tr class="warning">
                  <th>Count</th>
                  <th>Account Name</th>
                </tr>
              </thead>
              <tbody>
                {showLeadAccountWise &&
                  leadAccountWise?.map((d) => (
                    <tr>
                      <td>{d.count}</td>
                      <td>{d.know_us}</td>
                    </tr>
                  ))}
                {showClientAccountWise &&
                  clientAccountWise?.map((d) => (
                    <tr>
                      <td>{d.count}</td>
                      <td>{d.LeadID.know_us}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowLeadAccountWise(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* <SelectPaymentMode /> */}
    </section>
  );
}

export default Dashboard;
