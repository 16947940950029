import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import AccordionList from "../../../components/Accordion/AccordionList";
import SimpleTable from "../../../components/MuiTable/SimpleTable";
import { useEffect, useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { utcFormater } from "../../../hooks/helperFunction/dateConverter";
import { useParams } from "react-router-dom";
const taskStyle = {
  taskContainer: {
    width: "150px",
    height: "130px",
    // backgroundColor: "#F4F4F4",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
  },
  taskCompleted: {
    gap: "10px",
  },

  taskTitle: {
    fontSize: "18px",
    color: "#2D2D2D",
    fontWeight: 600,
  },
  circularInfo: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
};

const todayTableHeader = [
  "Date",
  "TaskName",
  "Allocated To",
  "Description",
  "Status",
];
const UpcomingTableHeader = [
  "Date",
  "TaskName",
  "Allocated To",
  "Description",
  "Status",
];
const CompletedTableHeader = [
  "Date",
  "TaskName",
  "Allocated To",
  "Description",
  "Completed on",
  "Completed by",
  "Status",
];

const ProgramTask = () => {
  const [task, setTask] = useState({});
  const { id } = useParams();

  const getTaskDetails = async () => {
    try {
      const response = await axiosFetch({
        method: "get",
        url: `/getProgramTaskByLeadId/${id}`,
      });

      if (response.status === 200) {
        const data = response.data.body;
        setTask(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTaskDetails();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: "100px",
          justifyContent: "center",
          marginBottom: "90px",
        }}
      >
        <Box sx={{ ...taskStyle.taskContainer, ...taskStyle.taskCompleted }}>
          <Typography sx={taskStyle.taskTitle}>Completed %</Typography>

          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <Stack sx={{ color: "#24D37A" }} direction="row">
              <CircularProgress
                variant="determinate"
                color="inherit"
                value={task?.completedPercentage}
                size={"50px"}
              />
            </Stack>
            <Box sx={taskStyle.circularInfo}>
              <Typography
                variant="caption"
                component="div"
                color="#24D37A"
                sx={{ fontSize: "18px", fontWeight: "600" }}
              >
                {task?.completedPercentage}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={taskStyle.taskContainer}>
          <Typography sx={taskStyle.taskTitle}>Total</Typography>
          <Box sx={taskStyle}>
            <Typography
              variant="caption"
              component="div"
              color="#2D2D2D"
              sx={{ fontSize: "38px" }}
            >
              {task?.totalCount}
            </Typography>
          </Box>
        </Box>

        <Box sx={taskStyle.taskContainer}>
          <Typography sx={taskStyle.taskTitle}>New</Typography>
          <Typography
            variant="caption"
            component="div"
            color="#FFA400"
            sx={{ fontSize: "38px" }}
          >
            {task?.newCount}
          </Typography>
        </Box>
        <Box sx={taskStyle.taskContainer}>
          <Typography sx={taskStyle.taskTitle}>Overdue</Typography>
          <Typography
            variant="caption"
            component="div"
            color="#DF2E38"
            sx={{ fontSize: "38px" }}
          >
            {task?.overdueCount}
          </Typography>
        </Box>
        <Box sx={taskStyle.taskContainer}>
          <Typography sx={taskStyle.taskTitle}>Completed</Typography>
          <Typography
            variant="caption"
            component="div"
            color="#4A90E2"
            sx={{ fontSize: "38px" }}
          >
            {task.completedCount}
          </Typography>
        </Box>
      </Box>

      <AccordionList day={"Pending"} className={{ marginBottom: "50px" }}>
        {!task.pendingTasks?.length && (
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
              }}
            >
              {!task.pendingTasks?.length && "No Data Available"}
            </Typography>
          </Box>
        )}

        {task.pendingTasks?.length > 0 && (
          <SimpleTable header={todayTableHeader}>
            {task.pendingTasks?.map((today, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  {utcFormater(today.due_date)[0]}
                </TableCell>
                <TableCell align="left">{today.description}</TableCell>
                <TableCell align="left">{today.programTaskAssigned}</TableCell>
                <TableCell align="left">{today.comments}</TableCell>
                <TableCell align="left">
                  <Typography
                    sx={{
                      color: "#FFA400",
                      backgroundColor: "#FFEECF",
                      display: "inline-block",
                      padding: "10px 20px",
                      borderRadius: "30px",
                    }}
                  >
                    {today.stage}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </SimpleTable>
        )}
      </AccordionList>
      {/* <AccordionList day={"Upcoming"} className={{ marginBottom: "50px" }}>
        {!task.upcomingTasks?.length && (
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
              }}
            >
              {!task.upcomingTasks?.length && "No Data Available"}
            </Typography>
          </Box>
        )}

        {task.upcomingTasks?.length > 0 && (
          <SimpleTable header={UpcomingTableHeader}>
            {task.upcomingTasks?.map((today, index) => (
              <TableRow>
                <TableCell align="left" key={index}>
                  {utcFormater(today.due_date)[0]}
                </TableCell>
                <TableCell align="left" key={index}>
                  {today.description}
                </TableCell>
                <TableCell align="left" key={index}>
                  {today.programTaskAssigned}
                </TableCell>
                <TableCell align="left" key={index}>
                  {today.comments}
                </TableCell>
                <TableCell align="left" key={index}>
                  <Typography
                    sx={{
                      color: "#4A90E2",
                      backgroundColor: "#DDEAFA",
                      display: "inline-block",
                      padding: "10px 20px",
                      borderRadius: "30px",
                    }}
                  >
                    {today.stage}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </SimpleTable>
        )}
      </AccordionList> */}
      <AccordionList day={"Completed"} className={{ marginBottom: "50px" }}>
        {!task.completedTasks?.length && (
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
              }}
            >
              {!task.completedTasks?.length && "No Data Available"}
            </Typography>
          </Box>
        )}
        {task.completedTasks?.length > 0 && (
          <SimpleTable header={CompletedTableHeader}>
            {task.completedTasks?.map((today, index) => {
              const cancel = today?.stage === "Cancelled";
              return (
                <TableRow key={index}>
                  <TableCell align="left">
                    {utcFormater(today.due_date)[0]}
                  </TableCell>
                  <TableCell align="left">{today.description}</TableCell>
                  <TableCell align="left">
                    {today.programTaskAssigned}
                  </TableCell>
                  <TableCell align="left">{today.comments}</TableCell>
                  <TableCell align="left">
                    {utcFormater(today.completed_date)[0]}
                  </TableCell>
                  <TableCell align="left">
                    {today.assigned_to?.Username}
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        color: cancel ? "#fa5252" : "#24D37A",
                        backgroundColor: cancel ? "#ffe3e3" : "#D6F7E6",
                        display: "inline-block",
                        padding: "10px 20px",
                        borderRadius: "30px",
                      }}
                    >
                      {today.stage}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </SimpleTable>
        )}
      </AccordionList>
    </Box>
  );
};

export default ProgramTask;
