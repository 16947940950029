import React, { useEffect, useState } from "react";

import "../LiveSession.css";

import LiveSessionAction from "../modals/LiveSessionAction";
import CreateSessionForm from "../modals/CreateSessionForm";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import EditOccurrence from "../modals/EditOccurrence";
import ViewBooking from "../modals/ViewBooking";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { toast } from "react-toastify";

import DataTable from "../../../components/DataTable/DataTable";
import Button from "../../../components/CustomButton/Button";

import useLiveSession from "../../../hooks/tableColumns/useLiveSession";
import { useHistory } from "react-router-dom";

import {
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";

const LiveSessionDashboard = ({ activeTab }) => {
  const apiRef = useGridApiRef();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [showNewSession, setShowNewSession] = useState(false);
  const [showOccurence, setShowOccurence] = useState(false);
  const [showBooking, setShowBooking] = useState(false);
  const columns = useLiveSession(handleClick, handleCopyLink, activeTab);
  const [rows, setRows] = useState([]);
  const [viewListName, setViewListName] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [saveColumList, setColumList] = useState("");
  const [view, setView] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 1,
  });

  const handlePagination = async (model) => {
    setPaginationModel(model);
    setPageNo((pre) => {
      return model.page + 1;
    });
  };

  const handleChangeColumns = (columnList) => {
    setColumList(columnList);
  };

  const handleSaveColumns = () => {
    console.log(saveColumList, "testt");
  };

  const handleClose = (edit) => {
    if (edit !== "edit") {
      // setShowNewSession(false);
      setShowOccurence(false);
      setSelectedRow("");
      setShowBooking(false);
      setDisableBtn(false);
    }
    setAnchorEl(null);
    setView(false);
  };

  const handleView = () => {
    history.push("/studio/createSession", {
      state: {
        view: true,
        selectedRow: selectedRow,
      },
    });

    // setView(true);
    // setShowNewSession(true);
    // setAnchorEl(null);
  };

  const handleListName = (value) => {
    setViewListName(value);
    setAnchorEl(null);
    setShowBooking(true);
  };

  function handleClick(e, params) {
    setAnchorEl(e.target);
    setSelectedRow(params);
  }

  const handleCancel = async (value) => {
    let reqObject = {
      cancel: value,
    };

    if (!selectedRow?.meeting?.is_recurring) {
      reqObject = {};
    }

    const request = await axiosFetch({
      url: `/cancelProgramSession/${selectedRow.session_id}/${selectedRow.id}`,
      method: "post",
      requestConfig: reqObject ? reqObject : "",
    });

    if (request.status === 200) {
      toast.success(request.data.body, {
        position: toast.POSITION.TOP_CENTER,
      });
      handleClose();
      getData("upcoming");
    } else {
      toast.error(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handlePlay = async () => {
    const request = await axiosFetch({
      url: `/admin-getProgramSessionPlayS3?meeting_id=${selectedRow.id}`,
      method: "get",
    });
    if (request.status === 200) {
      handleClose();
      getData("completed");

      // if (!request?.data) {
      //   toast.error("Recording link is not available", {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      // }

      window.open(request.data, "_blank");
    } else {
      toast.error(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getData = async (value) => {
    const request = await axiosFetch({
      url: `getProgramSessionByStatus?status=${value}&page=${pageNo}&perPage=${paginationModel.pageSize}`,
      method: "get",
    });

    setRows(request.data.body);
  };

  function handleCopyLink(copyValue) {
    const textToCopy = copyValue;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Copied!", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        toast.success("Something went wrong!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  useEffect(() => {
    if (activeTab === 0) {
      getData("upcoming");
    } else {
      getData("completed");
    }
  }, [activeTab, paginationModel]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <div className="live-session-header">
        <h4>{activeTab === 0 ? "Upcoming" : "Completed"} Session</h4>
        <div>
          {/* <ChecklistIcon
            sx={{
              cursor: "pointer",
              fontSize: "40px",
              mr: "25px",
              color: "#2d2d2d",
            }}
            onClick={handleSaveColumns}
          /> */}
          <Button
            inline
            onClick={() => {
              history.push("/studio/createSession");
              //setShowNewSession(true);
            }}
          >
            Create Session
          </Button>
        </div>
      </div>
      <DataTable
        apiRef={apiRef}
        columns={columns}
        rows={rows.list}
        count={rows.count}
        handlePagination={handlePagination}
        pinList={[
          "start_date",
          "start_time",
          "zoom_name",
          "session_name ",
          "session_link",
          "action",
        ]}
        onColumnVisibilityModelChange={handleChangeColumns}
        // slots={{ toolbar: CustomToolbar }}
      />
      <LiveSessionAction
        anchorEl={anchorEl}
        onClose={handleClose}
        activeTab={activeTab}
        onEditSession={() => {
          // setShowNewSession(true);
          // setDisableBtn(true);
          history.push("/studio/createSession", {
            state: {
              selectedRow: selectedRow,
            },
          });
        }}
        onEditOccurence={setShowOccurence}
        selectedRow={selectedRow}
        onView={handleView}
        onCancel={handleCancel}
        onPlay={handlePlay}
        onViewList={handleListName}
      />
      {/* {showNewSession && (
        <CreateSessionForm
          closeModel={handleClose}
          selectedRow={selectedRow}
          view={view}
          onSession={getData}
          disableBtn={disableBtn}
        />
      )} */}
      {showOccurence && (
        <EditOccurrence
          closeModel={handleClose}
          selectedRow={selectedRow}
          onSession={getData}
        />
      )}
      {showBooking && (
        <ViewBooking
          closeModel={handleClose}
          viewListName={viewListName}
          selectedRow={selectedRow}
        />
      )}
    </>
  );
};

export default LiveSessionDashboard;
