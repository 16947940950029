import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton } from "@mui/material";
const useSlots = (handleClick) => {
  const columns = [
    {
      field: "id",
      headerName: "S No",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "department",
      headerName: "Department",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "totalHours",
      headerName: "Total Hours",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Actions",
      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
            onClick={(e) => handleClick(e, params.row)}
          >
            <IconButton>
              <MoreHorizIcon sx={{ cursor: "pointer" }} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return columns;
};

export default useSlots;
