import Modal from "../../../components/Modal/Modal";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MUIDateConverter } from "../../../hooks/helperFunction/dateConverter";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import {
  Grid,
  Typography,
  Card,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import { Fragment } from "react";

const initialSlot = {
  fromTime: null,
  toTime: null,
  duration: "",
};

const initialState = {
  startDate: null,
  endDate: null,
  slotList: [initialSlot],
};

const duration = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

const convertTime = (time) => {
  const date = new Date(time);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedTime = date.toLocaleTimeString("en-US", options);

  return formattedTime;
};

const AddSlot = ({ isOpen, onClose }) => {
  const form = useForm({
    defaultValues: initialState,
  });

  const { control, reset, handleSubmit } = form;

  const {
    fields: slotFields,
    append: appendSlots,
    remove: removeSlots,
  } = useFieldArray({ name: "slotList", control });

  const handleRemoveSolt = (index) => {
    if (slotFields.length > 1) {
      removeSlots(index);
    }
  };

  const handleAddSlots = () => {
    if (slotFields.length < 5) {
      appendSlots(initialSlot);
    }
  };

  const handleClose = () => {
    reset();
    onClose(false);
  };

  const onSubmit = (formData) => {
    console.log(formData, "ttt");
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="dateForm">
        <Card elevation={0} sx={{ width: "650px", padding: "10px" }}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "5px",
                }}
              >
                Coach Name
              </Typography>
              <Controller
                name={`te`}
                rules={{ required: "required" }}
                control={control}
                defaultValue={""}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <Select
                      sx={{ fontSize: "16px" }}
                      {...field}
                      error={!!error}
                    >
                      <MenuItem value="select" disabled>
                        Select
                      </MenuItem>
                    </Select>
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "5px",
                }}
              >
                Start Date
              </Typography>
              <Controller
                name="startDate"
                control={control}
                rules={{ required: "required" }}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DatePicker
                      sx={{ width: "100%" }}
                      {...field}
                      onChange={(event) => {
                        field.onChange(MUIDateConverter(event.$d)[0]);
                      }}
                      value={field.value && dayjs(field.value)}
                      minDate={dayjs(new Date())}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "5px",
                }}
              >
                End Date
              </Typography>
              <Controller
                name="endDate"
                control={control}
                rules={{ required: "required" }}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DatePicker
                      sx={{ width: "100%" }}
                      {...field}
                      onChange={(event) => {
                        field.onChange(MUIDateConverter(event.$d)[0]);
                      }}
                      value={field.value && dayjs(field.value)}
                      minDate={dayjs(new Date())}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <AddIcon
                sx={{ cursor: "pointer", fontSize: "30px" }}
                onClick={handleAddSlots}
              />
            </Grid>
            {slotFields.map((field, index) => (
              <Fragment key={field.id}>
                <Grid item md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: "5px",
                    }}
                  >
                    From Time
                  </Typography>
                  <Controller
                    name={`slotList.${index}.fromTime`}
                    control={control}
                    rules={{ required: "required" }}
                    render={({ field, fieldState: { error } }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        fullWidth
                      >
                        <TimePicker
                          sx={{ width: "100%" }}
                          {...field}
                          onChange={(event) => {
                            field.onChange(convertTime(event?.$d));
                          }}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              error: !!error,
                              helperText: error?.message,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: "5px",
                    }}
                  >
                    To Time
                  </Typography>
                  <Controller
                    name={`slotList.${index}.toTime`}
                    control={control}
                    rules={{ required: "required" }}
                    render={({ field, fieldState: { error } }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        fullWidth
                      >
                        <TimePicker
                          sx={{ width: "100%" }}
                          {...field}
                          onChange={(event) => {
                            field.onChange(convertTime(event?.$d));
                          }}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              error: !!error,
                              helperText: error?.message,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: "5px",
                    }}
                  >
                    Duration
                  </Typography>

                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Controller
                      name={`slotList.${index}.duration`}
                      rules={{ required: "required" }}
                      control={control}
                      defaultValue={""}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small">
                          <Select
                            sx={{ fontSize: "16px" }}
                            {...field}
                            error={!!error}
                          >
                            <MenuItem value="select" disabled>
                              Select
                            </MenuItem>

                            {duration.map((value) => (
                              <MenuItem value={value} key={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={true}>
                            {error?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />

                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleRemoveSolt(index)}
                    />
                  </Box>
                </Grid>
              </Fragment>
            ))}

            <Grid item md={12} sx={{ display: "flex", gap: "20px" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#000",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                  marginBottom: "20px",
                }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#000",
                  },
                  borderColor: "#000",
                  color: "#000",
                  marginBottom: "20px",
                }}
                onClick={handleClose}
              >
                close
              </Button>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Modal>
  );
};

export default AddSlot;
