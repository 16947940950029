import React, { useContext, useEffect, useState } from "react";
import SideBarItem from "./SideBarItem";
import Accordion from "react-bootstrap/Accordion";
import stateContext from "../context/StateContext";
function SideBar(props) {
  const contextState = useContext(stateContext);
  const userid = JSON.parse(window.localStorage.getItem("operator"))?.Role;
  const leadsSubLinks = [];
  const item = JSON.parse(localStorage.getItem("loginDetails"));

  const [paymentList, setPaymentList] = useState([]);

  useEffect(() => {
    const updatedPaymentList =
      contextState.state.featureAccessible.find((fe) => {
        return fe.feature_id === 14 && fe.sub_feature_id === 91 && fe.is_view;
      }) !== undefined;

    if (updatedPaymentList) {
      setPaymentList(["Unallocated Payments", "List of Payments"]);
    } else {
      setPaymentList(["Unallocated Payments"]);
    }
  }, [contextState.state.featureAccessible]);

  // if (contextState.state.featureAccessible.find(fe => fe.sub_feature_info.sub_feature_name == "All Leads") && contextState.state.featureAccessible.find(fe => fe.sub_feature_id).is_edit) {
  //     leadsSubLinks.push("Fresh Leads")
  // }
  // leadsSubLinks.push("Upcoming Follow up", "Today Follow up", "Old Pending Follow up", "All leads")
  leadsSubLinks.push("List leads");

  const plansSubLink = [];

  if (
    contextState.state.featureAccessible.find((fe) => {
      return fe.sub_feature_id == 23 && fe.is_view == true;
    })
  ) {
    plansSubLink.push("Diet Plan");
  }
  if (
    contextState.state.featureAccessible.find((fe) => {
      return fe.sub_feature_id == 24 && fe.is_view == true;
    })
  ) {
    plansSubLink.push("Workout Plan");
  }

  const templatesSubLink = [];

  if (
    contextState.state.featureAccessible.find((fe) => {
      return fe.sub_feature_id == 23 && fe.is_view == true;
    })
  ) {
    templatesSubLink.push("Diet Template");
  }
  if (
    contextState.state.featureAccessible.find((fe) => {
      return fe.sub_feature_id == 24 && fe.is_view == true;
    })
  ) {
    templatesSubLink.push("Workout Template");
  }

  const settingsSublinks = [];

  // console.log(contextState.state.featureAccessible, "pugalllllllllll");

  if (contextState.state.currentUser.Role === 1) {
    settingsSublinks.push("Manage Users", "Manage Roles", "Automation");
    // settingsSublinks.push("Manage Users", "Manage Roles", "Slots");
  } else if (
    contextState.state.featureAccessible.find(
      (fe) => fe.feature_id === 36 && fe.sub_feature_id === 85 && fe.is_view
    ) !== undefined
  ) {
    settingsSublinks.push("Automation");
  }
  // if (
  //   contextState.state.featureAccessible.find(
  //     (fe) => fe.sub_feature_id === 85 && fe.is_view
  //   )
  // ) {
  //   settingsSublinks.push("Sales Allocation");
  // }

  return (
    <section id="menu_section" className={"noScroll"}>
      <Accordion>
        {contextState.state.featureAccessible.find(
          (fe) => fe.feature_id === 45 && fe.is_view
        ) && (
          <SideBarItem
            itemKey="0"
            title="Dashboard"
            icon="fa-regular fa-list-check"
            redirect="/dashboard"
          />
        )}

        <SideBarItem
          itemKey="0"
          title="My Tasks"
          icon="fa-solid fa-bars"
          redirect="/myTask"
        />

        {/* <SideBarItem
          itemKey="0"
          title="New Client"
          icon="fa-solid fa-bars"
          redirect="/newClient"
        />
        <SideBarItem
          itemKey="0"
          title="New Lead"
          icon="fa-solid fa-bars"
          redirect="/newLead"
        /> */}

        {/* <SideBarItem
          itemKey="0"
          title="Live Session"
          icon="fa-solid fa-bars"
          redirect="/liveSesion"
        /> */}

        <SideBarItem
          itemKey="17"
          title="Appointments"
          icon="fas fa-calendar"
          redirect="/appointment"
        />

        {/*   START-  For time being it is commented on 14/10/2022 */}

        {/* {contextState.state.pageAccessible.includes("Agents") &&
                    <SideBarItem
                        itemKey="2"
                        title="Agents"
                        icon="fas fa-user-secret"
                        subLinks={[
                            "Add Agent", "List Agents"
                        ]}
                    />
                } */}

        {/* {contextState.state.pageAccessible.includes("Projects") &&
                    <SideBarItem
                        itemKey="3"
                        title="Projects"
                        icon="fas fa-flag"
                        subLinks={[
                            "Add Projects", "View All Projects"
                        ]}
                    />
                } */}

        {/* {contextState.state.pageAccessible.includes("Properties") &&
                    <SideBarItem
                        itemKey="4"
                        title="Properties"
                        icon="fas fa-building"
                        subLinks={[
                            "Add New Property", "View All Properties"
                        ]}
                    />
                } */}

        {/*  END   */}

        {contextState.state.featureAccessible.find((fe) => {
          // console.log(
          //   '(fe.feature_info.feature_name == "Leads")::: ',
          //   fe.feature_info.feature_name == "Leads" && fe.is_view == true
          // );
          return fe.feature_id === 1 && fe.is_view == true;
        }) && (
          <SideBarItem
            itemKey="5"
            title="Leads"
            icon="far fa-phone-square-alt"
            subLinks={leadsSubLinks}
          />
        )}

        {contextState.state.featureAccessible.find((fe) => {
          /*  console.log(
              '(fe.feature_info.feature_name == "Leads")::: ',
              fe.feature_info.feature_name == "Leads" && fe.is_view == true
            ); */
          return (
            (fe.sub_feature_id == 23 && fe.is_view == true) ||
            (fe.sub_feature_id == 24 && fe.is_view == true)
          );
        }) && (
          <SideBarItem
            itemKey="12"
            title="Plans"
            icon="far fa-book"
            subLinks={plansSubLink}
          />
        )}

        {/* {contextState.state.featureAccessible.find(fe=>(fe.sub_feature_id==23 && fe.is_view) || (fe.sub_feature_id==24 && fe.is_view))&&(
            <SideBarItem
            itemKey='12'
            title='Template'
            icon='fas fa-book'
            redirect='/templateDetail'
            />
         ) }  */}

        {contextState.state.featureAccessible.find(
          (fe) =>
            (fe.sub_feature_id == 23 && fe.is_view) ||
            (fe.sub_feature_id == 24 && fe.is_view)
        ) && (
          <SideBarItem
            itemKey="14"
            title="Templates"
            icon="fas fa-book"
            subLinks={templatesSubLink}
          />
        )}

        {/* {contextState.state.pageAccessible.includes("Assigned Leads Calls") &&
                    <SideBarItem
                        itemKey="6"
                        title="Assigned Lead's Calls"
                        icon="fas fa-tasks"
                        redirect="/assignedLeadsCalls"
                    />
                } */}

        {/* {contextState.state.pageAccessible.includes("Assigned Leads Visits") &&
                    <SideBarItem
                        itemKey="6"
                        title="Assigned Lead's Visits"
                        icon="fas fa-synagogue"
                        redirect="/assignedLeadsVisits"
                    />
                } */}
        {/* {contextState.state.pageAccessible.includes("User Activity") &&
                    <SideBarItem
                        itemKey="12"
                        title="User Activity"
                        icon="fas fa-chart-line"
                        redirect="/userActivity"
                    />
                } */}

        {contextState.state.featureAccessible.find((fe) => {
          return fe.feature_id == 5 && fe.is_view;
        }) && (
          <SideBarItem
            itemKey="1"
            title="Clients"
            icon="fas fa-users"
            subLinks={[/*"Add Client",*/ "List Clients"]}
          />
        )}
        {contextState.state.featureAccessible.find((fe) => {
          return fe.feature_id == 5 && fe.is_view;
        }) && (
          <SideBarItem
            itemKey="17"
            title="Reports"
            icon="fas fa-users"
            subLinks={["Activity Report", "Progress Report"]}
          />
        )}

        {/* {contextState.state.pageAccessible.includes("Agreement") &&
                    <SideBarItem
                        itemKey="7"
                        title="Agreement"
                        icon="fas fa-file-contract"
                        subLinks={[
                            "Add New Agreement", "View Agreement History"
                        ]}
                    />
                } */}
        {userid?.toString() === "1" ? (
          <SideBarItem
            itemKey="13"
            title="Programs"
            icon="fa fa-briefcase"
            // redirect="/programsNew"
            subLinks={["Program List", "Task Automation", "Access Automation"]}
          />
        ) : (
          ""
        )}

        {userid?.toString() === "1" ? (
          <SideBarItem
            itemKey="7"
            title="Shape Up Studio"
            icon="fa fa-dumbbell"
            redirect="/studio"
          />
        ) : (
          ""
        )}

        <SideBarItem
          itemKey="30"
          title="Payments"
          icon="fa fa-briefcase"
          redirect="/Payments"
          // subLinks={["Unallocated Payments", "List of Payments"]}
          subLinks={paymentList}
        />
        <SideBarItem
          itemKey="7"
          title="Interactions"
          icon="fa fa-bullhorn"
          subLinks={["Calls"]}
        />
        <SideBarItem
          itemKey="7"
          title="Testimonials"
          icon="fa-solid fa-camera-retro"
          redirect="/testimonial"
        />

        {contextState.state.featureAccessible.find(
          (fe) => fe.feature_info.feature_name == "Masters"
        ) && (
          <SideBarItem
            itemKey="9"
            title="Masters"
            icon="far fa-cog"
            // subLinks={[
            //     "Add Source", "Add Property Type", "Add Amenities", "Add Agreement Type", "Add Status"
            // ]}
            subLinks={["Add Source", "Add Status"]}
          />
        )}
        {contextState.state.featureAccessible.find(
          (fe) => fe.feature_info.feature_name == "Notifications"
        ) && (
          <SideBarItem
            itemKey="16"
            title="Notifications"
            icon="fas fa-bell"
            redirect="/notifications"
          />
        )}

        <SideBarItem
          itemKey="10"
          title="Settings"
          icon="fas fa-wrench"
          subLinks={settingsSublinks}
        />

        {contextState.state.currentUser.Role === 1 && (
          <SideBarItem
            itemKey="11"
            title="Configurations"
            icon="fas fa-info-circle"
            redirect="/configurations"
          />
        )}
      </Accordion>
    </section>
  );
}

export default React.memo(SideBar);
