import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import UpdateThresold from "./Modals/UpdateThresold";
import { useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
  },
});

const table = {
  header: { fontWeight: "600" },
};

const ThresholdTable = ({ thresholdList, thresholdType, getThresholdList }) => {
  const classes = useStyles();

  const [isOPen, setIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = (value) => {
    setCurrentRow(value);
    setIsOpen(true);
  };

  const handleThreshold = async (formdata, reset) => {
    try {
      setIsLoading(true);
      const findType = {
        trainer: "trainerId",
        dietitian: "dietitianId",
      };
      const object = {
        threshold: +formdata.thresold,
        [findType[thresholdType]]: currentRow[findType[thresholdType]],
      };
      const request = await axiosFetch({
        url: `/api/v1/clientAutoThreshold?thresholdType=${thresholdType}`,
        method: "put",
        requestConfig: object,
      });

      if (request.status === 200) {
        toast.success(request.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        setIsOpen(false);
        getThresholdList();
        setIsLoading(false);
      } else {
        toast.error(request.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box sx={{ mt: "50px", width: "60%" }}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
            <TableRow>
              <TableCell align="center" sx={table.header}>
                Coach Name
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Active Client Count
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Threshold (Number)
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {thresholdList?.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.active_client_count}</TableCell>
                <TableCell align="center">{row.threshold}</TableCell>
                <TableCell align="center">
                  <EditIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <UpdateThresold
        isOpen={isOPen}
        onClose={() => setIsOpen(false)}
        handleThreshold={handleThreshold}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ThresholdTable;
