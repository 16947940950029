import { useState, useMemo, useEffect, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./AllocationList.css";
import { CreateAllocation } from "./Index";
import { ATLAS_URI } from "../../../Constants";
import stateContext from "../../../context/StateContext";
import { useHistory } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { dateConverter } from "../../../Utlis/dateConversion";
const AllocationList = () => {
  let history = useHistory();

  const contextState = useContext(stateContext);

  const { configToken } = contextState.state;
  const [openPopup, setOpenPopup] = useState(false);
  const [allocationList, setAllocationList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allocationId, setAllocationId] = useState("");
  const [typeId, setTypeId] = useState("");

  const open = Boolean(anchorEl);

  const togglePopup = () => {
    setOpenPopup(!openPopup);
    setAllocationId("");
    setTypeId("");
  };

  const toggleEditPopup = () => {
    setOpenPopup(!openPopup);
  };
  const closePopup = () => {
    setOpenPopup(false);
    getAllocationList();
  };
  const handleClick = (event, id) => {
    setAllocationId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAllocationId("");
    setTypeId("");
  };

  const getAllocationList = () => {
    axios
      .get(`${ATLAS_URI}/getAutoAllocationRule`, configToken)
      .then((res) => {
        if (res.status === 200) {
          setAllocationList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const editAllocation = (typeId) => {
    // typedId = 1 edit
    // typeId = 2 view
    if (typeId === 1) {
      setTypeId(1);
    } else if (typeId === 2) {
      setTypeId(2);
    }
    toggleEditPopup();
    setAnchorEl(null);
  };

  const deleteAllocation = () => {
    if (allocationId !== "") {
      axios
        .delete(
          `${ATLAS_URI}/deleteAutoAllocationRuleByRuleId/${allocationId}`,
          configToken
        )
        .then((res) => {
          if (res.status === 200) {
            getAllocationList();
            toast.success("Allocation Deleted Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            handleClose();
          } else if (res.status === 400) {
            history.push("/");
          }
        })
        .catch((err) => console.error(err));
    } else {
      toast.error("Allocation ID Must!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    getAllocationList();
  }, []);

  let background = { backgroundColor: "#F4F4F4" };
  let color = {};

  return (
    <div className="container-list">
      <Button variant="contained" className="alloc-btn" onClick={togglePopup}>
        <AddIcon />
        Create Allocation
      </Button>
      <br />
      <TableContainer component={Paper} className="alloc-table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableCell sx={background}>
              <strong style={color}>S.No</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Source</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Medical Condition</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Language</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Country</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Age Group</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Profession</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Gender</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Sequence No</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Assigned Sales Executive</strong>
            </TableCell>

            <TableCell sx={background}>
              <strong style={color}>Start Date</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>End Date</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Status</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Created By</strong>
            </TableCell>
            <TableCell sx={background}>
              <strong style={color}>Created On</strong>
            </TableCell>

            <TableCell sx={background}>
              <strong style={color}>Actions</strong>
            </TableCell>
          </TableHead>
          <TableBody>
            {allocationList && allocationList.length !== 0 ? (
              allocationList?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:nth-child(even)": {
                      background: "#F4F4F4",
                    },
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {row?.rule_details.filter(
                      (rule) => rule.field_name === "source"
                    )?.length &&
                    row?.rule_details.filter(
                      (rule) => rule.field_name === "source"
                    )[0].value?.length
                      ? row.rule_details.filter(
                          (rule) => rule.field_name === "source"
                        ).length === 1
                        ? row.rule_details
                            .filter((rule) => rule.field_name === "source")
                            .map((item) => item.value.map((val) => val.Source))
                        : row.rule_details
                            .filter((rule) => rule.field_name === "source")
                            .map((item) => item.value.map((val) => val.Source))
                            .toString()
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row?.rule_details.filter(
                      (rule) => rule.field_name === "medical_condition"
                    )?.length &&
                    row?.rule_details.filter(
                      (rule) => rule.field_name === "medical_condition"
                    )[0].value?.length
                      ? row.rule_details.filter(
                          (rule) => rule.field_name === "medical_condition"
                        ).length === 1
                        ? row.rule_details
                            .filter(
                              (rule) => rule.field_name === "medical_condition"
                            )
                            .map((item) =>
                              item.value.map((val) => val.medical_condition)
                            )
                            .toString()
                        : row.rule_details
                            .filter(
                              (rule) => rule.field_name === "medical_condition"
                            )
                            .map((item) =>
                              item.value.map((val) => val.medical_condition)
                            )
                            .toString()
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row?.rule_details.filter(
                      (rule) => rule.field_name === "language"
                    )?.length &&
                    row?.rule_details.filter(
                      (rule) => rule.field_name === "language"
                    )[0].value?.length
                      ? row.rule_details.filter(
                          (rule) => rule.field_name === "language"
                        ).length === 1
                        ? row.rule_details
                            .filter((rule) => rule.field_name === "language")
                            .map((item) =>
                              item.value.map((val) => val.languageDisplayValue)
                            )
                            .toString()
                        : row.rule_details
                            .filter((rule) => rule.field_name === "language")
                            .map((item) =>
                              item.value.map((val) => val.languageDisplayValue)
                            )
                            .toString()
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row?.rule_details.filter(
                      (rule) => rule.field_name === "country"
                    )?.length &&
                    row?.rule_details.filter(
                      (rule) => rule.field_name === "country"
                    )[0].value?.length
                      ? row.rule_details.filter(
                          (rule) => rule.field_name === "country"
                        ).length === 1
                        ? row.rule_details
                            .filter((rule) => rule.field_name === "country")
                            .map((item) => item.value.map((val) => val.country))
                            .toString()
                        : row.rule_details
                            .filter((rule) => rule.field_name === "country")
                            .map((item) => item.value.map((val) => val.country))
                            .toString()
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row?.rule_details.filter(
                      (rule) => rule.field_name === "age"
                    )?.length &&
                    row?.rule_details.filter(
                      (rule) => rule.field_name === "age"
                    )[0].value?.length
                      ? row.rule_details.filter(
                          (rule) => rule.field_name === "age"
                        ).length === 1
                        ? row.rule_details
                            .filter((rule) => rule.field_name === "age")
                            .map((item) => item.value.map((val) => val.value))
                            .toString()
                        : row.rule_details
                            .filter((rule) => rule.field_name === "age")
                            .map((item) => item.value.map((val) => val.value))
                            .toString()
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row?.rule_details.filter(
                      (rule) => rule.field_name === "profession"
                    )?.length &&
                    row?.rule_details.filter(
                      (rule) => rule.field_name === "profession"
                    )[0].value?.length
                      ? row.rule_details.filter(
                          (rule) => rule.field_name === "profession"
                        ).length === 1
                        ? row.rule_details
                            .filter((rule) => rule.field_name === "profession")
                            .map((item) =>
                              item.value?.length
                                ? item.value.map((val) => val.profession)
                                : "NA"
                            )
                            .toString()
                        : row.rule_details
                            .filter((rule) => rule.field_name === "profession")
                            .map((item) =>
                              item.value?.length
                                ? item.value.map((val) => val.profession)
                                : "NA"
                            )
                            .toString()
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row?.rule_details.filter(
                      (rule) => rule.field_name === "gender"
                    )?.length &&
                    row?.rule_details.filter(
                      (rule) => rule.field_name === "gender"
                    )[0].value?.length
                      ? row.rule_details.filter(
                          (rule) => rule.field_name === "gender"
                        ).length === 1
                        ? row.rule_details
                            .filter((rule) => rule.field_name === "gender")
                            .map((item) => item.value?.map((val) => val))
                            .toString() === "F"
                          ? "Female"
                          : "Male"
                        : row.rule_details
                            .filter((rule) => rule.field_name === "gender")
                            .map((item) => item.value.map((val) => val))
                            .toString() === "F"
                        ? "Female"
                        : "Male"
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row.sequence !== null ? row.sequence : "NA"}
                  </TableCell>
                  <TableCell>
                    {row.sales_executive.length !== 0
                      ? row.sales_executive
                          .map((ppl) => ppl.firstName)
                          .toString()
                      : "NA"}
                  </TableCell>

                  <TableCell>
                    {row.start_date !== null
                      ? dateConverter(row.start_date) //row.start_date.split("T")[0]
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row.end_date !== null
                      ? dateConverter(row.end_date) //row.end_date.split("T")[0]
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row.status !== null
                      ? row.status === 1
                        ? "Active"
                        : "InActive"
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row.created_by_details !== null
                      ? row.created_by_details.firstName !== null &&
                        row.created_by_details.firstName
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    {row.createdAt !== null
                      ? dateConverter(row.createdAt) //row.createdAt.split("T")[0]
                      : "NA"}
                  </TableCell>
                  <TableCell>
                    <MoreHorizIcon onClick={(e) => handleClick(e, row.id)} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <center>No Result Found</center>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          top: "-200px",
          left: "0px",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => editAllocation(1)}>
          <EditIcon /> Edit
        </MenuItem>
        <hr />
        <MenuItem onClick={() => editAllocation(2)}>
          <VisibilityIcon />
          View
        </MenuItem>
        <hr />
        <MenuItem onClick={() => deleteAllocation()}>
          <DeleteIcon /> Delete
        </MenuItem>
      </Menu>

      {openPopup && (
        <CreateAllocation
          open={openPopup}
          togglePopup={togglePopup}
          closePopup={closePopup}
          allocationId={allocationId}
          typeId={typeId}
        />
      )}
    </div>
  );
};

export default AllocationList;
