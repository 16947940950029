import { useEffect, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import { Typography, Card, Box } from "@mui/material";
import Button from "../../../../components/CustomButton/Button";

const DeleteModal = ({ isOpen, onClose, handleDelete, id }) => {
  const [value, setValue] = useState(id);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box sx={{ width: "400px" }}>
        <Typography
          sx={{ marginBottom: "20px", textAlign: "center", fontSize: "20px" }}
        >
          Are you sure you want to delete this ?
        </Typography>
        <Card
          elevation={0}
          sx={{ display: "flex", gap: "20px", justifyContent: "center" }}
        >
          <Button outline type={"button"} onClick={onClose}>
            No
          </Button>
          <Button inline type={"button"} onClick={() => handleDelete(value)}>
            Yes
          </Button>
        </Card>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
