const useBooking = () => {
  const columns = [
    {
      field: "no",
      headerName: "S No",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const rowId = params.row.id;
        const sortedRowIds = params.api.getSortedRowIds();
        const rowIndex = sortedRowIds.indexOf(rowId) + 1;

        return rowIndex;
      },
    },
    {
      field: "participant_id",
      headerName: "Lead Id",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return params.row?.participant?.id;
      },
    },
    {
      field: "client_Name",
      headerName: "Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return params.row?.participant?.name;
      },
    },
    {
      field: "contact_number",
      headerName: "Phone No",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return params.row?.participant?.contact_number;
      },
    },
  ];

  return columns;
};

export default useBooking;
