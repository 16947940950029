import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import * as $ from 'jquery'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import FollowupModal from '../../components/FollowupModal'
import EmailModal from '../../components/EmailModal'
import Dialog from '../../components/Dialog'
import { returnTime, getTodayTime } from '../../Helpers/DateHelpers'
import { changeHandler as oldChangeHandler } from '../../Helpers/ChangeHandler'
import { openFollowUpModal, closeFollowUpModal, openEmailModal, closeEmailModal, updateFollowUp, updateFollowUpStatus, deleteFollowUp } from '../../Helpers/ModalHandler'
import { ATLAS_URI, ADMIN } from '../../Constants'
import stateContext from '../../context/StateContext'

function AssignedLeadsCalls() {

    const contextState = useContext(stateContext)
    const { updateEditDetails, updateList } = contextState
    const { configToken, leadsList, usersList, sourcesList, propertyTypeList, statusList, operator } = contextState.state


    const [state, setState] = useState({
        resetNewRow: {
            id: "",
            Client: "",
            ClientName: "",
            ClientContact: "",
            ClientEmail: "",
            ClientLocation: "",
            LeadStatus: "",
            Remarks: [],
            PropertyType: "",
            RequiredLocation: "",
            MinArea: "",
            MaxArea: "",
            MinBudget: "",
            MaxBudget: "",
            RequiredLoan: "",
            Source: "Select",
            call_status: "",
            LeadDate: "",
            LastFollowUpDate: "",
            NextFollowUpDate: "",
            FollowUpList: [],
            CreatedBy: ""
        },
        newTableRow: {
            FollowUpList: [],
        },
        tableBodyList: [],
        tableBodyDisplayList: [],
        Email: "",
        EmailInfo: {},
        statusList: [],
        isModalOpen: false,
        isFollowUpModalOpen: false,
        isEmailModalOpen: false,
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        },
    })

    const mounted = React.useRef(true);
    useEffect(() => () => { mounted.current = false; }, []);
    useEffect(() => {
        const newEmailInfo = {}
        axios.get(`${ATLAS_URI}/getConfiguration/`, configToken)
            .then(response => {
                const resData = response.data[0];
                if (typeof resData !== 'undefined') {
                    newEmailInfo.UserID = response.data[0].UserID;
                    newEmailInfo.ServiceID = response.data[0].ServiceID;
                    newEmailInfo.TemplateID = response.data[0].TemplateID
                }
            }).catch(err => console.log(err))

        let leadsData = leadsList;
        let userData = usersList;
        let sourcesData = sourcesList;
        let propertyTypesData = propertyTypeList;
        let statusData = statusList;

        if (leadsData.length !== 0
            && sourcesData.length !== 0
            && propertyTypesData.length !== 0
            && userData.length !== 0) {

            leadsData.map(lead => {

                const filteredUser = userData.filter(user => user.id === lead.AssignedTo);
                lead._AssignedTo = filteredUser.length === 0 ? "" : filteredUser[0].Username;
                let tempSources = sourcesData.filter(source => source.id === lead.Source);
                lead.Sources = tempSources.length !== 0 && tempSources[0].Source;
                let tempPropertyType = propertyTypesData.filter(propertyType => propertyType.id === lead.PropertyType);
                lead.PropertyTypes = tempPropertyType.length !== 0 && tempPropertyType[0].PropertyType;
                lead.FollowUpList = lead.FollowUps
                lead.LeadStatus = lead.LeadStatusID && lead.LeadStatusID.Status

                lead.LastRemarks = lead.Remarks && (lead.Remarks.length !== 0 ? lead.Remarks[lead.Remarks.length - 1].Remarks : "");

                return lead;
            })

            const userID = operator.id;
            leadsData = operator.Role === ADMIN ? leadsData : leadsData.filter(lead => lead.AssignedTo === userID);

            setTimeout(() => {
                mounted.current && setState(prevState => ({
                    ...prevState,
                    EmailInfo: newEmailInfo,
                    tableBodyList: leadsData,
                    tableBodyDisplayList: leadsData,
                    statusList: statusData
                }))
            }, 200)

        }

    }, []);
    function editRecord(e) {
        const temp = e.target.parentElement.parentElement.id;
        updateEditDetails({ id: temp, editingActivated: true, redirectFrom: "/assignedLeadsCalls", redirectTo: "/Leads/leadDetails" });
    }

    function changeHandler(e) {
        oldChangeHandler(e, state, setState);
    }

    function openDialog(e) {
        const newDialogInfo = {
            isOpened: true,
            delID: e.target.parentElement.parentElement.id,
            text: "Are you sure you want to remove this Record?",
            type: "Confirm"
        }
        setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
        setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
    }

    function deleteFromTable(e) {
        const delID = state.dialogInfo.delID;
        let editedLead = state.tableBodyList.filter(lead => lead.id === delID)[0];

        // editedLead.AssignedTo = "Removed By: " + operator.Name;
        // editedLead._AssignedTo = "Removed By: " + operator.Name;
        axios.post(`${ATLAS_URI}/updateLead/${delID}`, editedLead, configToken)
            .then(() => {

                if (operator.Role === ADMIN) {
                    const newTableBodyList = state.tableBodyList.map(lead => lead.id.toString() !== delID ? lead : editedLead);
                    updateList('leadsList', newTableBodyList)

                    setState(prevState => ({
                        ...prevState,
                        tableBodyList: newTableBodyList,
                        tableBodyDisplayList: state.tableBodyDisplayList.map(lead => lead.id.toString() !== delID ? lead : editedLead),
                        dialogInfo: { isOpened: false, text: "", delID: "" }
                    }))

                } else {
                    const newTableBodyList = state.tableBodyList.map(lead => lead.id.toString() !== delID);
                    updateList('leadsList', newTableBodyList)

                    setState(prevState => ({
                        ...prevState,
                        tableBodyList: newTableBodyList,
                        tableBodyDisplayList: state.tableBodyDisplayList.filter(lead => lead.id.toString() !== delID),
                        dialogInfo: { isOpened: false, text: "", delID: "" }
                    }))

                }
            })
            .catch(err => alert(err))
    }

    function searchCriteria(e) {

        e.preventDefault();

        const followUps = $("#searchFollowUps").val();
        const leadStatus = $("#searchLeadStatus").val();
        const call_status = $("#searchcall_status").val();

        const newTableBodyList = state.tableBodyList.filter(data =>
            (leadStatus !== "" ? data.LeadStatus === leadStatus : true) &&
            (call_status !== "" ? data.call_status === call_status : true) &&

            (followUps === "" ? true :
                (
                    (followUps === "Today Follow-ups" && returnTime(data.NextFollowUpDate) === getTodayTime()) ||
                    (followUps === "Upcoming Follow-ups" && returnTime(data.NextFollowUpDate) > getTodayTime()) ||
                    (followUps === "Old Pending" && returnTime(data.NextFollowUpDate) < getTodayTime())
                )
            )
        )

        setState(prevState => ({
            ...prevState,
            tableBodyDisplayList: newTableBodyList
        }))
    }

    function resetSearchCriteria() {
        $("#searchFollowUps").val("")
        $("#searchLeadStatus").val("")
        $("#searchcall_status").val("")
    }

    function openWhatsapp(e) {
        e.preventDefault();
        const id = e.target.parentElement.parentElement.id
        const phone = state.tableBodyList.filter(data => data.id.toString() === id)[0].ClientPhone.replace("+", "");
        window.open(`https://wa.me/${phone}`, "_blank");

    }
    return (
        <section className="content">
            <div className="row">
                <Dialog
                    onFalse={(e) => setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "" } }))}
                    onTrue={(e) => deleteFromTable(e)}
                    dialogInfo={state.dialogInfo}
                />
                <div className="col-md-12">

                    <div className="box box-primary">

                        <BoxHeader title="Select Criteria" />

                        <form onSubmit={searchCriteria}>

                            <div className="box-body row">

                                <input type="hidden" name="ci_csrf_token" value="" />

                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label>Follow Ups</label>
                                        <select id="searchFollowUps" className="form-control" >
                                            <option value="" >Select All</option>
                                            <option value="Today Follow-ups">Today Follow-ups</option>
                                            <option value="Upcoming Follow-ups">Upcoming Follow-ups</option>
                                            <option value="Old Pending Follow-ups">Old Pending Follow-ups</option>
                                        </select>
                                        <span className="text-danger"></span>
                                    </div>
                                </div>

                                <div className="col-sm-3 col-md-3">
                                    <div className="form-group">
                                        <label>Lead Status</label>
                                        <select id="searchLeadStatus" className="form-control" >
                                            <option value="" >Select All</option>
                                            {
                                                statusList.map((status) =>
                                                    <option key={status.id} value={status.Status}>{status.Status}</option>
                                                )
                                            }
                                        </select>
                                        <span className="text-danger"></span>
                                    </div>
                                </div>

                                <div className="col-sm-3 col-md-3">
                                    <div className="form-group">
                                        <label>Call Status</label>
                                        <select id="searchcall_status" className="form-control" >
                                            <option value="" >Select All</option>
                                            <option value="Contacted">Contacted</option>
                                            <option value="Not Contacted">Not Contacted</option>
                                        </select>
                                        <span className="text-danger"></span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary btn-sm pull-right"><i className="fa fa-search"></i> Search</button>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <BoxHeader title="Assigned Leads Calls" />

                        <div className="box-body">
                            <DataTable
                                tableHeader={operator.Role === ADMIN ?
                                    ["id", "Client Name", "Client Phone", "Interested In", "Last Remarks", "Sources", "Next Follow Up Date", "Lead Status", "Call Status", "_Assigned To"] :
                                    ["id", "Client Name", "Client Phone", "Interested In", "Last Remarks", "Sources", "Next Follow Up Date", "Lead Status", "Call Status"]
                                }
                                tableBody={state.tableBodyDisplayList}
                                searchField={operator.Role === ADMIN ? "_AssignedTo" : "ClientName"}
                                customAction={[
                                    { title: "Follow", icon: "fas fa-phone-alt text-green", onClickEvent: (e) => openFollowUpModal(e, state, setState) },
                                    { title: "Update", icon: "fas fa-edit", redirectTo: "/Leads/leadDetails", onClickEvent: editRecord },
                                    // { title: "Delete", icon: "fas fa-times text-red", onClickEvent: openDialog },
                                    { title: "Email", icon: "fas fa fa-envelope", onClickEvent: (e) => openEmailModal(e, state, setState) },
                                    { title: "Whatsapp", icon: "fab fa-brand fa-whatsapp", onClickEvent: openWhatsapp },

                                ]}
                            />

                        </div>
                    </div>

                </div>
            </div>
            {state.isFollowUpModalOpen &&
                <FollowupModal
                    state={state}
                    closeFollowUpModal={(e) => closeFollowUpModal(setState)}
                    updateFollowUp={(e) => updateFollowUp(e, contextState, state, setState)}
                    deleteFollowUp={(e) => deleteFollowUp(e, contextState, state, setState)}
                    updateFollowUpStatus={(e) => updateFollowUpStatus(e, contextState, state, setState)}
                />
            }
            {state.isEmailModalOpen &&
                <EmailModal
                    state={state}
                    closeEmailModal={(e) => closeEmailModal(e, setState)}
                    changeHandler={changeHandler}
                />
            }
        </section>
    )
}

export default AssignedLeadsCalls