import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Snackbar, Alert } from "@mui/material";

const Message = ({
  snackBarColor,
  snackMessage,
  open,
  handleCloseSnackbar = () => {},
}) => {
  // const [open, setOpen] = useState(true);

  // openSnackbar, barColor, message

  const handleSnackbar = () => {
    handleCloseSnackbar();
  };

  // setTimeout(() => {
  //   handleCloseSnackbar();
  // }, 5000);

  return (
    <Snackbar
      className="snackbar-container"
      open={open}
      autoHideDuration={5000}
      onClose={handleSnackbar}
    >
      <Alert
        onClose={handleSnackbar}
        style={{ fontWeight: "bold", fontSize: "16px" }}
        severity={snackBarColor}
      >
        {snackMessage}
      </Alert>
    </Snackbar>
  );
};

const AlertPopup = (props) => {
  const backdropRoot = document.getElementById("workout__modal-backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const workoutModel = document.getElementById("workout__modal-root");
  if (!workoutModel) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Message
          closeModel={props.closeModel}
          snackMessage={props.snackMessage}
          snackBarColor={props.snackBarColor}
          open={props.open}
          handleCloseSnackbar={props.handleCloseSnackbar}
        />,
        workoutModel
      )}
    </>
  );
};

export default AlertPopup;
