import React, { useContext, useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { GrAddCircle } from "react-icons/gr";
import stateContext from "../../context/StateContext";
import Popupmodel from "../Leads/Popupmodel";
import { HiPencilAlt } from "react-icons/hi";

function GridTableClientPlans(props) {
  
 
  const [childFoodId, setChildFoodId] = useState();
  const [childFoodUnitId, setchildFoodUnitId] = useState(null);



  // const total=props?.dietGrid[props.id]?.newTableRow?.foodInTable?.reduce((acc,foodDetail)=>{
  //   acc.carbs += parseFloat(foodDetail.carbs)

  //   acc.protein += parseFloat(foodDetail.protein)

  //   acc.fats += parseFloat(foodDetail.fats)

  //   acc.fibre += parseFloat(foodDetail.fibre)

  //   acc.calories += parseFloat(foodDetail.calories)

  // },{carbs: 0, protein: 0, fats: 0, fibre: 0, calories:0})

  const total1 =
    props?.dietGrid[props.id]?.newTableRow?.foodInTable?.length >= 1 &&
    props?.dietGrid[props.id]?.newTableRow?.foodInTable?.reduce(
      (acc, foodDetail) => {
       
        // acc.carbs +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.carbs) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.carbs) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.carbs).toFixed(3))
        //         : "";

        // acc.protein +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.protein) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.protein) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.protein).toFixed(3))
        //         : "";

        // acc.fats +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.fats) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.fats) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.fats).toFixed(3))
        //         : "";

        // acc.fibre +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.fibre) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.fibre) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.fibre).toFixed(3))
        //         : "";

        // acc.calories +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.calories) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.calories) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.calories).toFixed(3))
        //         : "";

        // return acc;
        acc.carbs += parseFloat(foodDetail?.carbs?.toFixed(3));
        acc.fats += parseFloat(foodDetail?.fats?.toFixed(3));
        acc.protein += parseFloat(foodDetail?.protein?.toFixed(3));
        acc.calories += parseFloat(foodDetail?.calories?.toFixed(3));
        acc.fibre += parseFloat(foodDetail?.fibre?.toFixed(3));

        return acc;
      },
      { carbs: 0, protein: 0, fats: 0, fibre: 0, calories: 0 }
    );

  const { carbs, protein, fats, fibre, calories } =
    props?.data?.newTableRow?.foodInTable?.length >= 1 &&
    props?.data?.newTableRow?.foodInTable?.reduce(
      (acc, foodDetail) => {
        // acc.carbs +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.carbs) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.carbs) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.carbs).toFixed(3))
        //         : "";

        // acc.protein +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.protein) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.protein) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.protein).toFixed(3))
        //         : "";

        // acc.fats +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.fats) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.fats) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.fats).toFixed(3))
        //         : "";

        // acc.fibre +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.fibre) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.fibre) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.fibre).toFixed(3))
        //         : "";

        // acc.calories +=
        //   foodDetail.unit === "gms"
        //     ? parseFloat(
        //       ((foodDetail.quantity * foodDetail.calories) / 100).toFixed(3)
        //     )
        //     : foodDetail.unit === "ml"
        //       ? parseFloat(
        //         ((foodDetail.quantity * foodDetail.calories) / 100).toFixed(3)
        //       )
        //       : foodDetail.unit === "cups"
        //         ? parseFloat((foodDetail.quantity * foodDetail.calories).toFixed(3))
        //         : "";

        // return acc;

        acc.carbs += Number(parseFloat(foodDetail?.carbs).toFixed(3));
        acc.fats += parseFloat(foodDetail?.fats?.toFixed(3));
        acc.protein += parseFloat(foodDetail?.protein?.toFixed(3));
        acc.calories += parseFloat(foodDetail?.calories?.toFixed(3));
        acc.fibre += parseFloat(foodDetail?.fibre?.toFixed(3));

        return acc;
      },
      { carbs: 0, protein: 0, fats: 0, fibre: 0, calories: 0 }
    );

  useEffect(() => {
  

    props.setTakedata({ carbs, protein, fats, fibre, calories });
    props.setDietGrid((prevState) => {
      if (
        props.dietGrid[props.id].newTableRow.id ==
        prevState[props.id].newTableRow.id
      ) {
        prevState[props.id].newTableRow.total = [total1];
        // prevState[props.id].newTableRow.foodInTable=props?.dietGrid[props.id]?.newTableRow?.foodInTable?.map((data)=>{
        //   if(data.calories){

        //     data.calories=calories
        //   }
        //   return data
        // })
      }
      return prevState;
    });
  }, [carbs, calories, fats, protein, fibre, props.data]);

  // const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0);
 

  /* delete Food Row */

  const [deleteId, setDeleteId] = useState();
  const deleteFoodRow = async (id1) => {
   
    setDeleteId(id1);

    let newGrid = await props?.dietGrid[
      props.id
    ]?.newTableRow?.foodInTable?.filter((c, i) => i !== id1);
 
    // props.setDietGrid([{newTableRow:{foodInTable:newGrid}}])
    await props.setDietGrid((prevState) => {
      if (
        props?.dietGrid[props.id]?.newTableRow?.foodInTable ==
        prevState[props.id]?.newTableRow?.foodInTable
      ) {
        prevState[props.id].newTableRow.foodInTable = newGrid;
      }

      return prevState;
    });
  };

  const childItem = () => { };


  /* Delete Child Row */

  const deletechildFoodRow = async (obj, idx) => {
 
    setChildFoodId(obj?.id);

    var newChildFoodItem = props.dietGrid[props.id].newTableRow.foodInTable[
      idx
    ]?.childFoodItems.filter((item) => item.id !== obj?.id);
    await props.setDietGrid((prevState) => {
      prevState[props.id].newTableRow.foodInTable[idx]?.childFoodItems?.length>0 &&(prevState[props.id].newTableRow.foodInTable[idx].childFoodItems=newChildFoodItem)
      // var existingChildFood =  prevState[props.id].newTableRow.foodInTable[idx]?.childFoodItems
      // existingChildFood =
      //   newChildFoodItem;
      return prevState;
    });
  };
  // useEffect(() => {
  //   deletechildFoodRow();
  // }, [childFoodId]);

  useEffect(() => {
    deleteFoodRow();
  }, [deleteId]);

  /*  edit Food Items */
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(true);
  const [showData, setShowData] = useState(false);
  const [showData1, setShowData1] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [storeId, setstoreId] = useState();
 
  const editFoodItems = (data, id) => {
    
    // setShow1(true)
    // setShow2(true)
    // props.editItems(show2)
   
    setShowData(true);
    setShowData1(true);
    setstoreId(id);
    // <select >
    //                 <option>Select Quantity</option>
    //                {Array.apply(null, {length: 100}).map((value, index) =><option value={data.quantity}>{index + 1}</option> )}
    //               </select>
  };

  /* Edit Child Food Items */

  const editChildFood = (obj) => {
    setSelectedItem(obj.id);
  };

  const updateItem = (e, type, foodIdx, itemIdx) => {
    const { value } = e.target;


   
    if(type==='quantity'){
      
      props?.setDietGrid((prevState) => {
        props.dietGrid[props.id].newTableRow.foodInTable
          .find((food, idx) => idx === foodIdx)
          .childFoodItems.find((item, idx) => idx === itemIdx)[type] =
          parseInt(e.target.value);
  
        return prevState;
      });
    }
    else{
      props?.setDietGrid((prevState) => {
        props.dietGrid[props.id].newTableRow.foodInTable
          .find((food, idx) => idx === foodIdx)
          .childFoodItems.find((item, idx) => idx === itemIdx)[type] =
          e.target.value
  
        return prevState;
      });
    }
    setSelectedItem("");
  };
  const [updateQuan, setUpdateQuan] = useState();
  const updatedQuantity = (e) => {
    // setUpdateQuan(e.target.value)
   
    props.setQuantity(e.target.value);
    // if(id==storeId){

    //   props.dietGrid[storeId].newTableRow.foodInTable[storeId].quantity=props.quantity
    // }
    // if(storeId){

    

    props?.setDietGrid((prevState) => {
     
      if (
        props?.dietGrid[props.id]?.newTableRow?.foodInTable ==
        prevState[props.id]?.newTableRow?.foodInTable
      ) {
        prevState[props.id].newTableRow.foodInTable = props?.dietGrid[
          props.id
        ]?.newTableRow?.foodInTable.map((val, index) => {
       
          if (index == storeId) {
            // if(val.unit==='Small Cup'){
           
            //   val.calories=(parseInt(e.target.value)*100*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*100*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*100*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*100*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*100*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*100
            //   val.CALCIUM_mg=(parseInt(e.target.value)*100*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*100*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*100*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*100*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*100*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*100*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*100*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*100*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*100*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*100*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*100*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*100*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*100*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*100*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*100*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*100*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*100*val.VITAMIN_K_mg)/val.defaultValue

            // }
            // if(val.unit==='Medium Cup'){
            //   val.calories=(parseInt(e.target.value)*200*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*200*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*200*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*200*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*200*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*200
            //   val.CALCIUM_mg=(parseInt(e.target.value)*200*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*200*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*200*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*200*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*200*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*200*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*200*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*200*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*200*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*200*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*200*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*200*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*200*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*200*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*200*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*200*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*200*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(val.unit==='Large Cup'){
            //   val.calories=(parseInt(e.target.value)*350*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*350*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*350*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*350*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*350*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*350
            //   val.CALCIUM_mg=(parseInt(e.target.value)*350*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*350*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*350*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*350*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*350*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*350*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*350*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*350*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*350*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*350*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*350*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*350*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*350*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*350*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*350*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*350*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*350*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(val.unit==='Small Piece'){
            //   val.calories=(parseInt(e.target.value)*35*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*35*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*35*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*35*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*35*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*35
            //   val.CALCIUM_mg=(parseInt(e.target.value)*35*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*35*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*35*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*35*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*35*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*35*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*35*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*35*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*35*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*35*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*35*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*35*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*35*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*35*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*35*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*35*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*35*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(val.unit==='Medium Piece'){
            //   val.calories=(parseInt(e.target.value)*70*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*70*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*70*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*70*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*70*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*70
            //   val.CALCIUM_mg=(parseInt(e.target.value)*70*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*70*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*70*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*70*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*70*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*70*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*70*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*70*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*70*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*70*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*70*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*70*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*70*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*70*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*70*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*70*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*70*val.VITAMIN_K_mg)/val.defaultValue

            // }
            // if(val.unit==='Large Piece'){
            //   val.calories=(parseInt(e.target.value)*100*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*100*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*100*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*100*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*100*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*100
            //   val.CALCIUM_mg=(parseInt(e.target.value)*100*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*100*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*100*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*100*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*100*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*100*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*100*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*100*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*100*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*100*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*100*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*100*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*100*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*100*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*100*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*100*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*100*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(val.unit==='Small Glass'){
            //   val.calories=(parseInt(e.target.value)*100*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*100*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*100*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*100*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*100*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*100
            //   val.CALCIUM_mg=(parseInt(e.target.value)*100*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*100*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*100*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*100*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*100*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*100*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*100*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*100*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*100*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*100*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*100*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*100*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*100*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*100*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*100*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*100*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*100*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(val.unit==='Medium Glass'){
            //   val.calories=(parseInt(e.target.value)*250*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*250*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*250*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*250*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*250*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*250
            //   val.CALCIUM_mg=(parseInt(e.target.value)*250*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*250*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*250*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*250*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*250*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*250*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*250*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*250*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*250*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*250*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*250*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*250*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*250*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*250*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*250*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*250*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*250*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(val.unit==='Large Glass'){
            //   val.calories=(parseInt(e.target.value)*350*val.calories)/val.defaultValue
            //   val.carbs=(parseInt(e.target.value)*350*val.carbs)/val.defaultValue
            //   val.fats=(parseInt(e.target.value)*350*val.fats)/val.defaultValue
            //   val.protein=(parseInt(e.target.value)*350*val.protein)/val.defaultValue
            //   val.fibre=(parseInt(e.target.value)*350*val.fibre)/val.defaultValue
            //   val.defaultValue=parseInt(e.target.value)*350
            //   val.CALCIUM_mg=(parseInt(e.target.value)*350*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*350*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(parseInt(e.target.value)*350*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*350*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(parseInt(e.target.value)*350*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(parseInt(e.target.value)*350*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*350*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*350*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*350*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(parseInt(e.target.value)*350*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(parseInt(e.target.value)*350*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*350*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*350*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*350*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*350*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(parseInt(e.target.value)*350*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*350*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(val.unit==='gms' || val.unit==='ml' ){
              
            //   val.calories=((parseInt(e.target.value)*val.calories)/parseInt(val.quantity))
            //   val.carbs=(parseInt(e.target.value)*val.carbs)/parseInt(val.quantity)
            //   val.fats=(parseInt(e.target.value)*val.fats)/parseInt(val.quantity)
            //   val.protein=(parseInt(e.target.value)*val.protein)/parseInt(val.quantity)
            //   val.fibre=(parseInt(e.target.value)*val.fibre)/parseInt(val.quantity)
            //   val.defaultValue=parseInt(e.target.value)
            //   val.CALCIUM_mg=(parseInt(e.target.value)*val.CALCIUM_mg)/val.quantity
            //   val.FOLIC_ACID_mg=(parseInt(e.target.value)*val.FOLIC_ACID_mg)/val.quantity
            //   val.IRON_mg=(parseInt(e.target.value)*val.IRON_mg)/val.quantity
            //   val.MAGNESIUM_mg=(parseInt(e.target.value)*val.MAGNESIUM_mg)/val.quantity
            //   val.MANGANESE_mg=(parseInt(e.target.value)*val.MANGANESE_mg)/val.quantity
            //   val.NIACIN_mg=(parseInt(e.target.value)*val.NIACIN_mg)/val.quantity
            //   val.PHOSPHORUS_mg=(parseInt(e.target.value)*val.PHOSPHORUS_mg)/val.quantity
            //   val.POTASSIUM_mg=(parseInt(e.target.value)*val.POTASSIUM_mg)/val.quantity
            //   val.RIBOFLAVIN_mg=(parseInt(e.target.value)*val.RIBOFLAVIN_mg)/val.quantity
            //   val.SELENIUM_mg=(parseInt(e.target.value)*val.SELENIUM_mg)/val.quantity
            //   val.THIAMINE_mg=(parseInt(e.target.value)*val.THIAMINE_mg)/val.quantity
            //   val.VITAMIN_A_mg=(parseInt(e.target.value)*val.VITAMIN_A_mg)/val.quantity
            //   val.VITAMIN_C_mg=(parseInt(e.target.value)*val.VITAMIN_C_mg)/val.quantity
            //   val.VITAMIN_D_mg=(parseInt(e.target.value)*val.VITAMIN_D_mg)/val.quantity
            //   val.VITAMIN_E_mg=(parseInt(e.target.value)*val.VITAMIN_E_mg)/val.quantity
            //   val.ZINC_mg=(parseInt(e.target.value)*val.ZINC_mg)/val.quantity
            //   val.VITAMIN_K_mg=(parseInt(e.target.value)*val.VITAMIN_K_mg)/val.quantity
            // }
              val.calories=(parseInt(e.target.value)*val.calories)/val.quantity
              val.carbs=(parseInt(e.target.value)*val.carbs)/val.quantity
              val.fats=(parseInt(e.target.value)*val.fats)/val.quantity
              val.protein=(parseInt(e.target.value)*val.protein)/val.quantity
              val.fibre=(parseInt(e.target.value)*val.fibre)/val.quantity
              // val.quantity=parseInt(e.target.value)
              val.CALCIUM_mg=(parseInt(e.target.value)*val.CALCIUM_mg)/val.quantity
              val.FOLIC_ACID_mg=(parseInt(e.target.value)*val.FOLIC_ACID_mg)/val.quantity
              val.IRON_mg=(parseInt(e.target.value)*val.IRON_mg)/val.quantity
              val.MAGNESIUM_mg=(parseInt(e.target.value)*val.MAGNESIUM_mg)/val.quantity
              val.MANGANESE_mg=(parseInt(e.target.value)*val.MANGANESE_mg)/val.quantity
              val.NIACIN_mg=(parseInt(e.target.value)*val.NIACIN_mg)/val.quantity
              val.PHOSPHORUS_mg=(parseInt(e.target.value)*val.PHOSPHORUS_mg)/val.quantity
              val.POTASSIUM_mg=(parseInt(e.target.value)*val.POTASSIUM_mg)/val.quantity
              val.RIBOFLAVIN_mg=(parseInt(e.target.value)*val.RIBOFLAVIN_mg)/val.quantity
              val.SELENIUM_mg=(parseInt(e.target.value)*val.SELENIUM_mg)/val.quantity
              val.THIAMINE_mg=(parseInt(e.target.value)*val.THIAMINE_mg)/val.quantity
              val.VITAMIN_A_mg=(parseInt(e.target.value)*val.VITAMIN_A_mg)/val.quantity
              val.VITAMIN_C_mg=(parseInt(e.target.value)*val.VITAMIN_C_mg)/val.quantity
              val.VITAMIN_D_mg=(parseInt(e.target.value)*val.VITAMIN_D_mg)/val.quantity
              val.VITAMIN_E_mg=(parseInt(e.target.value)*val.VITAMIN_E_mg)/val.quantity
              val.ZINC_mg=(parseInt(e.target.value)*val.ZINC_mg)/val.quantity
              val.VITAMIN_K_mg=(parseInt(e.target.value)*val.VITAMIN_K_mg)/val.quantity
          
            val.quantity = parseInt(e.target.value);
          }
          return val;
        });

        return prevState;
      }
    });

    // }
    setShowData(false);
  };

  /* Edit Child Food Quantity */

  const updateChildFoodQuantity = (e) => {
    props.setDietGrid((prevState) => {
      if (
        props?.dietGrid[props.id]?.newTableRow?.id ==
        prevState[props.id]?.newTableRow?.id
      ) {
        prevState[props.id].newTableRow.foodInTable = prevState[
          props.id
        ].newTableRow.foodInTable.map((val, ind) => {
          val?.childFoodItems?.map((item, ind) => {
            if (ind == childFoodUnitId) {

              item.quantity = parseInt(e.target.value);
            }
            return item;
          });
          return val;
        });
      }

      return prevState;
    });
  };



  const editUnit = (e) => {
    props?.setDietGrid((prevState) => {
   
      if (
        props?.dietGrid[props.id]?.newTableRow?.foodInTable ==
        prevState[props.id]?.newTableRow?.foodInTable
      ) {
        prevState[props.id].newTableRow.foodInTable = props?.dietGrid[
          props.id
        ]?.newTableRow?.foodInTable.map((val, index) => {
          
          if (index == storeId) {
            
            let obj={
              'Small Cup':100,
              'Medium Cup':200,
              'Large Cup':350,
              'Small Piece':35,
              'Medium Piece':70,
              'Large Piece':100,
              'Small Glass':100,
              'Medium Glass':250,
              'Large Glass':350,
              'gms':1,
              'ml':1
            }
              val.calories=(obj[e.target.value]*val.calories)/val.defaultValue
              val.carbs=(obj[e.target.value]*val.carbs)/val.defaultValue
              val.fats=(obj[e.target.value]*val.fats)/val.defaultValue
              val.protein=(obj[e.target.value]*val.protein)/val.defaultValue
              val.fibre=(obj[e.target.value]*val.fibre)/val.defaultValue
              val.CALCIUM_mg=(obj[e.target.value]*val.CALCIUM_mg)/val.defaultValue
              val.FOLIC_ACID_mg=(obj[e.target.value]*val.FOLIC_ACID_mg)/val.defaultValue
              val.IRON_mg=(obj[e.target.value]*val.IRON_mg)/val.defaultValue
              val.MAGNESIUM_mg=(obj[e.target.value]*val.MAGNESIUM_mg)/val.defaultValue
              val.MANGANESE_mg=(obj[e.target.value]*val.MANGANESE_mg)/val.defaultValue
              val.NIACIN_mg=(obj[e.target.value]*val.NIACIN_mg)/val.defaultValue
              val.PHOSPHORUS_mg=(obj[e.target.value]*val.PHOSPHORUS_mg)/val.defaultValue
              val.POTASSIUM_mg=(obj[e.target.value]*val.POTASSIUM_mg)/val.defaultValue
              val.RIBOFLAVIN_mg=(obj[e.target.value]*val.RIBOFLAVIN_mg)/val.defaultValue
              val.SELENIUM_mg=(obj[e.target.value]*val.SELENIUM_mg)/val.defaultValue
              val.THIAMINE_mg=(obj[e.target.value]*val.THIAMINE_mg)/val.defaultValue
              val.VITAMIN_A_mg=(obj[e.target.value]*val.VITAMIN_A_mg)/val.defaultValue
              val.VITAMIN_C_mg=(obj[e.target.value]*val.VITAMIN_C_mg)/val.defaultValue
              val.VITAMIN_D_mg=(obj[e.target.value]*val.VITAMIN_D_mg)/val.defaultValue
              val.VITAMIN_E_mg=(obj[e.target.value]*val.VITAMIN_E_mg)/val.defaultValue
              val.ZINC_mg=(obj[e.target.value]*val.ZINC_mg)/val.defaultValue
              val.VITAMIN_K_mg=(obj[e.target.value]*val.VITAMIN_K_mg)/val.defaultValue
              val.defaultValue=obj[e.target.value]














            // if(e.target.value==='Small Cup'){
            
            //   val.calories=(val.quantity*100*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*100*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*100*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*100*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*100*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*100
            //   val.CALCIUM_mg=(val.quantity*100*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*100*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*100*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*100*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*100*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*100*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*100*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*100*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*100*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*100*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*100*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*100*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*100*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*100*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*100*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*100*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*100*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(e.target.value==='Medium Cup'){

            //   val.calories=(val.quantity*200*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*200*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*200*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*200*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*200*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*200
            //   val.CALCIUM_mg=(val.quantity*200*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*200*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*200*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*200*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*200*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*200*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*200*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*200*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*200*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*200*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*200*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*200*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*200*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*200*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*200*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*200*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*200*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(e.target.value==='Large Cup'){

            //   val.calories=(val.quantity*350*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*350*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*350*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*350*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*350*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*350
            //   val.CALCIUM_mg=(val.quantity*350*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*350*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*350*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*350*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*350*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*350*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*350*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*350*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*350*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*350*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*350*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*350*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*350*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*350*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*350*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*350*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*350*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(e.target.value==='Small Piece'){
            //   val.calories=(val.quantity*35*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*35*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*35*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*35*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*35*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*35
            //   val.CALCIUM_mg=(val.quantity*35*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*35*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*35*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*35*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*35*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*35*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*35*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*35*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*35*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*35*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*35*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*35*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*35*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*35*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*35*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*35*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*35*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(e.target.value==='Medium Piece'){
            //   val.calories=(val.quantity*70*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*70*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*70*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*70*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*70*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*70
            //   val.CALCIUM_mg=(val.quantity*70*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*70*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*70*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*70*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*70*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*70*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*70*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*70*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*70*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*70*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*70*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*70*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*70*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*70*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*70*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*70*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*70*val.VITAMIN_K_mg)/val.defaultValue

            // }
            // if(e.target.value==='Large Piece'){
            //   val.calories=(val.quantity*100*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*100*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*100*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*100*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*100*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*100
            //   val.CALCIUM_mg=(val.quantity*100*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*100*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*100*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*100*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*100*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*100*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*100*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*100*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*100*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*100*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*100*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*100*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*100*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*100*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*100*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*100*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*100*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(e.target.value==='Small Glass'){
            //   val.calories=(val.quantity*100*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*100*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*100*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*100*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*100*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*100
            //   val.CALCIUM_mg=(val.quantity*100*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*100*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*100*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*100*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*100*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*100*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*100*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*100*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*100*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*100*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*100*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*100*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*100*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*100*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*100*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*100*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*100*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(e.target.value==='Medium Glass'){
            //   val.calories=(val.quantity*250*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*250*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*250*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*250*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*250*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*250
            //   val.CALCIUM_mg=(val.quantity*250*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*250*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*250*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*250*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*250*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*250*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*250*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*250*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*250*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*250*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*250*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*250*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*250*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*250*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*250*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*250*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*250*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(e.target.value==='Large Glass'){
            //   val.calories=(val.quantity*350*val.calories)/val.defaultValue
            //   val.carbs=(val.quantity*350*val.carbs)/val.defaultValue
            //   val.fats=(val.quantity*350*val.fats)/val.defaultValue
            //   val.protein=(val.quantity*350*val.protein)/val.defaultValue
            //   val.fibre=(val.quantity*350*val.fibre)/val.defaultValue
            //   val.defaultValue=val.quantity*350
            //   val.CALCIUM_mg=(val.quantity*350*val.CALCIUM_mg)/val.defaultValue
            //   val.FOLIC_ACID_mg=(val.quantity*350*val.FOLIC_ACID_mg)/val.defaultValue
            //   val.IRON_mg=(val.quantity*350*val.IRON_mg)/val.defaultValue
            //   val.MAGNESIUM_mg=(val.quantity*350*val.MAGNESIUM_mg)/val.defaultValue
            //   val.MANGANESE_mg=(val.quantity*350*val.MANGANESE_mg)/val.defaultValue
            //   val.NIACIN_mg=(val.quantity*350*val.NIACIN_mg)/val.defaultValue
            //   val.PHOSPHORUS_mg=(val.quantity*350*val.PHOSPHORUS_mg)/val.defaultValue
            //   val.POTASSIUM_mg=(val.quantity*350*val.POTASSIUM_mg)/val.defaultValue
            //   val.RIBOFLAVIN_mg=(val.quantity*350*val.RIBOFLAVIN_mg)/val.defaultValue
            //   val.SELENIUM_mg=(val.quantity*350*val.SELENIUM_mg)/val.defaultValue
            //   val.THIAMINE_mg=(val.quantity*350*val.THIAMINE_mg)/val.defaultValue
            //   val.VITAMIN_A_mg=(val.quantity*350*val.VITAMIN_A_mg)/val.defaultValue
            //   val.VITAMIN_C_mg=(val.quantity*350*val.VITAMIN_C_mg)/val.defaultValue
            //   val.VITAMIN_D_mg=(val.quantity*350*val.VITAMIN_D_mg)/val.defaultValue
            //   val.VITAMIN_E_mg=(val.quantity*350*val.VITAMIN_E_mg)/val.defaultValue
            //   val.ZINC_mg=(val.quantity*350*val.ZINC_mg)/val.defaultValue
            //   val.VITAMIN_K_mg=(val.quantity*350*val.VITAMIN_K_mg)/val.defaultValue
            // }
            // if(e.target.value==='gms' || e.target.value==='ml' ){
            //   if(val.unit==='Small Cup'){
            //     val.calories=Number((val.calories/100).toFixed(3))
            //     val.carbs=Number((val.carbs/100).toFixed(3))
            //     val.fats=Number((val.fats/100).toFixed(3))
            //     val.protein=Number((val.protein/100).toFixed(3))
            //     val.fibre=Number((val.fibre/100).toFixed(3))
            //     val.defaultValue=val.quantity
            //     val.CALCIUM_mg=val.quantity/100
            //   val.FOLIC_ACID_mg=val.quantity/100
            //   val.IRON_mg=val.quantity/100
            //   val.MAGNESIUM_mg=val.quantity/100
            //   val.MANGANESE_mg=val.quantity/100
            //   val.NIACIN_mg=val.quantity/100
            //   val.PHOSPHORUS_mg=val.quantity/100
            //   val.POTASSIUM_mg=val.quantity/100
            //   val.RIBOFLAVIN_mg=val.quantity/100
            //   val.SELENIUM_mg=val.quantity/100
            //   val.THIAMINE_mg=val.quantity/100
            //   val.VITAMIN_A_mg=val.quantity/100
            //   val.VITAMIN_C_mg=val.quantity/100
            //   val.VITAMIN_D_mg=val.quantity/100
            //   val.VITAMIN_E_mg=val.quantity/100
            //   val.ZINC_mg=val.quantity/100
            //   val.VITAMIN_K_mg=val.quantity/100
            //   }
            //   if(val.unit==='Medium Cup'){
            //     val.calories=Number((val.calories/200).toFixed(3))
            //     val.carbs=Number((val.carbs/200).toFixed(3))
            //     val.fats=Number((val.fats/200).toFixed(3))
            //     val.protein=Number((val.protein/200).toFixed(3))
            //     val.fibre=Number((val.fibre/200).toFixed(3))
            //     val.defaultValue=(val.quantity)
            //     val.CALCIUM_mg=val.quantity/200
            //     val.FOLIC_ACID_mg=val.quantity/200
            //     val.IRON_mg=val.quantity/200
            //     val.MAGNESIUM_mg=val.quantity/200
            //     val.MANGANESE_mg=val.quantity/200
            //     val.NIACIN_mg=val.quantity/200
            //     val.PHOSPHORUS_mg=val.quantity/200
            //     val.POTASSIUM_mg=val.quantity/200
            //     val.RIBOFLAVIN_mg=val.quantity/200
            //     val.SELENIUM_mg=val.quantity/200
            //     val.THIAMINE_mg=val.quantity/200
            //     val.VITAMIN_A_mg=val.quantity/200
            //     val.VITAMIN_C_mg=val.quantity/200
            //     val.VITAMIN_D_mg=val.quantity/200
            //     val.VITAMIN_E_mg=val.quantity/200
            //     val.ZINC_mg=val.quantity/200
            //     val.VITAMIN_K_mg=val.quantity/200
            //   }
            //   if(val.unit==='Large Cup'){
            //     val.calories=Number((val.calories/350).toFixed(3))
            //     val.carbs=Number((val.carbs/350).toFixed(3))
            //     val.fats=Number((val.fats/350).toFixed(3))
            //     val.protein=Number((val.protein/350).toFixed(3))
            //     val.fibre=Number((val.fibre/350).toFixed(3))
            //     val.defaultValue=val.quantity
            //     val.CALCIUM_mg=val.quantity/350
            //     val.FOLIC_ACID_mg=val.quantity/350
            //     val.IRON_mg=val.quantity/350
            //     val.MAGNESIUM_mg=val.quantity/350
            //     val.MANGANESE_mg=val.quantity/350
            //     val.NIACIN_mg=val.quantity/350
            //     val.PHOSPHORUS_mg=val.quantity/350
            //     val.POTASSIUM_mg=val.quantity/350
            //     val.RIBOFLAVIN_mg=val.quantity/350
            //     val.SELENIUM_mg=val.quantity/350
            //     val.THIAMINE_mg=val.quantity/350
            //     val.VITAMIN_A_mg=val.quantity/350
            //     val.VITAMIN_C_mg=val.quantity/350
            //     val.VITAMIN_D_mg=val.quantity/350
            //     val.VITAMIN_E_mg=val.quantity/350
            //     val.ZINC_mg=val.quantity/350
            //     val.VITAMIN_K_mg=val.quantity/350
            //   }
            //   if(val.unit==='Small Piece'){
            //     val.calories=Number((val.calories/35).toFixed(3))
            //     val.carbs=Number((val.carbs/35).toFixed(3))
            //     val.fats=Number((val.fats/35).toFixed(3))
            //     val.protein=Number((val.protein/35).toFixed(3))
            //     val.fibre=Number((val.fibre/35).toFixed(3))
            //     val.defaultValue=val.quantity
            //     val.CALCIUM_mg=val.quantity/35
            //     val.FOLIC_ACID_mg=val.quantity/35
            //     val.IRON_mg=val.quantity/35
            //     val.MAGNESIUM_mg=val.quantity/35
            //     val.MANGANESE_mg=val.quantity/35
            //     val.NIACIN_mg=val.quantity/35
            //     val.PHOSPHORUS_mg=val.quantity/35
            //     val.POTASSIUM_mg=val.quantity/35
            //     val.RIBOFLAVIN_mg=val.quantity/35
            //     val.SELENIUM_mg=val.quantity/35
            //     val.THIAMINE_mg=val.quantity/35
            //     val.VITAMIN_A_mg=val.quantity/35
            //     val.VITAMIN_C_mg=val.quantity/35
            //     val.VITAMIN_D_mg=val.quantity/35
            //     val.VITAMIN_E_mg=val.quantity/35
            //     val.ZINC_mg=val.quantity/35
            //     val.VITAMIN_K_mg=val.quantity/35
            //   }
            //   if(val.unit==='Medium Piece'){
            //     val.calories=val.calories/70
            //     val.carbs=val.carbs/70
            //     val.fats=val.fats/70
            //     val.protein=val.protein/70
            //     val.fibre=val.fibre/70
            //     val.defaultValue=val.quantity
            //     val.CALCIUM_mg=val.quantity/70
            //     val.FOLIC_ACID_mg=val.quantity/70
            //     val.IRON_mg=val.quantity/70
            //     val.MAGNESIUM_mg=val.quantity/70
            //     val.MANGANESE_mg=val.quantity/70
            //     val.NIACIN_mg=val.quantity/70
            //     val.PHOSPHORUS_mg=val.quantity/70
            //     val.POTASSIUM_mg=val.quantity/70
            //     val.RIBOFLAVIN_mg=val.quantity/70
            //     val.SELENIUM_mg=val.quantity/70
            //     val.THIAMINE_mg=val.quantity/70
            //     val.VITAMIN_A_mg=val.quantity/70
            //     val.VITAMIN_C_mg=val.quantity/70
            //     val.VITAMIN_D_mg=val.quantity/70
            //     val.VITAMIN_E_mg=val.quantity/70
            //     val.ZINC_mg=val.quantity/70
            //     val.VITAMIN_K_mg=val.quantity/70
            //   }
            //   if(val.unit==='Large Piece'){
            //     val.calories=val.calories/100
            //     val.carbs=val.carbs/100
            //     val.fats=val.fats/100
            //     val.protein=val.protein/100
            //     val.fibre=val.fibre/100
            //     val.defaultValue=val.quantity
            //     val.CALCIUM_mg=val.quantity/100
            //     val.FOLIC_ACID_mg=val.quantity/100
            //     val.IRON_mg=val.quantity/100
            //     val.MAGNESIUM_mg=val.quantity/100
            //     val.MANGANESE_mg=val.quantity/100
            //     val.NIACIN_mg=val.quantity/100
            //     val.PHOSPHORUS_mg=val.quantity/100
            //     val.POTASSIUM_mg=val.quantity/100
            //     val.RIBOFLAVIN_mg=val.quantity/100
            //     val.SELENIUM_mg=val.quantity/100
            //     val.THIAMINE_mg=val.quantity/100
            //     val.VITAMIN_A_mg=val.quantity/100
            //     val.VITAMIN_C_mg=val.quantity/100
            //     val.VITAMIN_D_mg=val.quantity/100
            //     val.VITAMIN_E_mg=val.quantity/100
            //     val.ZINC_mg=val.quantity/100
            //     val.VITAMIN_K_mg=val.quantity/100
            //   }
            //   if(val.unit==='Small Glass'){
            //     val.calories=val.calories/100
            //     val.carbs=val.carbs/100
            //     val.fats=val.fats/100
            //     val.protein=val.protein/100
            //     val.fibre=val.fibre/100
            //     val.defaultValue=val.quantity
            //     val.CALCIUM_mg=val.quantity/100
            //     val.FOLIC_ACID_mg=val.quantity/100
            //     val.IRON_mg=val.quantity/100
            //     val.MAGNESIUM_mg=val.quantity/100
            //     val.MANGANESE_mg=val.quantity/100
            //     val.NIACIN_mg=val.quantity/100
            //     val.PHOSPHORUS_mg=val.quantity/100
            //     val.POTASSIUM_mg=val.quantity/100
            //     val.RIBOFLAVIN_mg=val.quantity/100
            //     val.SELENIUM_mg=val.quantity/100
            //     val.THIAMINE_mg=val.quantity/100
            //     val.VITAMIN_A_mg=val.quantity/100
            //     val.VITAMIN_C_mg=val.quantity/100
            //     val.VITAMIN_D_mg=val.quantity/100
            //     val.VITAMIN_E_mg=val.quantity/100
            //     val.ZINC_mg=val.quantity/100
            //     val.VITAMIN_K_mg=val.quantity/100
            //   }
            //   if(val.unit==='Medium Glass'){
            //     val.calories=val.calories/250
            //     val.carbs=val.carbs/250
            //     val.fats=val.fats/250
            //     val.protein=val.protein/250
            //     val.fibre=val.fibre/250
            //     val.defaultValue=val.quantity
            //     val.CALCIUM_mg=val.quantity/250
            //     val.FOLIC_ACID_mg=val.quantity/250
            //     val.IRON_mg=val.quantity/250
            //     val.MAGNESIUM_mg=val.quantity/250
            //     val.MANGANESE_mg=val.quantity/250
            //     val.NIACIN_mg=val.quantity/250
            //     val.PHOSPHORUS_mg=val.quantity/250
            //     val.POTASSIUM_mg=val.quantity/250
            //     val.RIBOFLAVIN_mg=val.quantity/250
            //     val.SELENIUM_mg=val.quantity/250
            //     val.THIAMINE_mg=val.quantity/250
            //     val.VITAMIN_A_mg=val.quantity/250
            //     val.VITAMIN_C_mg=val.quantity/250
            //     val.VITAMIN_D_mg=val.quantity/250
            //     val.VITAMIN_E_mg=val.quantity/250
            //     val.ZINC_mg=val.quantity/250
            //     val.VITAMIN_K_mg=val.quantity/250
            //   }
            //   if(val.unit==='Large Glass'){
            //     val.calories=val.calories/350
            //     val.carbs=val.carbs/350
            //     val.fats=val.fats/350
            //     val.protein=val.protein/350
            //     val.fibre=val.fibre/350
            //     val.defaultValue=val.quantity
            //     val.CALCIUM_mg=val.quantity/350
            //     val.FOLIC_ACID_mg=val.quantity/350
            //     val.IRON_mg=val.quantity/350
            //     val.MAGNESIUM_mg=val.quantity/350
            //     val.MANGANESE_mg=val.quantity/350
            //     val.NIACIN_mg=val.quantity/350
            //     val.PHOSPHORUS_mg=val.quantity/350
            //     val.POTASSIUM_mg=val.quantity/350
            //     val.RIBOFLAVIN_mg=val.quantity/350
            //     val.SELENIUM_mg=val.quantity/350
            //     val.THIAMINE_mg=val.quantity/350
            //     val.VITAMIN_A_mg=val.quantity/350
            //     val.VITAMIN_C_mg=val.quantity/350
            //     val.VITAMIN_D_mg=val.quantity/350
            //     val.VITAMIN_E_mg=val.quantity/350
            //     val.ZINC_mg=val.quantity/350
            //     val.VITAMIN_K_mg=val.quantity/350
            //   }
            // }


            val.unit = e.target.value;
          }
          return val;
        });

        return prevState;
      }
    });
    setShowData1(false);
    // setShowData(false)
  };

  /*  Edit Child Food Unit */

  const editChildFoodUnit = (e) => {
    props?.setDietGrid((prevState) => {
      if (
        props?.dietGrid[props.id]?.newTableRow?.id ==
        prevState[props.id]?.newTableRow?.id
      ) {
        prevState[props.id].newTableRow.foodInTable = prevState[
          props.id
        ].newTableRow.foodInTable.map((val, index) => {
          val.childFoodItems.map((item, ind) => {
            if (ind == childFoodUnitId) {
              item.unit = e.target.value;
            }
            return item;
          });
          return val;
        });
      }
      return prevState;
    });
  };

  /* edit Note */

  const [noteEdit, setNoteEdit] = useState(false);
  const editNote = () => {
    setNoteEdit(true);
  };

  const [edit, setEdit] = useState("");
  const editnoteChange = (e) => {
    setEdit(e.target.value);
   
    props.setDietGrid((prevState) => {
      if (
        props.dietGrid[props.id]?.newTableRow?.foodInTable ==
        prevState[props.id]?.newTableRow?.foodInTable
      ) {
        prevState[props.id].newTableRow.note = e.target.value;
      }
      return prevState;
    });
  };
 

  // const [deleteNoteId, setDeleteNoteId] = useState()
  // const deleteNote=(id)=>{
  //   setDeleteNoteId(id)
  //   // let deleteNote=  props?.dietGrid[props.id]?.newTableRow?.note?.filter((c,i)=>i!==props.id)
  //   let deleteNote=props?.dietGrid[props.id]?.newTableRow?.note?.replace(props?.dietGrid[props.id]?.newTableRow?.note,undefined)

  //   props.setDietGrid(prevState=>{
  //     if(props.dietGrid[props.id]?.newTableRow?.foodInTable==prevState[props.id]?.newTableRow?.foodInTable){
  //       prevState[props.id].newTableRow.note=deleteNote
  //     }
  //     return prevState
  //   })
  // }

  // useEffect(() => {

  //   deleteNote()
  // }, [deleteNoteId])



  /* update Food Name */

  const updateFoodName = (e) => {
    props.setDietGrid((prevState) => {
      if (
        props.dietGrid[props.id]?.newTableRow?.foodInTable ==
        prevState[props.id]?.newTableRow?.foodInTable
      ) {
        prevState[props.id].newTableRow.foodInTable = props?.dietGrid[
          props.id
        ]?.newTableRow?.foodInTable.map((val, index) => {
          if (index == storeId) {
           
            val.food_item = e.target.value;
          }
          return val;
        });
      }
      return prevState;
    });
  };

  const [childId, setChildId] = useState();
  const addChildRow = (data, id) => {
    //  props.setChildRowId(props.id)
    props.setStoreids(props.id);
    
 
    props.setchildRow(data);
    setChildId(id);
    props.addFood(props.id);
  
   
  };

  return (
    <div>
      {show1 && <Popupmodel show1={show1} setShow1={setShow1} show2={show2} />}
      <table className="table table-bordered " style={{border:'1px solid #D0D0D0 ' ,width:'1415px'}}>
        <tbody class="thead-light">
          <tr style={{ width: "50px" }}>
            <th scope="col">Name</th>
            <th scope="col">Qty</th>
            <th scope="col">Unit</th>
            <th scope="col">Cal</th>
            <th scope="col">Pro</th>
            <th scope="col">Fats</th>
            <th scope="col">Carbs</th>
            <th scope="col">Fibre</th>
            <th scope="col">Specifications</th>
            <th scope="col">Action</th>
          </tr>

          <tr>{/* <td>{props.foodInTable.food_item_name}</td> */}</tr>
         
          {props?.data?.newTableRow?.foodInTable?.map((data, idx) => {
           
            return (
              <>
                {
                  <>
                    <tr key={idx}>
                      <td style={{ width: "200px" }}>
                        {data?.Type==='Veg'&&<img src={require('../../img/icons8-vegetarian-48.png')} alt="Vegatarian" style={{height:'20px'}}/> }
                        {data?.Type==='Non-Veg' && <img src={require('../../img/icons8-non-vegetarian-food-symbol-48.png')} alt='non-Veg' style={{height:'20px'}}/>}
                        &nbsp; &nbsp; {data.food_item ? data.food_item : data.food_item_name}
                      </td>
                      <td>
                        {showData & (storeId == idx) ? (
                          <select
                            value={data.quantity}
                            onChange={updatedQuantity}
                          >
                            <option>Select Quantity</option>
                            {Array.apply(null, { length: 1000 }).map(
                              (value, index) => (
                                <option value={index + 1}>{index + 1}</option>
                              )
                            )}
                          </select>
                        ) : (
                          data.quantity
                        )}
                      </td>
                      <td>
                        {showData1 & (storeId == idx) ? (
                          <select value={data.unit} onChange={editUnit}>
                             {
                               data.Category==='Solid Food'?
                               <>
                           
                                   <option>Select Measure</option>
                                <option value="gms">gms</option>
                                <option value="Small Cup">Small Cup</option>
                                <option value="Medium Cup">Medium Cup</option>
                                <option value="Large Cup">Large Cup</option>

                                  </>:  //Piece Food Item
                                  data.Category==='Piece Food Item'?
                                  <>
                                  <option value="gms">gms</option>
                                <option value="Small Piece">Small Piece</option>
                                <option value="Medium Piece">Medium Piece</option>
                                <option value="Large Piece">Large Piece</option>
                                  
                                  </>:
                                  data.Category==='Unit Food Item'?
                                  <>
                                  <option disabled value='' selected>Select</option>
                                  <option value={data.Units_gms_Ml_Piece}>{data.Units_gms_Ml_Piece}</option>
                             
                                  </>:
                                  data.Category==='Liquid Food'?
                                  <>
                                  <option disabled selected>Select Measure</option>
                                  <option value='ml'>ml</option>
                                  <option value="Small Glass">Small Glass</option>
                                  <option value="Medium Glass">Medium Glass</option>
                                  <option value="Large Glass">Large Glass</option>
                                  </>:
                                  <>
                                   <option>Select Measure</option>
                                  <option value="gms">gms</option>
                                  <option value="cups">cups</option>
                                  <option value="ml">ml</option>
                                  </>
                                
                                  
                              }
                            {/* <option>Select Measure</option>
                            <option value="gms">gms</option>
                            <option value="cups">cups</option>
                            <option value="ml">ml</option> */}
                          </select>
                        ) : (
                          data.unit
                        )}
                      </td>
                      <td>
                        {/* {data.unit === "gms"
                          ? ((data.quantity * data.calories) / 100).toFixed(3)
                          : data.unit === "ml"
                            ? ((data.quantity * data.calories) / 100).toFixed(3)
                            : data.unit === "cups"
                              ? (data.quantity * data.calories).toFixed(3)
                              : ""}{" "} */}
                              {data?.calories?.toFixed(3)}
                      </td>
                      <td>
                        {/* {data.unit === "gms"
                          ? ((data.quantity * data.protein) / 100).toFixed(3)
                          : data.unit === "ml"
                            ? ((data.quantity * data.protein) / 100).toFixed(3)
                            : data.unit === "cups"
                              ? (data.quantity * data.protein).toFixed(3)
                              : ""} */}
                              {data?.protein?.toFixed(3)}
                      </td>
                      <td>
                        {/* {data.unit === "gms"
                          ? ((data.quantity * data.fats) / 100).toFixed(3)
                          : data.unit === "ml"
                            ? ((data.quantity * data.fats) / 100).toFixed(3)
                            : data.unit === "cups"
                              ? (data.quantity * data.fats).toFixed(3)
                              : ""} */}
                              {data?.fats?.toFixed(3)}
                      </td>
                      <td>
                        {/* {data.unit === "gms"
                          ? ((data.quantity * data.carbs) / 100).toFixed(3)
                          : data.unit === "ml"
                            ? ((data.quantity * data.carbs) / 100).toFixed(3)
                            : data.unit === "cups"
                              ? (data.quantity * data.carbs).toFixed(3)
                              : ""} */}
                              {data?.carbs?.toFixed(3)}
                      </td>
                      <td>
                        {/* {data.unit === "gms"
                          ? ((data.quantity * data.fibre) / 100).toFixed(3)
                          : data.unit === "ml"
                            ? ((data.quantity * data.fibre) / 100).toFixed(3)
                            : data.unit === "cups"
                              ? (data.quantity * data.fibre).toFixed(3)
                              : ""} */}
                              {data?.fibre?.toFixed(3)}
                      </td>
                      <td style={{whiteSpace:'pre-wrap',wordBreak:'break-word',maxWidth:'300px'}}>{data.note}</td>


                      <td style={{width:'150px'}}>
                        <MdDeleteOutline
                          style={{
                            // backgroundColor: "red",
                            color: "red",
                            borderRadius: "50%",
                            fontSize: "25px",
                            
                          }}
                          onClick={() => deleteFoodRow(idx)}
                        />
                        &nbsp;
                        <HiPencilAlt
                          style={{
                            // backgroundColor: "blue",
                            color: "black",
                            fontSize: "25px",
                            position:'relative',
                             left:'10px',
                          }}
                          onClick={() => editFoodItems(data, idx)}
                        />
                        &nbsp;
                        &nbsp;
                         <img
                         onClick={()=>props.addNote(data,idx,props.id)}
                         title="View Your Note"
                         src={require('../../img/Notes.png')}
                         style={{
                          // backgroundColor: "white",
                          position:'relative',
                          left:'10px',
                          color: "black",
                          fontSize: "25px",
                        }}
                        
                     
                         />
                         <GrAddCircle
                          style={{
                            // backgroundColor: "green",
                            color: "green",
                            fontSize: "25px",
                            position:'relative',
                          left:'20px',
                          }}
                          onClick={() => addChildRow(data, idx)}
                        />

                      </td>
                    </tr>

                    {data?.childFoodItems?.length > 0 &&
                      data.childFoodItems?.map((d, ind) => (
                        <>
                          <tr>
                            <td
                              colSpan={10}
                              style={{ position: "relative", left: "50%" }}
                            >
                              <b>OR</b>
                            </td>
                          </tr>
                          <tr key={ind}>
                            <td>
                            {d.Type==='Veg'&&<img src={require('../../img/icons8-vegetarian-48.png')} alt="Vegatarian" style={{height:'20px'}}/> }
                        {d.Type==='Non-Veg' && <img src={require('../../img/icons8-non-vegetarian-food-symbol-48.png')} alt='non-Veg' style={{height:'20px'}}/>}
                        &nbsp; &nbsp;   {d.food_item_name?d.food_item_name:d.food_item}
                            </td>
                            <td>
                              {selectedItem === d.id ? (
                                <select
                                  value={d.quantity}
                                  onChange={(e) =>
                                    updateItem(e, "quantity", idx, ind)
                                  }
                                >
                                  <option>Select Quantity</option>
                                  {Array.apply(null, { length: 1000 }).map(
                                    (value, index) => (
                                      <option value={index + 1}>
                                        {index + 1}
                                      </option>
                                    )
                                  )}
                                </select>
                              ) : (
                                d.quantity
                              )}
                            </td>
                            <td>
                              {selectedItem === d.id ? (
                                <select
                                  value={d.unit}
                                  onChange={(e) =>
                                    updateItem(e, "unit", idx, ind)
                                  }
                                >

{
                                  d.Category==='Solid Food'?
                                  <>
                                   <option>Select Measure</option>
                                <option value="gms">gms</option>
                                <option value="Small Cup">Small Cup</option>
                                <option value="Medium Cup">Medium Cup</option>
                                <option value="Large Cup">Large Cup</option>

                                  </>:  //Piece Food Item
                                  d.Category==='Piece Food Item'?
                                  <>
                                  <option value="gms">gms</option>
                                <option value="Small Piece">Small Piece</option>
                                <option value="Medium Piece">Medium Piece</option>
                                <option value="Large Piece">Large Piece</option>
                                  
                                  </>:
                                  d.Category==='Unit Food Item'?
                                  <>
                                  <option disabled value='' selected>Select</option>
                                  <option value={d.Units_gms_Ml_Piece}>{d.Units_gms_Ml_Piece}</option>
                             
                                  </>:
                                  d.Category==='Liquid Food'?
                                  <>
                                  <option disabled selected>Select Measure</option>
                                  <option value='ml'>ml</option>
                                  <option value="Small Glass">Small Glass</option>
                                  <option value="Medium Glass">Medium Glass</option>
                                  <option value="Large Glass">Large Glass</option>
                                  </>:
                                  <>
                                   <option>Select Measure</option>
                                  <option value="gms">gms</option>
                                  <option value="cups">cups</option>
                                  <option value="ml">ml</option>
                                  </>
                                
                                  
                              }
                                  {/* <option>Select Measure</option>
                                  <option value="gms">gms</option>
                                  <option value="cups">cups</option>
                                  <option value="ml">ml</option> */}
                                </select>
                              ) : (
                                d.unit
                              )}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <MdDeleteOutline
                                style={{
                                  // backgroundColor: "red",
                                  color: "red",
                                  borderRadius: "50%",
                                  fontSize: "25px",
                                }}
                                onClick={() => deletechildFoodRow(d, idx)}
                              />
                              &nbsp;
                              <HiPencilAlt
                                style={{
                                  // backgroundColor: "blue",
                                  color: "black",
                                  fontSize: "25px",
                                  position:'relative',
                                   left:'10px',
                                }}
                                onClick={() => editChildFood(d)}
                              />
                            </td>
                          </tr>
                        </>
                      ))}
                  </>
                }
              </>
            );
          })}

          {
            // props?.data?.newTableRow?.foodInTable?.length>=1&& props?.data?.newTableRow?.foodInTable?.map((data,cal)=>{
           
            //         return(
            //             <>
            //             <tr>
            //                 <td>Total</td>
            //                 <td></td>
            //                 <td></td>
            //                 <td>{Number(data.calories)}</td>
            //             </tr>
            //             </>
            //         )
            //         }).reduce((a,c)=>a+c)
          }
          <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td>{calories?.toFixed(3)}</td>
            <td>{protein?.toFixed(3)}</td>
            <td>{fats?.toFixed(3)}</td>
            <td>{carbs?.toFixed(3)}</td>
            <td>
              {fibre?.toFixed(3)}
            </td>
          </tr>
          {/* {props?.data?.newTableRow?.note && (
            <tr>
              <td colSpan={8}>
                {noteEdit ? (
                  <textarea
                    type="text"
                    defaultValue={props?.data?.newTableRow?.note}
                    onChange={(e) => editnoteChange(e)}
                    style={{ border: "none", width: "100%" }}
                  />
                ) : (
                  props?.data?.newTableRow?.note
                )}
              </td>
              <td>
                <AiOutlineEdit
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    fontSize: "19px",
                  }}
                  onClick={editNote}
                />{" "}
                <TiDelete
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    fontSize: "19px",
                  }}
                />
              </td>
            </tr>
          )} */}

          {/* <tr> */}

          {/*       
    </tr> */}
        </tbody>
      </table>
    </div>
  );
}

export default GridTableClientPlans;
