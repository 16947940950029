import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { DemoItem } from "@mui/x-date-pickers/internals/demo";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AlertPopup from "./AlertPopup";

import { axiosFetch } from "../../../../Utlis/AxiosFetch";

import {
  updateCustomList,
  updateCustom,
  updateRestList,
  updateRest,
} from "../../../store/WorkoutPlan/WorkoutPlan";

import "./Vmax.css";

import CustomCategory from "./CustomCategory";
import { v4 } from "uuid";

const Custom = (props) => {
  const dispatch = useDispatch();
  const liveList = useSelector((state) => state.workoutPlan.liveList);
  const getEvents = useSelector((state) => state.workoutPlan.getEvents);
  const customList = useSelector((state) => state.workoutPlan.customList);
  const vmaxList = useSelector((state) => state.workoutPlan.vmaxList);
  const restList = useSelector((state) => state.workoutPlan.restList);
  const plan = useSelector((state) => state.workoutPlan.startDate);

  const endDatePlan = useSelector((state) => state.workoutPlan.endDate);
  const editEvent = useSelector((state) => state.workoutPlan.editEvent);
  const clickEvent = useSelector((state) => state.workoutPlan.clickEvent);

  const [typeOfDay, setTypeOfDay] = useState("weeks");
  const [dayOfType, setDayOfType] = useState("");

  const [exerciseCount, updateExerciseCount] = useState(0);

  const [date, setDate] = useState("");
  const [activeDay, setActiveDays] = useState(["monday"]);
  const [workout, setWorkout] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [schedules, setSchedules] = useState({});
  const [iscategory, setCategory] = useState([1]);
  const everyRepeat = useRef("");
  const [startDateFlag, setStartDateFlag] = useState(false);
  const [endDateFlag, setEndDateFalg] = useState(false);
  const [week, setWeek] = useState([1, 2, 3, 4]);
  const days = [
    { id: 0, name: "sunday" },
    { id: 1, name: "monday" },
    { id: 2, name: "tuesday" },
    { id: 3, name: "wednesday" },
    { id: 4, name: "thursday" },
    { id: 5, name: "friday" },
    { id: 6, name: "saturday" },
  ];
  const RepsRef = useRef("");
  const SetsRef = useRef("");
  const [open, setOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  const [snackBarColor, setSnackBarColor] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [repeat, setRepeat] = useState(false);
  const typeOfMode = useRef("");

  const [workoutTypeCate, setWorkoutTypeCate] = useState("");
  const [hasdate, setHasdate] = useState("");

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosFetch({
          url: `/getWorkoutNames`,
          method: "get",
        });
        const categoryResponse = await axiosFetch({
          url: `/getWorkoutCategory`,
          method: "get",
        });
        setWorkout(response.data);
        setcategoryList(categoryResponse.data);
      } catch (error) {
        //console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (editEvent.plan_type === "custom") {
      setHasdate(editEvent.date);
      setStartDate(dayjs(editEvent.date));
      setWorkoutTypeCate(editEvent.workout_type);
      let [workoutDetails] = editEvent.workout_details;
      setSchedules(workoutDetails);
    }
  }, [editEvent]);

  const handleCheckboxChange = (event) => {
    setRepeat(event.target.checked);
  };

  function getDateFromString(dateStr) {
    const [month, day, year] = dateStr.split("-");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  const handleDatePicker = (selectedDate, params) => {
    const date = new Date(selectedDate.$d);
    const formattedDate = date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    if (params === "start") {
      const planStartDate = getDateFromString(plan.planStart);
      const vmaxStartDate = getDateFromString(formattedDate);

      if (vmaxStartDate < planStartDate) {
        setOpen(true);
        setSnackBarColor("warning");
        setsnackMessage(
          `Start date must be within plan start date ${plan.planStart}`
        );
        setStartDateFlag(true);
        setStartDate(null);
        setTimeout(() => {
          setStartDateFlag(false);
        }, 100);
      } else {
        setStartDate(formattedDate);
      }
    } else {
      function getDateFromString(dateStr) {
        const [month, day, year] = dateStr.split("-");
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      }

      if (endDatePlan.planEnd) {
        const date = getDateFromString(endDatePlan.planEnd);
        const endDate = getDateFromString(formattedDate);

        if (endDate > date) {
          setOpen(true);
          setSnackBarColor("warning");
          setsnackMessage(
            `End date must be within plan end date ${endDatePlan.planEnd}`
          );

          setEndDateFalg(true);
          setEndDate(null);
          setTimeout(() => {
            setEndDateFalg(false);
          }, 100);
          return;
        } else if (endDate <= date) {
          setEndDate(formattedDate);
        }
      } else {
        setEndDate(formattedDate);
      }
    }
  };

  const handleStartDate = (selectedDate) => {
    handleDatePicker(selectedDate, "start");
  };

  const handleEndDate = (selectedDate) => {
    handleDatePicker(selectedDate, "end");
  };

  const handleDayClick = (value, index) => {
    const isActive = activeDay.includes(value);
    if (isActive) {
      setActiveDays(activeDay.filter((day) => day !== value));
    } else {
      setActiveDays([...activeDay, value]);
    }
  };

  const handleWeek = (e) => {
    const day = e.target.value;
    setTypeOfDay(e.target.value);
    if (day === "month") {
      setWeek([1]);
    }
    if (day === "weeks") {
      setWeek([1, 2, 3, 4]);
    }

    setDayOfType(e.target.value);
  };

  const handleWorkoutType = async (e) => {
    setWorkoutTypeCate(e.target.value);
  };

  const addSchedule = () => {
    // restrict to add schedule after 15 exercise
    if (exerciseCount >= 45) return;
    updateExerciseCount((prev) => prev + 1);

    const checktype =
      editEvent.plan_type === "custom"
        ? startDate === ""
        : startDate === "" || endDate === "";

    if (checktype) {
      setOpen(true);
      setSnackBarColor("warning");
      setsnackMessage("Please fill in all the data before proceeding");
    } else {
      setSchedules((prevSchedules) => ({
        ...prevSchedules,
        [Object.keys(prevSchedules).length]: {
          items: [
            {
              id: v4(),
              intensity: "",
              exerciseName: "",
              link: "",
              noOfReps: 0,
              noOfSets: 0,
              duration: "00:00:00",
              checkbox: false,
              rest: "",
              showRest: false,
              notes:""
            },
          ],
          categoryType: "",
        },
      }));
    }
  };

  const onCategoryChange = (categoryType, items, parentIndex) => {
    setSchedules((prev) => {
      const temp = { ...prev };

      return {
        ...temp,
        [parentIndex]: {
          items,
          categoryType,
        },
      };
    });
  };

  const onSchedule = (categoryType, items, parentIndex) => {
    setSchedules((prev) => {
      const temp = { ...prev };

      return {
        ...temp,
        [parentIndex]: {
          items,
          categoryType,
        },
      };
    });
  };

  const onUpdateSchedule = (parentIndex, id, name, value) => {
    setSchedules((prev) => {
      const temp = { ...prev };

      return {
        ...temp,
        [parentIndex]: {
          ...temp[parentIndex],
          items: temp[parentIndex].items.map((i) => {
            if (i?.id === id) {
              return {
                ...i,
                [name]: value,
              };
            }
            return i;
          }),
        },
      };
    });
  };

  const addLinkSchedule = (parentIndex, id, name, value) => {
    setSchedules((prev) => {
      const temp = { ...prev };
      if (temp[parentIndex]) {
        const itemToUpdate = temp[parentIndex].items.find(
          (item) => item.id === id
        );
        if (itemToUpdate) {
          itemToUpdate[name] = value;
        }
      }

      return temp;
    });
  };

  const onClear = (updateClearItem, id) => {
    setSchedules((prev) => {
      const temp = { ...prev };
      return {
        ...temp,
        [id]: {
          categoryType: "",
          items: updateClearItem,
        },
      };
    });
  };

  const onClose = (id) => {
    setSchedules((prev) => {
      const temp = {
        ...prev,
      };
      delete temp[id];
      return temp;
    });
  };

  // Help to find dates
  const findDates = () => {
    function getDateFormat(dateStr) {
      const date = new Date(dateStr.$d);
      const formattedDate = date
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");
      return formattedDate;
    }

    function getDateFromString(dateStr) {
      const [month, day, year] = dateStr.split("-");
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }

    let startDateFind;
    let endDateFind;

    if (editEvent.plan_type === "custom") {
      const start = getDateFormat(startDate);
      const end = getDateFormat(startDate);
      startDateFind = getDateFromString(start);
      endDateFind = getDateFromString(end);
    } else {
      startDateFind = getDateFromString(startDate);
      endDateFind = getDateFromString(endDate);
    }

    const resultDates = [];

    let currentDate = startDateFind;
    while (currentDate <= endDateFind) {
      const formattedDate = `${currentDate.getDate()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getFullYear()}`;
      resultDates.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return resultDates;
  };

  // Help to find days
  const findDays = (dayOfWeek, start, end, repeatMode) => {
    function getNextDate(currentDate) {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      return nextDate;
    }

    const startDate = new Date(
      parseInt(start.split("-")[2]),
      parseInt(start.split("-")[0]) - 1,
      parseInt(start.split("-")[1])
    );
    const endDate = new Date(
      parseInt(end.split("-")[2]),
      parseInt(end.split("-")[0]) - 1,
      parseInt(end.split("-")[1])
    );

    const resultDates = [];

    let currentDate = startDate;
    let weekCount = 1;

    let isWeek = repeatMode.current ? +repeatMode.current.value : 1;

    if (typeOfDay === "weeks") {
      while (currentDate <= endDate) {
        const currentDayOfWeek = currentDate.getDay();

        if (dayOfWeek.includes(currentDayOfWeek) && weekCount === isWeek) {
          const formattedDate = `${currentDate.getDate()}-${
            currentDate.getMonth() + 1
          }-${currentDate.getFullYear()}`;
          resultDates.push({
            date: formattedDate,
            day: currentDayOfWeek,
          });
        }

        currentDate = getNextDate(currentDate);

        if (currentDate.getDay() === 0) {
          weekCount++;
        }
      }
    } else {
      while (currentDate <= endDate) {
        const currentDayOfWeek = currentDate.getDay();

        if (dayOfWeek.includes(currentDayOfWeek)) {
          const formattedDate = `${currentDate.getDate()}-${
            currentDate.getMonth() + 1
          }-${currentDate.getFullYear()}`;
          resultDates.push({
            date: formattedDate,
            day: currentDayOfWeek,
          });
        }

        // Get the next date
        currentDate = getNextDate(currentDate);
      }
    }

    return resultDates;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!startDate) {
      setOpen(true);
      setSnackBarColor("warning");
      setsnackMessage("Please fill in all the data before proceeding");
      return;
    }

    const activeDaysId = days
      .filter((day) => activeDay.includes(day.name))
      .map((day) => day.id);

    const transformedObj = {};

    Object.values(schedules).forEach((category) => {
      const categoryName = category.categoryType;
      const exerciseArray = category.items.map((item, index) => ({
        id: v4(),
        exercise: item.exerciseName,
        intensity: item.intensity,
        level: item.level,
        subLevel: item.subLevel,
        reps: item.noOfReps ? parseInt(item.noOfReps) : 0,
        set: item.noOfSets ? parseInt(item.noOfSets) : 0,
        duration: item.duration,
        rest: 15,
        notes: item.notes
      }));
      transformedObj[categoryName] = exerciseArray;
    });

    if (
      Object.keys(transformedObj).length === 0 ||
      Object.keys(transformedObj)[0] === "" ||
      transformedObj[Object.keys(transformedObj)[0]][0].exercise === ""
    ) {
      setOpen(true);
      setSnackBarColor("warning");
      setsnackMessage("Please Select any exercise");
      return;
    }
    if (
      transformedObj[Object.keys(transformedObj)[0]][0].level === undefined ||
      transformedObj[Object.keys(transformedObj)[0]][0].subLevel === undefined
    ) {
      setOpen(true);
      setSnackBarColor("warning");
      setsnackMessage("Please Select Levels");
      return;
    }
    if (editEvent) {
      if (startDate === "" || workout?.current?.value === "") {
        setOpen(true);
        setSnackBarColor("warning");
        setsnackMessage("Please fill in all the data before proceeding");
        return;
      }
    } else {
      if (
        startDate === "" ||
        endDate === "" ||
        workout?.current?.value === ""
      ) {
        setOpen(true);
        setSnackBarColor("warning");
        setsnackMessage("Please fill in all the data before proceeding");

        return;
      }
      if (transformedObj) {
        let results = Object.values(transformedObj).shift();
        let repsList = results?.map((item) => item.reps);
        let setsList = results?.map((item) => item.set);
      }
    }

    const dateFormat = (date) => {
      const dateStr = date;
      const [day, month, year] = dateStr.split("-");
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      return formattedDate;
    };

    let allDates;

    if (repeat) {
      allDates = findDays(activeDaysId, startDate, endDate, everyRepeat);
    } else {
      allDates = findDates();
    }

    let customData;

    if (editEvent.plan_type === "custom") {
      const dateObject = new Date(startDate);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const day = String(dateObject.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      let planName;

      if (plan.planName) {
        planName = plan.planName;
      } else if (plan.templateName) {
        planName = plan.templateName;
      }

      customData = [
        {
          id: editEvent.plan_type === "custom" ? editEvent.id : "",
          plan_name: planName,
          date: formattedDate,
          repeat: repeat,
          repeatCount: 0,
          repeatUnit: "",
          dayOfWeek: "",

          workout_details: [schedules],
          plan_type: "custom",
        },
      ];
    } else {
      const selectedWorkout = workout.find((s) => s.id == workoutTypeCate);

      customData = allDates.map((date) => {
        let currentDate;
        let currentDay;

        if (date.date) {
          currentDate = date.date;
          currentDay = date.day;
        } else {
          currentDate = date;
        }

        let planName;

        if (plan.planName) {
          planName = plan.planName;
        } else if (plan.templateName) {
          planName = plan.templateName;
        }

        return {
          plan_name: planName,
          date: dateFormat(currentDate),
          repeat: repeat,
          repeatCount: everyRepeat.current ? +everyRepeat.current.value : 1,
          repeatUnit: typeOfMode.current ? typeOfMode.current.value : "weeks",
          dayOfWeek: currentDay ? [currentDay] : "",

          workout_details: [schedules],
          plan_type: "custom",
        };
      });
    }

    dispatch(updateCustom(customData));

    const isDuplicate = customList.some((custom) => {
      return customData.some((customEvent) => {
        return custom.date === customEvent.date;
      });
    });

    const isVmaxDuplicate = vmaxList.some((vmax) => {
      return customData.some((customEvent) => {
        return vmax.date === customEvent.date;
      });
    });

    const isLiveDuplicate = liveList.some((live) => {
      return customData.some((customEvent) => {
        return live.date === customEvent.date;
      });
    });

    const isRestList = restList.some((rest) => {
      return customData.some((customEvent) => {
        return rest.date === customEvent.date;
      });
    });

    function checkForRestDay(oldArray, newArray, planName) {
      let old_array = oldArray.filter((old) => old.title === planName);
      const firstRestDays = {};
      let checkedDays = [];
      let state = false;

      for (const entry of old_array) {
        if (
          (entry.plan_type === "rest" || entry.planType === "rest") &&
          !(entry.start in firstRestDays) &&
          !checkedDays.includes(entry.start)
        ) {
          firstRestDays[entry.start] = true;
        } else {
          checkedDays.push(entry.start);
        }
      }

      // Check newArray for any exercise on the first rest day for each particular day in oldArray
      for (const entry of newArray) {
        const date = entry.date;
        if (date in firstRestDays) {
          alert(`Error: Rest 2 day doesn't allow any exercise on ${date}`);

          state = true;
        }
      }
      return state;
    }

    if (
      !isDuplicate &&
      !isVmaxDuplicate &&
      !checkForRestDay(getEvents, customData, plan.planName) &&
      !isLiveDuplicate &&
      !isRestList
    ) {
      dispatch(updateCustomList(customData));
    }

    props.closeModel();
  };

  const deleteEvent = async (event) => {
    const response = await axiosFetch({
      url: `/postWorkoutPlans`,
      requestConfig: {
        action: "delete",
        workoutTypeId: event.id,
      },
      method: "post",
    });
  };

  const handleRest = () => {
    let data;

    if (editEvent) {
      let formattedDate;
      if (startDate.$d) {
        const date = new Date(startDate.$d);
        formattedDate = date
          .toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-");
      } else {
        formattedDate = startDate;
      }

      const [month, day, year] = formattedDate.split("-");

      const dateObject = `${year}-${month}-${day}`;

      data = [
        {
          plan_type: "rest",
          date: dateObject,
          workout_details: [{ exercise: "Rest" }],
        },
      ];
    } else {
      if (!startDate || !endDate) {
        setOpen(true);
        setSnackBarColor("warning");
        setsnackMessage(
          "To enable rest Start date and End Date is must needed"
        );

        return;
      } else {
        data = findDates().map((data) => {
          const originalDate = data;
          const [day, month, year] = originalDate.split("-");

          const dateObject = new Date(`${year}-${month}-${day}`);

          const formattedYear = dateObject.getFullYear();
          const formattedMonth = String(dateObject.getMonth() + 1).padStart(
            2,
            "0"
          );
          const formattedDay = String(dateObject.getDate()).padStart(2, "0");

          const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

          return {
            plan_type: "rest",
            date: formattedDate,
            workout_details: [{ exercise: "Rest" }],
          };
        });
      }
    }
    const activeDaysId = days
      .filter((day) => activeDay.includes(day.name))
      .map((day) => day.id);
    let allDates;
    const dateFormat = (date) => {
      const dateStr = date;
      const [day, month, year] = dateStr.split("-");
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      return formattedDate;
    };

    if (repeat) {
      allDates = findDays(activeDaysId, startDate, endDate, everyRepeat);
    } else {
      allDates = findDates();
    }

    data = allDates.map((date) => {
      let currentDate;
      let currentDay;

      if (date.date) {
        currentDate = date.date;
        currentDay = date.day;
      } else {
        currentDate = date;
      }

      let planName;

      if (plan.planName) {
        planName = plan.planName;
      } else if (plan.templateName) {
        planName = plan.templateName;
      }

      return {
        plan_name: planName,
        date: dateFormat(currentDate),
        repeat: repeat,
        repeatCount: everyRepeat.current ? +everyRepeat.current.value : 1,
        repeatUnit: typeOfMode.current ? typeOfMode.current.value : "weeks",
        dayOfWeek: currentDay ? [currentDay] : "",

        workout_details: [{ exercise: "Rest" }],
        plan_type: "rest",
      };
    });
    const isDuplicate = vmaxList.some((vmax) => {
      return data.some((dataEvent) => {
        return vmax.date === dataEvent.date;
      });
    });
    const isCustomDuplicate = customList.some((custom) => {
      return data.some((dataEvent) => {
        return custom.date === dataEvent.date;
      });
    });
    const isLiveDuplicate = liveList.some((live) => {
      return data.some((dataEvent) => {
        return live.date === dataEvent.date;
      });
    });

    const isRestList = restList.some((rest) => {
      return data.some((dataEvent) => {
        return rest.date === dataEvent.date;
      });
    });

    const isEventsDuplicate = getEvents.some((event) => {
      return data.some((dataEvent) => {
        if (event.start === dataEvent.date && event.start === hasdate) {
          return false;
        }
        return event.start === dataEvent.date;
      });
    });

    const result = window.confirm("Are you sure you want to create rest event");
    if (result) {
      dispatch(updateRest(data));
    } else {
      return;
    }

    if (
      !isDuplicate &&
      !isCustomDuplicate &&
      !isEventsDuplicate &&
      !isLiveDuplicate &&
      !isRestList
    ) {
      props.closeModel();
      dispatch(updateRestList(data));
      deleteEvent(clickEvent);
    }
    props.closeModel();
  };

  return (
    <form onSubmit={submitHandler} className="form-custom">
      <div className="card-items">
        <Row className="mb-4 row-1">
          {editEvent.plan_type === "custom" ? (
            <Col sm={4}>
              <label>Start date</label>
              {!startDateFlag && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker
                      value={startDate}
                      onChange={handleStartDate}
                    />
                  </DemoItem>
                </LocalizationProvider>
              )}
              {startDateFlag && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker value={startDate} />
                  </DemoItem>
                </LocalizationProvider>
              )}
            </Col>
          ) : (
            <><Col sm={4}>
            <label>Start date</label>
            {!startDateFlag && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DesktopDatePicker
                    value={startDate}
                    onChange={handleStartDate}
                  />
                </DemoItem>
              </LocalizationProvider>
            )}
            {startDateFlag && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DesktopDatePicker value={startDate} />
                </DemoItem>
              </LocalizationProvider>
            )}
          </Col></>
          )}

          {editEvent.plan_type === "custom" ? (
            ""
          ) : (
            <>
              <Col sm={4}>
                <label>End date</label>

                {!endDateFlag && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DesktopDatePicker onChange={handleEndDate} />
                    </DemoItem>
                  </LocalizationProvider>
                )}
                {endDateFlag && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DesktopDatePicker value={endDate} />
                    </DemoItem>
                  </LocalizationProvider>
                )}
              </Col>
              <Col className="mb-1 radio-con">
                <p className="radio">
                  <input
                    className="repeat"
                    id="repeat"
                    type="checkbox"
                    checked={repeat}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="repeat">Repeat</label>
                </p>
              </Col>
            </>
          )}
          <Col className="rest-btn">
            <button type="button" onClick={handleRest}>
              Rest
            </button>
          </Col>
        </Row>

        {repeat && (
          <Row className="mb-4 row-2">
            <Col sm={3}>
              <label>Repeat Every</label>
              <select id="repeat" className="custom-input" ref={everyRepeat}>
                {week.length > 0 &&
                  week.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm={2} className="col-week">
              <select
                id="repeat"
                className="custom-select"
                ref={typeOfMode}
                onChange={handleWeek}
              >
                <option value="weeks">Week</option>
                <option value="months">Month</option>
              </select>
            </Col>
            <Col sm={7} className="week-btn">
              {days.map((value, index) => (
                <button
                  type="button"
                  key={value.id}
                  className={` ${
                    activeDay.includes(value.name) ? "active-day" : ""
                  }`}
                  onClick={() => handleDayClick(value.name, index)}
                >
                  {value.name.charAt(0).toUpperCase()}
                </button>
              ))}
            </Col>
          </Row>
        )}

        <Row className="mb-4">
          {/* <Col sm={8} className="select-box-con">
            <label>Type of Workout</label>
            <select
              id="type-of-workout"
              ref={workoutType}
              onChange={handleWorkoutType}
              value={workoutTypeCate}
            >
              <option disabled value="">
                Select
              </option>
              {workout.length > 0 &&
                workout.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </Col> */}
          <Col className="custom-add-schedule">
            <button type="button" onClick={addSchedule}>
              Add Schedule
            </button>
          </Col>
        </Row>
      </div>
      {Object.keys(schedules).map((key) => {
        return (
          <CustomCategory
            key={key}
            parentIndex={key}
            schedule={schedules[key]}
            categoryList={categoryList}
            RepsRef={RepsRef}
            SetsRef={SetsRef}
            onCategoryChange={onCategoryChange}
            onSchedule={onSchedule}
            onUpdateSchedule={onUpdateSchedule}
            addLinkSchedule={addLinkSchedule}
            onClear={onClear}
            onClose={onClose}
            exerciseCount={exerciseCount}
            updateExerciseCount={updateExerciseCount}
            onDeleteSchedule={(id, items) => {
              setSchedules((prev) => {
                const temp = { ...prev };
                if (items.length === 0) {
                  delete temp[id];
                  return temp;
                }
                return {
                  ...temp,
                  [id]: {
                    ...temp[id],
                    items: items,
                  },
                };
              });
            }}
          />
        );
      })}

      <div className="workout-btn-con">
        <button type="submit">Save</button>
        <button
          type="button"
          onClick={() => {
            props.closeModel();
          }}
        >
          Cancel
        </button>
      </div>
      <AlertPopup
        snackMessage={snackMessage}
        snackBarColor={snackBarColor}
        open={open}
        handleCloseSnackbar={handleCloseSnackbar}
      />
    </form>
  );
};

export default Custom;
