import React, { useEffect, useState } from "react";

import { Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";

import Description from "./Description";

const TaskActionMenu = ({
  anchorEl,
  handleModal,
  handleClose,
  handleDeleteTask,
  handleWhatsapp,
  handlecall,
  onServetel,
  editRow,
  handleReloadEditTask,
}) => {
  const [openCallMenu, setOpenCallMenu] = useState(null);
  const [isRole, setIsRole] = useState("");

  const [isOpenDescription, setIsOpenDescription] = useState(false);
  const [flag, setFlag] = useState("");

  const handleCloseDescription = () => {
    handleReloadEditTask();
    setIsOpenDescription(false);
  };

  const handleOpenDescription = (value) => {
    handleClose();
    setIsOpenDescription(true);
    setFlag(value);
  };

  const handleOpenCallMenu = (e) => {
    setOpenCallMenu(e.target);
    // onClose();
  };

  const handleCloseCallMenu = () => {
    setOpenCallMenu(false);
  };

  const handleServetelCall = () => {
    onServetel(true);
    setOpenCallMenu(false);
    handleClose();
  };

  const handlePlivo = () => {
    setOpenCallMenu(false);
    handlecall();
  };

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("loginDetails"));
    setIsRole(item.Role);
  }, []);

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          top: "-100px",
          left: "-100px",
        }}
      >
        <MenuItem onClick={handleModal}>
          <span className="mr-5">
            <EditIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Edit</span>
        </MenuItem>
        <MenuItem onClick={handleOpenCallMenu}>
          <span className="mr-5">
            <PhoneIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Call</span>
        </MenuItem>
        <MenuItem onClick={handleWhatsapp}>
          <span className="mr-5">
            <WhatsAppIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">WhatsApp</span>
        </MenuItem>
        <MenuItem onClick={() => handleOpenDescription("complete")}>
          <span className="mr-5">
            <CheckCircleOutlineIcon
              style={{ fontSize: "20px", color: "#6B6B6B" }}
            />
          </span>
          <span className="ms-1">Complete</span>
        </MenuItem>

        <MenuItem onClick={() => handleOpenDescription("cancel")}>
          <span className="mr-5">
            <CloseIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Cancel</span>
        </MenuItem>
      </Menu>

      <Menu
        id="simple-menu"
        anchorEl={openCallMenu}
        open={Boolean(openCallMenu)}
        onClose={handleCloseCallMenu}
        style={{
          left: "-250",
        }}
      >
        <MenuItem onClick={handlePlivo}>
          <span className="mr-5">
            <PhoneIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Plivo</span>
        </MenuItem>

        <MenuItem onClick={handleServetelCall}>
          <span className="mr-5">
            <PhoneIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Servetel</span>
        </MenuItem>

        {/* )} */}
      </Menu>
      <Description
        isOpen={isOpenDescription}
        onClose={handleCloseDescription}
        flag={flag}
        editRow={editRow}
      />
    </>
  );
};

export default TaskActionMenu;

{
  /* <MenuItem onClick={handleDeleteTask}>
          <span className="mr-5">
            <DeleteIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Delete</span>
        </MenuItem> */
}

{
  /* {(isRole === "Sales Manager" ||
          isRole === "Sales" ||
          isRole === "Super Admin") && ( */
}
