import React from "react";

import "./Button.css";

const Button = ({
  children,
  inline,
  outline,
  className,
  type,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      type={type}
      className={`${inline ? "btn-inline" : ""} ${
        outline ? "btn-outline" : ""
      } ${"btn-common"} ${className} ${disabled ? "btn-disabled" : ""} `}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;

//btn-disabled
