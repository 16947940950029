import React, { useState, useEffect, useContext } from "react";
import * as $ from "jquery";
import BoxHeader from "../../components/BoxHeader";
import DataTable from "../../components/DataTable";
import SelectBox from "../../components/SelectBox";
import axios from "axios";
import Dialog from "../../components/Dialog";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import { deleteFromTable, editTableRow } from "../../Helpers/TableHelpers";
import {
  getNormalDate,
  getInputDate,
  getFormattedDate,
  getFormatDate,
} from "../../Helpers/DateHelpers";
import { ATLAS_URI, ADMIN } from "../../Constants";
import stateContext from "../../context/StateContext";
import "./LeadDetails.css";
import { useHistory, useParams } from "react-router-dom";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { updateLeadSchema, paymentDetailSchema } from "../../ValidationSchema";
import { useFormik } from "formik";
import { Button } from "@mui/material";
import { getLeadList } from "../store/leads/leadActions";
import { useDispatch, useSelector } from "react-redux";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import ProgramDetails from "./ProgramDetails";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

function LeadDetails() {
  const leadsList = useSelector((state) => state.lead.data);
  const dispatch = useDispatch();
  const [isDisplayFollowUp, setIsDisplayFollowUp] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const contextState = useContext(stateContext);
  const { redirectFromEditDetails, resetEditID, updateList } = contextState;
  const {
    configToken,
    usersList,
    sourcesList,
    propertyTypeList,
    statusList,
    operator,
    EditDetailsData,
  } = contextState.state;

  const fitnessGoalList = [
    "Coach Guidance",
    "Diet Plan",
    "Weight Loss",
    "Weight Gain",
    "Count Calories",
    "Muscle Gain",
    "Home Workout",
    "Disease Management",
  ];

  const [fitnessGoalsSelected, setFitnessGoalsSelected] = useState(
    fitnessGoalList.map((fitness) => {
      return { [fitness]: false };
    })
  );
  const routeParams = useParams();

  //

  const [state, setState] = useState({
    resetNewRow: {
      id: "",
      Client: "",
      name: "",
      ClientPhone: "",
      email_id: "",
      PropertyType: "",
      source: "",
      // lead_status: "",
      RequiredLocation: "",
      MinArea: "",
      MaxArea: "",
      MinBudget: "",
      MaxBudget: "",
      RequiredLoan: "No",
      next_followup_date: "",
      InterestedIn: "",
      // call_status: "",
      Remarks: [],
      VisitInfos: [],
      Payments: [],
      checked: [],
      current_medical_condition: [],
      age: null,
      lead_payments: [],
      // vmax_lead_stage_id :null,
      // vmax_lead_disposition_id:"",
      // vmax_lead_sub_disposition_id : "",
      // vmax_lead_sub_not_interested_id :null,
      //    assigned_to:''
    },
    newTableRow: {
      id: "0001",
      Client: "",
      age: null,
      name: "",
      address: "",
      ClientPhone: "",
      email_id: "",
      PropertyType: "",
      source: "",
      // lead_status: "",
      RequiredLocation: "",
      MinArea: "",
      MaxArea: "",
      MinBudget: "",
      MaxBudget: "",
      RequiredLoan: "No",
      next_followup_date: "",
      InterestedIn: "",
      // call_status: "",
      Remarks: [],
      VisitInfos: [],
      Payments: [],
      current_medical_condition: [],
      // assigned_to:'',
      // fitness_goals: ['Diet Plan']
      checked: [],
      leadStageList: {},
      lead_payments: [],
      // vmax_lead_stage_id :"",
      // vmax_lead_disposition_id:"",
      // vmax_lead_sub_disposition_id : "",
      // vmax_lead_sub_not_interested_id :null,
    },
    tableBodyList: [],
    Project: "Select",
    Property: "Select",
    EditingVisitList: false,
    EditingVisitInfoID: "",

    editingActivated: false,
    editingID: "",
    dialogInfo: {
      isOpened: false,
      text: "",
      iconClass: "fas fa-exclamation-triangle",
    },
    projectsList: [],
    paidFor: [],
    propertiesList: [],
    propertyTypesList: [],
    sourcesList: [],
    clientsList: [],
    userList: [],
  });

  const [salesRole, setSalesRole] = useState([]);
  const [allRoles, setAllRoles] = useState([]);

  const [leadStageList, setLeadStageList] = useState([]);
  const [leadDisposition, setLeadDisposition] = useState([]);
  const [leadSubDisposition, setLeadSubDisposition] = useState([]);
  const [grandDisposition, setGrandDisposition] = useState([]);

  const [leadStage, setLeadStage] = useState("");
  const [leadDispositionId, setLeadDispositionId] = useState("");
  const [leadSubDispositionId, setLeadSubDispositionId] = useState("");
  const [grandDispositionId, setGrandDispositionId] = useState("");

  const [leadStageErr, setLeadStageErr] = useState(false);
  const [dispositionErr, setDispositionErr] = useState(false);
  const [subDispositionErr, setSubDispositionErr] = useState(false);

  const handleChangeLeadStage = (e) => {
    setLeadStage(e.target.value);
    let isDisplayFollowUp = leadStageList.filter(
      (stage) => stage.id === parseInt(e.target.value)
    )[0].isFollowupDisplay;
    setIsDisplayFollowUp(isDisplayFollowUp);

    getLeadDisposition(e.target.value);
    setLeadDispositionId("");
    setLeadSubDispositionId("");
    setGrandDispositionId("");
    if (e.target.value === "") {
      setLeadStageErr(true);
      return false;
    } else {
      setLeadStageErr(false);
    }
  };

  const handleChangeDisposition = (e) => {
    setLeadDispositionId(e.target.value);
    if (e.target.value !== "") {
      getLeadSubDisposition(e.target.value, leadStage);
    } else if (e.target.value === "") {
      setLeadSubDisposition([]);
      setDispositionErr(false);
    }

    // if(e.target.value === ""){
    //   setDispositionErr(true);
    //   return false;
    // }
    // else{
    //   setDispositionErr(false);

    // }
  };

  const handleChangeSubDisposition = (e) => {
    setLeadSubDispositionId(e.target.value);

    let isDisplayFollowUp = leadSubDisposition.filter(
      (subDisposition) => subDisposition.id === parseInt(e.target.value)
    )[0].isFollowupDisplay;
    setIsDisplayFollowUp(isDisplayFollowUp);

    if (e.target.value === "") {
      setGrandDisposition([]);
      setSubDispositionErr(false);
    } else if (e.target.value !== "") {
      getGrandDisposition(leadDispositionId, e.target.value);
    }
  };

  const handleChangeGrandDisposition = (e) => {
    setGrandDispositionId(e.target.value);
  };

  const mounted = React.useRef(true);

  const getLeadStage = () => {
    axiosFetch({
      url: "/getLeadStages",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setLeadStageList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getLeadDisposition = (stageId) => {
    setLeadStage(stageId);
    axiosFetch({
      url: "/getLeadDispositions",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          if (parseInt(stageId) === 7 || parseInt(stageId) === 5) {
            let disposition_id = leadStageList.filter(
              (list) => list.id == stageId
            )[0].vmax_lead_disposition_id;

            setLeadDisposition(
              res.data.filter((list) => list.id == disposition_id)
            );
          } else if (parseInt(stageId) === 6) {
            let disposition_id = leadStageList.filter(
              (list) => list.id == stageId
            )[0].vmax_lead_disposition_id;

            setLeadDisposition(
              res.data.filter((list) => list.id == disposition_id)
            );
          } else {
            setLeadDisposition(res.data);
          }
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getLeadSubDisposition = (id, stageId) => {
    let url;
    if (id == 2) {
      url = `getLeadSubDispositionsByDispositionId/${id}`;
    } else {
      url = `getLeadSubDispositionsByDispositionIdAndStageId/${id}/${stageId}`;
    }
    axiosFetch({
      // url : `/getLeadSubDispositionsByDispositionId/${id}`,
      url,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setLeadSubDisposition(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getGrandDisposition = (disId, subDisId) => {
    axiosFetch({
      url: `/getLeadSubNotInterestedListByDispositionIdAndSubDispositionId/${disId}/${subDisId}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setGrandDisposition(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  useEffect(() => {
    dispatch(getLeadList());

    let userData = usersList;

    let sourcesData = sourcesList;
    let propertyTypesData = propertyTypeList;
    let Allleadlist = leadsList;

    //Populate all Fields

    if (resetEditID("/Leads/leadDetails")) {
      // EditDetailsData = {}
      // window.location.replace("/Leads/freshLeads");
    } else {
      if (typeof routeParams.id !== "undefined") {
        axios
          .all([
            axios.get(`${ATLAS_URI}/getRoles/`, configToken),
            axios.get(`${ATLAS_URI}/getProjects/`, configToken),
            axios.get(`${ATLAS_URI}/getProperties/`, configToken),
            axios.get(`${ATLAS_URI}/getSalesRoles/`, configToken),
            axios.get(`${ATLAS_URI}/getPaidFor/`, configToken),
          ])
          .then(
            axios.spread((roles, projects, properties, salesRoles, paidFor) => {
              const projectsData = projects.data;
              const propertiesData = properties.data;
              const rolesData = roles.data;
              setAllRoles(rolesData);

              setSalesRole(salesRoles.data);
              // let leadsData = Allleadlist.filter(lead => lead.id == routeParams.id)[0]

              if (
                typeof projectsData !== "undefined" &&
                typeof propertiesData !== "undefined" &&
                typeof rolesData !== "undefined" /* && mounted.current */
              ) {
                let newUserList = [];
                if (
                  salesRoles.data.length !== 0 &&
                  // && propertyTypesData.length !== 0
                  rolesData.length !== 0
                ) {
                  newUserList = salesRoles.data.map((user) => {
                    const temp = rolesData.filter(
                      (Role) => user.Role === Role.id
                    );
                    user.assigned_to =
                      temp[0].Role +
                      ": " +
                      (user.Name
                        ? user.Name
                        : user.firstName +
                          " " +
                          (user.lastName ? user.lastName : ""));
                    return user;
                  });
                }

                setTimeout(() => {
                  setState((prevState) => ({
                    ...prevState,
                    sourcesList: sourcesData,
                    propertyTypesList: propertyTypesData,
                    userList: newUserList,
                    projectsList: projectsData,
                    propertiesList: propertiesData,
                    // newTableRow: leadsData,
                    editingActivated: true,
                    paidFor: paidFor.data,
                  }));

                  // setFitnessGoalsSelected([...fitnessGoalsSelected, fitnessGoalsSelected.map(fitness => {
                  //
                  //     if (state.newTableRow.fitness_goals.includes(fitness)) {
                  //         return { [fitness]: true }
                  //     }
                  // })])

                  // setFitnessGoalsSelected(prevState => [...prevState, state.newTableRow.fitness_goals.map(fitness => {

                  //     return prevState.map(fitnessGoalInfo => {

                  //         if (Object.keys(fitnessGoalInfo) == fitness) {
                  //             fitnessGoalInfo[fitness] = true
                  //         }
                  //     })
                  //     // return [fitness] = true
                  // })].filter(each => {

                  //     return !Array.isArray(each)
                  // }))
                }, 200);
              }
            })
          )
          .catch((error) => console.error(error));
      }
    }
  }, []);

  useEffect(() => {
    getLeadStage();
    if (leadStage !== 7) {
      getLeadDisposition();
    }

    // setShowLoader(true);
    axiosFetch({
      url: `/getLeadByID/${routeParams.id}`,
      method: "get",
    }).then((res) => {
      setState((prevState) => ({
        ...prevState,
        newTableRow: {
          ...prevState.newTableRow,
          ...res.data,
          vmax_lead_stage_id: res.data?.leadStageList?.leadStageInfo,

          lead_payments: res.data?.lead_payments ? res.data?.lead_payments : [],
        },
        sourcesList: sourcesList,
      }));

      if (res.data.leadStageList !== null) {
        setLeadStage(res.data.leadStageList.vmax_lead_stage_id);

        setLeadDispositionId(res.data.leadStageList.vmax_lead_disposition_id);
        if (res.data.leadStageList.vmax_lead_disposition_id !== null) {
          getLeadSubDisposition(
            res.data.leadStageList.vmax_lead_disposition_id,
            res.data.leadStageList.vmax_lead_stage_id
          );
        }
        setLeadSubDispositionId(
          res.data.leadStageList.vmax_lead_sub_disposition_id
        );
        if (
          res.data.leadStageList.vmax_lead_disposition_id !== null &&
          res.data.leadStageList.vmax_lead_sub_disposition_id !== null
        ) {
          getGrandDisposition(
            res.data.leadStageList.vmax_lead_disposition_id,
            res.data.leadStageList.vmax_lead_sub_disposition_id
          );
        }
        setGrandDispositionId(
          res.data.leadStageList.vmax_lead_sub_not_interested_id
        );
      }
    });
  }, []);

  function onSelectChange(e) {
    let newValue;
    newValue = e.target.value;
    setState((prevState) => ({
      ...prevState,
      newTableRow: {
        ...prevState.newTableRow,
        [e.target.name]: newValue,
      },
    }));
    let selectedOption = e.target.value;
    if (selectedOption === "Success" || selectedOption === "Dump") {
      $("[name=next_followup_date]").removeAttr("required");
      $(".notReq").hide();
    } else {
      $("[name=next_followup_date]").prop("required", true);
      $(".notReq").show();
    }
  }

  function onFitnessGoalSelectChange(e) {
    var isChecked = e.target.checked;

    var item = e.target.value;

    setFitnessGoalsSelected((prevState) => ({
      ...prevState,
      [e.target.name]: isChecked,
    }));

    /* setState(prevState => ({
            ...prevState,
            newTableRow: {
                ...prevState.newTableRow,
                fitness_goals: [item]
            }
        })) */

    // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));

    /* 
        let newValue;
        newValue = e.target.value;
        setState(prevState => ({
            ...prevState,
            newTableRow: {
                ...prevState.newTableRow,
                [e.target.name]: newValue
            }
        }))
        let selectedOption = e.target.value;
        if (selectedOption === "Success" || selectedOption === "Dump") {
            $("[name=NextFollowUpDate]").removeAttr("required")
            $('.notReq').hide()
        } else {
            $("[name=NextFollowUpDate]").prop("required", true)
            $('.notReq').show()
        } */
  }

  var currentMedicalCondition = [];
  $("input:checkbox[name=current_medical_condition]:checked").each(function () {
    currentMedicalCondition.push($(this).val());
  });

  const onSubmit = () => {
    let newLead = formik.values;
    newLead.updated_by = operator.id;
    newLead.occupation =
      newLead.occupation !== null && newLead.occupation !== undefined
        ? newLead.occupation
        : undefined;
    newLead.source =
      newLead.source !== null && newLead.source !== undefined
        ? newLead.source
        : undefined;
    newLead.assigned_to =
      newLead.assigned_to !== null && newLead.assigned_to !== undefined
        ? newLead.assigned_to
        : undefined;
    // newLead.updated_by =
    if (!leadStage) {
      setLeadStageErr(true);
      return false;
    }
    // if(!leadDispositionId){
    //   setSubDispositionErr(true);
    //   return false;
    // }
    // if(!leadSubDispositionId){
    //   setSubDispositionErr(true);
    //   return false;
    // }

    axiosFetch({
      url: "/postLeadStageRelation",
      method: "post",
      requestConfig: {
        leadId: routeParams.id,
        vmax_lead_stage_id: leadStage ? leadStage : null,
        vmax_lead_disposition_id: leadDispositionId ? leadDispositionId : null,
        vmax_lead_sub_disposition_id: leadSubDispositionId
          ? leadSubDispositionId
          : null,
        vmax_lead_sub_not_interested_id: grandDispositionId
          ? grandDispositionId
          : null,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // toast.success("Updated Successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .post(`${ATLAS_URI}/updateLead/${routeParams.id}`, newLead, configToken)
      .then((res) => {
        if (res.status === 200) {
          goBackToPreviousPage();
          // window.location.reload()
          let list = [...leadsList];
          var foundIndex = list.findIndex((x) => x.id == routeParams.id);
          list[foundIndex] = newLead;

          updateList("leadsList", list);

          setState((prevState) => ({
            ...prevState,
            editingActivated: false,
            newTableRow: state.resetNewRow,
          }));
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getupdatevalue = () => {
    axios
      .get(`${ATLAS_URI}/getLeadbyID/${routeParams.id}`, configToken)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          newTableRow: {
            ...prevState.newTableRow,
            ...res.data,
            vmax_lead_stage_id: res.data.leadStageList?.leadStageInfo,
            lead_payments: [...res.data.lead_payments],
          },
          sourcesList: sourcesList,
        }));
      });
  };

  // if (state.newTableRow)

  const formik = useFormik({
    initialValues: {
      ...state.newTableRow,
    },
    enableReinitialize: true,
    validationSchema: updateLeadSchema,
    onSubmit,
  });
  // console.log(
  //   formik.errors,
  //   "formikerrors",
  //   formik.values,
  //   "formik values",
  //   formik,
  //   "formik"
  // );

  const paymentFormik = useFormik({
    initialValues: {
      Amount: "",
      payment_type: "",
      PaymentDate: "",
      PaidFor: "",
    },
    validationSchema: paymentDetailSchema,
    onSubmit: () => {
      addLeadIfNotExist();
      const tempDescription = $("#newPaymentDescription").val();
      const tempDate = $("#newPaymentDate").val();
      const tempStatus = $("#newPaymentType").val();
      const tempAmount = $("#newAmount").val();
      const tempPaidFor = $("#PaidFor").val();

      const addedPayment = {
        Amount: tempAmount,
        Type: tempStatus,
        Date: getNormalDate(tempDate),
        Description: tempDescription,
        LeadId: routeParams.id,
        PaidFor: tempPaidFor,
      };
      let newPayment = [...state.newTableRow.lead_payments];

      let client = {
        Name: state.newTableRow.name,
        Phone: state.newTableRow.contact_number,
        Email: state.newTableRow.email_id,
        Lead: routeParams.id,

        DOB: state.newTableRow.dob,
        Address: state.newTableRow.address,
        City: state.newTableRow.city,
        State: state.newTableRow.state,
        PINCode: state.newTableRow.pincode,
        Occupation: state.newTableRow.occupation,
        // Organization: state.newTableRow.,
        // Designation: state.newTableRow.,
        Source: state.newTableRow.source,
      };

      axios
        .post(`${ATLAS_URI}/addPayment/`, addedPayment, configToken)
        .then((res) => {
          if (res.status === 200) {
            addedPayment.PaidFor == 1 &&
              axios.post(`${ATLAS_URI}/addClient/`, client, configToken);

            // axiosFetch({
            //   url: `/getLeadByID/${routeParams.id}`,
            //   method: "get",
            // })

            addedPayment.PaidFor == 1 &&
              axios.post(`${ATLAS_URI}/addClient/`, client, configToken);

            addedPayment.id = res.data.id;
            addedPayment.PaymentFor = res.data.PaidForInfo.displayValue;
            newPayment.push(addedPayment);

            setState((prevState) => ({
              ...prevState,
              newTableRow: {
                ...prevState.newTableRow,
                lead_payments: newPayment,
              },
            }));
            getupdatevalue();

            let list = [...leadsList];
            //
            // axios.get(`${ATLAS_URI}/getLeadByID/${routeParams.id}`,configToken)

            let foundIndex = list.findIndex((x) => x.id == routeParams.id);
            // list.map(leadIn=>{
            //     if(leadIn.id==routeParams.id){
            //         return(

            //             leadIn.lead_payments=newPayment,

            //             leadIn.Payment='Paid'
            //         )
            //     }
            // })

            list[foundIndex].lead_payments = newPayment;
            list[foundIndex].Payment =
              list[foundIndex].lead_payments &&
              list[foundIndex].lead_payments.length > 0
                ? (list[foundIndex].Payment = "Paid")
                : (list[foundIndex].Payment = "Un-Paid");
            updateList("leadsList", list);

            /* $("#newPaymentDescription").val("");
                    $("#newPaymentDate").val("");
                    $("#newPaymentType").val("");
                    $("#newAmount").val(""); */

            paymentFormik.resetForm();

            //paymentFormik.setValues({ Amount: "", payment_type: '' })
          }
        })
        .catch((err) => console.error(err));
    },
  });
  // useEffect(() => {
  //   // axios.get(`${ATLAS_URI}/getLeadByID/${routeParams.id}`,configToken)
  //   axiosFetch({
  //     url: `/getLeadByID/${routeParams.id}`,
  //     method: "get"
  //   })

  // }, [])

  //Overloading insertIntoTable
  //     function insertIntoTable(e) {
  //         e.preventDefault();
  //         if (!(/^[+]\d{2}?\d{10}$/).test(state.newTableRow.contact_number)) {
  //             return toast.error('Please enter proper number',{position: toast.POSITION.TOP_CENTER})
  //            }
  //         if(currentMedicalCondition.length<1){return alert('Please select current medical condition')}
  //         if(state.newTableRow.source==null){return toast.error("Please select the source",{position: toast.POSITION.TOP_CENTER})}
  //         if(state.newTableRow.assigned_to==null){return toast.error("Please assign a user",{position: toast.POSITION.TOP_CENTER})}

  //         let newLead = state.newTableRow;
  //

  //         // newLead.next_followup_date = getNormalDate(newLead.next_followup_date);
  //
  //         axios.post(`${ATLAS_URI}/updateLead/${routeParams.id}`, newLead, configToken)
  //             .then(() => {
  //

  //                 let list = [...leadsList]
  //                 var foundIndex = list.findIndex(x => x.id == routeParams.id);
  //                 list[foundIndex] = newLead;
  //                 updateList('leadsList', list)

  //                 setState(prevState => ({
  //                     ...prevState,
  //                     editingActivated: false,
  //                     newTableRow: state.resetNewRow
  //                 }))

  // //window.location.reload()

  //                 redirectFromEditDetails('/Leads/listLeads')

  //             })
  //             .catch(err => {
  //

  //             })

  //     }

  async function addLeadIfNotExist() {
    if (
      state.newTableRow.assigned_to === "" &&
      state.newTableRow.Remarks.length === 0 &&
      state.newTableRow.VisitInfos.length === 0 &&
      state.newTableRow.Payments.length === 0
    ) {
      await axios
        .post(
          `${ATLAS_URI}/updateLead/${routeParams.id}`,
          { id: routeParams.id },
          configToken
        )
        .then((res) => {
          if (res.status === 200) {
          }
        })
        .catch((err) => console.error(err));
    }
  }
  function insertIntoRemarksList(e) {
    e.preventDefault();

    addLeadIfNotExist();
    const tempRemarks = $("#newRemarks").val();
    let newRemarksList = [...state.newTableRow.Remarks];
    const addRemarks = {
      Remarks: tempRemarks,
      Date: getFormattedDate(),
      User: operator.Name,
      LeadId: state.newTableRow.id,
    };

    axios
      .post(`${ATLAS_URI}/addRemarks/`, addRemarks, configToken)
      .then((res) => {
        if (res.status === 200) {
          addRemarks.id = res.data.id;
          newRemarksList.push(addRemarks);

          let list = [...leadsList];
          var foundIndex = list.findIndex((x) => x.id == routeParams.id);
          list[foundIndex].Remarks = newRemarksList;
          updateList("leadsList", list);

          setState((prevState) => ({
            ...prevState,
            newTableRow: {
              ...prevState.newTableRow,
              Remarks: newRemarksList,
            },
          }));
          $("#newRemarks").val("");
        }
      })
      .catch((err) => console.error(err));
  }

  function insertIntoVisitList(e) {
    e.preventDefault();
    addLeadIfNotExist();
    if (state.Project === "Select") {
      const newDialogInfo = {
        isOpened: true,
        text: "Project is left Empty",
        type: "Error",
      };
      setState((prevState) => ({ ...prevState, dialogInfo: newDialogInfo }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          dialogInfo: { isOpened: false, text: "", type: "" },
        }));
      }, 3000);
    } else if (state.Property === "Select") {
      const newDialogInfo = {
        isOpened: true,
        text: "Property is left Empty",
        type: "Error",
      };
      setState((prevState) => ({ ...prevState, dialogInfo: newDialogInfo }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          dialogInfo: { isOpened: false, text: "", type: "" },
        }));
      }, 3000);
    } else {
      const tempProject = state.projectsList.filter(
        (project) => project.id.toString() === state.Project
      )[0].Name;
      const tempProperty = state.propertiesList.filter(
        (property) => property.id.toString() === state.Property
      )[0].PropertyTitle;

      const tempDescription = $("#newDescription").val();
      const tempVisitDate = $("#newVisitDate").val();
      const tempStatus = $("#newVisitStatus").val();

      let newVisitList = [...state.newTableRow.VisitInfos];

      const addedVisit = {
        Project: tempProject,
        Property: tempProperty,
        VisitDate: getNormalDate(tempVisitDate),
        Description: tempDescription,
        CreatedBy: operator.Name,
        VisitStatus: tempStatus,
        LeadId: state.newTableRow.id,
      };

      axios
        .post(`${ATLAS_URI}/addVisitInfo/`, addedVisit, configToken)
        .then((res) => {
          if (res.status === 200) {
            addedVisit.id = res.data.id;
            newVisitList.push(addedVisit);

            let list = [...leadsList];
            var foundIndex = list.findIndex(
              (x) => x.id.toString() == routeParams.id.toString()
            );
            list[foundIndex].VisitInfos = newVisitList;

            updateList("leadsList", list);

            setState((prevState) => ({
              ...prevState,
              newTableRow: {
                ...prevState.newTableRow,
                VisitInfos: newVisitList,
              },
              Project: "Select",
              Property: "Select",
            }));
            $("#newDescription").val("");
            $("#newVisitDate").val("");
          }
        })
        .catch((err) => console.error(err));
    }
  }

  function insertIntoPaymentList(e) {
    e.preventDefault();
    addLeadIfNotExist();
    const tempDescription = $("#newPaymentDescription").val();
    const tempDate = $("#newPaymentDate").val();
    const tempStatus = $("#newPaymentType").val();
    const tempAmount = $("#newAmount").val();

    const addedPayment = {
      Amount: tempAmount,
      Type: tempStatus,
      Date: getNormalDate(tempDate),
      Description: tempDescription,
      LeadId: routeParams.id,
    };
    let newPayment = [...state.newTableRow.Payments];
    let client = {
      Name: state.newTableRow.name,
      Phone: state.newTableRow.contact_number,
      Email: state.newTableRow.email_id,
      Lead: routeParams.id,

      DOB: state.newTableRow.dob,
      Address: state.newTableRow.address,
      City: state.newTableRow.city,
      State: state.newTableRow.state,
      PINCode: state.newTableRow.pincode,
      Occupation: state.newTableRow.occupation,
      // Organization: state.newTableRow.,
      // Designation: state.newTableRow.,
      Source: state.newTableRow.source,
    };

    axios
      .post(`${ATLAS_URI}/addPayment/`, addedPayment, configToken)
      .then((res) => {
        if (res.status === 200) {
          axios.post(`${ATLAS_URI}/addClient/`, client, configToken);

          addedPayment.id = res.data.id;
          newPayment.push(addedPayment);

          setState((prevState) => ({
            ...prevState,
            newTableRow: {
              ...prevState.newTableRow,
              Payments: newPayment,
            },
          }));

          let list = [...leadsList];
          var foundIndex = list.findIndex(
            (x) => x.id.toString() == routeParams.id.toString()
          );
          list[foundIndex].Payments = newPayment;
          list[foundIndex].Payment = "Paid";
          updateList("leadsList", list);

          $("#newPaymentDescription").val("");
          $("#newPaymentDate").val("");
          $("#newPaymentType").val("");
          $("#newAmount").val("");
        }
      })
      .catch((err) => console.error(err));
  }

  function changeHandler(e) {
    oldChangeHandler(e, state, setState);
  }

  //    function handleChange(e) {
  //         let newValue;

  //         if (e.target.type === "checkbox") {
  //             newValue = [...state.newTableRow.current_medical_condition]
  //             if (e.target.checked) {
  //                 newValue.push(e.target.value)
  //             } else {
  //                 newValue = newValue.filter(data => data !== e.target.value)
  //             }
  //         }
  //         setState({
  //           newTableRow:{
  //   current_medical_condition:newValue
  //           }
  //         })
  //
  //     // let newValue1=e.target.value

  //     // this.props.handleAssignedTo(e.target.value)
  // }
  // const [checked, setChecked] = useState([])
  const handleCheck = (event) => {
    let updatedList = [...state.newTableRow.current_medical_condition];
    if (event.target.type === "checkbox") {
      updatedList = [
        ...state.newTableRow.current_medical_condition,
        event.target.value,
      ];
      //   updatedList.filter((_,id)=>id==i)
    } else {
      updatedList.splice(
        state.newTableRow.current_medical_condition.indexOf(event.target.value),
        1
      );
    }
    setState((prevState) => ({
      ...prevState,
      newTableRow: {
        ...prevState.newTableRow,
        current_medical_condition: updatedList,
      },
    }));

    //  second method----

    // const { name, checked } = event.target;
    // let tempMedicalCondition=state.newTableRow.current_medical_condition.map((medical)=>{return medical?{...medical,isChecked:checked}:medical})

    // setState(prevState => ({
    //         ...prevState,
    //         newTableRow: {
    //             ...prevState.newTableRow,
    //             current_medical_condition: tempMedicalCondition
    //         }
    //     }))

    // third method......

    //     let newValue;

    //         if (e.target.type === "checkbox") {
    //             newValue = [...state.newTableRow.current_medical_condition]
    //             if (e.target.checked) {
    //                 newValue.push(e.target.value)
    //             } else {
    //                 newValue = newValue.filter(data => data !== e.target.value)
    //             }
    //         }
    //         setState({
    //           newTableRow:{
    //   current_medical_condition:newValue
    //           }
    //         })
    //   };

    //   let isChecked = (item) =>
    //     state.newTableRow.checked.includes(item) ?  : "not-checked-item";

    // const checkedItems = state.newTableRow.checked.length
    //     ? state.newTableRow.checked.reduce((total, item) => {
    //         return total + ", " + item;
    //       })
    //     : "";
  };
  //  on 11/10/2022 I changed All AssignedTo to assigned_to

  /* go Back To Previous Page */

  const history = useHistory();
  const goBackToPreviousPage = () => {
    localStorage.setItem("RESTORE_LEAD_LISTING_SCREEN_GRID", true);
    localStorage.setItem("RESTORE_LEAD_LISTING_SCREEN_PANEL", true);
    // history.goBack();
    history.push("/Leads/listLeads");
  };

  const [isLeadStatusChange, setIsLeadStatusChange] = useState(false);

  return (
    <section className="content">
      <div className="row">
        {typeof state.dialogInfo !== "undefined" && (
          <Dialog
            onFalse={(e) =>
              setState((prevState) => ({
                ...prevState,
                dialogInfo: { isOpened: false, text: "" },
              }))
            }
            dialogInfo={state.dialogInfo}
          />
        )}

        <div className="col-md-12">
          <div className="box box-primary ">
            <Button
              onClick={goBackToPreviousPage}
              variant="outlined"
              sx={{ margin: "8px 12px" }}
            >
              <ArrowBackOutlinedIcon /> Back
            </Button>
            <div className="d-flex justify-content-between pe-3">
              <BoxHeader
                title={`${state.editingActivated ? " " : "Add"} Lead Details`}
              />
            </div>

            <br />
            {/* {
              showLoader?
            
            

<div  className="loader">

</div>: */}
            <form onSubmit={formik.handleSubmit}>
              <fieldset>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Name</label>
                        <small className="req"> *</small>
                        <label className="col-md-8 floatclass" id="alignment">
                          <input
                            name="name"
                            type="text"
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.errors.name && formik.touched.name
                                ? "form-control input-error"
                                : "form-control"
                            }
                          />
                        </label>
                      </div>
                      {formik.errors.name && formik.touched.name && (
                        <p className="error-message">{formik.errors.name}</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Profession</label>

                        <label className="col-md-8 floatclass" id="alignment">
                          <input
                            name="occupation"
                            onBlur={formik.handleBlur}
                            value={formik.values.occupation}
                            type="occupation"
                            id="clientEmail"
                            className={
                              formik.errors.occupation &&
                              formik.touched.occupation
                                ? "form-control input-error"
                                : "form-control"
                            }
                            onChange={formik.handleChange}
                          />
                        </label>
                      </div>
                      {/* {formik.errors.occupation && formik.touched.occupation && (
                    <p className="error-message">{formik.errors.occupation}</p>
                  )} */}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Stage<small className="req">*</small>
                        </label>

                        <label className="col-md-8 floatclass" id="alignment">
                          <select
                            name="vmax_lead_stage_id"
                            value={leadStage}
                            onChange={handleChangeLeadStage}
                            className={
                              formik.errors.vmax_lead_stage_id &&
                              formik.touched.vmax_lead_stage_id
                                ? "form-control input-error"
                                : "form-control"
                            }
                          >
                            <option value="">Select</option>
                            {leadStageList.map((stage) => (
                              <option value={stage.id} key={stage.id}>
                                {stage.leadStage}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                      {leadStageErr ? (
                        <p className="error-message">Please Select Somethig</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Notes</label>
                        <label className="col-md-8 floatclass" id="alignment">
                          <input
                            name="notes"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="text"
                            className="form-control"
                          />
                        </label>
                        {formik.errors.notes && formik.touched.notes && (
                          <p className="error-message">{formik.errors.notes}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Country</label>
                        <label className="col-md-8 floatclass" id="alignment">
                          <input
                            name="country"
                            value={formik.values.country}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            id="clientEmail"
                            className="form-control"
                          />
                        </label>
                      </div>
                      {formik.errors.country && formik.touched.country && (
                        <p className="error-message">{formik.errors.country}</p>
                      )}
                    </div>

                    {/* <div className="col-md-4">
                  <div className="form-group">
                    <label>Account Name</label>
                    <label className="col-md-8 floatclass" id="alignment">
                      <input
                        name="account_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.account_name}
                        type="text"
                        id="account_name"
                        className="form-control"
                      />
                    </label>
                  </div>
                  {formik.errors.account_name &&
                    formik.touched.account_name && (
                      <p className="error-message">
                        {formik.errors.account_name}
                      </p>
                    )}
                    </div> */}
                    {/* <div className="form-group">
                    <label>Date Of Birth</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="dob"
                        type="date"
                        className="form-control"
                        onBlur={formik.handleBlur}
                        value={formik.values.dob}
                        onChange={formik.handleChange}
                      />
                    </label>
                  </div>
                  {formik.errors.dob &&
                    formik.touched.dob && (
                      <p className="error-message">
                        {formik.errors.dob}
                      </p>
                    )} */}

                    <div className="col-md-4">
                      <div className="form-group">
                        {leadStage == "5" || leadStage == "7" ? (
                          <></>
                        ) : (
                          <>
                            <label>
                              {" "}
                              Disposition<small className="req">*</small>
                            </label>

                            <label
                              className="col-md-8 floatclass"
                              id="alignment"
                            >
                              <select
                                value={leadDispositionId}
                                onChange={handleChangeDisposition}
                                className="form-control"
                              >
                                <option value="">Select</option>
                                {leadDisposition.map((stage) => (
                                  <option value={stage.id}>
                                    {stage.leadDisposition}
                                  </option>
                                ))}
                              </select>
                            </label>
                          </>
                        )}
                      </div>
                      {dispositionErr ? (
                        <p className="error-message">Please Select Something</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Phone Number</label>{" "}
                        <small className="req"> *</small>&nbsp;&nbsp;&nbsp;
                        <label className="col-md-8 floatclass" id="alignment">
                          {/* <input name='contact_number' value={state.newTableRow.contact_number} onChange={changeHandler} type="dfstext" id='clientPhone'  className="form-control" /> */}
                          <PhoneInput
                            value={formik.values.contact_number}
                            onChange={(e) => {
                              formik.setFieldValue("contact_number", "+" + e);
                            }}
                            country={"in"}
                            enableSearch={true}
                            inputStyle={
                              formik.errors.contact_number &&
                              formik.touched.contact_number
                                ? {
                                    paddingLeft: "5%",
                                    backgroundColor: "#fce4e4",
                                    borderColor: "#ff01416a",
                                    width: "300px",
                                  }
                                : { paddingLeft: "15%" }
                            }
                          />
                        </label>
                      </div>

                      {formik.errors.contact_number &&
                        formik.touched.contact_number && (
                          <p className="error-message">
                            {formik.errors.contact_number}
                          </p>
                        )}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Height</label>
                        <small className="req"> *</small>
                        <label className="col-md-8 floatclass" id="alignment">
                          <div style={{ display: "flex" }}>
                            <input
                              placeholder={"eg. 5'5\" for ft'in or 150 for cms"}
                              name="height"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.height}
                              type="text"
                              id="clientEmail"
                              className={
                                formik.errors.height && formik.touched.height
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                            />

                            <select
                              className={
                                formik.errors.height_unit &&
                                formik.touched.height_unit
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                              name="height_unit"
                              onBlur={formik.handleBlur}
                              value={formik.values.height_unit}
                              onChange={formik.handleChange}
                            >
                              <option value="">Select</option>
                              <option value="0">cms</option>
                              <option value="1">ft'in</option>
                            </select>
                          </div>
                        </label>
                      </div>
                      {formik.errors.height && formik.touched.height && (
                        <p className="error-message">{formik.errors.height}</p>
                      )}

                      {formik.errors.height_unit &&
                        formik.touched.height_unit && (
                          <p className="error-message">
                            {formik.errors.height_unit}
                          </p>
                        )}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        {
                          leadStage == "5" ||
                          leadStage == "7" ||
                          (leadStage == "8" && leadDispositionId == "1") ? (
                            <></>
                          ) : (
                            //  leadSubDisposition.length !== 0?
                            <>
                              <label>
                                {" "}
                                Sub Disposition<small className="req">*</small>
                              </label>

                              <label
                                className="col-md-8 floatclass"
                                id="alignment"
                              >
                                <select
                                  value={leadSubDispositionId}
                                  onChange={handleChangeSubDisposition}
                                  className="form-control"
                                >
                                  <option value="">Select</option>
                                  {leadSubDisposition.map((stage) => (
                                    <option value={stage.id}>
                                      {stage.leadSubDisposition}
                                    </option>
                                  ))}
                                </select>
                              </label>
                            </>
                          )
                          // :""
                        }
                      </div>
                      {subDispositionErr ? (
                        <p className="error-message">Please Select Something</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Email Id</label>{" "}
                        <small className="req"> *</small>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <label className="col-md-8 floatClass" id="alignment">
                          <input
                            name="email_id"
                            type="text"
                            className={
                              formik.errors.email_id && formik.touched.email_id
                                ? "form-control input-error"
                                : "form-control"
                            }
                            value={formik.values.email_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </label>
                      </div>
                      {formik.errors.email_id && formik.touched.email_id && (
                        <p className="error-message">
                          {formik.errors.email_id}
                        </p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Current Weight</label>
                        <small className="req"> *</small>
                        <label className="col-md-8 floatclass" id="alignment">
                          <div style={{ display: "flex" }}>
                            <input
                              name="current_weight"
                              value={formik.values.current_weight}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              type="text"
                              id="clientEmail"
                              className={
                                formik.errors.current_weight &&
                                formik.touched.current_weight
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                            />

                            <select
                              className={
                                formik.errors.weight_unit &&
                                formik.touched.weight_unit
                                  ? "form-control input-error"
                                  : "form-control"
                              }
                              name="weight_unit"
                              onBlur={formik.handleBlur}
                              value={formik.values.weight_unit}
                              onChange={formik.handleChange}
                            >
                              <option value="">Select</option>
                              <option value="0">kg</option>
                              <option value="1">lb</option>
                            </select>
                          </div>
                        </label>
                      </div>
                      {formik.errors.current_weight &&
                        formik.touched.current_weight && (
                          <p className="error-message">
                            {formik.errors.current_weight}
                          </p>
                        )}
                      {formik.errors.weight_unit &&
                        formik.touched.weight_unit && (
                          <p className="error-message">
                            {formik.errors.weight_unit}
                          </p>
                        )}
                    </div>

                    {grandDisposition.length !== 0 &&
                    leadStage == "3" &&
                    leadDispositionId == "1" &&
                    leadSubDispositionId == "4" ? (
                      // (leadStage=='3' && leadDispositionId=='1' && leadSubDispositionId=='4') ?
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            {" "}
                            Grand Disposition<small className="req">*</small>
                          </label>

                          <label className="col-md-8 floatclass" id="alignment">
                            <select
                              value={grandDispositionId}
                              onChange={handleChangeGrandDisposition}
                              className="form-control"
                            >
                              <option value="">Select</option>
                              {grandDisposition.map((stage) => (
                                <option value={stage.id}>
                                  {stage.leadSubNotInterested}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="form-group">
                    <label>Location</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="location"
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        id="clientEmail"
                        className="form-control"
                      />
                    </label>
                  </div> */}

                  {/*

                  <div className="form-group">
                    <label>State</label>
                    <label className="col-md-8 floatclass">
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.state}
                        onBlur={formik.handleBlur}
                        name="state"
                        type="text"
                        id="clientEmail"
                        className="form-control"
                      />
                    </label>
                  </div>
                  {formik.errors.state && formik.touched.state && (
                    <p className="error-message">{formik.errors.state}</p>
                  )}

                  <div className="form-group">
                    <label>Country</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="country"
                        value={formik.values.country}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        id="clientEmail"
                        className="form-control"
                      />
                    </label>
                  </div>
                  {formik.errors.country && formik.touched.country && (
                    <p className="error-message">{formik.errors.country}</p>
                  )}
                  <div className="form-group">
                    <label>Pincode</label>
                    <label className="col-md-8 floatclass">
                      <input
                        name="pincode"
                        value={formik.values.pincode}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        id="clientEmail"
                        className="form-control"
                      />
                    </label>
                  </div>
                  {formik.errors.pincode && formik.touched.pincode && (
                    <p className="error-message">{formik.errors.pincode}</p>
                  )} */}
                  {/* <div className="form-group">
                    <label>Mother Tongue</label>
                    <label className="col-md-8 floatclass">
                      <select
                        className="form-control"
                        name="mother_tongue"
                        onBlur={formik.handleBlur}
                        value={formik.values.mother_tongue}
                        onChange={formik.handleChange}
                      >
                        <option value="">Select</option>
                        <option value="English">English</option>
                        val<option value="Tamil">Tamil</option>
                        <option value="Telugu">Telugu</option>
                        <option value="Hindi">Hindi</option>
                        <option value="Bengali">Bengali</option>
                        <option value="Kannada">Kannada</option>
                        <option value="Urdu">Urdu</option>
                        <option value="Malayalam">Malayalam</option>
                      </select>
                    </label>
                  </div> */}

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group ">
                        <label
                          className={
                            formik.errors.gender && formik.touched.gender
                              ? "gender-error"
                              : ""
                          }
                        >
                          Gender :
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <span
                        // className="leftside_move"
                        // style={{ position: "relative", left: "300px" }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <input
                            name="gender"
                            onChange={formik.handleChange}
                            checked={
                              formik.values.gender == "M" ||
                              formik.values.gender == "Male"
                            }
                            value="M"
                            type="radio"
                            id="clientEmail"
                          />
                          &nbsp;&nbsp;<label>Male</label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            name="gender"
                            onChange={formik.handleChange}
                            checked={
                              formik.values.gender == "F" ||
                              formik.values.gender == "Female"
                            }
                            value="F"
                            type="radio"
                            id="clientEmail"
                          />
                          &nbsp;&nbsp;<label>Female</label>
                          <br />
                        </span>
                      </div>
                      {formik.errors.gender && formik.touched.gender && (
                        <p className="error-message">{formik.errors.gender}</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>City</label>
                        <label className="col-md-8 floatclass" id="alignment">
                          <input
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="text"
                            id="clientEmail"
                            className="form-control"
                          />
                        </label>
                        {formik.errors.city && formik.touched.city && (
                          <p className="error-message">{formik.errors.city}</p>
                        )}
                      </div>
                    </div>

                    {isDisplayFollowUp !== null && isDisplayFollowUp && (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Next
                            <br /> Followup
                            <br /> Date
                          </label>
                          <label
                            className="col-md-8 floatclass"
                            id="alignment"
                            style={{ float: "right", marginRight: "20px" }}
                          >
                            <input
                              name="next_followup_date"
                              type="datetime-local"
                              className="form-control"
                              value={formik.values.next_followup_date}
                              onChange={formik.handleChange}
                            />
                          </label>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Age</label>
                        <label className="col-md-8 floatclass" id="alignment">
                          <input
                            name="age"
                            className="form-control"
                            onBlur={formik.handleBlur}
                            value={formik.values.age}
                            onChange={formik.handleChange}
                          />
                        </label>
                      </div>
                      {formik.errors.age && formik.touched.age && (
                        <p className="error-message">{formik.errors.age}</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <div style={{ position: "relative", bottom: "15px" }}> */}
                        <SelectBox
                          label="Account Name"
                          name="source"
                          formikdata={formik.errors.source}
                          formikTouched={formik.touched.source}
                          options={state.sourcesList}
                          isEdit={true}
                          attributeShown="Source"
                          changeHandler={formik.handleChange}
                          value={formik.values.source ?? ""}
                          resetValue={() =>
                            setState((prevState) => ({
                              newTableRow: {
                                ...prevState.newTableRow,
                                source: "Select",
                              },
                            }))
                          }
                          show1={true}
                        />

                        <br />

                        {formik.errors.source && formik.touched.source && (
                          <p className="error-message">
                            {formik.errors.source}
                          </p>
                        )}
                        {/* </div> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        {operator.Feature &&
                        operator.Feature.find(
                          (fe) =>
                            fe.sub_feature_info.sub_feature_name ==
                            "Lead Allocation"
                        ).is_edit ? (
                          // {operator.Role === ADMIN &&

                          <SelectBox
                            label="AssignedTo"
                            name="assigned_to"
                            isEdit={true}
                            formikdata={formik.errors.assigned_to}
                            formikTouched={formik.touched.assigned_to}
                            options={state.userList}
                            attributeShown="assigned_to"
                            changeHandler={formik.handleChange}
                            value={formik.values.assigned_to}
                            resetValue={() =>
                              setState((prevState) => ({
                                newTableRow: {
                                  ...prevState.newTableRow,
                                  assigned_to: "Select",
                                },
                              }))
                            }
                            show={true}
                            onAssignedTo={formik}
                            isLeadStatusChange={isLeadStatusChange}
                          />
                        ) : (
                          <div className="form-group">
                            <label>Assigned To</label>
                            <p>
                              {state.newTableRow?.assigned_to_user?.Username}
                            </p>
                          </div>
                        )}
                      </div>
                      {formik.errors.assigned_to &&
                        formik.touched.assigned_to && (
                          <p className="error-message">
                            {formik.errors.assigned_to}
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Preferred Language</label>
                  <small className="req"> *</small>
                  {/* <input name='preferred_language' value={formik.values.preferred_language} type="preferred_language" id='clientEmail' className="form-control" onChange={formik.handleChange} /> */}
                  <div className="row">
                    <div className="col-md-3 px-5 ">
                      <div className="form-group">
                        <input
                          type="radio"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="English"
                          checked={
                            formik.values.preferred_language == "English"
                          }
                          name="preferred_language"
                        />
                        &nbsp;&nbsp;<label>English</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group">
                        <input
                          type="radio"
                          value="Tamil"
                          onChange={formik.handleChange}
                          name="preferred_language"
                          checked={formik.values.preferred_language == "Tamil"}
                        />
                        &nbsp;&nbsp;<label>Tamil</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group">
                        <input
                          type="radio"
                          value="Telugu"
                          checked={formik.values.preferred_language == "Telugu"}
                          onChange={formik.handleChange}
                          name="preferred_language"
                        />
                        &nbsp;&nbsp;<label>Telugu</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group">
                        <input
                          type="radio"
                          value="Hindi"
                          checked={formik.values.preferred_language == "Hindi"}
                          onChange={formik.handleChange}
                          name="preferred_language"
                        />
                        &nbsp;&nbsp;<label>Hindi</label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 px-5">
                      <div className="form-group">
                        <input
                          type="radio"
                          value="Bengali"
                          checked={
                            formik.values.preferred_language == "Bengali"
                          }
                          onChange={formik.handleChange}
                          name="preferred_language"
                        />
                        &nbsp;&nbsp;<label>Bengali</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group">
                        <input
                          type="radio"
                          value="Kannada"
                          onChange={formik.handleChange}
                          name="preferred_language"
                          checked={
                            formik.values.preferred_language == "Kannada"
                          }
                        />
                        &nbsp;&nbsp;<label>Kannada</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group">
                        <input
                          type="radio"
                          checked={formik.values.preferred_language == "Urdu"}
                          value="Urdu"
                          onChange={formik.handleChange}
                          name="preferred_language"
                        />
                        &nbsp;&nbsp;<label>Urdu</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group">
                        <input
                          type="radio"
                          value="Malayalam"
                          checked={
                            formik.values.preferred_language == "Malayalam"
                          }
                          onChange={formik.handleChange}
                          name="preferred_language"
                        />
                        &nbsp;&nbsp;<label>Malayalam</label>
                      </div>
                    </div>
                  </div>
                </div>
                {formik.errors.preferred_language &&
                  formik.touched.preferred_language && (
                    <p className="error-message">
                      {formik.errors.preferred_language}
                    </p>
                  )}

                {/* <div className="form-group">
                    <label>Work Hours</label>
                    <label className="col-md-8 floatclass">
                      <input
                        placeholder="Eg : 9 - 5"
                        name="work_hours"
                        onBlur={formik.handleBlur}
                        value={formik.values.work_hours}
                        onChange={formik.handleChange}
                        type="text"
                        id="clientEmail"
                        className="form-control"
                      />
                    </label>
                  </div> */}

                {/* <div className="form-group">
                    <label>Family Details</label>
                    <label className="col-md-8 floatClass">
                      <input
                        name="family_details"
                        type="text"
                        className="form-control"
                        value={formik.values.family_details}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </label>
                  </div> */}

                {/* <div className="form-group">
                    <label>Emergency Contact</label>
                    <label className="col-md-8 floatclass">
                     <PhoneInput
                        value={formik.values.emergency_contact}
                        onChange={(e) => {
                      
                          formik.setFieldValue("emergency_contact",`${e ? '+' :''}`+ e);
                        }}
                        
                        
                        country={"in"}
                        enableSearch={true}
                        inputStyle={{ paddingLeft: "5%" }}
                      />
                    </label>
                    {formik.errors.emergency_contact && formik.touched.emergency_contact && (
                    <p className="error-message">{formik.errors.emergency_contact}</p>
                  )}
                  </div> */}

                <div className="form-group">
                  <label
                    className={
                      formik.errors.current_medical_condition &&
                      formik.touched.current_medical_condition
                        ? "checkbox-error"
                        : ""
                    }
                  >
                    Current Medical Condition
                  </label>
                  {/* <small className="req"> *</small> */}

                  <div className="row">
                    <div className="col-md-3 px-5">
                      <div className="form-group "></div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group ">
                        <input
                          type="checkbox"
                          value="Diabetes"
                          checked={formik.values.current_medical_condition?.find(
                            (x) => x == "Diabetes"
                          )}
                          onChange={formik.handleChange}
                          disabled={
                            currentMedicalCondition?.some((e) => e == "None") ||
                            formik.values.current_medical_condition?.some(
                              (e) => e == "None"
                            )
                          }
                          name="current_medical_condition"
                        />
                        &nbsp;&nbsp;<label>Diabetes</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group ">
                        <input
                          type="checkbox"
                          value="Cholesterol"
                          checked={formik.values.current_medical_condition?.find(
                            (x) => x == "Cholesterol"
                          )}
                          onChange={formik.handleChange}
                          disabled={
                            currentMedicalCondition?.some((e) => e == "None") ||
                            formik.values.current_medical_condition?.some(
                              (e) => e == "None"
                            )
                          }
                          name="current_medical_condition"
                        />
                        &nbsp;&nbsp;<label>Cholesterol</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5 ">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          value="Hypertension"
                          checked={formik.values.current_medical_condition?.find(
                            (x) => x == "Hypertension"
                          )}
                          onChange={formik.handleChange}
                          disabled={
                            currentMedicalCondition?.some((e) => e == "None") ||
                            formik.values.current_medical_condition?.some(
                              (e) => e == "None"
                            )
                          }
                          name="current_medical_condition"
                        />
                        &nbsp;&nbsp;<label>Hypertension</label>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-3 px-5">
                      <div className="form-group ">
                        <input
                          type="checkbox"
                          value="PCOS"
                          checked={formik.values.current_medical_condition?.find(
                            (x) => x == "PCOS"
                          )}
                          onChange={formik.handleChange}
                          disabled={
                            currentMedicalCondition?.some((e) => e == "None") ||
                            formik.values.current_medical_condition?.some(
                              (e) => e == "None"
                            )
                          }
                          name="current_medical_condition"
                        />
                        &nbsp;&nbsp;<label>PCOS</label>
                      </div>
                    </div>
                    <div className="col-md-3   px-5">
                      <div className="form-group ">
                        <input
                          type="checkbox"
                          value="Thyroid"
                          checked={formik.values.current_medical_condition?.find(
                            (x) => x == "Thyroid"
                          )}
                          onChange={formik.handleChange}
                          disabled={
                            currentMedicalCondition?.some((e) => e == "None") ||
                            formik.values.current_medical_condition?.some(
                              (e) => e == "None"
                            )
                          }
                          name="current_medical_condition"
                        />
                        &nbsp;&nbsp;<label>Thyroid</label>
                      </div>
                    </div>
                    <div className="col-md-3  px-5">
                      <div className="form-group ">
                        <input
                          type="checkbox"
                          value="Physical Injury"
                          checked={formik.values.current_medical_condition?.find(
                            (x) => x == "Physical Injury"
                          )}
                          onChange={formik.handleChange}
                          disabled={
                            currentMedicalCondition?.some((e) => e == "None") ||
                            formik.values.current_medical_condition?.some(
                              (e) => e == "None"
                            )
                          }
                          name="current_medical_condition"
                        />
                        &nbsp;<label>Physical Injury</label>
                      </div>
                    </div>
                    <div className="col-md-3 px-5">
                      <div className="form-group ">
                        <input
                          type="checkbox"
                          value="None"
                          checked={formik.values.current_medical_condition?.find(
                            (x) => x == "None"
                          )}
                          onChange={formik.handleChange}
                          disabled={
                            (currentMedicalCondition?.some(
                              (e) => e !== "None"
                            ) &&
                              currentMedicalCondition?.length > 0) ||
                            (formik.values.current_medical_condition?.some(
                              (e) => e !== "None"
                            ) &&
                              state.newTableRow.current_medical_condition
                                .length > 0)
                          }
                          name="current_medical_condition"
                        />
                        &nbsp;&nbsp;<label>None</label>
                      </div>
                    </div>
                  </div>
                  {formik.errors.current_medical_condition &&
                    formik.touched.current_medical_condition && (
                      <p className="error-message">
                        {formik.errors.current_medical_condition}
                      </p>
                    )}
                </div>

                <div className="row">
                  {/* <div className="col-md-4">
                <div className="form-group">
                  <label
                    style={{
                      position: "relative",
                      top: "10px",
                      alignSelf: "flex-start",
                    }}
                  >
                    Lead Status
                  </label>{" "}
                  <small className="req"> *</small>
                  <label className="col-md-8 leadstatus">
                    <select
                      className={
                        formik.errors.lead_status && formik.touched.lead_status
                          ? "form-control input-error"
                          : "form-control"
                      }
                      name="lead_status"
                      value={formik.values.lead_status}
                      onChange={(e) => {
                        setIsLeadStatusChange(true);
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">{"Select"}</option>
                      {statusList.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.Status}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                </div> */}
                </div>

                <div className="row">
                  {formik.errors.lead_status && formik.touched.lead_status && (
                    <p className="error-message">{formik.errors.lead_status}</p>
                  )}

                  {formik.errors.InterestedIn &&
                    formik.touched.InterestedIn && (
                      <p className="error-message">
                        {formik.errors.InterestedIn}
                      </p>
                    )}

                  {/* <div className="form-group col-md-12">
                  <label>Call Status</label> <small className="req"> *</small>
                  <label className="col-md-8 floatclass">
                    <select
                      className={
                        formik.errors.call_status && formik.touched.call_status
                          ? "form-control input-error"
                          : "form-control"
                      }
                      name="call_status"
                      value={formik.values.call_status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select</option>
                      <option value="Not Contacted">Not Contacted</option>
                      {formik.values.lead_status !== "Un-Attempted" && (
                        <option value="Contacted">Contacted</option>
                      )}
                    </select>
                  </label>
                </div>
                {formik.errors.call_status && formik.touched.call_status && (
                  <p className="error-message">{formik.errors.call_status}</p>
                )} */}
                </div>

                <div className="row"></div>

                <br />
                <div className="box-footer">
                  <button type="submit" className="btn btn-info pull-right">
                    Update
                  </button>
                </div>
              </fieldset>
            </form>
            {/* } */}
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="box box-primary">
            {/* <div className="d-flex justify-content-between"> */}
            <BoxHeader title="Program List" />
            <br />
            <ProgramDetails
              lead={formik.values}
              getupdatevalue={getupdatevalue}
            />
            {/* </div> */}
          </div>
          {/* <div className="box box-primary">
            <div>
              <BoxHeader title="Add Payment" />
              {formik.dirty && (
                <p className="mt-3 error-message">
                  * Save the changes to add payment
                </p>
              )}
            </div>

            <div className="box-body">
              <form onSubmit={paymentFormik.handleSubmit}>
                <fieldset disabled={formik.dirty}>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group">
                        <label>Amount</label>
                        <small className="req"> *</small>
                        <input
                          name="Amount"
                          type="text"
                          className={
                            paymentFormik.errors.Amount &&
                            paymentFormik.touched.Amount
                              ? "form-control input-error"
                              : "form-control"
                          }
                          id="newAmount"
                          value={paymentFormik.values.Amount}
                          onChange={paymentFormik.handleChange}
                          onBlur={paymentFormik.handleBlur}
                        />
                        {paymentFormik.errors.Amount &&
                          paymentFormik.touched.Amount && (
                            <p className="error-message">
                              {paymentFormik.errors.Amount}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Payment Type</label>
                        <small className="req"> *</small>
                        <select
                          id="newPaymentType"
                          className={
                            paymentFormik.errors.payment_type &&
                            paymentFormik.touched.payment_type
                              ? "form-control input-error"
                              : "form-control"
                          }
                          name="payment_type"
                          value={paymentFormik.values.payment_type}
                          onChange={paymentFormik.handleChange}
                          onBlur={paymentFormik.handleBlur}
                        >
                          <option value="">Select</option>
                          <option value="Cash">Cash</option>
                          <option value="Cheque">Cheque</option>
                          <option value="Card">Card</option>
                          <option value="Net Banking">Net Banking</option>
                          <option value="Paytm">Paytm</option>
                          <option value="Razorpay">Razorpay</option>
                          <option value="Upi">Upi</option>
                          <option value="Phonepe">Phonepe</option>
                          <option value="Gpay">Gpay</option>
                          <option value="Easypay">Easypay</option>
                          <option value="Others">Others</option>
                        </select>
                        {paymentFormik.errors.payment_type &&
                          paymentFormik.touched.payment_type && (
                            <p className="error-message">
                              {paymentFormik.errors.payment_type}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Payment Date</label>{" "}
                        <small className="req"> *</small>
                        <input
                          id="newPaymentDate"
                          name="PaymentDate"
                          type="date"
                          onChange={paymentFormik.handleChange}
                          onBlur={paymentFormik.handleBlur}
                          className={
                            paymentFormik.errors.PaymentDate &&
                            paymentFormik.touched.PaymentDate
                              ? "form-control input-error"
                              : "form-control"
                          }
                        />
                        {paymentFormik.errors.PaymentDate &&
                          paymentFormik.touched.PaymentDate && (
                            <p className="error-message">
                              {paymentFormik.errors.PaymentDate}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Paid For</label>
                        <select
                          id="PaidFor"
                          name="PaidFor"
                          className="form-control"
                          value={paymentFormik.values.PaidFor}
                          onChange={paymentFormik.handleChange}
                          onBlur={paymentFormik.handleBlur}
                          required
                        >
                          
                          {state.paidFor.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>
                                {data.displayValue}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Description</label>

                    <textarea
                      name="Description"
                      id="newPaymentDescription"
                      className="form-control"
                      rows="4"
                    ></textarea>
                  </div>

                  <div className="box-footer">
                    <button type="submit" className="btn btn-info pull-right">
                      Add
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div> */}

          <div className="box box-primary">
            <BoxHeader title="Payment History" />

            <div className="box-body">
              {typeof state.newTableRow?.lead_payments !== "undefined" && (
                <DataTable
                  tableHeader={[
                    "id",
                    "Amount",
                    "Type",
                    "Description",
                    "Paid For",
                    "Date",
                  ]}
                  tableBody={state.newTableRow?.lead_payments}
                  searchField="Date"
                  deleteFromTable={deleteFromTable}
                  editTableRow={editTableRow}
                  noActions=""
                  isEditable={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeadDetails;
