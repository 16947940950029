import React, { useState, useEffect, useContext } from 'react'
import * as $ from 'jquery'
import axios from 'axios'
import SelectBox from '../../components/SelectBox'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import { changeHandler as oldChangeHandler } from '../../Helpers/ChangeHandler'
import { deleteFromTable, editTableRow } from '../../Helpers/TableHelpers'
import Dialog from '../../components/Dialog'
import { getNormalDate, getInputDate, getFormattedDate } from '../../Helpers/DateHelpers'
import { ATLAS_URI, paymentTypeList } from '../../Constants'
import stateContext from '../../context/StateContext'

function AgreementDetails() {

    const contextState = useContext(stateContext)
    const { redirectFromEditDetails, resetEditID } = contextState
    const { configToken, sourcesList, propertyTypeList, EditDetailsData } = contextState.state

    const [state, setState] = useState({
        resetNewRow: {
            Name: "",
            ClientContact: "",
            ClientEmail: "",
            ClientLocation: "",
            PropertyType: "",
            ReqProperty: "",
            PropertyMinArea: "",
            PropertyMaxArea: "",
            ClientMinBudget: "",
            ClientMaxBudget: "",
            RequiredLoan: "",
            Source: "",
            LeadStatus: "",
            NextFollowupDate: "",
            RemarksList: [],
            PaymentList: [],
            FromDate: "",
            ToDate: ""
        },
        newTableRow: {
            Name: "",
            ClientContact: "",
            ClientEmail: "",
            ClientLocation: "",
            PropertyType: "",
            ReqProperty: "",
            PropertyMinArea: "",
            PropertyMaxArea: "",
            ClientMinBudget: "",
            ClientMaxBudget: "",
            RequiredLoan: "",
            Project: "Select",
            Property: "Select",
            Source: "",
            LeadStatus: "",
            NextFollowupDate: "",
            RemarksList: [],
            PaymentList: [],
            FromDate: "",
            ToDate: ""
        },
        tableBodyList: [

        ],
        dialogInfo: {
            isOpened: false,
            text: ""
        },
        editingActivated: false,
        editingID: "",
        projectsList: [],
        propertiesList: [],
        agreementTypeList: [],

    })

    function changeHandler(e) {
        oldChangeHandler(e, state, setState);
    }


    const mounted = React.useRef(true);
    useEffect(() => () => { mounted.current = false; }, []);
    useEffect(() => {

        let sourcesData = sourcesList;
        let propertyTypesData = propertyTypeList;
       

        //Populate all Fields

        if (resetEditID("/Agreement/agreementDetails")) {
            EditDetailsData = {}
            window.location.replace("/Agreement/viewAgreementHistory")

        } else {
           

            if (typeof EditDetailsData.id !== 'undefined') {
                axios.get(`${ATLAS_URI}/getAgreementByID/${EditDetailsData.id}`, configToken)
                    .then(response => {
                        const agreementData = response.data;
                        if (typeof agreementData !== 'undefined') {
                            if (typeof agreementData.ToDate !== 'undefined' && agreementData.ToDate !== '') {
                                agreementData.ToDate = getInputDate(agreementData.ToDate);
                            }
                            if (typeof agreementData.FromDate !== 'undefined' && agreementData.FromDate !== '') {
                                agreementData.FromDate = getInputDate(agreementData.FromDate);
                            }
                            mounted.current && setState(prevState => ({
                                ...prevState, newTableRow: agreementData
                            }))
                        }
                    }).catch(err => console.log(err))
            }
        }

        axios.all(
            [
                axios.get(`${ATLAS_URI}/getProjects/`, configToken),
                axios.get(`${ATLAS_URI}/getProperties/`, configToken),
                axios.get(`${ATLAS_URI}/getAgreementTypes/`, configToken)

            ]
        )
            .then(axios.spread((projects, properties, agreementTypes) => {
                setState(prevState => ({
                    ...prevState,
                    agreementTypeList: agreementTypes.data,
                    projectsList: projects.data,
                    propertiesList: properties.data

                }));

            }))
            .catch(error => console.log(error));

    }, []);



    function validateThenUpdateAgreement(e) {

        if (state.newTableRow.Project === "Select") {
            e.preventDefault();
            const newDialogInfo = { isOpened: true, text: "Kindly Select a Project", iconClass: "fas fa-exclamation-triangle iconRed" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
        } else if (state.newTableRow.Property === "Select") {
            e.preventDefault();
            const newDialogInfo = { isOpened: true, text: "Kindly Select a Property", iconClass: "fas fa-exclamation-triangle iconRed" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
        }
        else {
            updateAgreement(e);
        }
    }

    function updateAgreement(e) {
        e.preventDefault();

        let AgreementData = state.newTableRow
        let ToDate = state.newTableRow.ToDate;
        let FromDate = state.newTableRow.FromDate;
        if (typeof ToDate !== 'undefined' && ToDate !== '') {
            AgreementData.ToDate = getNormalDate(ToDate);
        }
        if (typeof FromDate !== 'undefined' && FromDate !== '') {
            AgreementData.FromDate = getNormalDate(FromDate);
        }

        axios.post(`${ATLAS_URI}/updateAgreement/` + EditDetailsData.id, AgreementData, configToken)
            .then(() => {
                redirectFromEditDetails(EditDetailsData.redirectFrom)
            })
            .catch(err => alert(err))

    }


    function insertIntoRemarksList(e) {

        e.preventDefault();
      
        const tempRemarks = $("#newRemarks").val();
        let newRemarksList = [...state.newTableRow.Remarks];
        const addRemarks = { Remarks: tempRemarks, Date: getFormattedDate(), AgreementId: state.newTableRow.id }

        axios.post(`${ATLAS_URI}/addAgreementRemarks/`, addRemarks, configToken)
            .then(res => {
                if (res.status === 200) {
                    addRemarks.id = res.data.id
                    newRemarksList.push(addRemarks);
                    setState(prevState => ({
                        ...prevState,
                        newTableRow: {
                            ...prevState.newTableRow,
                            Remarks: newRemarksList
                        }
                    }))
                    $("#newRemarks").val("");

                }
            })
            .catch(err => alert(err))

    }
    function insertIntoPaymentList(e) {
        e.preventDefault();
        const tempPayment = $("#newPayment").val();
        const tempPaymentType = $("#newPropertyType").val();

        let newPaymentList = [...state.newTableRow.Payments];

        const addedPayment = { PaymentType: tempPaymentType, Payment: tempPayment, Date: getFormattedDate(), AgreementId: state.newTableRow.id }


        axios.post(`${ATLAS_URI}/addAgreementPayment/`, addedPayment, configToken)
            .then(res => {
                if (res.status === 200) {
                    addedPayment.id = res.data.id
                    newPaymentList.push(addedPayment);
                    setState(prevState => ({
                        ...prevState,
                        newTableRow: {
                            ...prevState.newTableRow,
                            Payments: newPaymentList
                        }
                    }))

                }
            })
            .catch(err => alert(err))



    }


    return (
        <section className="content">
            <div className="row">
                <Dialog
                    onClose={(e) => setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "" } }))}
                    dialogInfo={state.dialogInfo}
                />
                <div className="col-md-4">

                    <div className="box box-primary">

                        <BoxHeader title="Update Agreement Detail" />

                        <form onSubmit={validateThenUpdateAgreement}>
                            <div className="box-body">

                                <div className="form-group">
                                    <label>Agreement Name</label><small className="req"> *</small>
                                    <input name="Name" type="text" required className="form-control" autoComplete="off" value={state.newTableRow.Name} onChange={changeHandler} />
                                </div>


                                <div className="form-group">
                                    <label>Agreement Type</label> <small className="req"> *</small>
                                    <select required className="form-control" name="Type" value={state.newTableRow.Type} onChange={changeHandler} >
                                        <option value="">Select</option>
                                        {typeof state.agreementTypeList !== 'undefined' && state.agreementTypeList.map(classData => <option key={classData.AgreementType} value={classData.id}>{classData.AgreementType}</option>)}
                                    </select>
                                </div>


                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Buyer Name</label>
                                            <input type="text" className="form-control" name="BuyerName" value={state.newTableRow.BuyerName} readOnly="readOnly" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Buyer Phone</label>
                                            <input type="text" className="form-control" readOnly="readOnly" value={state.newTableRow.BuyerPhone} />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Seller Name</label>
                                            <input type="text" className="form-control" readOnly="readOnly" value={state.newTableRow.SellerName} />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Seller Phone</label>
                                            <input type="text" className="form-control" readOnly="readOnly" value={state.newTableRow.SellerPhone} />
                                        </div>
                                    </div>

                                </div>
                          
                                <SelectBox
                                    label="Project"
                                    name="Project"
                                    options={state.projectsList}
                                    attributeShown="Name"
                                    changeHandler={changeHandler}
                                    value={state.newTableRow.Project}
                                    resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, Project: "Select" } }))}
                                />

                                <SelectBox
                                    label="Properties"
                                    name="Properties"
                                    options={state.propertiesList.filter(property => property.Project === state.newTableRow.Project)}
                                    attributeShown="PropertyTitle"
                                    changeHandler={changeHandler}
                                    value={state.newTableRow.Property}
                                    resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, Property: "Select" } }))}
                                />

                                <div className="form-group">
                                    <label>Total Cost</label> <small className="req"> *</small>
                                    <input name="TotalCost" required type="number" className="form-control" value={state.newTableRow.TotalCost} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <label>Govt ID</label> <small className="req"> *</small>
                                    <input name="GovtID" required type="text" className="form-control" value={state.newTableRow.GovtID} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <label>Address Proof</label> <small className="req"> *</small>
                                    <input name="AddressProof" required type="text" className="form-control" value={state.newTableRow.AddressProof} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input name="FromDate" type="date" className="form-control" value={state.newTableRow.FromDate} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input name="ToDate" type="date" className="form-control" value={state.newTableRow.ToDate} onChange={changeHandler} />
                                </div>


                            </div>

                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right">Update</button>
                            </div>

                        </form>
                    </div>

                </div>

                <div className="col-md-8">

                    {/* Payment Section */}

                    <div className="box box-primary">
                        <BoxHeader title="Add Payment" />

                        <div className="box-body">

                            <form onSubmit={insertIntoPaymentList} >


                                <div className="form-group">
                                    <label>Payment Type</label> <small className="req"> *</small>
                                    <select id="newPropertyType" required className="form-control">
                                        <option value="">Select</option>
                                        {typeof paymentTypeList !== 'undefined' && paymentTypeList.map(classData => <option key={classData} value={classData}>{classData}</option>)}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Payment</label><small className="req"> *</small>
                                    <input id='newPayment' type="number" min={0} required className="form-control" autoComplete="off" />
                                </div>

                                <div className="box-footer">
                                    <button type="submit" className="btn btn-info pull-right">Add</button>
                                </div>
                            </form>
                        </div>

                    </div>

                    <div className="box box-primary">
                        <BoxHeader title="Recent Payment" />

                        <div className="box-body">
                            {typeof state.newTableRow.Payments !== 'undefined' &&
                                <DataTable
                                    tableHeader={["id", "PaymentType", "Payment", "Date"]}
                                    tableBody={state.newTableRow.Payments}
                                    searchField="Date"
                                    deleteFromTable={deleteFromTable}
                                    editTableRow={editTableRow}
                                    noActions=""
                                />
                            }
                        </div>

                    </div>

                    {/* Remarks Section */}
                    <div className="box box-primary">
                        <BoxHeader title="Add Remarks" />

                        <div className="box-body">

                            <form onSubmit={insertIntoRemarksList} >
                                <div className="form-group">
                                    <textarea name="Remarks" id='newRemarks' className="form-control" rows="4"></textarea>
                                </div>

                                <div className="box-footer">
                                    <button type="submit" className="btn btn-info pull-right">Add</button>
                                </div>
                            </form>
                        </div>

                    </div>

                    <div className="box box-primary">
                        <BoxHeader title="Recent Remarks" />

                        <div className="box-body">
                            {typeof state.newTableRow.Remarks !== 'undefined' &&
                                <DataTable
                                    tableHeader={["id", "Remarks", "Date"]}
                                    tableBody={state.newTableRow.Remarks}
                                    searchField="Date"
                                    deleteFromTable={deleteFromTable}
                                    editTableRow={editTableRow}
                                    noActions=""
                                />
                            }
                        </div>

                    </div>

                </div>

            </div>

        </section>
    )


}

export default AgreementDetails