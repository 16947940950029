import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card,
  Button,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Download from "../../../assets/Download.png";
import Delete from "../../../assets/Delete.png";
import View from "../../../assets/View.png";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import HandFReportViewer from "../HandFReportViewer";
import { TableHeader } from "semantic-ui-react";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
  },
});

const HFReportList = () => {
  const classes = useStyles();
  let params = useParams();
  let history = useHistory();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  let [isShow, setIsShow] = useState(false);
  let [disableReport, setDisableReport] = useState(false);
  let [reportList, setReportList] = useState([]);
  let [reportId, setReportId] = useState("");

  const [recommend, setRecommend] = useState(null);

  const openPopup = () => {
    setOpen(true);
  };
  const closePopup = () => {
    setOpen(false);
  };

  const handleClick = (event, report) => {
    setAnchorEl(event.currentTarget);
    setReportId(report.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ViewReport = () => {
    setAnchorEl(null);
    setIsShow(true);
  };

  const getReportList = () => {
    axiosFetch({
      url: `/getHFReportListByLeadId/${params.id}`,
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        setReportList(res.data);
      } else if (res.status === 500) {
        toast.error("Internal Server Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (res.status === 401) {
        history.push("/");
      }
    });
  };
  const generateReport = () => {
    setAnchorEl(null);
    axiosFetch({
      url: `/generateHFReport/${params.id}`,
      method: "post",
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Report Generated Successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          getReportList();
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const downloadReport = () => {
    setAnchorEl(null);
    axiosFetch({
      url: `/getHFreportpdfByReportId/${reportId}`,
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        window.open(res.data);
      } else if (res.status === 400) {
        toast.error(res.data.message, { position: toast.POSITION.TOP_CENTER });
      } else if (res.status === 401) {
        history.push("/");
      }
    });
  };

  const deleteReport = () => {
    setAnchorEl(null);
    axiosFetch({
      url: `/deleteHFReport/${params.id}`,
      method: "delete",
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Report deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          getReportList();
          setIsShow(false);
        } else if (res.status === 400) {
          toast.error("H & F report is not uploaded", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getReportList();
  }, []);

  useEffect(() => {
    const currentLead = JSON.parse(localStorage.getItem("leadCurrentRow"));
    setRecommend(currentLead);
  }, []);

  const table = {
    border: "2px solid #D0D0D0",
    color: "#6B6B6B",
  };

  const tableHeader = {
    border: "2px solid #D0D0D0",
    fontWeight: "600",
  };

  return (
    <div>
      <Card sx={{ width: "1500px !important" }}>
        <div style={{ margin: "22px" }}>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <Typography
              sx={{
                color: "#2D2D2D",
                fontFamily: "Poppins",
                fontWeight: "600",
                Size: "18px",
                width: "200px",
              }}
            >
              H & F Report
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "white",
                // width: "500px",
                backgroundColor: "black",
                textTransform: "capitalize",

                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
              onClick={generateReport}
            >
              Generate Report
            </Button>
          </div>
          <br />

          <TableContainer>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell sx={tableHeader}>Program Recommendation</TableCell>
                  <TableCell sx={tableHeader}> Description </TableCell>
                  <TableCell sx={tableHeader}> Duratoin </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell sx={table}>
                    {recommend?.recommended_program_name
                      ? recommend?.recommended_program_name
                      : "-"}
                  </TableCell>
                  <TableCell sx={table}>
                    {recommend?.recommended_program_description
                      ? recommend?.recommended_program_description
                      : "-"}
                  </TableCell>
                  <TableCell sx={table}>
                    {recommend?.recommended_program_duration
                      ? recommend?.recommended_program_duration
                      : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
                <TableRow>
                  <TableCell align="left">
                    <b>S.No</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Date</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Report Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Generated by</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportList.length !== 0 ? (
                  reportList.map((report, index) => (
                    <TableRow sx={{ color: "#6B6B6B" }} key={report.id}>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {report.createdAt.split("T")[0]}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {report.originalFileName !== null
                          ? report.originalFileName
                          : "-"}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: "#6B6B6B" }}
                      >
                        {report?.vmax_user !== null
                          ? report?.vmax_user?.Username
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          aria-label="action-button"
                          onClick={(e) => handleClick(e, report)}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ color: "red" }}
                    >
                      No Results Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={ViewReport}
              sx={{ gap: "6px" }}
              disabled={disableReport}
            >
              <img
                src={View}
                style={{
                  width: "20.12px",
                  height: "20.12px",
                  top: "1.88px",
                  left: "2px",
                  border: "2px",
                }}
              />
              View &nbsp;&nbsp;
            </MenuItem>
            <hr />
            <MenuItem
              onClick={downloadReport}
              sx={{ gap: "6px" }}
              disabled={disableReport}
            >
              <img
                src={Download}
                style={{
                  width: "20.12px",
                  height: "20.12px",
                  top: "1.88px",
                  left: "2px",
                  border: "2px",
                }}
              />
              Download &nbsp;&nbsp;
            </MenuItem>
            <hr />
            <MenuItem
              onClick={deleteReport}
              sx={{ gap: "6px" }}
              disabled={disableReport}
            >
              <img
                src={Delete}
                style={{
                  width: "20.12px",
                  height: "20.12px",
                  top: "1.88px",
                  left: "2px",
                  border: "2px",
                }}
              />
              Delete
            </MenuItem>
          </Menu>
        </div>
        {isShow && <HandFReportViewer id={reportId} />}
      </Card>
    </div>
  );
};

export default HFReportList;
