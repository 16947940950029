import {
  DialogContent,
  DialogContentText,
  Stack,
  DialogTitle,
  Dialog,
  Container,
  Button,
  DialogActions,
  FormHelperText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { TextareaAutosize } from "@mui/base";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
const AddNotes = (props) => {
  const theme = useTheme();
  let history = useHistory();
  let params = useParams();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);

  let [notes, setNotes] = useState("");
  let [isErrNotes, setIsErrNotes] = useState(false);

  const handleClose = () => {
    setOpen(false);

    if (props.close !== undefined) {
      props.close();
    }
  };

  const handleChangeNotes = (e) => {
    setNotes(e.target.value);
    props.handleChangeNote(e.target.value);
    if (e.target.value === "") {
      setIsErrNotes(true);
      return false;
    } else {
      setIsErrNotes(false);
    }
  };

  const id = JSON.parse(window.localStorage.getItem("operator")).id;

  const editNote = () => {
    if (props.note !== null) {
      axiosFetch({
        url: `/updateById/${props?.note?.id}`,
        method: "put",
        requestConfig: {
          id: props?.note?.id,
          note: notes,
        },
      }).then((res) => {
        if (res.status === 200) {
          toast.success("Note Updated!", {
            position: toast.POSITION.TOP_CENTER,
          });
          handleClose();
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };

  const saveNotes = () => {
    let requestObj;

    if (notes === "") {
      setIsErrNotes(true);
    }
    if (notes !== "") {
      requestObj = {
        note: notes !== null ? notes : undefined,
        created_by: id,
      };

      axiosFetch({
        url: `/post/${params.id}?leadId=${params.id}`,
        method: "post",
        requestConfig: requestObj,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Notes added Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            handleClose();
            props.getNotesList();
          } else if (res.status === 400) {
            toast.error("Bad Request", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 401) {
            history.push("/");
          } else if (res.status === 500) {
            toast.error("Internal Server Error", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (props?.note !== undefined) {
      setNotes(props.note?.note);
    }
  }, []);
  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      sx={{ width: "100% !important", height: "100% !important", gap: "8px" }}
    >
      <span
        style={{ marginTop: "10px", marginLeft: "83%", color: "#757575" }}
        onClick={handleClose}
      >
        <CloseIcon />
      </span>

      <DialogTitle sx={{ fontWeight: "600", size: "18px", lineHeight: "27px" }}>
        {props.popup_name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Enter Here"
            value={notes}
            onChange={handleChangeNotes}
          />
          <FormHelperText sx={{ color: "red" }}>
            {isErrNotes ? "Please enter something" : ""}
          </FormHelperText>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Container>
          <Stack direction="row" spacing={5}>
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                color: "black",
                borderColor: "black",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                backgroundColor: "black",
                color: "white",
              }}
              onClick={
                props.popup_name.toLowerCase() === "add note"
                  ? saveNotes
                  : editNote
              }
            >
              {props.popup_name.toLowerCase() === "add note"
                ? "Save"
                : "Update"}
            </Button>
          </Stack>
        </Container>
      </DialogActions>
    </Dialog>
  );
};
export default AddNotes;
