import React, { useState, useEffect, useContext } from 'react'
import { returnTime, getNormalDate } from '../../Helpers/DateHelpers'
import { deleteFromTable, insertIntoTable, editTableRow, openDialog } from '../../Helpers/TableHelpers'
import axios from 'axios'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import SelectBox from '../../components/SelectBox'
import * as $ from 'jquery'
import { changeHandler as oldChangeHandler } from '../../Helpers/ChangeHandler'
import stateContext from '../../context/StateContext'
import { ADMIN, ATLAS_URI } from '../../Constants'

function UserActivity() {

    const contextState = useContext(stateContext)
    const { configToken, operator } = contextState.state

    const [list, setLists] = useState({
        userList: [],
        roleList: [],
    })
    const [state, setState] = useState({
        resetNewRow: {
            id: "",
            Activity: "",
            Description: "",
            Date: "",
            User: operator.id,
            SelectUser: "Select",
            SelectRole: "Select"
        },
        newTableRow: {
            id: "",
            Activity: "",
            Description: "",
            Date: "",
            User: operator.id,
            SelectUser: "Select",
            SelectRole: "Select"
        },
        tableBodyList: [

        ],
        userRole: "",
        Username: "",
        editingActivated: false,
        editingID: "",
        allActivities: [],
        APIs: {
            AddData: "addUserActivity",
            UpdateData: "updateUserActivity",
            DeleteData: "deleteUserActivity"
        },
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        }
    })

    function changeHandler(e) {
        oldChangeHandler(e, state, setState);
    }


    useEffect(() => {

        //Get User Activities
        axios.get(`${ATLAS_URI}/getUserActivitiesByUser/${operator.id}`, configToken)
            .then(response => {
                const responseData = response.data;
                if (typeof responseData !== 'undefined') {
                    if (operator.Role === ADMIN) {
                        //Get All Activities
                        axios.get(`${ATLAS_URI}/getUserActivities`, configToken)
                            .then(response2 => {
                                const responseData2 = response2.data;
                                if (typeof responseData2 !== 'undefined') {
                                    setState(prevState => ({
                                        ...prevState,
                                        userRole: operator.Role,
                                        allActivities: responseData2,
                                        newTableRow: state.resetNewRow,
                                        tableBodyList: responseData,
                                    }))

                                }
                            }).catch(err => console.log(err))
                    } else {
                        setState(prevState => ({
                            ...prevState,
                            newTableRow: state.resetNewRow,
                            tableBodyList: responseData,
                            userRole: operator.Role
                        }))
                    }

                }

            }).catch(err => console.log(err))

        axios.get(`${ATLAS_URI}/getRoles/`, configToken)
            .then(res4 => {
                let rolesData = res4.data;
                if (typeof rolesData !== 'undefined') {
                    //Get All Users
                    axios.get(`${ATLAS_URI}/getUsers/`, configToken)
                        .then(res3 => {
                            let userData = res3.data;

                            setLists(prevState => ({
                                ...prevState,
                                userList: userData,
                                roleList: rolesData
                            }))
                        }

                        ).catch(err => console.log(err))
                }

            }).catch(err => console.log(err))

    }, []);


    function getRolesUser(role, user) {
        let x = list.userList.filter(users => (users.Role.toString() === role && users.Username === user))
        return (typeof x !== "undefined" && x.length !== 0);
    }

    function downloadReport(e) {

        e.preventDefault();

        let user = ""
        let role = ""
        let enterRole = ""
        let searchList = []

        if (state.userRole === ADMIN) {

            if (state.newTableRow.SelectUser !== "Select") { user = list.userList.filter(u => u.id.toString() === state.newTableRow.SelectUser.toString())[0].Username }
            if (state.newTableRow.SelectRole !== "Select") { role = state.newTableRow.SelectRole }
            if (state.newTableRow.SelectRole !== "Select") { enterRole = list.roleList.filter(u => u.id.toString() === state.newTableRow.SelectRole.toString())[0].Role }

            searchList = state.allActivities

        } else {
            role = (list.roleList.filter(u => u.Role === state.userRole)[0].id)
            enterRole = state.userRole
            user = operator.id
            searchList = state.tableBodyList
        }

        var temp = $("#searchEnquiryFromDate").val();
        const enquiryFromDate = returnTime(getNormalDate(temp))
        var temp2 = $("#searchEnquiryToDate").val();
        const enquiryToDate = returnTime(getNormalDate(temp2))


        let newList = [];
        newList.push({ Date: enterRole + " Username", Activity: user })
        newList.push({ Date: "Activity List ", Description: "From " + getNormalDate(temp) + " To " + getNormalDate(temp2) })
        newList.push({})
        newList.push({})
        newList.push({ Date: "Date:", Activity: "Activity", Description: "Description", User: "User" })

        let downloadList = newList.concat(
            searchList.filter(data =>
                (!isNaN(enquiryFromDate) ? returnTime(data.updatedAt) >= enquiryFromDate : true) &&
                (!isNaN(enquiryToDate) ? returnTime(data.updatedAt) <= enquiryToDate : true) &&
                (user !== "" ? data.User === user : true) &&
                (role !== "" ? getRolesUser(role, data.User) : true)
            ).map(x => { x.Date = x.updatedAt; return x })
        )
        axios.post(`${ATLAS_URI}/downloadActivities/`, downloadList)
            .then(res3 => {
                if (res3.status === 200) {

                    if (res3.data !== null) {
                        const downloadWin = window.open(`${ATLAS_URI}/excel/${res3.data}`);
                        if (downloadWin) downloadWin.opener = null;

                    }
                }

            })
            .catch(err => alert(err))
    }


    return (
        <section className="content">
            <div className="row">
                {typeof state.dialogInfo !== 'undefined' &&
                    <Dialog
                        onFalse={(e) => setState(...state, { dialogInfo: { isOpened: false, text: "" } })}
                        onTrue={(e) => deleteFromTable(contextState, state, setState)}
                        dialogInfo={state.dialogInfo}
                    />}
                <div className="col-md-5">

                    <div className="box box-primary">

                        <BoxHeader title={`${state.editingActivated ? "Edit" : "Add"} Activity`} />

                        <form onSubmit={(e) => insertIntoTable(e, contextState, state, setState)} autoComplete='off'>

                            <div className="box-body bozero">

                                <input type="hidden" name="ci_csrf_token" value="" />

                                <div className="form-group">
                                    <label >Activity </label><small className="req"> *</small>
                                    <input name="Activity" value={state.newTableRow.Activity} onChange={changeHandler} required type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Description</label><small className="req"> *</small>
                                    <textarea value={state.newTableRow.Description} required className='form-control' rows='5' name='Description' onChange={changeHandler} ></textarea>
                                </div>
                            </div>
                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right ">Save</button>
                            </div>
                        </form>
                    </div>

                    <div className="box box-primary">

                        <BoxHeader title={`Export Activities`} />

                        <form onSubmit={downloadReport} autoComplete='off'>

                            <div className="box-body bozero row">

                                <input type="hidden" name="ci_csrf_token" value="" />

                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label>Enquiry From Date</label>
                                        <input type="date" id="searchEnquiryFromDate" className="form-control" />
                                    </div>
                                    <span className="text-danger"></span>

                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label>Enquiry To Date</label>
                                        <input type="date" id="searchEnquiryToDate" className="form-control" />
                                    </div>
                                    <span className="text-danger"></span>

                                </div>
                                {state.userRole === ADMIN && <div className='row'>
                                    <div className="col-sm-6 col-md-6">
                                        <SelectBox
                                            label="Role"
                                            name="SelectRole"
                                            options={list.roleList}
                                            attributeShown="Role"
                                            changeHandler={changeHandler}
                                            value={state.newTableRow.SelectRole}
                                            resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, SelectRole: "Select" } }))}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <SelectBox
                                            label="User"
                                            name="SelectUser"
                                            options={list.userList.filter(users => users.Role === state.newTableRow.SelectRole)}
                                            attributeShown="Name"
                                            changeHandler={changeHandler}
                                            value={state.newTableRow.SelectUser}
                                            resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, SelectUser: "Select" } }))}
                                        />

                                    </div>
                                </div>}

                            </div>
                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right ">Download</button>
                            </div>
                        </form>
                    </div>



                </div>

                <div className="col-md-7">
                    <div className="box box-primary">

                        <BoxHeader title="List of Added Activities" />

                        <div className="box-body">

                            <DataTable
                                tableHeader={["id", "Activity", "Description", "updatedAt"]}
                                searchField="Activity"
                                tableBody={state.tableBodyList}
                                deleteFromTable={(e) => openDialog(e, state, setState)}
                                editTableRow={(e) => editTableRow(e, state, setState)}
                            />

                        </div>
                    </div>
                </div>

            </div>
        </section>
    )


}

export default UserActivity