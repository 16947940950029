import { useEffect, useRef, useState } from "react";

import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ImageIcon from "@mui/icons-material/Image";
import LoopIcon from "@mui/icons-material/Loop";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Button from "../../../components/CustomButton/Button";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import dayjs from "dayjs";

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

let days = [
  { id: 0, name: "sunday" },
  { id: 1, name: "monday" },
  { id: 2, name: "tuesday" },
  { id: 3, name: "wednesday" },
  { id: 4, name: "thursday" },
  { id: 5, name: "friday" },
  { id: 6, name: "saturday" },
];

const zoomList = [
  { host: "primary", name: "Primary" },
  { host: "host_1", name: "Host 01" },
  { host: "host_2", name: "Host 02" },
  { host: "host_4", name: "Host 04" },
  { host: "host_5", name: "Host 05" },
];

const requiredProperties = [
  "program_id",
  "session_image",
  "thumbnail",
  "format_id",
  "level_id",
  "session_name",
  "host",
  "start_time",
  "recurrence_ends_at",
  "timezone",
  "duration",
  "duration_unit",
  "coach_name",
  "coach_details",
];

const dateFormat = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

const timeFormat = (value) => {
  const date = new Date(value);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

const initialState = {
  recording: true,
  visibility: true,
  host: "",
  program_id: "",
  session_name: "",
  session_image: "",
  session_details: "",
  format_id: "",
  level_id: "",
  start_date: null,
  start_time: null,
  timezone: "",
  duration: "",
  duration_unit: "",
  coach_name: "",
  moderator_name: "",
  interval_value: 1,
  recurrence_ends_at: null,
  repeat_by: "",
  repeat_on_weekdays: [],
  coach_details: "",
  equipments_required: "",
  benefits: "",
  routine: "",
  who_should_attend: "",
  who_should_avoid: "",
  sessionType: "single",
};

const intialConditionalState = {
  editRowFlag: false,
  selectedSessionImage: "",
  selectedThumbnailImage: "",
  isToday: false,
  isDisable: false,
  liveProgramList: [],
  formatList: [],
  levelList: [],
  allTimeZone: [],
  coachList: [],
};

const CreateSession = ({ closeModel, onSession, disableBtn }) => {
  const location = useLocation();
  const history = useHistory();
  const view = location?.state?.state.view;
  const selectedRow = location?.state?.state.selectedRow;
  const fileInputRef = useRef(null);
  const [state, setState] = useState(initialState);
  const [conditionalState, setConditionalState] = useState(
    intialConditionalState
  );

  // handle all input values
  const handleState = (value, name) => {
    let updateState = { ...state, [name]: value };

    if (name === "program_id") {
      const selectedName = conditionalState.liveProgramList.filter(
        (live) => live.id === value
      )[0];
    
      updateState = { ...updateState, session_name: selectedName.name,benefits:selectedName.benefits,
        who_should_attend:selectedName.who_should_attend,who_should_avoid:selectedName.who_should_avoid,
        equipments_required:selectedName.equipments_required,routine:selectedName.routine,session_image:selectedName.
        s3KeyName,thumbnail:selectedName.thumbnail_image_s3KeyName

      };

      handleFormat(value);
    }

    if (name === "coach_name") {
      const arr = conditionalState.coachList.filter(
        (item) => item.id === value
      )[0];
      updateState.coach_details = arr?.about;
    }

    setState(updateState);
  };

  // handle image values

  const handleFileChange = (event, label) => {
    const file = event.target.files[0];

    if (file && file.size < 2097152) {
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          if (label === "thumbnail") {
            setState((pre) => {
              return { ...pre, thumbnail: file };
            });
            setConditionalState((prevState) => ({
              ...prevState,
              selectedThumbnailImage: reader.result , 
                        }));
          }
          if (label === "session_image") {
            setState((pre) => {
              return { ...pre, session_image: file };
            });
            setConditionalState((prevState) => ({
              ...prevState,
              selectedSessionImage: reader.result,
            }));
          }
        };

        reader.readAsDataURL(file);
      }
    } else {
      fileInputRef.current.value = "";
      toast.error("Please select an image less than 2MB", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // handle day click

  const handleDayClick = (value, index) => {
    const isActive = state.repeat_on_weekdays.includes(value);
    if (isActive) {
      setState((pre) => {
        return {
          ...pre,
          repeat_on_weekdays: state.repeat_on_weekdays.filter(
            (day) => day !== value
          ),
        };
      });
    } else {
      setState((pre) => {
        return {
          ...pre,
          repeat_on_weekdays: [...state.repeat_on_weekdays, value],
        };
      });
    }
  };

  const handleFormat = async (id) => {
    const response = await axiosFetch({
      url: `/gettypebyProgramId/${id}`,
      method: "get",
    });

    const format = response.data.body;
 
    if (format && format.type_id) {
      setState((pre) => {
        return { ...pre, format_id: format.type_id };
      });
    }

    if (format.image_url && format.thumbnail_image_url) {
      setConditionalState((prevState) => ({
        ...prevState,
        selectedThumbnailImage: format.thumbnail_image_url ,  
        selectedSessionImage:format.image_url
      }));
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const coachObject = conditionalState.coachList.filter(
      (item) => item.id === state.coach_name
    )[0];   
    let url;
    let reqObject = {
      ...state,
      coach_name: coachObject?.firstName,
      trainer_id: +coachObject?.id,
      is_recurring: state.sessionType === "single" ? false : true,
      start_date: dateFormat(state.start_date),
      start_time: timeFormat(state.start_time),
      recurrence_ends_at: dateFormat(state.recurrence_ends_at),
    };

    if (state.sessionType === "single") {
      delete reqObject.interval_value;
      delete reqObject.recurrence_ends_at;
      delete reqObject.weekday;
      delete reqObject.repeat_by;
    }

    if (selectedRow) {
      let id = selectedRow?.meeting?.id;
      if (selectedRow?.updated_session_details) {
        id = selectedRow?.updated_session_details?.id;
      }

      url = `/updateProgramSession/${id}`;
    } else {
      url = "/createProgramSession";
    }

    const formData = new FormData();

    for (const key in reqObject) {
      if (reqObject.repeat_on_weekdays && key === "repeat_on_weekdays") {
        reqObject[key].forEach((day, index) => {
          formData.append(`repeat_on_weekdays[${index}]`, day);
        });
      } else {
        formData.append(key, reqObject[key]);
      }
    }

    for (const key of requiredProperties) {      
      if (reqObject[key] === "") {
        toast.error(`One of the field is empty. Please fill it.`, {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }

    setConditionalState((pre) => {
      return { ...pre, isDisable: true };
    });

    const request = await axiosFetch({
      url: url,
      method: "post",
      requestConfig: formData,
    });

    if (request.status === 200) {
      toast.success(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });

      setState(initialState);

      setTimeout(() => {
        history.push("/studio");
      }, 1000);
    } else {
      toast.error(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setConditionalState((pre) => {
        return { ...pre, isDisable: false };
      });
    }
  };

  // All get  List items

  const getAllSelectBox = async () => {
    try {
      const allTimeData = await axiosFetch({
        url: "/getTimeZones",
        method: "get",
      });

      const formatAndLevel = await axiosFetch({
        url: "/admin-programSessionConstants",
        method: "get",
      });

      const coachName = await axiosFetch({
        url: "/admin-programSessionCoaches",
        method: "get",
      });

      const liveProgram = await axiosFetch({
        url: "/getLiveCoachingPrograms",
        method: "get",
      });

      return {
        allTimeZone: allTimeData.data,
        formatList: formatAndLevel.data.body.VMAX_WORKOUT_FORMAT,
        levelList: formatAndLevel.data.body.VMAX_WORKOUT_LEVEL,
        coachList: coachName.data.body,
        liveProgramList: liveProgram.data,
      };
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const data = await getAllSelectBox();
        if (isMounted) {
          setConditionalState((prev) => ({
            ...prev,
            ...data,
          }));
        }
      } catch (error) {
        // Handle errors
      }
    };
  
    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (selectedRow) {
      setState(() => {
        const obj = { ...selectedRow };
        delete obj.meeting;
        let updatedState = {
          ...obj,
          ...selectedRow?.meeting,
          coach_name: selectedRow.meeting.trainer_id,
          start_date: dayjs(new Date(selectedRow.meeting.start_date)),
          start_time: dayjs(
            `${selectedRow.meeting.start_date} ${selectedRow.meeting.start_time}`
          ),
          sessionType: !selectedRow.meeting.is_recurring ? "single" : "repeat",         
        };

        if (selectedRow.meeting.recurrence_ends_at) {
          updatedState.recurrence_ends_at = dayjs(
            new Date(selectedRow.meeting.recurrence_ends_at)
          );
        }

        return updatedState;
      });

      setConditionalState((pre) => {
        return {
          ...pre,
          editRowFlag: true,
          selectedSessionImage: selectedRow.meeting.session_image,
          selectedThumbnailImage: selectedRow.meeting.thumbnail,
        };
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    if (state.repeat_by === "week") {
      setState((pre) => {
        return { ...pre, interval_value: 1 };
      });
    }
  }, [state.repeat_by]);

  useEffect(() => {
    if (state.start_date) {
      setConditionalState((pev) => {
        return {
          ...pev,
          isToday: dateFormat(state.start_date) === dateFormat(new Date()),
        };
      });
    }
  }, [state.start_date]);

  return (
    <div className="form-session">
      <Button
        inline
        className="back-session"
        onClick={() => history.push("/studio")}
      >
        <KeyboardBackspaceIcon />
        Back
      </Button>
      <form
        className="live-session-container"
        autoComplete="off"
        onSubmit={submitHandler}
      >
        <div className="session-header">
          <h4>New Session</h4>
        </div>
        <div className="zoom-container">
          <div className="session-subHeader">
            <div>
              <span>Recording</span>
              <Switch
                onChange={(e) => handleState(e.target.checked, "recording")}
                checked={state.recording}
                disabled={view}
              />
            </div>
            <div>
              <span>Visibility </span>
              <Switch
                disabled={view}
                onChange={(e) => handleState(e.target.checked, "visibility")}
                checked={state.visibility}
              />
            </div>
          </div>
          <div className="zoom">
            <div className="require-con host">
              <label>Zoom Name</label>
              <span className="req"> *</span>
            </div>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                onChange={(e) => handleState(e.target.value, "host")}
                labelId="demo-simple-select-label"
                id="demo-select-small"
                value={state.host}
                disabled={conditionalState.editRowFlag}
              >
                {zoomList.map((item) => (
                  <MenuItem key={item.host} value={item.host}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="session-row">
          <div className="session-col">
            <div className="require-con">
              <label>Program</label>
              <span className="req"> *</span>
            </div>

            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                onChange={(e) => handleState(e.target.value, "program_id")}
                labelId="demo-simple-select-label"
                id="demo-select-small"
                value={state.program_id}
                disabled={conditionalState.editRowFlag}
              >
                {conditionalState.liveProgramList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="session-col">
            <div className="require-con">
              <div className="require-con">
                <label>Session Name</label>
                <span className="req"> *</span>
              </div>
            </div>

            <TextField
              id="outlined-size-small"
              fullWidth
              size="small"
              onChange={(e) => handleState(e.target.value, "session_name")}
              value={state.session_name}
              disabled={view}
            />
          </div>
        </div>
        <div className="session-row sub-margin">
          <div className="session-col">
            <div className="choose-img-container">
              <div className="session-row sub-margin">
                <div className="session-col">
                  <div className="choose-img-input">
                    <div className="require-con">
                      <label>Thumbnail Image</label>
                      <span className="req"> *</span>
                    </div>

                    <div>
                      <input
                        type="file"
                        style={{
                          width: "100px",
                          height: "100px",
                          position: "absolute",
                          opacity: 0,
                        }}
                        onChange={(e) => handleFileChange(e, "thumbnail")}
                      />
                    </div>
                    <div>
                      {conditionalState.selectedThumbnailImage ? (
                        <img
                          alt="thumbnail"
                          className="choose-image"
                          src={conditionalState.selectedThumbnailImage}
                        />
                      ) : (
                        <ImageIcon
                          style={{
                            fontSize: "100px",
                            color: "#EBEBEB",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                    {/* <input
                  className="form-control choose-img"
                  type="file"
                  id="formFile"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  disabled={view}
                /> */}
                  </div>
                </div>
                <div className="session-col">
                  <div className="choose-img-input">
                    <div className="require-con">
                      <label>Session Image</label>
                      <span className="req"> *</span>
                    </div>

                    <div>
                      <input
                        type="file"
                        style={{
                          width: "100px",
                          height: "100px",
                          position: "absolute",
                          opacity: 0,
                        }}
                        onChange={(e) => handleFileChange(e, "session_image")}
                      />
                    </div>
                    <div>
                      {conditionalState.selectedSessionImage ? (
                        <img
                          alt="session"
                          className="choose-image"
                          src={conditionalState.selectedSessionImage}
                        />
                      ) : (
                        <ImageIcon
                          style={{
                            fontSize: "100px",
                            color: "#EBEBEB",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="choose-img-icon">
                {conditionalState.selectedImage ? (
                  <img
                    alt="session type"
                    className="choose-image"
                    src={conditionalState.selectedImage}
                  />
                ) : (
                  <ImageIcon
                    style={{
                      fontSize: "100px",
                      marginLeft: "50px",
                      color: "#EBEBEB",
                    }}
                  />
                )}
              </div> */}
            </div>

            {/* <div className="session-col"> */}
            <div className="session-row sub-margin">
              <div className="session-col">
                <div className="require-con">
                  <label>Format</label>
                  <span className="req"> *</span>
                </div>

                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-select-small"
                    onChange={(e) => handleState(e.target.value, "format_id")}
                    value={state.format_id}
                    disabled={true}
                  >
                    {conditionalState.formatList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="session-col">
                <div className="require-con">
                  <label>Level</label>
                  <span className="req"> *</span>
                </div>

                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-select-small"
                    onChange={(e) => handleState(e.target.value, "level_id")}
                    value={state.level_id}
                    disabled={view}
                  >
                    {conditionalState.levelList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            {/* </div> */}
            <div className="session-row">
              <div className="session-col session-mode">
                <Button
                  type={"button"}
                  inline
                  className={`session-btn ${
                    state.sessionType !== "single" ? "repeat" : ""
                  }`}
                  // className={"session-btn"}
                  onClick={() => handleState("single", "sessionType")}
                  disabled={view || disableBtn}
                >
                  <span>
                    <KeyboardBackspaceIcon />
                  </span>
                  <span>Single Session</span>
                </Button>
                <Button
                  type={"button"}
                  disabled={view || disableBtn}
                  inline
                  className={`session-btn ${
                    state.sessionType !== "repeat" ? "repeat" : ""
                  }`}
                  //className={"session-btn repeat"}
                  onClick={() => handleState("repeat", "sessionType")}
                >
                  <span>
                    <LoopIcon />
                  </span>
                  <span>Repeating Session</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="session-col">
            <label>Session Details</label>
            <textarea
              className="form-control choose-img"
              id="floatingTextarea2"
              minLength={5}
              style={{
                height: "300px",
              }}
              onChange={(e) => handleState(e.target.value, "session_details")}
              value={state.session_details}
              disabled={view}
            ></textarea>
          </div>
        </div>
        <div className="session-row format-input sub-margin">
          <div className="session-col custom-col">
            <div className="require-con ">
              <label>Start Date</label>
              <span className="req"> *</span>
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  disablePast
                  onChange={(e) => handleState(e.$d, "start_date")}
                  value={state.start_date}
                  disabled={view}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="session-col custom-col">
            <div className="require-con">
              <label>Start Time</label>
              <span className="req"> *</span>
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  disablePast={conditionalState.isToday}
                  onChange={(e) => handleState(e.$d, "start_time")}
                  value={state.start_time}
                  disabled={view}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="session-col">
            <div className="require-con">
              <label>Time Zone</label>
              <span className="req"> *</span>
            </div>

            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-select-small"
                onChange={(e) => handleState(e.target.value, "timezone")}
                value={state.timezone}
                disabled={view}
              >
                {conditionalState.allTimeZone.map((time, index) => (
                  <MenuItem value={time} key={index}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="session-col">
            <div className="require-con">
              <label>Duration</label>
              <span className="req"> *</span>
            </div>

            <TextField
              id="outlined-size-small"
              fullWidth
              size="small"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              onChange={(e) => handleState(e.target.value, "duration")}
              value={state.duration}
              disabled={view}
            />
          </div>
        </div>
        <div className="session-row format-input sub-margin">
          <div className="session-col">
            <div className="require-con">
              <label>Duration Unit</label>
              <span className="req"> *</span>
            </div>

            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-select-small"
                onChange={(e) => handleState(e.target.value, "duration_unit")}
                value={state.duration_unit}
                disabled={view}
              >
                <MenuItem value={"minute"}>Minutes</MenuItem>
                <MenuItem value={"hour"}>Hours</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="session-col">
            <div className="require-con">
              <label>Coach Name</label>
              <span className="req"> *</span>
            </div>

            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-select-small"
                onChange={(e) => handleState(e.target.value, "coach_name")}
                value={state.coach_name}
                disabled={view}
              >
                {conditionalState.coachList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.firstName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="session-col">
            <label>Moderator Name</label>
            <TextField
              id="outlined-size-small"
              fullWidth
              size="small"
              onChange={(e) => handleState(e.target.value, "moderator_name")}
              value={state.moderator_name}
              disabled={view}
            />
          </div>
          <div className="session-col">
            {state.sessionType === "repeat" && (
              <>
                <label>Every</label>
                <TextField
                  id="outlined-size-small"
                  fullWidth
                  disabled={
                    view ? view : state.repeat_by === "week" ? true : false
                  }
                  size="small"
                  onChange={(e) =>
                    handleState(e.target.value, "interval_value")
                  }
                  type="number"
                  value={state.interval_value}
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                />
              </>
            )}
          </div>
        </div>

        {state.sessionType === "repeat" && (
          <div className="session-row repeat-contianer">
            <div className="session-col custom-col format-input">
              <div className="require-con">
                <label>End Date</label>
                <span className="req"> *</span>
              </div>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    disablePast
                    onChange={(e) => handleState(e.$d, "recurrence_ends_at")}
                    value={state.recurrence_ends_at}
                    disabled={view}
                    minDate={dayjs(state.start_date)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="session-col custom-col">
              <label>Repeat by</label>
              <FormControl sx={{ width: "100%" }} size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-select-small"
                  onChange={(e) => handleState(e.target.value, "repeat_by")}
                  value={state.repeat_by}
                  disabled={view}
                >
                  <MenuItem value={"day"}>Day</MenuItem>
                  <MenuItem value={"week"}>Week</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="session-col session-week-btn">
              {state.repeat_by === "week" &&
                days.map((value, index) => (
                  <button
                    disabled={view}
                    type="button"
                    key={value.id}
                    className={`${
                      state.repeat_on_weekdays.includes(value.name)
                        ? "active-day"
                        : ""
                    }`}
                    onClick={() => handleDayClick(value.name, index)}
                  >
                    {value.name.charAt(0).toUpperCase()}
                  </button>
                ))}
            </div>
            <div className="session-col"></div>
          </div>
        )}

        <div className="session-row">
          <div className="session-col sub-margin">
            <div className="require-con">
              <label>Coach Details</label>
              <span className="req"> *</span>
            </div>

            <textarea
              className="form-control choose-img"
              id="floatingTextarea2"
              minLength={5}
              style={{
                height: "100px",
              }}
              onChange={(e) => handleState(e.target.value, "coach_details")}
              value={state.coach_details}
              disabled={view}
            ></textarea>
          </div>
          <div className="session-col sub-margin">
            <label>Equipment's Required</label>
            <textarea
              className="form-control choose-img"
              id="floatingTextarea2"
              minLength={5}
              style={{
                height: "100px",
              }}
              onChange={(e) =>
                handleState(e.target.value, "equipments_required")
              }
              value={state.equipments_required}
              disabled={view}
            ></textarea>
          </div>
        </div>

        <div className="session-row">
          <div className="session-col sub-margin">
            <label>Benefits</label>
            <textarea
              className="form-control choose-img"
              id="floatingTextarea2"
              minLength={5}
              style={{
                height: "100px",
              }}
              onChange={(e) => handleState(e.target.value, "benefits")}
              value={state.benefits}
              disabled={view}
            ></textarea>
          </div>
          <div className="session-col sub-margin">
            <label>Routine</label>
            <textarea
              className="form-control choose-img"
              id="floatingTextarea2"
              minLength={5}
              style={{
                height: "100px",
              }}
              onChange={(e) => handleState(e.target.value, "routine")}
              value={state.routine}
              disabled={view}
            ></textarea>
          </div>
        </div>

        <div className="session-row">
          <div className="session-col sub-margin">
            <label>Who can attend the session?</label>
            <textarea
              className="form-control choose-img"
              id="floatingTextarea2"
              minLength={5}
              style={{
                height: "100px",
              }}
              onChange={(e) => handleState(e.target.value, "who_should_attend")}
              value={state.who_should_attend}
              disabled={view}
            ></textarea>
          </div>
          <div className="session-col sub-margin">
            <label>Who should avoid?</label>
            <textarea
              className="form-control choose-img"
              id="floatingTextarea2"
              minLength={5}
              style={{
                height: "100px",
              }}
              onChange={(e) => handleState(e.target.value, "who_should_avoid")}
              value={state.who_should_avoid}
              disabled={view}
            ></textarea>
          </div>
        </div>

        <div className="session-btn-container sub-margin">
          {/* <Button
            outline
            type={"button"}
            className={`session-btn-confirm`}
            onClick={closeModel}
          >
            Cancel
          </Button> */}
          <Button
            inline
            disabled={view ? view : conditionalState.isDisable}
            type={"submit"}
            className={"session-btn-confirm"}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateSession;
