import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Login,
  Dashboard,
  AssignedLeadsCalls,
  AssignedLeadsVisits,
  Configurations,
} from "./Pages/Miscellaneous/All";
import TopHeader from "./components/TopHeader";
import Header from "./components/Header";
import SideBar from "./components/Sidebar";
import Report from "./Pages/Reports/Report.jsx";
import { ATLAS_URI } from "./Constants";
import { AddClient, ListClients, ClientDetail } from "./Pages/Clients/Clients";
import { AddAgent, ListAgents } from "./Pages/Agents/Agents";
import { AddProjects, ViewAllProjects } from "./Pages/Projects/Projects";
import { AddProperty, ViewAllProperties } from "./Pages/Properties/Properties";
import { Automation } from "./Pages/Automation/Automation.jsx";
import {
  UpComingFollowUps,
  FreshLeads,
  AllLeads,
  OldPendingFollowUps,
  TodaysFollowUps,
  LeadDetails,
} from "./Pages/Leads/Leads";
import {
  ViewAgreementHistory,
  AddNewAgreement,
  AgreementDetails,
} from "./Pages/Agreement/Agreement";
import {
  AddSource,
  AddPropertyType,
  AddAmenities,
  AddAgreementType,
  AddStatus,
} from "./Pages/Masters/Masters";
import {
  ManageUsers,
  ManageRoles,
  UsersListAndLastLogin,
  UserActivity,
  Notifications,
} from "./Pages/Settings/Settings";
import State from "./context/State";
import LeadView from "./Pages/Leads/LeadView";

import Success from "./components/PaymentStatus/Status.jsx";
import Form from "./components/Entry Form/Form.jsx";

// import NewLeadAndClientTable from "./Pages/NewClientAndLead/Index.jsx";

import { axiosFetch } from "./Utlis/AxiosFetch.jsx";

import NewClientLeadTable from "./Pages/NewClientLeadTable/NewClientLeadTable.jsx";
import Slots from "./Pages/Slots/Slots.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CallHistory from "./Pages/Leads/CallHistory copy";
import Calender from "./Pages/Leads/Calender";
import ClientDietPlans from "./Pages/Clients/ClientDietPlans";
import DietPlanListing from "./Pages/Plans/DietPlanListing";
import WorkoutPlanListing from "./Pages/Plans/WorkoutPlanListing";
import DietTemplate from "./Pages/Templates/DietTemplate";
import { WorkoutTemplate } from "./Pages/Templates/WorkoutTemplate/Index";
import { AllocationList } from "./Pages/Settings/AutoAllocation/Index";
import Plivo from "./context/Plivo";
import { CallerPopupProvider } from "./Pages/store/Call/CallerPopupContext.jsx";
// import ClientWorkoutPlan from "./Pages/Clients/ClientWorkoutPlan";
import {
  Programs,
  AddProgram,
  AddProgramNew,
  ProgramsNew,
} from "./Pages/Programs/Index";
import Studio from "./Pages/Studio/Studio";

import ListOfPaymnets from "./Pages/Payments/ListOfPaymnets";
import AddRuleSales from "./Pages/Automation/SalesAutomation/AddRule.jsx";
import AddRuleClient from "./Pages/Automation/Client Automation/AddRule.jsx";

import MyTask from "./Pages/Add Task/MyTask";

import { LeadDetailsPopup, UnAssignedPayments } from "./Pages/Payments/Index";
import ViewPdf from "./Utlis/ViewPdf";
import { Appointments } from "./Pages/Appointments/Index";

import LiveSession from "./Pages/Live Session/LiveSession";

import CreateSession from "./Pages/Live Session/components/CreateSession.jsx";
import TaskDashBorad from "./Pages/Programs/components/TaskSettings/TaskDashBorad.jsx";
import AddTask from "./Pages/Programs/components/TaskSettings/AddTask.jsx";
import AccessDashborad from "./Pages/Programs/components/AccessSettings/AccessDashborad.jsx";
import AddAccess from "./Pages/Programs/components/AccessSettings/AddAccess.jsx";
import InactivityDetector from "./components/InactivityDetector.jsx";

import Threshold from "./Pages/Automation/Client Automation/Threshold.jsx";

function App() {
  let userList = JSON.parse(window.localStorage.getItem("operator"));

  // const { plivo, callFromName, setCallFromName, callFromNumber, setCallFromNumber, isIncomingCall, setIsIncomingCall } = useContext(PlivoContext)
  localStorage.removeItem("backButton");
  const [state, setState] = useState({
    // ATLAS_URI: "http://localhost:8080",
    // ATLAS_URI: "http://43.204.108.170:5000",
    // ATLAS_URI: "https://8114-103-112-33-217.ngrok-free.app",
    ATLAS_URI: process.env.REACT_APP_API_URL,
    // ATLAS_URI: "http://65.0.170.118:5000",
    //ATLAS_URI: "https://stage-back.absoluhealthcare.com",
    // ATLAS_URI: "http://13.233.246.89:5000",
    // ATLAS_URI: "http://172.31.47.13:5000",
    // ATLAS_URI: "http://3.6.91.84:5000",
    // ATLAS_URI: "http://65.1.134.119:5000",
    // ATLAS_URI: "http://15.206.89.127:5000",
    // ATLAS_URI: "https://team-back.absoluhealthcare.com",

    CSRF_TOKEN: "",
    operator: {
      Name: "",
      Username: "",
      Role: "",
      RoleId: "",
      Mobile_number: "",
    },
    configToken: {},
    pageAccessible: [],
    featureAccessible: [],
    sessionID: "",
    EditDetailsData: {},
    currentPage: "Dashboard",
    leadsList: [],
    sourcesList: [],
    propertyTypeList: [],
    usersList: [],
    statusList: [],
  });

  useEffect(() => {
    let operator = JSON.parse(window.localStorage.getItem("operator"));
    if (operator) {
      state.operator = operator;
    }

    if (state.operator.Username === "") {
      // if (window.location.pathname !== "/") {
      //   window.location.replace("/");
      // }
    } else {
      //Get All Roles
      const token = JSON.parse(window.localStorage.getItem("operator")).token;
      const config = { headers: { Authorization: `Bearer ${token}` } };

      axios
        .all([
          axios.get(`${ATLAS_URI}/getRoleByID/${state.operator.Role}`, config),
          axios.get(
            `${ATLAS_URI}/getByIdWithFeature/${state.operator.Role}`,
            config
          ),
        ])
        .then(
          axios.spread((roleInfo, roleFeatureInfo) => {
            const roleData = roleInfo.data;
            if (typeof roleData !== "undefined" && roleData !== null) {
              setState((prevState) => ({
                ...prevState,
                pageAccessible: roleData.Pages,
                operator: {
                  ...prevState.operator,
                  Role: roleData.Role,
                  RoleId: roleData.id,
                },
                configToken: config,
              }));
            }

            const roleFeatureData = roleFeatureInfo.data;
            if (
              typeof roleFeatureData !== "undefined" &&
              roleFeatureData !== null
            ) {
              setState((prevState) => ({
                ...prevState,
                featureAccessible: roleFeatureData.role_feature_access,
                operator: {
                  ...prevState.operator,
                  Feature: roleFeatureData.role_feature_access,
                },
                configToken: config,
              }));
            }
          })
        )
        .catch((err) => console.log(err));

      // axios.get(`${ATLAS_URI}/getRoleByID/${state.operator.Role}`)
      //   .then(response => {
      //     const roleData = response.data;
      //     if (typeof roleData !== 'undefined' && roleData !== null) {
      //       setState(prevState => ({
      //         ...prevState,
      //         pageAccessible: roleData.Pages,
      //         operator: {
      //           ...prevState.operator,
      //           Role: roleData.Role
      //         },
      //         configToken: config
      //       }))
      //     }
      //   }).catch(err => console.log(err))
    }
  }, []);

  const handleInactivity = async () => {
    const loginActivityId = JSON.parse(localStorage.getItem("loginActivityId"));

    const response = await axiosFetch({
      url: `/updateLogoutActivity`,
      method: "post",
      requestConfig: {
        loginActivityID: loginActivityId,
      },
    });

    if (response.status === 200) {
      window.localStorage.removeItem("states");
      window.localStorage.removeItem("operator");
      window.localStorage.removeItem("LEAD_LC_STORAGE_GRID");
      window.localStorage.removeItem("LEAD_LC_STORAGE_PANEL");
      window.localStorage.removeItem("RESTORE_LEAD_LISTING_SCREEN_GRID");
      window.localStorage.removeItem("RESTORE_LEAD_LISTING_SCREEN_PANEL");
      window.localStorage.removeItem("goto_clients_workoutplan_page");
      window.localStorage.removeItem("CLIENT_LC_STORAGE");
      window.localStorage.removeItem("RESTORE_CLIENT_LISTING_SCREEN");
      window.location.replace("/");
    }
  };

  if (window.location.pathname === "/payment-status") {
    return (
      <State>
        <Success />
      </State>
    );
  }

  if (window.location.pathname === "/forms") {
    return (
      <State>
        <Form />
      </State>
    );
  }

  if (window.location.pathname === "/") {
    return state.operator.Username === "" ? (
      <State>
        <Login />
      </State>
    ) : (
      (window.location.href = "/dashboard")
    );
  }

  const token = JSON.parse(localStorage.getItem("operator"))?.token;

  if (!token) {
    return (
      <State>
        <Login />
      </State>
    );
  }

  return (
    <React.Fragment>
      <Router>
        <State>
          <CallerPopupProvider>
            <Plivo>
              <TopHeader />
              <Header />

              {userList?.Username !== "Devaraj" && (
                <InactivityDetector
                  timeout={1800000}
                  onInactive={handleInactivity}
                />
              )}

              <main id="pageContainer">
                <SideBar featureState={state} />

                <section id="page_section" style={{ position: "sticky" }}>
                  {
                    <Switch>
                      {/*--------------- Dashboard ---------------------*/}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id == 45 && fe.is_view;
                      }) && (
                        <Route
                          path="/dashboard"
                          exact
                          component={() => <Dashboard />}
                        />
                      )}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id == 45 && fe.is_view;
                      }) && (
                        <Route
                          path="/myTask"
                          exact
                          component={() => <MyTask />}
                        />
                      )}
                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id == 45 && fe.is_view;
                      }) && (
                        <Route
                          path="/newClient"
                          exact
                          component={() => <NewClientLeadTable />}
                        />
                      )}
                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id == 45 && fe.is_view;
                      }) && (
                        <Route
                          path="/newLead"
                          exact
                          component={() => <NewClientLeadTable />}
                        />
                      )}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id == 45 && fe.is_view;
                      }) && (
                        <Route
                          path="/liveSesion"
                          exact
                          component={() => <LiveSession />}
                        />
                      )}
                      {/*--------------- Calender ---------------------*/}
                      {/* permission will add later */}
                      {/* // component={() => <Appointments />} */}
                      <Route
                        path="/appointment"
                        exact
                        component={() => <Appointments />}
                      />

                      {/*--------------- Clients ---------------------*/}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id === 5 && fe.is_view;
                      }) && (
                        <Route
                          path="/Clients/addClient"
                          exact
                          component={() => <AddClient />}
                        />
                      )}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id === 5 && fe.is_view;
                      }) && (
                        <Route
                          path="/Clients/listClients"
                          exact
                          // component={() => <NewLeadAndClientTable />}
                          component={() => <NewClientLeadTable />}
                        />
                      )}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id === 5 && fe.is_view;
                      }) && (
                        <Route
                          path="/Clients/progressReport"
                          exact
                          component={() => <ViewPdf />}
                        />
                      )}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id === 5 && fe.is_view;
                      }) && (
                        <Route
                          path="/Reports/activityReport"
                          exact
                          component={() => <Report />}
                        />
                      )}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id === 5 && fe.is_view;
                      }) && (
                        <Route
                          path="/Reports/progressReport"
                          exact
                          component={() => <Report />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) => fe.feature_id === 5 && fe.is_view
                      ) && (
                        <Route
                          path="/Clients/clientDetail/:id"
                          exact
                          component={() => <ClientDetail />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) => fe.feature_id === 5 && fe.is_view
                      ) && (
                        <Route
                          path="/Clients/templateDetail/:id"
                          exact
                          component={() => <ClientDietPlans />}
                        />
                      )}

                      {/*--------------- Agents ---------------------*/}
                      {state.pageAccessible.includes("Agents") && (
                        <Route
                          path="/Agents/addAgent"
                          exact
                          component={() => <AddAgent />}
                        />
                      )}

                      {/* {state.featureAccessible.find(fe=>{return ((fe.sub_feature_id == 23 && fe.is_view == true) || (fe.sub_feature_id == 24 && fe.is_view == true)) })
                      && (<Route
                        path="/Plans/diet&WorkoutPlan"
                        exact
                        component={() => <Plans />}
                      />)
                    } */}

                      {state.featureAccessible.find((fe) => {
                        return fe.sub_feature_id == 23 && fe.is_view == true;
                      }) && (
                        <Route
                          path="/Plans/dietPlan"
                          exact
                          component={() => <DietPlanListing />}
                        />
                      )}

                      {state.featureAccessible.find((fe) => {
                        return fe.sub_feature_id == 24 && fe.is_view == true;
                      }) && (
                        <Route
                          path="/Plans/workoutPlan"
                          exact
                          component={() => <WorkoutPlanListing />}
                        />
                      )}

                      {state.pageAccessible.includes("Agents") && (
                        <Route
                          path="/Agents/listAgents"
                          exact
                          component={() => <ListAgents />}
                        />
                      )}

                      {/*--------------- Projects ---------------------*/}

                      {state.pageAccessible.includes("Projects") && (
                        <Route
                          path="/Projects/addProjects"
                          exact
                          component={() => <AddProjects />}
                        />
                      )}
                      {state.pageAccessible.includes("Projects") && (
                        <Route
                          path="/Projects/viewAllProjects"
                          exact
                          component={() => <ViewAllProjects />}
                        />
                      )}

                      {/*--------------- Properties ---------------------*/}

                      {state.pageAccessible.includes("Properties") && (
                        <Route
                          path="/Properties/addNewProperty"
                          exact
                          component={() => <AddProperty />}
                        />
                      )}
                      {state.pageAccessible.includes("Properties") && (
                        <Route
                          path="/Properties/viewAllProperties"
                          exact
                          component={() => <ViewAllProperties />}
                        />
                      )}

                      {/*--------------- Leads ---------------------*/}

                      {state.featureAccessible.find((fe) => {
                        return fe.feature_id === 1 && fe.is_view == true;
                      }) && (
                        <Route
                          path="/Leads/listLeads"
                          exact
                          component={() => <NewClientLeadTable />}
                          //component={() => <NewLeadAndClientTable />}
                        />
                      )}

                      {/*  {state.featureAccessible.find(
                      (fe) =>
                        (fe.sub_feature_id === 2 || fe.sub_feature_id === 3) &&
                        fe.is_view &&
                        fe.is_edit
                    ) && ( */}
                      <Route
                        path="/Interactions/calls"
                        exact
                        component={() => <CallHistory />}
                      />
                      {/*   )}  */}

                      {/* -------------------------- Programs ------------------------ */}
                      {/* New UI  */}
                      <Route
                        path="/Programs/programList"
                        exact
                        component={() => <ProgramsNew />}
                      />
                      <Route
                        path="/programs/createNewProgramNew"
                        exact
                        component={() => <AddProgramNew />}
                      />
                      <Route
                        path="/Programs/taskAutomation"
                        exact
                        component={() => <TaskDashBorad />}
                      />
                      <Route
                        path="/Programs/accessAutomation"
                        exact
                        component={() => <AccessDashborad />}
                      />
                      <Route
                        path="/Programs/accessAutomation/addAccess"
                        exact
                        component={() => <AddAccess />}
                      />
                      <Route
                        path="/Programs/taskAutomation/AddTask"
                        exact
                        component={() => <AddTask />}
                      />
                      {/* end UI */}
                      {state.operator.RoleId == 1 && (
                        <Route
                          path="/programs"
                          exact
                          component={() => <Programs />}
                        />
                      )}

                      {state.operator.RoleId == 1 && (
                        <Route
                          path="/programs/client"
                          exact
                          component={() => <Programs />}
                        />
                      )}

                      {state.operator.RoleId == 1 && (
                        <Route
                          path="/programs/createNewProgram"
                          exact
                          component={() => <AddProgram />}
                        />
                      )}

                      {state.operator.RoleId == 1 && (
                        <Route
                          path="/programs/edit/:id"
                          exact
                          component={() => <AddProgram />}
                        />
                      )}

                      {/* -------------------------- Studio ------------------------ */}

                      {state.operator.RoleId == 1 && (
                        <Route
                          path="/studio/"
                          exact
                          //component={() => <Studio />
                          component={() => <LiveSession />}
                        />
                      )}

                      {state.operator.RoleId == 1 && (
                        <Route
                          path="/studio/createSession"
                          exact
                          //component={() => <Studio />
                          component={() => <CreateSession />}
                        />
                      )}

                      {/* -------------------------- Payments ------------------------ */}
                      {state.featureAccessible.find((fe) => {
                        return fe.sub_feature_id == 89 && fe.is_view == true;
                      }) && (
                        <Route
                          path="/Payments/unallocatedPayments"
                          exact
                          component={() => <UnAssignedPayments />}
                        />
                      )}

                      {state.featureAccessible.find((fe) => {
                        return fe.sub_feature_id == 91 && fe.is_view == true;
                      }) && (
                        <Route
                          path="/Payments/listOfPayments"
                          exact
                          component={() => <ListOfPaymnets />}
                        />
                      )}

                      {/* {
                      state.operator.RoleId == 1 && (
                        <Route
                        path="/Payments/listOfPayments"
                        exact
                        component={() => <ListOfPaymnets />}
                      />
                      )
                    
                     } */}

                      {state.featureAccessible.find((fe) => {
                        return fe.sub_feature_id == 48 && fe.is_view == true;
                      }) && (
                        <Route
                          path="/Payments/listOfPayments"
                          exact
                          component={() => <Dashboard />}
                        />
                      )}

                      {state.operator.RoleId == 1 && (
                        <Route
                          path="/allocateLead"
                          exact
                          component={() => <LeadDetailsPopup />}
                        />
                      )}
                      {state.featureAccessible.find(
                        (fe) => fe.sub_feature_info.id == 2
                      ) &&
                        state.featureAccessible.find((fe) => fe.sub_feature_id)
                          .is_edit && (
                          <Route
                            path="/Leads/freshLeads"
                            exact
                            component={() => <FreshLeads />}
                          />
                        )}
                      {state.pageAccessible.includes("Leads") && (
                        <Route
                          path="/Leads/oldPendingFollowUp"
                          exact
                          component={() => <OldPendingFollowUps />}
                        />
                      )}
                      {state.pageAccessible.includes("Leads") && (
                        <Route
                          path="/Leads/todayFollowUp"
                          exact
                          component={() => <TodaysFollowUps />}
                        />
                      )}
                      {state.pageAccessible.includes("Leads") && (
                        <Route
                          path="/Leads/upcomingFollowUp"
                          exact
                          component={() => <UpComingFollowUps />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          (fe.sub_feature_id === 2 ||
                            fe.sub_feature_id === 3) &&
                          fe.is_view &&
                          fe.is_edit
                      ) && (
                        <Route
                          path="/Leads/leadDetails/:id"
                          exact
                          component={() => <LeadDetails />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          (fe.sub_feature_id === 2 ||
                            fe.sub_feature_id === 3) &&
                          fe.is_view &&
                          fe.is_edit
                      ) && (
                        <Route
                          path="/lead/:id"
                          exact
                          component={() => <ClientDetail />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          (fe.sub_feature_id === 2 ||
                            fe.sub_feature_id === 3) &&
                          fe.is_view &&
                          fe.is_edit
                      ) && (
                        <Route
                          path="/callHistory/:page"
                          exact
                          component={() => <CallHistory />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          (fe.sub_feature_id === 2 ||
                            fe.sub_feature_id === 3) &&
                          fe.is_view
                      ) && (
                        <Route
                          path="/Leads/leadView/:id"
                          render={(props) => <LeadView {...props} />}
                          exact
                          // component={() => <LeadView />}
                        />
                      )}
                      {/*--------------- Assigned Leads Calls ---------------------*/}

                      {state.pageAccessible.includes(
                        "Assigned Leads Calls"
                      ) && (
                        <Route
                          path="/assignedLeadsCalls"
                          exact
                          component={() => <AssignedLeadsCalls />}
                        />
                      )}

                      {/*--------------- Assigned Leads Visits ---------------------*/}

                      {state.pageAccessible.includes(
                        "Assigned Leads Visits"
                      ) && (
                        <Route
                          path="/assignedLeadsVisits"
                          exact
                          component={() => <AssignedLeadsVisits />}
                        />
                      )}

                      {/*--------------- User Activity ---------------------*/}

                      {state.pageAccessible.includes("User Activity") && (
                        <Route
                          path="/userActivity"
                          exact
                          component={() => <UserActivity />}
                        />
                      )}

                      {/*--------------- Agreement ---------------------*/}

                      {state.pageAccessible.includes("Agreement") && (
                        <Route
                          path="/Agreement/viewAgreementHistory"
                          exact
                          component={() => <ViewAgreementHistory />}
                        />
                      )}
                      {state.pageAccessible.includes("Agreement") && (
                        <Route
                          path="/Agreement/addNewAgreement"
                          exact
                          component={() => <AddNewAgreement />}
                        />
                      )}

                      {state.pageAccessible.includes("Agreement") && (
                        <Route
                          path="/Agreement/agreementDetails"
                          exact
                          component={() => <AgreementDetails />}
                        />
                      )}

                      {/*--------------- Masters ---------------------*/}

                      {state.pageAccessible.includes("Masters") && (
                        <Route
                          path="/Masters/addSource"
                          exact
                          component={() => <AddSource />}
                        />
                      )}
                      {state.pageAccessible.includes("Masters") && (
                        <Route
                          path="/Masters/addPropertyType"
                          exact
                          component={() => <AddPropertyType />}
                        />
                      )}
                      {state.pageAccessible.includes("Masters") && (
                        <Route
                          path="/Masters/addAmenities"
                          exact
                          component={() => <AddAmenities />}
                        />
                      )}
                      {state.pageAccessible.includes("Masters") && (
                        <Route
                          path="/Masters/addAgreementType"
                          exact
                          component={() => <AddAgreementType />}
                        />
                      )}
                      {state.pageAccessible.includes("Masters") && (
                        <Route
                          path="/Masters/addStatus"
                          exact
                          component={() => <AddStatus />}
                        />
                      )}

                      {/*--------------- Template ---------------------*/}

                      {state.featureAccessible.find(
                        (fe) => fe.sub_feature_id == 23 && fe.is_view
                      ) && (
                        <Route
                          path="/Templates/dietTemplate"
                          exact
                          component={() => <DietTemplate />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) => fe.sub_feature_id == 24 && fe.is_view
                      ) && (
                        <Route
                          path="/Templates/workoutTemplate"
                          exact
                          component={() => <WorkoutTemplate />}
                        />
                      )}

                      {/* {state.featureAccessible.find(
                      (fe) => fe.sub_feature_id == 24 && fe.is_view
                    ) && (
                      <Route
                        path="/Templates/workoutTemplate/:id"
                        exact
                        component={() => <ClientWorkoutPlan />}
                      />
                    )} */}

                      {/*--------------- Notifications ---------------------*/}

                      {state.pageAccessible.includes("Notifications") && (
                        <Route
                          path="/notifications"
                          exact
                          component={() => <Notifications />}
                        />
                      )}

                      {/*--------------- Settings ---------------------*/}

                      {state.operator.RoleId === 1 && (
                        <Route
                          path="/Settings/slots"
                          exact
                          component={() => <Slots />}
                        />
                      )}
                      {state.operator.RoleId === 1 && (
                        <Route
                          path="/Settings/manageUsers"
                          exact
                          component={() => <ManageUsers />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          fe.feature_id === 36 &&
                          fe.sub_feature_id === 85 &&
                          fe.is_view
                      ) !== undefined && (
                        <Route
                          path="/Settings/automation/addClientRule"
                          exact
                          component={() => <AddRuleClient />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          fe.feature_id === 36 &&
                          fe.sub_feature_id === 85 &&
                          fe.is_view
                      ) !== undefined && (
                        <Route
                          path="/Settings/automation/addClientRule"
                          exact
                          component={() => <AddRuleClient />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          fe.feature_id === 36 &&
                          fe.sub_feature_id === 85 &&
                          fe.is_view
                      ) !== undefined && (
                        <Route
                          path="/Settings/automation/threshold"
                          exact
                          component={() => <Threshold />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          fe.feature_id === 36 &&
                          fe.sub_feature_id === 85 &&
                          fe.is_view
                      ) !== undefined && (
                        <Route
                          path="/Settings/automation/addSalesRule"
                          exact
                          component={() => <AddRuleSales />}
                        />
                      )}

                      {state.featureAccessible.find(
                        (fe) =>
                          fe.feature_id === 36 &&
                          fe.sub_feature_id === 85 &&
                          fe.is_view
                      ) !== undefined && (
                        <Route
                          path="/Settings/automation/addSalesRule"
                          exact
                          component={() => <AddRuleSales />}
                        />
                      )}

                      <Route
                        path="/Settings/automation"
                        exact
                        component={() => <Automation />}
                      />

                      {state.operator.RoleId === 1 && (
                        <Route
                          path="/Settings/manageRoles"
                          exact
                          component={() => <ManageRoles />}
                        />
                      )}
                      {state.operator.RoleId === 1 && (
                        <Route
                          path="/Settings/userListsAndLastLogin"
                          exact
                          component={() => <UsersListAndLastLogin />}
                        />
                      )}

                      {/*--------------- Configurations ---------------------*/}

                      {(state.operator.RoleId == 1 ||
                        state.featureAccessible.find(
                          (fe) => fe.sub_feature_id == 85 && fe.is_view
                        )) && (
                        <Route
                          path="/Settings/salesAllocation"
                          exact
                          component={() => <AllocationList />}
                        />
                      )}

                      {state.pageAccessible.includes("Configurations") && (
                        <Route
                          path="/configurations"
                          exact
                          component={() => <Configurations />}
                        />
                      )}

                      <Route path="*" exact component={() => <Dashboard />} />
                    </Switch>
                  }
                </section>
              </main>
              <ToastContainer />
            </Plivo>
          </CallerPopupProvider>
          {/*  {
                  isIncomingCall && <Modal show={isIncomingCall} onHide={() => setIsIncomingCall(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Incoming call</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5>Call From Lead: {callFromName}</h5>
                      <h5>Call From Number: {callFromNumber}</h5>
                      <Button onClick={() => plivo.client.answer()}>Answer</Button>
                      <Button onClick={() => plivo.client.reject()}>Reject</Button>
                    </Modal.Body>
                  </Modal>
                } */}
        </State>
      </Router>
    </React.Fragment>
  );
}

export default App;
