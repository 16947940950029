import React, { useState, useEffect } from "react";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import * as $ from "jquery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import axios from "axios";
import DataTable from "../../components/DataTable";
import SelectBox from "../../components/SelectBox";
import BoxHeader from "../../components/BoxHeader";
import SideBarItem from "../../components/SideBarItem";
import Accordion from "react-bootstrap/Accordion";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import { useContext } from "react";
import { toast } from "react-toastify";
import DietPreferrence1 from "./AboutClient/Index";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {
  CardContent,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MedicalAssesment1 from "./AboutClient/Index";
import WorkoutPreferrence1 from "./AboutClient/Index";
import Others1 from "./AboutClient/Index";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Card } from "@material-ui/core";
import { TabPanel } from "@mui/base";
import DataViewer from "../../components/DataViewer/DataViewer";
import BasicInformation from "./Profile/BasicInformation";
import DietPreferenceForm from "./Profile/DietPreferenceForm";
import WorkPreferenceForm from "./Profile/WorkPreferenceForm";
import MedicalAssesmentForm from "./Profile/MedicalAssesment";

// import { stat } from "fs";
import basicformdata from "./Profile/basicprofile_form";
import dietformdata from "./Profile/diet_preference";
import workoutdata from "./Profile/workout_preference";
import medicalform from "./Profile/medicalform";
import workoutform from "./Profile/WorkPreferenceForm";
import StressManagement from "./Profile/StressManagement";
import FemaleInformation from "./Profile/FemaleInformation";
import OtherInformation from "./Profile/OtherInformation";

function ClientProfile(props) {
  let history = useHistory();
  const [state, setState] = useState({
    newTableRow: {},
    // editingActivated: false,
    // sourcesList: [],
    // diseaseList: [],
  });
  const basicdisplayinfo = [
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Email ID",
      value: "email_id",
    },
    {
      label: "Phone No",
      value: "contact_number",
    },
    {
      label: "Gender",
      value: "gender",
    },
    {
      label: "Age",
      value: "dob",
    },
    {
      label: "Height",
      value: "height",
    },
    {
      label: "Weight",
      value: "current_weight",
    },
    {
      label: "BMI",
      value: "bmi",
    },
    {
      label: "Goal",
      value: "goal",
    },
    {
      label: "Profession",
      value: "profession_details",
    },
    {
      label: "Work Timings",
      value: "Name",
    },
    {
      label: "Work Timings",
      value: "Name",
    },
    {
      label: "Emotional heath",
      value: "Name",
    },
    {
      label: "Best time for us to call you?",
      value: "Name",
    },
    {
      label: "1st Preferred language",
      value: "preferred_language",
    },
    {
      label: "2nd Preferred language",
      value: "Name",
    },
    {
      label: "Address",
      value: "address",
    },
    {
      label: "City",
      value: "city",
    },
    {
      label: "Country",
      value: "country",
    },
    {
      label: "Pin Code",
      value: "pincode",
    },
    {
      label: "Account Name",
      value: "account_name",
    },
    {
      label: "Facebook Link/Id",
      value: "facebook_ID",
    },
    {
      label: "Instagram Link/Id",
      value: "instagram_ID",
    },
  ];

  const dietpreferences = [
    {
      label: "Diet preference",
      value: "dietPreference",
    },
    {
      label: "Allergies",
      value: "dietAnyAllergies",
    },
    {
      label: "other Allergies",
      value: "otherAllergies",
    },
    {
      label: "Preferred Cuisines",
      value: "dietPreferredCuisine",
    },
    {
      label: "Food Dislikes",
      value: "food_dislike",
    },
    {
      label: "Meal Timings",
      value: "",
    },
    {
      label: "Eating Outside",
      value: "",
    },
    {
      label: "Cravings",
      value: "",
    },
    {
      label: "Suppliment Intake",
      value: "",
    },
    {
      label: "Water Intake",
      value: "",
    },
  ];

  const workoutpreference = [
    {
      label: "Any Physical Restrictions",
      value: "",
    },
    {
      label: "Preferred Excercise Time",
      value: "",
    },
    {
      label: "Current Fitness Regime",
      value: "",
    },
    {
      label: "Any Challenges Faced",
      value: "",
    },
    {
      label: "How Often do you workout in a week",
      value: "",
    },
    {
      label: "Workout Preference",
      value: "",
    },
  ];

  const medicalassessment = [
    {
      label: "Medical Condition",
      value: "",
    },
    {
      label: "Are you taking any medication",
      value: "",
    },
    {
      label: "Are you suffering from any health issues?",
      value: "",
    },
    {
      label: "Body aches",
      value: "",
    },
    {
      label: "Any recent health checkup?",
      value: "",
    },
    {
      label: "History of surgery",
      value: "",
    },
  ];

  const stressmanagement = [
    {
      label: "Emotional Health",
      value: "",
    },
    {
      label: "Sleep Hours",
      value: "",
    },
    {
      label: "Type of Sleep",
      value: "",
    },
    {
      label: "Stress leve on a scale of 1-10",
      value: "",
    },
    {
      label: "Reason for stress",
      value: "",
    },
    {
      label: "Social habits?",
      value: "",
    },
    {
      label: "Energy Level throughout the Day",
      value: "",
    },
  ];

  const femaleinformation = [
    {
      label: "Which Trimster are you in currently?",
      value: "",
    },
    {
      label: "Are you in your postpartum phase?",
      value: "",
    },
    {
      label: "Type of Delivery?",
      value: "",
    },
    {
      label: "Are you dealing with Diastases recti?",
      value: "",
    },
    {
      label: "Finger Gap",
      value: "",
    },
  ];
  const [currentTab, setCurrentTab] = React.useState(0);

  const [flag, setFlag] = useState(false);
  const contextState = useContext(stateContext);

  const { configToken, sourcesList } = contextState.state;
  const { operator, featureAccessible } = contextState.state;
  const [editable, setEditable] = useState(false);
  const [emotionalHealth, setEmotionalHealth] = useState([]);
  const [displayinfo, setDisplayinfo] = useState([]);
  const [forminfo, setForminfo] = useState([]);

  const [preferred_language, setPreferred_language] = useState({});
  const [errorForClientName, setErrorForClientName] = useState("");
  const [errorForHeight, setErrorForHeight] = useState("");
  const [errorForWeight, setErrorForWeight] = useState("");
  const [errorForPhoneNo, setErrorForPhoneNo] = useState("");
  const [phoneInput, setPhoneInput] = useState("");

  const healthList = [
    {
      id: 1,
      title: "Excessive Stress/Anxiety",
    },
    {
      id: 2,
      title: "Sleep issues",
    },

    {
      id: 3,
      title: "Depression",
    },

    {
      id: 4,
      title: "Anger issue",
    },

    {
      id: 5,
      title: "Loneliness",
    },

    {
      id: 6,
      title: "Relationship Stress",
    },

    {
      id: 7,
      title: "None",
    },
  ];
  const emotionalHealthIds = healthList
    .filter((c) => props.state?.emotional_health?.includes(c.title))
    .map((c) => c.id);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      newTableRow: { ...props.state, emotional_health: emotionalHealthIds },
    }));
    setPhoneInput(props?.state?.contact_number);
  }, [props?.state]);

  const AboutTab = "BasicInformation";
  const MedicalAssessmentTab = "MedicalAssessment";
  const FoodStyleTab = "FoodStyle";
  const DietPreferrence = "DietPreferrence";
  const WorkoutPreferrence = "WorkoutPreferrence";
  const Others = "Others";

  const [tab, setTab] = useState(AboutTab);
  const routeParams = useParams();

  function setTabShown(e, tab) {
    $(".profileInfo li").removeClass("active");
    $(e.target).addClass("active");

    $(`.profileTab`).animate({ left: "+=100%", opacity: "-=1" }, 250);
    setTimeout(() => {
      setTab(tab);
      $(".profileTab").css({ left: "-100%" });
      $(".profileTab").animate({ left: "+=100%", opacity: "+=1" }, 250);
    }, 300);
    setEditable(false);
  }
  const createSpecialCharsRegex = () => /[^A-Za-z 0-9]/g;

  function changeHandler(e) {
    const specialCharsRegex = createSpecialCharsRegex();

    if (
      e.target.name === "occupation" &&
      specialCharsRegex.test(e.target.value)
    ) {
      return;
    }
    if (e.target.name === "city" && specialCharsRegex.test(e.target.value)) {
      return;
    }

    if (e.target.name === "country" && specialCharsRegex.test(e.target.value)) {
      return;
    }

    oldChangeHandler(
      e,
      state,
      setState,
      setErrorForClientName,
      setErrorForHeight,
      setErrorForPhoneNo,
      setErrorForWeight
    );
  }

  const detectPermision = () => {
    const userid = JSON.parse(window.localStorage.getItem("operator")).Role;

    if (userid === 1) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };
  useEffect(() => {
    let view_arr = [];
    if (state.newTableRow) {
      basicdisplayinfo.map((key) => {
        let viewobj = {
          label: key.label,
          value: state.newTableRow[key.value],
        };
        view_arr.push(viewobj);
      });
      setDisplayinfo(view_arr);
    }
  }, [state]);
  useEffect(() => {
    detectPermision();
  }, []);
  const LeadID = useParams();
  const saveEditedClient = (e) => {
    e.preventDefault();

    const id = JSON.parse(window.localStorage.getItem("operator")).id;

    let request = {
      id: LeadID.id,
      name: state.newTableRow.name,
      updated_by: id,
      Email: state.newTableRow?.email_id,
      contact_number: phoneInput,
      dob: state.newTableRow.dob,
      city: state.newTableRow.city,
      address: state.newTableRow?.address,
      PINCode: state.newTableRow.PinCode,
      // State:state.newTableRow.State
      gender: state.newTableRow?.gender,
      age: parseInt(state.newTableRow?.age),
      height: state.newTableRow?.height,
      height_unit: state?.newTableRow?.height_unit,
      current_weight: state.newTableRow?.current_weight,
      bmi: parseFloat(state.newTableRow?.bmi),
      pincode: state?.newTableRow?.pincode,
      country: state.newTableRow?.country,
      source: sourcesList?.find((c) => c.Source === state.newTableRow.SourceID)
        ?.id,
      emotional_health: healthList
        ?.filter((c) => state?.newTableRow?.emotional_health.includes(c.id))
        .map((c) => c.title),
      preferred_language: state.newTableRow?.preferred_language,
      occupation: state.newTableRow?.occupation,
      instagram_ID: state.newTableRow?.instagram_ID,
      facebook_ID: state.newTableRow?.facebook_ID,
      weight_unit: state?.newTableRow?.weight_unit,
    };
    if (
      state.newTableRow?.height !== "" &&
      state.newTableRow?.current_weight !== "" &&
      state.newTableRow.name.trim() !== "" &&
      state.newTableRow.contact_number !== "" &&
      phoneInput?.length > 3
      // /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(state.newTableRow.LeadID?.contact_number)
    ) {
      const regexForHeightUnit1 = /^(?:[3-9]'(?:[0-9]|1[0-2])")$/;
      const regexForLimitingTheValue = /^\d+'\d+"$/;

      if (
        (state?.newTableRow?.height_unit === 1 &&
          regexForHeightUnit1.test(state.newTableRow?.height) &&
          regexForLimitingTheValue.test(state.newTableRow?.height)) ||
        (state?.newTableRow?.height_unit === 0 &&
          !regexForHeightUnit1.test(state.newTableRow?.height))
      ) {
        axiosFetch({
          url: `/updateLead/${LeadID.id}`,
          method: "post",
          requestConfig: request,
        })
          .then((res) => {
            if (res.status === 200) {
              toast.success("Updated Successfully!", {
                position: toast.POSITION.TOP_CENTER,
              });

              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else if (res.status === 400) {
              console.error(res.data.message);
              toast.error("Bad Request", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (res.status === 401) {
              history.push("/");
            }
          })
          .catch((err) => console.error(err));
      } else {
        toast.error("Please provide the correct height value", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      if (state.newTableRow?.height === "") {
        setErrorForHeight("Please Enter Something");
      }
      if (state.newTableRow?.current_weight === "") {
        setErrorForWeight("Please Enter Something");
      }
      if (state.newTableRow?.name.trim() === "") {
        setErrorForClientName("Please Enter Something");
      }
      if (phoneInput === "") {
        setErrorForPhoneNo("Please Enter Something");
      } else if (phoneInput?.length <= 3) {
        setErrorForPhoneNo("Invalid Phone Number");
      }
      // else if(!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(state.newTableRow.LeadID?.contact_number))){
      //   setErrorForPhoneNo('Invalid Phone Number')
      // }
    }

    // axios.post(`${ATLAS_URI}/updateClient/${props.state.id}`,request,configToken)

    // .then(res=>{
    //  if(res.status==200){
    //   toast.success("Updated Successfully!", {
    //     position: toast.POSITION.TOP_CENTER,
    //   })
    //   setTimeout(() => {

    //     window.location.reload()
    //   }, 1000);
    //  }

    //     setState(prevState => ({

    //        newTableRow: request
    //    }))
    //   // )
    //   })
    // .catch(err=>console.error(err))
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChangeEmotionalHealth = (e) => {
    setEmotionalHealth(e.target.value);
  };

  const handleChangePreferredLanguage = (e) => {
    setPreferred_language(e.target.value);
  };

  const preferredLanguage = [
    "English",
    "Tamil",
    "Telugu",
    "Hindi",
    "Bengali",
    "Kannada",
    "Urdu",
    "Malayalam",
  ];

  const gender = ["M", "F"];

  const handleChangePhoneNumber = (e) => {
    setPhoneInput("+" + e);
    setErrorForPhoneNo("");
  };

  const setEditableTrue = (e) => {
    e.preventDefault();
    setEditable(true);
  };
  const weightUnit = [
    {
      id: 0,
      title: "Kg",
    },
    {
      id: 1,
      title: "lbs",
    },
  ];

  const heightUnit = [
    {
      id: 0,
      title: "cm",
    },
    {
      id: 1,
      title: "ft",
    },
  ];
  const handleChange = (event, newValue) => {
    let view_arr = [];
    setCurrentTab(newValue);

    let displaymeta = [];
    let formmeta = [];
    switch (newValue) {
      case 0:
        displaymeta = basicdisplayinfo;
        break;
      case 1:
        displaymeta = dietpreferences;
        break;
      case 2:
        displaymeta = workoutpreference;

        break;
      case 3:
        displaymeta = medicalassessment;

        break;
      case 4:
        displaymeta = femaleinformation;

        break;
      default:
        break;
    }

    if (state.newTableRow) {
      displaymeta.map((key) => {
        let viewobj = {
          label: key.label,
          value: state.newTableRow[key.value],
        };
        view_arr.push(viewobj);
      });
      setDisplayinfo([...view_arr]);
      //setForminfo([...formmeta])
    }
  };

  // Close function only implementoed for basic info edit
  const updateclientinformation = async (data, closeFunction) => {
    axiosFetch({
      url: `/updateclientprofileinfo/${LeadID?.id}`,
      method: "post",
      requestConfig: {
        ...data,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Updated Successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.setIsSaveInfo(true);
          setTimeout(() => {}, 1000);
          closeFunction(false);
        } else if (res.status === 400) {
          console.error(res.data.message);
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              value={currentTab}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "black",
                },
              }}
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <Tab
                label={
                  <Typography
                    variant="inherit"
                    style={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Basic Information
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    variant="inherit"
                    style={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Diet Preference
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    variant="inherit"
                    style={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Workout Preference
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    variant="inherit"
                    style={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Medical Assessment
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    variant="inherit"
                    style={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Sleep, Stress, Energy
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    variant="inherit"
                    style={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Female Information
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    variant="inherit"
                    style={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Other Information
                  </Typography>
                }
              />
            </Tabs>
          </Grid>

          {currentTab == 0 && props?.profileinfo && (
            <Grid container item xs={12} margin={2}>
              {" "}
              <BasicInformation
                displaydata={props?.profileinfo}
                formdata={forminfo}
                update={updateclientinformation}
              />{" "}
            </Grid>
          )}
          {currentTab == 1 && (
            <Grid container item xs={12} margin={2}>
              {" "}
              <DietPreferenceForm
                displaydata={props?.profileinfo}
                formdata={forminfo}
                update={updateclientinformation}
              />{" "}
            </Grid>
          )}
          {currentTab == 2 && (
            <Grid container item xs={12} margin={2}>
              {" "}
              <WorkPreferenceForm
                displaydata={props?.profileinfo}
                formdata={forminfo}
                update={updateclientinformation}
              />
            </Grid>
          )}
          {currentTab == 3 && (
            <Grid container item xs={12} margin={2}>
              {" "}
              <MedicalAssesmentForm
                displaydata={props?.profileinfo}
                formdata={forminfo}
                update={updateclientinformation}
              />{" "}
            </Grid>
          )}
          {currentTab == 4 && (
            <Grid container item xs={12} margin={2}>
              {" "}
              <StressManagement
                displaydata={props?.profileinfo}
                formdata={forminfo}
                update={updateclientinformation}
              />{" "}
            </Grid>
          )}
          {currentTab == 5 && (
            <Grid container item xs={12} margin={2}>
              {" "}
              <FemaleInformation
                displaydata={props?.profileinfo}
                formdata={forminfo}
                update={updateclientinformation}
              />{" "}
            </Grid>
          )}
          {currentTab == 6 && (
            <Grid container item xs={12} margin={2}>
              {" "}
              <OtherInformation
                displaydata={props?.profileinfo}
                formdata={forminfo}
                update={updateclientinformation}
              />{" "}
            </Grid>
          )}
        </Grid>
      </CardContent>

      {/* <nav className="tabNavigationForProfile">
        <ul>
          <li className="active" onClick={(e) => setTabShown(e, AboutTab)}>
            Basic Information
          </li>
          <li onClick={(e) => setTabShown(e, DietPreferrence)}>
            Diet Preference
          </li>
          <li onClick={(e) => setTabShown(e, WorkoutPreferrence)}>
            Workout Preference
          </li>
          <li onClick={(e) => setTabShown(e, MedicalAssessmentTab)}>
            Medical Assessment
          </li>
          <li onClick={(e) => setTabShown(e, Others)}>Others</li>
        </ul>
      </nav> */}
    </Card>
  );
}
function About(props, state, setState, changeHandler) {
  return;
}
// function MedicalAssessment(props, state, setState, changeHandler)

// return
/*
function About(props, state, setState, changeHandler) {
  return <div className="row">
    <BoxHeader title={`Personal Information`} />

    <form>
      <div className="box-body bozero ">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>First Name</label>{" "}
              <small className="req"> *</small>
              <input
                name="FirstName"
                type="text"
                className="form-control"
                required
                value={state.newTableRow.FirstName}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Last Name</label> <small className="req"> *</small>
              <input
                name="LastName"
                type="text"
                className="form-control"
                required
                value={state.newTableRow.LastName}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>DOB</label>
              <input
                name="DOB"
                type="date"
                className="form-control"
                value={state.newTableRow.DOB}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Gender</label> <small className="req"> *</small>
              <select
                required
                className="form-control"
                name="Type"
                value={state.newTableRow.Gender}
                onChange={changeHandler}
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>Phone</label> <small className="req"> *</small>
              <input
                name="Phone"
                type="text"
                className="form-control"
                required
                value={state.newTableRow.Phone}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>AlternativePhone</label>
              <input
                name="AlternativePhone"
                type="date"
                className="form-control"
                value={state.newTableRow.AlternativePhone}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label> Email</label> <small className="req"> *</small>
              <input
                name="Email"
                type="email"
                className="form-control"
                required
                value={state.newTableRow.Email}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>AlternativeEmail</label>
              <input
                name="AlternativeEmail"
                type="text"
                className="form-control"
                value={state.newTableRow.AlternativeEmail}
                onChange={changeHandler}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>Anniversary</label>
              <input
                name="Anniversary"
                type="date"
                className="form-control"
                value={state.newTableRow.Anniversary}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <SelectBox
              label="Source"
              name="Source"
              options={props.state.sourcesList}
              attributeShown="Source"
              changeHandler={changeHandler}
              value={state.newTableRow.Source}
              resetValue={() =>
                setState({
                  ...state,
                  newTableRow: {
                    ...state.newTableRow,
                    Source: "Select",
                  },
                })
              }
            />
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Change Referral</label>
              <input
                name="ChangeReferral"
                type="text"
                className="form-control"
                value={state.newTableRow.ChangeReferral}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Goal</label>
              <input
                name="Goal"
                type="text"
                className="form-control"
                value={state.newTableRow.Goal}
                onChange={changeHandler}
              />
            </div>
          </div>
        </div>
        <h5 className="mt-2 mb-2">Anthropometric Measurements</h5>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label>Height Ft</label>
              <input
                name="HeightFt"
                type="text"
                className="form-control"
                value={state.newTableRow.HeightFt}
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Height Inch</label>
              <input
                name="HeightInch"
                type="text"
                className="form-control"
                value={state.newTableRow.HeightInch}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Weight (Kgs)</label>
              <input
                name="Weight"
                type="text"
                className="form-control"
                value={state.newTableRow.Weight}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Target Weight (Kgs)</label>
              <input
                name="TargetWeight"
                type="text"
                className="form-control"
                value={state.newTableRow.TargetWeight}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>BMI</label>
              <input
                name="BMI"
                type="text"
                className="form-control"
                value={state.newTableRow.BMI}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>IdealWeight (Kgs)</label>
              <input
                name="IdealWeight"
                type="text"
                className="form-control"
                value={state.newTableRow.IdealWeight}
                onChange={changeHandler}
              />
            </div>
          </div>
        </div>
        <h5 className="mt-2 mb-2">Permanent Address</h5>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Address Line 1</label>
              <textarea
                name="Address1"
                type="text"
                className="form-control"
                value={state.newTableRow.Address1}
                onChange={changeHandler}
                rows="3"
              ></textarea>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Address Line 2</label>
              <textarea
                name="Address2"
                type="text"
                className="form-control"
                value={state.newTableRow.Address2}
                onChange={changeHandler}
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>Country</label>
              <input
                name="Country"
                type="date"
                className="form-control"
                value={state.newTableRow.Country}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>State</label>
              <input
                name="State"
                type="date"
                className="form-control"
                value={state.newTableRow.State}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>City</label>
              <input
                name="City"
                type="text"
                className="form-control"
                value={state.newTableRow.City}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Pincode</label>
              <input
                name="PINcode"
                type="text"
                className="form-control"
                value={state.newTableRow.PINcode}
                onChange={changeHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="box-footer">
        <button type="submit" className="btn btn-info pull-right">
          Save
        </button>
      </div>
    </form>
  </div>
}
*/
export default ClientProfile;
