import ReactDOM from "react-dom";
import "../Report.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

const Backdrop = ({ dispatch }) => {
  return (
    <div
      className={"session-backdrop"}
      onClick={() =>
        dispatch({
          type: "meals",
          payload: false,
        })
      }
    ></div>
  );
};

const table = {
  border: "2px solid #D0D0D0",
  color: "#6B6B6B",
};

const TableHeader = ["S No", "Meal Timing", "Meal List", "Meals Pictures"];

const Picutre = ({ dispatch, currentRow }) => {
  const [meals, setMeal] = useState([]);

  const getMeals = async () => {
    const request = await axiosFetch({
      url: `getMealPlateImagesByLeadIdDate?leadId=${currentRow.leadId}&date=${currentRow.Date}`,
      method: "get",
    });

    setMeal(request.data);
  };

  useEffect(() => {
    getMeals();
  }, [currentRow]);

  return (
    <div className="progress_picture">
      <div className="report__modal-header">
        <h3>Meals Pictures</h3>
        <CloseIcon
          style={{ fontSize: "21px", color: "#6B6B6B", cursor: "pointer" }}
          onClick={() =>
            dispatch({
              type: "meals",
              payload: false,
            })
          }
        />
      </div>

      {meals.length === 0 && (
        <span className="no_data">No Meals Available</span>
      )}
      {meals.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TableHeader.map((row, index) => (
                  <TableCell sx={table}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {meals.map((row, index) => (
                <TableRow>
                  <TableCell key={index} sx={table}>
                    {row.id}
                  </TableCell>
                  <TableCell key={index} sx={table}>
                    {row.mealName}
                  </TableCell>

                  <TableCell key={index} sx={table}>
                    {row.mealList.length === 0 ? (
                      <span className="no_data">No Meals List Available</span>
                    ) : (
                      row.mealList.map((val, idx) => (
                        <span key={idx}>
                          {val.food_item_name}
                          {idx < row.mealList.length - 1 && ', '} 
                        </span>
                      ))
                    )}
                  </TableCell>

                  <TableCell key={index} sx={table}>
                    <div className="meal-img-con">
                      {row.meal.length ===0 ? 
                      (
                        <span className="no_data">No Meals Images Available</span>
                      )
                      : row.meal.map((row, index) => (
                        <img
                          key={index}
                          src={row}
                          alt="progress Pictures"
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "8px",
                          }}
                        />
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

const ActiviyMeals = ({ dispatch, currentRow }) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(<Backdrop dispatch={dispatch} />, backdropRoot)}
      {ReactDOM.createPortal(
        <Picutre dispatch={dispatch} currentRow={currentRow} />,
        editTableModal
      )}
    </>
  );
};

export default ActiviyMeals;
