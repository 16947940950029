import { Typography, Grid } from "@material-ui/core";
import React, { useState } from "react";
import NoImage from "../../../assets/NoImage.jpg";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useEffect } from "react";

const ProgressImages = () => {
  let params = useParams();
  let history = useHistory();

  const [progressImages, setProgressImages] = useState({
    beforeRightFacing: "",
    beforeLeftFacing: "",
    beforeFrontFacing: "",
    beforeBackFacing: "",
    afterRightFacing: "",
    afterLeftFacing: "",
    afterFrontFacing: "",
    afterBackFacing: "",
  });

  const getProgressImages = () => {
    axiosFetch({
      url: `/getProgressImagesToCRM/${params.id}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setProgressImages({
            beforeRightFacing: res.data.right_facing_before_image,
            beforeLeftFacing: res.data.left_facing_before_image,
            beforeFrontFacing: res.data.front_facing_before_image,
            beforeBackFacing: res.data.back_facing_before_image,
            afterRightFacing: res.data.right_facing_after_image,
            afterLeftFacing: res.data.left_facing_after_image,
            afterFrontFacing: res.data.front_facing_after_image,
            afterBackFacing: res.data.back_facing_after_image,
          });
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getProgressImages();
  }, []);
  return (
    <div>
      <Typography
        style={{ fontFamily: "Poppins", fontWeight: "600", fontSize: "18px" }}
      >
        Before Images
      </Typography>
      <br />
      <Grid container xs={12}>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Right Facing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Left Facing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Front Facing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Back Facing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <img
            src={
              progressImages.beforeRightFacing
                ? progressImages.beforeRightFacing
                : NoImage
            }
            alt="no image"
            style={{ width: "190px", height: "200px" }}
          />
        </Grid>
        <Grid item xs={3}>
          <img
            src={
              progressImages.beforeLeftFacing
                ? progressImages.beforeLeftFacing
                : NoImage
            }
            alt="no image"
            style={{ width: "190px", height: "200px" }}
          />
        </Grid>
        <Grid item xs={3}>
          <img
            src={
              progressImages.beforeFrontFacing
                ? progressImages.beforeFrontFacing
                : NoImage
            }
            alt="no image"
            style={{ width: "190px", height: "200px" }}
          />
        </Grid>
        <Grid item xs={3}>
          <img
            src={
              progressImages.beforeBackFacing
                ? progressImages.beforeBackFacing
                : NoImage
            }
            alt="no image"
            style={{ width: "190px", height: "200px" }}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <Typography
        style={{
          fontFamily: "Poppins",
          fontWeight: "600",
          fontSize: "18px",
        }}
      >
        After Images
      </Typography>
      <br />
      <Grid container xs={12}>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Right Facing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Left Facing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Front Facing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Back Facing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <img
            src={
              progressImages.afterRightFacing
                ? progressImages.afterRightFacing
                : NoImage
            }
            alt="no image"
            style={{ width: "190px", height: "200px" }}
          />
        </Grid>
        <Grid item xs={3}>
          <img
            src={
              progressImages.afterLeftFacing
                ? progressImages.afterLeftFacing
                : NoImage
            }
            alt="no image"
            style={{ width: "190px", height: "200px" }}
          />
        </Grid>
        <Grid item xs={3}>
          <img
            src={
              progressImages.afterFrontFacing
                ? progressImages.afterFrontFacing
                : NoImage
            }
            alt="no image"
            style={{ width: "190px", height: "200px" }}
          />
        </Grid>
        <Grid item xs={3}>
          <img
            src={
              progressImages.afterBackFacing
                ? progressImages.afterBackFacing
                : NoImage
            }
            alt="no image"
            style={{ width: "190px", height: "200px" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProgressImages;
