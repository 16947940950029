import React from 'react'
import CalenderAppointment from '../../components/CalenderAppointment'
import EventSection from '../../components/EventSection';

function Calender() {
  return (
    <div className='col-md-12 d-flex flex-row h-100 ms-2' >
      <div className='col-md-6'>
        <CalenderAppointment />
      </div>
      <div className='col-md-6'>
       <EventSection />
      </div>
    </div>
  );
}

export default Calender