import React, { useContext, useEffect, useState } from "react";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FreshLeads from "./FreshLeads";
import LeadDetails from "./LeadDetails";
import { GrClose } from "react-icons/gr";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { slice } from "lodash";
import "./Popupmodel.css";
import { Logger } from "ag-grid-community";
import GridTableClientPlans from "../Clients/GridTableClientPlans";
import { toast } from "react-toastify";

function Popmodel(props) {
  const [show, setShow] = useState(props.show1);
  const [unit, setunit] = useState();
  const contextState = useContext(stateContext);
  // const [planArr, setPlanArr] = useState([]);
  const { configToken } = contextState.state;

  const handleClose = () => {
    props.closeModel({ type: "closeModel", payload: false });
  };

  useEffect(() => {
    props.show1 && setShow(true);
  }, [props.show1]);

  const handleShow = () => setShow(props.show1);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  /* Pagination fo Food data */

  let [page, setPage] = React.useState(1);

  let [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  /* Food Data */

  const [getFoodDetails, setGetFoodDetails] = useState([]);
  const [totalfoodRecord, setTotalfoodRecord] = useState("");
  const [totalPage, setTotalPage] = useState("");
  const foodDetails = async () => {
    await axios
      .get(
        `${ATLAS_URI}/getFoodNutrientDatas?limit=${rowsPerPage}&page=${page}&search=${searchFood}`,
        // `${ATLAS_URI}/getFoodNutrientDatas?search=${searchFood}`,
        configToken
      )
      .then((res) => {
        return (
          setTotalfoodRecord(res.data.body.totalRecord),
          setGetFoodDetails(res.data.body.foodDetails)
        );
      })
      .catch((err) => console.error(err));
  };

  /* Selected Food */

  const [selectedFood, setselectedFood] = useState([]);
  const [food, setFood] = useState(null);
  const [id1, setId1] = useState([]);
  const [childFoodArr, setChildFoodArr] = useState([]);

  const addselectedFood = (food, id) => {
    if (
      food.quantity !== null &&
      food.quantity !== undefined &&
      food.unit !== null &&
      food.unit !== undefined &&
      food.unit !== ""
    ) {
      food.food_item = food.food_item_name;
      let foodArr = props.dietGrid[props.storeids].newTableRow.foodInTable;

      let filteredArr = foodArr.filter((item) => item.id === food.id);

      for (let i in foodArr) {
        if (foodArr[i].childFoodItems !== undefined) {
          let arr = foodArr[i].childFoodItems.filter(
            (item) => item.id === food.id
          );
          if (arr.length !== 0) {
            toast.error("Selected Food Already Exist!", {
              position: toast.POSITION.TOP_CENTER,
            });
            return false;
          }
        }
      }

      if (filteredArr.length !== 0) {
        toast.error("Selected Food Already Exist!", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let obj = {
          "Small Cup": 100,
          "Medium Cup": 200,
          "Large Cup": 350,
          "Small Piece": 35,
          "Medium Piece": 70,
          "Large Piece": 100,
          "Small Glass": 100,
          "Medium Glass": 250,
          "Large Glass": 350,
          gms: 1,
          ml: 1,
        };

        food.calories = Number(
          (
            (parseFloat(food.calories) / parseInt(food.Quantity)) *
            parseInt(food.quantity) *
            obj[food.unit]
          ).toFixed(3)
        );
        food.carbs = Number(
          (
            (food.carbs
              ? (parseFloat(food.carbs) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.fats = Number(
          (
            (parseFloat(food.fats) / parseInt(food.Quantity)) *
            parseInt(food.quantity) *
            obj[food.unit]
          ).toFixed(3)
        );
        food.protein = Number(
          (
            (parseFloat(food.protein) / parseInt(food.Quantity)) *
            parseInt(food.quantity) *
            obj[food.unit]
          ).toFixed(3)
        );
        food.fibre = Number(
          (
            (food.fibre
              ? (parseFloat(food.fibre) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.defaultValue = Number(obj[food.unit]);
        food.CALCIUM_mg = Number(
          (
            (food.CALCIUM_mg
              ? (parseFloat(food.CALCIUM_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.FOLIC_ACID_mg = Number(
          (
            (food.FOLIC_ACID_mg
              ? (parseFloat(food.FOLIC_ACID_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.IRON_mg = Number(
          (
            (food.IRON_mg
              ? (parseFloat(food.IRON_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.MAGNESIUM_mg = Number(
          (
            (food.MAGNESIUM_mg
              ? (parseFloat(food.MAGNESIUM_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.MANGANESE_mg = Number(
          (
            (food.MANGANESE_mg
              ? (parseFloat(food.MANGANESE_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.NIACIN_mg = Number(
          (
            (food.NIACIN_mg
              ? (parseFloat(food.NIACIN_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.PHOSPHORUS_mg = Number(
          (
            (food.PHOSPHORUS_mg
              ? (parseFloat(food.PHOSPHORUS_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.POTASSIUM_mg = Number(
          (
            (food.POTASSIUM_mg
              ? (parseFloat(food.POTASSIUM_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.RIBOFLAVIN_mg = Number(
          (
            (food.RIBOFLAVIN_mg
              ? (parseFloat(food.RIBOFLAVIN_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.SELENIUM_mg = Number(
          (
            (food.SELENIUM_mg
              ? (parseFloat(food.SELENIUM_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.THIAMINE_mg = Number(
          (
            (food.THIAMINE_mg
              ? (parseFloat(food.THIAMINE_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.VITAMIN_A_mg = Number(
          (
            (food.VITAMIN_A_mg
              ? (parseFloat(food.VITAMIN_A_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.VITAMIN_C_mg = Number(
          (
            (food.VITAMIN_C_mg
              ? (parseFloat(food.VITAMIN_C_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.VITAMIN_D_mg = Number(
          (
            (food.VITAMIN_D_mg
              ? (parseFloat(food.VITAMIN_D_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.VITAMIN_E_mg = Number(
          (
            (food.VITAMIN_E_mg
              ? (parseFloat(food.VITAMIN_E_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.ZINC_mg = Number(
          (
            (food.ZINC_mg
              ? (parseFloat(food.ZINC_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );
        food.VITAMIN_K_mg = Number(
          (
            (food.VITAMIN_K_mg
              ? (parseFloat(food.VITAMIN_K_mg) / parseInt(food.Quantity)) *
                parseInt(food.quantity)
              : 0.0) * obj[food.unit]
          ).toFixed(3)
        );

        setFood(food);
        let array = [...id1];
        array.push(id);
        setId1(array);
        let array2 = [];
        array2.push(id);
        let arr = [...selectedFood];
        food.childFoodItems = [];

        arr.push({ ...food });

        setselectedFood(arr);
      }
    } else {
      if (food.quantity === null || food.quantity === undefined) {
        toast.error("Please Select Quantity", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (food.unit === null || food.unit === undefined || food.unit === "") {
        toast.error("Please Select Unit", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
  // food.carbs=(parseFloat(food.carbs)/parseInt(food.Quantity))*parseInt(e.target.value)
  // food.fats=(parseFloat(food.fats)/parseInt(food.Quantity))*parseInt(e.target.value)
  // food.protein=(parseFloat(food.protein)/parseInt(food.Quantity))*parseInt(e.target.value)
  // food.fibre=food.fibre? (parseFloat(food.fibre)/parseInt(food.Quantity))*parseInt(e.target.value):0.000

  /* Remove selected Food */

  const removeSelectedFood = (index) => {
    let list = selectedFood.splice(index, 1);

    setselectedFood([...selectedFood]);
  };

  /* Search your food */

  const [searchFood, setSearchFood] = useState("");
  const searchYourfood = (e) => {
    setSearchFood(e.target.value);
  };

  useEffect(() => {
    foodDetails();
  }, [page, rowsPerPage, searchFood]);

  /* displaySelectedFoodInTable */

  const selectedFoodInTable = (e) => {
    //
    //

    if (
      props?.dietGrid[props.storeids]?.newTableRow?.foodInTable.length !== 0
    ) {
      let arr = props?.dietGrid[props.storeids]?.newTableRow?.foodInTable;
    }

    const selectedFoodWithQuantity = selectedFood.map((foodSelected) => {
      return { ...foodSelected };
    });
    let array = [...selectedFoodWithQuantity];

    if (
      props?.dietGrid[props.storeids]?.newTableRow?.foodInTable?.filter(
        (C) => C.id == props?.childRow?.id
      )
    ) {
      // retrieving particular arr to add child obj
      let newArray = props?.dietGrid[
        props.storeids
      ]?.newTableRow?.foodInTable?.filter((C) => C.id == props?.childRow?.id);
      if (newArray.length !== 0) {
        for (let i in selectedFood) {
          if (selectedFood[i] !== undefined) {
            if (newArray[0].childFoodItems !== undefined) {
              newArray[0].childFoodItems.push(selectedFood[i]);
            } else {
              newArray[0].childFoodItems = [selectedFood[i]];
            }
          }
        }
        props.removeChild(null);
      } else {
        props.setDietGrid((prevState, index) => {
          if (
            props.dietGrid[props.storeids].newTableRow.id ==
            prevState[props.storeids].newTableRow.id
          ) {
            prevState[props.storeids].newTableRow.foodInTable = [
              ...prevState[props.storeids].newTableRow.foodInTable,
              ...array,
            ];
          }
          return prevState;
        });
      }
    }

    // if(!props.show1){
    // if(props.storeids==props.storeids){
    //   props.setDietGrid((prevState)=>{
    //     if(props.dietGrid[props.childRowId].newTableRow.id==prevState[props.childRowId].newTableRow.id){
    //       prevState[props.childRowId].newTableRow.foodInTable.map((c)=>{

    //       })
    //     }
    //   })
    // }

    // props.setDietGrid((prevState, index) => {
    //   // if(props.dietGrid[props.childRowId]?.newTableRow?.foodInTable.find((C)=>C.id==props.childRow.id)){

    //     if (props.dietGrid[props.storeids]?.newTableRow?.id == prevState[props.storeids]?.newTableRow?.id) {

    //       prevState[props.storeids].newTableRow.foodInTable=  [...prevState[props.storeids].newTableRow.foodInTable.map((c)=>{
    //         if(c.id==props.childRow.id){
    //           c.childFoodItems=array
    //         }

    //         // return c

    //       })]
    //       return prevState
    //     }
    //   // }
    //   // else{

    //   //   if (props.dietGrid[props.storeids]?.newTableRow?.id == prevState[props.storeids]?.newTableRow?.id) {
    //   //    prevState[props.storeids].newTableRow.foodInTable = [...prevState[props.storeids].newTableRow.foodInTable, ...array]
    //   //  }
    //   //  return prevState
    //   // }
    // })

    // }
    props.setShow1(false);
  };

  /*  select Quntity */

  const [storeIndex, setStoreIndex] = useState();

  const selectQuntity = (e, food, id) => {
    // food.calories=((parseFloat(food.calories)/parseInt(food.Quantity))*parseInt(e.target.value))
    // food.carbs=(parseFloat(food.carbs)/parseInt(food.Quantity))*parseInt(e.target.value)
    // food.fats=(parseFloat(food.fats)/parseInt(food.Quantity))*parseInt(e.target.value)
    // food.protein=(parseFloat(food.protein)/parseInt(food.Quantity))*parseInt(e.target.value)
    // food.fibre=food.fibre? (parseFloat(food.fibre)/parseInt(food.Quantity))*parseInt(e.target.value):0.000

    food.quantity = parseInt(e.target.value);
    food.childFoodItems = [];
  };

  /* select Unit */

  const selectUnit = (e, food) => {
    // if(e.target.value==='Small Cup'){
    //   food.calories=Number((food.calories*100).toFixed(3))
    //   food.carbs=Number((food.carbs*100).toFixed(3))
    //   food.fats=Number((food.fats*100).toFixed(3))
    //   food.protein=Number((food.protein*100).toFixed(3))
    //   food.fibre=Number((food.fibre*100).toFixed(3))
    //   food.defaultValue=food.quantity*100
    //   // food.unit='gms'
    // }
    // if(e.target.value==='Medium Cup'){
    //   food.calories=Number((food.calories*200).toFixed(3))
    //   food.carbs=Number((food.carbs*200).toFixed(3))
    //   food.fats=Number((food.fats*200).toFixed(3))
    //   food.protein=Number((food.protein*200).toFixed(3))
    //   food.fibre=Number((food.fibre*200).toFixed(3))
    //   food.defaultValue=Number(food.quantity*200)

    // }
    // if(e.target.value==='Large Cup'){
    //   food.calories=Number((food.calories*350).toFixed(3))
    //   food.carbs=Number((food.carbs*350).toFixed(3))
    //   food.fats=Number((food.fats*350).toFixed(3))
    //   food.protein=Number((food.protein*350).toFixed(3))
    //   food.fibre=Number((food.fibre*350).toFixed(3))
    //   food.defaultValue=Number(food.quantity*350)

    // }
    // if(e.target.value==='Small Piece'){
    //   food.calories=Number((food.calories*35).toFixed(3))
    //   food.carbs=Number((food.carbs*35).toFixed(3))
    //   food.fats=Number((food.fats*35).toFixed(3))
    //   food.protein=Number((food.protein*35).toFixed(3))
    //   food.fibre=Number((food.fibre*35).toFixed(3))
    //   food.defaultValue=Number(food.quantity*35)

    // }
    // if(e.target.value==='Medium Piece'){
    //   food.calories=Number((food.calories*70).toFixed(3))
    //   food.carbs=Number((food.carbs*70).toFixed(3))
    //   food.fats=Number((food.fats*70).toFixed(3))
    //   food.protein=Number((food.protein*70).toFixed(3))
    //   food.fibre=Number((food.fibre*70).toFixed(3))
    //   food.defaultValue=Number(food.quantity*70)

    // }
    // if(e.target.value==='Large Piece'){
    //   food.calories=Number((food.calories*100).toFixed(3))
    //   food.carbs=Number((food.carbs*100).toFixed(3))
    //   food.fats=Number((food.fats*100).toFixed(3))
    //   food.protein=Number((food.protein*100).toFixed(3))
    //   food.fibre=Number((food.fibre*100).toFixed(3))
    //   food.defaultValue=Number(food.quantity*100)

    // }
    // if(e.target.value==='Small Glass'){
    //   food.calories=Number((food.calories*100).toFixed(3))
    //   food.carbs=Number((food.carbs*100).toFixed(3))
    //   food.fats=Number((food.fats*100).toFixed(3))
    //   food.protein=Number((food.protein*100).toFixed(3))
    //   food.fibre=food.fibre ?Number((food.fibre*100).toFixed(3)):0.000
    //   food.defaultValue=Number(food.quantity*100)

    // }
    // if(e.target.value==='Medium Glass'){
    //   food.calories=Number((food.calories*250).toFixed(3))
    //   food.carbs=Number((food.carbs*250).toFixed(3))
    //   food.fats=Number((food.fats*250).toFixed(3))
    //   food.protein=Number((food.protein*250).toFixed(3))
    //   food.fibre=Number((food.fibre*250).toFixed(3))
    //   food.defaultValue=Number((food.quantity*250))

    // }
    // if(e.target.value==='Large Glass'){
    //   food.calories=Number((food.calories*350).toFixed(3))
    //   food.carbs=Number((food.carbs*350).toFixed(3))
    //   food.fats=Number((food.fats*350).toFixed(3))
    //   food.protein=Number((food.protein*350).toFixed(3))
    //   food.fibre=Number((food.fibre*350).toFixed(3))
    //   food.defaultValue=Number((food.quantity*350))

    // }
    // if(e.target.value==='ml'){

    //   food.calories=Number((food.calories).toFixed(3))
    //   food.carbs=Number((food.carbs).toFixed(3))
    //   food.fats=Number((food.fats).toFixed(3))
    //   food.protein=Number((food.protein).toFixed(3))
    //   food.fibre=food.fibre ?Number((food.fibre).toFixed(3)):0.000
    //   food.defaultValue=Number(food.quantity)

    // }
    // if(e.target.value==='gms'){

    //   food.calories=Number((food.calories).toFixed(3))
    //     food.carbs=Number((food.carbs).toFixed(3))
    //     food.fats=Number((food.fats).toFixed(3))
    //     food.protein=Number((food.protein).toFixed(3))
    //     food.fibre=food.fibre ?Number((food.fibre).toFixed(3)):0.000
    //   food.defaultValue=Number(food.quantity)
    // }

    food.unit = e.target.value;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="bs-example">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 ">
                    {props.show2 ? "ADD FOOD" : "New Lead"}
                  </div>
                  <div class="col-md-6 alignRight"></div>
                </div>
              </div>
            </div>
          </Modal.Title>
          <GrClose
            style={{
              backgroundColor: isHovering ? "#FF0000" : "",
              color: isHovering ? "#fff" : "",
              border: isHovering ? "1px solid" : "",
              height: isHovering ? "30px" : "",
              width: isHovering ? "30px" : "",
              opacity: isHovering ? "0.7" : "",
            }}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          {props.show2 ? (
            <>
              <p>Selected Foods</p>
              <p>
                {selectedFood.map((selectedFood, index) => {
                  return (
                    <span className="selectedFood">
                      {selectedFood.food_item_name}&nbsp;
                      <GrClose
                        value={index}
                        onClick={() => removeSelectedFood(index)}
                      />
                    </span>
                  );
                })}
              </p>
              <div className="row ">
                <div className="col-sm-3">
                  <div className="form-group"></div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group"></div>
                </div>
                <div
                  className="col-sm-3 "
                  style={{ position: "relative", right: "50px" }}
                >
                  <div className="form-group">
                    <button
                      type="button"
                      style={{ backgroundColor: "#0d6efd", color: "white" }}
                      className="btn btn-primary"
                      onClick={selectedFoodInTable}
                    >
                      Done
                    </button>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <input
                      type="search"
                      onChange={searchYourfood}
                      value={searchFood}
                      placeholder="search"
                    />
                  </div>
                </div>
              </div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>Quantity</td>
                    <td>Measure</td>

                    <td>Action</td>
                  </tr>
                </tbody>
                <tbody>
                  {getFoodDetails
                    .filter((val) => {
                      if (searchFood == "") {
                        return val;
                      } else if (
                        val.food_item_name
                          .toLowerCase()
                          .includes(searchFood.toLowerCase())
                      ) {
                        return val;
                      }
                    })

                    .map((food, id) => {
                      return (
                        <>
                          <tr>
                            <td style={{ width: "200px" }}>
                              <p style={{ lineHeight: "20px" }}>
                                {food.Type === "Veg" ? (
                                  <img
                                    src={require("../../img/icons8-vegetarian-48.png")}
                                    alt="Vegatarian"
                                    style={{ height: "20px" }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../img/icons8-non-vegetarian-food-symbol-48.png")}
                                    alt="non-Veg"
                                    style={{ height: "20px" }}
                                  />
                                )}
                                &nbsp;&nbsp; {food.food_item_name}
                              </p>
                            </td>
                            <td style={{ width: "200px" }}>
                              <select
                                onChange={(e) => selectQuntity(e, food, id)}
                              >
                                <option>Select Quantity</option>
                                {Array.apply(null, { length: 1000 }).map(
                                  (value, index) => (
                                    <option
                                      onClick={() => setStoreIndex}
                                      value={index + 1}
                                    >
                                      {index + 1}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                            <td style={{ width: "200px" }}>
                              <select onChange={(e) => selectUnit(e, food, id)}>
                                {food.Category === "Solid Food" ? (
                                  <>
                                    <option disabled selected>
                                      Select Measure
                                    </option>
                                    <option value="gms">gms</option>
                                    <option value="Small Cup">Small Cup</option>
                                    <option value="Medium Cup">
                                      Medium Cup
                                    </option>
                                    <option value="Large Cup">Large Cup</option>
                                  </> //Piece Food Item
                                ) : food.Category === "Piece Food Item" ? (
                                  <>
                                    <option disabled selected>
                                      Select Measure
                                    </option>
                                    <option value="gms">gms</option>
                                    <option value="Small Piece">
                                      Small Piece
                                    </option>
                                    <option value="Medium Piece">
                                      Medium Piece
                                    </option>
                                    <option value="Large Piece">
                                      Large Piece
                                    </option>
                                  </>
                                ) : food.Category === "Unit Food Item" ? (
                                  <>
                                    <option disabled value="" selected>
                                      Select
                                    </option>
                                    <option value={food.Units_gms_Ml_Piece}>
                                      {food.Units_gms_Ml_Piece}
                                    </option>
                                  </>
                                ) : food.Category === "Liquid Food" ? (
                                  <>
                                    <option disabled selected>
                                      Select Measure
                                    </option>
                                    <option value="ml">ml</option>
                                    <option value="Small Glass">
                                      Small Glass
                                    </option>
                                    <option value="Medium Glass">
                                      Medium Glass
                                    </option>
                                    <option value="Large Glass">
                                      Large Glass
                                    </option>
                                  </>
                                ) : (
                                  <>
                                    <option disabled selected>
                                      Select Measure
                                    </option>
                                    <option value="gms">gms</option>
                                    <option value="cups">cups</option>
                                    <option value="ml">ml</option>
                                  </>
                                )}
                              </select>
                            </td>

                            <td
                              style={{
                                width: "200px",
                                alignContent: "center",
                                transform: "translateX(30%)",
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: "#5126ed",
                                  color: "#fff",
                                  borderColor: "none",
                                }}
                                onClick={() => addselectedFood(food, id)}
                              >
                                Add
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
                <tr>
                  <td></td>
                </tr>
              </table>

              <TablePagination
                count={parseInt(totalfoodRecord)}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <FreshLeads />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Popmodel;
