import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import BoxHeader from '../../components/BoxHeader'
import SelectBox from '../../components/SelectBox'
import Dialog from '../../components/Dialog'
import { getNormalDate, getInputDate } from '../../Helpers/DateHelpers'
import { changeHandler as oldChangeHandler } from '../../Helpers/ChangeHandler'
import stateContext from '../../context/StateContext'
import { ATLAS_URI, occupationList } from '../../Constants'

function AddClient(props) {

    const contextState = useContext(stateContext)
    let { EditDetailsData, configToken, sourcesList } = contextState.state
    const { redirectFromEditDetails, resetEditID } = contextState

    const [state, setState] = useState({
        resetNewRow: {
            Name: "",
            Phone: "",
            Email: "",
            DOB: "",
            PAN: "",
            Address: "",
            City: "",
            State: "",
            PINCode: "",
            Occupation: "",
            Organization: "",
            Designation: "",
            Source: "Select",
            Type: "",
            Anniversary: "",
            ProfileImage: ""

        },
        newTableRow: {
            Name: "",
            Phone: "",
            Email: "",
            DOB: "",
            PAN: "",
            Address: "",
            City: "",
            State: "",
            PINCode: "",
            Occupation: "",
            Organization: "",
            Designation: "",
            Source: "Select",
            Type: "",
            Anniversary: "",
            ProfileImage: ""
        },
        tableBodyList: [

        ],
        dialogInfo: {
            isOpened: false,
            text: ""
        },
        editingActivated: false,
        editingID: "",
        sourceList: [],
    })

    useEffect(() => {

        let sourceList = sourcesList
        if (!resetEditID("/Clients/addClient")) {
            //Get Client with ID
            axios.get(`${ATLAS_URI}/getClientByID/${EditDetailsData.id}`, configToken)
                .then(response => {
                    const responseData = response.data;
                
                    if (typeof responseData !== 'undefined' && responseData !== null) {
                        if (typeof responseData.DOB !== 'undefined' && responseData.DOB !== null) { responseData.DOB = getInputDate(responseData.DOB); }
                        if (typeof responseData.Anniversary !== 'undefined' && responseData.Anniversary !== null) { responseData.Anniversary = getInputDate(responseData.Anniversary); }
                        setState({ ...state, newTableRow: responseData, editingActivated: true, sourceList: sourceList })

                    }

                }).catch(err => console.log(err))
        } else {
            EditDetailsData = {}
            setState({ ...state, newTableRow: state.resetNewRow, sourceList: sourceList })
        }
    }, []);



    const validateThenAddClient = (e) => {
        if (state.newTableRow.Source === "Select") {
            e.preventDefault();
            const newDialogInfo = { isOpened: true, text: "Source Empty", type: "Error" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
        } else {
            addNewClient(e);
        }
    }

    function addNewClient(e) {
        e.preventDefault();
        const formData = new FormData();
        state.newTableRow.ProfileImageSelected && formData.append('ProfileImageSelected', state.newTableRow.ProfileImageSelected[0]);
        formData.append('Name', state.newTableRow.Name);
        formData.append('Phone', state.newTableRow.Phone);
        formData.append('Email', state.newTableRow.Email);
        formData.append('PAN', state.newTableRow.PAN);
        formData.append('Address', state.newTableRow.Address);
        formData.append('City', state.newTableRow.City);
        formData.append('State', state.newTableRow.State);
        formData.append('PINCode', state.newTableRow.PINCode);
        formData.append('Occupation', state.newTableRow.Occupation);
        formData.append('Organization', state.newTableRow.Organization);
        formData.append('Designation', state.newTableRow.Designation);
        formData.append('Source', state.newTableRow.Source);
        formData.append('Type', state.newTableRow.Type);

        let AnniversaryDate = state.newTableRow.Anniversary
        let DateOB = state.newTableRow.DOB
        if (typeof DateOB !== 'undefined' && DateOB !== null) {
            formData.append('DOB', getNormalDate(DateOB));

        }
        if (typeof AnniversaryDate !== 'undefined' && AnniversaryDate !== null) {
            formData.append('Anniversary', getNormalDate(AnniversaryDate));

        }
        if (!state.editingActivated) {
            axios.post(`${ATLAS_URI}/addClient`, formData, configToken)
                .then(response => {
                    if (response.status === 200) {

                        setState({ ...state, dialogInfo: { isOpened: true, text: "Client Added Successfully", type: "Success" }, newTableRow: state.resetNewRow })
                        setTimeout(() => {
                            setState({ ...state, dialogInfo: { isOpened: false, text: "", type: "" } })
                        }, 3000)
                    }
                })
                .catch(err => alert(err))

        } else {
            axios.post(`${ATLAS_URI}/updateClient/` + EditDetailsData.id, formData, configToken)
                .then(() => {

                    redirectFromEditDetails(EditDetailsData.redirectFrom)
                })
                .catch(err => alert(err))

        }

    }

    function changeHandler(e) {
        oldChangeHandler(e, state, setState);
    }

    return (
        <section className="content">
            <div className="row">
                {state.dialogInfo && <Dialog
                    onClose={(e) => setState(...state, { dialogInfo: { isOpened: false, text: "", type: "" } })}
                    dialogInfo={state.dialogInfo}
                />}
                <div className="col-md-12">
                    <div className="box box-primary">
                        <BoxHeader title={`${state.editingActivated ? "Edit" : "Add"} Client`} />
                        <form onSubmit={validateThenAddClient}>
                            <div className="box-body bozero mx5p">
                                <input type="hidden" name="ci_csrf_token" value="" />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client Name</label> <small className="req"> *</small>
                                            <input name="Name" type="text" className="form-control" required value={state.newTableRow.Name} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client Phone</label> <small className="req"> *</small>
                                            <input name="Phone" type="text" className="form-control" required value={state.newTableRow.Phone} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client Email</label> <small className="req"> *</small>
                                            <input name="Email" type="email" className="form-control" required value={state.newTableRow.Email} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>Type</label> <small className="req"> *</small>
                                            <select required className="form-control" name="Type" value={state.newTableRow.Type} onChange={changeHandler} >
                                                <option value="">Select</option>
                                                <option value="Seller">Seller</option>
                                                <option value="Buyer">Buyer</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>PAN</label>
                                            <input name="PAN" type="text" className="form-control" value={state.newTableRow.PAN} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>DOB</label>
                                            <input name="DOB" type="date" className="form-control" value={state.newTableRow.DOB} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <textarea name="Address" type="text" className="form-control" value={state.newTableRow.Address} onChange={changeHandler} rows="3"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input name="City" type="text" className="form-control" value={state.newTableRow.City} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>State</label>
                                            <input name="State" type="text" className="form-control" value={state.newTableRow.State} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>PIN Code</label>
                                            <input name="PINCode" type="text" className="form-control" value={state.newTableRow.PINCode} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Occupation</label>
                                            <select className="form-control" name="Occupation" value={state.newTableRow.Occupation} onChange={changeHandler} >
                                                <option value="">Select</option>
                                                {typeof occupationList !== 'undefined' && occupationList.map(classData => <option key={classData} value={classData}>{classData}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Organization</label>
                                            <input name="Organization" type="text" className="form-control" value={state.newTableRow.Organization} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Designation</label>
                                            <input name="Designation" type="text" className="form-control" value={state.newTableRow.Designation} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Anniversary</label>
                                            <input name="Anniversary" type="date" className="form-control" value={state.newTableRow.Anniversary} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">

                                        <SelectBox
                                            label="Source"
                                            name="Source"
                                            options={sourcesList}
                                            attributeShown="Source"
                                            changeHandler={changeHandler}
                                            value={state.newTableRow.Source}
                                            resetValue={() => setState(({ ...state, newTableRow: { ...state.newTableRow, Source: "Select" } }))}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>ProfileImage</label><small className="req"> *</small>
                                            <input name="ProfileImageSelected" type="file" accept=".png, .jpg, .jpeg" className='form-control' onChange={changeHandler} />

                                            {state.newTableRow.ProfileImage && state.newTableRow.ProfileImage !== "" &&
                                                <img alt="database images" style={{ margin: "5px 2px", objectFit: "cover" }} src={`${ATLAS_URI}/file/${state.newTableRow.ProfileImage}`} width={60} height={60}></img>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )



}

export default AddClient