import { useEffect, useState } from "react";
import "./History.css";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useLocation } from "react-router-dom";

import { axiosFetch } from "../../../Utlis/AxiosFetch.jsx";
import { dateConverter } from "../../../hooks/helperFunction/index.js";

const History = () => {
  const location = useLocation();
  const leadId = location.pathname.split("/").pop();
  const utcFormater = dateConverter.utcFormater;
  const [history, setHistory] = useState([]);
  const [isHistory, setIsHistory] = useState(false);
  const [open, setOpen] = useState(false);

  const getHistory = async () => {
    const request = await axiosFetch({
      url: `/getHistoryByLeadId?leadId=${leadId}`,
      method: "get",
    });
    setIsHistory(request.data === "No history found");
    setHistory(request.data);
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div className="history-container">
      <h3> History</h3>

      <div className={`history-header`}>
        <div
          className="history-title-container"
          onClick={() => setOpen((pre) => !pre)}
        >
          {isHistory ? (
            <h5> No history found</h5>
          ) : (
            <>
              <h5>{history?.status === "premium" ? "Premium" : "Free"} User</h5>
              <span className={`expand-icon ${open ? "open" : ""}`}>▼</span>
            </>
          )}
        </div>

        {open && (
          <div className={`history-details`}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {!isHistory &&
                history.history.map((history, index) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {
                        <div className="history-item">
                          <span className="item-title">{history.title}</span>
                          <span>
                            <span className="item-title-two">
                              {history.name} &nbsp;
                            </span>
                            <span>
                              {utcFormater(history.dateTime)[1][0]}-
                              {utcFormater(history.dateTime)[1][1]}
                            </span>
                          </span>
                        </div>
                      }
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          </div>
        )}
      </div>
    </div>
  );
};

export default History;
