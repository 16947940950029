import {
  Typography,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LiveSesionModal from "../../Reports/modals/LiveSesionModal";
const useStyles = makeStyles({
  table: {
    width: "100%",
    border: "1px solid #D0D0D0",
  },
});

const LeadProgress = () => {
  let params = useParams();
  const classes = useStyles();
  let tmrwDate = new Date();
  tmrwDate.setDate(tmrwDate.getDate() + 1);

  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(tmrwDate));
  const [activityTracker, setActivityTracker] = useState([]);

  const [isOpenLiveSessionModal, setIsOpenLiveSesstionModal] = useState(false);
  const [liveSessionDetails, setLiveSessionDetails] = useState([]);

  let [activelyStarted, setActivelyStarted] = useState(null);
  let [currentProgress, setCurrentProgress] = useState(null);
  let [difference, setDifference] = useState(null);
  let [calWeight, setCalWeight] = useState("");
  let [target, setTarget] = useState(null);
  const formattedDate = (date) => {
    return new Date(date)
      ?.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-");
  };

  const onCloseLiveModal = () => {
    setIsOpenLiveSesstionModal(false);
  };

  const handleLiveSession = (session) => {
    console.log(session, "check");
    setLiveSessionDetails(session.completedSession);
    setIsOpenLiveSesstionModal(true);
  };

  const getActivityTracker = () => {
    let parameters = {
      leadId: params.id,

      fromDate: !!startDate ? formattedDate(startDate) : null,

      toDate: !!endDate ? formattedDate(endDate) : null,

      timezone: encodeURIComponent(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      ),
    };

    const queryString = Object.entries(parameters)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    axiosFetch({
      url: `/getActivityTrackerByActivityDateAndLeadId/?${queryString}`,
      method: "get",
    })
      .then((res) => {
        if (res.status == 200) {
          let response = res.data.activity;
          setActivityTracker(response.activity_tracker);
          setCurrentProgress(response.current);
          setActivelyStarted(response.started);
          setDifference(response.started);
          setTarget(response.target);
          setCalWeight(response.difference);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getActivityTracker();
  }, [startDate, endDate]);

  return (
    <div>
      <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
        Activities
      </Typography>
      <br />
      <div className="d-flex justify-content-between me-4">
        <div>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            Actively Started
          </Typography>
          <table className="table table-bordered" style={{ width: "250px" }}>
            <tbody>
              <tr className="table table-primary">
                <th>Weight </th>
                <td>
                  {activelyStarted?.weight !== null
                    ? activelyStarted?.weight
                    : "-"}
                </td>
              </tr>
              <tr className="table table-info">
                <th>BMI </th>
                <td>
                  {activelyStarted?.bmi !== null ? activelyStarted?.bmi : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            Current Progress
          </Typography>
          <table className="table table-bordered" style={{ width: "250px" }}>
            <tbody>
              <tr className="table table-primary">
                <th>Weight </th>
                <td>
                  {currentProgress?.weight !== null
                    ? currentProgress?.weight
                    : "-"}
                </td>
              </tr>
              <tr className="table table-info">
                <th>BMI </th>
                <td>
                  {currentProgress?.bmi !== null ? currentProgress?.bmi : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            Difference
          </Typography>
          <table className="table table-bordered" style={{ width: "250px" }}>
            <tbody>
              <tr className="table table-primary">
                <th>Weight </th>
                <td>{calWeight ? calWeight : "-"}</td>
              </tr>
              {/* <tr className="table table-info">
                <th>BMI </th>
                <td>{difference?.bmi ? difference?.bmi : "-"}</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            Target
          </Typography>

          <table className="table table-bordered" style={{ width: "250px" }}>
            <tbody>
              <tr className="table table-primary">
                <th>Weight </th>
                <td>{target?.weight !== null ? target?.weight : "-"}</td>
              </tr>
              <tr className="table table-info">
                <th>BMI </th>
                <td>{target?.bmi !== null ? target?.bmi : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            Daily Target
          </Typography>

          <table className="table table-bordered" style={{ width: "250px" }}>
            <tbody>
              <tr className="table table-primary">
                <th>Consumed Cal </th>
                <td>{target?.calorie !== null ? target?.calorie : "-"}</td>
              </tr>
              <tr className="table table-info">
                <th>Burnt Cal </th>
                <td>
                  {target?.calorie_burn !== null ? target?.calorie_burn : "-"}
                </td>
              </tr>
              <tr className="table table-info">
                <th>Steps </th>
                <td>{target?.steps !== null ? target?.steps : "-"}</td>
              </tr>
              <tr className="table table-info">
                <th>Water Intake </th>
                <td>
                  {target?.water_intake !== null ? target?.water_intake : "-"}
                </td>
              </tr>
              <tr className="table table-info">
                <th>Sleep </th>
                <td>{target?.sleep !== null ? target?.sleep : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br /> <br />
      <Grid container xs={12}>
        <Grid item xs={4}>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            Start Date
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
            End Date
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              sx={{
                width: "370px",
                height: "46px",

                borderRadius: "6px",
                border: "2px",
              }}
              slotProps={{
                textField: {
                  sx: { color: "red" },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              minDate={dayjs(new Date())}
              sx={{
                width: "370px",
                height: "46px",
                borderRadius: "6px",
                border: "2px",
              }}
              slotProps={{
                textField: {
                  sx: { color: "red" },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper} style={{ width: "90%" }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#F4F4F4 !important" }}>
            <TableRow>
              <TableCell align="left">
                <b>Date</b>
              </TableCell>
              <TableCell align="left">
                <b>Consumed Calories</b>
              </TableCell>
              <TableCell align="left">
                <b>Calories Burnt</b>
              </TableCell>
              <TableCell align="left">
                <b>Live</b>
              </TableCell>
              <TableCell>
                <b>Steps</b>
              </TableCell>
              <TableCell align="left">
                <b>Water Intake</b>
              </TableCell>
              <TableCell align="left">
                <b>Sleep(in hour)</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log(activityTracker, "tracker")}

            {activityTracker.length !== 0 ? (
              activityTracker.map((tracker) => (
                <TableRow sx={{ color: "#6B6B6B" }} key={tracker.id}>
                  <TableCell align="left">
                    {tracker.activityDate !== null ? tracker.activityDate : "-"}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {tracker?.caloriesConsumed !== null &&
                    tracker?.caloriesConsumed !== undefined
                      ? tracker?.caloriesConsumed?.quantity !== null &&
                        tracker?.caloriesConsumed?.quantity
                      : "-"}
                  </TableCell>

                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {tracker?.caloriesBurned !== null &&
                    tracker?.caloriesBurned !== undefined
                      ? tracker?.caloriesBurned !== null &&
                        Number(tracker?.caloriesBurned)?.toFixed(2)
                      : "-"}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {tracker?.caloriesBurned !== null
                      ? tracker?.isAttendSession !== null && (
                          <span
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#FF00001A",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              handleLiveSession(tracker);
                            }}
                          >
                            {tracker?.isAttendSession}
                          </span>
                        )
                      : "-"}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {tracker?.Steps !== null && tracker?.Steps !== undefined
                      ? tracker?.Steps?.quantity !== null &&
                        tracker?.Steps?.quantity
                      : "-"}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ color: "#6B6B6B" }}
                  >
                    {tracker?.Water !== null && tracker?.Water !== undefined
                      ? tracker?.Water?.quantity !== null &&
                        tracker?.Water?.quantity
                      : "-"}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {tracker?.Sleep !== null && tracker?.Sleep !== undefined
                      ? tracker?.Sleep?.duration !== null &&
                        (+tracker?.Sleep?.duration / 3600).toFixed(2)
                      : "-"}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>No Results Found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <LiveSesionModal
        isOpen={isOpenLiveSessionModal}
        liveSessionDetails={liveSessionDetails}
        onCloseLiveModal={onCloseLiveModal}
      />
    </div>
  );
};

export default LeadProgress;
