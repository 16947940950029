import DataTable from "../../components/DataTable/DataTable";
import useSlots from "../../hooks/tableColumns/useSlots";
import { Button, Card, Box } from "@mui/material";
import AddSlot from "./Modals/AddSlot";
import { useState } from "react";
import ActionMenu from "./Modals/ActionMenu";
const Slots = () => {
  const columns = useSlots(handleClick);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const rows = [
    { id: "1", name: "test" },
    { id: "2", name: "test" },
    { id: "3", name: "test" },
    { id: "4", name: "test" },
  ];

  function handleClick(e, params) {
    console.log(params, e.target, "ttt");
    setAnchorEl(e.target);
  }

  const handleClose = () => {
    setAnchorEl(false);
  };

  return (
    <div className="new-client">
      <div className="new-cient-container">
        <Card elevation={0}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#000",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#000",
                },
                marginBottom: "20px",
              }}
              onClick={() => setIsOpen(true)}
            >
              Add Slots
            </Button>
          </Box>

          <DataTable columns={columns} rows={rows} />
          <AddSlot isOpen={isOpen} onClose={setIsOpen} />
          <ActionMenu anchorEl={anchorEl} onClose={handleClose} />
        </Card>
      </div>
    </div>
  );
};

export default Slots;
