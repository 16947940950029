import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "jquery";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "dropify/dist/css/dropify.min.css";
import "dropify/dist/js/dropify.min.js";

import "datatables.net-dt/css/jquery.dataTables.min.css";

import "./css/basic.css";
import "./css/dashboard.css";
import "./css/App.css";
import "./css/Client.css";
import "./css/mainStyle.css";

import "./css/font-awesome.min.css";
import { store } from "./Pages/store";
import { Provider } from "react-redux";

import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "5a96a74d124707d3c0656c714b144c59Tz04OTQwMSxFPTE3NDYwMzMxMzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
