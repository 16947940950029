import * as React from "react";
import { Button, Grid, Switch, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ProgramDetailsForm from "./ProgramDetailsForm";
import FeatureDetailsForm from "./FeatureDetailsForm";
import VendorDetailsForm from "../VendorDetailsForm";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialObject = {
  value: 0,
  isProgramCompleted: {
    createProgram: false,
    createFeature: false,
  },

  programDetails: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "addProgram": {
      return {
        ...state,
        programDetails: action.payload,
      };
    }
    case "changeTab": {
      return {
        ...state,
        value: action.payload,
      };
    }
    case "isProgramCompleted": {
      return {
        ...state,
        isProgramCompleted: action.payload,
      };
    }

    case "programWithOtherValues": {
      return {
        ...state,
        programDetails: action.payload?.program,
        isProgramCompleted: action.payload?.isProgramCompleted,
        value: action.payload?.value,
      };
    }
    default: {
      throw new Error("Unkown Action");
    }
  }
};

const AddProgramNew = () => {
  const history = useHistory();

  const [{ value, isProgramCompleted, programDetails }, dispatch] =
    React.useReducer(reducer, initialObject);

  const handleChange = (event, newValue) => {
    if (isProgramCompleted.createFeature) {
      toast.warning(`Please first complete and save the Feature Details`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (
      programDetails.category === "Webinar" ||
      programDetails.category === "Consultation"
    ) {
      toast.warning(
        `${programDetails.category} program doesn't have access to feature details`,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    if (isProgramCompleted.createProgram) {
      dispatch({ type: "changeTab", payload: newValue });
    } else {
      toast.warning(`Please first complete and save the Program Details`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
  };

  const goBackToPreviousPage = () => {
    history.push("/Programs/programList");
  };

  return (
    <div className="new-client">
      <div className="new-cient-container">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant={"text"}
              sx={{ borderRadius: "50px", minWidth: "14px", color: "#000" }}
              onClick={() => goBackToPreviousPage()}
            >
              <KeyboardBackspaceIcon />
            </Button>
            <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
              {"Add Program"}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "16px" }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  sx={{ "& .MuiTabs-indicator": { backgroundColor: "#000" } }}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <Typography
                        sx={{ textTransform: "capitalize", fontWeight: 600 }}
                      >
                        {"Program Details"}
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                  {/* <Tab
                    label={
                      <Typography
                        sx={{ textTransform: "capitalize", fontWeight: 600 }}
                      >
                        {"Feature Details"}
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <Typography
                        sx={{ textTransform: "capitalize", fontWeight: 600 }}
                      >
                        {"Vendor Details"}
                      </Typography>
                    }
                    {...a11yProps(2)}
                  /> */}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <ProgramDetailsForm
                  setIsProgramCompleted={dispatch}
                  setIsNotCompleted={dispatch}
                  setProgramDetails={dispatch}
                  setValue={dispatch}
                  dispatch={dispatch}
                />
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={1}>
                <FeatureDetailsForm
                  programDetails={programDetails}
                  isProgramCompleted={isProgramCompleted}
                  dispatch={dispatch}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <VendorDetailsForm />
              </CustomTabPanel> */}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default AddProgramNew;
