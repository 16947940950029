import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ClientPlansPopUp(props) {
  const [show, setShow] = useState(props.show);
  const [storeInputValue, setStoreInputValue] = useState('')
  const [items, setitems] = useState('')
  
  let propsid=props.dietGrid
  const handleClose = () => {
    // setitems(storeInputValue)
    // props.setDropDownValue(prevState=>[...prevState,storeInputValue])
    // props.addValueToDropdown(storeInputValue)
   
    

    props.setDietGrid(props.dietGrid?.map(c=>{
      c.newTableRow.addValue=[...c.newTableRow.addValue,storeInputValue]
      return c;
    }))


//   Previous method.....

//     props.setDietGrid(prevState=>{

//       if (props.dietGrid[props.checkids].newTableRow.id == prevState[props.checkids].newTableRow.id){
//         prevState[props.checkids].newTableRow.addValue=[...prevState[props.checkids].newTableRow.addValue, storeInputValue]
//       }
//       return prevState
//     })


    return props.setshow(false)

};


  const handleShow = () => setShow(true);
 
  useEffect(() => {
    props.show && setShow(true)
     }, [props.show])

     
     const addMealName=(e)=>{
     setStoreInputValue(e.target.value)
    //  setStoreInputValue((store)=>{
   
    //     return store;

    //  })
     }
    
    
     


  return (
    <>
      

      <Modal show={show} onHide={handleClose} style={{'width':'600px','transform':'translate(50%)',marginLeft:'550px'}}>
        <Modal.Header closeButton>
          <Modal.Title>Meal Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type='text' value={storeInputValue} style={{'width':'100%'}} onChange={addMealName}/>
            </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>props.setshow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose} style={{backgroundColor:'black',color:'white'}}>
            Save Changes
           
            
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ClientPlansPopUp