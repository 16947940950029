import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const SimpleTable = ({ header, children }) => {
  return (
    <TableContainer
      sx={{
        "& .MuiTableCell-root": {
          border: "1px solid #D0D0D0",
        },
      }}
    >
      <Table aria-label="simple table" sx={{ width: "98%", margin: "0 auto" }}>
        <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
          <TableRow>
            {header?.map((value, index) => (
              <TableCell align="left" key={index}>
                <b>{value}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
