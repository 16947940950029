import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import Dialog from '../../components/Dialog'
import BoxHeader from '../../components/BoxHeader'
import { ATLAS_URI } from '../../Constants'
import stateContext from '../../context/StateContext'
function Configurations() {
    const contextState = useContext(stateContext)
    const { configToken } = contextState.state

    const [state, setState] = useState({
        configurations: {
            BusinessName: "",
            BusinessLogo: "",
            SupportWhatsapp: "",
            BackgroundImage: "",
            SupportEmail: "",

            AutoLeadsAssignment: true,

            ServiceID: "",
            TemplateID: "",
            UserID: "",
        },
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        },
    })

    useEffect(() => {


        //get Configurations
        axios.get(`${ATLAS_URI}/getConfiguration/`, configToken)
            .then(response => {
                var resp = response.data[0]
                resp.AutoLeadsAssignment = response.data[0].AutoLeadsAssignment
                resp.ServiceID = response.data[0].ServiceID
                resp.UserID = response.data[0].UserID
                resp.TemplateID = response.data[0].TemplateID

                setState({
                    ...state,
                    configurations: resp
                });

            }).catch(err => console.log(err))
    }, []);

    function changeHandler(e) {
        let newValue;
        const config = state.configurations;
        if (e.target.type === "file") {
            newValue = Object.values(e.target.files)[0];
            if (e.target.name === 'BusinessLogoSelected') config.BusinessLogo = ""
            else config.BackgroundImage = "";


        } else {
            newValue = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        }
        config[e.target.name] = newValue;
        setState({
            ...state,
            configurations: config
        })
    }

    function updateConfig(e) {
        e.preventDefault();

        const formData = new FormData();

        if (state.configurations.BusinessLogoSelected && state.configurations.BusinessLogoSelected !== "") {
            formData.append('BusinessLogo', 0)
            formData.append('SelectedImages', state.configurations.BusinessLogoSelected);
        }
        if (state.configurations.BackgroundImageSelected && state.configurations.BackgroundImageSelected !== "") {
            formData.append('SelectedImages', state.configurations.BackgroundImageSelected);
            formData.append('BackgroundImage', 1)
        }

        formData.append('BusinessName', state.configurations.BusinessName);

        axios.post(`${ATLAS_URI}/updateConfiguration/1`, formData, configToken)
            .then((res) => {
              
                if (typeof res.data !== 'undefined') {
                    if (res.data.PreviousLogo !== "") axios.delete(`${ATLAS_URI}/file/${res.data.PreviousLogo}`, configToken);
                    if (res.data.PreviousImage !== "") axios.delete(`${ATLAS_URI}/file/${res.data.PreviousImage}`, configToken);
                }
                const newDialogInfo = { isOpened: true, text: "Configs Updated Successfully!!", type: "Success" }
                setState({ ...state, dialogInfo: newDialogInfo })
                setTimeout(() => { setState({ ...state, dialogInfo: { isOpened: false, text: "", type: "" } }) }, 3000)
            }).catch(err => alert(err))
    }

    function updateFlag(e) {
        e.preventDefault();

        axios.post(`${ATLAS_URI}/updateFlag/1`, state.configurations, configToken)
            .then((res) => {
                const newDialogInfo = { isOpened: true, text: "Flag Updated Successfully!!", type: "Success" }
                setState({ ...state, dialogInfo: newDialogInfo })
                setTimeout(() => { setState({ ...state, dialogInfo: { isOpened: false, text: "", type: "" } }) }, 3000)
            }).catch(err => alert(err))

    }

    function updateEmailInfo(e) {
        e.preventDefault();
  
        axios.post(`${ATLAS_URI}/updateEmailInfo/1`, state.configurations, configToken)
            .then((res) => {
                const newDialogInfo = { isOpened: true, text: "Email Info Updated Successfully!!", type: "Success" }
                setState({ ...state, dialogInfo: newDialogInfo })
                setTimeout(() => { setState({ ...state, dialogInfo: { isOpened: false, text: "", type: "" } }) }, 3000)
            }).catch(err => alert(err))
    }

    return (

        <section className="content">
            <div className="row">
                {state.dialogInfo.isOpened && <Dialog
                    onClose={(e) => setState(...state, { dialogInfo: { isOpened: false, text: "", type: "" } })}
                    dialogInfo={state.dialogInfo}
                />}
                <div className="col-md-5">

                    <div className="box box-primary">

                        <BoxHeader title="Add Business Details" />

                        <form onSubmit={updateConfig} autoComplete='off'>

                            <div className="box-body bozero">

                                <input type="hidden" name="ci_csrf_token" value="" />

                                <div className="form-group">
                                    <label>Business Name </label><small className="req"> *</small>
                                    <input name="BusinessName" value={state.configurations.BusinessName} required type="text" className="form-control" onChange={changeHandler} />
                                </div>

                                <div className="form-group">
                                    <label>Business Logo </label><small className="req"> *</small>
                                    <input name="BusinessLogoSelected" id='logo' type="file" accept=".png, .jpg, .jpeg" className='form-control' onChange={changeHandler} />

                                    {state.configurations.BusinessLogo && state.configurations.BusinessLogo !== "" &&
                                        <img alt="database images" style={{ margin: "5px 2px", objectFit: "cover" }} src={`${ATLAS_URI}/file/${state.configurations.BusinessLogo}`} width={60} height={60}></img>
                                    }

                                    {state.configurations.BusinessLogoSelected && state.configurations.BusinessLogoSelected !== "" &&
                                        <img alt="selected images" style={{ margin: "5px 2px", objectFit: "cover" }} src={URL.createObjectURL(state.configurations.BusinessLogoSelected)} width={60} height={60}></img>
                                    }
                                </div>

                                <div className="form-group">
                                    <label>Background Image </label><small className="req"> *</small>
                                    <input name="BackgroundImageSelected" id='backgroundImage' type="file" accept=".png, .jpg, .jpeg" className='form-control' onChange={changeHandler} />

                                    {state.configurations.BackgroundImage && state.configurations.BackgroundImage !== "" &&
                                        <img alt="database images" style={{ margin: "5px 2px", objectFit: "cover" }} src={`${ATLAS_URI}/file/${state.configurations.BackgroundImage}`} width={60} height={60}></img>
                                    }

                                    {state.configurations.BackgroundImageSelected && state.configurations.BackgroundImageSelected !== "" &&
                                        <img alt="selected images" style={{ margin: "5px 2px", objectFit: "cover" }} src={URL.createObjectURL(state.configurations.BackgroundImageSelected)} width={60} height={60}></img>
                                    }
                                </div>

                            </div>
                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right ">Save</button>
                            </div>
                        </form>
                    </div>

                    <div className="box box-primary">

                        <BoxHeader title="Leads Assignment" />

                        <form onSubmit={updateFlag} autoComplete='off'>

                            <div className="box-body bozero row">
                                <input type="hidden" name="ci_csrf_token" value="" />
                                <div className='form-group col-sm-10'>
                                    <label className="form-check-label">Automatic Leads Assignment </label><small className="req"> *</small>
                                </div>
                                <div className="form-group col-sm-2">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input " name='AutoLeadsAssignment' type="checkbox" onChange={changeHandler}
                                            checked={state.configurations.AutoLeadsAssignment} />
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right ">Save</button>
                            </div>

                        </form>
                    </div>

                </div>

                <div className="col-md-7">
                    <div className="box box-primary">

                        <BoxHeader title="Support Info" />

                        <div className="box-body">

                            <div className="form-group">
                                <h5>WhatsApp: {state.configurations.SupportWhatsapp} </h5>
                                <h5 >Mail: {state.configurations.SupportEmail} </h5>
                            </div>

                        </div>
                    </div>

                    <div className="box box-primary">

                        <BoxHeader title="Email API Info" />

                        <form onSubmit={updateEmailInfo} autoComplete='off'>

                            <div className="box-body bozero">

                                <input type="hidden" name="ci_csrf_token" value="" />

                                <div className="form-group">
                                    <label>ServiceID</label><small className="req"> *</small>
                                    <input name="ServiceID" type="text" className='form-control' value={state.configurations.ServiceID} required onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <label>UserID</label><small className="req"> *</small>
                                    <input name="UserID" type="text" className='form-control' value={state.configurations.UserID} required onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <label>TemplateID</label><small className="req"> *</small>
                                    <input name="TemplateID" value={state.configurations.TemplateID} type="text" className='form-control' onChange={changeHandler} />
                                </div>

                            </div>
                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right ">Save</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </section>
    )





}

export default Configurations