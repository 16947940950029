import "../LiveSession.css";

const LiveSessionTab = ({ name, num, onActiveTab, activeTab }) => {
  return (
    <div className="session-tab">
      <button
        type="button"
        className={num === activeTab ? "active" : ""}
        onClick={() => onActiveTab(num)}
      >
        {name}
      </button>
    </div>
  );
};

export default LiveSessionTab;
