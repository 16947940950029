import { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {axiosFetch} from '../../Utlis/AxiosFetch';
import { useParams, useHistory } from "react-router-dom";
import {toast} from 'react-toastify';
const Current = ()=>{
  
  const {id} = useParams();
  const history = useHistory();

  const [currentUserList, setCurrentUserList] = useState([]);
  const [isLoading, setIsLoding] = useState(false);

  const getCurrentUsers = ()=>{
    setIsLoding(true);
    axiosFetch({
      url : `/v3/getClientRetentionProgramByClientId/${id}`,
      method :"get"
    })
    .then((res)=>{
     
      if(res.status === 200){
        setIsLoding(false);
        setCurrentUserList(res.data);
      }
      else if(res.status === 401){
        history.push('/');
      }
      else if(res.status === 400){
        toast.error('Bad Request',{
          position: toast.POSITION.TOP_CENTER,
        })
      }
    })
    .catch(err =>{
      console.error(err);
    })
  }
  
  useEffect(()=>{
    getCurrentUsers();
  },[]);



    return(
        <div>
          
    {isLoading ?(<div className='loader'/>):(<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><strong>Date</strong></TableCell>
            <TableCell><strong>Role</strong></TableCell>
            <TableCell><strong>Coach Name</strong></TableCell>
            <TableCell><strong>Program Name</strong></TableCell>
            <TableCell><strong>Duration</strong></TableCell>
            <TableCell><strong>Remarks</strong></TableCell>
            <TableCell><strong>Previous Program Name</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          isLoading ?(<div className='loader'/>) : currentUserList.length > 0 && typeof(currentUserList) !== 'string' ? currentUserList?.map((users) => ( 
          <TableRow>
            <TableCell>{!! users.createdAt ? users.createdAt : "-"}</TableCell>
            <TableCell>{!!users.role ? users.role : "-"}</TableCell>
            <TableCell></TableCell>
            <TableCell>{!!users.product_details && !!users.product_details.name ? users.product_details.name : "-"}</TableCell>
            <TableCell>{!!users.product_details && !!users.product_details.duration && !!users.product_details.duration_unit ? users.product_details.duration + " " + users.product_details.duration_unit : "-"}</TableCell>
            <TableCell>{!!users.product_details && !!users.product_details.comments ? users.product_details.commnets : "-"}</TableCell>
            <TableCell></TableCell>
          </TableRow> 
          
           
            ))
         :(<p>No Results found</p>)}
          </TableBody>
       
      </Table>
    </TableContainer>)}
        </div>
    )
}
export default Current;