import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Grid,
  Typography,
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Checkbox,
  Autocomplete,
  Paper,
  ListItemText,
  Tabs,
  Tab,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import styles from "./Task.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useEffect, useState, Fragment } from "react";
import { RulesList } from "./RulesList";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const taskRuleInitialValue = {
  taskName: "",
  allocatedTo: "",
  conditionOne: "",
  conditionTwo: "",
  day: "",
  month: "",
  description: "",
};

const initialState = {
  ruleName: "",
  description: "",
  taskList: {
    fitness: [
      {
        ...taskRuleInitialValue,
        allocatedTo: "Fitness",
      },
    ],
    dietician: [
      {
        ...taskRuleInitialValue,
        allocatedTo: "Dietician",
      },
    ],
  },
  programList: [],
};

const AddTask = () => {
  const history = useHistory();
  const { state } = useLocation();
  const editTaskRow = state && state.taskDetails;
  const isEdit = state && state.isEdit;
  const isClone = state && state.isClone;
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   trigger("taskList.dietician");
  //   setTabValue(newValue);
  // };

  const handleChange = async (event, newValue) => {
    const isValidDieticain = await trigger("taskList.dietician");
    const isValidFitness = await trigger("taskList.fitness");

    if (newValue === 1 && isValidFitness) {
      setTabValue(newValue);
    } else if (newValue === 0 && isValidDieticain) {
      setTabValue(newValue);
    }
  };

  const [dropDownlists, setDropDownLists] = useState({
    taskName: [],
    allocatedTo: [],
    conditionOne: [],
    conditionTwo: [],
    programDetails: [],
  });

  const form = useForm({
    defaultValues: isEdit || isClone ? editTaskRow : initialState,
  });

  const { handleSubmit, control, reset, setValue, trigger } = form;

  const {
    fields: fitnessFields,
    append: appendFitness,
    remove: removeFitness,
  } = useFieldArray({
    name: "taskList.fitness",
    control,
  });

  const {
    fields: dieticianFields,
    append: appendDietician,
    remove: removeDietician,
  } = useFieldArray({
    name: "taskList.dietician",
    control,
  });

  const handleRemoveFitness = (value) => {
    removeFitness(value);
  };

  const handleRemoveDietician = (value) => {
    removeDietician(value);
  };

  const handleConditionTwoChange = async (
    selectedValue,
    index,
    formData,
    allocatedTo
  ) => {
    try {
      const previousRowIndex = index - 1;
      let url = `/getTaskDayAndMonth?condition=${selectedValue}&previousDay=0&previousMonth=0`;

      if (previousRowIndex >= 0) {
        const previousRowData =
          formData.taskList[allocatedTo][previousRowIndex];

        url = `/getTaskDayAndMonth?condition=${selectedValue}&previousDay=${previousRowData.day}&previousMonth=${previousRowData.month}`;
      }

      const response = await axiosFetch({
        method: "get",
        url: url,
      });

      if (response.status === 200) {
        const data = response.data.body;
        setValue(`taskList.${allocatedTo}.${index}.day`, data?.day);
        setValue(`taskList.${allocatedTo}.${index}.month`, data?.month);
      }
    } catch (error) {}
  };

  const onSubmit = async (formData) => {
    // const updatedFormData = formData?.taskList.map((item) => {
    //   return { ...item, programId: id };
    // });

    let url = "/createProgramTask";
    let method = "post";

    if (isEdit) {
      url = `/updateProgramTask`;
      method = "put";
    }

    try {
      const response = await axiosFetch({
        method: method,
        url: url,
        requestConfig: formData,
      });

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        history.push("/Programs/taskAutomation");
      }
    } catch {}
  };

  const getTaskDropdownDetails = async () => {
    try {
      const response = await axiosFetch({
        method: "get",
        url: `/getProgramTaskConfigurations`,
      });

      const programList = await axiosFetch({
        method: "get",
        url: `/getProgramListDropdown`,
      });

      if (response.status === 200 && programList.status === 200) {
        const data = response.data.body;
        const list = programList.data.body;

        setDropDownLists(() => {
          return { ...data, programDetails: list };
        });
        setIsLoading(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTaskDropdownDetails();
  }, []);

  const goBackToPreviousPage = () => {
    history.push("/Programs/taskAutomation");
  };

  // useEffect(() => {

  //     setValue("programName", product_details.name);

  // }, [tabValue, setValue]);

  return (
    <div className="new-client">
      <div className={`new-cient-container ${styles["add_task_container"]} `}>
        <Box
          sx={{
            marginTop: "10px",
          }}
        >
          <Button
            variant={"text"}
            sx={{ borderRadius: "50px", minWidth: "14px", color: "#000" }}
            onClick={() => goBackToPreviousPage()}
          >
            <KeyboardBackspaceIcon />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                textTransform: "capitalize",
                marginLeft: "5px",
              }}
            >
              Add Rule
            </Typography>
          </Button>
        </Box>

        <Box sx={{ marginTop: "50px", padding: "0px 20px" }}>
          {isLoading && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3} sx={{ marginBottom: "40px" }}>
                <Grid item md={4}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: "10px",
                    }}
                  >
                    Rule Name
                  </Typography>
                  <Controller
                    name={`ruleName`}
                    rules={{ required: "required" }}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={!!error}
                        />
                        <FormHelperText error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </Grid>
                <Grid item md={8}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: "10px",
                    }}
                  >
                    Rule Description
                  </Typography>
                  <Controller
                    name={`description`}
                    rules={{ required: "required" }}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={!!error}
                        />
                        <FormHelperText error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </Grid>

                <Grid item md={12}>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        sx={{
                          "& .MuiTabs-indicator": { backgroundColor: "#000" },
                          "& .MuiTab-root": {
                            "&.Mui-selected": {
                              color: "#000",
                            },
                          },
                        }}
                      >
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: 600,
                            color: "#000",
                          }}
                          label="Fitness"
                          {...a11yProps(0)}
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: 600,
                            color: "#000",
                          }}
                          label="Dietician"
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#2a2a2a",
                              textTransform: "capitalize",
                              "&:hover": {
                                backgroundColor: "#000",
                              },
                            }}
                            onClick={() =>
                              appendFitness({
                                ...taskRuleInitialValue,
                                allocatedTo: "Fitness",
                              })
                            }
                          >
                            Add Task
                          </Button>
                        </Grid>

                        {fitnessFields.map((field, index) => (
                          <RulesList
                            key={field.id}
                            index={index}
                            Controller={Controller}
                            control={control}
                            dropDownlists={dropDownlists}
                            handleConditionTwoChange={handleConditionTwoChange}
                            form={form}
                            handleRemoveTask={handleRemoveFitness}
                            allocatedTo={"fitness"}
                          />
                        ))}
                      </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#2a2a2a",
                              textTransform: "capitalize",
                              "&:hover": {
                                backgroundColor: "#000",
                              },
                            }}
                            onClick={() =>
                              appendDietician({
                                ...taskRuleInitialValue,
                                allocatedTo: "Dietician",
                              })
                            }
                          >
                            Add Task
                          </Button>
                        </Grid>

                        {dieticianFields.map((field, index) => (
                          <RulesList
                            key={field.id}
                            index={index}
                            Controller={Controller}
                            control={control}
                            dropDownlists={dropDownlists}
                            handleConditionTwoChange={handleConditionTwoChange}
                            form={form}
                            handleRemoveTask={handleRemoveDietician}
                            allocatedTo={"dietician"}
                          />
                        ))}
                      </Grid>
                    </CustomTabPanel>
                  </Box>
                </Grid>

                <Grid item md={12} sx={{ marginTop: "20px" }}>
                  <Typography sx={{ color: "red" }}>
                    - The 'Review call' should be selected last in the Rule List
                    and only once
                  </Typography>
                  <Typography sx={{ color: "red" }}>
                    - Review calls will be automatically generated with
                    alternating coaches and dates selected until the tenure end
                    date
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginBottom: "10px",
                    }}
                  >
                    Programs
                  </Typography>

                  <Controller
                    control={control}
                    name={"programList"}
                    rules={{ required: "required" }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl size="small" fullWidth>
                        <Autocomplete
                          multiple
                          options={dropDownlists.programDetails}
                          size="small"
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            field.onChange(value);
                          }}
                          value={field.value}
                          freeSolo
                          disableCloseOnSelect
                          // getOptionSelected={(option, selectedValue) =>
                          //   option.id === selectedValue.id
                          // }
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              onClick={(event) => {
                                const newValue = field.value.some(
                                  (item) => item.id === option.id
                                )
                                  ? field.value.filter(
                                      (item) => item.id !== option.id
                                    )
                                  : [...field.value, option];
                                field.onChange(newValue);
                              }}
                            >
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={field.value.some(
                                  (item) => item.id === option.id
                                )}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} error={!!error} />
                          )}
                          PaperComponent={(props) => (
                            <Paper
                              {...props}
                              sx={{
                                marginTop: "-300px",
                              }}
                            />
                          )}
                        />

                        <FormHelperText error={true}>
                          {error?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#2a2a2a",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                }}
              >
                Save
              </Button>
            </form>
          )}
        </Box>
      </div>
    </div>
  );
};

export default AddTask;
