import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import { ATLAS_URI } from "../../../Constants";
import { useHistory } from "react-router-dom";

const ViewTemplate = ({ showTemplate, closeWorkoutPlanModal, data }) => {
  let selectedTemplate = [],
    workoutInfo;

  const [isHovering, setIsHovering] = useState(false);
  let history = useHistory();

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const tableStyle = {
    border: "solid #000",
  };

  return (
    <Modal show={showTemplate} onHide={() => closeWorkoutPlanModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Workout Template Details</Modal.Title>
        <GrClose
          style={{
            backgroundColor: isHovering ? "#FF0000" : "",
            color: isHovering ? "#fff" : "",
            border: isHovering ? "1px solid" : "",
            height: isHovering ? "30px" : "",
            width: isHovering ? "30px" : "",
            opacity: isHovering ? "0.7" : "",
          }}
          onMouseOver={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => closeWorkoutPlanModal()}
        />
      </Modal.Header>
      <Modal.Body>
        <div>
          <table className="table table-bordered" style={tableStyle}>
            <thead>
              <tr bgcolor="#4B4B4B" style={{ ...tableStyle, color: "#fff" }}>
                <td className="text-center">
                  <strong>#NAME</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>L1</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 1</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 2</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 3</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 4</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 5</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 6</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 7</strong>
                </td>
              </tr>
            </thead>
            <tbody className="bg__transparent">
              {data?.map((data, index1) => {
                return (
                  <>
                    <td
                      className="text-center"
                      style={{ transform: "translateY(50%)" }}
                      rowSpan={data.week.length * 2 + 1}
                    >
                      <strong>Month {index1 + 1}</strong>
                    </td>
                    {data.week.map((weekData, index2) => {
                      return (
                        <>
                          <tr bgcolor="#FAF8F1">
                            <td style={tableStyle} className="text-center">
                              <strong>Week {index2 + 1}</strong>
                            </td>
                            {weekData.day.map((dayData) => {
                              return (
                                /*  changes did */
                                <td style={tableStyle} className="text-center">
                                  <strong>
                                    {dayData?.details
                                      ? dayData.details
                                          .map((d) => d.title)
                                          .toString()
                                      : "-"}
                                  </strong>
                                </td>
                              );
                            })}
                          </tr>

                          <tr bgcolor="#FAF8F1">
                            <td style={tableStyle} className="text-center">
                              <strong>Workout</strong>
                            </td>
                            {weekData.day.map((dayData) => {
                              return (
                                <>
                                  <td
                                    style={tableStyle}
                                    className="text-center"
                                  >
                                    {dayData?.details
                                      ? dayData?.details.map((d) => {
                                          return (
                                            <a href={d.link} target="_blank">
                                              {d.name}
                                            </a>
                                          );
                                        })
                                      : "-"}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewTemplate;
