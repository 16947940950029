import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import DragDots from "../../../../assets/DragDots.svg";
import "./AddExercise.css";

function AddWorkout({ item, workout, exerciseName, isSelected, toggleSelect }) {
  const timeRef = useRef();

  return (
    <Row className="mt-2 exercise-row">
      <Col
        className="d-flex flex-row justify-content-start align-items-center"
        xs={1}
      >
        <div>
          <label style={{ visibility: "hidden", width: "1px", height: "10px" }}>
            Checkbox
          </label>
          <input
            className="repeat"
            type="checkbox"
            checked={isSelected}
            onChange={toggleSelect}
          />
        </div>
        <div>
          <label style={{ visibility: "hidden" }}>Checkbox</label>
          <img src={DragDots} alt="Dots" width={8} height={18} />
        </div>
      </Col>
      <Col className="exercise-cols" md={2}>
        <label>Type of workout</label>
        <select
          id="paymentSelect"
          onChange={(e) => (item.type = e.target.value)}
        >
          <option value="week">Select</option>
          {workout.length > 0 &&
            workout.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
        </select>
      </Col>
      <Col className="exercise-cols" md={3}>
        <label>Exercise Name</label>
        <input
          type="text"
          placeholder="Enter Exercise"
          onChange={(e) => {
            item.name = e.target.value;
          }}
        />
      </Col>
      <Col className="exercise-cols" xs={2}>
        <label>No of Reps</label>
        <input
          type="number"
          className=""
          placeholder="No of Reps"
          name="noOfReps"
          min={0}
          // value={category.noOfReps}
          onChange={(e) => (item.noOfReps = parseInt(e.target.value))}
        />
      </Col>
      <Col className="exercise-cols" xs={2}>
        <label>No of Sets</label>
        <input
          type="number"
          className=""
          name="noOfSets"
          min={0}
          placeholder="No of sets"
          onChange={(e) => (item.noOfSets = parseInt(e.target.value))}
        />
      </Col>
      <Col className="exercise-cols" xs={2}>
        <label>Duration</label>
        <input
          className="exercise-duration"
          step="1"
          placeholder="Select Time"
          ref={timeRef}
          onFocus={() => (timeRef.current.type = "time")}
          onBlur={() => (timeRef.current.type = "text")}
          onChange={(e) => {
            const value = e.target.value;
            // Assuming the input format is "hh:mm:ss"
            const [hours, minutes, seconds] = value.split(":");
            const formattedDuration = `${hours}:${minutes}:${seconds}`;

            item.duration = formattedDuration;
          }}
        />
      </Col>
    </Row>
  );
}

export default AddWorkout;
