import { Box, Paper, InputBase, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory } from "react-router-dom";
import AutomationTable from "./AutomationTable";
import { useEffect, useRef, useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import SelectBox from "../../../components/TextFields/SelectBox";
import { useForm } from "react-hook-form";

const ClientAutomation = () => {
  const history = useHistory();
  const [clientlist, setClientList] = useState([]);
  const isMountedRef = useRef(true);

  const form = useForm({
    defaultValues: {
      clientType: "trainer",
    },
  });

  const { control, watch } = form;
  const currentClientType = watch("clientType");

  const handleAddRule = () => {
    history.push("/Settings/automation/addClientRule");
  };

  const handleThreshold = () => {
    history.push("/Settings/automation/threshold");
  };

  const handleSearch = async (value) => {
    try {
      const request = await axiosFetch({
        url: `/api/v1/clientAutomation/searchFilter/?ruleName=${value}&clientType=${currentClientType}`,
        method: "get",
      });
      setClientList(request.data?.allAutomationList);
    } catch (err) {
      console.log(err);
    }
  };

  const getClientList = async (value) => {
    try {
      const reqeust = await axiosFetch({
        url: `api/v1/clientAutomation/${value}`,
        method: "get",
      });

      if (reqeust.status === 200) {
        setClientList(reqeust.data?.allAutomationList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClientList(currentClientType);
    return () => {
      isMountedRef.current = false;
    };
  }, [currentClientType]);

  return (
    <Box sx={{ mt: "30px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
          mb: "40px",
        }}
      >
        <Box sx={{ width: "160px" }}>
          <SelectBox
            name={`clientType`}
            control={control}
            menuList={[
              { id: "trainer", value: "Tariner" },
              { id: "dietitian", value: "Dietitian" },
            ]}
            isRequired
          />
        </Box>

        <Box sx={{ display: "flex", gap: "20px" }}>
          <Paper
            component="form"
            sx={{
              p: "0px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2a2a2a",
              textTransform: "capitalize",
              py: "8px",
              "&:hover": { backgroundColor: "#2a2a2a" },
            }}
            onClick={handleAddRule}
          >
            Add Rule
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2a2a2a",
              textTransform: "capitalize",
              py: "8px",
              "&:hover": { backgroundColor: "#2a2a2a" },
            }}
            startIcon={<SettingsIcon />}
            onClick={handleThreshold}
          >
            Threshold
          </Button>
        </Box>
      </Box>
      {clientlist.length > 0 &&
        clientlist.map((item) => (
          <AutomationTable
            clientlist={item}
            key={item.sequenceNo}
            getClientList={getClientList}
          />
        ))}
    </Box>
  );
};

export default ClientAutomation;
