import * as React from "react";
import { Button, Grid, Typography, Paper, TextField } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const top100Films = [
	{ label: 'The Shawshank Redemption', year: 1994 },
	{ label: 'The Godfather', year: 1972 },
	{ label: 'The Godfather: Part II', year: 1974 },
	{ label: 'The Dark Knight', year: 2008 },
	{ label: '12 Angry Men', year: 1957 },
	{ label: "Schindler's List", year: 1993 },
	{ label: 'Pulp Fiction', year: 1994 } ];


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }


  const Android12Switch = styled(Switch)(({ theme }) => ({
	padding: 8,
	"& .Mui-checked+.MuiSwitch-track": {
		backgroundColor: "#000 !important",
		opacity: "1 !important"
	},
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: "#fff",
	},
	'& .MuiSwitch-track': {
	  borderRadius: 22 / 2,
	  '&::before, &::after': {
		content: '""',
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		width: 16,
		height: 16,
	  },
	//   '&::before': {
	// 	background: `green`,
	// 	left: 12,
	//   },
	//   '&::after': {
	// 	background: `red`,
	// 	right: 12,
	//   },
	},
	'& .MuiSwitch-thumb': {
	  boxShadow: 'none',
	  width: 16,
	  height: 16,
	  margin: 2,
	},
  }));

const VendorDetailsForm = () => {

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return(
		<Grid container spacing={3} style={{padding: "0px 10px"}}>
			<Grid item xs={12} sx={{paddingLeft: "0px !important"}}>
				<Typography sx={{fontSize: "18px", fontWeight: 500}} >{"Vendor Details"}</Typography>
			</Grid>
			<Grid item xs={3} sx={{paddingLeft: "0px !important"}} >
				<Typography sx={{fontSize: "14px", marginBottom: "4px"}} >{"Vendor Number"}</Typography>
				<TextField placeholder={"Enter here"} size="small" fullWidth variant="outlined" />
			</Grid>
			<Grid item xs={3}>
				<Typography sx={{fontSize: "14px", marginBottom: "4px"}} >{"Vendor ID"}</Typography>
				<TextField placeholder={"Enter here"} size="small" fullWidth variant="outlined" />
			</Grid>
			<Grid item xs={3}>
				<Typography sx={{fontSize: "14px", marginBottom: "4px"}} >{"Vendor Name"}</Typography>
				<Autocomplete
					size="small"
					disablePortal
					id="combo-box-demo"
					options={top100Films}
					// sx={{ width: 300 }}
					renderInput={(params) => <TextField size="small" fullWidth {...params} placeholder="Select here" />}
				/>
			</Grid>

			{/* Shipment Details */}
			<Grid item xs={12} container style={{padding: "40px 0px 0px 0px"}}>
				<Typography sx={{fontSize: "18px", fontWeight: 500}} >{"Shipment Details"}</Typography>
			</Grid>
			<Grid item xs={12} style={{padding: "0px 0px 16px 0px"}}>	
				<div>
					<FormGroup sx={{ flexDirection: "row"}}>
						<FormControlLabel control={<Checkbox defaultChecked sx={{'&.Mui-checked': { color: "#000" }}} />} label="Program Items Ordered" />
						<FormControlLabel control={<Checkbox sx={{'&.Mui-checked': { color: "#000" }}}/>} label="Program Service" />
						<FormControlLabel control={<Checkbox sx={{'&.Mui-checked': { color: "#000" }}} />} label="Shipping" />
					</FormGroup> 
				</div>
			</Grid>

			{/* Tax Details */}
			<Grid item xs={12} container style={{padding: "40px 0px 0px 0px"}}>
				<Typography sx={{fontSize: "18px", fontWeight: 500}} >{"Tax Details"}</Typography>
			</Grid>
			<Grid item xs={3} sx={{paddingLeft: "0px !important"}} >
				<Typography sx={{fontSize: "14px", marginBottom: "4px"}} >{"Tax Address"}</Typography>
				<TextField placeholder={"Enter here"} size="small" fullWidth variant="outlined" />
			</Grid>
			<Grid item xs={3}>
				<Typography sx={{fontSize: "14px", marginBottom: "4px"}} >{"Tax Status"}</Typography>
				<TextField placeholder={"Enter here"} size="small" fullWidth variant="outlined" />
			</Grid>
			<Grid item xs={3}>
				<Typography sx={{fontSize: "14px", marginBottom: "4px"}} >{"Tax Status Description"}</Typography>
				<TextField placeholder={"Enter here"} size="small" fullWidth variant="outlined" />
			</Grid>
			<Grid item xs={3} sx={{display: "flex", justifyContent: "start", alignItems: "end"}} >
				<Stack direction="row" spacing={1} alignItems="center">
					<Android12Switch defaultChecked />
					<Typography>{"Tax Exemption"}</Typography>
				</Stack>
			</Grid>
		</Grid>
	)
}
export default VendorDetailsForm;