import { useState, useEffect } from "react";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Paper, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

const LeadDetails = () => {
  const params = useLocation();
  const [kywd, setKywd] = useState("");

  const routeParams = useLocation().search;
  const searchParams = new URLSearchParams(routeParams);

  const programDtls = params?.state?.paymentList;

  function dateConverter(str) {
    var date = new Date(str);
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var hours = ("0" + date.getHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    var seconds = ("0" + date.getSeconds()).slice(-2);
    var year = date.getFullYear();
    return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleClear = () => {
    setKywd("");

    getLeadList(1);
  };

  const handleSearch = () => {
    if (kywd !== "") {
      getLeadList();
    }
  };

  const handleChangeKeyword = (e) => {
    setKywd(e.target.value);
  };

  const columns = [
    // {
    //   field: 'id',
    //   headerName: "ID",
    //   width: 150,
    //   headerClassName: "super-app-theme--header",

    // },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (!!params.row.name ? params.row.name : "-"),
    },

    {
      field: "account_name",
      headerName: "Account Name",
      headerClassName: "super-app-theme--header",
      width: 150,

      valueGetter: (params) => {
        return !!params.row.SourceID ? params.row.SourceID.Source : "-";
      },
    },
    {
      field: "age",
      headerName: "Age",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (!!params.row.age ? params.row.age : "-"),
    },
    {
      field: "gender",
      headerName: "Gender",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (!!params.row.gender ? params.row.gender : "-"),
    },
    // {
    //   field: "lead_status",
    //   headerName: "Lead Status",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) => {
    //     if (params?.row.LeadStatusID !== null) {
    //       return params?.row?.LeadStatusID.Status;
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    {
      field: `leadStageInfo`,
      headerName: "Stage",
      headerClassName: "super-app-theme--header",
      width: 150,

      valueGetter: (params) => {
        if (params.row?.leadStage !== null) {
          return params.row?.leadStage;
        } else {
          return "-";
        }
      },
    },
    {
      field: `leadDispositionInfo`,
      headerName: "Disposition",
      headerClassName: "super-app-theme--header",
      width: 150,

      valueGetter: (params) => {
        if (
          params.row?.leadStageList !== null &&
          params.row?.leadStageList?.leadDispositionInfo !== null
        ) {
          return params.row?.leadStageList?.leadDispositionInfo.display_value;
        } else {
          return "-";
        }
      },
    },
    {
      field: `leadSubDispositionInfo`,
      headerName: "Sub Disposition",
      headerClassName: "super-app-theme--header",
      width: 200,

      valueGetter: (params) => {
        if (
          params.row?.leadStageList !== null &&
          params.row?.leadStageList?.leadSubDispositionInfo !== null
        ) {
          return params.row?.leadStageList?.leadSubDispositionInfo
            .display_value;
        } else {
          return "-";
        }
      },
    },
    {
      field: `leadSubNotInterestedInfo`,
      headerName: "Grand Disposition",
      headerClassName: "super-app-theme--header",
      width: 200,

      valueGetter: (params) => {
        if (
          params.row?.leadStageList !== null &&
          params.row?.leadStageList?.leadSubNotInterestedInfo !== null
        ) {
          return params.row?.leadStageList?.leadSubNotInterestedInfo
            .display_value;
        } else {
          return "-";
        }
      },
    },
    {
      field: "email_id",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.email_id ? params.row.email_id : "-",
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      headerClassName: "super-app-theme--header",
      width: 150,

      valueGetter: (params) =>
        !!params.row.contact_number ? params.row.contact_number : "-",
    },
    {
      field: "height",
      headerName: "Height",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (!!params.row.height ? params.row.height : "-"),
    },
    {
      field: "current_weight",
      headerName: "Weight",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.current_weight ? params.row.current_weight : "-",
    },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   valueGetter: (params) =>
    //     params.row.address !== null ? params.row.address : "-",
    // },
    {
      field: "city",
      headerName: "City",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (!!params.row.city ? params.row.city : "-"),
    },
    // {
    //   field: "state",
    //   headerName: "State",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   valueGetter: (params) =>
    //     params.row.state !== null ? params.row.state : "-",
    // },
    {
      field: "country",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.country ? params.row.country : "-",
    },
    // {
    //   field: "pincode",
    //   headerName: "Pin Code",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   valueGetter: (params) =>
    //     params.row.pincode !== null ? params.row.pincode : "-",
    // },
    // {
    //   field: "mother_tongue",
    //   headerName: "Mother Tongue",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   valueGetter: (params) =>
    //     params.row.mother_tongue !== null ? params.row.mother_tongue : "-",
    // },
    {
      field: "notes",
      headerName: "Notes",
      width: "200",
      valueGetter: (params) => (!!params.row.notes ? params.row.notes : "-"),
    },
    {
      field: "preferred_language",
      headerName: "Preferred Language",
      headerClassName: "super-app-theme--header",
      width: 150,

      valueGetter: (params) =>
        !!params.row.preferred_language ? params.row.preferred_language : "-",
    },
    {
      field: "assigned_to_user",
      headerName: "Sales Person",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => {
        if (params?.row?.assignedToUserName !== null) {
          return params?.row?.assignedToUserName;
        } else {
          return "-";
        }
      },
    },
    {
      field: "occupation",
      headerName: "Profession",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.occupation ? params.row.occupation : "-",
    },
    // {
    //   field: "work_hours",
    //   headerName: "Work Hours",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   hide :true,
    //   valueGetter: (params) =>
    //     params.row.work_hours !== null ? params.row.work_hours : "-",

    // },
    // {
    //   field: "family_details",
    //   headerName: "Family Details",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.family_details !== null ? params.row.family_details : "-",
    // },
    // {
    //   field: "emergency_contact",
    //   headerName: "Emergency Contact",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.emergency_contact !== null
    //       ? params.row.emergency_contact
    //       : "-",
    // },
    // {
    //   field: "medications",
    //   headerName: "Medications",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.medications !== null ? params.row.medications : "-",
    // },
    // {
    //   field: "medical_history",
    //   headerName: "Medical History",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.medical_history !== null ? params.row.medical_history : "-",
    // },
    {
      field: "current_medical_condition",
      headerName: "Current Medical Condition",
      headerClassName: "super-app-theme--header",
      width: 200,

      valueGetter: (params) =>
        params.row.current_medical_condition !== null
          ? params.row.current_medical_condition
          : "-",
    },
    // {
    //   field: "weight_goal",
    //   headerName: "Weight Goal",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.weight_goal !== null ? params.row.weight_goal : "-",
    // },
    // {
    //   field: "sleep_goal",
    //   headerName: "Sleep Goal",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.sleep_goal !== null ? params.row.sleep_goal : "-",
    // },
    // {
    //   field: "water_goal",
    //   headerName: "Water Goal",
    //   width: 150,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "nutrition_goal",
    //   headerName: "Nutrition Goal",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.nutrition_goal !== null ? params.row.nutrition_goal : "-",
    // },
    // {
    //   field: "steps_goal",
    //   headerName: "Steps Goal",
    //   width: 150,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "fitness_specifications",
    //   headerName: "Fitness Specifications",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.fitness_specifications !== null
    //       ? params.row.fitness_specifications
    //       : "-",
    // },
    // {
    //   field: "food_specifications",
    //   headerName: "Food Specifications",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.food_specifications !== null
    //       ? params.row.food_specifications
    //       : "-",
    // },
    // {
    //   field: "body_measurements",
    //   headerName: "Body Measurements",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,

    //   valueGetter: (params) =>
    //     params.row.body_measurements !== null
    //       ? params.row.body_measurements
    //       : "-",
    // },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 150,
      valueGetter: (params) => {
        if (params.row.createdAt !== null) {
          return dateConverter(params.row.createdAt);
        } else {
          return "-";
        }
      },
    },
    {
      field: "created_by_details",
      headerName: "Created By",
      width: 150,
      valueGetter: (params) => {
        if (params.row.createdUserName !== null) {
          return params.row.createdUserName;
        } else {
          return "-";
        }
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      width: 150,
      valueGetter: (params) => {
        if (params.row.updatedAt !== null) {
          return params.row.updatedAt.split("T")[0];
        } else {
          return "-";
        }
      },
    },
    {
      field: "updated_by_details",
      headerName: "Updated By",
      width: 150,
      valueGetter: (params) => {
        if (params.row.updatedUserName !== null) {
          return params.row.updatedUserName;
        } else {
          return "-";
        }
      },
    },
    {
      field: "Actions",
      hideable: false,
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 150,

      renderCell: (params) => {
        // if (params.row.vmax_lead_hf_report !== null) {
        //   setIsReportExist(true);
        // } else {
        //   setIsReportExist(false);
        // }

        return (
          <Button onClick={() => updatePayment(params.id)}>Pick Lead</Button>
        );
      },
    },
  ];

  const history = useHistory();
  const [leadList, setLeadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRecords, setTotalRecords] = useState();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const updatePayment = (id) => {
    axiosFetch({
      url: `/manualConsultationAssignment/${id}`,
      method: "put",
      requestConfig: {
        program_id: programDtls.program_detail.id,

        vendor_id: programDtls.program_detail.vendor_id,

        id: programDtls.id,

        payment_date: programDtls.payment_date,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Lead Assigned", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.goBack();
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getLeadList = (param) => {
    setLoading(true);

    let parameters;
    if (param !== undefined) {
      parameters = {
        page: paginationModel.page + 1,
        perPage: paginationModel.pageSize,
      };
    } else {
      parameters = {
        page: paginationModel.page + 1,
        perPage: paginationModel.pageSize,
        search: !!kywd ? kywd : null,
      };
    }

    const queryString = Object.entries(parameters)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    axiosFetch({
      url: `/getLeads?${queryString}`,
      method: "post",
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setLeadList(res.data.leads);
          setTotalRecords(res.data.count);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChangePaginationModel = (model) => {
    setPaginationModel(model);
  };
  useEffect(() => {
    let qryStr = searchParams;

    if (!!qryStr.get("search")) {
      setKywd(qryStr.get("search"));
    }

    getLeadList();
  }, [paginationModel]);

  return (
    <div>
      <br />

      <Card sx={{ minWidth: 275 }}>
        <br />
        <CardContent>
          <input
            type="text"
            placeholder="Search by name, number, email"
            style={{ width: "13%", height: "55px" }}
            value={kywd}
            onChange={handleChangeKeyword}
          />
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => handleClear()}
          >
            Clear
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => handleSearch()}
          >
            Search
          </Button>
        </CardActions>
      </Card>
      <br />
      <Paper
        sx={{
          height: 700,
          width: "100%",
        }}
      >
        <DataGridPro
          checkboxSelection
          rowCount={totalRecords}
          loading={loading}
          rows={leadList}
          columns={columns}
          // onRowClick={(row)=>{
          //   updatePayment(row.id);
          // }}
          // initialState={{
          //   pagination: { paginationModel: { pageSize: 10 } },
          // }}
          // pageSizeOptions={[10,15,20,100]}
          page={paginationModel.page}
          pageSize={paginationModel.pageSize}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handleChangePaginationModel}
        />
      </Paper>
    </div>
  );
};
export default LeadDetails;
