import Cups from "../../img/Cups.jpeg";
import "./ClientDietPlanView.css";
import TableSpoon from "../../img/TableSpoon.jpeg";
import logo from "../../img/VMax Logo.png";
import { useState ,useEffect} from "react";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const ClientDietPlanView = ({
  dietPlanInfo,
  clientDetails,
  age,
  height,
  weight,
  weight_unit,
  current_medical_condition,
  height_unit
  
}) => {
  
 
  const [waterTarget, setWaterTarget] = useState(2)

 
 var id=clientDetails?.leadId

 let history=useHistory()
 const waterTargetValue=async()=>{
  if(id !== undefined){
    await axiosFetch({
      url:`/getWaterGoalByLeadId/${id}`,
      method:"get"
    }).then(res=>{
      if(res.status===200){
        setWaterTarget(res?.data?.water_goal)
      }
      else if(res.status === 400){
        toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
      }else if(res.status === 401){
        history.push('/');
      }
      }).catch(err=>console.error(err))
  }
 }
 useEffect(() => {
  
  if(id !== undefined){
  waterTargetValue()
  }
 }, [clientDetails])
 


 function convert24HrTo12Hr(time24) {
  var ts = time24;
  var H = +ts.substr(0, 2);
  var h = (H % 12) || time24;
  h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
  var ampm = H < 12 ? " AM" : " PM";
  ts =(H % 12) ?( h + ts.substr(2, 3) + ampm):time24;
  return  `(${ts})`;
};

var clientName=(!!clientDetails?.Lead && !!clientDetails?.Lead?.ClientInfo?.ClientDietitianInfo) ?clientDetails?.Lead?.ClientInfo?.ClientDietitianInfo:"-"
 var dietPlanTotalValue=(dietPlanInfo[0]?.totalValue?.length !==0 && dietPlanInfo[0]?.totalValue !==undefined)?dietPlanInfo[0]?.totalValue:"-"

//  var customizedMealList;
//  if(dietPlanInfo.length >= 2){
//   customizedMealList  = dietPlanInfo[dietPlanInfo.length-1].newTableRow.addValue.filter(item=>item!==dietPlanInfo[dietPlanInfo.length-1].newTableRow.dropDownValue);
// }
// else{
//   customizedMealList = dietPlanInfo.filter(item=>item.newTableRow.dropDownValue !== dietPlanInfo[dietPlanInfo.length-1].newTableRow.dropDownValue);
// }

// console.log(' ', customizedMealList[0])
  return (
    <div>
      <img className="center" src={logo} alt="Vmax logo" />
      <br />
      <div class="table__head">
        {" "}
        {clientDetails?.Lead?.ClientInfo?.Name !== null ? `Name : ${clientDetails?.Lead?.ClientInfo?.Name}` : ""}
      </div>
      <table id='tableCss' >
        <tbody  >
          <tr className="text-center" >
            <td className="bg__grey" colspan="2">
              <strong>
                {!!age ? `Age : ${age}` : "-"}{" "}&nbsp;
                &nbsp; {!!height ? `Height : ${height}${" "}${height_unit} ` : "-"}&nbsp;&nbsp;
                {!!weight ? `Weight : ${weight}${" "}${weight_unit} ` : "-"}
              </strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>PRIMARY FITNESS GOALS</strong>
            </td>
            <td>-</td>
          </tr>
          <tr>
            <td>
              <strong>DIET TYPE </strong>
            </td>
            <td>-</td>
          </tr>
          <tr>
            <td>
              <strong>DIET RESTRICTIONS </strong>
            </td>
            <td>-</td>
          </tr>
          <tr>
            <td>
              <strong>HEALTH ISSUES</strong>
            </td>
            <td>{current_medical_condition?.length !== 0 ? current_medical_condition?.toString() : "-"}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <br />
        <br />
        <div>
          <strong>NUTRITIONIST NAME: {(clientName.firstName?clientName.firstName:'-')+(clientName?.lastName?clientName?.lastName:'')}</strong>
          <br />
          NUTRITIONAL INFORMATION: Calories-
          <strong> { dietPlanInfo?.length>0 && dietPlanTotalValue?.calories} gm</strong>&nbsp;&nbsp;
          Protein-<strong>{dietPlanInfo?.length>0 && dietPlanTotalValue?.protein} gm</strong>&nbsp;&nbsp; Water Intake-
          <strong>{(waterTarget!==null && waterTarget!==undefined)?waterTarget:"-"} &nbsp;L</strong>
          <br />
          <br />
          <div>
            <table id='tableCss' >
              <tbody class="bg__transparent">
                {dietPlanInfo && dietPlanInfo?.length>0
                  ? dietPlanInfo
                  ?.map((plan) => {
                      return plan?.food_menu?.map((food) => (
                        <>
                          <tr>
                            <td className="text-center">
                              <strong>
                                
                                {!!food.name ? food.name.toUpperCase() : ""}&nbsp;
                                {!!food.time ?  ( convert24HrTo12Hr(`${food.time}`) )  : ""}
                              </strong>
                            </td>
                            <td className="text-center">
                              {" "}
                              <strong>SPECIFICATION </strong>
                            </td>
                          </tr>
                          {food.food.map((item, idx) => (
                            <>
                            <tr>
                             
                              <td>
                              {item.Type==='Veg'&&<img src={require('../../img/icons8-vegetarian-48.png')} alt="Vegatarian" style={{height:'20px'}}/> }
                       {item.Type==='Non-Veg' && <img src={require('../../img/icons8-non-vegetarian-food-symbol-48.png')} alt='non-Veg' style={{height:'20px'}}/>}&nbsp; &nbsp;
                                {`${idx + 1}. ${
                                !!item.food_item_name ? `${item.food_item_name} -` : ""
                              } ${
                                !!item.quantity ? `${item.quantity} -` : ""
                              } ${!!item.unit ? `${item.unit}` : ""}`}

                              {item.childFoodItems.length>0 && item.childFoodItems?.map(child=>(
                              <>
                                <div><b style={{marginLeft:'100px'}}>(or)</b></div>
                              {/* <tr> */}
                              {/* <td> */}
                              {child.Type==='Veg'&&<img src={require('../../img/icons8-vegetarian-48.png')} alt="Vegatarian" style={{height:'20px'}}/> }
                       {child.Type==='Non-Veg' && <img src={require('../../img/icons8-non-vegetarian-food-symbol-48.png')} alt='non-Veg' style={{height:'20px'}}/>}&nbsp; &nbsp;
                                {` ${
                                !!child.food_item ? `${child.food_item} -` : ""
                              } ${
                                !!child.quantity ? `${child.quantity} -` : ""
                              } ${!!child.unit ? `${child.unit}` : ""}`}
                              
                              {/* </tr> */}
                              </>
                            ))} 
                              </td>
                              <td className="text-center" style={{whiteSpace:'pre-wrap',wordBreak:'break-word',maxWidth:'300px'}}>
                                {!!item.note ? item?.note : "-"}
                              </td>
                            </tr>
                            
                            
                            </>
                          ))}
                        </>
                      ));
                    })
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <div>
       
        <br />
        <h style={{ color: "black" }}>
          <u>
            <strong>General Instruction:</strong>
          </u>
        </h>
        <p></p>
        <div>
         <p>
         ✓ Keep yourself hydrated by consuming at least 10-12 glasses of water
            everyday. Avoid consuming water before and after the meal.{" "}
         </p>
         
         <p>
         ✓ Ensure that you do not skip the mid meals and make a point to
            consume mid meal snacks for boosting energy needs which will help
            reduce fatigue and also provide energy.
         </p>
         
           <p>
           ✓ Avoid lying down immediately after meals to reduce gastric issues.
         </p>
         
         <p>
         ✓ Avoid consuming too much salt in your diet. Limit salt intake to
            5gms/day.{" "}
         </p>
      
          <p>
          ✓ Weigh the food before serving on the plate (do buy a food weighing
            scale and measuring cups).
          </p>
        
          <p>
          ✓ Eat protein followed by vegetables and then the other item on the
            plate.
          </p>
      <p>
      ✓ Do not add coconut to your gravies, sprinkle coconut once the
            poriyal/sabji is cooked.
          
      </p>

         <p>
         ✓ Update your meal plates regularly, this will help us to guide you
            further.{" "}
         </p>

       <p>
       ✓ Go on less salt for dinner.
       </p>
        
        <p>
        ✓ Do not use sugars in your diets; they add extra calories.
        </p>
          
     <p>
     ✓  Do not use butter,dalda, margine, ghee. If the ghee is organic and
            pure then 1tsp of ghee can be added to your rice/roti.
     </p>

          
        
          
        </div>
        <p></p> <br />
        <h style={{ color: "black" }}>
          <u>
            <strong>Measurement Help Desk:</strong>
          </u>
        </h>
        <div class="row">
          <div class="column">
            <strong>
              <u>MEASURING SPOONS</u>
            </strong>
          </div>
          <div class="column">
            <strong>
              <u>MEASURING CUPS</u>
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <img
              class="imageborder"
              src={Cups}
              height="80%"
              width="80%"
              alt=""
            />
          </div>
          <div class="column">
            <img
              class="imageborder"
              src={TableSpoon}
              height="102%"
              width="102%"
              alt=""
            />
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default ClientDietPlanView;
