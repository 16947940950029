import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useState } from "react";
import {
  Button,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Container,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/system";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useEffect } from "react";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

const localizer = momentLocalizer(moment); // or globalizeLocalizer
const MyCalendar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [meetingDetails, setMeetingDetails] = useState("");
  const [isRepeat, setIsRepeat] = useState(false);
  const [leadName, setLeadName] = useState("");
  const [isErrLeadName, setIsErrLeadName] = useState(false);
  const [isErrStartDate, setIsErrStartDate] = useState(false);
  const [isErrStartTime, setIsErrStartTime] = useState(false);
  const [isErrEndDate, setIsErrEndDate] = useState(false);
  const [isErrEndTime, setIsErrEndTime] = useState(false);
  const [isErrEventTitle, setIsErrEventTitle] = useState(false);
  const [isErrmeetingDetails, setIsErrMeetingDetails] = useState(false);

  const [toggleSlotMenu, setToggleSlotMenu] = useState(false);
  const [slotTitle, setSlotTitle] = useState("");
  const [slotStartDate, setSlotStartDate] = useState(null);
  const [slotEndDate, setSlotEndDate] = useState(null);
  const [slotStart_FromTime, setSlotStart_FromTime] = useState(null);
  const [slotStart_ToTime, setSlotStart_ToTime] = useState(null);
  const [slotEnd_FromTime, setSlotEnd_FromTime] = useState(null);
  const [slotEnd_ToTime, setSlotEnd_ToTime] = useState(null);
  const [slotDurartion, setSlotDuration] = useState(null);
  const [slotIsRepeat, setSlotIsRepeat] = useState(false);

  const [isErrSlotTitle, setIsErrSlotTitle] = useState(false);
  const [isErrSlotStartDate, setIsErrSlotStartDate] = useState(false);
  const [isErrSlotEndDate, setIsErrSlotEndDate] = useState(false);
  const [isIsErrSlotDuration, setIsErrSlotDuration] = useState(false);

  const [isErrSlotStart_FromTime, setIsErrSlotStart_FromTime] = useState("");
  const [isErrSlotStart_ToTime, setIsErrSlotStart_ToTime] = useState(false);
  const [isErrSlotEnd_FromTime, setIsErrSlotEnd_FromTime] = useState(false);
  const [isErrSlotEnd_ToTime, setIsErrSlotEnd_ToTime] = useState("");
  const [btnName, setBtnName] = useState("Save");
  const [slotBtnName, setSlotBtnName] = useState("Save");
  const [leadList, setLeadList] = useState([]);
  const [slotList, setSlotList] = useState([]);

  let history = useHistory();
  let operator = JSON.parse(window.localStorage.getItem("operator"));

  let [duration, setDuration] = useState([]);

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };`
  );

  let [eventList, setEventList] = useState([]);
  let [selectedEventId, setSelectedEventId] = useState(null);

  const handleChangeSlotStartDate = (newValue) => {
    setSlotStartDate(newValue);
    if (newValue === null) {
      setIsErrSlotStartDate(true);
    } else {
      setIsErrSlotStartDate(false);
    }
  };

  const handleChangeLeadName = (e) => {
    setLeadName(e.target.value);
    if (e.target.value === "") {
      setIsErrLeadName(true);
    } else {
      setIsErrLeadName(false);
    }
  };

  const handleChangeSlotEndDate = (newValue) => {
    setSlotEndDate(newValue);
    if (newValue === null) {
      setIsErrSlotEndDate(true);
    } else {
      setIsErrSlotEndDate(false);
    }
  };

  const handleChangeSlotDuration = (event) => {
    setSlotDuration(event.target.value);
    if (event.target.value === "") {
      setIsErrSlotDuration(true);
    } else {
      setIsErrSlotDuration(false);
    }
  };

  const slotStart_FromTimeChanges = (newValue) => {
    setSlotStart_FromTime(newValue);
    if (newValue === null) {
      setIsErrSlotStart_FromTime(true);
    } else {
      setIsErrSlotStart_FromTime(false);
    }
  };

  const slotEnd_FromTimeChanges = (newValue) => {
    setSlotEnd_FromTime(newValue);
    if (newValue === null) {
      setIsErrSlotEnd_FromTime(true);
    } else {
      setIsErrSlotEnd_FromTime(false);
    }
  };

  const slotEnd_ToTimeChanges = (newValue) => {
    setSlotEnd_ToTime(newValue);
    if (newValue === null) {
      setIsErrSlotEnd_ToTime(true);
    } else {
      setIsErrSlotEnd_ToTime(false);
    }
  };
  const slotStart_ToTimeChanges = (newValue) => {
    setSlotStart_ToTime(newValue);
    if (newValue === null) {
      setIsErrSlotStart_ToTime(true);
    } else {
      setIsErrSlotStart_ToTime(false);
    }
  };

  const editEvent = (e) => {
    setSelectedEventId(e.id);

    if (e.isSlot) {
      // slot
      setToggleSlotMenu(true);
      loopDuration();
      setSlotBtnName("Update");
      setSlotStartDate(dayjs(e.start));
      setSlotEndDate(dayjs(e.end));
      setSlotStart_FromTime(dayjs(e.start));
      setSlotEnd_FromTime(dayjs(e.end));
      setSlotDuration(e.duration);
    } else {
      // appointment
      setToggleMenu(true);
      setBtnName("Update");
      setLeadName(e.title);
      setStartDate(dayjs(e.start));
      setEndDate(dayjs(e.end));
      setStartTime(dayjs(e.start));
      setEndTime(dayjs(e.end));
    }
  };
  const openMenu = () => {
    setToggleMenu(true);
    setBtnName("Save");
    setLeadName("");
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
  };
  const closeMenu = () => {
    setToggleMenu(false);
  };

  const openSlotMenu = () => {
    setToggleSlotMenu(true);
    setSlotBtnName("Save");
    setSlotStartDate(null);
    setSlotEndDate(null);
    setSlotStart_FromTime(null);
    setSlotEnd_FromTime(null);
    setSlotDuration("");
    loopDuration();
  };
  const closeSlotMenu = () => {
    setToggleSlotMenu(false);
  };

  const deleteAppointment = (e) => {
    let url;
    if (e.isSlot) {
      url = `/deleteUserAvailableSlot/${e.id}`;
    } else {
      url = `/deleteFollowUpFromCalender/${e.id}`;
    }
    axiosFetch({
      url,
      method: "delete",
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data, { position: toast.POSITION.TOP_CENTER });
          getAvailableSlots();
          getAppointmentsList();
        } else if (res.status === 400) {
          toast.error(res.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleTitle = (e) => {
    return (
      <div>
        <span>{e.title}</span>
        <span style={{ float: "right" }}>
          <Stack direction="row" spacing={2}>
            <EditIcon onClick={() => editEvent(e)} />
            <DeleteOutlineIcon onClick={() => deleteAppointment(e)} />
          </Stack>
        </span>
      </div>
    );
  };

  const handleChangeSlotTitle = (e) => {
    setSlotTitle(e.target.value);
    if (e.target.value === "") {
      setIsErrSlotTitle(true);
    } else {
      setIsErrSlotTitle(false);
    }
  };

  const handleChangeMeetingDetails = (e) => {
    setMeetingDetails(e.target.value);
    if (e.target.value === "") {
      setIsErrMeetingDetails(true);
    } else {
      setIsErrMeetingDetails(false);
    }
  };

  const handleChangeRepeat = (e) => {
    setIsRepeat(e.target.checked);
  };

  const handleChangeSlotRepeat = (e) => {
    setSlotIsRepeat(e.target.checked);
  };
  const handleChangeTitle = (e) => {
    setEventTitle(e.target.value);
    if (e.target.value === "") {
      setIsErrEventTitle(false);
    } else {
      setIsErrEventTitle(true);
    }
  };

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
    if (newValue === null) {
      setIsErrStartDate(true);
    } else {
      setIsErrStartDate(false);
    }
  };

  const handleChangeStartTime = (newValue) => {
    setStartTime(newValue);
    if (newValue === null) {
      setIsErrStartTime(true);
    } else {
      setIsErrStartTime(false);
    }
  };

  const handleChangeEndTime = (newValue) => {
    setEndTime(newValue);
    if (newValue === null) {
      setIsErrEndTime(true);
    } else {
      setIsErrEndTime(false);
    }
  };

  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
    if (newValue === null) {
      setIsErrEndDate(true);
    } else {
      setIsErrEndDate(false);
    }
  };

  const loopDuration = () => {
    if (duration.length === 0) {
      for (let i = 5; i <= 60; i = i + 5) {
        duration.push(i);
      }
    }
  };

  const handleCancel = () => {
    setEventTitle("");
    setStartDate(null);
    setEndDate(null);
    setEndTime(null);
    setStartTime(null);
    setIsRepeat(false);
    setMeetingDetails("");
    setToggleMenu(false);
  };

  const handleSlotCancel = () => {
    setToggleSlotMenu(false);
    setSlotBtnName("Save");
    setSlotStartDate(null);
    setSlotEndDate(null);
    setSlotStart_FromTime(null);
    setSlotEnd_FromTime(null);
    setSlotDuration("");
  };

  const makeAppointment = () => {
    let url, method;
    if (leadName === "" || leadName === null) {
      setIsErrLeadName(true);
      return false;
    }
    if (startDate === null || startDate === "") {
      setIsErrStartDate(true);
      return false;
    }
    if (startTime === null || startTime === "") {
      setIsErrStartTime(true);
      return false;
    }
    if (endTime === null || endTime === "") {
      setIsErrEndTime(true);
      return false;
    }
    if (endDate === null || endDate === "") {
      setIsErrEndDate(true);
      return false;
    }

    let requestObj = {
      title: !!leadName ? leadName : undefined,
      startDate:
        new Date(startDate).toLocaleDateString().replaceAll("/", "-") +
        " " +
        new Date(startTime).toTimeString().split(" ")[0],

      endDate:
        new Date(endDate).toLocaleDateString().replaceAll("/", "-") +
        " " +
        new Date(endTime).toTimeString().split(" ")[0],
    };
    if (btnName.toLowerCase() === "save") {
      url = "/addFollowUpFromCalender";
      method = "post";
    } else {
      url = `/updateFollowUpFromCalender/${selectedEventId}`;
      method = "put";
    }
    axiosFetch({
      url,
      method,
      requestConfig: requestObj,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data, { position: toast.POSITION.TOP_CENTER });
          setToggleMenu(false);
          getAppointmentsList();
        } else if (res.status === 400) {
          toast.error(res.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const saveSlot = () => {
    let method, url;

    // if (slotTitle === "") {
    //   setIsErrSlotTitle(true);
    //   return false;
    // }
    if (slotStartDate === null || slotStartDate === "") {
      setIsErrSlotStartDate(true);
      return false;
    }
    if (slotStart_FromTime === null || slotStart_FromTime === "") {
      setIsErrSlotStart_FromTime(true);
      return false;
    }
    // if (slotStart_ToTime === "") {
    //   setIsErrSlotStart_ToTime(true);
    //   return false;
    // }
    if (slotEnd_FromTime === null || slotEnd_FromTime === "") {
      setIsErrSlotEnd_FromTime(true);
      return false;
    }
    // if (slotEnd_ToTime === "") {
    //   setIsErrSlotEnd_ToTime(true);
    //   return false;
    // }
    if (slotEndDate === null || slotEndDate === "") {
      setIsErrEndDate(true);
      return false;
    }
    if (slotDurartion === "" || slotDurartion === null) {
      setIsErrSlotDuration(true);
      return false;
    }

    const convertDateFormat = (startDate, startTime) => {
      const [month, day, year] = startDate.split("-");
      const [hours, minutes, seconds] = startTime.split(":");
      const localDate = new Date(year, month - 1, day, hours, minutes, seconds);
      const utcDate = new Date(localDate.toUTCString());
      return utcDate;
    };

    let requestObj = {
      startDateTime: convertDateFormat(
        new Date(slotStartDate).toLocaleDateString().replaceAll("/", "-"),
        new Date(slotStart_FromTime).toTimeString().split(" ")[0]
      ),

      endDateTime: convertDateFormat(
        new Date(slotEndDate).toLocaleDateString().replaceAll("/", "-"),
        new Date(slotEnd_FromTime).toTimeString().split(" ")[0]
      ),

      duration: slotDurartion !== "" ? slotDurartion : undefined,

      userId: operator.id,
    };

    if (slotBtnName.toLowerCase() === "save") {
      url = "/postUserAvailableSlot";
      method = "post";
    } else {
      url = `/updateUserAvailableSlot/${selectedEventId}`;
      method = "put";
    }

    axiosFetch({
      url,
      method,
      requestConfig: requestObj,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data, { position: toast.POSITION.TOP_CENTER });
          getAvailableSlots();
          setToggleSlotMenu(false);
        } else if (res.status === 400) {
          toast.error(res.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getLeadDetails = () => {
    axiosFetch({
      url: "/getLeadsForFollowup",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setLeadList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const configEventList = (eventArr) => {
    let list = eventArr.map((data) => {
      var split_startDate = data.NextFollowUpDate?.split(/-|T|:/);
      var convert_startDate = new Date(
        split_startDate[0],
        split_startDate[1] - 1,
        split_startDate[2],
        split_startDate[3],
        split_startDate[4]
      );
      var final_startDate = new Date(convert_startDate);

      var split_endDate = data.NextFollowUpEndDate?.split(/-|T|:/);
      var convert_endDate = new Date(
        split_endDate[0],
        split_endDate[1] - 1,
        split_endDate[2],
        split_endDate[3],
        split_endDate[4]
      );
      var final_endDate = new Date(convert_endDate);

      return {
        id: data.id,
        isSlot: false,
        title: data.LeadInfo.name + "-" + data.LeadId,
        start: final_startDate,
        end: final_endDate,
      };
    });

    eventList.push(...list);
    const uniqueSlots = [...new Map(eventList.map((v) => [v.id, v])).values()];
    setEventList(uniqueSlots);
  };

  const getAppointmentsList = () => {
    axiosFetch({
      url: "/getFollowUpsforCalender",
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        configEventList(res.data);
      } else if (res.status === 401) {
        history.push("/");
      } else if (res.status === 500) {
        toast.error("Internal Server Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const getAvailableSlots = () => {
    axiosFetch({
      url: `/getUserAvailableSlotByUserId/${operator.id}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((data) => {
            const final_startDate = new Date(data.startDateTime);
            const final_endDate = new Date(data.endDateTime);

            return {
              id: data.id,
              isSlot: true,
              start: final_startDate,
              end: final_endDate,
              duration: data.duration,
            };
          });

         

          eventList.push(...data);
          const uniqueSlots = [
            ...new Map(eventList.map((v) => [v.id, v])).values(),
          ];

          setEventList(uniqueSlots);
          setSlotList(res.data);
        } else if (res.status === 400) {
          toast.error(res.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getLeadDetails();
    getAppointmentsList();
    getAvailableSlots();
  }, []);
  return (
    <div className="myCustomHeight" style={{ margin: "28px" }}>
      <br />
      <br />
      <div>
        <Stack direction="row" spacing={2}>
          <Typography variant="h6">
            <strong>Appointments</strong>
          </Typography>
          <div
            style={{
              float: "right",
              textTransform: "capitalize",
              marginLeft: "78%",
            }}
          >
            <Button
              variant="contained"
              onClick={openMenu}
              sx={{
                textTransform: "capitalize",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              Add Schedule
            </Button>
            &nbsp; &nbsp;
            <Button
              variant="contained"
              onClick={openSlotMenu}
              sx={{
                textTransform: "capitalize",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              Add Slots
            </Button>
          </div>
        </Stack>
      </div>
      <br />

      <Calendar
        views={["day", "agenda", "work_week", "month"]}
        selectable
        defaultDate={new Date()}
        localizer={localizer}
        events={eventList}
        defaultView="month"
        style={{ height: "100vh" }}
        step={5}
        timeslots={2}
        // onSelectSlot={handleSelect}
        titleAccessor={handleTitle}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "lightgrey",
            color: "black",
            borderRadius: "0px",
            border: "none",
          };

          if (event.isSlot) {
            newStyle.backgroundColor = "lightgreen";
          }

          return {
            className: "",
            style: newStyle,
          };
        }}
      />
      <Dialog onClose={closeMenu} open={toggleMenu}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogTitle>Schedule Appointments</DialogTitle>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Lead Name<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Select
                  placeholder="Select"
                  value={leadName}
                  onChange={handleChangeLeadName}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "center",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "center",
                      horizontal: "center",
                    },
                  }}
                >
                  {leadList.length !== 0 &&
                    leadList.map((lead) => {
                      return <MenuItem value={lead.id}>{lead.text}</MenuItem>;
                    })}
                </Select>
                <span style={{ color: "red" }}>
                  {isErrLeadName ? "Please select something" : ""}
                </span>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <Typography>
                Start Date<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Start Time<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={startDate}
                  onChange={handleChangeStartDate}
                  defaultDate={startDate}
                  slotProps={{
                    textField: {
                      sx: { color: "red" },
                      error: isErrStartDate,
                      helperText: isErrStartDate
                        ? "Please select somthing"
                        : "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  placeholder="Select Time"
                  value={startTime}
                  onChange={handleChangeStartTime}
                  slotProps={{
                    textField: {
                      sx: { color: "red" },
                      error: isErrStartTime,
                      helperText: isErrStartTime
                        ? "Please select somthing"
                        : "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Typography>
                End Date<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                End Time<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={endDate}
                  onChange={handleChangeEndDate}
                  slotProps={{
                    textField: {
                      sx: { color: "red" },
                      error: isErrEndDate,
                      helperText: isErrEndDate ? "Please select somthing" : "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={endTime}
                  onChange={handleChangeEndTime}
                  slotProps={{
                    textField: {
                      sx: { color: "red" },
                      error: isErrEndTime,
                      helperText: isErrEndTime ? "Please select somthing" : "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {/* <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox value={isRepeat} onChange={handleChangeRepeat} />
                }
                label="Repeat"
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <Typography>
                Meeting Details<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                fullwidth="true"
                value={meetingDetails}
                onChange={handleChangeMeetingDetails}
                maxRows={4}
                aria-label="maximum height"
                placeholder="Enter Here"
                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua."
              />
            </Grid> */}

            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    color: "#2D2D2D",
                    borderColor: "#2D2D2D",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  fullwidth="true"
                  sx={{
                    textTransform: "capitalize",
                    color: "#FFFFFF",
                    backgroundColor: "#2D2D2D",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                  onClick={makeAppointment}
                >
                  {btnName}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Container>
        <br />
        <br />
      </Dialog>

      <Dialog
        onClose={closeSlotMenu}
        open={toggleSlotMenu}
        fullwidth="true"
        contentstyle={{ width: "100%", maxWidth: "none !important" }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogTitle>Slot Booking</DialogTitle>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography>
                Add Title<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12}>
              <TextField
                error={isErrSlotTitle}
                fullwidth="true"
                value={slotTitle}
                onChange={handleChangeSlotTitle}
                placeholder="Enter Here"
                helperText={isErrSlotTitle ? "Please enter something" : ""}
              />
            </Grid> */}
            <Grid item xs={4}>
              <Typography>
                Start Date<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Time<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>

            <Grid item xs={4}></Grid>

            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD-MM-YYYY"
                  value={slotStartDate}
                  onChange={handleChangeSlotStartDate}
                  slotProps={{
                    textField: {
                      error: { isErrSlotStartDate },
                      helperText: isErrSlotStartDate
                        ? "Please select something"
                        : "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  placeholder="Select Time"
                  value={slotStart_FromTime}
                  onChange={slotStart_FromTimeChanges}
                  slotProps={{
                    textField: {
                      error: { isErrSlotStart_FromTime },
                      helperText: isErrSlotStart_FromTime
                        ? "Please select something"
                        : "",
                    },
                  }}
                />{" "}
                &nbsp;
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              {" "}
              {/* <Stack direction="row" spacing={2}>
                <Typography>to</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    placeholder="Select Time"
                    value={slotStart_ToTime}
                    onChange={slotStart_ToTimeChanges}
                    slotProps={{
                      textField: {
                        error: { isErrSlotStart_ToTime },
                        helperText: isErrSlotStart_ToTime
                          ? "Please select something"
                          : "",
                      },
                    }}
                  />{" "}
                </LocalizationProvider>
              </Stack> */}
            </Grid>

            <Grid item xs={4}>
              <Typography>
                End date<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {" "}
              <Typography>
                Time<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={slotEndDate}
                  onChange={handleChangeSlotEndDate}
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      error: { isErrSlotEndDate },
                      helperText: isErrSlotEndDate
                        ? "Please select something"
                        : "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  placeholder="Select Time"
                  value={slotEnd_FromTime}
                  onChange={slotEnd_FromTimeChanges}
                  slotProps={{
                    textField: {
                      error: { isErrSlotEnd_FromTime },
                      helperText: isErrSlotEnd_FromTime
                        ? "Please select something"
                        : "",
                    },
                  }}
                />{" "}
                &nbsp;
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              {/* <Stack direction="row" spacing={2}>
                <Typography>to</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    placeholder="Select Time"
                    value={slotEnd_ToTime}
                    onChange={slotEnd_ToTimeChanges}
                    slotProps={{
                      textField: {
                        error: { isErrSlotEnd_ToTime },
                        helperText: isErrSlotEnd_ToTime
                          ? "Please select something"
                          : "",
                      },
                    }}
                  />{" "}
                </LocalizationProvider>
              </Stack> */}
            </Grid>

            <Grid item xs={12}>
              <Typography>
                Slot with Duration<span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Select
                sx={{ width: "90px !important" }}
                value={slotDurartion}
                onChange={handleChangeSlotDuration}
              >
                <MenuItem value="">Select</MenuItem>
                {duration.length !== 0 &&
                  duration.map((mins) => {
                    return <MenuItem value={mins}>{mins}</MenuItem>;
                  })}
              </Select>
              <span style={{ color: "red" }}>
                {isIsErrSlotDuration ? "Please select something" : ""}
              </span>
            </Grid>
            {/* <Grid item xs={3}>
              <Typography>Minutes</Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={slotIsRepeat}
                    onChange={handleChangeSlotRepeat}
                  />
                }
                label="Repeat"
              />
            </Grid> */}
            <Grid item xs={6}></Grid>
            <Grid item xs={4}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    color: "#2D2D2D",
                    borderColor: "#2D2D2D",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                  }}
                  onClick={handleSlotCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  fullwidth="true"
                  sx={{
                    textTransform: "capitalize",
                    color: "#FFFFFF",
                    backgroundColor: "#2D2D2D",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                  onClick={saveSlot}
                >
                  {slotBtnName}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Container>
        <br />
        <br />
      </Dialog>
    </div>
  );
};

export default MyCalendar;
