import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import DataViewer from "../../../components/DataViewer/DataViewer";
import ControlledTextField from "../../../components/ControlledTextField";
import { FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../../components/ControlledAutocomplete";
import ControlledRadioGroup from "../../../components/ControlledRadioGroup";
import ControlledTimepicker from "../../../components/ControlledTimepicker";
import ControllerDatetimepicker from "../../../components/ControlledDatetimepicker";
import FormRenderer from "../../../components/FormRenderer/FormRenderer";
import formdata from "./diet_preference";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const WorkoutPreferenceForm = ({ displaydata, update }) => {
  const [isedit, setIsedit] = useState(false);
  const [masters, setMasters] = useState({});
  const [infoview, setInfoview] = useState({});

  const defaultValues = {
    createDate: new Date(),
  };
  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors, isValid },
  } = methods;

  const handleClick = (event) => {
    setIsedit(!isedit);
  };
  useEffect(() => {
    axiosFetch({
      method: "get",
      url: `/getWorkoutPreferenceMaster`,
    })
      .then((response) => {
        setMasters({
          ...masters,
          excerciseTime: [...response.data.body.excercise_time],
          fitnessRegime: [...response.data.body.fitness_regime],
          workoutFreq: [...response.data.body.workout_freq],
        });
      })
      .catch((err) => console.error(err));
  }, []);

  const handleCancel = (event) => {
    setIsedit(false);
  };

  useEffect(() => {
    if (displaydata) {
      let dietInfo = displaydata?.workoutPreferences;
      setValue(
        "is_physical_restriction",
        dietInfo?.is_physical_restriction ? "Yes" : "No"
      );
      setValue("physical_restrictions", dietInfo?.physical_restrictions);
      setValue("preferred_exercise_time", dietInfo?.preferred_exercise_time);
      setValue("current_fitness_regime", dietInfo?.current_fitness_regime);
      setValue("challenges_faced", dietInfo?.challenges_faced);
      setValue("workout_frequency", dietInfo?.workout_frequency);
      setValue("workout_preference", dietInfo?.workout_preference);
      setValue("Other Fitness Regime", dietInfo?.other_fitness_regime);
      setInfoview({
        "Any Physical Restriction?": dietInfo?.is_physical_restriction
          ? "Yes"
          : "No",
        "Physical Restrictions": dietInfo?.physical_restrictions,
        "Preferred Exercise Time": dietInfo?.preferred_exercise_time,
        "Current Fitness Regime": dietInfo?.current_fitness_regime,
        "Other Fitness Regime": dietInfo?.other_fitness_regime,
        "Challenges Faced": dietInfo?.challenges_faced,
        "Workout Frequency": dietInfo?.workout_frequency,
        "Workout Preference": dietInfo?.workout_preference,
      });

      //  setValue("mealtimings",dietInfo?.mealtimings)

      // setValue("gender",basicinfo?.gender)
      // setValue("dob",basicinfo?.dob)
    }
  }, [displaydata]);
  const handleworkoutpreference = async (data) => {
    const isNotValid = () => {
      toast.error("Please fill all the Yes mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    };

    const { physical_restrictions, is_physical_restriction } = data;

    if (is_physical_restriction === "Yes" && !physical_restrictions) {
      isNotValid();
      return;
    }

    setIsedit(false);

    update({
      ...data,
      formtype: 3,
    });

    //alert(JSON.stringify(data))
  };

  const is_physical_restriction = watch("is_physical_restriction");
  useEffect(() => {
    if (is_physical_restriction?.toLowerCase() == "no") {
      setValue("physical_restrictions", "");
    } else {
    }
  }, [is_physical_restriction]);

  const current_fitness_regime = watch("current_fitness_regime");
  useEffect(() => {
    if (current_fitness_regime?.toLowerCase() == "other") {
    } else {
      setValue("other_fitness_regime", "");
    }
  }, [current_fitness_regime]);

  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit((data) => {
          handleworkoutpreference(data);
        })}
      >
        {isedit ? (
          <>
            {masters?.excerciseTime && (
              <Grid container xs={12} spacing={2}>
                <Grid container item xs={3} alignItems={"center"}>
                  <ControlledRadioGroup
                    label={"Any Physical Restrictions"}
                    name={"is_physical_restriction"}
                    control={control}
                    row={true}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    options={[
                      {
                        value: "Yes",
                        label: "Yes",
                      },
                      {
                        value: "No",
                        label: "No",
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={3}>
                  <ControlledTextField
                    label={"Enter here"}
                    name={"physical_restrictions"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    isDisableColumn={
                      getValues("is_physical_restriction") == "No"
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Prefered Excercise Time"}
                    name={"preferred_exercise_time"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={
                      displaydata?.workoutPreferences?.preferred_exercise_time
                    }
                    options={
                      masters?.excerciseTime ? masters?.excerciseTime : []
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"Current Fitness Regime"}
                    name={"current_fitness_regime"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={
                      displaydata?.workoutPreferences?.current_fitness_regime
                    }
                    options={
                      masters?.fitnessRegime ? masters?.fitnessRegime : []
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControlledTextField
                    label={"Other Fitness Regime"}
                    name={"other_fitness_regime"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    isDisableColumn={
                      getValues("current_fitness_regime")?.toLowerCase() ==
                      "other"
                        ? false
                        : true
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControlledTextField
                    label={"Any Challenges Faced"}
                    name={"challenges_faced"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControlledAutocomplete
                    label={"How oftendo you workout in a week"}
                    name={"workout_frequency"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={
                      displaydata?.workoutPreferences?.workout_frequency
                    }
                    options={masters?.workoutFreq ? masters?.workoutFreq : []}
                  />
                </Grid>
                <Grid container item xs={3} alignItems={"center"}>
                  <ControlledRadioGroup
                    label={"Workout Preferrence"}
                    name={"workout_preference"}
                    control={control}
                    row={true}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    options={[
                      {
                        value: "Gym",
                        label: "Gym",
                      },
                      {
                        value: "Home",
                        label: "Home",
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid container xs={12} spacing={2}>
            <DataViewer data={infoview} />
          </Grid>
        )}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          {isedit && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Stack>
          )}
        </Grid>
      </form>
      {!isedit && (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button variant="contained" onClick={handleClick}>
              Edit
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default WorkoutPreferenceForm;

//btn-disabled

{
  /* <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Any Physical Restrictions"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.dietPreferredCuisine ? displaydata?.dietPreferredCuisine  : "--"}
              </Typography>
              </Stack>
              </Grid>
              <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Preferred Excercise Time"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.preferred_exercise_time ? displaydata?.preferred_exercise_time  : "--"}
              </Typography>
              </Stack>
              </Grid>
              <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Current Fitness Regime"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.preferred_exercise_time ? displaydata?.preferred_exercise_time  : "--"}
              </Typography>
              </Stack>
              </Grid>
              <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Any Challenges Faced"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.health_issues ? displaydata?.health_issues  : "--"}
              </Typography>
              </Stack>
              </Grid>
              <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"How Often do you workout in a week"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.preferred_exercise_time ? displaydata?.preferred_exercise_time  : "--"}
              </Typography>
              </Stack>
              </Grid>
              <Grid item xs={3}>
            <Stack item >
              <Typography variant="body2" >
              {"Workout Preference"}
              </Typography>
              <Typography variant="h6">
                {displaydata?.workout_preference ? displaydata?.workout_preference  : "--"}
              </Typography>
              </Stack>
              </Grid> */
}
