import * as React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";

import dayjs from "dayjs";

export default function ControlledDatepicker({
  rules,
  label,
  control,
  name,
  setDate,
  date,
  isDisableColumn = false,
  error,
  helperText,
}) {
  const DATE_FORMAT = "DD MMM YYYY";
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...rest } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DesktopDatePicker
              disabled={isDisableColumn}
              value={date}
              label={label}
              size="small"
              maxDate={dayjs(new Date())}
              format={DATE_FORMAT}
              inputFormat={DATE_FORMAT}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error: !!error,
                  helperText: helperText,
                },
              }}
              // renderInput={(params) => (
              //   <TextField
              //     size="small"
              //     {...params}
              //     error={error}
              //     helperText={helperText}
              //   />
              // )}
              {...rest}
            />
          </Stack>
        </LocalizationProvider>
      )}
    />
  );
}
