import React, { useEffect, useState } from "react";
import CallStatusForm from "./CallStatusForm";

import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { axiosFetch } from "../../Utlis/AxiosFetch";

const InteractionForm = ({
  callFlag,
  setCallFlag,
  onSetIsBackDrop,
  leadId = "",
}) => {
  let [isShowForm, setIsShowForm] = useState(callFlag);
  let [endCall, setEndCall] = useState(false);
  let [endCallId, setEndCallId] = useState("");
  let [interactionList, setInteractionList] = useState([]);

  let params = useParams();
  let history = useHistory();

  let operator = JSON.parse(window.localStorage.getItem("operator"));

  useEffect(() => {
    setIsShowForm(callFlag);
    setEndCall(false);
  }, [callFlag]);

  const handleMakeFalse = () => {
    setIsShowForm(false);
    setCallFlag(false);
    setEndCallId("");
  };

  const handleEndCall = () => {
    let id = params.id;
    if (!id && leadId) {
      id = leadId;
    }
    axiosFetch({
      method: "get",
      url: `/getInteractionByRoleIdAndLeadId?leadId=${id}&roleId=${operator.Role}&userId=${operator.id}&page=1&perPage=10&isCallUUID=true`,
    })
      .then((res) => {
        if (res.status === 200) {
          setEndCall(true);
          setEndCallId(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <CallStatusForm
        isShowForm={isShowForm}
        callFlag={callFlag}
        getInteractionList={() => null}
        handleEndCall={handleEndCall}
        endCall={endCall}
        endCallId={endCallId}
        onMakeFalse={handleMakeFalse}
        onSetIsBackDrop={onSetIsBackDrop}
        leadId={leadId}
      />
    </div>
  );
};
export default InteractionForm;
