import React from 'react'
import axios from 'axios'
import * as $ from 'jquery'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import PageComponent from '../../components/PageComponent'

class Notifications extends PageComponent {

    state = {
        tableBodyList: [],
        tableBodyDisplayList: [],
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        },
        onTrueFn: ""
    }

    constructor(props) {
        super(props);
        this.searchNotifications = this.searchNotifications.bind(this)
        if (props.state.currentPage !== "Notifications") {
            props.state.setCurrentPage("Notifications")
        }
    }
    searchNotifications(e) {
        e.preventDefault();
        const enquiryDate = $("#EnquiryDate").val()
        let newTableBodyList = []
      
        if (enquiryDate !== '') {
            newTableBodyList = this.state.tableBodyList.filter(data => this.returnTime(data.NotificationDate) === this.returnTime(this.getNormalDate(enquiryDate)))
        } else {
            newTableBodyList = this.state.tableBodyList
        }
        this.setState({
            tableBodyDisplayList: newTableBodyList
        })
    }

    componentDidMount() {
        let notify = []
        let today = new Date();


        let clientsData = this.props.state.clientsList;
        axios.get(`${this.props.state.ATLAS_URI}/getAgreements/`, this.props.state.configToken)
            .then(response => {
                let agreementData = response.data;
                agreementData.forEach(element => {
                    if (typeof element.ToDate !== 'undefined' && element.ToDate !== '') {
                        if (this.returnTime(element.ToDate) <= this.getTodayTime()) {
                            notify.push({
                                _id: element._id + "0",
                                Name: element.Name,
                                Type: "Agreement Renewable",
                                Date: element.ToDate,
                                NotificationDate: this.getDateFormat(this.returnDate(element.ToDate)),
                                SortDate: this.returnDate(element.ToDate),
                            })
                        }
                    }
                });
                clientsData.forEach(element => {
                    if (typeof element.Anniversary !== 'undefined' && element.Anniversary !== '') {
                        let date = this.returnDate(element.Anniversary);
                        date.setFullYear(today.getFullYear())
                        if (date.getTime() <= this.getTodayTime()) {
                            notify.push({
                                _id: element._id + "1",
                                Name: element.Name,
                                Type: "Anniversary",
                                Date: element.Anniversary,
                                NotificationDate: this.getDateFormat(date),
                                SortDate: date
                            })
                        }
                    }
                    if (typeof element.DOB !== 'undefined' && element.DOB !== '') {
                        let date = this.returnDate(element.DOB);
                        date.setFullYear(today.getFullYear())
                        if (date.getTime() <= this.getTodayTime()) {
                            notify.push({
                                _id: element._id + "2",
                                Name: element.Name,
                                Type: "DOB",
                                Date: element.DOB,
                                NotificationDate: this.getDateFormat(date),
                                SortDate: date
                            })
                        }
                    }
                })
                const x = notify.sort((a, b) => b.SortDate - a.SortDate)
                this.setState({
                    tableBodyDisplayList: x,
                    tableBodyList: x
                });
            }).catch(err => console.log(err))


        /*   const params = new URLSearchParams(window.location.search);
   
           const tempDetails = {};
           tempDetails.id = params.get("id");
           tempDetails.type = params.get("type");
           tempDetails.date = params.get("date");
           console.log(tempDetails)*/
    }

    getDateFormat(today) {
        const date = today.getDate();
        const month = String(parseInt(today.getMonth()) + 1);
        const year = today.getFullYear();
        return (date < 10 && "0") + date + "-" + (month < 10 && "0") + month + "-" + year;
    }

    render() {

        return (
            <section className="content">
                <div className="row">

                    <Dialog
                        onFalse={(e) => this.setState({ dialogInfo: { isOpened: false, text: "" } })}
                        onTrue={this.state.onTrueFn}
                        dialogInfo={this.state.dialogInfo}
                    />

                    <div className="col-md-12">

                        <div className="box box-primary">

                            <BoxHeader title="Select Criteria" />
                            <form onSubmit={this.searchNotifications}>

                                <div className="box-body row">

                                    <input type="hidden" name="ci_csrf_token" value="" />

                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label>Enquiry  Date</label>
                                            <input type="date" id="EnquiryDate" className="form-control" />
                                        </div>
                                        <span className="text-danger"></span>

                                    </div>

                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn btn-primary btn-sm pull-right"><i className="fa fa-search"></i> Search</button>
                                        </div>
                                    </div>

                                </div>

                            </form>

                            <BoxHeader title="Notifications" />

                            <div className="box-body">
                                <DataTable
                                    tableHeader={["_id", "Name", "Type", "Date", "Notification Date"]}
                                    tableBody={this.state.tableBodyDisplayList}
                                    searchField="Type"
                                    deleteFromTable={this.deleteFromTable}
                                    editTableRow={this.editTableRow}
                                    noActions={""}
                                />

                            </div>
                        </div>

                    </div>
                </div>

            </section>
        )

    }

}


export default Notifications;
