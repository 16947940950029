import * as React from "react";
import { Button, Grid, Paper } from "@mui/material";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";

import Typography from "@mui/material/Typography";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#000 !important",
    opacity: "1 !important",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#fff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    //   '&::before': {
    // 	background: `green`,
    // 	left: 12,
    //   },
    //   '&::after': {
    // 	background: `red`,
    // 	right: 12,
    //   },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const initialValue = {
  general_work: false,
  special_work: false,
  value: 0,
  studioType: [],
  workout_plan: false,
  diet_plan: false,
  trainer: false,
  nutrition: false,
  generalWorkout: [],
  SpecialWorkout: [],
  is_consultation: false,
};

const FeatureDetailsForm = ({ programDetails, dispatch }) => {
  const history = useHistory();
  const location = useLocation();
  const isView = location.state?.view;
  const isEdit = location.state?.edit;
  const [activeMenuName, setActiveMenuName] = React.useState("plan");

  const activeMenu = (type) => {
    setActiveMenuName(type);
  };

  const [state, setState] = React.useState(initialValue);

  const handleChange = (event, newValue) => {
    const updatevalue = { ...state, value: newValue };
    setState(updatevalue);
  };

  const handleSwitch = (e, name) => {
    const updateValue = { ...state, [name]: e };
    setState(updateValue);
  };

  const handleradio = (e) => {
    const updateValue = {
      ...state,
      is_consultation: e.target.value,
    };
    setState(updateValue);
  };

  const handleGeneral = (e, value) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setState((prevState) => ({
        ...prevState,
        generalWorkout: [...prevState.generalWorkout, value],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        generalWorkout: prevState.generalWorkout.filter(
          (item) => item !== value
        ),
      }));
    }
  };

  const handleSpecial = (e, value) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setState((prevState) => ({
        ...prevState,
        SpecialWorkout: [...prevState.generalWorkout, value],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        SpecialWorkout: prevState.generalWorkout.filter(
          (item) => item !== value
        ),
      }));
    }
  };

  // Add Studio details function

  const getAlltudio = async () => {
    const yogaList = await axiosFetch({
      url: `/getProductFeatures/${programDetails.id}`,
      method: "get",
    });

    const allList = yogaList.data.body;

    setState((pre) => {
      return {
        ...pre,
        ...allList,
        studioType: allList,
        general_work: allList?.general_work?.isTrue,
        special_work: allList?.special_work?.isTrue,
        is_consultation: allList?.is_consultation ? "yes" : "no",
      };
    });
  };

  const handleReset = () => {
    getAlltudio();
  };

  // handle feature submit form

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestObj = {
      plansAndCoaches: {
        workout_plan: state.workout_plan,
        diet_plan: state.diet_plan,
        trainer: state.trainer,
        nutrition: state.nutrition,
      },
      studio: {
        generalWorkout: state.generalWorkout,
        SpecialWorkout: state.SpecialWorkout,
      },
      is_consultation: state.is_consultation === "yes" ? true : false,
      feature_id: state.feature_id,
    };

    let sendRequest;

    if (!isEdit) {
      sendRequest = await axiosFetch({
        url: `/addProductFeatures/${programDetails.id}`,
        method: "post",
        requestConfig: requestObj,
      });
    } else {
      sendRequest = await axiosFetch({
        url: `/updateProgramFeature/${programDetails.id}`,
        method: "put",
        requestConfig: requestObj,
      });
    }

    if (sendRequest.status === 200) {
      toast.success(sendRequest.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });

      setState(initialValue);
      history.push("/Programs/programList");
    }
  };

  React.useEffect(() => {
    getAlltudio();
  }, [isEdit]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} style={{ padding: "0px 10px" }}>
        <Grid item xs={12} sx={{ textAlign: "end" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2a2a2a",
              marginRight: "20px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
            onClick={handleReset}
          >
            {"Reset"}
          </Button>

          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#5D9C59",
              "&:hover": {
                backgroundColor: "#5D9C59",
              },
            }}
            type="submit"
          >
            {"Save"}
          </Button>
        </Grid>
        <Grid item xs={2} sx={{ paddingLeft: "0px !important" }}>
          <Paper>
            <MenuList>
              <MenuItem
                onClick={() => activeMenu("plan")}
                selected={activeMenuName === "plan" ? true : false}
              >
                <ListItemText>{"Plants & Coaches"}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  <KeyboardArrowRightIcon />
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => activeMenu("Studio")}
                selected={activeMenuName === "Studio" ? true : false}
              >
                <ListItemText>{"Studio"}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  <KeyboardArrowRightIcon />
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => activeMenu("Consultation")}
                selected={activeMenuName === "Consultation" ? true : false}
              >
                <ListItemText>{"Consultation"}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  <KeyboardArrowRightIcon />
                </Typography>
              </MenuItem>
            </MenuList>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          {activeMenuName === "plan" && (
            <Paper sx={{ padding: "20px", width: 550, display: "flex" }}>
              <Grid item xs={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={{ width: "120px" }}>
                    {"Workout Plan"}
                  </Typography>
                  <Android12Switch
                    disabled={isView}
                    checked={state.workout_plan}
                    onChange={(e) =>
                      handleSwitch(e.target.checked, "workout_plan")
                    }
                  />
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={{ width: "120px" }}>
                    {"Workout Coach"}
                  </Typography>
                  <Android12Switch
                    disabled={isView}
                    checked={state.trainer}
                    onChange={(e) => handleSwitch(e.target.checked, "trainer")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={{ width: "120px" }}>{"Diet Plan"}</Typography>
                  <Android12Switch
                    disabled={isView}
                    checked={state.diet_plan}
                    onChange={(e) =>
                      handleSwitch(e.target.checked, "diet_plan")
                    }
                  />
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={{ width: "120px" }}>
                    {"Diet Coach"}
                  </Typography>
                  <Android12Switch
                    disabled={isView}
                    checked={state.nutrition}
                    onChange={(e) =>
                      handleSwitch(e.target.checked, "nutrition")
                    }
                  />
                </Stack>
              </Grid>
            </Paper>
          )}
          {activeMenuName === "Studio" && (
            <Paper sx={{ padding: "20px" }}>
              <Grid>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: "#000" } }}
                    value={state.value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      sx={{ padding: "0px 12px" }}
                      label={
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            checked={state.value === 0 ? true : false}
                            sx={{ "&.Mui-checked": { color: "#000" } }}
                            label="General Workout"
                          />
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {"General Workout"}
                          </Typography>
                        </div>
                      }
                    />
                    <Tab
                      sx={{ padding: "0px 12px" }}
                      label={
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            checked={state.value === 1 ? true : false}
                            sx={{ "&.Mui-checked": { color: "#000" } }}
                            label="Special Workout"
                          />
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {"Special Workout"}
                          </Typography>
                        </div>
                      }
                    />
                  </Tabs>
                </Box>
              </Grid>

              <CustomTabPanel value={state.value} index={0}>
                {!state.general_work ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>{"No data Found here..."}</Typography>
                  </div>
                ) : (
                  <Grid container spacing={2}>
                    {state?.studioType?.general_work?.type.map(
                      (subtype, index) => (
                        <Grid item xs={3} key={index}>
                          <Paper sx={{ height: "100%" }}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              sx={{
                                padding: "10px 10px !important",
                                backgroundColor: "#F4F4F4",
                              }}
                            >
                              <Typography sx={{ width: "190px" }}>
                                {subtype?.type}
                              </Typography>
                              {/* <Android12Switch
                                defaultChecked
                                disabled={isView}
                              /> */}
                            </Stack>
                            {subtype?.programs.map((name, index) => (
                              <FormControlLabel
                                disabled={isView}
                                key={index}
                                sx={{ width: "100%", margin: "0px" }}
                                onChange={(e) =>
                                  handleGeneral(e, name.program_id)
                                }
                                control={
                                  <Checkbox
                                    sx={{ "&.Mui-checked": { color: "#000" } }}
                                    label="All"
                                    checked={state?.generalWorkout.includes(
                                      name.program_id
                                    )}
                                  />
                                }
                                label={name.program_name}
                              />
                            ))}
                          </Paper>
                        </Grid>
                      )
                    )}
                  </Grid>
                )}

                {}
              </CustomTabPanel>
              <CustomTabPanel value={state.value} index={1}>
                {!state.special_work ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>{"No data Found here..."}</Typography>
                  </div>
                ) : (
                  <Grid container spacing={2}>
                    {state?.studioType?.special_work?.type.map(
                      (subtype, index) => (
                        <Grid item xs={3} key={index}>
                          <Paper sx={{ height: "100%" }}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              sx={{
                                padding: "0px 10px !important",
                                backgroundColor: "#F4F4F4",
                              }}
                            >
                              <Typography sx={{ width: "190px" }}>
                                {subtype?.type}
                              </Typography>
                              <Android12Switch
                                defaultChecked
                                disabled={isView}
                              />
                            </Stack>
                            {subtype?.programs.map((name, index) => (
                              <FormControlLabel
                                disabled={isView}
                                key={index}
                                sx={{ width: "100%", margin: "0px" }}
                                onChange={(e) =>
                                  handleSpecial(e, name.program_id)
                                }
                                control={
                                  <Checkbox
                                    sx={{ "&.Mui-checked": { color: "#000" } }}
                                    label="All"
                                  />
                                }
                                label={name.program_name}
                              />
                            ))}
                          </Paper>
                        </Grid>
                      )
                    )}
                  </Grid>
                )}
              </CustomTabPanel>
            </Paper>
          )}
          {activeMenuName === "Consultation" && (
            <Paper sx={{ padding: "20px", height: "100%" }}>
              <Grid item xs={2} sx={{ paddingLeft: "0px !important" }}>
                <Paper>
                  <FormControl sx={{ padding: "20px", height: "100%" }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={state.is_consultation}
                      name="radio-buttons-group"
                      row
                      onChange={handleradio}
                    >
                      <FormControlLabel
                        disabled={isView}
                        value="yes"
                        control={
                          <Radio
                            sx={{ "& .MuiSvgIcon-root": { color: "black" } }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={isView}
                        value="no"
                        control={
                          <Radio
                            sx={{ "& .MuiSvgIcon-root": { color: "black" } }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>
            </Paper>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default FeatureDetailsForm;

/* 


    <Grid item xs={3}>
                  <Paper sx={{ height: "100%" }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{
                        padding: "0px 10px !important",
                        backgroundColor: "#F4F4F4",
                      }}
                    >
                      <Typography sx={{ width: "190px" }}>{"Yoga"}</Typography>
                      <Android12Switch defaultChecked />
                    </Stack>
                    {yoga.map((val) => {
                      return (
                        <FormControlLabel
                          sx={{ width: "100%", margin: "0px" }}
                          control={
                            <Checkbox
                              sx={{ "&.Mui-checked": { color: "#000" } }}
                              label="All"
                            />
                          }
                          label={val}
                        />
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper sx={{ height: "100%" }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{
                        padding: "0px 10px !important",
                        backgroundColor: "#F4F4F4",
                      }}
                    >
                      <Typography sx={{ width: "190px" }}>{"Dance"}</Typography>
                      <Android12Switch defaultChecked />
                    </Stack>
                    {dance.map((val) => {
                      return (
                        <FormControlLabel
                          sx={{ width: "100%", margin: "0px" }}
                          control={
                            <Checkbox
                              sx={{ "&.Mui-checked": { color: "#000" } }}
                              label="All"
                            />
                          }
                          label={val}
                        />
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper sx={{ height: "100%" }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{
                        padding: "0px 10px !important",
                        backgroundColor: "#F4F4F4",
                      }}
                    >
                      <Typography sx={{ width: "190px" }}>{"HIIT"}</Typography>
                      <Android12Switch defaultChecked />
                    </Stack>
                    {HIIT.map((val) => {
                      return (
                        <FormControlLabel
                          sx={{ width: "100%", margin: "0px" }}
                          control={
                            <Checkbox
                              sx={{ "&.Mui-checked": { color: "#000" } }}
                              label="All"
                            />
                          }
                          label={val}
                        />
                      );
                    })}
                  </Paper>
                </Grid>
*/
