import React from "react";
import ReactDOM from "react-dom";

import Button from "../../../components/CustomButton/Button";
const Backdrop = ({ dispatch }) => {
  return (
    <div
      className={"session-backdrop"}
      onClick={() => dispatch({ type: "closeModel", payload: false })}
    ></div>
  );
};

const Delete = ({ dispatch, handleDelete }) => {
  return (
    <div className="client-delete">
      <h4>Are you sure you want to delete this ?</h4>
      <div className="delete-container">
        <Button
          outline
          type={"button"}
          onClick={() => dispatch({ type: "closeModel", payload: false })}
        >
          No
        </Button>
        <Button inline type={"button"} onClick={handleDelete}>
          Yes
        </Button>
      </div>
    </div>
  );
};

const DeleteModal = (props) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop dispatch={props.dispatch} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <Delete
          dispatch={props.dispatch}
          closeDelete={props.closeDelete}
          handleDelete={props.handleDelete}
        />,
        editTableModal
      )}
    </>
  );
};

export default DeleteModal;
