import React, { useState, useEffect, useContext } from 'react'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import axios from 'axios'
import Dialog from '../../components/Dialog'
import FollowupModal from '../../components/FollowupModal'
import { getTodayTime, getTodayTimeFormatted, returnTime, returnTimeFormatted } from '../../Helpers/DateHelpers'
import { editTableRow } from '../../Helpers/TableHelpers'
import { openFollowUpModal, closeFollowUpModal, updateFollowUp, updateFollowUpStatus, deleteFollowUp } from '../../Helpers/ModalHandler'
import { ATLAS_URI, ADMIN } from '../../Constants'
import stateContext from '../../context/StateContext'

function OldPendingFollowUps() {

    const contextState = useContext(stateContext)
    const { updateEditDetails, updateList } = contextState
    const { configToken, leadsList, usersList, sourcesList, propertyTypeList, statusList, operator } = contextState.state


    const [state, setState] = useState({
        resetNewRow: {
            id: "",
            Client: "",
            ClientContact: "",
            ClientEmail: "",
            ClientLocation: "",
            LeadStatus: "",
            Remarks: [],
            PropertyType: "",
            RequiredLocation: "",
            MinArea: "",
            MaxArea: "",
            MinBudget: "",
            MaxBudget: "",
            RequiredLoan: "",
            Source: "",
            call_status: "",
            LeadDate: "",
            LastFollowUpDate: "",
            next_followup_date: "",
            FollowUpList: [],
            CreatedBy: ""
        },
        newTableRow: {},
        tableBodyList: [
        ],
        editingActivated: false,
        editingID: "",
        isModalOpen: false,
        isFollowUpModalOpen: false,
        dialogInfo: {
            isOpened: false,
            text: "",
            iconClass: "fas fa-exclamation-triangle"
        },
    })

    const mounted = React.useRef(true);
    useEffect(() => () => { mounted.current = false; }, []);
    useEffect(() => {
        let leadsRes = leadsList;
        let userData = usersList;
        let sourcesData = sourcesList;
        let propertyTypesData = propertyTypeList;
        let statusList1 = statusList;

        if (leadsRes.length !== 0
            && sourcesData.length !== 0
            && propertyTypesData.length !== 0
            && userData.length !== 0) {

            let leadsData = leadsRes.filter(lead => lead.next_followup_date && returnTimeFormatted(lead.next_followup_date) < getTodayTimeFormatted());
           

            leadsData.map(lead => {
                const filteredUser = lead.AssignedTo ? userData.filter(user => user.id === lead.AssignedTo) : [];
                lead._AssignedTo = filteredUser.length === 0 ? "" : filteredUser[0].Username;

                let tempSources = lead.Source ? sourcesData.filter(source => source.id === lead.Source) : [];
                lead.Sources = tempSources.length !== 0 && tempSources[0].Source;

                let tempPropertyType = lead.PropertyType ? propertyTypesData.filter(propertyType => propertyType.id === lead.PropertyType) : []
                lead.PropertyTypes = tempPropertyType.length !== 0 && tempPropertyType[0].PropertyType;
                lead.FollowUpList = lead.FollowUps
                lead.LeadStatus = lead.LeadStatusID && lead.LeadStatusID.Status
                lead.LastRemarks = lead.Remarks && (lead.Remarks.length !== 0 ? lead.Remarks[lead.Remarks.length - 1].Remarks : "");

                lead.User = "Select"
                lead.Role = "Select"
                return lead;
            })

            const userID = operator.id;
            leadsData = operator.Role === ADMIN ? leadsData : leadsData.filter(lead => lead.AssignedTo === userID);

            setTimeout(() => {
                mounted.current && setState(prevState => ({
                    ...prevState,
                    tableBodyList: leadsData,
                    tableBodyDisplayList: leadsData,
                    statusList: statusList1
                }))
            }, 200)


        }
    }, []);


    function editRecord(e) {
        const temp = e.target.parentElement.parentElement.id;
        updateEditDetails({ id: temp, editingActivated: true, redirectFrom: "/Leads/oldPendingFollowUp", redirectTo: "/Leads/leadDetails" });
    }

    function openDialog(e) {
        const newDialogInfo = {
            isOpened: true,
            delID: e.target.parentElement.parentElement.id,
            text: "Are you sure you want to delete this Lead?",
            type: "Confirm"
        }
        setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
    }
    function deleteFromTable(e) {
        const delID = state.dialogInfo.delID;
        axios.delete(`${ATLAS_URI}/deleteLead/` + delID, configToken)
            .then(() => {
                const newTableBodyList = leadsList.filter(data => data.id.toString() !== delID);
                updateList('leadsList', newTableBodyList)
                setState(prevState => ({
                    ...prevState,
                    tableBodyList: newTableBodyList,
                    dialogInfo: { isOpened: false, text: "", delID: "" }
                }))
            })
            .catch(err => alert(err))
    }
    function openWhatsapp(e) {
        e.preventDefault();
        const id = e.target.parentElement.parentElement.id
        const phone = state.tableBodyList.filter(data => data.id.toString() === id)[0].ClientPhone.replace("+", "");
        window.open(`https://wa.me/${phone}`, "_blank");

    }

    return (
        <section className="content">
            <div className="row">
                <Dialog
                    onFalse={(e) => setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "" } }))}
                    onTrue={(e) => deleteFromTable(e)}
                    dialogInfo={state.dialogInfo}
                />

                <div className="col-md-12">

                    <div className="box box-primary">

                        <BoxHeader title="Old Pending Follow Ups" />

                        <div className="box-body">

                            <DataTable
                                tableHeader={["id", "Client Name", "Client Phone", "Interested In", "Last Remarks", "Sources", "Lead Date", "Next Follow Up Date", "Created By"]}
                                tableBody={state.tableBodyList}
                                searchField="ClientName"
                                deleteFromTable={deleteFromTable}
                                editTableRow={editTableRow}
                                customAction={[
                                    { title: "Whatsapp", icon: "fab fa-brand fa-whatsapp", onClickEvent: openWhatsapp },
                                    { title: "Follow", icon: "fas fa-phone-alt text-green", onClickEvent: (e) => openFollowUpModal(e, state, setState) },
                                    { title: "Update", icon: "fas fa-edit", redirectTo: "/Leads/leadDetails", onClickEvent: editRecord },
                                    { title: "Delete", icon: "fas fa-times text-red", onClickEvent: openDialog }
                                ]}


                            />

                        </div>
                    </div>

                </div>
            </div>

            {state.isFollowUpModalOpen &&
                <FollowupModal
                    state={state}
                    closeFollowUpModal={(e) => closeFollowUpModal(setState)}
                    updateFollowUp={(e) => updateFollowUp(e, contextState, state, setState)}
                    deleteFollowUp={(e) => deleteFollowUp(e, contextState, state, setState)}
                    updateFollowUpStatus={(e) => updateFollowUpStatus(e, contextState, state, setState)}
                />
            }


        </section>
    )


}

export default OldPendingFollowUps