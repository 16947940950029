import React, { useState, useEffect, useContext } from "react";
import {
  deleteFromTable,
  insertIntoTable,
  editTableRow,
  openDialog,
} from "../../Helpers/TableHelpers";
import axios from "axios";
import BoxHeader from "../../components/BoxHeader";
import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import * as $ from "jquery";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";

function ManageRoles() {
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const [state, setState] = useState({
    resetNewRow: {
      id: "",
      Role: "",
      Pages: [],
      PageID: [],
      roleFeatureData: "",
    },
    newTableRow: {
      id: "",
      Role: "",
      Pages: [],
      PageID: [],
      // roleFeatureData:[],
    },
    newRoleTable: {
      id: "",
      Role: "",
      Pages: [],
      PageID: [],
      roleFeatureData: [],
    },
    newValue: [],
    newValue1: [],

    tableBodyList: [],
    tableEditList: [],
    checkPages: [],
    // checkPages: [
    // { id: 1, Page: "Dashboard" },
    // { id: 2, Page: "Clients" },
    // { id: 3, Page: "Agents" },
    // { id: 4, Page: "Projects" },
    // { id: 5, Page: "Properties" },
    // { id: 6, Page: "Leads" },
    // { id: 7, Page: "Assigned Leads Calls" },
    // { id: 8, Page: "Assigned Leads Visits" },
    // { id: 10, Page: "Agreement" },
    // { id: 11, Page: "Masters" },
    // { id: 12, Page: "Notifications" },
    // { id: 13, Page: "Settings" },
    // { id: 14, Page: "Support" },
    // { id: 15, Page: "Configurations" },
    // { id: 16, Page: "User Activity" }],
    //             { id: 1,  Page: "Dashboard" },
    //             { id: 2,  Page: "All Lead" },
    //             { id: 3,  Page: "Lead Allocation" },
    //             { id: 4,  Page: "My Leads" },
    //             { id: 5,  Page: "Client Details" },
    //             { id: 6,  Page: "Client Allocation" },
    //             { id: 7,  Page: "My Calender" },
    //             { id: 8,  Page: "Program List" },
    //             { id: 9,  Page: "Add New Program" },
    //             { id: 10,  Page: "Approve adding New Program" },
    //             { id: 11,  Page: "My Program" },
    //             { id: 12,  Page: "Payment Details" },
    //             { id: 13,  Page: "Payment History" },
    //             { id: 14,  Page: "My Team" },
    //             { id: 15,  Page: "Add Team Manager" },
    //             { id: 16,  Page: "Approve Adding team Manager" },
    //             { id: 17,  Page: "My Coaches" },
    //             { id: 18,  Page: "Diet Chart" },
    //             { id: 19,  Page: "Workout Chart" },
    //             { id: 20,  Page: "Receipes" },
    //             { id: 21,  Page: "Food DB" },
    //             { id: 22,  Page: "Ingredient DB" },
    //             { id: 23,  Page: "Diet Templates" },
    //             { id: 24,  Page: "Calorie Burn Calculator" },
    //             { id: 25,  Page: "Workout Template" },
    //             { id: 26,  Page: "Workout DB" },
    //             { id: 27,  Page: "Progress" },
    //             { id: 28,  Page: "Activity Tracking" },
    //             { id: 29,  Page: "Compliance" },
    //             { id: 30,  Page: "Terms and Condition" },
    //             { id: 31,  Page: "Awards" }
    // ],

    editingActivated: false,
    editingID: "",
    APIs: {
      // AddData: "addRole",
      AddData: "addRoleWithFeature",

      UpdateData: "updateRoleWithFeature",
      DeleteData: "deleteRole",
    },
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
  });

  function changeHandler(e) {
    // alert(e.target.value)
    let newValue;

    newValue = e.target.value;
    if (typeof $(e.target).attr("index") !== "undefined") {
      newValue = [...state.newTableRow[e.target.name]];
      newValue[$(e.target).attr("index")] = e.target.value;
    }

    if (e.target.type === "checkbox") {
      newValue = [...state.newTableRow[e.target.name]];

      if (e.target.checked) {
        // alert('hi')
        newValue.push(e.target.value);

        //if lead allocation permission given,all leads permission checked
        if (e.target.value == 4) {
          newValue.push("2");
        }

        //if any child feature of clients permission given,List clients permission checked
        if (
          e.target.value == 34 ||
          e.target.value == 8 ||
          e.target.value == 42 ||
          e.target.value == 43
        ) {
          newValue.push("6");
        }
      } else {
        newValue = newValue.filter((data) => {
          return data !== e.target.value;
        });
      }
    }

    setState((prevState) => ({
      ...prevState,
      newTableRow: {
        ...state.newTableRow,
        [e.target.name]: newValue,
      },
      newValue: newValue,
    }));
  }
  // console.log(state.newTableRow.roleFeatureData.filter(data=>{return data}),'new Table data checking');

  //  start- changes
  function handleChange(e) {
    let newValue1;
    newValue1 = e.target.value;
    if (typeof $(e.target).attr("index") !== "undefined") {
      newValue1 = [...state.newRoleTable[e.target.name]];
      newValue1[$(e.target).attr("index")] = e.target.value;
    }

    if (e.target.type === "checkbox") {
      newValue1 = [...state.newRoleTable[e.target.name]];
      if (e.target.checked) {
        newValue1.push(e.target.value);
      } else {
        newValue1 = newValue1.filter((data) => data !== e.target.value);
      }
    }

    setState((prevState) => ({
      ...prevState,
      newRoleTable: {
        ...state.newRoleTable,
        [e.target.name]: newValue1,
      },
      newValue1: newValue1,
    }));
  }

  // End

  const mounted = React.useRef(true);
  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  useEffect(() => {
    axios
      .get(`${ATLAS_URI}/getFeatureDetails`, configToken)
      .then((response) => {
        axios
          .get(`${ATLAS_URI}/getRolesWithPages`, configToken)
          .then((response1) => {
            const responseData = response1.data;
            if (typeof responseData !== "undefined" && mounted.current) {
              const viewFeatures = [];
              const editFeatures = [];
              responseData.map((data) => {
                viewFeatures.push({
                  id: data.id,
                  Role: data.Role,
                  Pages: data.Pages.filter((data) => data.is_view == true).map(
                    (page) => {
                      return page.sub_feature_info.sub_feature_name;
                    }
                  ),
                  PageID: data.PageID.filter((page) => {
                    return page.is_view == true;
                  }).map((fe) => fe.sub_feature_id.toString()),
                });
                editFeatures.push({
                  id: data.id,
                  Role: data.Role,
                  Pages: data.Pages.map((page) => {
                    return page;
                  }),
                  PageID: data.PageID.filter((page) => {
                    return page.is_edit == true;
                  }).map((fe) => fe.sub_feature_id.toString()),
                });
              });

              setState((prevState) => ({
                ...prevState,
                newTableRow: state.resetNewRow,
                tableBodyList: viewFeatures,
                tableEditList: editFeatures,
                checkPages: response.data.flat(Infinity),
              }));
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err, "Error"));
  }, []);

  return (
    <section className="content">
      <div className="row">
        {typeof state.dialogInfo !== "undefined" && (
          <Dialog
            onFalse={(e) =>
              setState((prevState) => ({
                ...prevState,
                dialogInfo: { isOpened: false, text: "" },
              }))
            }
            onTrue={(e) => deleteFromTable(contextState, state, setState)}
            dialogInfo={state.dialogInfo}
          />
        )}
        <div className="col-md-5">
          <div className="box box-primary">
            <BoxHeader
              title={`${state.editingActivated ? "Edit" : "Add"} Role`}
            />

            <form
              onSubmit={(e) =>
                insertIntoTable(e, contextState, state, setState)
              }
              autoComplete="off"
            >
              <div className="box-body bozero">
                <input type="hidden" name="ci_csrf_token" value="" />

                <div className="form-group">
                  <label>Role </label>
                  <small className="req"> *</small>
                  <input
                    name="Role"
                    value={state.newTableRow.Role}
                    onChange={changeHandler}
                    required
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <table className="table">
                    <thead>
                      <tr>
                        {" "}
                        <td>Page Accessibility</td>
                        <td>View</td>
                        <td>Edit</td>
                      </tr>
                    </thead>

                    <tbody>
                      {/* <div className="section_checkbox"> */}
                      {typeof state.checkPages !== "undefined" &&
                        typeof state.newTableRow.Pages !== "undefined" &&
                        state.checkPages.map((page, id) => (
                          <>
                            <tr
                              className="bg-light"
                              style={{ fontWeight: "bold" }}
                            >
                              <td>
                                <div>{page.feature_name}</div>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            {page.sub_features.map((subF, index) => (
                              <tr>
                                <td>
                                  <div className="checkbox" key={id}>
                                    {subF.sub_feature_name}
                                  </div>
                                </td>

                                <td>
                                  <div className="checkbox" key={id}>
                                    <input
                                      type="checkbox"
                                      name="PageID"
                                      value={subF.id}
                                      disabled={subF.id == 84}
                                      checked={
                                        state.newTableRow.PageID.includes(
                                          subF.id && subF.id.toString()
                                        ) ||
                                        //to check the boxed if the child features are selected
                                        (subF.id === 2 &&
                                          state.newTableRow.PageID.some(
                                            (id) => id == 4 || id == 84
                                          )) ||
                                        (subF.id === 6 &&
                                          state.newTableRow.PageID.some(
                                            (id) =>
                                              id == 8 ||
                                              id == 42 ||
                                              id == 43 ||
                                              id == 34
                                          ))
                                      }
                                      onChange={changeHandler}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className="checkbox" key={id}>
                                    {/* <input type="checkbox" name="PageID" value={id}
                                               checked={state.newTableRow.PageID.includes((id.toString()))}
                                               required={state.newTableRow.PageID.length === 0}
                                               onChange={changeHandler} />  */}
                                    <input
                                      type="checkbox"
                                      name="PageID"
                                      value={subF.id}
                                      checked={state.newRoleTable.PageID.includes(
                                        subF.id && subF.id.toString()
                                      )}
                                      disabled={page.id === 45}
                                      //  required={state.newRoleTable.PageID.length === 0}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-info pull-right ">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="col-md-7">
          <div className="box box-primary">
            <BoxHeader title="List of Added Roles" />

            <div className="box-body">
              <DataTable
                tableHeader={["id", "Role"]}
                searchField="Role"
                tableBody={state.tableBodyList}
                deleteFromTable={(e) => openDialog(e, state, setState)}
                editTableRow={(e) => editTableRow(e, state, setState)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ManageRoles;
