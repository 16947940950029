import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";

const RadioButtonGroup = ({
  isRequired,
  control,
  disabled,
  options,
  name,
  row = true,
}) => {
  return (
    <Controller
      name={name}
      rules={{ required: isRequired ? "required" : false }}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset" error={!!error} disabled={disabled}>
          <RadioGroup {...field} row={row}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    sx={{
                      color: "#2a2a2a",
                      "&.Mui-checked": {
                        color: "#2a2a2a",
                      },

                      "& .MuiSvgIcon-root": {
                        fontSize: 24,
                      },
                    }}
                  />
                }
                label={option.label}
                disabled={disabled}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontWeight: "bold",
                    color: "black",
                  },
                }}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default RadioButtonGroup;
