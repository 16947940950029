import axios from "axios";
import { ATLAS_URI } from "../../src/Constants";

var configHeader = {};

export const axiosFetch = async (configObj) => {
  var userDetails =
    localStorage.getItem("operator") !== null
      ? localStorage.getItem("operator")
      : null;

  var token =
    userDetails !== null && JSON.parse(userDetails).token !== null
      ? JSON.parse(userDetails).token
      : "";
  if (token) {
    configHeader = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    configHeader = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    };
  }
  const ConfigAxios = axios.create({
    baseURL: ATLAS_URI,
    headers: configHeader,
  });

  const { method, url, requestConfig = {} } = configObj;
  try {
    const res = await ConfigAxios[method.toLowerCase()](url, requestConfig);
    return res;
  } catch (err) {
    return err.response;
  }
};
