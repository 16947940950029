import React, { useContext, useEffect, useState } from "react";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import ClientDietPlanView from "./ClientDietPlanView copy";

function PopupDietPlanView(props) {
  const [show, setShow] = useState(props.show1);

  const [dietPlan, setDietPlan] = useState([]);
  const [clientDetails, setClientDetails] = useState(null);
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [age, setAge] = useState("");
  const [weight_unit, setWeightUnit] = useState("");
  let [current_medical_condition, setCurrent_medical_condition] = useState([]);
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;
  const [height_unit, setHeight_unit] = useState("");

  const handleClose = () => {
    props.setShow1(false);
  };

  const getDetails = () => {
    axios
      .get(`${ATLAS_URI}/getDietPlanByPlanId/${props.planId}`, configToken)
      .then((res) => {
        if (res.status == 200) {
          setClientDetails(res.data?.dietPlan);
          setDietPlan(res.data.dietPlanDetails);
          setHeight(res.data?.dietPlan?.Lead?.height);
          setWeight(res.data?.dietPlan?.Lead?.current_weight);
          setAge(res.data?.dietPlan?.Lead?.age);
          setWeightUnit(
            res.data?.dietPlan?.Lead?.weight_unit === 0 ? "Kg" : "gm"
          );
          setHeight_unit(
            res.data?.dietPlan?.Lead?.weight_unit === 0 ? "cm" : "ft in"
          );
          setCurrent_medical_condition(
            res.data?.dietPlan?.Lead?.current_medical_condition
          );
        }
      });
  };
  useEffect(() => {
    props.show1 && setShow(true);
    getDetails();
  }, [props.show1]);

  const handleShow = () => setShow(props.show1);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Diet Plan Details</Modal.Title> */}
          <GrClose
            style={{
              backgroundColor: isHovering ? "#FF0000" : "",
              color: isHovering ? "#fff" : "",
              border: isHovering ? "1px solid" : "",
              height: isHovering ? "30px" : "",
              width: isHovering ? "30px" : "",
              opacity: isHovering ? "0.7" : "",
              marginLeft: "97%",
            }}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <ClientDietPlanView
            dietPlanInfo={dietPlan}
            clientDetails={clientDetails}
            age={age}
            height={height}
            weight={weight}
            weight_unit={weight_unit}
            height_unit={height_unit}
            current_medical_condition={current_medical_condition}
          />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default PopupDietPlanView;
