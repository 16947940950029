import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormControlLabel, RadioGroup, Radio, FormLabel } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";

const ControlledRadioGroup = ({
  name,
  control,
  label,
  error,
  rules,
  options,
  fullWidth,
  helperText,
  defaultValue,
  row,
  isDisableColumn = false,
}) => {
  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <RadioGroup
            aria-label="gender"
            defaultValue={defaultValue}
            row={row}
            {...field}
            onClick={(event, value) => field.onChange(value)}
            value={field.value}
          >
            {options.map((option, index) => (
              <FormControlLabel
                disabled={isDisableColumn}
                value={option.value}
                control={<Radio disabled={option.disabled} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        )}
      />
      {/* <FormHelperText >{error ? helperText : " "}</FormHelperText> */}
    </FormControl>
  );
};
export default ControlledRadioGroup;
