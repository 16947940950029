import { Fragment, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useLocation } from "react-router-dom";
import Modal from "../../../components/Modal/Modal";

const style = {
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
    width: "98%",
    margin: "0 auto",
    marginTop: "50px ",
  },
  cell: {
    display: "flex",
    gap: "10px",
  },
  img: {
    width: "50px",
    height: "50px",
  },
};

const table3Column = [
  "Date",
  "Meal 1",
  "Meal 2",
  "Meal 3",
  "Meal 4",
  "Meal 5",
  "Meal 6",
];

const MealPlate = () => {
  const location = useLocation();
  const leadId = location.pathname.split("/").pop();
  const [mealList, setMealList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isOpen, setIsopen] = useState(false);
  const [openImage, setOpenImage] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImageOpen = (image) => {
    setOpenImage(image);
    setIsopen(true);
  };

  const handleClose = () => {
    setIsopen(false);
  };

  const getMealList = async () => {
    const request = await axiosFetch({
      url: `/v3/getMealPlateImageByLeadId?leadId=${leadId}&perPage=${rowsPerPage}&page=${
        page + 1
      }`,
      method: "get",
    });

    setMealList(request.data.body);
  };

  useEffect(() => {
    getMealList();
  }, [page, rowsPerPage]);

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <TableContainer component={Paper} sx={style.table}>
        <Table>
          <TableHead>
            <TableRow>
              {table3Column.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mealList?.mealPlate?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {table3Column.map((column, columnIndex) => (
                  <Fragment key={columnIndex}>
                    <TableCell style={{ verticalAlign: "top" }}>
                      <Box>
                        <Box sx={{ ...style.cell, width: "100px" }}>
                          {column === "Date" && row.date}
                        </Box>
                        {column !== "Date" && row.meals[columnIndex - 1] && (
                          <Box
                            sx={{
                              ...style.cell,
                              width: "200px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Box
                              sx={{
                                ...style.cell,
                              }}
                            >
                              {row.meals[columnIndex - 1].img.map(
                                (image, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={image}
                                    alt={`meal-${image}-${imgIndex}`}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() => handleImageOpen(image)}
                                  />
                                )
                              )}
                            </Box>

                            <Typography
                              sx={{ marginTop: "10px", fontWeight: "600" }}
                              component={"div"}
                            >
                              {row.meals[columnIndex - 1].name}:
                            </Typography>
                            {row.meals[columnIndex - 1].mealNames?.map(
                              (value, index) => (
                                <Typography key={index}>
                                  {value}
                                  {index <
                                    row.meals[columnIndex - 1].mealNames
                                      .length -
                                      1 && ", "}
                                </Typography>
                              )
                            )}
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {mealList?.mealPlate?.length === 0 && (
          <Paper
            sx={{
              mt: "10px",
              p: "20px",
              textAlign: "center",
            }}
          >
            No Meals Available...
          </Paper>
        )}
      </TableContainer>

      <TablePagination
        component="div"
        count={mealList?.count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // rowsPerPageOptions={[2, 4, 10, 25, 50, 100]}
      />
      <Modal isOpen={isOpen} onClose={handleClose}>
        <Box sx={{ width: "100%" }}>
          <img src={openImage} alt="meal_image" style={{ width: "100%" }} />
        </Box>
      </Modal>
    </Box>
  );
};

export default MealPlate;
