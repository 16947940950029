import { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
  },
});

export default function AppointmentsTable() {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [appointmentList, setAppointmentList] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAppointmentsOfTheDay = () => {
    axiosFetch({
      url: "/getFollowupsFromToday",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setAppointmentList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error(res.data, { position: toast.POSITION.TOP_CENTER });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAppointmentsOfTheDay();
  }, []);
  return (
    <div style={{ margin: "29px" }}>
      <br />
      <Typography
        sx={{
          color: "#2D2D2D",
          fontFamily: "Poppins",
          fontWeight: "600",
          Size: "18px",
        }}
      >
        Appointments
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
            <TableRow>
              <TableCell align="left">
                <b>Lead/Client ID</b>
              </TableCell>
              <TableCell align="left">
                <b>Lead/Client Name</b>
              </TableCell>
              <TableCell align="left">
                <b>Phone NO</b>
              </TableCell>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell align="left">
                <b>Time</b>
              </TableCell>
              <TableCell align="left">
                <b>Duration</b>
              </TableCell>
              <TableCell align="left">
                <b>Recording</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentList.length !== 0 &&
              appointmentList.map((row) => (
                <TableRow key={row.name} sx={{ color: "#6B6B6B" }}>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {row.LeadId}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {row.LeadInfo.name}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {row.LeadInfo.contact_number}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ color: "#6B6B6B" }}
                  >
                    {row.NextFollowUpDate !== null
                      ? new Date(row.NextFollowUpDate).toLocaleDateString()
                      : "-"}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {row.NextFollowUpDate !== null
                      ? new Date(row.NextFollowUpDate).toLocaleTimeString()
                      : "-"}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    {row.duration}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                    -
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        component="div"
        count={100}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </div>
  );
}
