import React, { useState } from "react";
import "./LiveSession.css";

import LiveSessionTabs from "./components/LiveSessionTabs";
import LiveSessionTab from "./components/LiveSessionTab";
import LiveSessionDashboard from "./components/LiveSessionDashboard";
import Card from "../../components/Card/Card";
import { Box } from "@mui/material";
const LiveSession = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Card>
      <Box sx={{ minHeight: "100vh" }}>
        <LiveSessionTabs className={"tab-container"}>
          <LiveSessionTab
            name="Upcoming"
            num={0}
            onActiveTab={setActiveTab}
            activeTab={activeTab}
          />
          <LiveSessionTab
            name="Completed"
            num={1}
            onActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </LiveSessionTabs>
        <LiveSessionDashboard activeTab={activeTab} />
      </Box>
    </Card>
  );
};

export default LiveSession;
