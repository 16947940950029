import React, { useRef, duration, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useState } from "react";

import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";

import { axiosFetch } from "../../../../Utlis/AxiosFetch";

const Category = ({
  category,
  onChangeCategory = () => {},
  addLinkSchedule = () => {},
  categoryId,
  categoryInstance,
  parentIndex,
  categoryList,
  schedule,
}) => {
  const [intenstiy, setIntenstiy] = useState("");
  const editEvent = useSelector((state) => state.workoutPlan.editEvent);
  const [exerciseList, setExerciseList] = useState([]);
  const token = JSON.parse(window.localStorage.getItem("operator")).Role;

  useEffect(() => {
    if (editEvent.plan_type === "custom") {
      let categoryObj;
      categoryList.map((categoryList) => {
        if (categoryList.name === schedule.categoryType) {
          categoryObj = categoryList;
        }
      });

      if (categoryObj) {
        fetchExerciseList(category.intensity);
      }

      async function fetchExerciseList(value) {
        let response = await axiosFetch({
          url: `getExerciseByCategoryAndIntensity?intensity=${value}&category=${categoryObj.id}&userId=${token}`,
          method: "get",
        });

        setExerciseList(response.data);
      }
    }
  }, [editEvent, categoryList, intenstiy]);

  const handleCheckbox = (e) => {
    onChangeCategory(parentIndex, category.id, "checkbox", e.target.checked);
  };

  const handleRestChange = (e) => {
    onChangeCategory(parentIndex, category.id, "rest", e.target.value);
  };

  const getExerciseList = async (value) => {
    if (categoryId) {
      const response = await axiosFetch({
        url: `getExerciseByCategoryAndIntensity?intensity=${value}&category=${categoryId}&userId=${token}`,
        method: "get",
      });

      setExerciseList(response.data);
    }
  };
  const [selectedLevel, setSelectedLevel] = useState("");
  const handleChange = (e) => {
    onChangeCategory(parentIndex, category.id, e.target.name, e.target.value);
    if (e.target.name === "noOfReps") {
      let calor = e.target.value;
      const inputValues = calor.replace(/^0+/, "");
      const numericValues = parseInt(inputValues, 10) || 0;
      if (numericValues >= 1 && numericValues <= 99) {
        onChangeCategory(
          parentIndex,
          category.id,
          "noOfReps",
          // parseInt(e.target.value)
          numericValues
          // x
        );
      } else {
        onChangeCategory(
          parentIndex,
          category.id,
          "noOfReps",
          // parseInt(e.target.value)
          0
          // x
        );
      }
    }
    if (e.target.name === "noOfSets") {
      let calor = e.target.value;
      const inputValues = calor.replace(/^0+/, "");
      const numericValues = parseInt(inputValues, 10) || 0;
      if (numericValues >= 1 && numericValues <= 99) {
        onChangeCategory(
          parentIndex,
          category.id,
          "noOfSets",
          // parseInt(e.target.value)
          numericValues
          // x
        );
      } else {
        onChangeCategory(
          parentIndex,
          category.id,
          "noOfSets",
          // parseInt(e.target.value)
          0
          // x
        );
      }
    }
    if (e.target.name === "intensity") {
      setSelectedLevel(e.target.value);
      getExerciseList(e.target.value);
    }
    if (e.target.name === "exerciseName") {
      let link;
      exerciseList.map((value) => {
        if (value.name === e.target.value) {
          link = value.link;
        }
      });
      addLinkSchedule(parentIndex, category.id, "link", link);
    }

    if (e.target.name === "intensity") {
      setIntenstiy(e.target.value);
    }
    if (e.target.name === "notes") {
      onChangeCategory(parentIndex, category.id, "notes", e.target.value);
    }
  };

  const handleDurationChange = (e) => {
    var input = e.target;
    var inputValue = input.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    if (inputValue.length >= 2) {
      // Insert colon after hours
      inputValue = inputValue.substring(0, 2) + ":" + inputValue.substring(2);
      if (parseInt(inputValue.substring(0, 2)) > 12) {
        inputValue = "12" + inputValue.substring(2);
      }
    }

    if (inputValue.length >= 5) {
      // Insert colon after minutes
      inputValue = inputValue.substring(0, 5) + ":" + inputValue.substring(5);
      if (parseInt(inputValue.substring(3, 5)) > 59) {
        inputValue =
          inputValue.substring(0, 3) + "59" + inputValue.substring(5);
      }
    }

    if (inputValue.length >= 8) {
      // Insert colon after seconds
      inputValue = inputValue.substring(0, 8);
      if (parseInt(inputValue.substring(6, 8)) > 59) {
        inputValue = inputValue.substring(0, 6) + "59";
      }
    }

    // Update input value
    input.value = inputValue;

    if (!/^\d{0,2}:\d{0,2}:\d{0,2}$/.test(input.value)) {
      input.setCustomValidity("Invalid time format. Please use HH:mm:ss.");
    } else {
      input.setCustomValidity("");
    }
    onChangeCategory(parentIndex, category.id, "duration", e.target.value);
  };
  const handleChangeLevel = (e) => {
    onChangeCategory(parentIndex, category.id, "level", e.target.value);
    handleDropdownChange(e);
  };
  const handleChangeSubLevel = (e) => {
    onChangeCategory(parentIndex, category.id, "subLevel", e.target.value);
    onChangeCategory(
      parentIndex,
      category.id,
      "textLevel",
      levelDropdownData?.[`${selectedOption}`]?.[`${e.target.value}`]
    );
    setLevel(e.target.value);
  };

  // useEffect(() => {
  //   if (subLevelRef.current) {
  //     onChangeCategory(
  //       parentIndex,
  //       category.id,
  //       "subLevel",
  //       subLevelRef.current.value
  //     );
  //   }
  // }, [subLevelRef]);
  const levelDropdownData = {
    Beginner: {
      level1: "low",
      level2: "Low",
      level3: "moderate",
    },
    Intermediate: {
      level1: "moderate",
      level2: "high",
    },
    Advanced: {
      Level1: "High",
      level2: "high",
    },
  };
  const [selectedOption, setSelectedOption] = useState("Beginner");

  const [subOptions, setSubOptions] = useState([]);
  // Object.keys(levelDropdownData["Beginner"])
  const handleDropdownChange = (event) => {
    setSubOptions([]);
    const selectedValue = event.target.value;

    setSelectedOption(selectedValue);
    setSubOptions(Object.keys(levelDropdownData[selectedValue]));
  };

  // const [selectedSubLevel, setSubLevel] = useState("");
  const [level, setLevel] = useState("");

  // function FindValue(matrix, level) {
  //   if (selectedLevel) {
  //     let selectedIntensity = matrix.find(
  //       (item) => item.name === selectedLevel
  //     );
  //     let selectedSublevel = selectedIntensity["level"][level];
  //     setSubLevel(selectedSublevel);
  //   }
  // }
  return (
    <>
      <Row className="mb-4 row-3">
        <Col sm={1} id="checkbox-wp">
          <div>
            <input
              className="repeat"
              type="checkbox"
              checked={category.checkbox}
              onChange={handleCheckbox}
            />
          </div>
        </Col>
        <Col style={{ width: "20%" }} className="select-box-con ">
          <div className="custom-select-con">
            <label>Intensity</label>
            <select
              id="intensity-name"
              name="intensity"
              value={category.intensity}
              //   value={category.intensity || ""}
              onChange={handleChange}
            >
              <option defaultValue="">Select</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </div>
        </Col>
        <Col style={{ width: "20%" }} className="select-box-con">
          <label>Level</label>

          <select
            name="level"
            onChange={handleChangeLevel}
            value={category.level}
            // value={selectedOption}
          >
            <option value="">Select</option>
            {Object.keys(levelDropdownData).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </Col>
        <Col id="wp-sub-level" className="exercise-col" sm={2}>
          <label>Sub Level</label>

          <select
            id="subLevel1"
            name="subLevel"
            value={category.subLevel}
            onChange={handleChangeSubLevel}
          >
            <option value="">Select</option>
            {Object.keys(levelDropdownData[selectedOption]).map((subOption) => (
              <option key={subOption} value={subOption}>
                {subOption}
              </option>
            ))}
          </select>
        </Col>

        <Col style={{ width: "20%" }} className="select-box-con ">
          <label style={{ visibility: "hidden" }}>sub-level</label>
          <input
            // id="subLevel1"
            name="textLevel"
            type="text"
            // ref={level}
            value={
              levelDropdownData?.[`${selectedOption}`]?.[`${level}`] ||
              category.textLevel
            }
            disabled
          />
        </Col>
        <Col id="wpExerciseName" className="select-box-con">
          <label>ExerciseName</label>
          <select
            id="exercise-name"
            name="exerciseName"
            value={category.exerciseName}
            // value={exerciseName}
            onChange={handleChange}
          >
            <option defaultValue="">Select</option>
            {exerciseList.length > 0 &&
              exerciseList.map((item) => (
                <option value={item.name} key={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </Col>
        <Col style={{ width: "20%" }} className="custom-rep">
          <label>No-Reps</label>
          <input
            type="number"
            className="custom-input"
            placeholder="No of Reps"
            name="noOfReps"
            min={0}
            value={category.noOfReps || ""}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (
                e.key === "+" ||
                e.key === "-" ||
                e.key === "." ||
                e.key === "e"
              ) {
                e.preventDefault();
              }
            }}
          />
        </Col>
        <Col style={{ width: "20%" }} className="custom-rep">
          <label>No-Sets</label>
          <input
            type="number"
            className="custom-input"
            name="noOfSets"
            placeholder="No of sets"
            min={0}
            value={category.noOfSets || ""}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (
                e.key === "+" ||
                e.key === "-" ||
                e.key === "." ||
                e.key === "e"
              ) {
                e.preventDefault();
              }
            }}
          />
        </Col>
        <Col style={{ width: "20%" }} className="live-duration">
          <label>Duration</label>
          {/* <input
            className="vmax-duration"
            type="time"
            step="1"
            value={category.duration}
            placeholder="duration"
            onChange={handleDurationChange}
          /> */}
          <input
            type="text"
            id="timeInput"
            maxlength="8"
            pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
            placeholder="hh:mm:ss"
            onChange={handleDurationChange}
            value={category.duration}
            step="1"
          />

          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DesktopTimePicker"]}>
              <DemoItem>
                <DesktopTimePicker
                  label=""
                  value={category.duration || null}
                  onChange={(e) => handleDurationChange(e)}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider> */}
        </Col>
        <Col className="live-duration">
          <label>Notes</label>
          <textarea
            style={{
              borderRadius: "5px",
              borderColor: "#d0d0d0",
              padding: "1px 8px",
              height: "47%",
            }}
            id="notes"
            placeholder="Enter Notes"
            name="notes"
            onChange={handleChange}
            value={category.notes}
          />
        </Col>
      </Row>
      {category.showRest && (
        <Row className="mb-3 custom-rest">
          <Col sm={1} id="checkbox-wp-rest">
            <div>
              <input
                className="repeat"
                type="checkbox"
                checked={category.checkbox}
                onChange={handleCheckbox}
              />
            </div>
          </Col>
          <Col sm={3} className="custom-icon-rest">
            <button type="button">
              <AirlineSeatReclineNormalIcon />
            </button>
          </Col>
          <Col sm={2} id="rest-wp-custom" className="p-0">
            <button type="button" className="rest-label">
              Rest
            </button>
          </Col>
          <Col sm={2} className="p-0 rest-input">
            <input
              type="number"
              min="0"
              onKeyPress={(e) => {
                if (e.key === "+" || e.key === "-") {
                  e.preventDefault();
                }
              }}
              value={category.rest}
              placeholder="In sec"
              onChange={handleRestChange}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Category;
