import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";

import ProgramList from "./ProgramList";
import ProgramTask from "./ProgramTask";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProgramDashborad = ({ lead_id }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={3} sx={{ padding: "20px", minHeight: "100vh" }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#000" },
              "& .MuiTab-root": {
                "&.Mui-selected": {
                  color: "#000",
                },
              },
            }}
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                color: "#000",
              }}
              label="Programs"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                color: "#000",
              }}
              label="ProgramTask"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ProgramList lead_id={lead_id} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ProgramTask />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default ProgramDashborad;
