import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import BoxHeader from '../../components/BoxHeader'
import Dialog from '../../components/Dialog'
import { ATLAS_URI } from '../../Constants'
import { changeHandler as oldChangeHandler } from '../../Helpers/ChangeHandler'
import stateContext from '../../context/StateContext'

function AddAgent(props) {
    const contextState = useContext(stateContext)
    let { EditDetailsData, configToken } = contextState.state
    const { redirectFromEditDetails, resetEditID } = contextState

    const [state, setState] = useState({
        resetNewRow: {
            Name: "",
            Phone: "",
            Email: "",
            DOB: "",
            PAN: "",
            Address: "",
            City: "",
            State: "",
            PINCode: "",
            DealsIn: ""
        },
        newTableRow: {
            Name: "",
            Phone: "",
            Email: "",
            DOB: "",
            PAN: "",
            Address: "",
            City: "",
            State: "",
            PINCode: "",
            DealsIn: ""
        },
        tableBodyList: [

        ],
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        },
        editingActivated: false,
        editingID: "",

        //Temporary
        sourceList: ["Source 1", "Source 2", "Source 3"]

    })

    useEffect(() => {

        if (!resetEditID("/Agents/addAgent")) {
            axios.get(`${ATLAS_URI}/getAgentByID/${EditDetailsData.id}`, configToken).then(response => {
                const responseData = response.data;
                if (typeof responseData !== 'undefined' && responseData !== null) {
                    setState(prevState => ({ ...prevState, newTableRow: responseData, editingActivated: true }));
                }
            }).catch(err => console.log(err))
        } else EditDetailsData = {}

    }, []);

    function changeHandler(e) {
        oldChangeHandler(e, state, setState);
    }
    const addNewAgent = (e) => {
        e.preventDefault();
        if (!state.editingActivated) {

            axios.post(`${ATLAS_URI}/addAgent`, state.newTableRow, configToken)
                .then(response => {

                    const newDialogInfo = { isOpened: true, text: "Agent Added Successfully!!", type: "Success" }
                    setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo, newTableRow: state.resetNewRow }))
                    setTimeout(() => {
                        setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" }, newTableRow: state.resetNewRow }))
                    }, 3000)

                })
                .catch(err => alert(err))
        } else {
            axios.post(`${ATLAS_URI}/updateAgent/` + EditDetailsData.id, state.newTableRow, configToken).then(() => {
                redirectFromEditDetails(EditDetailsData.redirectFrom)
            }).catch(err => alert(err))
        }

    }


    return (
        <section className="content">
            <div className="row">
                {state.dialogInfo.isOpened && <Dialog
                    onClose={(e) => setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } }))}
                    dialogInfo={state.dialogInfo}
                />}
                <div className="col-md-12">
                    <div className="box box-primary">
                        <BoxHeader title={`${state.editingActivated ? "Edit" : "Add"} Agent`} />
                        <form onSubmit={addNewAgent}>
                            <div className="box-body bozero mx5p">
                                <input type="hidden" name="ci_csrf_token" value="" />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Agent Name</label> <small className="req"> *</small>
                                            <input name="Name" type="text" className="form-control" required value={state.newTableRow.Name} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Agent Phone</label> <small className="req"> *</small>
                                            <input name="Phone" type="text" className="form-control" required value={state.newTableRow.Phone} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Agent Email</label> <small className="req"> *</small>
                                            <input name="Email" type="email" className="form-control" required value={state.newTableRow.Email} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>DOB</label> <small className="req"> *</small>
                                            <input name="DOB" type="date" className="form-control" required value={state.newTableRow.DOB} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>PAN</label> <small className="req"> *</small>
                                            <input name="PAN" type="text" className="form-control" required value={state.newTableRow.PAN} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Deals In</label> <small className="req"> *</small>
                                            <input name="DealsIn" type="text" className="form-control" required value={state.newTableRow.DealsIn} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <textarea name="Address" type="text" className="form-control" value={state.newTableRow.Address} onChange={changeHandler} rows="3"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>City</label> <small className="req"> *</small>
                                            <input name="City" type="text" className="form-control" required value={state.newTableRow.City} onChange={changeHandler} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>State</label> <small className="req"> *</small>
                                            <input name="State" type="text" className="form-control" required value={state.newTableRow.State} onChange={changeHandler} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>PIN Code</label> <small className="req"> *</small>
                                            <input name="PINCode" type="text" className="form-control" required value={state.newTableRow.PINCode} onChange={changeHandler} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right">Save</button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    )



}
export default AddAgent