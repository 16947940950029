import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { ClientDietPlansFoodRow } from "./ClientDietPlansFoodRow";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "../../Helpers/StrictModeDroppable";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ClientDietPlansMenuTable = ({
  mealIdx,
  state,
  menue,
  menueIdx,
  setState,
}) => {
  const [AndOrState, setAndOrState] = useState("and");

  let color = "";

  let calorieCount = state.food_meals[mealIdx].foodMenues[menueIdx].calories;

  let targetCalorie = state.food_meals[mealIdx].targetCalories;

  const percentage = (calorieCount / targetCalorie) * 100;

  if (percentage >= 101 && percentage <= 105) {
    color = "#FFBF00";
  } else if (percentage < 101) {
    color = "green";
  } else if (percentage > 105) {
    color = "red";
  }

  function add(data, mealIdx, menuIdx) {
    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));
      let foodLength =
        newState.food_meals[mealIdx].foodMenues[menuIdx]?.food?.length;
      if (AndOrState == "and" || !foodLength) {
        newState.food_meals[mealIdx].foodMenues[menuIdx].food.push([data]);
      } else {
        newState.food_meals[mealIdx].foodMenues[menuIdx].food[
          foodLength - 1
        ].push(data);
      }
      return { ...newState };
    });
  }

  const getIndex = (droppableId) => {
    let item_list = state.food_meals[mealIdx].foodMenues[menueIdx]?.food;
    let findIndex = item_list.findIndex((tappedItem) => {
      return item_list.indexOf(tappedItem) == droppableId;
    });

    return findIndex;
  };

  const getList = (droppableId) => {
    let item_list = state.food_meals[mealIdx].foodMenues[menueIdx]?.food;
    let filtered_item = item_list.find(
      (tappedItem) => item_list.indexOf(tappedItem) == droppableId
    );

    return filtered_item;
  };

  const reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    let result_old = result[startIndex];
    let result_new = result[endIndex];
    result[startIndex] = result_new;
    result[endIndex] = result_old;

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    try {
      let result = {};

      let sourceClone = Array.from(source);
      let destClone = Array.from(destination);
      let first_item = sourceClone[droppableSource.index];
      let second_item = destClone[droppableDestination.index];

      if (first_item === undefined || second_item === undefined) {
        return;
      }
      sourceClone[droppableSource.index] = second_item;
      destClone[droppableDestination.index] = first_item;

      result.source = sourceClone;
      result.dest = destClone;

      return result;
    } catch (e) {}
  };

  const onDragEnd = (result) => {
    try {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      let state_item_list = [
        ...state.food_meals[mealIdx].foodMenues[menueIdx].food,
      ];

      if (source.droppableId === destination.droppableId) {
        let result_index = getIndex(source.droppableId);

        let result_items = reorder(
          getList(source.droppableId),
          source.index,
          destination.index
        );

        state_item_list[result_index] = result_items;

        setState((state) => {
          let newState = JSON.parse(JSON.stringify(state));
          newState.food_meals[mealIdx].foodMenues[menueIdx].food =
            state_item_list;
          return { ...newState };
        });
      } else {
        let result_source_index = getIndex(source.droppableId);
        let result_dest_index = getIndex(destination.droppableId);
        let result_m = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
        );
        if (
          result_m === undefined ||
          result_m.source === undefined ||
          result_m.dest === undefined
        ) {
          return;
        }
        state_item_list[result_source_index] = result_m.source;
        state_item_list[result_dest_index] = result_m.dest;

        setState((state) => {
          let newState = JSON.parse(JSON.stringify(state));
          newState.food_meals[mealIdx].foodMenues[menueIdx].food =
            state_item_list;
          return { ...newState };
        });
      }
    } catch (e) {}
  };

  return (
    <table key={menueIdx} className="table table-bordered">
      <thead>
        <tr style={{ width: "100%", backgroundColor: "white" }}>
          <th
            style={{
              fontFamily: " Poppins",
              fontSize: "16px",
              fontStyle: " normal",
              fontWeight: 500,
            }}
            colSpan={9}
          >
            <span
              style={{
                fontFamily: " Poppins",
                fontSize: "17px",
                fontStyle: " normal",
                fontWeight: 500,
              }}
            >
              Menu {menueIdx + 1}
            </span>{" "}
            <MoreVertIcon />
            <span
              style={{
                paddingLeft: "1em",
                fontFamily: " Poppins",
                fontSize: "17px",
                fontStyle: " normal",
                fontWeight: 500,
              }}
            >
              Total Calories:{" "}
              <span style={{ color }}>
                {state.food_meals[mealIdx].foodMenues[menueIdx].calories}
                &nbsp; Cal
              </span>{" "}
            </span>
          </th>
        </tr>
        <tr>
          <th
            style={{
              width: "130px",
              minWidth: "130px",
              maxWidth: "130px",
              overflow: "hidden",
              boxSizing: "border-box",
              border: "1px solid #dddddd",
            }}
          >
            Name
          </th>
          <th
            style={{
              width: "210px",
              minWidth: "210px",
              maxWidth: "210px",
              overflow: "hidden",
              boxSizing: "border-box",
              border: "1px solid #dddddd",
            }}
          >
            Quantity
          </th>
          <th
            style={{
              width: "40px",
              minWidth: "40px",
              maxWidth: "40px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              border: "1px solid #dddddd",
            }}
          >
            Calorie
          </th>
          <th
            style={{
              width: "40px",
              minWidth: "40px",
              maxWidth: "40px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              border: "1px solid #dddddd",
            }}
          >
            Protein
          </th>
          <th
            style={{
              width: "40px",
              minWidth: "40px",
              maxWidth: "40px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              border: "1px solid #dddddd",
            }}
          >
            Carb
          </th>
          <th
            style={{
              width: "40px",
              minWidth: "40px",
              maxWidth: "40px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              border: "1px solid #dddddd",
            }}
          >
            Fibre
          </th>
          <th
            style={{
              width: "40px",
              minWidth: "40px",
              maxWidth: "40px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              border: "1px solid #dddddd",
            }}
          >
            Fat
          </th>
          <th
            style={{
              width: "60px",
              minWidth: "60px",
              maxWidth: "60px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",

              border: "1px solid #dddddd",
            }}
          >
            Food Instructions
          </th>
          <th
            style={{
              width: "30px",
              minWidth: "30px",
              maxWidth: "30px",
              overflow: "hidden",
              boxSizing: "border-box",
              textAlign: "center",
              border: "1px solid #dddddd",
            }}
          >
            Action
          </th>
        </tr>
      </thead>

      <DragDropContext onDragEnd={onDragEnd}>
        {menue.food.map((andFood, andFoodIdx, andFoodArr) => {
          return (
            <Droppable
              droppableId={andFoodIdx.toString()}
              direction="vertical"
              key={andFoodIdx}
            >
              {(provided, snapshot) => {
                return (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {andFood.map((orFood, orFoodIdx, orFoodArr) => {
                      return (
                        <Draggable
                          key={orFood.id}
                          draggableId={orFood.id}
                          index={orFoodIdx}
                        >
                          {(provided, snapshot) => {
                            return (
                              <tr
                                colSpan={9}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ClientDietPlansFoodRow
                                  state={state}
                                  setState={setState}
                                  orFoodIdx={orFoodIdx}
                                  andFoodIdx={andFoodIdx}
                                  mealIdx={mealIdx}
                                  menueIdx={menueIdx}
                                  orFoodArr={orFoodArr}
                                  andFoodArr={andFoodArr}
                                  orFood={orFood}
                                  andFood={andFood}
                                />
                              </tr>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                  </tbody>
                );
              }}
            </Droppable>
          );
        })}
      </DragDropContext>

      <tfoot>
        {menue.food.length ? (
          <AndOr
            state={AndOrState}
            isDefault={true}
            onClick={() =>
              setAndOrState((state) => (state == "or" ? "and" : "or"))
            }
          />
        ) : (
          <></>
        )}
        <tr
          style={{ height: "43px" }}
          onClick={() => {
            add({ name: "test" }, mealIdx, menueIdx);
          }}
          colSpan={8}
        >
          <td style={{ width: "30%" }}></td>
          <td style={{ width: "20%" }}></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ClientDietPlansMenuTable;

export function AndOr({ state, isDefault, onClick }) {
  return (
    <tr
      style={{
        border: "none",
        height: "50px",
      }}
    >
      <th
        style={{
          border: "none",
        }}
        className="text-center"
        colSpan={9}
      >
        <span
          style={{
            backgroundColor: state === "or" ? "black" : "#dddddd",
            color: state === "or" ? "white" : "black",
            fontSize: "14px",
            width: "43px",
            height: "20px",
            padding: "2px 12px",
            borderRadius: "4px",
            fontFamily: "Poppins",
            fontWeight: 400,
          }}
          onClick={onClick}
          onKeyDown={onClick}
        >
          Or
        </span>
        <span
          style={{
            backgroundColor: state === "and" ? "black" : "#dddddd",
            color: state === "and" ? "white" : "black",
            fontSize: "14px",
            width: "43px",
            height: "20px",
            padding: "2px 12px",
            borderRadius: "4px",
            fontFamily: "Poppins",
            fontWeight: 400,
          }}
          onClick={onClick}
          onKeyDown={onClick}
        >
          And
        </span>
      </th>
    </tr>
  );
}
