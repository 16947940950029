import * as $ from 'jquery'
export { changeHandler }

function changeHandler(e, state, setState,setErrorForClientName,setErrorForHeight,setErrorForPhoneNo,setErrorForWeight) {
    // const MAX_WORD_COUNT = {
        // "occupation":50,
        // "country":100,
        // "city":100,
        // "name":30,
        // "height":3,
        // "current_weight":3,
        // "address":1000,
        // "instagram_ID":500,
        // "facebook_ID":500
    // };
    
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{0,6})[/\\w .-]*/?');
    const regexForHeight = /^[^a-zA-Z]*$/;
    const DIGIT_REGEX = /\d/; 
    const specialCharsRegex = /[^a-zA-Z0-9 ]/g;
  
    let newValue;
    if (e.target.type === "file") {
        newValue = Object.values(e.target.files);
        
    } else {
        newValue = e.target.value;
        if (e.target.type !== "button") {
           
            if(e.target.type === 'text' && e.target.name !== 'address' && e.target.name !=="instagram_ID" && e.target.name !== "facebook_ID" && e.target.name!=='height'){
                if (DIGIT_REGEX.test(newValue)) {
                    e.preventDefault();
                    return;
                  }
            }
            if(e.target.name==='name' && specialCharsRegex.test(newValue)){
                return;
            }
            if(e.target.type==='number'){
                if(newValue.includes('-')){
                    return;
                }
            }
            if(e.target.name==='height'){
                if(!regexForHeight.test(newValue)){
                   return;
                }
            }
            
            if(e.target.name==='instagram_ID' && !regex.test(newValue)){
                return false;
               }
               if(e.target.name==='facebook_ID' && !regex.test(newValue)){
                return false;
           
               }
           

            if(e.target.name==='dob'){
                const selectedDate = new Date(newValue);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
     
      e.preventDefault();
      return;
    }
            }
                // const wordCount = newValue.length;
                
                // if (wordCount > MAX_WORD_COUNT[e.target.name]) {
                   
                //   // If the word count exceeds the limit, truncate the input to 50 words
                //   const words = e.target.value;
                //   const truncatedInput = words.slice(0, MAX_WORD_COUNT[e.target.name]);
                //   newValue = truncatedInput;
                // }
            // }
            
            if(e.target.name==="emotional_health" && newValue.includes(7)){
               newValue=newValue?.filter(c=>c === 7)
               }
            if (typeof $(e.target).attr("index") !== 'undefined') {
                newValue = [...state.newTableRow[e.target.name]]
                newValue[$(e.target).attr("index")] = e.target.value
            }
            if (e.target.type ==='radio') {
           
                if(e.target.checked){
                    // newValue = [state.newTableRow[e.target.name]]
                    // newValue[$(e.target).attr("index")] = e.target.value
                   
                    newValue=e.target.value
                    setState(prevState => ({
                        ...prevState,
                        newTableRow: {
                            ...prevState.newTableRow, 
                            gender:newValue,
                           
                           
                            LeadID:{
                                ...prevState.newTableRow.LeadID,
                                gender:newValue,
                           
                               
                            }
                        }
                    }))
                }
                // newValue[$(e.target).attr("index")] = e.target.value
            }
            
            if (e.target.type === "checkbox") {
                newValue = [...state.newTableRow[e.target.name]]
                if (e.target.checked) {
                    newValue.push(e.target.value)
                } else {
                    newValue = newValue.filter(data => data !== e.target.value)
                }
                
            }
        }

    }

if(e.target.name==='weight_unit'){
    newValue=  newValue=== 'Kg' ? 0 : 1
}
if(e.target.name==='height_unit'){
    newValue=  newValue=== 'cm' ? 0 : 1
}
    setState(prevState => ({
        ...prevState,
        newTableRow: {
            ...prevState.newTableRow,
            // [e.target.name]: newValue,
            
            
            [e.target.name]: newValue ,
            LeadID:{
            // [e.target.name]: newValue,
                // age:newValue,
                // gender:newValue,
                // height:newValue,
                // current_weight:newValue,
                // bmi:newValue,
                // address:newValue,
                // profession:newValue,
                // instagram_ID:newValue,
                // facebook_ID:newValue
            }
        }
    }))
    if(e.target.name==='name'){
        setErrorForClientName('')
    }
    if(e.target.name=== 'contact_number'){
        setErrorForPhoneNo('')
    }
    if(e.target.name=== 'current_weight'){
        setErrorForWeight('')
    }
    if(e.target.name==='height'){
        setErrorForHeight('')

    }
}