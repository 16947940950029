import React, { useState, useEffect, useContext } from 'react'
import * as $ from 'jquery'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import SelectBox from '../../components/SelectBox'
import axios from 'axios'
import Dialog from '../../components/Dialog'
import { changeHandler as oldChangeHandler } from '../../Helpers/ChangeHandler'
import { deleteFromTable, editTableRow } from '../../Helpers/TableHelpers'
import { getNormalDate, getInputDate, getFormattedDate } from '../../Helpers/DateHelpers'
import { ATLAS_URI, ADMIN } from '../../Constants'
import stateContext from '../../context/StateContext'
import './leadview.css'
import FollowupModal from '../../components/FollowupModal'
import HandFReportViewer from './HandFReportViewer'
import { Button } from 'react-bootstrap'
import { Prompt, useHistory, useLocation, useNavigate, useParams } from "react-router-dom";
import AggridFollowUpModal from '../../components/AggridFollowUpModal'
import LeadViewCallHistory from './LeadViewCallHistory'
import { useSelector } from 'react-redux'
import { axiosFetch } from '../../Utlis/AxiosFetch'
import { toast } from 'react-toastify'

function LeadView(props) {

    const routeParams=useParams()

    const location = useLocation();
    const leadsList = useSelector((state) => state.lead.data);
    
    const [currentNote, setCurrentNote] = useState('')

   
    const contextState = useContext(stateContext)
    const { redirectFromEditDetails, resetEditID, updateList } = contextState
    const { configToken, usersList, sourcesList, propertyTypeList, statusList, operator, EditDetailsData } = contextState.state
    const [readMore,setReadMore]=useState(false);
    const [leadDetails, setLeadDetails] = useState(null);
    const [state, setState] = useState({
        resetNewRow: {
            id: "",
            Client: "",
            name: "",
            ClientPhone: "",
            LastRemarks: "",
            LeadDate: "",
            Referral: "",
            Sources: "",
            LeadStatus: "",
            Payment: "",
            MinArea: "",
            MaxArea: "",
            MinBudget: "",
            MaxBudget: "",
            RequiredLoan: "No",
            next_followup_date: "",
            InterestedIn: "",
            call_status: "",
            Remarks: [],
            VisitInfos: [],
            Payments: []
        },
        newTableRow: {
            id: "0001",
            Client: "",
            name: "",
            ClientPhone: "",
            LastRemarks: "",
            LeadDate: "",
            Sources: "",
            LeadStatus: "",
            Referral: "",
            Payment: "",
            MinArea: "",
            MaxArea: "",
            MinBudget: "",
            MaxBudget: "",
            RequiredLoan: "No",
            next_followup_date: "",
            InterestedIn: "",
            call_status: "",
            Remarks: [],
            VisitInfos: [],
            Payments: [],

            current_medical_condition: [],
            fitness_goals: []
        },
        tableBodyList: [

        ],
        Project: "Select",
        Property: "Select",
        EditingVisitList: false,
        EditingVisitInfoID: "",

        editingActivated: false,
        editingID: "",
        dialogInfo: {
            isOpened: false,
            text: "",
            iconClass: "fas fa-exclamation-triangle"
        },
        projectsList: [],
        propertiesList: [],
        propertyTypesList: [],
        sourcesList: [],
        clientsList: [],
        userList: []
    })

    const mounted = React.useRef(true);

    const getLeadDetails = () => {
        axiosFetch({
            url: `/getLeadByID/${routeParams.id}`,
            method: "get",
        })
        .then((res)=>{
            if(res.status === 200){
                
                setLeadDetails(res.data);
            }
            else if(res.status === 401){
               window.location.reload('/');
            }
        })
        .catch(err =>{
            console.error(err);
        })
    }
    useEffect(() => () => { mounted.current = false; }, []);
    useEffect(() => {
        getLeadDetails();
        let userData = usersList;
        let sourcesData = sourcesList;
        let propertyTypesData = propertyTypeList;
        let Allleadlist = leadsList;



        //Populate all Fields

        if (resetEditID("/Leads/leadView")) {
          
            window.location.replace("/Leads/freshLeads")
        
        } else {
            if (typeof routeParams.id !== 'undefined') {

                axios.all(
                    [
                        axios.get(`${ATLAS_URI}/getRoles/`, configToken),
                        axios.get(`${ATLAS_URI}/getProjects/`, configToken),
                        axios.get(`${ATLAS_URI}/getProperties/`, configToken),
                    ]
                )
                    .then(axios.spread((roles, projects, properties) => {
                        const projectsData = projects.data;
                        const propertiesData = properties.data;
                        const rolesData = roles.data;
                       
                        let leadsData = Allleadlist.filter(lead => lead.id == routeParams.id)[0]
                       
                        if (typeof leadsData !== 'undefined' && typeof projectsData !== 'undefined' && typeof propertiesData !== 'undefined' && typeof rolesData !== 'undefined' && mounted.current) {
                            let newUserList = []
                            if (sourcesData.length !== 0
                                && propertyTypesData.length !== 0
                                && userData.length !== 0) {

                                newUserList = userData.map(user => {
                                    const temp = rolesData.filter(Role => user.Role === Role.id);
                                    user.AssignedTo = temp[0].Role + ": " + user.Name
                                    return user;
                                })
                            }
                            setTimeout(() => {
                                setState(prevState => ({
                                    ...prevState,
                                    sourcesList: sourcesData,
                                    propertyTypesList: propertyTypesData,
                                    userList: newUserList,
                                    projectsList: projectsData,
                                    propertiesList: propertiesData,
                                    newTableRow: leadsData,
                                    editingActivated: true
                                }))
                            }, 200)
                        }
                    }))
                    .catch(error => console.error(error));
            }
        }
    }, [contextState]);


    function onSelectChange(e) {

        let newValue;
        newValue = e.target.value;
        setState(prevState => ({
            ...prevState,
            newTableRow: {
                ...prevState.newTableRow,
                [e.target.name]: newValue
            }
        }))
        let selectedOption = e.target.value;
        if (selectedOption === "Success" || selectedOption === "Dump") {
            $("[name=next_followup_date]").removeAttr("required")
            $('.notReq').hide()
        } else {
            $("[name=next_followup_date]").prop("required", true)
            $('.notReq').show()
        }

    }


    //Overloading insertIntoTable
    function insertIntoTable(e) {
        e.preventDefault();
        let newLead = state.newTableRow;
        newLead.next_followup_date = getNormalDate(newLead.next_followup_date);
        axios.post(`${ATLAS_URI}/updateLead/${routeParams.id}`, newLead, configToken)
            .then(() => {
                let list = [...leadsList]
                var foundIndex = list.findIndex(x => x.id == routeParams.id);
                list[foundIndex] = newLead;
                updateList('leadsList', list)

                setState(prevState => ({
                    ...prevState,
                    editingActivated: false,
                    newTableRow: state.resetNewRow
                }))

                // redirectFromEditDetails(EditDetailsData.redirectFrom)
                redirectFromEditDetails('/Leads/listLeads')


            })
            .catch(err => console.error(err))

    }

    async function addLeadIfNotExist() {
        if (state.newTableRow.AssignedTo === "" &&
            state.newTableRow.Remarks.length === 0 &&
            state.newTableRow.VisitInfos.length === 0 &&
            state.newTableRow.Payments.length === 0) {
            await axios.post(`${ATLAS_URI}/updateLead/${routeParams.id}`, { id: routeParams.id }, configToken)
                .then(res => {
                    if (res.status === 200) {
                    
                    }
                })
                .catch(err => console.error(err))
        }
    }
    function insertIntoRemarksList(e) {
        e.preventDefault();
      
        addLeadIfNotExist()
        const tempRemarks = $("#newRemarks").val();
        let newRemarksList = [...state.newTableRow.Remarks];
        const addRemarks = { Remarks: tempRemarks, Date: getFormattedDate(), User: operator.Name, LeadId: state.newTableRow.id }

        axios.post(`${ATLAS_URI}/addRemarks/`, addRemarks, configToken)
            .then(res => {
                if (res.status === 200) {
                    addRemarks.id = res.data.id
                    newRemarksList.push(addRemarks);


                    let list = [...leadsList]
                    var foundIndex = list.findIndex(x => x.id == routeParams.id);
                    list[foundIndex].Remarks = newRemarksList;
                    updateList('leadsList', list)


                    setState(prevState => ({
                        ...prevState,
                        newTableRow: {
                            ...prevState.newTableRow,
                            Remarks: newRemarksList
                        }
                    }))
                    $("#newRemarks").val("");

                }
            })
            .catch(err => console.error(err))

    }

    function insertIntoVisitList(e) {
        e.preventDefault();
        addLeadIfNotExist()
        if (state.Project === "Select") {
            const newDialogInfo = { isOpened: true, text: "Project is left Empty", type: "Error" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
        }
        else if (state.Property === "Select") {
            const newDialogInfo = { isOpened: true, text: "Property is left Empty", type: "Error" }
            setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
            setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
        }
        else {
            const tempProject = state.projectsList.filter(project => project.id.toString() === state.Project)[0].Name;
            const tempProperty = state.propertiesList.filter(property => property.id.toString() === state.Property)[0].PropertyTitle;

            const tempDescription = $("#newDescription").val();
            const tempVisitDate = $("#newVisitDate").val();
            const tempStatus = $("#newVisitStatus").val();

            let newVisitList = [...state.newTableRow.VisitInfos];

            const addedVisit = { Project: tempProject, Property: tempProperty, VisitDate: getNormalDate(tempVisitDate), Description: tempDescription, CreatedBy: operator.Name, VisitStatus: tempStatus, LeadId: state.newTableRow.id }


            axios.post(`${ATLAS_URI}/addVisitInfo/`, addedVisit, configToken)
                .then(res => {
                    if (res.status === 200) {
                        addedVisit.id = res.data.id
                        newVisitList.push(addedVisit);

                        let list = [...leadsList]
                        var foundIndex = list.findIndex(x => x.id.toString() == routeParams.id.toString());
                        list[foundIndex].VisitInfos = newVisitList;
                       
                        updateList('leadsList', list)


                        setState(prevState => ({
                            ...prevState,
                            newTableRow: {
                                ...prevState.newTableRow,
                                VisitInfos: newVisitList
                            },
                            Project: "Select",
                            Property: "Select"
                        }))
                        $("#newDescription").val("");
                        $("#newVisitDate").val("");
                    }
                })
                .catch(err => console.error(err))
        }
    }

    function insertIntoPaymentList(e) {
        e.preventDefault();
        addLeadIfNotExist()
        const tempDescription = $("#newPaymentDescription").val();
        const tempDate = $("#newPaymentDate").val();
        const tempStatus = $("#newPaymentType").val();
        const tempAmount = $("#newAmount").val();

        const addedPayment = { Amount: tempAmount, Type: tempStatus, Date: getNormalDate(tempDate), Description: tempDescription, LeadId: routeParams.id }
        let newPayment = [...state.newTableRow.Payments];
        let client = {
            Name: state.newTableRow.name,
            Phone: state.newTableRow.ClientPhone,
            Email: state.newTableRow.ClientEmail,
            Lead: routeParams.id,
            SourceId: state.newTableRow.source
        }

        axios.post(`${ATLAS_URI}/addPayment/`, addedPayment, configToken)
            .then(res => {
                if (res.status === 200) {

                    axios.post(`${ATLAS_URI}/addClient/`, client, configToken)


                    addedPayment.id = res.data.id
                    newPayment.push(addedPayment);

                    setState(prevState => ({
                        ...prevState,
                        newTableRow: {
                            ...prevState.newTableRow,
                            Payments: newPayment
                        }
                    }))

                    let list = [...leadsList]
                    var foundIndex = list.findIndex(x => x.id.toString() == routeParams.id.toString());
                    list[foundIndex].Payments = newPayment;
                    list[foundIndex].Payment = "Paid";
                    updateList('leadsList', list)

                    $("#newPaymentDescription").val("");
                    $("#newPaymentDate").val("");
                    $("#newPaymentType").val("");
                    $("#newAmount").val("");
                }
            })
            .catch(err => console.error(err))
    }


    function changeHandler(e) {
        oldChangeHandler(e, state, setState);
    }

    const [leadTabview, setLeadTabView] = useState({tabShown: "Lead View"})

    function toggleTab (){
        if(location.state.isCallHistoryView){
            setLeadTabView({
                tabShown : "Call History"
            })
        }
        else if(location.state.isLeadView){
            setLeadTabView({
                tabShown : "Lead View"
            })
        }
        else if(location.state.isHFReport){
            setLeadTabView({tabShown: "H F Report" });
        }
    }
    useEffect(() => {
       toggleTab();        
    }, [])  
 
    

    function setTabShown(e) {
        $(".profileInfo li").removeClass("active");
        $(e.target).addClass("active");
        const tabShown = $(e.target).html();
        setLeadTabView((prevState) => ({ ...prevState, tabShown: tabShown }));
      } 

    const linkName=readMore?'Read Less << ':'Read More >> '
    
  

                                            /* Back To LeadPage */
    const history = useHistory()
    const [goBack, setGoBack] = useState(false)

    const handleBeforeUnload = (event) => {
      
        event.preventDefault()
        event.returnValue = 'Are you sure you want to leave?'
        return 'Are you sure you want to leave?'
      }

    const backToLeadPage=(e)=>{
        localStorage.setItem('RESTORE_LEAD_LISTING_SCREEN', true)
        
        history.push('/Leads/listLeads');
    }

    
    useEffect(() => {
       
        
      
          if (currentNote != '') {
            window.addEventListener('beforeunload', handleBeforeUnload)
          }
      
          return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [currentNote != '', 'Are you sure you want to leave?'])

    return (
        <section className="justify-content-center profileInfo">
            <nav className="tabNavigation">
                <Button onClick={()=>backToLeadPage()}>Back</Button>
                {/* {goBack&& <} */}
        <ul>
          <li className={location.state.isLeadView && "active"} onClick={setTabShown}>
          Lead View
          </li>
          <li className={location.state.isCallHistoryView && "active"} onClick={setTabShown}>Call History</li>
          <li className={location.state?.isHFReport && "active"} onClick={setTabShown}>H F Report</li>
          {/* <li onClick={setTabShown}>Progress Analysis</li> */}

        </ul>
      </nav>
      
        
      {/* </div> */}
            <div className="row">

                {typeof state.dialogInfo !== 'undefined' &&
                    <Dialog
                        onFalse={(e) => setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "" } }))}
                        dialogInfo={state.dialogInfo}
                    />
                }
        
                <div className="profileTab">
        {leadTabview.tabShown==='Lead View'&&
             <div>
                <div className="col-md-12 ">
                    <div className="box-body bozero">
                        <BoxHeader title='Leads View' />
                       
                       <div className='grid-container' style={{'height':'100%'}}>
                        <form onSubmit={insertIntoTable}>
                            <div className="box-body">
                              <div className='row'>

                              <div className="col-md-6">
                                 <div className='form-group'>
                                <label id='textFormat'>Lead Name :   </label> <span className='leftSidealignment'>{!! leadDetails?.name ? leadDetails?.name  :"-" }</span> 
                                </div>
                                </div> 
                               
                                                              
                                <div className="col-md-6">
                                <div className="form-group">
                                <label id='textFormat'>Account Name :  </label> <span className='rightSidealignment' >{!!leadDetails?.SourceID ? leadDetails?.SourceID?.Source : "-"}</span>
                                </div>
                                
                                </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label> Email ID : </label><span className='leftSidealignment'>{!! leadDetails?.email_id ? leadDetails?.email_id  :"-" }</span> 
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                <div className="form-group">
                                <label id='textFormat'>Contact Number :  </label> <span className='rightSidealignment' >{!!leadDetails?.contact_number ? leadDetails?.contact_number : "-"}</span>
                                </div>
                                
                                </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label id='textFormat'>Gender : </label><span className='leftSidealignment'>{!! leadDetails?.gender ? leadDetails?.gender  :"-" }</span> 
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                <div className="form-group">
                                <label id='textFormat'>Age :  </label> <span className='rightSidealignment' >{!!leadDetails?.age ? leadDetails?.age : "-"}</span>
                                </div>
                                
                                
                                </div>
</div>
                                <div className='row'>
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label id='textFormat'>Height :   </label> <span className='leftSidealignment'>{!!leadDetails?.height ? leadDetails?.height : "-"}</span> 
                                    {/* <input required name="next_followup_date" type="date" className="form-control" value={state.newTableRow.next_followup_date} onChange={changeHandler} /> */}
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                   <label id='textFormat'>Current Weight :  </label><span className='rightSidealignment'>{!!leadDetails?.current_weight ? leadDetails?.current_weight : "-"}</span> 
    {/* <input name="LastRemarks" type="text" className="form-control" value={state.newTableRow.LeadDate} required  /> */}
                         </div>
                         </div>

                              
                                </div>


                               


                                <div className='row'>

                    

<div className="col-md-6">
{/* <div className="form-group">
    <label id='textFormat'>City :     </label> <span className='rightSidealignment'>{state.newTableRow.city}</span> 
</div> */}
</div>
</div>


                  


                           {/* <div className='row'>
                           <div className="col-md-6">
                           <div className="form-group">
                          <label id='textFormat'>State :  </label><span className='leftSidealignment'>{state.newTableRow.state}</span> 
                          </div>
                          </div>

                    <div className="col-md-6">
                  <div className="form-group">
                <label id='textFormat'>Country :   </label> <span className='rightSidealignment'>{state.newTableRow.country}</span>
    
                    </div>
                    </div>
                    </div>      */}

                    {/* <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Pincode :   </label><span className='leftSidealignment'>{state.newTableRow.pincode}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Mother Tongue :  </label> <span className='rightSidealignment'>{state.newTableRow.mother_tongue}</span>
                            </div>
                        </div>
                    </div> */}


                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Preferred Language :    </label><span className='leftSidealignment'>{!!leadDetails?.preferred_language? leadDetails?.preferred_language:"-" }</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Profession :   </label> <span className='rightSidealignment'>{!!leadDetails?.occupation ? leadDetails?.occupation : "-"}</span>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                                <div className='form-group'>
                                    <label id='textFormat'>Current Medical Condition :  </label> <span className='leftSidealignment'>{!!leadDetails?.current_medical_condition ? leadDetails?.current_medical_condition?.toString() : "-"}</span>
                                </div>
                        </div>
                        <div className='col-md-6'>
                        <div className='form-group'>
                                    <label id='textFormat'>Notes :  </label> <span className='rightSidealignment'>{!!leadDetails?.notes ? leadDetails?.notes : "-"}</span>
                                </div>
                        </div>
                    </div>


                    {/* <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Work Hours :   </label><span className='leftSidealignment'>{state.newTableRow.work_hours}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Family Details :   </label> <span className='rightSidealignment'>{state.newTableRow.family_details}</span>
                            </div>
                        </div>
                    </div> */}



                    <div className='row'>
                        <div className='col-md-6'>
                            {/* <div className='form-group'>
                                <label id='textFormat'>Emergency Contact :  </label><span className='leftSidealignment'>{state.newTableRow.emergency_contact}</span>
                            </div> */}
                        </div>
                        <div className='col-md-6'>
                            {/* <div className='form-group'>
                                <label id='textFormat'>Medications :  </label> <span className='rightSidealignment'>{state.newTableRow.medications}</span>
                            </div> */}
                        </div>
                    </div>



                    <div className='row'>
                        <div className='col-md-6'>
                            {/* <div className='form-group'>
                                <label id='textFormat'>Medical History :  </label><span className='leftSidealignment'>{state.newTableRow.medical_history}</span>
                            </div> */}
                        </div>
                       
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Assigned To :  </label> <span className='leftSidealignment'>{!!leadDetails?.assigned_to_user ? leadDetails?.assigned_to_user?.Username : "-"}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Fitness Goals :  </label><span className='rightSidealignment'>{leadDetails?.fitness_goals !== null ? leadDetails?.fitness_goals : "-"}</span>
                            </div>
                        </div>
                    </div>



                    {/* <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Weight Goal :   </label><span className='reduce_length'>{ state.newTableRow.weight_goal }</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Nutrition Goal :  </label> <span className='rightSidealignment'>{state.newTableRow.nutrition_goal?.calorie ?? 0} calorie</span>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Sleep Goal :  </label><span className='leftSidealignment'>{state.newTableRow.sleep_goal}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Water Goal : </label> <span className='rightSidealignment'>{state.newTableRow.water_goal}</span>
                            </div>
                        </div>
                    </div> */}



                    <div className='row'>
                    <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Payments :    </label><span className='leftSidealignment'>{state.newTableRow.Payment}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Fitness Specifications :  </label> <span className='rightSidealignment'>{leadDetails?.fitness_specifications !== null ? leadDetails?.fitness_specifications : "-"}</span>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                    <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Stage :    </label><span className='leftSidealignment'>{!!leadDetails?.leadStageList !== null ? leadDetails?.leadStageList?.leadStageInfo?.display_value : "-"}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Disposition :  </label> <span className='rightSidealignment'>{!!leadDetails?.leadStageList !== null ? leadDetails?.leadStageList?.leadDispositionInfo?.display_value : "-"}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className='row'>
                    <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Sub Disposition :    </label><span className='leftSidealignment'>{!!leadDetails?.leadStageList !== null ? leadDetails?.leadStageList?.leadSubDispositionInfo?.display_value : "-"}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Grand Disposition :  </label> <span className='rightSidealignment'>{!!leadDetails?.leadStageList !== null ? leadDetails?.leadStageList?.leadSubNotInterestedInfo?.display_value : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label id='textFormat'>Social Media Handle :    </label><span className='leftSidealignment'>{leadDetails?.social_media_handle_name !== null ? leadDetails?.social_media_handle_name : "-"}</span>
                            </div>
                        </div>

                        <div className='col-md-6'>

                            <div className='form-group'>
                                <label id='textFormat'>Referral :  </label><span className='leftSidealignment'>{leadDetails?.referral_code}</span>
                            </div>
                    
                        </div>
                    </div>  
                    <div className='row'>
                        <div className='col-md-6'>
                            {/* <div className='form-group'>
                                <label id='textFormat'>Lead Status :  </label><span className='leftSidealignment'>{state.newTableRow.LeadStatus}</span>
                            </div> */}
                        </div>
                       
                    </div>




                    {/* <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Food Specifications :  {state.newTableRow.food_specifications}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Body Measurements : {state.newTableRow.body_measurements}</label>
                            </div>
                        </div>
                    </div> */}

                              
                                {/* <SelectBox
                                    label="Property Type"
                                    name="PropertyType"
                                    options={state.propertyTypesList}
                                    attributeShown="PropertyType"
                                    changeHandler={changeHandler}
                                    value={state.newTableRow.PropertyType}
                                    resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, PropertyType: "Select" } }))}
                                /> */}

                                {/* <SelectBox
                                    label="Source"
                                    name="Source"
                                    options={state.sourcesList}
                                    attributeShown="Source"
                                    changeHandler={changeHandler}
                                    value={state.newTableRow.Source}
                                    resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, Source: "Select" } }))}
                                /> */}
                                {/* <div className="col-md-4">
                                <div className="form-group">
                                    <label>Lead Status</label> <small className="req"> *</small>

                                    <select required className="form-control" name="LeadStatus" value={state.newTableRow.LeadStatus} onChange={onSelectChange} >
                                        <option value={null}>{"Select"}</option>
                                        {
                                            statusList.map((status) =>
                                            <option key={status.id} value={status.id}>{status.Status}</option>
                                            )
                                        }

                                    </select>
                                </div>
                                </div>
                                */}

                                

                                {/* <div className="col-md-4">
                                <div className="form-group">
                                        {operator.Role === ADMIN &&
                                            <SelectBox
                                                label="AssignedTo"
                                                name="AssignedTo"
                                                options={state.userList}
                                                attributeShown="AssignedTo"
                                                changeHandler={changeHandler}
                                                value={state.newTableRow.AssignedTo}
                                                resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, AssignedTo: "Select" } }))}
                                            />
                                        }
                                <div className="col-md-12">
                                <div className="form-group">
                                    <label>Payment</label>
                                    <input name="Payment" type="text" className="form-control" placeholder="eg. Ashiana, Omaxe, LDA" value={state.newTableRow.Payment} onChange={changeHandler} />
                                </div>
                                </div>
                                </div>
                                </div>
                                
                                 */}

                                {/* <div className='row form-group'>
                                    <label>Property Area/Size </label>

                                    <div className='col-md-6'>
                                        <input name="MinArea" type="number" min={0} className="form-control" placeholder="Min. Sq. Ft" value={state.newTableRow.MinArea} onChange={changeHandler} />
                                    </div>

                                    <div className='col-md-6'>
                                        <input name="MaxArea" type="number" min={0} className="form-control" placeholder="Max. Sq. Ft" value={state.newTableRow.MaxArea} onChange={changeHandler} />
                                    </div>
                                </div> */}

                                {/* <div className='row form-group'>
                                    <label>Client Budget</label>

                                    <div className='col-md-6'>
                                        <input name="MinBudget" type="number" min={0} className="form-control" placeholder="Min Budget" value={state.newTableRow.MinBudget} onChange={changeHandler} />
                                    </div>

                                    <div className='col-md-6'>
                                        <input name="MaxBudget" type="number" min={0} className="form-control" placeholder="Max Budget" value={state.newTableRow.MaxBudget} onChange={changeHandler} />
                                    </div>
                                </div> */}

                                {/* <div className="form-group">

                                    <label className="radio-inline">Required Loan</label>

                                    <label className="radio-inline">
                                        <input
                                            type="radio" name="RequiredLoan"
                                            checked={state.newTableRow.RequiredLoan === "Yes"}
                                            value="Yes" onChange={changeHandler} /> Yes
                                    </label>
                                    &nbsp; &nbsp;
                                    <label className="radio-inline">
                                        <input
                                            type="radio" name="RequiredLoan"
                                            checked={state.newTableRow.RequiredLoan === "No"}
                                            value="No" onChange={changeHandler} /> No
                                    </label>

                                </div> */}

                                {/* <div className="form-group">
                                    <label>Call Status</label> <small className="req"> *</small>

                                    <select required className="form-control" name="call_status" value={state.newTableRow.call_status} onChange={changeHandler} >
                                        <option value="">Select</option>
                                        <option value="Not Contacted">
                                            Not Contacted
                                        </option>
                                        {state.newTableRow.LeadStatus !== "Un-Attempted" && <option value="Contacted">
                                            Contacted
                                        </option>}

                                    </select>
                                </div> */}

                                

                            </div>

                            {/* <div className="box-footer">
                                <button type="submit" className="btn btn-info pull-right">Update</button>
                            </div> */}

                        </form>
                        </div>
                    </div>

                </div>
                </div>
}
</div>
                {leadTabview.tabShown === 'Call History' &&

                    <LeadViewCallHistory leadId={routeParams.id} leadInfo={state.newTableRow} currentCallUUID={location.state?.currentCallUUID} setCurrentNote={setCurrentNote} currentNote={currentNote} />
                }
              {leadTabview.tabShown==='H F Report'&&<HandFReportViewer id={routeParams.id}/>}



                {/* <div className="col-md-8">
                    <div className='row'> */}
                        {/* <div className="box box-primary">
                            <BoxHeader title="Add Payment" />

                            <div className="box-body">

                                <form onSubmit={insertIntoPaymentList} >
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className="form-group">
                                                <label>Amount</label>
                                                <input name="Amount" type="text" className="form-control" id="newAmount" required />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Payment Type</label>
                                                <select id="newPaymentType" className='form-control' required>
                                                    <option value="Cash">Cash</option>
                                                    <option value="Cheque">Cheque</option>
                                                    <option value="Card">Card</option>
                                                    <option value="Net Banking">Net Banking</option>
                                                    <option value="Paytm">Paytm</option>
                                                    <option value="Razorpay">Razorpay</option>
                                                    <option value="Upi">Upi</option>
                                                    <option value="Phonepe">Phonepe</option>
                                                    <option value="Gpay">Gpay</option>
                                                    <option value="Easypay">Easypay</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Payment Date</label> <small className="req"> *</small>
                                                <input id="newPaymentDate" name="PaymentDate" type="date" required className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Description</label>

                                        <textarea name="Description" id='newPaymentDescription' className="form-control" rows="4"></textarea>
                                    </div>

                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-info pull-right">Add</button>
                                    </div>
                                </form>
                            </div>

                        </div> */}

                        {/* <div className="box box-primary">
                            <BoxHeader title="Recent Payments" />

                            <div className="box-body">
                                {typeof state.newTableRow.Payments !== 'undefined' &&
                                    <DataTable
                                        tableHeader={["id", "Amount", "Type", "Description", "Date"]}
                                        tableBody={state.newTableRow.Payments}
                                        searchField="Date"
                                        deleteFromTable={deleteFromTable}
                                        editTableRow={editTableRow}
                                        noActions=""
                                    />
                                }
                            </div>

                        </div> */}
                    {/* </div> */}
{/* 
                    <div className='row'>
                        <div className="box box-primary"> */}
                            {/* <BoxHeader title={`${state.EditingVisitList ? "Edit" : "Add"} Visit Info`} /> */}

                            {/* <div className="box-body"> */}

                                {/* <form onSubmit={insertIntoVisitList} > */}
                                    {/* <div className="form-group"> */}

                                        {/* <div className='row'> */}
                                            {/* <div className='col-6'>
                                                <SelectBox
                                                    label="Project"
                                                    name="Project"
                                                    options={state.projectsList}
                                                    attributeShown="Name"
                                                    changeHandler={(e) => {
                                                        setState(prevState => ({ ...prevState, Project: e.target.value }))

                                                    }}
                                                    value={state.Project}
                                                    resetValue={() => setState(prevState => ({ ...prevState, Project: "Select" }))}
                                                />
                                            </div> */}
                                            
                                        {/* </div */}

                                        {/* <div className='row'>
                                            <div className='col-6'> */}
                                                {/* <div className='form-group'> */}
                                                    {/* <label>Visit Status</label> */}
                                                    {/* <select id="newVisitStatus" className='form-control'>
                                                        <option value="Not Visited">Not Visited</option>
                                                        <option value="Visited">Visited</option>
                                                    </select> */}
                                                {/* </div> */}
                                            {/* </div> */}
                                            {/* <div className='col-6'>
                                                <div className='form-group'>
                                                    <label>Visit Date</label> <small className="req"> *</small>
                                                    <input id="newVisitDate" name="VisitDate" type="date" required className="form-control" />
                                                </div>
                                            </div> */}
                                        {/* </div> */}

                                        {/* <div className='form-group'>
                                            <label>Description</label>
                                            <textarea name="Description" id='newDescription' className="form-control" rows="4"></textarea>
                                        </div> */}

                                    {/* </div> */}

                                    {/* <div className="box-footer">
                                        <button type="submit" className="btn btn-info pull-right">{`${state.EditingVisitList ? "Update" : "Add"}`}</button>
                                    </div> */}
                                {/* </form> */}
                            {/* </div> */}

                        {/* </div> */}

                        {/* <div className="box box-primary">
                            <BoxHeader title="Visit Info" />

                            <div className="box-body">
                                {typeof state.newTableRow.VisitInfos !== 'undefined' &&
                                    <DataTable
                                        tableHeader={["id", "Project", "Property", "Visit Date", "Description", "Visit Status"]}
                                        tableBody={state.newTableRow.VisitInfos}
                                        searchField="Properties"
                                        // customAction={[
                                        //  { title: "Edit", icon: "fas fa-edit", onClickEvent: activateEditingVisitList },
                                        // ]}
                                        noActions=""
                                    />
                                }
                            </div>

                        </div> */}
                    </div>

                    {/* <div className='row'> */}
                        {/* <div className="box box-primary">
                            <BoxHeader title="Add Remarks" />

                            <div className="box-body"> */}

                                {/* <form onSubmit={insertIntoRemarksList} >
                                    <div className="form-group">
                                        <textarea name="Remarks" id='newRemarks' className="form-control" rows="4"></textarea>
                                    </div>

                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-info pull-right">Add</button>
                                    </div>
                                </form> */}
                            {/* </div>

                        </div> */}

                        {/* <div className="box box-primary">
                            <BoxHeader title="Recent Remarks" />

                            <div className="box-body">
                                {typeof state.newTableRow.Remarks !== 'undefined' &&
                                    <DataTable
                                        tableHeader={["id", "Remarks", "Date", "User"]}
                                        tableBody={state.newTableRow.Remarks}
                                        searchField="Date"
                                        deleteFromTable={deleteFromTable}
                                        editTableRow={editTableRow}
                                        noActions=""
                                    />
                                }
                            </div>

                        </div> */}
                    {/* </div> */}

                {/* </div> */}

            {/* </div> */}

        </section>
    )


}

export default LeadView