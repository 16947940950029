import React, { useState, useEffect, useRef } from "react";

// import { BookOpen, AlertTriangle } from "react-feather";

import DropdownMenu from "../components/Dropdown menu/DropdownMenu";

import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { axiosFetch } from "../Utlis/AxiosFetch";

const NotifyMe = (props) => {
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState([]);

  const [triggerTime, setTriggerTime] = useState();

  const setTime = () => {
    const startTime = localStorage.getItem("startTime");
    const timeDifference = performance.now() - +startTime;

    let formattedTimeDifference;

    if (timeDifference < 60000) {
      const seconds = Math.floor(timeDifference / 1000);
      formattedTimeDifference =
        seconds <= 1 ? "a second ago" : `${seconds} seconds ago`;
    } else if (timeDifference < 3600000) {
      const minutes = Math.floor(timeDifference / (1000 * 60));
      formattedTimeDifference =
        minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else {
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      formattedTimeDifference =
        hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    }

    setTriggerTime(formattedTimeDifference);
  };

  const handleClick = () => {
    setOpen((pre) => !pre);
    setTime();
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (!dropdownRef.current.contains(event.target)) {
      handleClose();
    }
  };

  const getNotification = async () => {
    const response = await axiosFetch({
      url: `/getNotifications?userId=${userId}`,
      method: "get",
    });

    setNotification(response.data);

    localStorage.setItem("startTime", performance.now());
  };

  const markNotification = async (id) => {
    const response = await axiosFetch({
      url: `/markNotificationAsRead`,
      method: "post",
      requestConfig: id,
    });

    if (response.status === 200) {
      getNotification();
    }
  };

  // const pushNotification = async () => {
  //   const response = await axiosFetch({
  //     url: `subscribe`,
  //     method: "post",
  //     requestConfig: {},
  //   });
  // };

  // const handleCheck = async () => {
  //   const publicVapidKey =
  //     "BFHUg8I6rUXirrTXVlzoZNhn93LiGz4G2BBkPaiA7r1WGNqdSHzgHXK77pVx6Db5sA5xwDnO8oXh0Qil5ybyiho";

  //   if ("serviceWorker" in navigator && "PushManager" in window) {
  //     navigator.serviceWorker
  //       .register("/worker.js", {
  //         scope: "/",
  //       })
  //       .then(async (registration) => {
  //         const subscription = await registration.pushManager.subscribe({
  //           userVisibleOnly: true,
  //           applicationServerKey: publicVapidKey,
  //         });

  //         await axiosFetch({
  //           url: `/subscribe`,
  //           method: "post",
  //           requestConfig: JSON.stringify({
  //             userId: userId,
  //             subscription,
  //           }),
  //         });
  //       });
  //   }
  // };

  useEffect(() => {
    getNotification();
    // handleCheck();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* style={{ marginLeft: "10px" }} */}
      <div ref={dropdownRef}>
        <div className="d-flex mt-2 ms-2">
          <span onClick={handleClick}>
            {notification.count > 0 ? (
              <NotificationsActiveIcon color="error" />
            ) : (
              <NotificationsIcon />
            )}
          </span>
        </div>
        {open && (
          <DropdownMenu
            handleClose={handleClose}
            notification={notification.notificationsResponse}
            triggerTime={triggerTime}
            markNotification={markNotification}
          />
        )}
      </div>
    </>
  );
};

export default NotifyMe;
/* 
             <>
              <AlertTriangle color="#000000" size={32} />
              <h5 className="nodata">No Notifications found!</h5>
            </>
*/
