import React, { useCallback, useMemo, useRef, useState } from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { BsRecordCircle } from "react-icons/bs";

const AggridFollowUpModal = (props) => {
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "1000px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [show, setShow] = useState(false);
  const [getId, setGetId] = useState();
  // {callInfo?.createdAt && (new Date(callInfo?.createdAt).toDateString() + ', ' + new Date(callInfo?.createdAt).toLocaleTimeString())}
  const listenAudio = (id) => {
    // alert(id)
    setGetId(id);
    setShow(true);
  };
  let callHistory = props?.callHistory?.map((data) => {
    data.username = data?.User?.Username;
    data.calledAt =
      data?.createdAt &&
      new Date(data?.createdAt).toDateString() +
        ", " +
        new Date(data?.createdAt).toLocaleTimeString();
    data.recording = data?.recordingURL;
    return data;
  });
  const [columnDefs, setColumnDefs] = useState([
    { field: "username", filter: "agTextColumnFilter" },
    { field: "calledAt", minWidth: 200 },
    { field: "callDuration", minWidth: 200 },
    {
      field: "recordingURL",
      minWidth: 200,
      cellRenderer: (params, id) => {
        // return <BsRecordCircle fontSize={20} onClick={()=>listenAudio(params.data.id)}/>
        return (
          <audio controls>
            <source src={params?.value} type="audio/mp3" />
          </audio>
        );
      },
    },
    { field: "callStatus", minWidth: 200 },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    /* fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
        .then((resp) => resp.json())
        .then((data) => setRowData(data)); */
  }, []);
  let Id = props.callHistory?.map((c) => c.id);

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        {show & Id?.includes(getId) && (
          <audio controls>
            {/* <source src={callInfo?.recordingURL} type="audio/mp3" /> */}
          </audio>
        )}
        <AgGridReact
          rowData={props.callHistory}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          // autoGroupColumnDef={autoGroupColumnDef}
          // sideBar={'columns'}
          sideBar={false}
          onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default AggridFollowUpModal;
