import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import "../LiveSession.css";
import DataTable from "../../../components/DataTable/DataTable";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

import useViewAttendance from "../../../hooks/tableColumns/useViewAttendance";
import useBooking from "../../../hooks/tableColumns/useBooking";

const Backdrop = (props) => {
  return <div className={"session-backdrop"} onClick={props.closeModel}></div>;
};

const BookingData = ({ viewListName, selectedRow }) => {
  const bookingList = useBooking();
  const attendanceList = useViewAttendance();

  const [columns, setColumns] = useState();
  const [rows, setRows] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 1,
  });

  const handlePagination = async (model) => {
    setPaginationModel(model);
    setPageNo((pre) => {
      return model.page + 1;
    });
  };

  const handleViewBooking = async () => {
    const request = await axiosFetch({
      url: `/getBookings?meeting_id=${selectedRow.id}&page=${pageNo}&perPage=${paginationModel.pageSize}`,
      method: "get",
    });
    setRows(request.data);
  };

  const handleViewAttendance = async () => {
    const request = await axiosFetch({
      url: `/getSessionAttendees/${selectedRow.session_id}/${selectedRow.id}&page=${pageNo}&perPage=${paginationModel.pageSize}`,
      method: "get",
    });
    setRows(request.data);
  };

  useEffect(() => {
    if (viewListName === "Booking" || viewListName === "Booked") {
      handleViewBooking();
      setColumns(bookingList);
    } else if (viewListName === "Attendance") {
      handleViewAttendance();
      setColumns(attendanceList);
    }
  }, [viewListName]);

  return (
    <div className="view-booking">
      <div className="view-Header">
        <h4> View {viewListName}</h4>
      </div>

      <DataTable
        columns={columns}
        rows={rows.list}
        count={rows.count}
        handlePagination={handlePagination}
      />
    </div>
  );
};

const ViewBooking = (props) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop closeModel={props.closeModel} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <BookingData
          closeModel={props.closeModel}
          selectedRow={props.selectedRow}
          onSession={props.onSession}
          viewListName={props.viewListName}
        />,
        editTableModal
      )}
    </>
  );
};

export default ViewBooking;
