import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import * as $ from "jquery";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";

import stateContext from "../../context/StateContext";
import { AgGridReact } from "ag-grid-react";
import { ATLAS_URI } from "../../Constants";
import ClientWorkoutPlan from "../Clients/ClientWorkoutPlan";
import PopupWoroutPlanView from "../Clients/PopupWorkoutPlanView";
import { toast } from "react-toastify";
import PopupWorkoutPlanView from "../Clients/PopupWorkoutPlanView";
import { dateConverter } from "../../Utlis/dateConversion";
function WorkoutPlanListing() {

  const contextState = useContext(stateContext);

  const { configToken, operator, currentUser } = contextState.state;


  const [workoutPlanList, setWorkoutPlanList] = useState([]);


  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [clientInfo, setClientInfo] = useState({})

  const [workoutPlanId, setWorkoutPlanId] = useState(null)
  const [showWorkoutDetailPage, setShowWorkoutDetailPage] = useState(false)
  const [workoutUpdate, setWorkoutUpdate] = useState(false)

  const [workoutPlanView, setWorkoutPlanView] = useState(false)
  const [workoutPlanViewPlanId, setWorkoutPlanViewPlanId] = useState('')

  const [workoutViewClientInfo, setWorkoutViewClientInfo] = useState({})
  const [workoutPlanInfo, setWorkoutPlanInfo] = useState({})


  const showWorkoutPlanViewModal = async (workoutPlanInfoId, leadId) => {

    const clientInfo = await axios.get(`${ATLAS_URI}/getClientInfoByLeadId/${leadId}`, configToken).then().catch(err => {
      console.error('err', err)
    })

   
    setWorkoutViewClientInfo(clientInfo.data)

    const resp = await axios.get(`${ATLAS_URI}/getWorkoutPlanByPlanId/${workoutPlanInfoId}`, configToken)
    setWorkoutPlanInfo(resp.data)

    setWorkoutPlanViewPlanId(workoutPlanInfoId)
    setWorkoutPlanView(true)
  }

 


  const workoutPlanPermission = contextState.state.featureAccessible.find(
    (feature) => (feature.sub_feature_id == 24 && feature.is_view)
  )

  const myLeadsPermissionOnly =
    contextState.state?.featureAccessible.find(
      (feature) => feature.sub_feature_id == 44
    ).is_view &&
    contextState.state?.featureAccessible.find(
      (feature) => feature.sub_feature_id == 6
    ).is_view == false;
 



  const onGridReady = (params) => {
 
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };





  const getWorkoutGoal = () => {

    let URL = `${ATLAS_URI}/listAllWorkoutPlan`

    if (myLeadsPermissionOnly) {URL = `${ATLAS_URI}/myClientPlan/workout`}
  

   

    axios.get(`${URL}`, configToken).then(res => {
    
      if (res.status == 200) {
      
        setWorkoutPlanList(res.data.map(planInfo => {
          planInfo.planName = planInfo?.plan_name
          planInfo.leadName = planInfo?.Lead?.name
          planInfo.phone = planInfo?.Lead?.contact_number
          planInfo.email = planInfo?.Lead?.email_id
          planInfo.assignedBy = planInfo?.Lead?.ClientInfo?.TrainerAssignedByInfo ? (planInfo?.Lead?.ClientInfo?.TrainerAssignedByInfo?.firstName + ' ' + (planInfo?.Lead?.ClientInfo?.TrainerAssignedByInfo?.lastName ? planInfo?.Lead?.ClientInfo?.TrainerAssignedByInfo?.lastName : '')) : ''
          planInfo.dateJoined = planInfo?.Lead?.createdAt ? dateConverter(planInfo?.Lead?.createdAt) : ''
          planInfo.action = { leadId: planInfo?.leadId, planId: planInfo?.id }
          return planInfo
        }))
      
      }
    }).catch(err => {
      console.error('err',err)
      
    })
  }

  useEffect(() => {

   


    if (workoutPlanPermission) {

      getWorkoutGoal()
    }


  }, []);





  async function editworkoutPlan(workoutPlanInfoId) {
   
    // alert(workoutPlanId)
    setWorkoutPlanId(workoutPlanInfoId);
    setShowWorkoutDetailPage(true);
    setWorkoutUpdate(true)

  }



  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    };
  }, []);
  const containerStyle = useMemo(() => ({ width: '100%', 'height': '1000px' }), []);
  const gridStyle = useMemo(() => ({ 'height': '100%', width: '100%' }), []);

  const paginationNumberFormatter = useCallback((params) => {
    return '[' + params.value.toLocaleString() + ']';
  }, []);

  return (
    <section className="profileInfo">


      {(
        // <>kkkk</>

        (workoutPlanPermission && showWorkoutDetailPage) ? (
          <ClientWorkoutPlan
            showDetailPage={showWorkoutDetailPage}
            setShowDetailPage={setShowWorkoutDetailPage}
            clientsData={clientInfo}
            workoutPlanId={workoutPlanId}
            editPermission={true}
          />
        ) : (
          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div style={containerStyle}>
                  <div style={gridStyle} className="ag-theme-alpine">


                    <AgGridReact
                      rowData={workoutPlanList}
                      columnDefs={[
                        { field: 'planName' },
                        { field: 'leadName' },
                        { field: 'phone' },
                        { field: 'email' },
                        { field: 'assignedBy' },
                        { field: 'dateJoined' },
                        {
                          field: 'action', cellRenderer: (params, id) => {
                        
                            return (<React.Fragment key={params.data.id}>
                              <button
                                className="btn btn-default btn-xs no-focus"
                                title="View"
                                onClick={() => showWorkoutPlanViewModal(params.data.id, params.data.leadId)}
                              >
                                <i className="fas fa-eye" aria-hidden="true"></i>
                              </button>

                              <button
                                className="btn btn-default btn-xs no-focus"
                                title="Edit"
                                onClick={() => {
                                  editworkoutPlan(params.data.id)
                                  setClientInfo({ ...params.data, Lead: params.data.leadId })
                                }}
                              >
                                <i className="fas fa-pencil" aria-hidden="true"></i>
                              </button>

                              <button className="btn btn-default btn-xs no-focus"
                                title="Delete Plan"
                                onClick={() => {
                                  axios.delete(`${ATLAS_URI}/deleteWorkoutPlanByPlanId/${params.data.id}`, configToken).then(res => {
                                    if (res.status == 200) {
                                      // toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                                      toast.success('Plan Deleted', { position: toast.POSITION.TOP_CENTER })

                                      getWorkoutGoal()

                                    }
                                  }).catch(err => {
                                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                                  })
                                }}>
                                <i className="fas fa-trash" aria-hidden="true"></i>
                              </button>

                            </React.Fragment>)
                          }
                        }
                      ]}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReady}
                      sideBar={{
                        position: 'bottom',
                        defaultToolPanel: 'columns',
                      }}

                      pagination={true}
                      paginationPageSize={10}
                      paginationNumberFormatter={paginationNumberFormatter}
                    >

                    </AgGridReact>
                  </div>
                </div>
              </div>
            </div>
            {
              workoutPlanView && <PopupWorkoutPlanView show1={workoutPlanView} setShow1={setWorkoutPlanView} planId={workoutPlanViewPlanId} clientInfo={workoutViewClientInfo} workoutPlanInfo={workoutPlanInfo} />
            }
          </section>
        )
      )
      }
    </section>
  );
}

export default WorkoutPlanListing;
