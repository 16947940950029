import { ATLAS_URI } from "../Constants";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { useState } from "react";

const ViewPdf = ({ pdfData }) => {
  let history = useHistory();
  const location = useLocation();
  const toolbarPluginInstance = toolbarPlugin();
  const [defaultScale, setDefaultScale] = useState(2.4); // Set your default zoom level here
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  window.localStorage.setItem('goto_clients_workoutplan_page', true);
  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    // Download: () => <></>,
    // EnterFullScreen: () => <></>,
    // SwitchTheme: () => <></>,
    // GoToPreviousPage:()=><></>,
    // GoToNextPage:()=><></>,
    SearchTheme: () => <></>,
    Open: () => <></>,
  });
  const goBackToPreviousPage = () => {
    history.goBack();
  };

  return (
    <>
      <Button
        onClick={goBackToPreviousPage}
        variant="outlined"
        sx={{ margin: "8px 12px" }}
      >
        <ArrowBackOutlinedIcon /> Back
      </Button>

      <Worker workerUrl={ATLAS_URI + "/static/pdf.worker.min.js"}>
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        <Viewer
          fileUrl={location?.state?.pdfData}
          plugins={[toolbarPluginInstance]}
          defaultScale={defaultScale}
          onLoadSuccess={({ zoom }) => setDefaultScale(zoom)}
        />
      </Worker>
    </>
  );
};
export default ViewPdf;
