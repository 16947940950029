import React, { useState, useEffect, useContext } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import "./PreferredFoodTime.css";

import { axiosFetch } from "../../../Utlis/AxiosFetch";

import stateContext from "../../../context/StateContext";

import { useSelector } from "react-redux";

import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const PreferredFoodTime = () => {
  const clientId = useSelector((state) => state?.leadId);

  const [editing, setEditing] = useState(false);

  const [editedRow, setEditedRow] = useState([]);

  const [foodTimingList, setFoodTimingList] = useState([]);

  const [leadId, setLeadId] = useState("");

  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const sleepTableColumn = [
    {
      field: "wakeupTime",
      headerName: "Wakeup Time",
      width: 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "sleepTime",
      headerName: "Sleep Time",
      width: 90,
      headerClassName: "super-app-theme--header",
    },
  ];

  const sleepTableRow = [
    {
      id: 1,
      wakeupTime: "5:00",
      sleepTime: "10:00",
    },
  ];

  const getFoodTime = async () => {
    // Today date

    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];

    const responce = await axiosFetch({
      url: `/v3/getAllFoodTimingCRM/${clientId.value}`,
      method: "get",
    });

    // const sleepTimeResponce = await axiosFetch({
    //   url: `/v3/getSleepActivity?leadId=${clientId}&date=${formattedDate}&timezone=Asia/Kolkata`,
    //   method: "get",
    // })

    // console.error(sleepTimeResponce.data.body,'check')

    // const sleepTime = await axios.get(`${ATLAS_URI}/graphql`,configToken)
    const foodTime = responce.data.body?.foodTimingList;
    //  console.log(sleepTime)

    setFoodTimingList(foodTime);
    setLeadId(responce.data.body?.leadId);

    // Get the current date and time
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    // Specify the desired time
    const time = foodTime?.map((data) => {
      return dayjs(`${currentYear}-${currentMonth}-${currentDay} ${data.time}`);
    });

    setEditedRow(time);
  };

  useEffect(() => {
    getFoodTime();
  }, []);

  const handleEditClick = () => {
    setEditing(true);
  };

  const [updatedTime, setUpdatedTime] = useState([]);

  // update time function

  const handleCellChange = (field, value) => {
    const date = new Date(value.$d);
    const formattedTime = date.toLocaleTimeString([], { timeStyle: "short" });
    setUpdatedTime((prevRow) => ({
      ...prevRow,
      [field]: formattedTime,
    }));
  };

  const updateArray = (oldArray, newValues) => {
    return oldArray.map((item) => {
      const updatedItem = { ...item };
      const itemName = item.name;
      if (newValues.hasOwnProperty(itemName)) {
        const newTime = newValues[itemName];
        if (newTime !== updatedItem.time) {
          updatedItem.time = newTime;
          updatedItem.isSelected = true;
        }
      }
      return updatedItem;
    });
  };

  const saveHandler = async () => {
    const updateRow = updateArray(foodTimingList, updatedTime);
    const updateFoodTimeobj = {
      leadId: leadId,
      foodTimingList: updateRow,
    };

    // const responce =  axios.post(`${ATLAS_URI}/v3/postFoodTimingCRM`,updateFoodTimeobj,configToken)

    const responce = await axiosFetch({
      url: `/v3/postFoodTimingCRM`,
      requestConfig: updateFoodTimeobj,
      method: "post",
    });

    if (responce.status === 200) {
      getFoodTime();
      setEditing(false);
    }
  };

  const stringDate = dayjs("2023-06-27 06:30 PM");

  //  const parsedDate =  dayjs(parse(stringDate, 'yyyy-MM-dd hh:mm aa', new Date()))

  return (
    <div className="food-con">
      <div className="table-con">
        {/* <TableContainer  style={{ width: '350px', border: '1px solid #ccc',borderRadius:'6px' }}  >
    <Table>
      <TableBody>
        {sleepTableColumn.map((column) => (
          <TableRow key={column.field}>
            <TableCell style={{ width: column.width, backgroundColor: '#F4F4F4', fontWeight: 'bold' }}>
              {column.headerName}
            </TableCell>
            <TableCell style={{ width: column.width, backgroundColor: '#fff' }}>{sleepTableRow[0][column.field]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
    */}

        <TableContainer
          style={{
            width: "350px",
            border: "1px solid #ccc",
            borderRadius: "6px",
          }}
        >
          <Table>
            <TableBody>
              {foodTimingList?.length > 0 &&
                foodTimingList?.map((column, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        width: "90px",
                        backgroundColor: "#F4F4F4",
                        fontWeight: "bold",
                      }}
                    >
                      {column.name}
                    </TableCell>
                    {editing ? (
                      <TableCell
                        style={{ width: "90px", backgroundColor: "#fff" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <div style={{ width: "100%" }}>
                              <TimePicker
                                label="Food time picker"
                                style={{ width: "100%" }}
                                inputFormat="HH:mm"
                                value={editedRow[index]}
                                onChange={(newValue) =>
                                  handleCellChange(column.name, newValue)
                                }
                              />
                            </div>
                          </DemoContainer>
                        </LocalizationProvider>
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{ width: "90px", backgroundColor: "#fff" }}
                      >
                        {column.time}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <div className='food-time-btn-con'>
  <button onClick={handleEditClick}>Edit</button>
    <button onClick={saveHandler} >Save</button>
  </div> */}
    </div>
  );
};

export default PreferredFoodTime;

// import * as React from 'react';
// import dayjs, { Dayjs } from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// const  PreferredFoodTime = ()=> {
//   const [value, setValue] = React.useState(dayjs('2023-06-27 06:30 PM'));
//  return (
//   <LocalizationProvider dateAdapter={AdapterDayjs}>
//        <DemoContainer components={['TimePicker', 'TimePicker']}>
//       <TimePicker
//           label="Uncontrolled picker"
//           defaultValue={dayjs('2022-04-17T15:30')}
//         />
//         <TimePicker
//           label="Controlled picker"
//           value={value}
//           onChange={(newValue) => setValue(newValue)}
//         />
//       </DemoContainer>
//     </LocalizationProvider>
//  )

// }

// export default PreferredFoodTime
