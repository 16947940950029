import React, { createContext, useState, useEffect } from "react";
import { axiosFetch } from "../../Utlis/AxiosFetch";

const ApiDataContext = createContext();

export function ApiDataProvider({ children }) {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track errors if any
  const [isMasterDataLoaded, setIsMasterDataLoaded] = useState(false);

  // const [leadStages, setLeadStages] = useState([])
  // const [leadDispositions, setLeadDispositions] = useState([])
  // const [leadSubDispositions, setLeadSubDispositions] = useState([])
  // const [leadNotInterested, setLeadNotInterested] = useState([])
  // const [users, setUsers] = useState([])

  useEffect(() => {
    axiosFetch({
      url: `/lead-screen-master-data`,
      method: "get",
    })
      .then((res) => {
        // setLeadStages(res.data.leadStages)
        // setLeadDispositions(res.data.leadDisposition)
        // setLeadSubDispositions(res.data.leadSubDisposition)
        // setLeadNotInterested(res.data.leadNotInterested)
        // setUsers(res.data.users)
        setIsMasterDataLoaded(true);

        setApiData(res.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch(() => {
        setError(error); // Handle errors and set error state
        setLoading(false); // Set loading to false on error
      });
  }, []);

  if (loading) {
    // You can render a loading spinner or message here
    return <div>Loading...</div>;
  }

  if (error) {
    // You can render an error message here
    return <div>Error: {error.message}</div>;
  }

  return (
    <ApiDataContext.Provider value={apiData}>
      {children}
    </ApiDataContext.Provider>
  );
}

export default ApiDataContext;
