import { useEffect, useState, useRef } from "react";

const InactivityDetector = ({ timeout, onInactive }) => {
  const [isActive, setIsActive] = useState(true);
  const timer = useRef(null);

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      if (isActive) {
        setIsActive(false);
        onInactive();
      }
    }, timeout);
  };

  const handleUserActivity = () => {
    if (!isActive) {
      setIsActive(true);
    }
    resetTimer();
  };

  useEffect(() => {
    const events = ["mousemove", "mousedown", "keypress", "touchstart"];
    events.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );

    resetTimer();

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [isActive]);

  return null;
};

export default InactivityDetector;
