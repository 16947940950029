import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import "./AccordionList.css";
const AccordionList = ({ day, children, showCard, className }) => {
  const [open, setOpen] = useState(showCard);

  return (
    <Box sx={className}>
      <div className={`accordion__header`}>
        <div
          className="accordion-title-container"
          onClick={() => setOpen((pre) => !pre)}
        >
          <div>
            <Typography
              variant="h5"
              display="block"
              gutterBottom
              className="session-day-title"
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                marginBottom: "10px",
                userSelect: "none",
              }}
            >
              {day}
            </Typography>
          </div>
          <div>
            <span className={`expand-icon ${open ? "open" : ""}`}>▼</span>
          </div>
        </div>

        {open && <div className={`history-details`}>{children}</div>}
      </div>
    </Box>
  );
};

export default AccordionList;
