import { configureStore } from "@reduxjs/toolkit";
import leadsReducer from "./leads/leadSlice";
import sourceReducer from "./Source/SourceSlice";
import leadIdSlice from "./leads/leadIdSlice";
import WorkoutPlan from "./WorkoutPlan/WorkoutPlan";
import Call from "./Call/CallItem";

export const store = configureStore({
  reducer: {
    lead: leadsReducer,
    source: sourceReducer,
    leadId: leadIdSlice,
    workoutPlan: WorkoutPlan,
    call: Call,
  },
});
