import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";

import stateContext from "../../context/StateContext";
import { ATLAS_URI } from "../../Constants";
import ClientWorkoutPlanView from "./ClientWorkoutPlanView";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ClientWorkoutPlan = (props) => {
  let { id } = useParams();
  let history = useHistory();

  const contextState = useContext(stateContext);
  const { configToken, operator } = contextState.state;
  const [workoutPlan, setWorkoutPlan] = useState([]);
  const [workoutPlanName, setWorkoutPlanName] = useState("");
  const [targetCalorie, setTargetCalorie] = useState(1);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [isHidden, setIsHidden] = useState(true);
  const [workoutTemplate_list, setWorkoutTemplate_list] = useState([]);
  const [TemplateNameErr, setisTemplateNameErr] = useState(false);
  // const [isEnable, setIsEnable] = useState(true);
  const [isRestrict, setIsRestrict] = useState(false);
  const [isEnableDropdown, setIsEnableDropdown] = useState(false);

  // ------------------ Template work ----------------------

  const pathname = window.location.pathname;

  const [isTemplateZone, setIsTemplateZone] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  async function getWorkoutPlan(templateId) {
    if (!props.workoutPlanId && !templateId) {
      return;
    } else if (props.workoutPlanId || templateId) {
      const dataToset = await axios
        .get(
          `${ATLAS_URI}/getWorkoutPlanByPlanId/${props.workoutPlanId}`,
          configToken
        )
        .then((res) => {
          if (res.status === 200) {
            let workoutResponse = res.data;

            setWorkoutPlanName(workoutResponse.workoutPlan.plan_name);

            setTargetCalorie(workoutResponse.workoutPlan.target_calorie);

            // getSchedulerData(funcResult);

            const workoutPlanArr = [];
            if (workoutResponse.workoutPlanDetails.length > 0) {
              for (const workoutPlan of workoutResponse.workoutPlanDetails) {
                const workoutObj = workoutPlan.workout_details.map(
                  (workoutInfo) => {
                    const startDate = workoutPlan.date + ":00:00";
                    const endDate = workoutPlan.date + ":23:59";

                    return {
                      title: workoutInfo.title,
                      startDate: new Date(startDate),
                      endDate: new Date(endDate),
                      customField: JSON.stringify({
                        name: workoutInfo.name,
                        youtube_link: workoutInfo.youtube_link,
                        duration: workoutInfo.duration,
                        x_value: workoutInfo.x_value,
                      }),
                      customURLField: workoutInfo.customURLField,
                      setCount: workoutInfo.setCount,
                      id: workoutInfo.id,
                      workoutPlanDetailId: workoutPlan.id,
                    };
                  }
                );

                workoutPlanArr.push(...workoutObj);
              }
            }

            return workoutPlanArr;
          }
        });

      setWorkoutPlan((prevState) => {
        return [...prevState, ...dataToset];
      });
    }
  }

  function getSchedulerData(date, data) {
    let dateitem = date;

    const workoutPlanArr = [];
    if (data.length > 0) {
      let index = 0;

      for (const workoutPlan of data) {
        const workoutObj = workoutPlan.workout_details.map(
          (workoutInfo, idx) => {
            let org_date = new Date(dateitem);

            let date = new Date(org_date).getDate() + idx;

            if (date <= 9) {
              date = "0" + date;
            }
            let month;
            let mnth = new Date(org_date).getMonth() + 1;

            if (mnth <= 9) {
              month = "0" + mnth;
            }

            let NumberOfdaysInAMonth = new Date(
              org_date.getFullYear(),
              org_date.getMonth() + 1,
              0
            ).getDate();

            if (date > NumberOfdaysInAMonth) {
              // date = setDate(new Date().getDate() + 1)

              // if (date == 32) {
              //   index = 0
              // }

              org_date.setMonth(org_date.getMonth() + 1, index + 1);
              let nextMonth = new Date(org_date);

              date = nextMonth.getDate();
              index++;
              if (date <= 9) {
                date = "0" + date;
              }

              if (nextMonth.getMonth() + 1 <= 9) {
                month = "0" + (nextMonth.getMonth() + 1);
              } else {
                month = nextMonth.getMonth() + 1;
              }
            }
            let year = new Date(org_date).getFullYear();

            let formatedDate = year + "-" + month + "-" + date;

            workoutInfo.date = formatedDate;

            const startDate = workoutInfo.date + "T00:00:00";
            const endDate = workoutInfo.date + "T23:59:59";

            let name,
              youtube_link,
              duration,
              x_value,
              title,
              id,
              customURLField,
              setCount;

            workoutInfo.workout_detail.map((item) => {
              name = item.name;
              youtube_link = item.youtube_link;
              duration = item.duration;
              x_value = item.x_value;
              title = item.title;
              id = item.id;
              customURLField = item.customURLField;
              setCount = item.setCount;
            });

            return {
              title: title,
              startDate: new Date(startDate),
              endDate: new Date(endDate),
              customField: JSON.stringify({
                name,
                youtube_link,
                duration,
                x_value,
              }),
              id: id,
              workoutPlanDetailId: workoutInfo.id,
              customURLField: customURLField,
              setCount: setCount,
            };
          }
        );

        workoutPlanArr.push(...workoutObj);
      }
    }
    setWorkoutPlan((prevState) => {
      return [...prevState, ...workoutPlanArr];
    });
  }

  async function getWorkoutDetails() {
    axios
      .all([
        axios.get(`${ATLAS_URI}/getWorkoutDetails`, configToken),
        axios.get(`${ATLAS_URI}/getWorkoutNames`, configToken),
      ])
      .then(
        axios.spread((workoutDetails, workoutNames) => {
          setWorkoutDetail(workoutDetails.data);
          setWorkoutName(workoutNames.data);
        })
      )
      .catch((error) => {
        console.error(error);
      });
  }

  const listExistingTemplate = () => {
    axios
      .get(`${ATLAS_URI}/getWorkoutPlanTemplate`, configToken)
      .then((res) => {
        if (res.status === 200) {
          setWorkoutTemplate_list(res.data);

          // getSchedulerData(res.data);
        } else if (res.status === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const editTemplate = (templateId) => {
    axios
      .get(`${ATLAS_URI}/getWorkoutTemplateById/${templateId}`, configToken)
      .then((res) => {
        if (res.status === 200) {
          const workoutPlanArr = [];
          if (res.data.workout_details.length > 0) {
            let index = 0;
            let y = res.data.workout_details;
            // for (const workoutPlan1 of y) {

            const workoutObj = res.data.workout_details.map(
              (workoutInfo, idx) => {
                let org_date = new Date(new Date().toISOString().split("T")[0]);

                let date = new Date(org_date).getDate() + idx;

                if (date <= 9) {
                  date = "0" + date;
                }
                let month;
                let mnth = new Date(org_date).getMonth() + 1;

                if (mnth <= 9) {
                  month = "0" + mnth;
                }

                let NumberOfdaysInAMonth = new Date(
                  org_date.getFullYear(),
                  org_date.getMonth() + 1,
                  0
                ).getDate();

                if (date > NumberOfdaysInAMonth) {
                  // date = setDate(new Date().getDate() + 1)

                  // if (date == 32) {
                  //   index = 0
                  // }

                  org_date.setMonth(org_date.getMonth() + 1, index + 1);
                  let nextMonth = new Date(org_date);

                  date = nextMonth.getDate();
                  index++;
                  if (date <= 9) {
                    date = "0" + date;
                  }

                  if (nextMonth.getMonth() + 1 <= 9) {
                    month = "0" + (nextMonth.getMonth() + 1);
                  } else {
                    month = nextMonth.getMonth() + 1;
                  }
                }
                let year = new Date(org_date).getFullYear();

                let formatedDate = year + "-" + month + "-" + date;

                workoutInfo.date = formatedDate;

                const startDate = workoutInfo.date + "T00:00:00";
                const endDate = workoutInfo.date + "T23:59:59";

                let name,
                  youtube_link,
                  duration,
                  x_value,
                  title,
                  id,
                  customURLField,
                  setCount;

                workoutInfo.workout_detail.map((item) => {
                  name = item.name;
                  youtube_link = item.youtube_link;
                  duration = item.duration;
                  x_value = item.x_value;
                  title = item.title;
                  id = item.id;
                  customURLField = item.customURLField;
                  setCount = item.setCount;
                });

                return {
                  title: title,
                  startDate: new Date(startDate),
                  endDate: new Date(endDate),
                  customField: JSON.stringify({
                    name,
                    youtube_link,
                    duration,
                    x_value,
                  }),
                  id: id,
                  workoutPlanDetailId: workoutInfo.id,
                  customURLField: customURLField,
                  setCount: setCount,
                };
              }
            );

            workoutPlanArr.push(...workoutObj);

            // }
          }
          setWorkoutPlan((prevState) => {
            return [...prevState, ...workoutPlanArr];
          });
          if (res.data?.template_name !== null) {
            setTemplateName(res.data.template_name);
          } else if (res.status === 401) {
            history.push("/");
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const checkForTemplateZone = () => {
    if (pathname.includes("/Templates/workoutTemplate")) {
      setIsTemplateZone(true);
      editTemplate(id);
    } else {
      setIsTemplateZone(false);
    }
  };

  useEffect(() => {
    getWorkoutPlan();
    getWorkoutDetails();
    listExistingTemplate();
    checkForTemplateZone();
  }, [props?.clientsData?.Lead]);

  function commitChanges({ added, changed, deleted }) {
    setWorkoutPlan((prevState) => {
      if (added) {
        const startingAddedId =
          prevState.length > 0 ? prevState[prevState.length - 1].id + 1 : 0;

        const startAt = `${added.startDate.getFullYear()}-${
          added.startDate.getMonth() + 1
        }-${added.startDate.getDate()}`;
        const endAt = `${added.endDate.getFullYear()}-${
          added.endDate.getMonth() + 1
        }-${added.endDate.getDate()}`;

        const dates = getDaysArray(startAt, endAt);

        const addedDates = dates.map((date, index) => {
          date = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
            -2
          )}-${("0" + date.getDate()).slice(-2)}`;

          return {
            id: startingAddedId + index,
            ...added,
            startDate: new Date(date + ":00:00"),
            endDate: new Date(date + ":23:59"),
          };
        });

        return [...prevState, ...addedDates];
      }

      if (changed) {
        prevState = prevState.map((workoutPlanInfo) =>
          changed[workoutPlanInfo.id]
            ? { ...workoutPlanInfo, ...changed[workoutPlanInfo.id] }
            : workoutPlanInfo
        );
        return [...prevState];
      }
      if (deleted !== undefined) {
        prevState = prevState.filter(
          (workoutPlanInfo) => workoutPlanInfo.id !== deleted
        );
        return [...prevState];
      }
    });
  }

  const [textEditorFocus, setTextEditorFocus] = useState(false);

  const handleTextEditorFocus = () => {
    setTextEditorFocus(true);
  };

  const handleTextEditorBlur = () => {
    setTextEditorFocus(false);
  };

  const [textSetCountEditorFocus, setTextSetCountEditorFocus] = useState(false);

  const handleTextSetCountEditorFocus = () => {
    setTextSetCountEditorFocus(true);
  };

  const handleTextSetCountEditorBlur = () => {
    setTextSetCountEditorFocus(false);
  };

  // const list = [{id: 1, text: 'ttt'}, {id: 2, text: 't2'}, {id: 3, text: 't3'}, {id: 4, text: 't4'}]
  const [workoutDetail, setWorkoutDetail] = useState();
  const [workoutName, setWorkoutName] = useState();
  const [first, setfirst] = useState();
  const [other, setOther] = useState(true);
  const [title, setTitle] = useState("t3");

  function changeWorkoutType(workoutValue) {
    let newTitle = workoutName.find((v) => v.id == workoutValue).text;

    setTitle(newTitle);

    if (workoutValue == 1) {
      setOther(false);
    } else {
      setOther(true);
    }
    setfirst(workoutValue);
  }

  const getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const saveWorkoutPlan = async (e) => {
    e.preventDefault();

    let workoutPlanDetails = workoutPlan;

    workoutPlanDetails = workoutPlanDetails.map((workoutPlanInfo) => {
      const endDate = new Date(workoutPlanInfo.endDate);
      const startDate = new Date(workoutPlanInfo.startDate);

      const startAt = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      const endAt = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;

      const date = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;

      const customField = workoutPlanInfo.customField
        ? JSON.parse(workoutPlanInfo.customField)
        : workoutPlanInfo.title;
      let { name, youtube_link, duration, x_value } = customField;
      if (!name) {
        name = workoutPlanInfo.title;
      }
      let customURLField = null;

      let setCount = 0;

      if (workoutPlanInfo.customURLField) {
        customURLField = workoutPlanInfo.customURLField;
      }

      if (workoutPlanInfo.setCount) {
        setCount = workoutPlanInfo.setCount;
      }

      // const youtube_link = workoutPlanInfo.customField
      const title = workoutPlanInfo.text
        ? workoutPlanInfo.text
        : workoutPlanInfo.title;

      let id = undefined;
      if (workoutPlanInfo.workoutPlanDetailId) {
        id = workoutPlanInfo.workoutPlanDetailId;
      }

      return {
        id,
        date: date,
        workout_details: {
          id: workoutPlanInfo.id,
          youtube_link,
          name,
          duration,
          title,
          x_value,
          customURLField,
          setCount,
        },
      };
    });

    const postData = [...new Set(workoutPlanDetails.map((x) => x.date))].map(
      (x) => ({ id: undefined, date: x, workout_details: [] })
    );

    // const postData = removeDuplicates();

    workoutPlanDetails.forEach((x) => {
      if (postData.find((y) => y.date === x.date)) {
        return {
          workoutPlanDetails: postData
            .find((y) => y.date === x.date)
            .workout_details.push(x.workout_details),
          id: (postData.find((y) => y.date === x.date).id = x.id),
        };
      }
    });

    // // pass without id
    // let customizedData = postData.map(({ id, ...rest }) => {
    //   return rest;
    // });

    const workoutGoalData = {
      workoutPlanRelationshipData: postData,
      leadId: props.clientsData.Lead,
      plan_name: workoutPlanName,
      target_calorie: !targetCalorie ? 1 : targetCalorie,
    };

    if (props.workoutPlanId != null) workoutGoalData.id = props.workoutPlanId;

    /* const string = renderToString(<ClientWorkoutPlanView workoutData={workoutGoalData.workoutPlanRelationshipData} />);

    const pdf = new jsPDF("p", "pt", [595.28, 841.89]);

          await pdf.html(string, { width: 300, windowWidth: 600, margin: [10, 10, 10, 10], autoPaging: 'text' });
         

    return */

    const clientInfo = await axios
      .get(
        `${ATLAS_URI}/getClientInfoByLeadId/${props.clientsData.Lead}`,
        configToken
      )
      .then()
      .catch((err) => {
        console.error("err", err);
      });

    await axios
      .post(`${ATLAS_URI}/postWorkoutGoalDetails`, workoutGoalData, configToken)
      .then(async (res) => {
        if (res.status == 200) {
          const string = renderToString(
            <ClientWorkoutPlanView
              workoutData={workoutGoalData.workoutPlanRelationshipData}
              clientInfo={clientInfo.data}
            />
          );
          const pdf = new jsPDF("p", "pt", [595.28, 841.89]);

          await pdf.html(string, {
            width: 300,
            windowWidth: 600,
            margin: [10, 10, 10, 10],
            autoPaging: "text",
          });

          const temp = pdf.output("blob");
          var data = new FormData();

          data.append("workout_plan", temp, `workout_plan.pdf`);

          await axios.put(
            `${ATLAS_URI}/uploadWorkoutPlan/${res.data.id}/${props.clientsData.Lead}`,
            data,
            configToken
          );

          // return

          props.setShowDetailPage(false);
        }
      })
      .catch((err) => {
        console.error("err:::: ", err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const isDataValid = (data) => {
    setErrorMessage(`Please select title and link`);
    if (data.customField && JSON.parse(data.customField).name == "other") {
      setErrorMessage("Enter a valid URL");

      if (
        !data?.customURLField?.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/
        )
      ) {
        return false;
      }
      return data.title && data.customField && data.customURLField;
    }

    if (data?.setCount && data?.setCount < 0) {
      setErrorMessage(`Provide set value as positive value`);
      return false;
    }
    return data.title && data.customField;
  };

  const handleChangeExistingTemplate = (e) => {
    setWorkoutPlan([]);

    if (!e.target.value) {
      setSelectedTemplateId("");
      setIsHidden(true);
      setStartDate("");
      setIsRestrict(false);
    } else {
      setSelectedTemplateId(e.target.value);
      setIsHidden(false);
      setIsRestrict(true);
      setisTemplateNameErr(false);

      axios
        .get(
          `${ATLAS_URI}/getWorkoutTemplateById/${e.target.value}`,
          configToken
        )
        .then((res) => {
          if (res.status === 200) {
            const workoutPlanArr = [];
            if (res.data.workout_details.length > 0) {
              let index = 0;
              let y = res.data.workout_details;
              // for (const workoutPlan1 of y) {

              const workoutObj = res.data.workout_details.map(
                (workoutInfo, idx) => {
                  let org_date = new Date(
                    new Date().toISOString().split("T")[0]
                  );

                  let date = new Date(org_date).getDate() + idx;

                  if (date <= 9) {
                    date = "0" + date;
                  }
                  let month;
                  let mnth = new Date(org_date).getMonth() + 1;

                  if (mnth <= 9) {
                    month = "0" + mnth;
                  }

                  let NumberOfdaysInAMonth = new Date(
                    org_date.getFullYear(),
                    org_date.getMonth() + 1,
                    0
                  ).getDate();

                  if (date > NumberOfdaysInAMonth) {
                    // date = setDate(new Date().getDate() + 1)

                    // if (date == 32) {
                    //   index = 0
                    // }

                    org_date.setMonth(org_date.getMonth() + 1, index + 1);
                    let nextMonth = new Date(org_date);

                    date = nextMonth.getDate();
                    index++;
                    if (date <= 9) {
                      date = "0" + date;
                    }

                    if (nextMonth.getMonth() + 1 <= 9) {
                      month = "0" + (nextMonth.getMonth() + 1);
                    } else {
                      month = nextMonth.getMonth() + 1;
                    }
                  }
                  let year = new Date(org_date).getFullYear();

                  let formatedDate = year + "-" + month + "-" + date;

                  workoutInfo.date = formatedDate;

                  const startDate = workoutInfo.date + "T00:00:00";
                  const endDate = workoutInfo.date + "T23:59:59";

                  let name,
                    youtube_link,
                    duration,
                    x_value,
                    title,
                    id,
                    customURLField,
                    setCount;

                  workoutInfo.workout_detail.map((item) => {
                    name = item.name;
                    youtube_link = item.youtube_link;
                    duration = item.duration;
                    x_value = item.x_value;
                    title = item.title;
                    id = item.id;
                    customURLField = item.customURLField;
                    setCount = item.setCount;
                  });

                  return {
                    title: title,
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    customField: JSON.stringify({
                      name,
                      youtube_link,
                      duration,
                      x_value,
                    }),
                    id: id,
                    workoutPlanDetailId: workoutInfo.id,
                    customURLField: customURLField,
                    setCount: setCount,
                  };
                }
              );

              workoutPlanArr.push(...workoutObj);

              // }
            }
            setWorkoutPlan((prevState) => {
              return [...prevState, ...workoutPlanArr];
            });
            if (res.data?.template_name !== null) {
              setTemplateName(res.data.template_name);
            } else if (res.status === 401) {
              history.push("/");
            }
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const removeDuplicatesCopy = (wrkoutPlanDtls) => {
    let workoutPlanDetails = wrkoutPlanDtls;

    workoutPlanDetails = workoutPlanDetails.map((workoutPlanInfo) => {
      const endDate = new Date(workoutPlanInfo.endDate);
      const startDate = new Date(workoutPlanInfo.startDate);

      const startAt = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      const endAt = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;
      let date = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      // let date;
      // if (startDate.getDate() <= 9) {
      //   date = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-0${startDate.getDate()}`

      // }
      // else {
      //   date = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
      // }
      // if ((startDate.getMonth() + 1) <= 9) {
      //   date = `${startDate.getFullYear()}-0${startDate.getMonth() + 1}-${startDate.getDate()}`

      // }
      // else {
      //   date = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
      // }
      // let date = new Date(startDate).getDate();
      // if (date <= 9) {
      //   date = '0' + date
      // }
      // let month;
      // let mnth = new Date(startDate).getMonth() + 1;

      // if (mnth <= 9) {

      //   month = '0' + mnth;
      // }
      // let year = new Date(startDate).getFullYear();

      // let formatedDate = year + '-' + month + '-' + date;

      const customField = JSON.parse(workoutPlanInfo.customField);
      const { name, youtube_link, duration, x_value } = customField;

      let customURLField = null;
      let setCount = 0;

      if (workoutPlanInfo.customURLField) {
        customURLField = workoutPlanInfo.customURLField;
      }

      if (workoutPlanInfo.setCount) {
        setCount = workoutPlanInfo.setCount;
      }

      // const youtube_link = workoutPlanInfo.customField
      const title = workoutPlanInfo.text
        ? workoutPlanInfo.text
        : workoutPlanInfo.title;

      let id = undefined;
      if (workoutPlanInfo.workoutPlanDetailId) {
        id = workoutPlanInfo.workoutPlanDetailId;
      }
      return {
        id,
        date: date,
        workout_details: {
          id: workoutPlanInfo.id,
          youtube_link,
          name,
          duration,
          title,
          x_value,
          customURLField,
          setCount,
          date,
        },
      };

      // return {
      //   id, startDate: new Date(startDate), endDate: new Date(endDate), workout_details: { id: workoutPlanInfo.id, youtube_link, name, duration, title, x_value, customURLField, setCount }
      // }
      //  Recently added.........

      // return {
      //   title: title,
      //   startDate: new Date(startDate),
      //   endDate: new Date(endDate),
      //   customField: JSON.stringify({ name, youtube_link, duration, x_value }),
      //   id: id,
      //   workoutPlanDetailId: workoutInfo.id,
      //   customURLField: customURLField,
      //   setCount: setCount
      // }
    });

    const postData = [...new Set(workoutPlanDetails.map((x) => x.date))].map(
      (x) => ({ id: undefined, date: x, workout_detail: [] })
    );

    // const postData = removeDuplicates();

    workoutPlanDetails.forEach((x) => {
      if (postData.find((y) => y.date === x.date)) {
        return {
          workoutPlanDetails: postData
            .find((y) => y.date === x.date)
            .workout_detail.push(x.workout_details),
          id: (postData.find((y) => y.date === x.date).id = x.id),
        };
      }
    });

    return postData;
  };

  const removeDuplicates = () => {
    let workoutPlanDetails = workoutPlan;

    workoutPlanDetails = workoutPlanDetails.map((workoutPlanInfo) => {
      const endDate = new Date(workoutPlanInfo.endDate);
      const startDate = new Date(workoutPlanInfo.startDate);

      const startAt = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      const endAt = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;

      const date = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;

      const customField = JSON.parse(workoutPlanInfo.customField);
      const { name, youtube_link, duration, x_value } = customField;

      // const youtube_link = workoutPlanInfo.customField
      const title = workoutPlanInfo.text
        ? workoutPlanInfo.text
        : workoutPlanInfo.title;

      let id = undefined;
      if (workoutPlanInfo.workoutPlanDetailId) {
        id = workoutPlanInfo.workoutPlanDetailId;
      }
      return {
        id,
        date: date,
        workout_details: {
          id: workoutPlanInfo.id,
          youtube_link,
          name,
          duration,
          title,
          x_value,
        },
      };
    });

    const postData = [...new Set(workoutPlanDetails.map((x) => x.date))].map(
      (x) => ({ id: undefined, date: x, workout_details: [] })
    );

    workoutPlanDetails.forEach((x) => {
      if (postData.find((y) => y.date === x.date)) {
        return {
          workoutPlanDetails: postData
            .find((y) => y.date === x.date)
            .workout_details.push(x.workout_details),
          id: (postData.find((y) => y.date === x.date).id = x.id),
        };
      }
    });

    return postData;
  };

  const UpdateTemplate = (e) => {
    e.preventDefault();

    if (templateName !== "" && workoutPlan.length > 0) {
      setisTemplateNameErr(false);

      const unquieData = removeDuplicatesCopy(workoutPlan);

      let customizedSchedulerData = unquieData.map(({ date, id, ...rest }) => {
        return rest;
      });

      var request = {
        id: selectedTemplateId ? selectedTemplateId : id,
        template_name: templateName,
        createdBy: operator.id,
        workout_details: customizedSchedulerData,
      };

      axios
        .put(
          `${ATLAS_URI}/updateWorkoutTemplateById/${
            selectedTemplateId ? selectedTemplateId : id
          }`,
          request,
          configToken
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Template Updated Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              history.push("/Templates/workoutTemplate");
            }, 7000);
          } else if (res.status === 400) {
            toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => console.error(err));
    } else if (templateName === "") {
      setisTemplateNameErr(true);
    } else if (workoutPlan.length === 0) {
      toast.error("Please Schedule Your Plan", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const SaveTemplate = (e) => {
    e.preventDefault();

    if (templateName !== "" && workoutPlan.length > 0) {
      setisTemplateNameErr(false);

      const unquieData = removeDuplicatesCopy(workoutPlan);

      let customizedSchedulerData = unquieData.map(({ date, id, ...rest }) => {
        return rest;
      });

      var request = {
        template_name: templateName,
        createdBy: operator.id,
        workout_details: customizedSchedulerData,
      };

      axios
        .post(`${ATLAS_URI}/postWorkoutPlanTemplate`, request, configToken)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Template Created Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            if (props?.from !== undefined) {
              window.location.reload();
            } else {
              setTimeout(() => {
                history.push("/Templates/workoutTemplate");
              }, 7000);
            }
          } else if (res.status === 400) {
            toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => console.error(err));
    } else if (templateName === "") {
      setisTemplateNameErr(true);
    } else if (workoutPlan.length === 0) {
      toast.error("Please Schedule Your Plan", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleChangeTemplateName = (e) => {
    setTemplateName(e.target.value);
    if (e.target.value === "") {
      // setIsEnable(true);
      setisTemplateNameErr(true);
      setIsEnableDropdown(false);
    } else {
      setisTemplateNameErr(false);
      // setIsEnable(false);
      setIsEnableDropdown(true);
    }
  };
  const handleStartChange = (e) => {
    setStartDate(e.target.value);
    let date = e.target.value,
      selectedTemplate;
    setWorkoutPlan([]);

    /*  here line No 1160 'id' was replaced by selectedTemplateId and also in line no 1162  */
    if (selectedTemplateId !== undefined) {
      selectedTemplate = workoutTemplate_list.filter(
        (item) => item.id === parseInt(selectedTemplateId)
      );
    } else {
      selectedTemplate = workoutTemplate_list.filter(
        (item) => item.id === parseInt(selectedTemplateId)
      );
    }

    getSchedulerData(date, selectedTemplate);
  };
  let location = useLocation();
  const goBack = () => {
    // return location.state.setShowDetailPage
    // location.state.setShowDetailPage=false
    // props.setShowDetailPage(false)
    // history.goBack()
    if (
      id &&
      pathname.includes(`/Templates/workoutTemplate/${location?.state?.id}`)
    ) {
      history.goBack();
    } else {
      props.setShowDetailPage(false);
    }
  };

  return (
    <section>
      <div className="box-body bozero mx5p">
        <ArrowBackIcon
          style={{ fontSize: 30 }}
          tooltip="back"
          onClick={() => goBack()}
        />

        <div className="w-100">
          <div className="row">
            <div
              className="col"
              style={{ display: isTemplateZone ? "none" : "" }}
            >
              <form onSubmit={saveWorkoutPlan} className="col">
                <div className="box-body bozero mx5p">
                  <div className="row">
                    <div>
                      <div className="form-group">
                        <label>Workout plan Name</label>{" "}
                        <small className="req"> *</small>
                        <input
                          disabled={!props.editPermission}
                          name="Name"
                          type="text"
                          className="form-control"
                          pattern="^[a-zA-Z0-9]+$"
                          required
                          value={workoutPlanName}
                          onChange={(e) => {
                            setWorkoutPlanName(e.target.value);
                          }}
                        />
                        <label>Target Calorie to burnt</label>{" "}
                        <small className="req"> *</small>
                        <input
                          name="Name"
                          type="number"
                          min={1}
                          disabled={!props.editPermission}
                          className="form-control"
                          required
                          value={targetCalorie}
                          onChange={(e) => setTargetCalorie(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {props.editPermission && (
                    <button
                      className="btn btn-primary ml-5"
                      style={{ marginTop: "19px" }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </form>
            </div>
            <div
              className="col"
              style={{ display: isTemplateZone ? "none" : "" }}
            >
              <form className="col">
                <div className="box-body bozero mx5p">
                  <div className="row">
                    <div>
                      <div className="form-group">
                        <label>Select from Existing Template</label>
                        <select
                          className="form-control"
                          value={selectedTemplateId}
                          onChange={handleChangeExistingTemplate}
                          disabled={isEnableDropdown}
                        >
                          <option value="">Select</option>
                          {workoutTemplate_list &&
                            workoutTemplate_list.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.template_name}
                              </option>
                            ))}
                        </select>
                        <div style={{ display: isHidden ? "none" : "" }}>
                          <label>Start Date</label>{" "}
                          <small className="req"> *</small>
                          <input
                            className="form-control"
                            type="date"
                            name="startDate"
                            min={new Date().toISOString().split("T")[0]}
                            value={startDate}
                            onChange={handleStartChange}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        </div>

                        <label>
                          Enter Template Name<span className="req"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={isRestrict}
                          value={templateName}
                          onChange={(e) => handleChangeTemplateName(e)}
                        />
                        <p className="req">
                          {TemplateNameErr ? "Template Name is mandatory" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary ml-5"
                    onClick={(e) => UpdateTemplate(e)}
                    style={{
                      display: isHidden ? "none" : "",
                      margin: "2px 81px",
                    }}
                  >
                    Update Template
                  </button>

                  <button
                    className="btn btn-primary ml-5"
                    onClick={(e) => SaveTemplate(e)}
                    style={{ margin: "2px 81px" }}
                  >
                    Save As Template
                  </button>
                </div>
              </form>
            </div>

            <div className="col">
              <form className="col">
                <div className="box-body bozero mx5p">
                  <div className="row">
                    <div
                      className="col-md-6"
                      style={{ display: isTemplateZone ? "" : "none" }}
                    >
                      <div className="form-group">
                        <label>
                          Enter Template Name<span className="req"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={isRestrict}
                          value={templateName}
                          onChange={(e) => handleChangeTemplateName(e)}
                        />
                        <p className="req">
                          {TemplateNameErr ? "Template Name is mandatory" : ""}
                        </p>
                      </div>
                      <div
                        className="col-md-6"
                        style={{ display: isTemplateZone ? "" : "none" }}
                      >
                        <div className="form-group">
                          <label>Select from Existing Template</label>
                          <select
                            className="form-control"
                            value={selectedTemplateId}
                            onChange={handleChangeExistingTemplate}
                            disabled={isEnableDropdown}
                          >
                            <option value="">Select</option>
                            {workoutTemplate_list &&
                              workoutTemplate_list.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.template_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary ml-5"
                        onClick={(e) => UpdateTemplate(e)}
                        style={{ margin: "2px 81px" }}
                      >
                        Update Template
                      </button>
                      <button
                        className="btn btn-primary ml-5"
                        onClick={(e) => SaveTemplate(e)}
                        style={{ margin: "2px 81px" }}
                      >
                        Create New Template
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientWorkoutPlan;
