import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ENDPOINT_PASSWORD } from "../Constants";
import { PlivoContext } from "./PlivoContext";

import {
  closeInputModal,
  openInputModalLeads,
  openFollowUpModal,
  closeFollowUpModal,
  openEmailModal,
  closeEmailModal,
  updateFollowUp,
  updateFollowUpStatus,
  deleteFollowUp,
} from "../Helpers/ModalHandler";
import { Button, Modal } from "react-bootstrap";
import FollowupModal from "../components/FollowupModal";
import stateContext from "./StateContext";
// import Interaction from "../Pages/Leads/Interaction/Interaction";
import InteractionForm from "../components/CallInteractionForm";
import CallStatusBtn from "../components/CallStatusBtn";

import "./Plivo.css";

const Plivo = (props) => {
  const history = useHistory();

  const contextState = useContext(stateContext);

  const { operator } = contextState.state;

  const [currentCallStatus, setCurrentCallStatus] = useState("");
  const [duration, setDuration] = useState("");
  const [isTimerOn, setIsTimerOn] = useState(false);

  const [callFromName, setCallFromName] = useState("");
  const [callFromNumber, setCallFromNumber] = useState("");
  const [isIncomingCall, setIsIncomingCall] = useState(false);

  const [isLeadInboundCaller, setIsLeadInboundCaller] = useState(false);

  const [newTableRowPlivo, setNewTableRowPlivo] = useState({
    id: "",
    email_id: "",
    contact_number: "",
    createdAt: "",
  });

  const [statePlivo, setStatePlivo] = useState({
    tableBodyList: [],
    userName: [],
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
    isFollowUpModalOpen: false,
  });

  const [microphoneDevices, setMicrophoneDevices] = useState([]);
  const [speakerDevices, setSpeakerDevices] = useState([]);

  const [interactionForm, setInteractionForm] = useState({
    open: false,
    details: null,
  });

  const options = {
    debug: "ERROR",
    permOnClick: false,
    codecs: ["OPUS", "PCMU"],
    enableIPV6: false,
    audioConstraints: { optional: [{ googAutoGainControl: false }] },
    dscp: true,
    enableTracking: true,
  };

  const plivo = new window.Plivo(options);

  useEffect(() => {
    if (interactionForm?.details?.callType === "telecmi") {
      return;
    }
    plivo.client.on("onMediaPermission", (evt) => {});

    plivo.client.audio
      .availableDevices()
      .then(function (e) {
        let speakerarr = [];
        let microPhoneArr = [];

        e.forEach(function (dev) {
          if (dev.label && dev.kind == "audiooutput") {
            speakerarr.push(dev);
          }
          if (dev.label && dev.kind == "audioinput") {
            microPhoneArr.push(dev);
          }

          // if(dev.label && dev.kind == "audiooutput"){
          //   $('#ringtoneDev').append('<option value='+dev.deviceId+'>'+dev.label+'</option>');
          //   $('#speakerDev').append('<option value='+dev.deviceId+'>'+dev.label+'</option>')
          // }
        });
        const key = "deviceId";
        const uniqueSpeakerDevices = [
          ...new Map(speakerarr.map((item) => [item[key], item])).values(),
        ];

        const uniqueMicroPhoneDevices = [
          ...new Map(microPhoneArr.map((item) => [item[key], item])).values(),
        ];

        setMicrophoneDevices(uniqueMicroPhoneDevices);
        setSpeakerDevices(uniqueSpeakerDevices);
      })
      .catch(function (error) {
        console.error(error);
      });

    plivo.client.login(operator.plivoEndPointUserName, ENDPOINT_PASSWORD);
    plivo.client.on("onLogin", () => {});

    plivo.client.on("onLoginFailed", () => {});

    plivo.client.on("onCallFailed", (cause, callInfo) => {
      setCurrentCallStatus(cause);
    });

    // Attach event listeners to the Plivo SDK instance
    plivo.client.on("onCallRemoteRinging", (callInfo) => {
      setDuration(0);

      setCurrentCallStatus("ringing");

      if (callInfo.extraHeaders["X-PH-page"] == 1) {
        history.push({
          pathname: "/Leads/leadView/" + callInfo.extraHeaders["X-PH-leadId"],
          state: {
            currentCallUUID: plivo.client.getCallUUID(),
            isCallHistoryView: true,
            // currentCallStatus: currentCallStatus
          },
        });
      }

      if (callInfo.extraHeaders["X-PH-page"] == 2) {
        setNewTableRowPlivo({ id: callInfo.extraHeaders["X-PH-leadId"] });
        setStatePlivo((prevState) => ({
          ...prevState,
          currentCallUUID: plivo.client.getCallUUID(),
        }));
        openFollowUpModal(
          callInfo.extraHeaders["X-PH-leadId"],
          statePlivo,
          setStatePlivo
        );
      }
    });

    plivo.client.on("onIncomingCall", handleIncomingCall);

    function handleIncomingCall(call, extraHeaders) {
      // Handle the incoming call event here

      setIsIncomingCall(true);

      setCallFromNumber(extraHeaders["X-Ph-Callerphonenumber"]);
      setCallFromName(extraHeaders["X-Ph-Leadname"]);
      setIsLeadInboundCaller(extraHeaders["X-PH-isLead"]);
      setIsIncomingCall(true);
    }

    let interval = null;

    plivo.client.on("onCallAnswered", (callInfo) => {
      if (callInfo.direction == "incoming") {
        setIsIncomingCall(false);

        if (callInfo.extraHeaders["X-Ph-Islead"] == "true") {
          history.push({
            pathname: "/Leads/leadView/" + callInfo.extraHeaders["X-Ph-Leadid"],
            state: {
              currentCallUUID: plivo.client.getCallUUID(),
              isCallHistoryView: true,
              // currentCallStatus: currentCallStatus
            },
          });
        } else {
          setNewTableRowPlivo({ id: callInfo.extraHeaders["X-Ph-Leadid"] });
          setStatePlivo((prevState) => ({
            ...prevState,
            tableBodyList: [{ id: callInfo.extraHeaders["X-Ph-Leadid"] }],
          }));
          setStatePlivo((prevState) => ({
            ...prevState,
            currentCallUUID: plivo.client.getCallUUID(),
          }));
          openFollowUpModal(
            callInfo.extraHeaders["X-Ph-Leadid"],
            statePlivo,
            setStatePlivo
          );
        }
      }

      const startDate = new Date();
      setCurrentCallStatus("answered");

      interval = setInterval(() => {
        let endDate = new Date();
        let elapsed = endDate - startDate;
        let hours = Math.floor(elapsed / 3600000);
        let minutes = Math.floor((elapsed - hours * 3600000) / 60000);
        let seconds = Math.floor(
          (elapsed - hours * 3600000 - minutes * 60000) / 1000
        );
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        let display = hours + ":" + minutes + ":" + seconds;

        setDuration(display);
      }, 1000);
    });

    plivo.client.on("onCallTerminated", () => {
      setCurrentCallStatus("ended");
      clearInterval(interval);
      setIsIncomingCall(false);
    });
  }, [operator]);

  const navigateInteraction = () => {
    let pathname = window.location.pathname;
    if (pathname.includes("leads")) {
      // history.push('/')
    }
  };

  const handleReset = () => {
    setCurrentCallStatus("");
    setDuration("");
  };

  const endCall = () => {
    // plivo.client.hangup();
    setInteractionForm({
      ...interactionForm,
      details: {
        ...interactionForm?.details,
        open: true,
      },
    });
  };

  const setCallFlag = (data) => {
    setInteractionForm({
      ...interactionForm,
      details: {
        ...interactionForm?.details,
        callFlag: data,
      },
    });
  };

  const closeModal = (data) => {
    if (
      currentCallStatus === "ended" ||
      currentCallStatus === "Canceled" ||
      currentCallStatus === "Busy"
    ) {
      setInteractionForm({
        ...interactionForm,
        open: false,
        details: null,
      });
      setCurrentCallStatus("");
    } else {
      setInteractionForm({
        ...interactionForm,
        details: {
          ...interactionForm?.details,
          open: data,
        },
      });
    }
  };

  const statusColor = () => {
    switch (currentCallStatus) {
      case "answered":
        return "red";
      case "ringing":
        return "#4B8E4C";
      case "w":
        return "#F8BB43";
      default:
        return "#CCCCCC";
    }
  };

  return (
    <PlivoContext.Provider
      value={{
        handleReset,
        plivo,
        setCurrentCallStatus,
        currentCallStatus,
        duration,
        setDuration,
        isTimerOn,
        setIsTimerOn,
        newTableRowPlivo,
        setNewTableRowPlivo,
        setStatePlivo,
        statePlivo,
        callFromName,
        setCallFromName,
        callFromNumber,
        setCallFromNumber,
        isIncomingCall,
        setIsIncomingCall,
        microphoneDevices,
        speakerDevices,
        interactionForm,
        setInteractionForm,
      }}
    >
      <Modal show={isIncomingCall} onHide={() => setIsIncomingCall(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Incoming call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Call From Lead: {callFromName}</h5>
          <h5>Call From Number: {callFromNumber}</h5>
          <Button onClick={() => plivo.client.answer()}>Answer</Button>
          <Button onClick={() => plivo.client.reject()}>Reject</Button>
        </Modal.Body>
      </Modal>

      {interactionForm.open && currentCallStatus !== "" && (
        <CallStatusBtn
          endCall={endCall}
          statusColor={statusColor}
          duration={duration}
          currentCallStatus={currentCallStatus}
          setInteractionForm={setInteractionForm}
          interactionForm={interactionForm}
        />
      )}

      <Modal
        show={interactionForm?.details?.open}
        onHide={() => closeModal(false)}
      >
        <Modal.Body style={{ margin: "0" }}>
          <InteractionForm
            callFlag={interactionForm?.details?.callFlag}
            callType={interactionForm?.details?.callType}
            setCallFlag={setCallFlag}
            leadId={interactionForm?.details?.leadId}
            onSetIsBackDrop={() => null}
          />
        </Modal.Body>
      </Modal>

      {/* {statePlivo.isFollowUpModalOpen && (
        <FollowupModal
          state={statePlivo}
          newTableRow={newTableRowPlivo}
          closeFollowUpModal={(e) => closeFollowUpModal(setStatePlivo)}
          updateFollowUp={(e) =>
            updateFollowUp(e, contextState, statePlivo, setStatePlivo)
          }
          deleteFollowUp={(e) =>
            deleteFollowUp(e, contextState, statePlivo, setStatePlivo)
          }
          updateFollowUpStatus={(e) =>
            updateFollowUpStatus(e, contextState, statePlivo, setStatePlivo)
          }
          currentCallUUID={statePlivo.currentCallUUID}
        />
      )} */}

      {props.children}
    </PlivoContext.Provider>
  );
};

export default Plivo;
