import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import Dialog from './Dialog';
import SelectBox from './SelectBox'
import { changeHandler as oldChangeHandler } from '../Helpers/ChangeHandler';
function BulkUpdateModal(props) {


    const [state, setState] = useState({
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        },
        newTableRow: { AssignedTo: "Select" },
        assignList: [],
        configToken: {}
    })
//  const [show, setShow] = useState(true)
//  function Show(){
//     setShow(false)
//  }

    useEffect(() => {

        let x = props.state.userList
        // if (x.filter(x => x.id && x.id === 0).length === 0) {
        //     x.unshift({
        //         id: 0,
        //         AssignedTo: "Un-Assign All Leads"
        //     })
        // }
        setState(prevState => ({
            ...prevState,
            assignList: x,
        }))

    }, []);


    function updateLeads(e) {
        e.preventDefault();
        const token = (JSON.parse(window.localStorage.getItem('operator')).token)
        const configToken = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        axios.get(`${props.ATLAS_URI}/updateLeadsAssignment/${props.SelectedLeads}/${state.newTableRow.AssignedTo}`, configToken)
            .then(() => {
                window.location.reload();
            }).catch(err => console.log(err))
    }
    function changeHandler(e) {
        oldChangeHandler(e, state, setState);
    }

    return (

        <Modal show={props.state.isModalOpen} onHide={props.closeModal}>
            <Dialog
                onClose={(e) => setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "" } }))}
                dialogInfo={state.dialogInfo}
            />
            <Modal.Header closeButton>
                <Modal.Title>Bulk Update</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="">
                        <div className="taskFieldArea">
                            <form onSubmit={updateLeads}>
                                <h5><strong>Selected Leads: </strong> {props.SelectedLeads.length}</h5>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <SelectBox
                                                label="Assigned To"
                                                name="AssignedTo"
                                                options={state.assignList}
                                                attributeShown="AssignedTo"
                                                changeHandler={changeHandler}
                                                value={state.newTableRow.AssignedTo}
                                                resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, AssignedTo: "Select" } }))}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="box-footer pr0">
                                    <button className="btn btn-info pull-right"  >Save</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

}

export default BulkUpdateModal