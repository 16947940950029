import Modal from "../../components/Modal/Modal";

import {
  Typography,
  TextField,
  FormHelperText,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { axiosFetch } from "../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const Description = ({ isOpen, onClose, flag, editRow }) => {
  const form = useForm({
    defaultValues: { notes: "" },
  });

  const { control, handleSubmit, reset } = form;

  const onSubmit = async (formData) => {
    try {
      if (flag === "complete") {
        const request = await axiosFetch({
          url: `/editTask?taskId=${editRow.id}`,
          method: "put",
          requestConfig: {
            ...formData,
            is_completed: true,
          },
        });

        if (request.status === 200) {
          toast.success(request.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          handleClose();
        }
      } else if (flag === "cancel") {
        const request = await axiosFetch({
          url: `/cancelTask?taskId=${editRow.id}`,
          method: "put",
          requestConfig: formData,
        });

        if (request.status === 200) {
          toast.success(request.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                Description
              </Typography>
              <Controller
                name={`notes`}
                rules={{ required: "required" }}
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      error={!!error}
                    />
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </Grid>

            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#2a2a2a",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
};

export default Description;
