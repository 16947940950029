import { Grid, Typography, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const unitList = ["hours", "days", "month", "years"];

const AddDuration = ({
  id,
  currentRow,
  onInputChange,
  handleClose,
  isView,
}) => {
  const handleUnit = (_, value) => {
    onInputChange(id, "unit", value);
  };

  return (
    <div
      style={{ display: "flex", gap: "20px", width: "100%", padding: "20px" }}
    >
      <Grid item xs={2}>
        <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
          {"Duration"}
        </Typography>
        <TextField
          placeholder={"Enter here"}
          size="small"
          fullWidth
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: { min: 1 },
          }}
          name="duration"
          disabled={isView}
          value={currentRow.duration}
          onChange={(e) => onInputChange(id, e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
          {"Duration Unit"}
        </Typography>

        <FormControl style={{ width: "100%" }} size="small">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => handleUnit(id, e.target.value)}
            disabled={isView}
            value={currentRow.unit}
          >
            <MenuItem value=" " disabled>
              Select
            </MenuItem>
            {unitList.map((value, index) => (
              <MenuItem value={value} key={index}>
                <div className="filter-date">
                  <span>{value}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
          {"Duration Description"}
        </Typography>
        <TextField
          placeholder={"Enter here"}
          size="small"
          fullWidth
          variant="outlined"
          name="desc"
          value={currentRow.desc}
          disabled={isView}
          onChange={(e) => onInputChange(id, e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={2} className="d-flex align-items-center mt-4 ">
        <CloseIcon
          style={{ fontSize: "25px", color: "#6B6B6B", cursor: "pointer" }}
          onClick={() => {
            handleClose(id);
          }}
        />
      </Grid>
    </div>
  );
};

export default AddDuration;
