import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormControl, FormHelperText } from '@mui/material';

 const ControlledTextField = ({
  name,
  control,
  label,
  error,
  rules,
  fullWidth,
  placeholder,
  helperText,
  isDisableColumn = false,
  onChangeCallback,
  maxlength,
  fieldtype = 'text'
}) => {

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState
        }) => (
          <TextField
            disabled={isDisableColumn}
            type={fieldtype}
            onChange={(event, newValue) => {
              onChange(event);
              onChangeCallback &&
                onChangeCallback(event.target.value);
            }}
            value={value}
            error={error}
            autoComplete='off'
            fullWidth
            placeholder={placeholder}
            label={label}
            variant="outlined"
            inputProps={{ maxLength: maxlength ? maxlength : 10000000000000 }}
            InputLabelProps={{ shrink: value, maxLength: maxlength ? maxlength : 10000000000000 }}
            onInput={(e) => { e.target.value = maxlength && e.target.value ? e.target.value?.toString()?.slice(0, maxlength) : e.target.value }}
          // {...validations}
          />
        )}
      />
      {/* {helperText && !error && (
        <FormHelperText>{helperText}</FormHelperText>
      )} */}
      {error && (
        <FormHelperText>{error ? helperText : ' '}</FormHelperText>
      )}
    </FormControl>
  );
};
export default  ControlledTextField;
