import "./ListOfPayments.css";
import * as React from "react";

import { TextField, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ImageIcon from "@mui/icons-material/Image";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";

import { useEffect, useContext, useState } from "react";

import stateContext from "../../context/StateContext";

import { axiosFetch } from "../../Utlis/AxiosFetch";

import Modal from "../../components/Modal/Modal";
import ActionMenu from "./modals/ActionMenu";
import Card from "../../components/Card/Card";

const paymentStatus = [
  { label: "Not Verified", value: "Not Verified" },
  { label: "Pending", value: "Pending" },
  { label: "Payment Verified", value: "Payment Verified" },
  { label: "Partial Payment", value: "Partial Payment" },
  { label: "Excess Amount Received", value: "Excess Amount Received" },
  { label: "Less Amount Received", value: "Less Amount Received" },
];

export default function ListOfPaymnets() {
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;

  const [search, setSearch] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [anchorEl, setanchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState([]);

  const [count, setCount] = useState(0);
  const [row, setRow] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const [filter, setFilter] = useState({
    items: [],
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });
  function onFilterChange(filters) {
    setFilter(filters);
  }

  const getPaymentHistory = async () => {
    let url;

    if (search)
      url = `/getAllPaymentHistory?page=${pageNo}&pageSize=${paginationModel.pageSize}&search=${search}`;
    else
      url = `/getAllPaymentHistory?page=${pageNo}&pageSize=${paginationModel.pageSize}`;

    const response = await axiosFetch({
      url: url,
      requestConfig: { ...filter },
      method: "post",
    });
    if (response.status === 200) {
      setRow(response.data.rows);
      setCount(response.data.count);
    }
  };

  const handleSearch = () => {
    getPaymentHistory();
  };

  const handleClear = () => {
    setSearch("");
  };

  const handleClose = () => {
    setanchorEl(null);
  };

  const handleClick = (e, row) => {
    setanchorEl(e.target);
    setCurrentRow(row);
  };

  const handleChangePaginationModel = (model) => {
    setPaginationModel(model);
    setPageNo((pre) => {
      return model.page + 1;
    });
  };

  useEffect(() => {
    getPaymentHistory();
  }, [paginationModel, filter]);

  useEffect(() => {
    if (!search) {
      getPaymentHistory();
    }
  }, [search]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "clientId",
      headerName: "Cliend ID",
      width: 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "leadId",
      headerName: "Lead ID",
      width: 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "leadName",
      headerName: "name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "leadEmail",
      headerName: "Email",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "leadContactNo",
      headerName: "Contact",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "accountName",
      headerName: "AccountName",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "programName",
      headerName: "Program Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "programId",
      headerName: "Program Id",
      width: 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "programAmount",
      headerName: "Amount",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paymentImageKey",
      headerName: "Payment Image",
      width: 200,
      headerClassName: "super-app-theme--header",

      renderCell: (params) => {
        if (params?.row?.payment_image) {
          return params.row.payment_image.map((img) => (
            <img
              alt="payment"
              style={{
                padding: "10px",
                width: "50px",
                height: "50px",
                display: "inline-block",
                marginLeft: "8px",
                cursor: "pointer",
                objectFit: "cover",
              }}
              src={img}
              onClick={(e) => handleViewImage(e, img)}
            />
          ));
        } else {
          return (
            <ImageIcon
              style={{
                fontSize: "65px",
                color: "#ddd",
              }}
            />
          );
        }
      },
    },
    {
      field: "PaymentStatus",
      headerName: "Payment Status",
      width: 200,
      headerClassName: "super-app-theme--header",
      type: "singleSelect",
      valueOptions: paymentStatus.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      renderCell: (params) => {
        if (params?.row?.PaymentStatus?.status)
          return (
            <div
              style={{
                backgroundColor: params?.row?.PaymentStatus?.color,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
              }}
            >
              <span>{params.row.PaymentStatus.status}</span>
            </div>
          );
      },
    },
    {
      field: "is_succeeded",
      headerName: "Current Payment Status",
      width: 210,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return params?.row?.is_succeeded ? "Success" : "Failed";
      },
    },
    {
      field: "paymentType",
      headerName: "Payment Type",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paymentFrom",
      headerName: "Payment From",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paymentDate",
      headerName: "Date",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "programDuration",
      headerName: "Duration",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "salesperson",
      headerName: "Sales Person",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "action",
      headerName: "Actions",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className="action-btn"
            onClick={(e) => handleClick(e, params.row)}
          >
            <MoreHorizIcon />
          </div>
        );
      },
    },
  ];

  const handleViewImage = (e, image) => {
    setIsOpen(true);
    setImage(image);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  return (
    <Card>
      <div>
        <div className="payment-header">
          <h3 className="mt-2">List of Payments</h3>
          <Grid
            item
            xl={4}
            md={4}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {
                xl: "center",
                sm: "center,",
              },
              gap: "15px",
            }}
          >
            <TextField
              autoComplete="off"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              id="outlined-basic"
              variant="outlined"
              style={{ width: "250px" }}
              size="small"
            />
            <SearchIcon
              onClick={handleSearch}
              style={{ fontSize: "35px", color: "#2d2d2d", cursor: "pointer" }}
            />
            <CleaningServicesIcon
              onClick={handleClear}
              style={{ fontSize: "34px", color: "#2d2d2d", cursor: "pointer" }}
            />
          </Grid>
        </div>

        <div>
          <DataGridPro
            sx={{
              width: "100%",
              ".MuiDataGrid-virtualScroller": {
                height: "100%",
                minHeight: "500px",
              },
              ".super-app-theme--header": {
                backgroundColor: "#F4F4F4",
              },
              ".MuiDataGrid-row:nth-child(odd)": {
                backgroundColor: "#fff",
              },
              ".MuiDataGrid-row:nth-child(even)": {
                backgroundColor: "#F4F4F4",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold !important",
              },
            }}
            columns={columns}
            rows={row}
            rowCount={count}
            paginationMode="server"
            filterMode="server"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
              pinnedColumns: {
                left: [
                  "clientId",
                  "leadId",
                  "leadName",
                  "paymentImageKey",
                  "PaymentStatus",
                  "action",
                ],
              },
            }}
            pagination
            pageSizeOptions={[25, 50, 100]}
            page={paginationModel.page}
            onPaginationModelChange={handleChangePaginationModel}
            onFilterModelChange={onFilterChange}
            slots={{
              toolbar: CustomToolbar,
            }}
            localeText={{ noResultsOverlayLabel: "No result found !!!" }}
          />

          {/* <DataGridPro
            sx={{
              width: "100%",
              ".MuiDataGrid-virtualScroller": {
                height: "100%",
                minHeight: "500px",
              },
              ".super-app-theme--header": {
                backgroundColor: "#F4F4F4",
              },
              ".MuiDataGrid-row:nth-child(odd)": {
                backgroundColor: "#fff",
              },
              ".MuiDataGrid-row:nth-child(even)": {
                backgroundColor: "#F4F4F4",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold !important",
              },
            }}
            columns={columns}
            rows={row}
            rowCount={count}
            paginationMode="server"
            filterMode="server"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
              pinnedColumns: {
                left: [
                  "clientId",
                  "leadId",
                  "leadName",
                  "paymentImageKey",
                  "PaymentStatus",
                  "action",
                ],
              },
            }}
            pagination
            pageSizeOptions={[25, 50, 100]}
            page={paginationModel.page}
            onPaginationModelChange={handleChangePaginationModel}
            onFilterModelChange={onFilterChange}
            slots={{ toolbar: CustomToolbar }}
            localeText={{ noRowsLabel: "No result found !!!" }}
          /> */}
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <img src={image} alt="payment-screentshot" />
      </Modal>
      <ActionMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        currentRow={currentRow}
        getPaymentHistory={getPaymentHistory}
      />
    </Card>
  );
}
