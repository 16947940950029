import React from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PaymentIcon from "@mui/icons-material/Payment";
import HFReport from "../assets/SaveNotes.png";
function SideBarItem(props) {
  const titleLink = "/" + props.title.replace(/\s/g, "") + "/";

  const getLink = (str) => {
    return (
      titleLink +
      str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "")
    );
  };

  if (typeof props.subLinks === "undefined") {
    return (
      <Link className="sideBarBtn" to={props.redirect}>
        {props?.data == "2" ? (
          <WorkOutlineIcon />
        ) : props?.data == "3" ? (
          <img
            src={require("../assets/Payments.png")}
            style={{ width: "25px" }}
          />
        ) : props?.data == "4" ? (
          <img src={require("../assets/Notes.png")} style={{ width: "25px" }} />
        ) : props?.data == "5" ? (
          <img src={require("../assets/Plans.png")} style={{ width: "22px" }} />
        ) : props?.data == "6" ? (
          <img
            src={require("../assets/Progress.png")}
            style={{ width: "25px" }}
          />
        ) : props?.data == "7" ? (
          <img
            src={require("../assets/Testimonial.png")}
            style={{ width: "25px" }}
          />
        ) : props?.data == "8" ? (
          <img
            src={require("../assets/Retention.png")}
            style={{ width: "25px" }}
          />
        ) : props?.data == "9" ? (
          <img
            src={require("../assets/Profile.png")}
            style={{ width: "25px" }}
          />
        ) : props?.data == "10" ? (
          <img
            src={require("../assets/Interaction.png")}
            style={{ width: "25px" }}
          />
        ) : props?.data === "11" ? (
          <img src={HFReport} style={{ width: "25px" }} />
        ) : props?.data === "12" ? (
          <img
            src={require("../assets/History.png")}
            style={{ width: "25px" }}
          />
        ) : (
          <i className={props.icon}></i>
        )}
        {(props?.data == "2" ||
          props?.data == "3" ||
          props?.data == "4" ||
          props?.data == "5" ||
          props?.data == "6" ||
          props?.data == "7" ||
          props?.data == "8" ||
          props?.data == "9" ||
          props?.data == "12" ||
          props?.data == "11" ||
          props?.data == "10") && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}

        <b>{props.title}</b>
      </Link>
    );
  } else {
    return (
      <Accordion.Item eventKey={props.itemKey}>
        <Accordion.Header>
          <i className={props.icon}></i>&nbsp;
          <b>{props.title}</b>
        </Accordion.Header>

        <Accordion.Body>
          {props.subLinks.map((subLink) => (
            <Link
              key={subLink}
              className="accordion_submenu sideBarSubBtn"
              to={getLink(subLink)}
            >
              <i className="fas fa-chevron-double-right" />
              {subLink}
            </Link>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    );
  }
}

export default React.memo(SideBarItem);
