import React, { useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

import { Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import AddIcon from "@mui/icons-material/Add";
const ActionMenu = ({
  anchorEl,
  onClose,
  onEdit,
  onWhatsapp,
  onCall,
  onTask,
  onServetel,
  top,
  left,
}) => {
  const location = useLocation();
  // const pathName = location.pathname === "/Leads/listLeads" ? "lead" : "client";

  const [openCallMenu, setOpenCallMenu] = useState(null);

  const handleOpenCallMenu = (e) => {
    setOpenCallMenu(e.target);
    // onClose();
  };

  const handleCloseCallMenu = () => {
    setOpenCallMenu(false);
  };

  const handleServetelCall = () => {
    onServetel(true);
    setOpenCallMenu(false);
  };

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{
          top: top,
          left: left,
        }}
      >
        {/* onClick={(e) => setOpen(e.target)} */}
        <MenuItem onClick={onEdit}>
          <span className="mr-5">
            <EditIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Edit</span>
        </MenuItem>
        <MenuItem onClick={onWhatsapp}>
          <span className="mr-5">
            <WhatsAppIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">WhatsApp</span>
        </MenuItem>
        <MenuItem onClick={handleOpenCallMenu}>
          <span className="mr-5">
            <CallIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Call</span>
        </MenuItem>
        <MenuItem onClick={onTask}>
          <span className="mr-5">
            <AddIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Add Task</span>
        </MenuItem>
      </Menu>

      <Menu
        id="simple-menu"
        anchorEl={openCallMenu}
        open={Boolean(openCallMenu)}
        onClose={handleCloseCallMenu}
        style={{
          top: top,
          left: "-250",
        }}
      >
        <MenuItem onClick={onCall}>
          <span className="mr-5">
            <CallIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Plivo</span>
        </MenuItem>
        {/* {pathName === "lead" && (
        )} */}
        <MenuItem onClick={handleServetelCall}>
          <span className="mr-5">
            <CallIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Servetel</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionMenu;
