import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";

import "./WorkoutModel.css";

import Vmax from "./Vmax";
import Live from "./Live";
import Custom from "./Custom";
import { useSelector } from "react-redux";
// import { useEffect } from "react";

const Backdrop = (props) => {
  return (
    <div
      className="backdrop-vmax"
      onClick={props.closeModel}
      onKeyDown={props.closeModel}
    ></div>
  );
};

const Workout = (props) => {
  const editEvent = useSelector((state) => state.workoutPlan.editEvent);

  const [activeItem, setActiveItem] = useState("vmax");

  // const [cardHeight, setCardHeight] = useState(500);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  useEffect(() => {
    if (editEvent) {
      setActiveItem(editEvent.plan_type);
    } else {
      // Set a default active item when editEvent is false or null
      setActiveItem("vmax");
    }
  }, [editEvent]);

  return (
    <div className="card">
      <nav className="menu-bar-con">
        {/* <ul className="menu-bar"> */}
        {/* {editEvent && editEvent.plan_type === "vmax" && (
            <li
              className={`menu-item ${activeItem === "vmax" ? "active" : ""}`}
              onClick={() => handleItemClick("vmax")}
            >
              VMax
            </li>
          )} */}

        {/* {editEvent && editEvent.plan_type === "custom" && ( */}
        <p
          className={`menu-item ${activeItem === "custom" ? "active" : ""}`}
          onClick={() => handleItemClick("custom")}
          onKeyDown={() => handleItemClick("custom")}
          style={{ fontSize: "26px" }}
        >
          Edit Exercise
        </p>
        {/* )} */}
        {/* {editEvent && editEvent.plan_type === "live" && (
            <li
              className={`menu-item ${activeItem === "live" ? "active" : ""}`}
              onClick={() => handleItemClick("live")}
            >
              Live
            </li>
          )} */}
        {/* {!editEvent && (
            <>
              <li
                className={`menu-item ${activeItem === "vmax" ? "active" : ""}`}
                onClick={() => handleItemClick("vmax")}
              >
                VMax
              </li>
              <li
                className={`menu-item ${
                  activeItem === "custom" ? "active" : ""
                }`}
                onClick={() => handleItemClick("custom")}
              >
                Custom
              </li>
              <li
                className={`menu-item ${activeItem === "live" ? "active" : ""}`}
                onClick={() => handleItemClick("live")}
              >
                Live
              </li>
            </>
          )} */}
        {/* </ul> */}
      </nav>
      {/* {activeItem === "vmax" && <Vmax closeModel={props.closeModel} />}
      {activeItem === "live" && <Live closeModel={props.closeModel} />}
      {activeItem === "custom" && <Custom closeModel={props.closeModel} />} */}
      <Custom closeModel={props.closeModel} />
    </div>
  );
};

const WorkoutModel = (props) => {
  const backdropRoot = document.getElementById("workout__modal-backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const workoutModel = document.getElementById("workout__modal-root");
  if (!workoutModel) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop closeModel={props.closeModel} props={props} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <Workout closeModel={props.closeModel} />,
        workoutModel
      )}
    </>
  );
};

export default WorkoutModel;
