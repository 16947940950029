import axios from "axios";
import { useContext, useEffect } from "react";
import { ATLAS_URI } from "../../Constants";
import stateContext from '../../context/StateContext'

const ClientWorkoutPlanView = (props) => {



    props.workoutData.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    })

   

    const monthList = []

    const monthData = []
    props.workoutData?.forEach(workoutInfo => {
      
        let month = new Date(workoutInfo.date).getMonth()
        if (monthList.indexOf(month) == -1) {
            monthList.push(month)
            /* 
            monthData[monthData.length]['week'] = []
            monthData[monthData.length].week.push({
                day: [
                    { details: workoutInfo.workout_details.map(c => {
                        return {
                            link: c.youtube_link,
                            name: c.name,
                            title: c.title
                        }
                    }) }
                ]
            }) */
            monthData.push({
                week: [{
                    day: [
                        {
                            details: workoutInfo.workout_details.map(c => {
                                return {
                                    link: c.youtube_link,
                                    name: c.name,
                                    title: c.title,
                                    date: workoutInfo.date
                                }
                            })
                        }
                    ]
                }]
            })
        } else {
           

            if (monthData[monthList.indexOf(month)].week[monthData[monthList.indexOf(month)].week.length - 1].day.length <= 6) {
                monthData[monthList.indexOf(month)].week[monthData[monthList.indexOf(month)].week.length - 1].day.push({
                    details: workoutInfo.workout_details.map(c => {
                        return {
                            link: c.youtube_link,
                            name: c.name,
                            title: c.title,
                            date: workoutInfo.date
                        }
                    })
                })
            } else {
                monthData[monthList.indexOf(month)].week.push({
                    day: [
                        {
                            details: workoutInfo.workout_details.map(c => {
                                return {
                                    link: c.youtube_link,
                                    name: c.name,
                                    title: c.title,
                                    date: workoutInfo.date
                                }
                            })
                        }
                    ]
                })
            }

            return


            monthData[monthList.indexOf(month)].week.forEach(weekInfo => {
              
                if (weekInfo.day.length <= 6) {
                    weekInfo.day.push({
                        details: workoutInfo.workout_details.map(c => {
                            return {
                                link: c.youtube_link,
                                name: c.name,
                                title: c.title,
                                date: workoutInfo.date
                            }
                        })
                    })
                } else {

                  

                    monthData[monthList.indexOf(month)].week.push({
                        day: [
                            {
                                details: workoutInfo.workout_details.map(c => {
                                    return {
                                        link: c.youtube_link,
                                        name: c.name,
                                        title: c.title,
                                        date: workoutInfo.date
                                    }
                                })
                            }
                        ]
                    })

                    /* monthData[monthList.indexOf(month)].week[monthData[monthList.indexOf(month)].week.length] = {
                        day: [
                            { details: workoutInfo.workout_details.map(c => {
                                return {
                                    link: c.youtube_link,
                                    name: c.name,
                                    title: c.title
                                }
                            }) }
                        ]
                    } */

                    /*  monthData[monthList.indexOf(month)].week[monthData[monthList.indexOf(month)].week.length] = []
                     monthData[monthList.indexOf(month)].week.push({
                         day: [
                             {
                                 details: workoutInfo.workout_details.map(c => {
                                     return {
                                         link: c.youtube_link,
                                         name: c.name,
                                         title: c.title
                                     }
                                 })
                             }
                         ]
                     }) */

                    /* monthData[monthList.indexOf(month)].week.push({
                        // week: [{
                            day: [
                                { details: workoutInfo.workout_details.map(c => {
                                    return {
                                        link: c.youtube_link,
                                        name: c.name,
                                        title: c.title
                                    }
                                }) }
                            ]
                        // }]
                    }) */
                }
            });
        }
    })

   

    const workoutInfo = monthData.map(c => {
        c.week.map(a => {
            
            if (a.day.length < 6) {
                for (let index = a.day.length; index < 7; index++) {
                    // const element = array[index];

                    a.day[index] = null

                }
                /* 
                const lll = a.day.fill({link: '', name: '', title: ''}, a.day.length, 7)
                */
               
                return a
            }
        })
        return c
    })
    



    // axios.post(`${ATLAS_URI}/ttt`, workoutInfo, {})

    const jj = {
        "workout": [
            {
                "week": [
                    {
                        "day": [{ "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }]
                    },
                    {
                        "day": [{ "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }, { "details": { "link": "test", 'workoutName': 'Cardio' } }]
                    },
                    /*  {
                         "day": [{"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}]
                     },
                     {
                         "day": [{"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}]
                     } */
                ]
            },
            /*  {
                 "week": [
                     {
                         "day": [{"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}]
                     },
                     {
                         "day": [{"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}, {"details": {"link": "test"}}]
                     }
                 ]
             } */
        ]
    }
  

    /* const table__head {
        color: white;
        background-color: rgb(32, 38, 46);
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        padding: 5px 0;
    } */

    const tableStyle = {
        border: 'solid #000'
    }

    return (
        <>
            <div className="container">
                <div>
                    <div style={{
                        color: 'white',
                        backgroundColor: '#20262E',
                        fontWeight: 600,
                        fontSize: '100%',
                        textAlign: 'center',
                        padding: '1% 0'
                    }}>NAME:{props?.clientInfo?.Name}</div>
                    <table style={{ ...tableStyle, width: '100%' }}>
                        <tbody>
                            <tr className="text-center" style={tableStyle}>
                                <td className="bg__grey" colSpan="2" style={tableStyle}><strong>Trainer Name:{props?.clientInfo?.ClientTrainerInfo?.firstName} &nbsp; {props?.clientInfo?.ClientTrainerInfo?.lastName} Trainer Fitness Induction Date:{'{Fitness Induction Date}'} </strong></td>
                            </tr>
                            <tr style={tableStyle}>
                                <td style={tableStyle}><strong>Pain Points</strong></td>
                                <td style={tableStyle}>{'{Pain Points}'}</td>
                            </tr>
                            <tr style={tableStyle}>
                                <td style={tableStyle}><strong>Health Issues</strong></td>
                                <td style={tableStyle}>{props?.clientInfo?.LeadID?.current_medical_condition_string ? props?.clientInfo?.LeadID?.current_medical_condition_string : (props?.clientInfo?.LeadID?.current_medical_condition.length > 0 ? props?.clientInfo?.LeadID?.current_medical_condition.toString() : '')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <table style={tableStyle}>
                    <tbody className="bg__transparent">
                        <tr bgcolor="#4B4B4B" style={{ ...tableStyle, color: "#fff" }}>
                            <td className="text-center"><strong>#NAME</strong></td>
                            <td style={tableStyle} className="text-center"><strong>L1</strong></td>
                            <td style={tableStyle} className="text-center"><strong>Week Day 1</strong></td>
                            <td style={tableStyle} className="text-center"><strong>Week Day 2</strong></td>
                            <td style={tableStyle} className="text-center"><strong>Week Day 3</strong></td>
                            <td style={tableStyle} className="text-center"><strong>Week Day 4</strong></td>
                            <td style={tableStyle} className="text-center"><strong>Week Day 5</strong></td>
                            <td style={tableStyle} className="text-center"><strong>Week Day 6</strong></td>
                            <td style={tableStyle} className="text-center"><strong>Week Day 7</strong></td>
                        </tr>
                        {
                            workoutInfo.map((data, index1) => {
                               
                                return (
                                    <tr style={tableStyle} key={index1} bgcolor="#FAF8F1">
                                        <td className="text-center" rowSpan={(data.week.length * 3) + 1}><strong>Month {index1 + 1}</strong></td>
                                        
                                        {
                                            data.week.map((weekData, index2) => {
                                                return (
                                                    <>
                                                    <tr>
                              <td style={tableStyle} className="text-center"><strong>Date</strong></td>
                              {weekData.day?.map(dayData=>{
                              return(
                               
                                      <td style={tableStyle} className="text-center" ><strong>{dayData?.details ? dayData.details.map(d => d.date.split('-').reverse().join('-')) : '-'}</strong></td>
                                
                               
                              )
                              })}
                            </tr>
                                                        <tr bgcolor="#FAF8F1">
                                                            <td style={tableStyle} className="text-center"><strong>Week {index2 + 1}</strong></td>
                                                            {
                                                                weekData.day.map(dayData => {
                                                                   
                                                                    return (
                                                                        <>

                                                                            <td style={tableStyle} className="text-center"><strong>{dayData?.details ? dayData.details.map(d => d.title).toString() : 'Rest'}</strong></td>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </tr>

                                                        <tr bgcolor="#FAF8F1">
                                                            <td style={tableStyle} className="text-center"><strong>Workout</strong></td>
                                                            {
                                                                weekData.day.map(dayData => {
                                                                    return (
                                                                        <>

                                                                            {/* <td className="text-center"><strong>{dayData.details.workoutName}</strong></td>  */}
                                                                            {/* <td className="text-center"><strong>{dayData?.details ? dayData.details.map(d => d.link).toString(): 'Rest'}</strong></td>  */}
                                                                            <td style={tableStyle} className="text-center">
                                                                                {
                                                                                    dayData?.details ? dayData?.details.map(d => {
                                                                                        return <a href={d.link} target="_blank">{d.name}</a>
                                                                                    }) : 'Rest'
                                                                                }
                                                                            </td>

                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </tr>

                                                    </>
                                                )
                                            })
                                        }


                                    </tr>
                                )
                            })
                        }
                        {/* <tr bgcolor="#FAF8F1">
               <td className="text-center"rowspan="9"><strong>1st Month</strong></td>
               <td className="text-center"><strong>Week 1</strong></td>
               <td className="text-center"><strong>Cardio</strong></td>
               <td className="text-center"><strong>Body Weight Strength Training</strong></td>
               <td className="text-center"><strong>Cardio</strong></td>
               <td className="text-center"><strong>Body Weight Strength Training</strong></td>
               <td className="text-center"><strong>Core Strengthening</strong></td>
               <td className="text-center"><strong>Mobility & Flexibility</strong></td>
               <td className="text-center"><strong>Rest</strong></td>
            </tr>
            <tr>
               <td className="text-center">Workout</td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">LIM20</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">SS1</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">LIM20</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">SS1</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">VM Abs1.1 X 2 Reps</a></td>
               <td className="text-center">Yoga - Link in Telegram</td>
               <td className="text-center">Rest</td>
            </tr>
            
            <tr bgcolor="#FAF8F1">
               <td className="text-center"><strong>Week 2</strong></td>
               <td className="text-center"><strong>Cardio</strong></td>
               <td className="text-center"><strong>Body Weight Strength Training</strong></td>
               <td className="text-center"><strong>Cardio</strong></td>
               <td className="text-center"><strong>Body Weight Strength Training</strong></td>
               <td className="text-center"><strong>Core Strengthening</strong></td>
               <td className="text-center"><strong>Mobility & Flexibility</strong></td>
               <td className="text-center"><strong>Rest</strong></td>
            </tr>
            <tr>
               <td className="text-center">Workout</td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">LIM20</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">SS1</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">LIM20</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">SS1</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">VM Abs1.1 X 2 Reps</a></td>
               <td className="text-center">Yoga - Link in Telegram</td>
               <td className="text-center">Rest</td>
            </tr>
            <tr bgcolor="#FAF8F1">
               <td className="text-center"><strong>Week 3</strong></td>
               <td className="text-center"><strong>Cardio</strong></td>
               <td className="text-center"><strong>Body Weight Strength Training</strong></td>
               <td className="text-center"><strong>Cardio</strong></td>
               <td className="text-center"><strong>Body Weight Strength Training</strong></td>
               <td className="text-center"><strong>Core Strengthening</strong></td>
               <td className="text-center"><strong>Mobility & Flexibility</strong></td>
               <td className="text-center"><strong>Rest</strong></td>
            </tr>
            <tr bgcolor>
               <td className="text-center">Workout</td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">LIM20</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">SS1</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">LIM20</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">SS1</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">VM Abs1.1 X 2 Reps</a></td>
               <td className="text-center">Yoga - Link i Telegram</td>
               <td className="text-center">Rest</td>
            </tr>
            <tr bgcolor="#FAF8F1">
               <td className="text-center"><strong>Week 4</strong></td>
               <td className="text-center"><strong>Cardio</strong></td>
               <td className="text-center"><strong>Body Weight Strength Training</strong></td>
               <td className="text-center"><strong>Cardio</strong></td>
               <td className="text-center"><strong>Body Weight Strength Training</strong></td>
               <td className="text-center"><strong>Core Strengthening</strong></td>
               <td className="text-center"><strong>Mobility & Flexibility</strong></td>
               <td className="text-center"><strong>Rest</strong></td>
            </tr>
            <tr>
               <td className="text-center">Workout</td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">LIM20</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">SS1</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">LIM20</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">SS1</a></td>
               <td className="text-center"><a href="https://youtu.be/t6rTY8hS" target="_blank">VM Abs1.1 X 2 Reps</a></td>
               <td className="text-center">Yoga - Link in Telegram</td>
               <td className="text-center">Rest</td>
            </tr> */}
                    </tbody>
                </table>
            </div>
        </>
    )

}

export default ClientWorkoutPlanView