import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import * as $ from 'jquery'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import { ADMIN, ATLAS_URI } from '../../Constants'
import { returnTime, getTodayTime, getNormalDate, returnDate } from '../../Helpers/DateHelpers'
import stateContext from '../../context/StateContext'

function AssignedLeadsVisits() {

    const contextState = useContext(stateContext)
    const { updateEditDetails, updateList } = contextState
    const { configToken, leadsList, usersList, sourcesList, propertyTypeList, statusList, operator } = contextState.state


    const [state, setState] = useState({
        tableBodyList: [],
        tableBodyDisplayList: [],
        dialogInfo: {
            isOpened: false,
            text: "",
            type: ""
        },
        onTrueFn: ""
    })

    const mounted = React.useRef(true);
    useEffect(() => () => { mounted.current = false; }, []);

    useEffect(() => {

        let leadsData = leadsList;
        let userData = usersList;
        let sourcesData = sourcesList;

        if (leadsData.length !== 0
            && sourcesData.length !== 0
            && userData.length !== 0) {

            leadsData = leadsData.map(lead => {

                lead.LastRemarks = lead.Remarks && (lead.Remarks.length !== 0 ? lead.Remarks[lead.Remarks.length - 1].Remarks : "");

                const filteredUser = lead.AssignedTo ? userData.filter(user => user.id.toString() === lead.AssignedTo.toString()) : []
                lead._AssignedTo = filteredUser.length === 0 ? "" : filteredUser[0].Name;

                let tempSources = lead.Source ? sourcesData.filter(source => source.id.toString() === lead.Source.toString()) : []
                lead.Sources = tempSources.length !== 0 && tempSources[0].Source;


                lead.FollowUpList = lead.FollowUps
                return lead;

            })

            const userID = operator.id;
            leadsData = operator.Role === ADMIN ? leadsData : leadsData.filter(lead => lead.AssignedTo === userID);

            setTimeout(() => {
                mounted.current && setState(prevState => ({
                    ...prevState,
                    tableBodyList: leadsData,
                    tableBodyDisplayList: leadsData
                }))
            }, 200)
        }
    }, []);

    function editRecord(e) {
        const temp = e.target.parentElement.parentElement.id;
        updateEditDetails({ id: temp, editingActivated: true, redirectFrom: "/assignedLeadsVisits", redirectTo: "/Leads/leadDetails" });
    }

    function openDeleteDialog(e) {
        const newDialogInfo = {
            isOpened: true,
            delID: e.target.parentElement.parentElement.id,
            text: "Are you sure you want to remove this Record?",
            type: "Confirm"
        }
        setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo, onTrueFn: deleteFromTable }))
    }

    function openStatusDialog(e) {
        const editID = e.target.parentElement.parentElement.id
        const newDialogInfo = {
            isOpened: true,
            editedID: editID,
            text: "Change Visit Status to 'Visited'?",
            type: "Confirm"
        }
        setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo, onTrueFn: (e) => statusVisited(e, editID) }))
    }

    function statusVisited(e, editedID) {
        let editedLead = state.tableBodyList.filter(lead => lead.id.toString() === editedID)[0];
        editedLead.VisitInfos[editedLead.VisitInfos.length - 1].VisitStatus = "Visited";
        editedLead.VisitStatus = "Visited";

        axios.post(`${ATLAS_URI}/updateVisitInfo/${editedLead.VisitInfos[editedLead.VisitInfos.length - 1].id}`, editedLead, configToken)
            .then(() => {
                setState({
                    tableBodyList: state.tableBodyList.map(lead => lead.id === editedID ? editedLead : lead),
                    tableBodyDisplayList: state.tableBodyDisplayList.map(lead => lead.id === editedID ? editedLead : lead),
                    dialogInfo: { isOpened: false, text: "", delID: "" }
                })

            })
            .catch(err => alert(err))

    }

    function deleteFromTable(e) {
        const delID = state.dialogInfo.delID;
        let editedLead = state.tableBodyList.filter(lead => lead.id === delID)[0];

        editedLead.AssignedTo = "Removed By: " + operator.Name;
        editedLead._AssignedTo = "Removed By: " + operator.Name;
        axios.post(`${ATLAS_URI}/updateLead/${delID}`, editedLead, configToken)
            .then(() => {
                if (operator.Role === ADMIN) {
                    const newTableBodyList = state.tableBodyList.map(lead => lead.id !== delID ? lead : editedLead);
                    updateList('leadsList', newTableBodyList)
                    setState(prevState => ({
                        ...prevState,
                        tableBodyList: newTableBodyList,
                        tableBodyDisplayList: state.tableBodyDisplayList.map(lead => lead.id !== delID ? lead : editedLead),
                        dialogInfo: { isOpened: false, text: "", delID: "" }
                    }))
                } else {
                    const newTableBodyList = state.tableBodyList.map(lead => lead.id !== delID);
                    updateList('leadsList', newTableBodyList)
                    setState(prevState => ({
                        ...prevState,
                        tableBodyList: newTableBodyList,
                        tableBodyDisplayList: state.tableBodyDisplayList.filter(lead => lead.id !== delID),
                        dialogInfo: { isOpened: false, text: "", delID: "" }
                    }))
                }

            })
            .catch(err => alert(err))
    }

    function searchCriteria(e) {

        e.preventDefault();

        const visits = $("#searchVisits").val();
        const leadStatus = $("#searchLeadStatus").val();
        const visitStatus = $("#searchVisitStatus").val();
        const newTableBodyList = state.tableBodyList.filter(data =>
            (leadStatus !== "" ? data.LeadStatus === leadStatus : true) &&
            (visitStatus !== "" ? data.VisitStatus === visitStatus : true) &&
            (visits === "" ? true :
                (
                    (visits === "Today Visits" && returnTime(data.VisitDate) === getTodayTime()) ||
                    (visits === "Upcoming Visits" && returnTime(data.VisitDate) > getTodayTime()) ||
                    (visits === "Old Pending Visits" && returnTime(data.VisitDate) < getTodayTime())
                )
            )
        )

        setState(prevState => ({
            ...prevState,
            tableBodyDisplayList: newTableBodyList
        }))


    }

    function resetSearchCriteria() {
        $("#searchVisits").val("")
        $("#searchLeadStatus").val("")
        $("#searchVisitStatus").val("")
    }

    function downloadReport(e) {
        e.preventDefault();
        var temp = $("#searchEnquiryFromDate").val();
        const enquiryFromDate = returnDate(getNormalDate(temp))

        var temp1 = $("#searchEnquiryToDate").val();
        const enquiryToDate = returnDate(getNormalDate(temp1))

        let newList = [];
        newList.push({ Activity: "StaffName", Lead: operator.Name })
        newList.push({ Activity: "Activity List ", Lead: "From", ClientPhone: getNormalDate(temp), Project: "To", ProjectName: getNormalDate(temp1) })
        newList.push({})
        newList.push({})
        newList.push({
            VisitDate: "VisitDate:",
            Activity: "Activity",
            Lead: "Lead",
            ClientPhone: "ClientPhone",
            Project: "",
            ProjectName: "",
            Property: "",
            PropertyName: "",
            VisitStatus: "VisitStatus",
            Description: "Description"
        })
        state.tableBodyList.forEach(element => {

            element.VisitInfos.forEach(visits => {
                if ((!isNaN(enquiryFromDate) ? returnTime(visits.VisitDate) >= enquiryFromDate.getTime() : true) &&
                    (!isNaN(enquiryToDate) ? returnTime(visits.VisitDate) <= enquiryToDate.getTime() : true)) {

                    let x = {
                        VisitDate: visits.VisitDate,
                        Activity: "Visit",
                        Lead: element.ClientName,
                        ClientPhone: element.ClientPhone,
                        Project: "Project",
                        ProjectName: visits.Project,
                        Property: "Property",
                        PropertyName: visits.Property,
                        VisitStatus: visits.VisitStatus,
                        Description: visits.Description
                    }

                    newList.push(x)
                }
            })

            element.FollowUpList.forEach(follow => {
                if ((!isNaN(enquiryFromDate) ? returnDate(follow.FollowUpDate) >= enquiryFromDate.getTime() : true) &&
                    (!isNaN(enquiryToDate) ? returnDate(follow.FollowUpDate) <= enquiryToDate.getTime() : true)) {

                    let x = {
                        VisitDate: follow.FollowUpDate,
                        Activity: "Follow Up",
                        Lead: element.ClientName,
                        ClientPhone: element.ClientPhone,
                        Project: "Response",
                        ProjectName: follow.Response,
                        Property: "Note",
                        PropertyName: follow.Note,
                        VisitStatus: "",
                        Description: ""
                    }
                    newList.push(x)
                }
            })
        });

        newList.sort(function compare(a, b) {
            var dateA
            var dateB
            try {
                dateA = returnDate(a.VisitDate);
                dateB = returnDate(b.VisitDate);
            } catch (error) { }
            return dateA - dateB;
        });

        axios.post(`${ATLAS_URI}/downloadLeads/`, newList)
            .then(res3 => {
                if (res3.status === 200) {

                    if (res3.data !== null) {
                        const downloadWin = window.open(`${ATLAS_URI}/excel/${res3.data}`);
                        if (downloadWin) downloadWin.opener = null;

                    }
                }

            })
            .catch(err => alert(err))
    }


    return (
        <section className="content">
            <div className="row">


                <Dialog
                    onFalse={(e) => setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "" } }))}
                    onTrue={state.onTrueFn}
                    dialogInfo={state.dialogInfo}
                />
                <div className="col-md-12">

                    <div className="box box-primary">

                        <BoxHeader title="Select Criteria" />

                        <form onSubmit={searchCriteria}>

                            <div className="box-body row">

                                <input type="hidden" name="ci_csrf_token" value="" />

                                <div className="col-sm-4 col-md-4">
                                    <div className="form-group">
                                        <label>Visits</label>
                                        <select id="searchVisits" className="form-control" >
                                            <option value="" >Select All</option>
                                            <option value="Today Visits">Today Visits</option>
                                            <option value="Upcoming Visits">Upcoming Visits</option>
                                            <option value="Old Pending Visits">Old Pending Visits</option>
                                        </select>
                                        <span className="text-danger"></span>
                                    </div>
                                </div>

                                <div className="col-sm-4 col-md-4">
                                    <div className="form-group">
                                        <label>Lead Status</label>
                                        <select id="searchLeadStatus" className="form-control" >
                                            <option value="" >Select All</option>
                                            {
                                                statusList.map((status) =>
                                                    <option key={status.id} value={status.Status}>{status.Status}</option>
                                                )
                                            }
                                        </select>
                                        <span className="text-danger"></span>
                                    </div>
                                </div>

                                <div className="col-sm-4 col-md-4">
                                    <div className="form-group">
                                        <label>Visit Status</label>
                                        <select id="searchVisitStatus" className="form-control" >
                                            <option value="" >Select All</option>
                                            <option value="Not Visited">Not Visited</option>
                                            <option value="Visited">Visited</option>
                                            <option value="Not Planned">Not Planned</option>
                                        </select>
                                        <span className="text-danger"></span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary btn-sm pull-right"><i className="fa fa-search"></i> Search</button>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <form onSubmit={downloadReport}>

                            <div className="box-body row">

                                <input type="hidden" name="ci_csrf_token" value="" />

                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label>Enquiry From Date</label>
                                        <input type="date" id="searchEnquiryFromDate" className="form-control" />
                                    </div>
                                    <span className="text-danger"></span>

                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label>Enquiry To Date</label>
                                        <input type="date" id="searchEnquiryToDate" className="form-control" />
                                    </div>
                                    <span className="text-danger"></span>

                                </div>

                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary btn-sm pull-right"><i className="fa fa-search"></i> Download</button>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <BoxHeader title="Assigned Leads Visits" />

                        <div className="box-body">

                            <DataTable
                                tableHeader={operator.Role === ADMIN ?
                                    ["id", "Client Name", "Client Phone", "Interested In", "Last Remarks", "Sources", "Visit Property", "Visit Date", "Visit Status", "_Assigned To"] :
                                    ["id", "Client Name", "Client Phone", "Interested In", "Last Remarks", "Sources", "Visit Property", "Visit Date", "Visit Status"]
                                }
                                tableBody={state.tableBodyDisplayList}
                                searchField={operator.Role === ADMIN ? "_AssignedTo" : "ClientName"}
                                customAction={[
                                    { title: "Set to Visited", icon: "fas fa-comment-alt-check text-green", fieldCondition: { "VisitStatus": "Not Visited" }, onClickEvent: openStatusDialog },
                                    { title: "Update", icon: "fas fa-edit", redirectTo: "/Leads/leadDetails", onClickEvent: editRecord },
                                    // { title: "Remove", icon: "fas fa-times text-red", onClickEvent: openDeleteDialog }
                                ]}
                            />

                        </div>
                    </div>

                </div>
            </div>

        </section>
    )


}

export default AssignedLeadsVisits