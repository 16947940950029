import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";

import { ATLAS_URI, CALL_METHOD } from "../Constants";
import { PlivoContext } from "../context/PlivoContext";
import stateContext from "../context/StateContext";
import AggridFollowUpModal from "./AggridFollowUpModal";
import { axiosFetch } from "../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { dateConverter } from "../Utlis/dateConversion";

function FollowupModal(props) {
 

  const contextState = useContext(stateContext);
  const history = useHistory();
  const { plivo, duration, currentCallStatus, microphoneDevices } =
    useContext(PlivoContext);

  const { configToken, operator } = contextState.state;

  const data = props.state.newTableRow || props.newTableRow;

  const [callHistory, setCallHistory] = useState([]);

  const [openNotePopUp, setOpenNotePopup] = useState(false);
  const [noteUUID, setNoteUUID] = useState("");
  const [editNote, setEditNote] = useState("");

  const [currentNote, setCurrentNote] = useState("");

  const [nextFollowupDateTime, setNextFollowupDateTime] = useState("");

  const [nextFollowupInfo, setNextFollowupInfo] = useState("");
  const [lastFollowupInfo, setLastFollowupInfo] = useState("");

  const [isCallHistory, setIsCallHistory] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const [leadInfo, setLeadInfo] = useState({});
  const [productDtls, setProductDtls] = useState([]);
  const [dealSize, setDealSize] = useState([]);

  const [latestFollowUpdate, setLatestFollowUpdate] = useState("");

  const [dispositionList, setDispositionList] = useState([]);
  const [subDispoistionList, setSubDispositionList] = useState([]);

  const [leadStatusList, setLeadStatusList] = useState([]);
  const [leadStatus, setLeadStatus] = useState("");

  const [selectedDisposition, setSelectedDisposition] = useState("");
  const [selectedSubDisposition, setSelectedSubDisposition] = useState("");

  const [dispositionErr, setDispositionErr] = useState(false);
  const [subDispositionErr, setSubDispositionErr] = useState(false);

  const [clientDispositionDtls, setClientDispositionDtls] = useState(null);
  const [updatedleadStatus, setUpdatedLeadStatus] = useState("");

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const handleClose = () => {
    props.closeFollowUpModal();
  };

  function getCallHistory() {
    axios
      .get(`${ATLAS_URI}/getCallsByLeadId/${data.id}`, configToken)
      .then((res) => {
        if (res.status == 200) {
          let selectedClientDtls = props.state.tableBodyList.filter(
            (item) => item.id == props.newTableRow.clientId
          )[0];

          const userid = JSON.parse(
            window.localStorage.getItem("operator")
          ).Role;
          let filteredData;
          // Trainer
          if (userid == 31) {
            filteredData = res.data
              .filter((item) => item.User !== null)
              .filter((item) => item.User.Role == 31);
          }
          // TrainerManager
          else if (userid == 30) {
            filteredData = res.data
              .filter((item) => item.User !== null)
              .filter((item) => item.User.Role == 31 || item.User.Role == 30);
          }
          // Dietician Manager
          else if (userid == 28) {
            filteredData = res.data
              .filter((item) => item.User !== null)
              .filter((item) => item.User.Role == 29 || item.User.Role == 28);
          }
          // Dietician
          else if (userid == 29) {
            filteredData = res.data
              .filter((item) => item.User !== null)
              .filter((item) => item.User.Role == 29);
          }
          // sales executive
          else if (userid == 18) {
            filteredData = res.data
              .filter((item) => item.User !== null)
              .filter((item) => item.User.Role == 18);
          }
          // Sales Manager
          else if (userid == 15) {
            filteredData = res.data
              .filter((item) => item.User !== null)
              .filter((item) => item.User.Role == 18 || item.User.Role == 15);
          } else if (userid == 1) {
            filteredData = res.data;
          }
          setCallHistory(filteredData);
          return;
        }
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getNextFollowupInfo() {
    axios
      .get(
        `${ATLAS_URI}/getLatestFollowupByLeadIdAndUserId/${data.id}/${operator.id}`,
        configToken
      )
      .then((res) => {
        if (res.status == 200) {
          setNextFollowupInfo(res.data?.NextFollowUpDate);
          return;
        }
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  const getDispostionList = () => {
    axiosFetch({
      url: "/v3/getClientDisposition",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setDispositionList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getSubDispostionList = (dispositionId) => {
    axiosFetch({
      url: `/v3/getClientSubDisposition?clientStatus=${0}&dispositionId=${dispositionId}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setSubDispositionList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  function getLastFollowupInfo() {
    axios
      .get(
        `${ATLAS_URI}/getLastFollowupDateByLeadIdAndUserId/${data.id}/${operator.id}`,
        configToken
      )
      .then((res) => {
        if (res.status == 200) {
          setLastFollowupInfo(res.data?.createdAt);
          return;
        }
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLeadStatusList() {
    axios
      .get(`${ATLAS_URI}/getStatuses`, configToken)
      .then((res) => {
        setLeadStatusList(res.data);
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  function getLeadInfo(leadId) {

    axios
      .get(`${ATLAS_URI}/getLeadByID/${leadId}`, configToken)
      .then((res) => {
        setLeadInfo(res.data);
        if (res.data.ClientInfo.products !== undefined) {
          setProductDtls(
            res.data.ClientInfo.products.map(
              (data) => data.product_details.name
            )
          );
          setDealSize(res.data.products.map((data) => data.deal_size));
        }
        
        if (
          res.data.followupDetails !== undefined &&
          res.data.followupDetails.length !== 0
        ) {
          
          setLatestFollowUpdate(
            res.data.followupDetails[res.data.followupDetails.length - 1]
              .NextFollowUpDate
            //   res.data.followupDetails.find((data) => data.id == 1591)
            //     .NextFollowUpDate
          );
        }
        setLeadStatus(res.data.lead_status);
        setUpdatedLeadStatus(res.data.lead_status);
      })
      .catch((err) => {
        console.error("err", JSON.stringify(err, null, 2));
      });
  }

  useEffect(() => {
    getCallHistory();
    getNextFollowupInfo();
    getLastFollowupInfo();
    getLeadStatusList();
    getDispostionList();
    getLeadInfo(data.id);
    getClientDispositionDtls();

    // for call history props.currentCallUUID will be undefined
    if (!props.currentCallUUID) {
      setIsCallHistory(true);
    }
  }, []);

  function saveNote(callUUID, notes) {
    const postNote = {
      // callUUID: callUUID,
      id: data.id,
      notes: notes,
    };
    axios
      .post(`${ATLAS_URI}/updateLead/${data.id}`, postNote, configToken)
      .then((noteRes) => {
        if (noteRes.status == 200) {
          getCallHistory();
        }
      })
      .catch((err) => {});
  }

  function saveFollowupInfo() {
    
    if(nextFollowupDateTime !== null){
      const postFollowupData = {
        NextFollowUpDate: nextFollowupDateTime,
        userId: operator.id,
      };
  
      let followupId;
      if (
        leadInfo.followupDetails !== undefined &&
        leadInfo.followupDetails.length !== 0
      ) {
        followupId =
          leadInfo.followupDetails[leadInfo.followupDetails.length - 1].id;
      }
  
      axios
        .post(
          `${ATLAS_URI}/updateFollowUp/${followupId}`,
          postFollowupData,
          configToken
        )
        .then((followupRes) => {
          if (followupRes.status == 200) {
            setNextFollowupDateTime("");
            getNextFollowupInfo();
            getLeadInfo(data.id);
            getClientDispositionDtls();
          }
        })
        .catch((err) => {});
    }
  
  }

  function updateLeadInfo() {
    const leadData = {
      id: data.id,
      lead_status: leadStatus,
    };
    axios
      .post(`${ATLAS_URI}/updateLead/${data.id}`, leadData, configToken)
      .then((leadInfo) => {
        if (leadInfo.status == 200) {
          setUpdatedLeadStatus(leadInfo.data.lead_status);
        }
      })
      .catch((err) => {});
  }

  const handleChangeDisposition = (e) => {
    if (e.target.value === "") {
      setDispositionErr(true);
      setSubDispositionList([]);
      setSelectedDisposition("");
      setSelectedSubDisposition("");
      return false;
    } else {
      setDispositionErr(false);
      setSelectedDisposition(e.target.value);
      getSubDispostionList(e.target.value);
    }
  };

  const handleChangeSubDisposition = (e) => {
    if (e.target.value === "") {
      setSubDispositionErr(true);
      setSelectedSubDisposition("");
      return false;
    } else {
      setSubDispositionErr(false);
      setSelectedSubDisposition(e.target.value);
    }
  };

  const getClientDispositionDtls = () => {
    axiosFetch({
      url: `/v3/getLatestDispositionByClientId/${props?.selectedLead?.id}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setClientDispositionDtls(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const postClientDispositions = () => {
    if (selectedDisposition === "") {
      setDispositionErr(true);
      return false;
    }
    if (selectedSubDisposition === "") {
      setSubDispositionErr(true);
      return false;
    } else if (selectedDisposition !== "" && selectedSubDisposition !== "") {
      axiosFetch({
        url: "/v3/postClientDispositionRelation",
        method: "post",
        requestConfig: {
          clientId: props?.selectedLead?.id,
          vmax_client_disposition_id:
            selectedDisposition !== "" ? selectedDisposition : null,
          vmax_client_sub_disposition_id:
            selectedSubDisposition !== "" ? selectedSubDisposition : null,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Saved Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 400) {
            toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  return (
    <Modal
      show={props.state.isFollowUpModalOpen}
      onHide={props.closeFollowUpModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Call history</Modal.Title>
        <GrClose
          style={{
            backgroundColor: isHovering ? "#FF0000" : "",
            color: isHovering ? "#fff" : "",
            border: isHovering ? "1px solid" : "",
            height: isHovering ? "30px" : "",
            width: isHovering ? "30px" : "",
            opacity: isHovering ? "0.7" : "",
          }}
          onMouseOver={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="taskFieldArea">
              {!isCallHistory && (
                <form onSubmit={props.updateFollowUp}>
                  <div>
                    <div className="container">
                      <div className="row">
                        <div className="col-md">
                          <label>
                            <b>Call Status </b>
                          </label>
                        </div>
                        <div className="col-md">{currentCallStatus}</div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md">
                          {CALL_METHOD == "WebRTC" && (
                            <label>
                              <b>Duration </b>
                            </label>
                          )}
                        </div>
                        <div className="col-md">
                          {duration !== 0 ? duration : "-"}
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-md">
                          <label>
                            <b>Next Followup Date</b>
                          </label>
                        </div>
                        <div className="col-md">
                          <input
                            name="call_next_followup_date"
                            type="datetime-local"
                            min={new Date().toISOString().slice(0, 16)}
                            onChange={(e) =>
                              setNextFollowupDateTime(e.target.value)
                            }
                            value={nextFollowupDateTime}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md">
                          <label>
                            <b>Disposition</b>
                          </label>
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="col-md">
                          <select
                            name="call_lead_status"
                            value={selectedDisposition}
                            onChange={(e) => {
                              handleChangeDisposition(e);
                            }}
                            style={{ width: "48% !important" }}
                          >
                            <option value="">{"Select"}</option>
                            {dispositionList.map((disposition) => (
                              <option
                                key={disposition.id}
                                value={disposition.id}
                              >
                                {disposition.leadDisposition}
                              </option>
                            ))}
                          </select>

                          <p style={{ color: "red" }}>
                            {dispositionErr ? "Please select something" : ""}
                          </p>
                        </div>
                      </div>
                      <br />

                     {selectedDisposition !== "" ?<><div className="row">
                        <div className="col-md">
                          <label>
                            <b>Sub Disposition</b>
                          </label>
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="col-md">
                          <select
                            name="call_lead_status"
                            value={selectedSubDisposition}
                            onChange={(e) => {
                              handleChangeSubDisposition(e);
                            }}
                            style={{ width: "48% !important" }}
                          >
                            <option value="">{"Select"}</option>
                            {subDispoistionList.map((disposition) => (
                              <option
                                key={disposition.id}
                                value={disposition.id}
                              >
                                {disposition.display_value}
                              </option>
                            ))}
                          </select>
                          <p style={{ color: "red" }}>
                            {subDispositionErr ? "Please select something" : ""}
                          </p>
                        </div>
                      </div>
                      <br /> </>: ""}
                      <div className="row">
                        <div className="col-md">
                          <label>
                            <b>Call Note</b>
                          </label>
                        </div>

                        <div className="col-md">
                          <textarea
                            id="callNote"
                            onChange={(e) => setCurrentNote(e.target.value)}
                            value={currentNote}
                          ></textarea>
                        </div>
                      </div>

                      <br />
                    </div>
                  </div>

                  <div className="box-footer pr0">
                    <Button onClick={() => plivo.client.hangup()}>
                      End Call
                    </Button>

                    <button
                      className="btn btn-info pull-right"
                      onClick={() => {

                        if (selectedDisposition === "") {
                          setDispositionErr(true);
                          return false;
                        }
                        if (selectedSubDisposition === "") {
                          setSubDispositionErr(true);
                          return false;
                        }

                        if (currentNote != "") {
                          saveNote(props.currentCallUUID, currentNote);
                        }

                        if (nextFollowupDateTime != "") {
                          saveFollowupInfo();
                        }
                        if (leadStatus != "") {
                          updateLeadInfo();
                        }
                       
                        if (
                          selectedDisposition !== "" &&
                          selectedSubDisposition !== ""
                        ) {
                          postClientDispositions();
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
              <div className="ptbnull">
                <h4 className="box-title titlefix pb5">Call History</h4>
                <div className="box-tools pull-right"></div>
              </div>

              <div className="pt20">
                <div className="tab-pane active" id="timeline">
                  <AggridFollowUpModal callHistory={callHistory} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 col-eq">
            <div className="taskside">
              <hr className="taskseparator" />

              <div className="taskInfo">
                <div>
                  <span>Client Name : </span>
                  {leadInfo?.name ? leadInfo?.name : "-"}
                </div>
                <div>
                  <span>Phone No : </span>
                  {leadInfo?.contact_number  ? leadInfo?.contact_number : "-"}
                </div>
                <div>
                  <span>Email ID : </span>
                  {leadInfo?.email_id  ? leadInfo?.email_id : "-"}
                </div>
                <div>
                  <span>Source : </span>
                  {data?.SourceID?.Source ? data?.SourceID?.Source : "-"}
                </div>
                <div>
                  <span> Created Date : </span>
                  {leadInfo?.createdAt ?
                     leadInfo?.createdAt
                    : "-"}
                </div>
              </div>
            </div>

            <br />
            <div className="taskside">
              <hr className="taskseparator" />

              <div className="taskInfo">
                {clientDispositionDtls !== null ? (
                  <>
                    <div>
                      <span>Call Disposition : </span>
                      {clientDispositionDtls.clientDispositionInfo !== null
                        ? clientDispositionDtls.clientDispositionInfo
                            .display_value
                        : "-"}
                    </div>
                    <div>
                      <span>Call Sub Disposition : </span>{" "}
                      {clientDispositionDtls.clientSubDispositionInfo !== null
                        ? clientDispositionDtls.clientSubDispositionInfo
                            .display_value
                        : "-"}
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}
                <div>
                  <span> Next Follow Up Date : </span>{" "}
                  {leadInfo &&  leadInfo?.followupDetails?.length > 0 ? leadInfo?.followupDetails[leadInfo?.followupDetails?.length - 2]?.NextFollowUpDate ?  leadInfo?.followupDetails[leadInfo?.followupDetails.length - 2]?.NextFollowUpDate : "-":  "-"}
                </div>
                <div>
                  <span>Program Name : </span>{" "}
                  {productDtls.length !== 0 ? productDtls.toString() : "-"}
                </div>
                <div>
                  <span>Deal Size : </span>{" "}
                  {dealSize.length !== 0 ? dealSize.toString() : "-"}
                </div>
              </div>
            </div>
          </div>

          {openNotePopUp && (
            <Modal
              show={openNotePopUp}
              onHide={() => setOpenNotePopup(false)}
              style={{
                width: "50%",
                justifyContent: "center",
                position: "relative",
                left: "50%",
                transform: "translateY(-1000px)",
              }}
            >
              {" "}
              Note
              <Modal.Body>
                <textarea
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setEditNote(e.target.value);
                  }}
                  defaultValue={editNote}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    saveNote(noteUUID, editNote);
                    setOpenNotePopup(false);
                  }}
                >
                  save
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setOpenNotePopup(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FollowupModal;
