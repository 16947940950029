import "./MyTask.css";

const Tab = ({ num, name, onClick, activeTab }) => {
  return (
    <div className="tab">
      <button
        type="button"
        onClick={() => onClick(num)}
        className={num === activeTab ? "active" : ""}
      >
        {name}
      </button>
    </div>
  );
};

export default Tab;
