import { Box, Typography } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { utcFormater } from "../../../hooks/helperFunction/dateConverter";
import ActionMenu from "./Modals/ActionMenu";
import { useReducer } from "react";

const typographyStyles = {
  title: {
    fontSize: "18px",
    fontWeight: 600,
    textTransform: "capitalize",
    padding: "10px",
    paddingBottom: "0px",
    display: "flex",
    justifyContent: "space-between",
  },
  description: {
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "10px",
  },
  program: {
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "10px",
    display: "flex",
  },

  footer: {
    fontSize: "18px",
    fontWeight: 500,
    textTransform: "capitalize",
    marginBottom: "10px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },

  footer_item: {
    fontSize: "18px",
    color: "#6B6B6B",
  },
  icon: {
    cursor: "pointer",
  },
};

const initialObject = {
  anchorEl: false,
  salesList: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "action":
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
      };
    case "salesList/get":
      return {
        ...state,
        salesList: action.payload,
      };
    default: {
      throw new Error("Unkown Action");
    }
  }
};

const AutomationTable = ({ getClientList, clientlist }) => {
  const [{ anchorEl }, dispatch] = useReducer(reducer, initialObject);

  const handleClick = (e) => {
    dispatch({ type: "action", payload: { anchorEl: e.target } });
  };

  const handleClose = (value, clientType) => {
    if (value === "delete") {
      getClientList(clientType);
    } else {
      dispatch({ type: "action", payload: { anchorEl: false } });
    }
  };

  return (
    <Box
      sx={{
        marginBottom: "50px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: "6px",
      }}
    >
      <Box sx={{ p: "10px" }}>
        <Typography component="span" sx={typographyStyles.title}>
          {clientlist.sequenceNo} {clientlist.ruleName}
          <MoreVertIcon sx={typographyStyles.icon} onClick={handleClick} />
        </Typography>
        <Typography sx={typographyStyles.description}>
          {clientlist.description}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "2px solid #ddd",
        }}
      >
        <Box
          sx={{
            width: "100%",
            // borderRight: "2px solid #ddd"
            p: "10px",
          }}
        >
          <Typography component="span" sx={typographyStyles.footer}>
            <Typography component="span" sx={typographyStyles.footer_item}>
              Created:
            </Typography>
            {clientlist?.created_by} &nbsp;
            {utcFormater(clientlist?.createdAt)[0]}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", p: "10px" }}>
          <Typography component="span" sx={typographyStyles.footer}>
            <Typography component="span" sx={typographyStyles.footer_item}>
              Updated:
            </Typography>
            {clientlist?.updated_by} &nbsp;
            {utcFormater(clientlist?.updatedAt)[0]}
          </Typography>
        </Box>
        <Box>
          <Typography component="span" sx={typographyStyles.footer}>
            <Typography component="span" sx={typographyStyles.footer_item}>
              Status:{clientlist.status ? "Active" : "Inactive"}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <ActionMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        currentRow={clientlist}
      />
    </Box>
  );
};

export default AutomationTable;
