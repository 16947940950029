import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ClientTestimonials = ()=>{
    return(
        <div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><strong>Date</strong></TableCell>
            <TableCell><strong>Coach Name</strong></TableCell>
            <TableCell><strong>Previous Weight</strong></TableCell>
            <TableCell><strong>Previous BMI</strong></TableCell>
            <TableCell><strong>Current Weight</strong></TableCell>
            <TableCell><strong>Current BMI</strong></TableCell>
            <TableCell><strong>Mode</strong></TableCell>
            <TableCell><strong>Content</strong></TableCell>
            <TableCell><strong>Photo</strong></TableCell>
            <TableCell><strong>Video</strong></TableCell>
            <TableCell><strong>Status</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
        </TableBody>
      </Table>
    </TableContainer>
        </div>
    )
}
export default ClientTestimonials;