import React, { useState, useEffect, useContext, useMemo } from 'react'
import * as $ from 'jquery'
import BoxHeader from '../../components/BoxHeader'
import axios from 'axios'
import { ATLAS_URI } from '../../Constants'
import stateContext from '../../context/StateContext'
import { Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PopupDietPlanView from '../Clients/PopupDietPlanView'
import { toast } from 'react-toastify'
import DietTemplateView from './DietTemplateView'
import ClientDietPlans from '../Clients/ClientDietPlans';
import {BiRefresh} from 'react-icons/bi'
import { dateConverter } from '../../Utlis/dateConversion'

function DietTemplate() {






  const contextState = useContext(stateContext)
  const [showNewTemp, setShowNewTemp] = useState(false);
  const { configToken } = contextState.state
  const [dietPlanView, setDietPlanView] = useState(false)
  const containerStyle = useMemo(() => ({ width: '100%', 'height': '1000px' }), []);
  
  const gridStyle = useMemo(() => ({ 'height': '100%', width: '100%' }), []);


 
  /* route To DietPlan */

  // const routeToDietPlan = (params) => {
  //   history.push({
  //     pathname: '/Clients/templateDetail/' + params.data.id,
  // const containerStyle = useMemo(() => ({ width: '100%', 'height': '1000px' }), [])
  // const gridStyle = useMemo(() => ({ 'height': '100%', width: '100%' }), [])
  // })


  /* route To DietPlan */

  const routeToDietPlan = (params) => {
    
    history.push({
      pathname: '/Clients/templateDetail/' + params.data.id,
      state: {
        isDietTemplateView: true,
        id: params.data.id,
        from : 1
      }
    })
  }

  /*  View Modal */


  const [dietPlanViewPlanId, setDietPlanViewPlanId] = useState('')

  const showDietPlanViewModal = (dietPlanId) => {
    setDietPlanView(true)
    setDietPlanViewPlanId(dietPlanId)

  }




  /* delete Template */


  const deleteTemplate = async (id) => {
    const deleteMessage = await axios.delete(`${ATLAS_URI}/deleteTemplate/${id}`, configToken).then(res => toast.success('Template Deleted Successfully', { position: toast.POSITION.TOP_CENTER }),).catch(err => console.error(err))

    const dieplanTemplate = await axios.get(`${ATLAS_URI}/getDietPlanTemplate`, configToken)
    //.then(res=>(console.log('res in get template inise delete', res.data),setGetTemplateDetails(res.data))).catch(err=>alert(err))

    setGetTemplateDetails(dieplanTemplate.data)
  }










  const [columnDefs, setColumnDefs] = useState([
    { field: 'sNo' },
    { field: 'templateName' },
    { field: 'createdOn' },
    { field: 'createdBy' },
    {
      field: 'Action', cellRenderer: params => {
        return (
          <>
            <button className="btn btn-default btn-xs no-focus" title="Edit" onClick={() => routeToDietPlan(params)} >
              <i className="fas fa-pencil" aria-hidden="true"></i>
            </button>
            <button className="btn btn-default btn-xs no-focus" title="View" onClick={() => showDietPlanViewModal(params.data.id)}  >
              <i className="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button className="btn btn-default btn-xs no-focus" title="Delete Template" onClick={() => deleteTemplate(params.data.id)}>
              <i className="fas fa-trash" aria-hidden="true"></i>
            </button>

          </>
        )
      }
    }

  ])
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    };
  }, []);

  /*  Tab View */






  /* Back To LeadPage */


  const history = useHistory()


  /* Get Template Details */

  const [showTemplate, setShowTemplate] = useState(false);
  const [getTemplateDetails, setGetTemplateDetails] = useState([]);
  const getDietPlanTemplate = () => {
    axios.get(`${ATLAS_URI}/getDietPlanTemplate`, configToken)
      .then(res => {
        if (res.status === 200) {
          setGetTemplateDetails(res.data);
        }
        else if (res.status === 401) {
          history.push('/');
        }
      })
      .catch(err => console.error(err))
  }
  useEffect(() => {
    getDietPlanTemplate()
  }, [contextState, !showTemplate])


  getTemplateDetails.map((data, index) => {
    data.sNo = index + 1
    data.templateName = data.template_name
    data.createdOn =    dateConverter(data.createdAt) //data.createdAt.slice(0, 10)
    data.createdBy = data?.createdByInfo?.firstName + "" + (data?.createdByInfo?.lastName ? data?.createdByInfo?.lastName : '')
  })

  const createNewTemplate = () =>{
    setShowTemplate(true);
  }

  return (
    <section className=" profileInfo">

      <div className="row">



        <div className="profileTab">
          {
            <div>
              <div className="col-md-12 ">
                <div className="box-body bozero">
                  <BoxHeader title='Diet Template' />
                  <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                    
                    {!showTemplate  ? (<><Button onClick={createNewTemplate} style={{float : "right"}}>Create New Temaplate</Button>
            {/* <Button style={{ margin: "0 0 0 75%", 'backgroundColor': 'transparent', 'borderColor': '#999' }} onClick={getDietPlanTemplate}   ><BiRefresh className='text-dark' fontSize='25px' title='Refresh the table' /></Button>	 */}

                      <br/>
                      <br/>
                      <br/></>):""}
                     {showTemplate ? (<ClientDietPlans from={1}  setShowDetailPage={setShowTemplate}/>) : (<AgGridReact
                        rowData={getTemplateDetails}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                      ></AgGridReact>) }
                      {
                        dietPlanView && <DietTemplateView show1={dietPlanView} setShow1={setDietPlanView} planId={dietPlanViewPlanId} />
                      }
                    </div>
                  </div>



                </div>

              </div>
            </div>
          }
        </div>


      </div>



    </section>
  )


}

export default DietTemplate
