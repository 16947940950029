import { createSlice } from '@reduxjs/toolkit';

const leadsSlice = createSlice({
  name: 'leads',
  initialState: { value: localStorage.getItem('leadId') || '' },
  reducers: {
    addLeadId: (state, action) => {
      const leadId = action.payload;
      state.value = leadId;
      localStorage.setItem('leadId', leadId);
    },
  },
});

export const { addLeadId } = leadsSlice.actions;

export default leadsSlice.reducer;
