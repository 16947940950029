import axios from "axios";
import { ATLAS_URI } from "../Constants";

import { useContext } from "react";
import stateContext from "../context/StateContext";

export {
  deleteFromTable,
  insertIntoTable,
  editTableRow,
  openDialog,
  EditTableRow,
};

function openDialog(e, state, setState) {
  const newDialogInfo = {
    isOpened: true,
    delID: e.target.parentElement.parentElement.id,
    text: "Are you sure you want to delete this Record?",
    type: "Confirm",
  };
  setState({ ...state, dialogInfo: newDialogInfo });
}

function editTableRow(e, state, setState) {
  const editRowID = Number(e.target.parentElement.parentElement.id);
  // alert(editRowID)
  const editData = state.tableBodyList.filter(
    (data) => data.id === editRowID
  )[0];
  const row = { ...state.newTableRow };
  Object.assign(row, editData);

  const editCheckBox = state.tableEditList?.filter(
    (data) => data.id === editRowID
  )[0];
  const editRow = { ...state.newRoleTable };
  Object.assign(editRow, editCheckBox);
  setState({
    ...state,
    editingActivated: true,
    editingID: editRowID,
    newTableRow: row,
    newRoleTable: editRow,
  });

  // axios.get(`${ATLAS_URI}/getUserByID/${editRowID}`,configToken).then(res=>console.log('res for editable row', res)).catch(err=>alert(err))
}

function EditTableRow(e, state, setState) {
  const contextState = useContext(stateContext);
  const { configToken } = contextState.state;
  const editRowID = Number(e.target.parentElement.parentElement.id);
  // alert(editRowID)
  //   const editData = state.tableBodyList.filter(data => data.id === editRowID)[0];
  //   const row = { ...state.newTableRow }
  //   Object.assign(row, editData);
  //   console.log(row,'row')
  //   const editCheckBox=state.tableEditList?.filter(data => data.id === editRowID)[0];
  //   const editRow={ ...state.newRoleTable }
  //   Object.assign(editRow,editCheckBox)
  //  setState({
  //       ...state,
  //       editingActivated: true,
  //       editingID: editRowID,
  //       newTableRow: row,
  //       newRoleTable:editRow
  //   })

  // axios.get(`${ATLAS_URI}/getUserByID/${editRowID}`,configToken).then(res=>console.log('res for editable row', res)).catch(err=>alert(err))
}

function insertIntoTable(e, props, state, setState) {
  e.preventDefault();

  if (!state.editingActivated) {
    //When Adding new Data
    // alert('working')
    // const op=state.newTableRow.roleFeatureData.map(data=>data.is_view)
    // console.log(state.newTableRow.roleFeatureData,'table helpers..........');
    let roleFeatures = [];

    state.checkPages.map((fe) =>
      fe.sub_features.forEach((data) => {
        data.is_view = state.newValue.includes(data.id.toString());
        data.is_edit = state.newValue1.includes(data.id.toString());
        data.sub_feature_id = data.id;

        roleFeatures.push(data);
      })
    );

    state.newTableRow.roleFeatureData = roleFeatures;
    axios
      .post(
        `${ATLAS_URI}/${state.APIs.AddData}/`,
        state.newTableRow,
        props.state.configToken
      )
      .then((response) => {
        if (response.status === 200) {
          let newTableBodyList = [...state.tableBodyList];
          newTableBodyList = [...response.data];
          state.APIs.updateList &&
            props.updateList(state.APIs.updateList, newTableBodyList);
          setState((prevState) => ({
            ...prevState,
            tableBodyList: newTableBodyList,
            newTableRow: state.resetNewRow,
            newRoleTable: state.resetNewRow,
          }));
          alert("Successfully added new role");
          window.location.reload();
        }
      })
      .catch((err) => alert(err));
  } else {
    //When Edit is Activated
    let roleFeatures = [];

    state.checkPages.map((fe) =>
      fe.sub_features.forEach((data) => {
        data.is_view = state.newTableRow.PageID.includes(data.id.toString());
        data.is_edit = state.newRoleTable.PageID.includes(data.id.toString());
        data.sub_feature_id = data.id;

        delete data.id;

        roleFeatures.push(data);
      })
    );

    state.newTableRow.roleFeatureData = roleFeatures;

    axios
      .post(
        `${ATLAS_URI}/${state.APIs.UpdateData}/` + state.editingID,
        state.newTableRow,
        props.state.configToken
      )
      .then((x) => {
        const newTableBodyList = state.tableBodyList.map((data) =>
          data.id === state.editingID ? state.newTableRow : data
        );
        state.APIs.updateList &&
          props.updateList(state.APIs.updateList, newTableBodyList);
        setState({
          ...state,
          editingActivated: false,
          tableBodyList: newTableBodyList,
          newTableRow: state.resetNewRow,
          newRoleTable: state.resetNewRow,
        });
        window.location.reload();
      })
      .catch((err) => alert(err));
  }
}

function deleteFromTable(props, state, setState) {
  const delID = state.dialogInfo.delID;
  axios
    .delete(
      `${ATLAS_URI}/${state.APIs.DeleteData}/` + delID,
      props.state.configToken
    )
    .then(() => {
      const newTableBodyList = state.tableBodyList.filter(
        (data) => data.id.toString() !== delID.toString()
      );
      state.APIs.updateList &&
        props.updateList(state.APIs.updateList, newTableBodyList);
      setState({
        ...state,
        tableBodyList: newTableBodyList,
        tableBodyDisplayList: newTableBodyList,
        newTableRow: state.resetNewRow,
        dialogInfo: { isOpened: false, text: "", delID: "" },
      });
    })
    .catch((err) => {
      setState({
        ...state,
        dialogInfo: { isOpened: false, text: "", delID: "" },
      });
      alert(err, err.response.data && err.response.data.message);
    });
}
