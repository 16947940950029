import {
  DataGridPro,
  // GridToolbarContainer,
  // GridToolbarFilterButton,
  // GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";

const DataTable = ({
  columns = [],
  rows = [],
  count,
  handlePagination,
  apiRef,
  checkbox = false,
  onFilterChange,
  oddColor = "#fff",
  evenColor = "#F4F4F4",
  headerColor = "#F4F4F4",
  slots = {},
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  handleChangeRowSelection,
  visibileColumns,
  currentFilter,
  pinList,
}) => {
  return (
    <>
      <DataGridPro
        key={JSON.stringify(visibileColumns)}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            height: "100%",
            minHeight: "200px",
          },
          ".super-app-theme--header": {
            backgroundColor: headerColor,
          },
          ".MuiDataGrid-columnHeader": {
            backgroundColor: headerColor,
          },
          // help to prevent colors when you display only few columns
          ".MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor": {
            backgroundColor: headerColor,
          },
          ".MuiDataGrid-row:nth-child(odd)": {
            backgroundColor: oddColor,
          },
          ".MuiDataGrid-row:nth-child(even)": {
            backgroundColor: evenColor,
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
          },
        }}
        columns={columns}
        rows={rows}
        rowCount={count}
        paginationMode="server"
        filterMode="server"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          columns: {
            columnVisibilityModel: visibileColumns,
          },
          filter: {
            filterModel: currentFilter,
          },
          // pinnedColumns: { left: ["name"], right: ["action"] },
          pinnedColumns: { left: pinList },
        }}
        pagination
        pageSizeOptions={[25, 50, 100]}
        onPaginationModelChange={handlePagination}
        onFilterModelChange={onFilterChange}
        apiRef={apiRef}
        checkboxSelection={checkbox}
        slots={slots}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onRowSelectionModelChange={handleChangeRowSelection}
      />
    </>
  );
};

export default DataTable;

// const deepEqual = (obj1, obj2) => {
//   const keys1 = Object.keys(obj1);
//   const keys2 = Object.keys(obj2);

//   if (keys1.length !== keys2.length) {
//     return false;
//   }

//   for (const key of keys1) {
//     const val1 = obj1[key];
//     const val2 = obj2[key];

//     if (typeof val1 === "object" && typeof val2 === "object") {
//       if (!deepEqual(val1, val2)) {
//         return false;
//       }
//     } else if (val1 !== val2) {
//       return false;
//     }
//   }

//   return true;
// };
