import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import ProgramList from "./ProgramList";
import { Button } from "@mui/material";
import FeatureList from "./FeatureList";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
export default function Programs() {
  const history = useHistory();
  const [programList, setProgramList] = useState([]);
  const [featureList, setFeatureList] = useState([]);

  const getProgramList = (params) => {
    let url;
    if (params !== undefined) {
      url = `/getProducts/${params}`;
    } else {
      url = `/getProducts/`;
    }
    axiosFetch({
      url: url,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setProgramList(res.data);
          if (res.data.length !== 0) {
            getFeatureList(res.data[0].id);
          }
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getFeatureList = (id) => {
    axiosFetch({
      method: "get",
      url: `/getProductLinesByProdId/${id}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setFeatureList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getProgramList();
    // getFeatureList();
  }, []);

  const addRow = () => {
    history.push("/programs/createNewProgram");
  };

  return (
    <div style={{ height: 300, width: "100%" }}>
      <Button
        variant="contained"
        onClick={() => addRow()}
        sx={{ marginTop: "5px", marginLeft: "88%" }}
      >
        <AddIcon /> Add New Program
      </Button>
      {/* <Button variant="contained" onClick={addFeature}>
        Add Feature
      </Button> */}
      <br /> <br />
      <ProgramList
        programList={programList}
        getProgramList={getProgramList}
        getFeatureList={getFeatureList}
      />
      <br /> <br /> <br /> <br />
      <br /> <br /> <br /> <br />
      <br /> <br /> <br /> <br />
      <br /> <br /> <br /> <br />
      <FeatureList featureList={featureList} getFeatureList={getFeatureList} />
    </div>
  );
}
