import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import * as $ from 'jquery'
import BoxHeader from '../../components/BoxHeader'
import DataTable from '../../components/DataTable'
import { ATLAS_URI } from '../../Constants'
import stateContext from '../../context/StateContext'

function ViewAgreementHistory() {

    const contextState = useContext(stateContext)
    const updateEditDetails = contextState.updateEditDetails
    const { configToken } = contextState.state

    const [state, setState] = useState({
        tableBodyList: [],
    })

    const mounted = React.useRef(true);
    useEffect(() => () => { mounted.current = false; }, []);
    useEffect(() => {
        axios.get(`${ATLAS_URI}/getAgreements/`, configToken)
            .then(response => {
                const agreementData = response.data;
                if (typeof agreementData !== 'undefined' && mounted.current) {
                    //Get All Agreement Types
                    setState(prevState => ({ ...prevState, tableBodyList: agreementData }))
                }

            }).catch(err => console.log(err))
    }, [])


    function onAttemptClick(e) {
        const temp = $(e.target).parents(".dtFieldRows").attr("id");
        updateEditDetails({ id: temp, editingActivated: false, redirectFrom: "/Agreement/viewAgreementHistory", redirectTo: "/Agreement/agreementDetails" });
    }

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">

                    <div className="box box-primary">

                        <BoxHeader title="All Agreements" />

                        <div className="box-body">

                            <DataTable
                                tableHeader={["id", "Name", "Type", "Buyer", "Seller", "Project", "Property", "Total Cost"]}
                                tableBody={state.tableBodyList}
                                searchField="Name"
                                customAction={[
                                    { title: "Attempt", icon: "fas fa-books", redirectTo: "/Agreement/agreementDetails", onClickEvent: onAttemptClick }
                                ]}
                            />

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )


}

export default ViewAgreementHistory