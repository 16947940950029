export const dateConverter = (date) => {
  let nwdate, utcDate;

  if (date?.includes("T")) {
    nwdate = date;
  } else {
    nwdate = date?.split(" ").join("T") + "Z";
  }

  utcDate = new Date(nwdate);

  // var date = new Date(str);
  var mnth = ("0" + (utcDate.getMonth() + 1)).slice(-2);
  var day = ("0" + utcDate.getDate()).slice(-2);
  var hours = ("0" + utcDate.getHours()).slice(-2);
  var minutes = ("0" + utcDate.getMinutes()).slice(-2);
  var seconds = ("0" + utcDate.getSeconds()).slice(-2);
  var year = utcDate.getFullYear();
  return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`;
};
