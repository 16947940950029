import { createSlice } from "@reduxjs/toolkit";

const Call = createSlice({
  name: "call",
  initialState: {
    id: "",
    flag: true,
    callType: "",
    triggerEndCall: false,
    callId: "",
    callPlatForm: "",
  },
  reducers: {
    updateId: (state, action) => {
      state.id = action.payload;
    },
    updateFlag: (state, action) => {
      state.flag = action.payload;
    },
    updateCallType: (state, action) => {
      state.callType = action.payload;
    },
    updateTriggerEndCall: (state, action) => {
      state.triggerEndCall = action.payload;
    },

    updateCallId: (state, action) => {
      state.callId = action.payload;
    },
    updateCallPlatForm: (state, action) => {
      state.callPlatForm = action.payload;
    },

    resetid: (state) => {
      state.id = "";
      state.callId = "";
    },
  },
});

export const {
  updateId,
  resetid,
  updateFlag,
  updateCallType,
  updateCallId,
  updateTriggerEndCall,
  updateCallPlatForm,
} = Call.actions;

export default Call.reducer;
