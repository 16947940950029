import React, { useState } from "react";

import "./Vmax.css";

import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Category from "./Category";
import { v4 } from "uuid";
import RecliningPerson from "../../../../assets/RecliningPerson.svg";

const CustomCategory = ({
  parentIndex,
  schedule,
  exerciseCount,
  updateExerciseCount,
  onCategoryChange = () => {},
  onSchedule = () => {},
  onUpdateSchedule = () => {},
  addLinkSchedule = () => {},
  onDeleteSchedule = () => {},
  onClear = () => {},
  onClose = () => {},
  categoryList,
}) => {
  const [workout, setWorkout] = useState([]);

  const [allCategoryType, setallCategoryType] = useState(schedule.categoryType);
  const [categoryId, setCategoryId] = useState([]);

  const [categoryItem, setCategoryItem] = useState({
    intensity: "",
    level: "",
    subLevel: "",
    textLevel: "",
    exerciseName: "",
    noOfReps: "",
    noOfSets: "",
    duration: "",
    notes:"",
    checkbox: false,
  });

  const handleCategoryType = (categoryType) => {
    onCategoryChange(categoryType, schedule.items, parentIndex);
    setallCategoryType(categoryType);

    const [idList] = categoryList.filter((data) => {
      return categoryType === data.name;
    });

    if (idList) {
      setCategoryId(idList.id);
    }
  };

  const addCategory = (parentIndex) => {
    // restrict exercise per schedule to 5 & overall exercise to 15
    if (schedule.items.length >= 15 || exerciseCount >= 45) {
      return;
    }

    updateExerciseCount((prev) => prev + 1);

    const addedCategory = [
      ...schedule.items,
      {
        id: v4(),
        intensity: "",
        level: "",
        subLevel: "",
        textLevel: "",
        exerciseName: "",
        link: "",
        noOfReps: 0,
        noOfSets: 0,
        duration: "00:00:00",
        checkbox: false,
        notes:""
      },
    ];
    onSchedule(allCategoryType, addedCategory, parentIndex);
  };

  const deleteCategory = (parentIndex) => {
    const deletedCategory = schedule.items.filter((data) => {
      return data.checkbox !== true;
    });

    onDeleteSchedule(parentIndex, deletedCategory);
  };

  const clearCategoryInputs = () => {
    const updateClearItem = schedule.items.map((clearItem) => {
      return {
        id: clearItem.id,
        intensity: "",
        level: "",
        subLevel: "",
        textLevel: "",
        exerciseName: "",
        link: "",
        noOfReps: "",
        noOfSets: "",
        duration: "",
        checkbox: false,
        rest: "",
        showRest: false,
      };
    });

    onClear(updateClearItem, parentIndex);
  };

  const closeCategory = (parentIndex) => {
    onClose(parentIndex);
  };

  const handleRestClick = (parentIndex) => {
    schedule.items.forEach((i) => {
      if (i.checkbox) {
        onUpdateSchedule(parentIndex, i.id, "showRest", true);
      }
    });
  };
  const levelDropdownData = {
    Beginner: {
      level1: "low",
      level2: "high",
      level3: "moderate",
    },
    Intermediate: {
      level1: "moderate",
      level2: "high",
    },
    Advanced: {
      Level1: "High",
      level2: "high",
    },
  };
  const [subOptions, setSubOptions] = useState(
    Object.keys(levelDropdownData["Beginner"])
  );
  const [selectedOption, setSelectedOption] = useState("Beginner");
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setSubOptions(Object.keys(levelDropdownData[selectedValue]));
  };
  return (
    <div className="card-items">
      <div className="custom-close">
        <span
          onClick={() => closeCategory(parentIndex)}
          onKeyDown={() => closeCategory(parentIndex)}
        >
          <ClearIcon />
        </span>
      </div>
      <Row className="mb-5">
        <Col sm={7} className="custom-select-input">
          <label>Category</label>
          <select
            value={schedule.categoryType}
            id="type-of-workout"
            onChange={(e) => {
              handleCategoryType(e.target.value);
            }}
          >
            <option defaultValue="">Select</option>
            {categoryList.length > 0 &&
              categoryList.map((item) => (
                <option value={item.name} key={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </Col>

        <Col sm={1} className="custom-icon">
          <button type="button" onClick={() => addCategory(parentIndex)}>
            <AddIcon />
          </button>
        </Col>
        <Col sm={1} className="custom-icon delete">
          <button type="button" onClick={() => deleteCategory(parentIndex)}>
            <DeleteIcon />
          </button>
        </Col>
        <Col
          sm={1}
          className="custom-icon"
          onClick={() => handleRestClick(parentIndex)}
        >
          <button type="button">
            <img
              src={RecliningPerson}
              alt="recliningPerson"
              weight="20"
              height="20"
            />
          </button>
        </Col>
        <Col sm={1} className="custom-clear" onClick={clearCategoryInputs}>
          <button type="button">Clear</button>
        </Col>
      </Row>
      {schedule.items &&
        schedule.items.length > 0 &&
        schedule.items.map((category, childIndex) => (
          <Category
            key={childIndex}
            categoryInstance={categoryItem}
            categoryId={categoryId}
            onChangeCategory={(id, categoryId, name, value) => {
              onUpdateSchedule(id, categoryId, name, value);
            }}
            addLinkSchedule={addLinkSchedule}
            parentIndex={parentIndex}
            category={category}
            categoryList={categoryList}
            schedule={schedule}
          />
        ))}
    </div>
  );
};

export default CustomCategory;
