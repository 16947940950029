import { createSlice } from "@reduxjs/toolkit";

const WorkoutPlan = createSlice({
  name: "leads",
  initialState: {
    vmax: "",
    custom: "",
    live: "",
    events: [],
    vmaxList: [],
    liveList: [],
    customList: [],
    getEvents: [],
    startDate: "",
    endDate: "",
    editEvent: "",
    editWorkout: "",
    clickEvent: "",
    restList: [],
    rest: "",
    deleteUpdateEvent: false,
    viewTemplate: "",
  },
  reducers: {
    updateVmax: (state, action) => {
      state.vmax = action.payload;
    },
    updateCustom: (state, action) => {
      state.custom = action.payload;
    },
    updateLive: (state, action) => {
      state.live = action.payload;
    },
    updateEvents: (state, action) => {
      state.events = action.payload;
    },
    updateVmaxList: (state, action) => {
      state.vmaxList.push(...action.payload);
    },
    updateLiveList: (state, action) => {
      state.liveList.push(...action.payload);
    },
    updateCustomList: (state, action) => {
      state.customList.push(...action.payload);
    },
    updateCustomArray: (state, action) => {
      state.customList = action.payload;
    },
    updateGetEvents: (state, action) => {
      state.getEvents = action.payload;
    },
    updateStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    updateEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    updateEditEvent: (state, action) => {
      state.editEvent = action.payload;
    },
    updateEditWorkout: (state, action) => {
      state.editWorkout = action.payload;
    },
    updateClickEvent: (state, action) => {
      state.clickEvent = action.payload;
    },
    updateRestList: (state, action) => {
      state.restList.push(...action.payload);
    },
    updateRest: (state, action) => {
      state.rest = action.payload;
    },

    updateView: (state, action) => {
      state.viewTemplate = action.payload;
    },

    updateDeleteUpdateEvent: (state, action) => {
      state.deleteUpdateEvent = action.payload;
    },

    resetVmaxList: (state) => {
      state.vmaxList = [];
      state.liveList = [];
      state.customList = [];
      state.restList = [];
      state.editEvent = "";
      state.getEvents = [];
      state.clickEvent = "";
      state.events = [];
      state.vmax = "";
      state.custom = "";
      state.live = "";
      state.rest = "";
    },
  },
});

export const {
  updateVmax,
  updateCustom,
  updateLive,
  updateEvents,
  updateVmaxList,
  updateLiveList,
  updateCustomList,
  updateCustomArray,
  updateGetEvents,
  updateStartDate,
  updateEndDate,
  updateEditEvent,
  updateEditWorkout,
  updateClickEvent,
  updateRestList,
  updateRest,
  updateDeleteUpdateEvent,
  resetVmaxList,
  updateView,
} = WorkoutPlan.actions;

export default WorkoutPlan.reducer;
