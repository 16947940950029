import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";

const WorkoutPlanView = ({ showPlan, closeWorkoutPlanModal, data,clientsData,selectDate }) => {
  let selectedTemplate = [],
    workoutInfo;
    


  const [isHovering, setIsHovering] = useState(false);
  const contextState = useContext(stateContext)
  const { configToken } = contextState.state
  const [clientInfo, setClientInfo] = useState({})
  let history = useHistory();

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const tableStyle = {
    border: "solid #000",
  };


  useEffect(() => {
    
      axios.get(`${ATLAS_URI}/getClientInfoByLeadId/${clientsData.Lead}`, configToken).then(res=>(  setClientInfo(res.data))).catch(err => {
      console.error('err', err)
    })
}, [])
  


  return (
    <Modal show={showPlan} onHide={() => closeWorkoutPlanModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Workout Plan Details</Modal.Title>
        <GrClose
          style={{
            backgroundColor: isHovering ? "#FF0000" : "",
            color: isHovering ? "#fff" : "",
            border: isHovering ? "1px solid" : "",
            height: isHovering ? "30px" : "",
            width: isHovering ? "30px" : "",
            opacity: isHovering ? "0.7" : "",
          }}
          onMouseOver={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => closeWorkoutPlanModal()}
        />
      </Modal.Header>
      <Modal.Body>
        <div>

        <div>
                    <div style={{
                        color: 'white',
                        backgroundColor: '#4B4B4B',
                        fontWeight: 600,
                        fontSize: '100%',
                        textAlign: 'center',
                        padding: '1% 0'
                    }}>NAME:&nbsp;&nbsp;{clientInfo?.Name}</div>
                    <table style={{ ...tableStyle, width: '100%' }}>
                        <tbody>
                            <tr className="text-center" style={tableStyle}>
                                <td className="bg__grey" colSpan="2" style={tableStyle}><strong>Trainer Name:
                                  {clientInfo?.ClientTrainerInfo?clientInfo?.ClientTrainerInfo?.firstName:'-'}&nbsp;{clientInfo?.ClientTrainerInfo?.lastName}&nbsp;&nbsp;&nbsp;
                                  Trainer Fitness Induction Date:- &nbsp;&nbsp;&nbsp;{selectDate} </strong></td>
                            </tr>
                            <tr style={tableStyle}>
                                <td style={tableStyle}><strong>Pain Points  </strong></td>
                                <td style={tableStyle}><center><b>{!! clientInfo?.leadId?.lead_pain_point ? clientInfo?.leadId?.lead_pain_point : "-"}</b></center> </td>
                            </tr>
                            <tr style={tableStyle}>
                                <td style={tableStyle}><strong>Health Issues</strong></td>
                                <td style={tableStyle}><center><b>{clientInfo?.LeadID?.current_medical_condition_string ? clientInfo?.LeadID?.current_medical_condition_string : (clientInfo?.LeadID?.current_medical_condition.length > 0 ? clientInfo?.LeadID?.current_medical_condition.toString() : '')}</b></center></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>

<br/>


          <table className="table table-bordered" style={tableStyle}>
            <thead>
              <tr bgcolor="#4B4B4B" style={{ ...tableStyle, color: "#fff" }}>
                <td className="text-center">
                  <strong>#NAME</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>L1</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 1</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 2</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 3</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 4</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 5</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 6</strong>
                </td>
                <td style={tableStyle} className="text-center">
                  <strong>Week Day 7</strong>
                </td>
              </tr>
            </thead>
            <tbody className="bg__transparent">
              {
                data?.map((data, index1) => {
                 
                  return (
                    <>
                      <td className="text-center" style={{'transform':'translateY(50%)'}} rowSpan={(data.week.length * 3) +1}><strong>Month {index1 + 1}</strong></td>
                      {
                        data.week.map((weekData, index2) => {                   
                          return (
                            <>
                            <tr>
                              <td style={tableStyle} className="text-center"><strong>Date</strong></td>
                              {weekData.day?.map(dayData=>{
                              return(
                               
                                      <td style={tableStyle} className="text-center" ><strong>{dayData?.details ? dayData.details.map(d => d.date.split('-').reverse().join('-')) : '-'}</strong></td>
                                
                            
                              )
                              })}
                            </tr>
                              <tr bgcolor="#FAF8F1">
                                <td style={tableStyle} className="text-center"><strong>Week {index2 + 1}</strong></td>
                                {
                                  weekData.day.map(dayData => {
                                   
                                    return (
                                       <>                                
                                      <td style={tableStyle} className="text-center"><strong>{dayData?.details ? dayData.details.map(d => d.title).toString() : '-'}</strong></td>

                                      </>
                                    )
                                  })
                                }
                              </tr>

                              <tr bgcolor="#FAF8F1">
                                <td style={tableStyle} className="text-center"><strong>Workout</strong></td>
                                {
                                  weekData.day.map(dayData => {
                                    return (
                                      <>

                                   
                                        <td style={tableStyle} className="text-center">
                                          {
                                            dayData?.details ? dayData?.details.map(d => {
                                              return <a href={d.link} target="_blank">{d.name}</a>
                                            }) : '-'
                                          }
                                        </td>

                                      </>
                                    )
                                  })
                                }
                              </tr>

                            </>
                          )
                        })
                      }


                    </>
                  )
                })

              }
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WorkoutPlanView;
