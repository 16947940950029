import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

export const getLeadList = createAsyncThunk(
  "leads/getLeadList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosFetch({
        method: "post",
        url: "/getLeads",
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
