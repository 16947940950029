import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./AddExercise.css";
import { v4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import DeleteIcon from "@mui/icons-material/Delete";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CloseIcon from "@mui/icons-material/Close";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import AddWorkout from "./AddWorkout";
import RecliningPerson from "../../../../assets/RecliningPerson.svg";
import DragDots from "../../../../assets/DragDots.svg";

const Backdrop = (props) => {
  return (
    <div
      className="backdrop"
      onClick={props.closeModel}
      onKeyDown={props.closeModel}
    ></div>
  );
};

const Exercise = (props) => {
  const [workout, setWorkout] = useState([]);
  // const [category, setCategory] = useState([]);
  const [repeat, setRepeat] = useState(false);
  const [week, setWeek] = useState([1, 2, 3, 4]);
  const typeOfMode = useRef("");

  const handleCheckboxChange = (event) => {
    setRepeat(event.target.checked);
  };
  const everyRepeat = useRef("");

  // const [typeofWorkout, setTypeofWorkout] = useState("");
  const exerciseName = useRef("");
  // const exerciseLink = useRef("");
  const intensity = useRef("");
  // const selectCategory = useRef("");
  // const selectLevel = useRef("");

  // const selectTypeofWorkout = useRef("");

  const id = JSON.parse(window.localStorage.getItem("operator")).id;
  const [activeDay, setActiveDays] = useState(["monday"]);

  const levelDropdownData = {
    Beginner: {
      level1: "low",
      level2: "high",
      level3: "moderate",
    },
    Intermediate: {
      level1: "moderate",
      level2: "high",
    },
    Advanced: {
      Level1: "High",
      level2: "high",
    },
  };
  const [level, setLevel] = useState("low");
  const [selectedOption, setSelectedOption] = useState("Beginner");
  const [subOptions, setSubOptions] = useState(
    Object.keys(levelDropdownData["Beginner"])
  );

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setSubOptions(Object.keys(levelDropdownData[selectedValue]));
  };

  useEffect(() => {
    const fetchData = async () => {
      // const response = await axiosFetch({
      //   url: `/getWorkoutCategory`,
      //   method: "get",
      // });

      // setCategory(response.data);

      const responseWorkout = await axiosFetch({
        url: `/getWorkoutNames`,
        method: "get",
      });

      setWorkout(responseWorkout.data);
    };

    fetchData();
  }, []);
  const [typeOfDay, setTypeOfDay] = useState("weeks");
  const [dayOfType, setDayOfType] = useState("");

  const handleWeek = (e) => {
    const day = e.target.value;
    setTypeOfDay(e.target.value);
    if (day === "month") {
      setWeek([1]);
    }
    if (day === "weeks") {
      setWeek([1, 2, 3, 4]);
    }

    setDayOfType(e.target.value);
  };
  const days = [
    { id: 0, name: "sunday" },
    { id: 1, name: "monday" },
    { id: 2, name: "tuesday" },
    { id: 3, name: "wednesday" },
    { id: 4, name: "thursday" },
    { id: 5, name: "friday" },
    { id: 6, name: "saturday" },
  ];
  const addExerciseHandler = async (value) => {
    const request = {
      created_by: id,
      repeats: repeat,
      everyRepeat: everyRepeat.current.value,
      typeOfMode: typeOfMode.current.value,
      activeDay: activeDay,
      workouts: exercise,
      intensity: intensity.current.value,
      level: level,
    };

    let response;
    try {
      if (
        Object.values(request).some(
          (value) => value !== undefined && value !== null && value !== ""
        )
      ) {
        response = await axiosFetch({
          url: `/addExercise`,
          requestConfig: request,
          method: "post",
        });
        if (response.status === 200) {
          props.closeModel();
        }
      }
    } catch (error) {
      //console.error(error);
    }

    // if (
    //   exerciseName.current.value === "" ||
    //   exerciseLink.current.value === "" ||
    //   typeofWorkout.target.value === "" ||
    //   intensity.target.value === "" ||
    //   selectCategory.target.value === ""
    // ) {
    //   return;
    // }
  };
  const handleDayClick = (value, index) => {
    const isActive = activeDay.includes(value);
    if (isActive) {
      setActiveDays(activeDay.filter((day) => day !== value));
    } else {
      setActiveDays([...activeDay, value]);
    }
  };
  const [exercise, setExercise] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [rest, setRest] = useState(null);

  const addExercise = () => {
    if (exercise.length >= 4) {
      return;
    }
    const addedExercise = [
      ...exercise,
      {
        id: v4(),
        type: "",
        name: "",
        noOfReps: 0,
        noOfSets: 0,
        duration: "",
        notes: "",
        isRest: false,
        secs: 0,
      },
    ];
    setExercise(addedExercise);
  };
  const deleteSelectedItems = () => {
    const updatedItems = exercise.filter(
      (item) => !selectedItems.includes(item.id)
    );
    setExercise(updatedItems);
    setSelectedItems([]);
  };
  const addRestItem = () => {
    exercise.forEach((item) => {
      if (item.id === selectedItems[0]) {
        item.isRest = true;
      }
    });

    // const restItem = { isRest: true, id: selectedItems[0], sec: "" };
    // setRest(restItem);
    setExercise([...exercise]);
  };
  const handleClear = () => {
    setExercise([]);
  };
  return (
    <div className="exercise-card">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>Add Exercise</h4>
        <CloseIcon style={{ cursor: "pointer" }} onClick={props.closeModel} />
      </div>
      <div className="exercise-flex">
        <Row className="mb-4 exercise-row">
          <Col xs={2}>
            <input
              className="repeat"
              id="repeat"
              type="checkbox"
              checked={repeat}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="repeat">Repeat</label>
          </Col>
          {repeat && (
            <>
              <Col className="fs-6">
                <label className="repeat-label">Repeat Every</label>
                <select
                  style={{ display: "inline" }}
                  id="repeat"
                  className="custom-input"
                  ref={everyRepeat}
                >
                  {week.length > 0 &&
                    week.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </Col>
              <Col className="col-week">
                <select
                  id="repeat"
                  className="custom-select"
                  ref={typeOfMode}
                  onChange={handleWeek}
                >
                  <option value="weeks">Week</option>
                  <option value="month">Month</option>
                </select>
              </Col>
              <Col className="week-btn">
                {days.map((value, index) => (
                  <button
                    type="button"
                    key={value.id}
                    className={` ${
                      activeDay.includes(value.name) ? "active-day" : ""
                    }`}
                    onClick={() => handleDayClick(value.name, index)}
                  >
                    {value.name.charAt(0).toUpperCase()}
                  </button>
                ))}
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col className="exercise-col" sm={3}>
            <label>Intensity</label>
            <select id="intensity" className="" ref={intensity}>
              <option value="high">select</option>
              <option value="high">High</option>
              <option value="medium">Medium</option>
              <option value="low">Low</option>
            </select>
          </Col>
          <Col className="exercise-col" sm={3}>
            <label>Level</label>
            <select onChange={handleDropdownChange} value={selectedOption}>
              {Object.keys(levelDropdownData).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Col>
          <Col className="exercise-col" sm={2}>
            <label style={{ visibility: "hidden" }}>Sub Level</label>
            <select onChange={(e) => setLevel(e.target.value)}>
              {Object.keys(levelDropdownData[selectedOption]).map(
                (subOption) => (
                  <option key={subOption} value={subOption}>
                    {subOption}
                  </option>
                )
              )}
            </select>
          </Col>
          <Col className="exercise-col" sm={1}>
            <label style={{ visibility: "hidden" }}>Add</label>
            <button
              style={{ backgroundColor: "black" }}
              type="button"
              onClick={() => addExercise()}
            >
              <AddIcon style={{ color: "white" }} />
            </button>
          </Col>
          <Col className="exercise-col" sm={1}>
            <label style={{ visibility: "hidden" }}>Delete</label>
            <button
              style={{ backgroundColor: "#DF2E38", color: "white" }}
              type="button"
              onClick={deleteSelectedItems}
            >
              <DeleteIcon />
            </button>
          </Col>
          <Col className="exercise-col" sm={1}>
            <label style={{ visibility: "hidden" }}>Rest</label>
            <button
              style={{ backgroundColor: "black", color: "white" }}
              type="button"
              onClick={addRestItem}
            >
              <img
                src={RecliningPerson}
                alt="recliningPerson"
                width="20"
                height="20"
              />
            </button>
          </Col>
          <Col className="exercise-col" sm={1}>
            <label style={{ visibility: "hidden" }}>Clear</label>
            <button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={handleClear}
            >
              Clear
            </button>
          </Col>
        </Row>
        {exercise.map((item) => {
          return (
            <>
              <AddWorkout
                key={item.id}
                item={item}
                workout={workout}
                exerciseName={exerciseName}
                isSelected={selectedItems.includes(item.id)}
                toggleSelect={() => {
                  if (selectedItems.includes(item.id)) {
                    setSelectedItems(
                      selectedItems.filter((id) => id !== item.id)
                    );
                  } else {
                    setSelectedItems([...selectedItems, item.id]);
                  }
                }}
              />
              {item.isRest ? (
                <RestComponent
                  key={item.id + "rest"}
                  item={item}
                  isSelected={selectedItems.includes(item.id)}
                  toggleSelect={() => {
                    if (selectedItems.includes(item.id)) {
                      setSelectedItems(
                        selectedItems.filter((id) => id !== item.id)
                      );
                    } else {
                      setSelectedItems([...selectedItems, item.id]);
                    }
                  }}
                />
              ) : (
                <></>
              )}
            </>
          );
        })}
        <Row className="final-btn">
          <button
            className="fill"
            type="button"
            onClick={() => {
              addExerciseHandler(false);
            }}
          >
            Add
          </button>

          <button className="outline" type="button" onClick={props.closeModel}>
            Cancel
          </button>
        </Row>
      </div>
    </div>
  );
};

const AddExercise = (props) => {
  const backdropRoot = document.getElementById("workout__modal-backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const workoutModel = document.getElementById("workout__modal-root");
  if (!workoutModel) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop closeModel={props.closeModel} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <Exercise closeModel={props.closeModel} />,
        workoutModel
      )}
    </>
  );
};

const RestComponent = ({ item, isSelected, toggleSelect }) => {
  return (
    <Row className="mt-3 exercise-row">
      <Col
        className="flex-row d-flex justify-content-start align-items-center"
        sm={1}
      >
        {/* <label style={{ visibility: "hidden" }}>Checkbox</label> */}
        <div>
          <input
            className="repeat"
            type="checkbox"
            checked={isSelected}
            onChange={toggleSelect}
          />
        </div>
        <div>
          <img src={DragDots} alt="Dots" width={8} height={18} />
        </div>
      </Col>
      <Col className="exercise-cols" sm={1}>
        {/* <label style={{ visibility: "hidden" }}>Rest</label> */}
        <button
          type="button"
          style={{ backgroundColor: "black", color: "white" }}
        >
          <img
            src={RecliningPerson}
            alt="recliningPerson"
            width="20"
            height="20"
          />
        </button>
      </Col>
      <Col className="exercise-cols" sm={1}>
        {/* <label style={{ visibility: "hidden" }}>Rest</label> */}
        <button type="button" style={{ backgroundColor: "white" }}>
          Rest
        </button>
      </Col>
      <Col className="exercise-cols" sm={1}>
        {/* <label style={{ visibility: "hidden" }}>In Sec</label> */}
        <input
          type="number"
          style={{ width: "80px" }}
          name="secs"
          min={0}
          placeholder="In sec"
          onChange={(e) => {
            item.secs = parseInt(e.target.value);
          }}
        />
      </Col>
    </Row>
  );
};

export default AddExercise;
