import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";
import { GrNotes } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import {
  DataGridPro,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Paper from "@mui/material/Paper";
import { dateConverter } from "../../Utlis/dateConversion";
import moment from "moment";

function CallHistory() {
  /* const routeParams = useParams()

    console.log('routeParams.page', routeParams.page) */

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [filterModel, setFilterModel] = useState({
    items: [],
    logicOperator: "and",
    quickFilterLogicOperator: "and",
    quickFilterValues: [],
  });

  const userType = [
    {
      value: "lead",
      label: "Lead",
    },
    {
      value: "client",
      label: "Client",
    },
  ];

  const [callHistory, setCallHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState("");
  const [openReviewNotePopUp, setOpenReviewNotePopup] = useState(false);
  const [reviewNoteUUID, setReviewNoteUUID] = useState("");
  const [editReviewNote, setReviewEditNote] = useState("");
  const [disableNotes, setDisableNotes] = useState(false);
  const [rowData, setRowData] = useState();
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "1000px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const contextState = useContext(stateContext);

  const { configToken, operator, featureAccessible } = contextState.state;

  let loggedInUser = JSON.parse(window.localStorage.getItem("operator"));

  const routeParams = useLocation().search;
  const searchParams = new URLSearchParams(routeParams);
  let leadTitle;

  const [masterData, setMasterData] = useState({
    callTypes: [
      { id: 1, name: "Outgoing" },
      { id: 2, name: "Incoming" },
    ],
  });

  function getDisplayValueById(arr, id, fn) {
    try {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return fn(arr[i]);
          // arr[i][attrName];
        }
      }
    } catch (error) {
      let err = error;
    }
    return "-";
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    type: false,
    fromNumber: false,
    leadStatus: false,
    callStatus: false,
    reportingManager: false,
  });

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      valueOptions: masterData.callTypes.map((e) => {
        return {
          value: e.id,
          label: e.name,
        };
      }),
      hideable: true,
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.type) {
          let val = getDisplayValueById(
            masterData.callTypes,
            obj.row.type,
            (e) => e.name
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "leadId",
      headerName: "Lead ID",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => {
        return !!params?.row?.leadId ? params?.row?.leadId : "-";
      },
    },
    {
      field: "fromName",
      headerName: "Username",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => {
        return !!params?.row?.fromName ? params?.row?.fromName : "-";
      },
    },
    {
      field: "fromNumber",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "IVR Number",
      valueGetter: (params) => {
        return !!params?.row?.from ? params?.row?.from : "-";
      },
    },
    {
      field: "firstName",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Client / Lead Name",
      valueGetter: (params) => {
        return !!params?.row?.Lead?.name ? params?.row?.Lead?.name : "-";
      },
    },

    {
      field: "contactNumber",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Client/lead Contact Number",
      valueGetter: (params) => {
        return !!params?.row?.to ? params?.row?.to : "-";
      },
    },
    {
      field: "leadOrClient",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      headerName: "Lead/Client",
      valueOptions: userType.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      // valueGetter: (params) => {
      //   let isClient = params?.row?.isClient;
      //   return isClient ? "Client" : "Lead";
      // },
      renderCell: (params) => {
        let isClient = params?.row?.isClient;
        return isClient ? "Client" : "Lead";
      },
    },
    {
      field: "leadStatus",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Lead Status",
      filterable: false,
      valueGetter: (params) => {
        let lead = params.row.Lead;
        if (!!lead) {
          if (!!lead.LeadStatusID) {
            return !!lead.LeadStatusID.Status ? lead.LeadStatusID.Status : "-";
          }
        } else {
          return "-";
        }
      },
    },

    // { field: 'followupDate' },
    {
      field: "callDate",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Date/Time",
      type: "date",
      renderCell: (obj) => {
        // let date = params.row.createdAt;
        return moment(obj.row.createdAt).format("YYYY-MM-DD");
      },
      valueGetter: (params) => {
        return new Date(params.row.createdAt);
      },
    },
    // {
    //     field:'callTime',
    //     headerClassName: "super-app-theme--header",
    //     width : 150,
    //     headerName : "Time",
    //     valueGetter : (params)=>{
    //         let date = params.row.createdAt;
    //         return date.split('T')[1].slice(0,-5);
    //     }
    // },
    {
      field: "callDurationinSeconds",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Duration(mins)",
      valueGetter: (params) => {
        // let duration = params.row.callDuration;
        // let seconds = Math.round(duration / 60);
        return !!params.row.callDuration ? params.row.callDuration : "-";
      },
    },
    {
      field: "callNotes",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Call Notes",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <GrNotes
              onClick={(e) => {
                setReviewNoteUUID(params?.row?.callUUID);
                setOpenReviewNotePopup(true);
                setReviewEditNote(params?.value);
                setDisableNotes(true);
              }}
              fontSize={10}
            />
          </>
        );
      },
    },
    {
      field: "InteractionCategoryName",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Call Category",
    },
    {
      field: "telephony",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Telephony",
    },
    {
      field: "recordingURL",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Recording",
      filterable: false,
      renderCell: (params, id) => {
        // console.log('params', params)
        return (
          <audio controls>
            <source src={params.row.recordingURL} type="audio/mp3" />
          </audio>
        );
      },
    },

    {
      field: "callStatus",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Call Status",
      valueGetter: (params) => {
        return !!params.row.callStatus ? params.row.callStatus : "-";
      },
    },
    {
      field: "reportingManager",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Reporting Manager",
      valueGetter: (params) => {
        let user = params.row.User;
        if (user !== null) {
          let reportingManagerDtls = user?.reportingManagerInfo;
          if (!!reportingManagerDtls) {
            return !!reportingManagerDtls?.Username
              ? reportingManagerDtls?.Username
              : "-";
          } else {
            return "-";
          }
        } else {
          return "-";
        }
      },
    },

    {
      field: "reviewNote",
      headerClassName: "super-app-theme--header",
      width: 150,
      headerName: "Review Notes",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <GrNotes
              onClick={(e) => {
                setReviewNoteUUID(params?.row?.callUUID);
                setOpenReviewNotePopup(true);
                setReviewEditNote(params?.value);
                setDisableNotes(false);
              }}
              fontSize={10}
            />
          </>
        );
      },
    },
  ]);

  /* {
    featureAccessible.find(fe=>fe.sub_feature_id==2 && fe.is_view ) && <th scope="col">Review Note</th>
 } */

  useEffect(() => {
    (featureAccessible.find((fe) => fe.sub_feature_id == 2 && fe.is_view) ||
      featureAccessible.find((fe) => fe.sub_feature_id == 3 && fe.is_view)) &&
      setColumnDefs((preState) => {
        preState.push({
          field: "reviewNote",
          cellRenderer: (params, is) => {
            return (
              <>
                {
                  <GrNotes
                    onClick={(e) => {
                      // console.log('callInfo::', callInfo)
                      setReviewNoteUUID(params?.data?.callUUID);
                      setOpenReviewNotePopup(true);
                      setReviewEditNote(params?.value);
                      setDisableNotes(false);
                    }}
                    fontSize={10}
                  />
                }

                {params?.value}
              </>
            );
          },
        });

        return preState;
      });
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);
  const onGridReady = useCallback((params) => {
    /* fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
      .then((resp) => resp.json())
      .then((data) => setRowData(data)); */
  }, []);

  function getCallHistory() {
    let callHistoryAPI = `${ATLAS_URI}/getCallHistory`;
    setIsLoading(true);
    let parameters = {
      page: !!paginationModel.page + 1 ? paginationModel.page + 1 : null,

      perPage: !!paginationModel.pageSize ? paginationModel.pageSize : null,
    };

    const queryString = Object.entries(parameters)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    /* if (!routeParams.page) { */

    // if both lead and client list access
    if (
      featureAccessible.find((fe) => fe.sub_feature_id == 2 && fe.is_view) &&
      featureAccessible.find((fe) => fe.sub_feature_id == 6 && fe.is_view)
    ) {
      callHistoryAPI = `${ATLAS_URI}/getAllCallHistory`;
    }

    // only access for all leads
    else if (
      featureAccessible.find((fe) => fe.sub_feature_id == 2 && fe.is_view) &&
      featureAccessible.find(
        (fe) => fe.sub_feature_id == 6 && fe.is_view == false
      )
    ) {
      callHistoryAPI = `${ATLAS_URI}/getCallHistory`;
    }

    // only access for all clients
    else if (
      featureAccessible.find(
        (fe) => fe.sub_feature_id == 2 && fe.is_view == false
      ) &&
      featureAccessible.find((fe) => fe.sub_feature_id == 6 && fe.is_view)
    ) {
      callHistoryAPI = `${ATLAS_URI}/getClientCallHistory`;
    }

    // only my leads
    else if (
      featureAccessible.find((fe) => fe.sub_feature_id == 3 && fe.is_view) &&
      featureAccessible.find(
        (fe) => fe.sub_feature_id == 2 && fe.is_view == false
      ) &&
      featureAccessible.find(
        (fe) =>
          fe.sub_feature_id == 4 && fe.is_view == false && fe.is_edit == false
      )
    ) {
      callHistoryAPI = `${ATLAS_URI}/getCallHistoryByUserId/${operator.id}`;
    }

    // my client view or diet chart edit or workout chart edit
    else if (operator.Feature !== "") {
      if (
        operator?.Feature?.find((features) => features?.sub_feature_id === 44)
          ?.is_view ||
        operator?.Feature?.find((features) => features.sub_feature_id === 23)
          ?.is_edit ||
        operator?.Feature?.find((features) => features.sub_feature_id === 24)
          ?.is_edit
      ) {
        callHistoryAPI = `${ATLAS_URI}/getClientCallHistoryByUserId/${operator.id}`;
      }
    }

    axios
      .post(`${callHistoryAPI}?${queryString}`, filterModel, configToken)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setTotalRecords(res.data.count);
          const userid = JSON.parse(
            window.localStorage.getItem("operator")
          ).Role;

          /* let filteredData;
               if(res.data.length !== 0 && userid !== 1){
                // Trainer 
                if(userid == 31){
                    filteredData = res.data.callHistory.filter(item => item.User !== null).filter(item => (item.User.Role == 31));
                }
                 // TrainerManager
                else if(userid == 30) {
                    filteredData = res.data.callHistory.filter(item => item.User !== null).filter(item => (item.User.Role == 31 || item.User.Role == 30));
                }
                // Dietician Manager
                else if(userid == 28){
                    filteredData = res.data.callHistory.filter(item => item.User !== null).filter(item => (item.User.Role == 29 || item.User.Role == 28));
                }
                // Dietician  
                else if(userid == 29){
                    filteredData = res.data.callHistory.filter(item => item.User !== null).filter(item => (item.User.Role == 29));
                }
                // sales executive
                else if(userid == 18){
                    filteredData = res.data.callHistory.filter(item => item.User !== null).filter(item => (item.User.Role == 18));
                }
                // Sales Manager
                else if(userid == 15){
                    filteredData = res.data.callHistory.filter(item => item.User !== null).filter(item => (item.User.Role == 18 || item.User.Role == 15));
                }
               }
              
               else{
                filteredData = res.data.callHistory;
               } */
          setCallHistory(
            res.data?.callHistory?.map((c) => {
              c.fromName = c?.User?.Username;
              c.firstName = c?.Lead?.name;
              c.fromNumber = `"${c?.callerId}"`;
              c.contactNumber = `"${c?.to}"`;
              c.leadOrClient = c?.isClient ? "Client" : "Lead";
              c.leadStatus = c?.Lead?.LeadStatusID?.Status;
              c.callDate = c?.createdAt;
              c.callTime =
                c?.createdAt && new Date(c?.createdAt).toLocaleTimeString();
              // c.followupDate = c?.Lead?.vmax_followups?.[0]?.NextFollowUpDate && (new Date(c?.Lead?.vmax_followups?.[0]?.NextFollowUpDate).toDateString() + ', ' + new Date(c?.Lead?.vmax_followups?.[0]?.NextFollowUpDate).toLocaleTimeString().toString())
              c.callDurationinSeconds = c?.callDuration;
              // c.type = c.type == 1 ? "Outbound" : "Inbound";
              c.leadId = c?.Lead?.id;
              c.reportingManager = c?.User?.reportingManagerInfo?.Username;
              return c;
            })
          );
          return;
        }
      })
      .catch((err) => {
        // alert(err.message)
      });
  }

  useEffect(() => {
    getCallHistory();
  }, [paginationModel, filterModel]);

  function saveReviewNote(callUUID, notes) {
    const postNote = {
      callUUID: callUUID,
      reviewNotes: notes,
      reviewNoteAddedBy: operator.id,
    };

    axios
      .post(`${ATLAS_URI}/postReviewNoteByCallUUID`, postNote, configToken)
      .then((noteRes) => {
        if (noteRes.status == 200) {
          getCallHistory();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const handleChangePaginationModel = (model) => {
    setPaginationModel(model);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function handleAndAppendFilter(fil) {
    // const fullstate = apiRef.current.exportState();
    // props.onFilterChange(fil, fullstate);
    setFilterModel(fil);
  }

  return (
    <section className="content">
      <div className="row">
        <div className="col-md-12">
          <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
              <Paper
                sx={{
                  height: 700,
                  width: "100%",
                }}
              >
                <DataGridPro
                  loading={isLoading}
                  onPageChange={(a, b, c) => console.log({ a, b, c })}
                  rowCount={totalRecords}
                  rows={callHistory}
                  columns={columnDefs}
                  paginationMode="server"
                  filterMode="server"
                  onFilterModelChange={handleAndAppendFilter}
                  onPaginationModelChange={handleChangePaginationModel}
                  // slots={{ toolbar: parseInt(loggedInUser.id) === 1 || parseInt(loggedInUser.id) === 15 || parseInt(loggedInUser.id) === 28 || parseInt(loggedInUser.id) === 30 ? CustomToolbar : ""}}
                  slots={{ toolbar: CustomToolbar }}
                  pagination
                  pageSizeOptions={[25, 50, 100]}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={handleColumnVisibilityChange}
                  page={paginationModel.page}
                  pageSize={paginationModel.pageSize}
                  paginationModel={paginationModel}
                  sx={{
                    ".MuiDataGrid-row.Mui-even": {
                      backgroundColor: "#DCD6D0",
                    },
                    ".MuiDataGrid-row.Mui-odd": {
                      backgroundColor: "#fff",
                    },
                    ".MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold !important",
                      overflow: "visible !important",
                    },
                  }}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>

      {openReviewNotePopUp && (
        <Modal
          show={openReviewNotePopUp}
          onHide={() => setOpenReviewNotePopup(false)}
          style={{
            width: "50%",
            justifyContent: "center",
            position: "relative",
            left: "50%",
            transform: "translateY(-1000px)",
          }}
        >
          {!disableNotes ? `Review` : ""} Notes
          <Modal.Body>
            {/* only sales mangers can edit the review notes */}
            <textarea
              readOnly={disableNotes}
              style={{ width: "100%" }}
              onChange={(e) => {
                setReviewEditNote(e.target.value);
              }}
              defaultValue={editReviewNote}
              disabled={
                !featureAccessible.find(
                  (fe) =>
                    fe.feature_id === 92 &&
                    fe.sub_feature_id === 93 &&
                    fe.is_edit &&
                    fe.is_view
                )
              }
            />
          </Modal.Body>
          <Modal.Footer>
            {featureAccessible.find(
              // (fe) => fe.sub_feature_id == 2 && fe.is_view
              (fe) =>
                fe.feature_id === 92 &&
                fe.sub_feature_id === 93 &&
                fe.is_edit &&
                fe.is_view
            ) &&
              !disableNotes && (
                <Button
                  onClick={() => {
                    saveReviewNote(reviewNoteUUID, editReviewNote);
                    setOpenReviewNotePopup(false);
                  }}
                >
                  save
                </Button>
              )}
            <Button
              variant="secondary"
              onClick={() => setOpenReviewNotePopup(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
}

export default CallHistory;
