import React, { useEffect } from "react";
import "./MyTask.css";
import FilterListIcon from "@mui/icons-material/FilterList";
// import Select from "react-select";
import { useState } from "react";

import { axiosFetch } from "../../Utlis/AxiosFetch";

import Box from "@mui/material/Box";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { toast } from "react-toastify";

import Button from "../../components/CustomButton/Button";

const today = new Date();

const lableNames = [
  "Today",
  "Tomorrow",
  "Next 7 days",
  "Next 30 days",
  "Yesterday",
  "Last 7 days",
  "Last 30 days",
];

const dateOptions = [0, 1, 7, 30, -1, -7, -30].reduce((result, days, index) => {
  const date = new Date(new Date().setDate(today.getDate() + days))
    .toISOString()
    .split("T")[0];

  const dateAndDay = new Date(new Date().setDate(new Date().getDate() + days));
  const formattedDay = dateAndDay.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  result.push({
    id: index + 1,
    date: date,
    label: lableNames[index],
    day: formattedDay,
  });

  return result;
}, []);

const TaskFilter = ({
  className,
  activeTab,
  onFilterdata,
  onFlag,
  clearTable,
}) => {
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;
  const [dudeDate, setDudeDate] = useState("");

  const [filterOption, setFilterOption] = useState([]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndtime] = useState(null);

  const handleDueDate = (e) => {
    if (+e.target.value > 1) {
      setStartTime(null);
      setEndtime(null);
    }
    setDudeDate(e.target.value);
  };

  const convertTime = (e) => {
    const time = e;
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };

  const handleFilter = async () => {
    let convertStartTime;
    let convertEndTime;
    const filterDate = dateOptions.filter((item) => +item.id === +dudeDate)[0];

    if (filterDate.id === 1 && startTime && endTime) {
      convertStartTime = convertTime(startTime);
      convertEndTime = convertTime(endTime);
    }

    const response = await axiosFetch({
      url: `/getTaskDetailsByStatus?page=1&perPage=10&date=${
        filterDate.date
      }&startTime=${convertStartTime || "00:00"}&endTime=${
        convertEndTime || "23:59"
      }&userId=${userId}&status=${activeTab}`,
      method: "post",
    });

    if (response.status === 200) {
      toast.success("Filter Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });

      if (filterDate.label === "Today") {
        onFlag(false);
      } else {
        onFlag(true);
      }

      onFilterdata(response.data);
    }
  };

  const handleClear = () => {
    setDudeDate("");
    setEndtime(null);
    setStartTime(null);
    clearTable(true);
  };

  useEffect(() => {
    if (activeTab === "due") {
      const filteredData = dateOptions.filter(
        (item) =>
          item.label !== "Next 7 days" &&
          item.label !== "Tomorrow" &&
          item.label !== "Next 30 days"
      );
      setFilterOption(filteredData);
    } else if (activeTab === "open") {
      const filteredData = dateOptions.filter(
        (item) =>
          item.label !== "Yesterday" &&
          item.label !== "Last 7 days" &&
          item.label !== "Last 30 days"
      );
      setFilterOption(filteredData);
    } else {
      setFilterOption(dateOptions);
    }
  }, [activeTab]);

  return (
    <div className={className}>
      <h4>My Tasks</h4>
      <div className="filter-box row-1">
        <FilterListIcon />
        <label> Due date:</label>
        <Box sx={{ minWidth: 120 }}>
          {/* */}
          <FormControl style={{ width: "210px" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={handleDueDate}
              value={dudeDate}
            >
              {filterOption.map((value, index) => (
                <MenuItem value={value.id} key={index}>
                  <div className="filter-date">
                    <span>{value.label}</span>
                    <span>{value.day}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <label> Start Time:</label>
        <div className="selected-box">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                disabled={+dudeDate !== 1}
                onChange={(e) => setStartTime(e.$d)}
                value={startTime}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <label> End Time:</label>
        <div className="selected-box">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                disabled={+dudeDate !== 1}
                onChange={(e) => setEndtime(e.$d)}
                value={endTime}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div>
          <Button inline type={"button"} onClick={handleFilter}>
            Submit
          </Button>
        </div>
        <div>
          <Button inline type={"button"} onClick={handleClear}>
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TaskFilter;
