import { createContext, useContext, useState } from "react";
import CallerPopup from "../../Call/CallerPopup";

const CallerPopupContext = createContext();

const CallerPopupProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [callId, setCallid] = useState("");
  const [pendingInbound, setPendingInbound] = useState({});

  return (
    <CallerPopupContext.Provider
      value={{
        isVisible,
        setIsVisible,
        setSelectedItems,
        selectedItems,
        setCallid,
        callId,
        setPendingInbound,
        pendingInbound,
      }}
    >
      {children}
      <CallerPopup />
    </CallerPopupContext.Provider>
  );
};

const useCallerPopup = () => {
  const context = useContext(CallerPopupContext);

  if (context === undefined) {
    throw new Error(
      "CallerPopupContext was used outside of the CallerPopupProvider"
    );
  }
  return context;
};

export { CallerPopupProvider, useCallerPopup };
