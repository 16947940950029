import React, { useState, useEffect, useContext, useMemo } from "react";
import { editTableRow, openDialog } from "../../Helpers/TableHelpers";
import axios from "axios";
import SelectBox from "../../components/SelectBox";
import BoxHeader from "../../components/BoxHeader";
import DataTable from "../../components/DataTable";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import { Button } from "react-bootstrap";
// import ClientWorkoutPlan from "./ClientWorkoutPlan";
import { BiRefresh } from "react-icons/bi";
import WorkoutPlanView from "../Plans/WorkoutPlanView";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublicIcon from "@mui/icons-material/Public";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { axiosFetch } from "../../Utlis/AxiosFetch";

import ClientWorkoutPlan from "./clientPlans/WorkoutPlan/ClientWorkoutPlan";
import { useDispatch } from "react-redux";
import { updateEditWorkout } from "../store/WorkoutPlan/WorkoutPlan";

// import ClientWorkoutPlan from "./clientPlans/ClientWorkoutPlan";

function ClientWorkoutPlanListing(props) {
  const current_pathname = window.location.pathname.includes("lead");

  const dispatch = useDispatch();
  // const clientId = useSelector((state) => state.leadId);
  const { id } = useParams();
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [workoutPlanId, setWorkoutPlanId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState([]);
  const contextState = useContext(stateContext);
  const [showPlan, setShowPlan] = useState(false);
  const { updateEditDetails, updateList } = contextState;
  const { configToken, operator, currentUser } = contextState.state;
  const [state, setState] = useState();
  const [workoutPlanList, setWorkoutPlanList] = useState([]);
  const [selectedIds, setSelectedIds] = useState(null);
  const [selectDate, setSelectDate] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [remarksPopup, setRemarksPopup] = React.useState(false);
  const [remarks, setRemarks] = useState("");
  const [dietPlanView, setDietPlanView] = useState(false);
  const [dietPlanViewPlanId, setDietPlanViewPlanId] = useState("");
  const [dietPlanId, setDietPlanId] = useState(null);
  const [update, setUpdate] = useState(false);
  const [rowDetails, setRowDetails] = useState("");
  const [publish, setPublish] = useState("");

  const isAccess = JSON.parse(localStorage.getItem("currentRow"))
    ?.programFeature?.workout_plan;

  const [activeId, setActiveId] = useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [planId, setPlanId] = useState("");

  const [pdfLink, setPdfLink] = useState();

  const open = Boolean(anchorEl);
  const clientId = id;

  const getData = async () => {
    const response = await axiosFetch({
      url: `/getWorkoutPlansByLeadId/${+clientId}`,
      method: "get",
    });

    if (response.status === 200) {
      setWorkoutPlanList(response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const updatePlan = async (request) => {
    const response = await axiosFetch({
      url: `updateWorkoutPlanStageAndStateByPlanId/${planId}`,
      requestConfig: request,
      method: "post",
    });

    if (response.status === 200) {
      let clientId = id;
      const response = await axiosFetch({
        url: `/getWorkoutPlansByLeadId/${+clientId}`,
        method: "get",
      });

      if (response.status === 200) {
        setWorkoutPlanList(response.data);
      }
    }
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  const handlePublish = async () => {
    let clientId = id;
    let request = {
      stage: "Published",
      leadId: clientId,
      status: true,
    };

    updatePlan(request);

    setAnchorEl(null);
  };

  const handleDownload = async () => {
    if (pdfLink) {
      window.open(pdfLink, "_blank");
    }
  };

  const handleActive = (payload) => {
    let request;
    if (payload === "active") {
      request = {
        stage: "Published",
        leadId: clientId,
        status: true,
      };
    } else {
      request = {
        leadId: clientId,
        status: false,
      };
    }

    updatePlan(request);
    setAnchorEl(null);
  };

  const editPermission = contextState.state.featureAccessible.find(
    (feature) => feature.sub_feature_id === 24
  ).is_edit;
  let monthList = [],
    monthData = [];
  const onSelectionChanged = (event) => {
    setSelectedIds(
      event.api.getSelectedRows().map((selected) => {
        return selected.id;
      })
    );
  };
  // function editWorkoutPlan(dietPlanId) {
  //   setDietPlanId(dietPlanId);
  //   setShowDetailPage(true);
  //   setUpdate(true);
  // }

  const openRemarks = () => {
    setRemarksPopup(true);
  };
  const showDietPlanViewModal = (dietPlanId) => {
    setDietPlanView(true);
    setDietPlanViewPlanId(dietPlanId);
  };

  const closeRemarks = () => {
    setRemarksPopup(false);
    setRemarks("");
  };
  const viewDietPlan = () => {
    showDietPlanViewModal(planId);
    handleClose();
  };

  const handleClick = (event, row, index) => {
    setPdfLink(row.pdfLink);
    setRowDetails(row);

    setAnchorEl(event.currentTarget);
    setActiveId(index);
    setPlanId(row.id);
    props.setShowDetailPage(false);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const updateData = (data) => {
      setRowData(data);
    };
  };

  const checkbox = (params) => {
    return params.node.group === true;
  };

  const autoGroupColumnDef = useMemo((params) => {
    /* get Workout Plan */

    const getWorkoutPlan = () => {};
    return {
      headerName: "Athlete",
      field: "athlete",
      minWidth: 250,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox,
      },
    };
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${ATLAS_URI}/getWorkoutPlansByLeadId/${props.clientDetail.Lead}`,
  //       configToken
  //     )
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setWorkoutPlanList(res.data);
  //       }
  //     });
  // }, [!showDetailPage]);

  function addNew() {
    setWorkoutPlanId(null);
    setShowDetailPage(true);
    dispatch(updateEditWorkout(""));
  }

  function editWorkoutPlan(workoutPlanId) {
    setDietPlanId(dietPlanId);
    setUpdate(true);

    setAnchorEl(null);
    setWorkoutPlanId(workoutPlanId);
    setShowDetailPage(true);

    dispatch(updateEditWorkout(workoutPlanId));
  }

  /* Get Workout plan Refresh */

  const getWorkoutPlan = () => {
    axios
      .get(
        `${ATLAS_URI}/getWorkoutPlansByLeadId/${props.clientDetail.Lead}`,
        configToken
      )
      .then((res) => {
        if (res.status == 200) {
          setWorkoutPlanList(res.data);
        }
      });
  };

  const customizeData = (data) => {
    if (data && data.length !== 0) {
      let index = 0;
      for (let i in data.workoutPlanDetails) {
        // insert date
        let enteredDate = data.workoutPlanDetails[i].date;
        let org_Date = new Date();
        let date = new Date(org_Date).getDate() + parseInt(i);
        let month;

        let mnth = new Date(org_Date).getMonth() + 1;
        if (mnth <= 9) {
          month = "0" + mnth;
        }
        if (date <= 9) {
          date = "0" + date;
        }
        let year = new Date(org_Date).getFullYear();

        // if (date == 32) {
        //     date = "01";
        // }
        let NumberOfdaysInAMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
        if (date > NumberOfdaysInAMonth) {
          // if (date == 32) {
          //     index = 0
          // }
          org_Date.setMonth(org_Date.getMonth() + 1, index + 1);
          let nextMonth = new Date(org_Date);
          date = nextMonth.getDate();
          index++;
          if (date <= 9) {
            date = "0" + date;
          }

          if (nextMonth.getMonth() + 1 <= 9) {
            month = "0" + (nextMonth.getMonth() + 1);
          } else {
            month = nextMonth.getMonth() + 1;
          }
        }
        let formatedDate = year + "-" + month + "-" + date;

        data.workoutPlanDetails[i].date = enteredDate;
      }

      generateTableJSON(data);
    }
  };

  const generateTableJSON = (data) => {
    data.workoutPlanDetails.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    const monthList = [];

    const monthData = [];
    data.workoutPlanDetails?.forEach((workoutInfo) => {
      let month = new Date(workoutInfo.date).getMonth();
      // if(workoutInfo.date.getDate()>31){

      // }
      if (monthList.indexOf(month) == -1) {
        monthList.push(month);

        monthData.push({
          week: [
            {
              day: [
                {
                  details: workoutInfo.workout_details.map((c) => {
                    return {
                      link: c.youtube_link,
                      name: c.name,
                      title: c.title,
                      date: workoutInfo.date,
                    };
                  }),
                },
              ],
            },
          ],
        });
      } else {
        if (
          monthData[monthList.indexOf(month)].week[
            monthData[monthList.indexOf(month)].week.length - 1
          ].day.length <= 6
        ) {
          monthData[monthList.indexOf(month)].week[
            monthData[monthList.indexOf(month)].week.length - 1
          ].day.push({
            details: workoutInfo.workout_details.map((c) => {
              return {
                link: c.youtube_link,
                name: c.name,
                title: c.title,
                date: workoutInfo.date,
              };
            }),
          });
        } else {
          monthData[monthList.indexOf(month)].week.push({
            day: [
              {
                details: workoutInfo.workout_details.map((c) => {
                  return {
                    link: c.youtube_link,
                    name: c.name,
                    title: c.title,
                    date: workoutInfo.date,
                  };
                }),
              },
            ],
          });
        }
      }
    });

    const workoutInfo = monthData.map((c) => {
      c.week.map((a) => {
        if (a.day.length < 6) {
          for (let index = a.day.length; index < 7; index++) {
            a.day[index] = null;
          }

          const lll = a.day.fill(
            { link: "", name: "", title: "" },
            a.day.length,
            7
          );

          return a;
        }
      });
      return c;
    });

    setState(workoutInfo);
    setShowPlan(true);
  };

  let history = useHistory();
  const fetchData = (id) => {
    monthData = [];
    monthList = [];

    axios
      .get(`${ATLAS_URI}/getWorkoutPlanByPlanId/${planId}`, configToken)
      .then((res) => {
        if (res.status === 200) {
          setSelectDate(res.data.workoutPlanDetails[0].date);
          let org_data = res.data;
          customizeData(org_data);
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => console.error(err));
  };

  const openWorkoutPlanModal = (id) => {
    localStorage.setItem("currentPlanTab", "Workout Plan");

    history.push({
      pathname: "/Clients/progressReport",
      state: { pdfData: pdfLink },
    });

    setAnchorEl(null);
    // setSelectedTemplateId(id);
    // fetchData(id);
  };

  const closeWorkoutPlanModal = () => {
    setShowPlan(false);
  };

  // const getWorkoutGoal = () => {
  //   axios
  //     .get(
  //       `${ATLAS_URI}/getWorkoutPlansByLeadId/${props.clientDetail.Lead}`,
  //       configToken
  //     )
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setWorkoutPlanList(res.data);
  //       }
  //     });
  // };

  const handleChildClose = () => {
    setShowDetailPage(false);
    getData();
  };

  return showDetailPage ? (
    <ClientWorkoutPlan
      handleChildClose={handleChildClose}
      setShowDetailPage={setShowDetailPage}
      rowDetails={rowDetails}
    />
  ) : (
    <div style={{ width: "100%", height: "1000px" }}>
      <div
        id="myGrid"
        style={{
          height: "100%",
          width: "100%",
        }}
        className="ag-theme-alpine"
      >
        {!current_pathname && (
          <>
            {editPermission && isAccess && (
              <Button style={{ margin: "0.5rem" }} onClick={addNew}>
                Add New Plan
              </Button>
            )}
          </>
        )}

        <br />
        {/* <Button style={{ margin: "0 0 0 75%", 'backgroundColor': 'transparent', 'borderColor': '#999' }} onClick={getWorkoutPlan} ><BiRefresh className='text-dark' fontSize='25px' title='Refresh the table' /></Button> */}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Plan No</strong>
                </TableCell>
                <TableCell>
                  <strong>Plan Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Stage</strong>
                </TableCell>
                <TableCell>
                  <strong>Status</strong>
                </TableCell>
                <TableCell>
                  <strong>Created By</strong>
                </TableCell>
                <TableCell>
                  <strong>Created Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Updated By</strong>
                </TableCell>
                <TableCell>
                  <strong>Updated Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Remarks</strong>
                </TableCell>
                <TableCell>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workoutPlanList.length !== 0
                ? workoutPlanList.map((row, index) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {!!row.plan_name ? row.plan_name : "-"}
                      </TableCell>
                      <TableCell>{row.stage}</TableCell>
                      <TableCell>
                        {row.status ? "Active" : "InActive"}
                      </TableCell>

                      <TableCell>
                        {row.User ? row.User.firstname : "-"}
                      </TableCell>
                      <TableCell>{row.createdAt.split("T")[0]}</TableCell>
                      <TableCell>
                        {row.updated_by ? row.updated_by.firstname : "-"}
                      </TableCell>

                      {/* !== null
                          ? row.updated_by.Username
                          : "-" */}
                      <TableCell>{row.updatedAt.split("T")[0]}</TableCell>

                      <TableCell>-</TableCell>
                      {/* <TableCell>-<IconButton onClick={openRemarks}><AddIcon/></IconButton></TableCell> */}
                      <TableCell>
                        <IconButton onClick={(e) => handleClick(e, row, index)}>
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : "No Results Found"}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          style={{ transform: "translateX(-10px)" }}
        >
          <MenuItem onClick={openWorkoutPlanModal}>
            <VisibilityIcon />
            &nbsp;View
          </MenuItem>
          <hr />

          {!current_pathname && (
            <div>
              <MenuItem onClick={() => editWorkoutPlan(planId)}>
                <EditIcon />
                &nbsp;Edit
              </MenuItem>

              <hr />
              {/* onClick={handleActive} */}
              {workoutPlanList[activeId]?.stage === "Published" && (
                <div>
                  <MenuItem>
                    {workoutPlanList[activeId]?.status === false ? (
                      <div
                        onClick={() => {
                          handleActive("active");
                        }}
                      >
                        <CheckIcon />
                        &nbsp;Active
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          handleActive("InActive");
                        }}
                      >
                        <CloseIcon />
                        &nbsp;InActive
                      </div>
                    )}
                  </MenuItem>
                  <hr />
                </div>
              )}
              <MenuItem
                onClick={() => {
                  axios
                    .delete(
                      `${ATLAS_URI}/deleteWorkoutPlanByPlanId/${planId}`,
                      configToken
                    )
                    .then((res) => {
                      if (res.status == 200) {
                        setAnchorEl(null);
                        getData();
                        // toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                        toast.success("Plan Deleted", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                        // axios.get(`${ATLAS_URI}/getWorkoutPlansByLeadId/${props.clientDetail.Lead}`, configToken)
                        // getWorkoutGoal();
                      }
                    })
                    .catch((err) => {
                      toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                      });
                    });
                }}
              >
                <DeleteIcon />
                &nbsp;Delete
              </MenuItem>
              <hr />

              {workoutPlanList[activeId]?.stage === "Draft" ? (
                <div>
                  <MenuItem onClick={handlePublish}>
                    <PublicIcon />
                    &nbsp;Publish
                  </MenuItem>
                </div>
              ) : (
                <div>
                  <MenuItem onClick={handleDownload}>
                    <DownloadIcon />
                    &nbsp;Download
                  </MenuItem>
                </div>
              )}
            </div>
          )}
        </Menu>
        {/* 


        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            sortable: true,
            filter: true,

          }}
          rowSelection={'multiple'}

          onSelectionChanged={onSelectionChanged}

        
          onGridReady={onGridReady}
          rowData={workoutPlanList}
          suppressDragLeaveHidesColumns={true}

        >
          
          <AgGridColumn
           
            filter="agTextColumnFilter"
            
            suppressColumnsToolPanel={true}
            rowSelection={'multiple'}
            autoGroupColumnDef={autoGroupColumnDef}
            headerCheckboxSelection={true}
            checkboxSelection={true}
          />
          <AgGridColumn enableRowGroup={true} suppressColumnsToolPanel={true} minWidth={100} rowDrag={false} field="plan_name" />


          <AgGridColumn enableRowGroup={true} suppressColumnsToolPanel={true} field="Action" cellRenderer={(params, id) =>


            <React.Fragment key={params.data.id}>
              <button className="btn btn-default btn-xs no-focus" title="Edit" onClick={() => editWorkoutPlan(params.data.id)} >
                <i className="fas fa-pencil" aria-hidden="true"></i>
              </button>

              <button
                        className="btn btn-default btn-xs no-focus"
                        title="View"
                        onClick={() => openWorkoutPlanModal(params.data.id)}
                      >
                        <i className="fas fa-eye" aria-hidden="true"></i>
                      </button>


                      <button className="btn btn-default btn-xs no-focus"
                                title="Delete Plan"
                                onClick={() => {
                                  axios.delete(`${ATLAS_URI}/deleteWorkoutPlanByPlanId/${params.data.id}`, configToken).then(res => {
                                    if (res.status == 200) {
                                      toast.success('Plan Deleted', { position: toast.POSITION.TOP_CENTER })
                                      getWorkoutGoal()

                                    }
                                  }).catch(err => {
                                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                                  })
                                }}>
                                <i className="fas fa-trash" aria-hidden="true"></i>
                              </button>

            </React.Fragment>


          }
          />

          
        </AgGridReact> */}
      </div>

      {showPlan && state.length !== 0 && (
        <WorkoutPlanView
          showPlan={showPlan}
          closeWorkoutPlanModal={closeWorkoutPlanModal}
          id={selectedTemplateId}
          data={state}
          clientsData={props.clientDetail}
          selectDate={selectDate}
        />
        //   <ClientWorkoutPlan
        //   showPlan={showPlan}
        //   closeWorkoutPlanModal={closeWorkoutPlanModal}
        //   id={selectedTemplateId}
        //   data={state}
        //   clientsData={props.clientDetail}
        //   selectDate={selectDate}
        // />
      )}
    </div>
  );
}

export default ClientWorkoutPlanListing;
