import React, { useState } from "react";
import { render } from "react-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ setTarget_calorie, target_calorie, showplanNotes }) => {
  if (!showplanNotes) {
    var modules = {
      toolbar: false,
    };
  } else {
    var modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ size: [] }],
        [{ font: [] }],
        [{ align: ["right", "center", "justify"] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [
          {
            color: [
              "red",
              "#785412",
              "blue",
              "#00FF00",
              "#FFFF00",
              "black",
              "white",
              "#C31C1F",
            ],
          },
        ],
        [
          {
            background: [
              "red",
              "#785412",
              "blue",
              "#00FF00",
              "#FFFF00",
              "black",
              "white",
              "#C31C1F",
            ],
          },
        ],
      ],
    };
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  const handleProcedureContentChange = (content) => {
    setTarget_calorie(content);
  };
  return (
    <div>
      {!showplanNotes ? (
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={target_calorie}
          onChange={handleProcedureContentChange}
          readOnly={true}
        />
      ) : (
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={target_calorie}
          onChange={handleProcedureContentChange}
          readOnly={false}
        />
      )}
    </div>
  );
};

export default TextEditor;
