import React, { useState, useEffect, useContext } from "react";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import * as $ from "jquery";
import axios from "axios";
import DataTable from "../../components/DataTable";
import SelectBox from "../../components/SelectBox";
import BoxHeader from "../../components/BoxHeader";
import SideBarItem from "../../components/SideBarItem";
import Accordion from "react-bootstrap/Accordion";
import Box from "../Clients/Box";

import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";

function Form({ onCreate, onUpdate, onInputChange, mode, item = {} }) {
  const handleSubmit = (e) => {
    e.preventDefault();

    mode === "create" ? onCreate() : onUpdate();
  };

  return (
    <>
      <div className="card">
        <form onSubmit={handleSubmit}>
          <div className="row" style={{ margin: "20px" }}>
            <div className="col-md-3">
              <label htmlFor="title">
                <label
                  style={{
                    position: "relative",
                    left: "100px",
                    top: "20px",
                    color: "#847D7F",
                  }}
                >
                  Title
                </label>
                <input
                  type="text"
                  value={item.title}
                  // placeholder="Title"
                  onChange={(e) => onInputChange("title", e.target.value)}
                  style={{
                    width: "100%",
                    margin: "0 0 5px",
                    padding: "7px 0",
                    border: "none",
                    borderBottom: "1px solid #3d5866",
                    position: "relative",
                    left: "100px",
                    top: "14px",
                  }}
                />
              </label>
            </div>
            <div className="col-md-3">
              <label htmlFor="note">
                <textarea
                  required
                  value={item.note}
                  placeholder="Take a note..."
                  onChange={(e) => onInputChange("note", e.target.value)}
                  style={{
                    width: "100%",
                    margin: "0 0 5px",
                    padding: "18px 0 ",
                    border: "none",
                    borderBottom: "1px solid black",
                    height: "75px",
                    resize: "none",
                    borderRight: "none",
                    position: "relative",
                    right: "50px",
                  }}
                />
              </label>
            </div>
            <div
              className="col-md-3"
              style={{ position: "relative", right: "210px", top: "50px" }}
            >
              <button>{mode === "create" ? "Create" : "Save"}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

const Notes = ({ items, onEdit, onDelete }) =>
  items.length ? (
    items.map(({ id, title, note, User, updatedAt }, index) => (
      <div
        key={id}
        className="card"
        style={{
          display: "inline-block",
          background: "white",
          margin: "24px 10px 0",
          padding: "16px",
          position: "relative",
          borderRadius: "5px",
          transition: "box-shadow 0.25s",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 1px 10px, rgba(0, 0, 0, 0.09) 0px 1px 2px",
        }}
      >
        <h4 className="card__title" style={{ margin: "0 0 1em" }}>
          {title}
        </h4>

        {note}

        <h6 className="card__title" style={{ margin: "0 0 1em" }}>
          Created By {User.Name}
        </h6>
        <h6 className="card__title" style={{ margin: "0 0 1em" }}>
          Created At{" "}
          {new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(updatedAt))}
        </h6>

        <div className="card__action" style={{ margin: "1em 0 0" }}>
          <button onClick={() => onDelete(id)}>Delete</button>
          <button onClick={() => onEdit(id)}>Edit</button>
        </div>
      </div>
    ))
  ) : (
    <p className="text-center" style={{ textAlign: "center" }}>
      No notes
    </p>
  );

function ClientNotesSection(props) {
  const contextState = useContext(stateContext);

  const { configToken } = contextState.state;

  const [mode, setMode] = useState("create");
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${ATLAS_URI}/getNoteByLeadId/${props.clientsData.Lead}`,
        configToken
      )
      .then((res) => {
        setItems(res.data);
      });
  }, [props.clientsData.Lead]);

  const [formItem, setFormItem] = useState({ title: "", note: "" });

  const handleInputChange = (name, value) => {
    setFormItem({ ...formItem, [name]: value });
  };

  const handleCreate = () => {
    const { title, note } = formItem;

    axios
      .post(
        `${ATLAS_URI}/post/${props.clientsData.Lead}`,
        formItem,
        configToken
      )
      .then((res) => {
        if (res.status === 200) {
          const newItems = {
            id: res.data.id,
            title,
            note,
            User: res.data.User,
            updatedAt: res.data.updatedAt,
          };

          setItems([...items, newItems]);
          setFormItem({ title: "", note: "" });
        }
      });
  };

  const handleEdit = (id) => {
    setMode("edit");

    axios.get(`${ATLAS_URI}/getNoteById/${id}`, configToken).then((res) => {
      if (res.status === 200) {
        const itemToEdit = {
          id: res.data.id,
          title: res.data.title,
          note: res.data.note,
          User: res.data.User,
          updatedAt: res.data.updatedAt,
        };

        setFormItem(itemToEdit);
      }
    });
  };

  const handleUpdate = () => {
    axios
      .put(`${ATLAS_URI}/updateById/${formItem.id}`, formItem, configToken)
      .then((res) => {
        if (res.status === 200) {
          axios
            .get(
              `${ATLAS_URI}/getNoteByLeadId/${props.clientsData.Lead}`,
              configToken
            )
            .then((res) => {
              setItems(res.data);
            });
        }
      });

    // const index = items.findIndex(item => item.id === formItem.id);
    // const updatedItems = [...items];
    // updatedItems[index] = formItem;

    setMode("create");
    setFormItem({ title: "", note: "" });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${ATLAS_URI}/deleteNoteById/${id}`, configToken)
      .then((res) => {
        if (res.status === 200) {
          axios
            .get(
              `${ATLAS_URI}/getNoteByLeadId/${props.clientsData.Lead}`,
              configToken
            )
            .then((res) => {
              setItems(res.data);
            });
        }
      });

    // const newItems = items.filter(item => item.id !== id);

    // setItems(newItems);
  };

  return (
    <>
      <Form
        mode={mode}
        item={formItem}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onInputChange={handleInputChange}
      />

      <br />

      <Notes items={items} onEdit={handleEdit} onDelete={handleDelete} />
    </>
  );
}

export default ClientNotesSection;
