import { Button, Grid, Typography, Box } from "@mui/material";
import { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import TaskItem from "./TaskItem";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import Card from "../../../../components/Card/Card";

const initialObject = {
  taskDetails: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "getTask": {
      return {
        ...state,
        taskDetails: action.payload,
      };
    }
    default: {
      throw new Error("Unkown Action");
    }
  }
};

const TaskDashBorad = () => {
  const history = useHistory();

  const [{ taskDetails }, dispatch] = useReducer(reducer, initialObject);

  const handleAddTask = () => {
    history.push({
      pathname: "/Programs/taskAutomation/AddTask",
      //   state: { programDetails: programDetails },
    });
  };

  const getTaskDetails = async () => {
    try {
      const response = await axiosFetch({
        method: "get",
        url: `/getTaskRuleList`,
      });

      if (response.status === 200) {
        const data = response.data.body;
        dispatch({ type: "getTask", payload: data });
      }
    } catch (error) {}
  };

  const handleSearch = async (event) => {
    try {
      const response = await axiosFetch({
        method: "get",
        url: `/getRuleSearch?search=${event.target.value}`,
      });

      if (response.status === 200) {
        const data = response.data.body;
        dispatch({ type: "getTask", payload: data });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTaskDetails();
  }, []);

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            textTransform: "capitalize",
            marginLeft: "5px",
          }}
        >
          Task Automation
        </Typography>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#000",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#000",
            },
            border: "20px slid #2a2a2a",
          }}
          onClick={handleAddTask}
        >
          Add Task
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
            marginBottom: "20px",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      {taskDetails?.map((task, index) => (
        <TaskItem
          task={task}
          id={index}
          key={task.id}
          getTaskDetails={getTaskDetails}
        />
      ))}
    </Card>
  );
};

export default TaskDashBorad;
